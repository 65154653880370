import React, {Component} from 'react';

import JourneyTable from './journey_table';
import NeestedRecord from './neested_record';
import OptionsCache from './options_cache';
import {globalDefaults} from './journey_data';
import {GeneSearchKey} from '../../constants.js.erb';


export default class Sequencings extends JourneyTable {
  constructor(props) {
    super(props);
    this.state = {};
    this.geneCache = new OptionsCache({source: 'genes', onChange: e => this.forceUpdate()})
    this.cancerTypeCache = new OptionsCache({source: 'patient_cancer_types', onChange: e => this.forceUpdate()});
    this.preloadNested();
  }

  componentDidUpdate() {
    this.preloadNested();
  }

  preloadNested() {
    let {sequencies} = this.props.data;
    let gene_ids = sequencies.map(s => s.gene_id);
    this.geneCache.preload(gene_ids);
  }

  addSequencing() {
    let {data, settings} = this.props;
    let current = globalDefaults({}, data, settings);
    this.setState({current});
  }

  delete(sequencing) {
    if (!confirm('Are you sure?')) return;

    let {onDelete} = this.props;
    let sectionLabel = buildSectionLabel(sequencing, this.geneCache);
    onDelete(BASE_PATH, sectionLabel, sequencing);
  }

  show(current) {
    this.setState({current});
  }


  renderForm() {
    let {readOnly, patient, data, fields_changelog, onAddBatch, onEditBatch} = this.props;
    let {current} = this.state;
    let props = {readOnly, patient, data, fields_changelog, onAddBatch, onEditBatch, record: current};
    props.onClose = e => this.setState({current: null});
    props.geneCache = this.geneCache;

    return <Sequencing {...props}  />
  }

  renderList() {
    let {data, readOnly} = this.props;
    let {sequencies} = data;

    return (
      <div>
        <button className="btn btn-success pull-right" onClick={e => this.addSequencing()} disabled={readOnly}>Add Sequencing</button>
        <div className="clearfix"></div>
        <p><b>Sequencing, Markers and Other Diagnostics</b></p>
        <table className="table table-condensed table-striped jt-log-table" id="sequencing_list">
          <thead>
            <tr>

              <th>Gene</th>
              <th>Finding</th>
              <th>Quantification</th>
              <th>Significance</th>
              <th>Detail</th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {sequencies.map(sequencing => {
              let action_label = readOnly ? 'Show' : 'Edit';
              let gene = this.geneCache.get(sequencing.gene_id);
              let unit;
              if (sequencing.units === 'multiplier') unit = 'x'
              else if (sequencing.units === 'percentage') unit = '%';

              let delete_el;
              if (!readOnly) {
                let id = "delete_sequencing-" + sequencing.id
                delete_el = <button id={id} className="btn btn-default btn-sm" onClick={e => this.delete(sequencing)}><i className="fa fa-trash"></i></button>
              }

              return(
                <tr key={sequencing.id}>

                  <td>{gene.text}</td>
                  <td>{sequencing.finding}</td>
                  <td>
                    {sequencing.quantification}{unit}
                  </td>
                  <td>{sequencing.significance}</td>
                  <td>{sequencing.details}</td>
                  <td className="actions">
                    <div className="btn-group">
                      <button className="btn btn-default btn-sm" onClick={e => this.show(sequencing)}>{action_label}</button>
                      {delete_el}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    );

  }
}


const BASE_PATH = 'sequencies';

function buildSectionLabel(record, geneCache) {
  let gene = geneCache.get(record.gene_id);
  return ['Sequencing', gene.text];
}

class Sequencing extends NeestedRecord {
  getModelName() {
    return "Sequencing";
  }

  getBasePath() {
    return BASE_PATH;
  }

  getSectionLabel() {
    let {record} = this.state;
    return buildSectionLabel(record, this.props.geneCache);
  }

  onChange(field_name, value, meta) {
    super.onChange(field_name, value, meta);
    let {record} = this.state;
    if (record.gene_id) {
      this.props.geneCache.preload([record.gene_id]);
    }
  }

  formFields() {
    let geneValueLabelCallback = sequencing => {
      let {geneCache} = this.props;
      let gene = geneCache.get(sequencing.gene_id);
      return gene.text;
    };

    return [
      {name: 'gene_id', label: 'Gene', required: true, type: 'remote-select2', src: "/admin/genes/as_options.json", search_key: GeneSearchKey, valueLabelCallback: geneValueLabelCallback},
      {name: 'significance', type: 'select', collection: ['Pathologic (Type Relevant)', 'Variant of Unknown Significance (Other)', 'Not Specified']},
      {name: 'finding', type: 'select', collection: ['Amplification', 'Deletion', 'Rearrangement', 'Mutation', 'Wildtype']},
      {name: 'details', type: 'text'},
      {name: 'quantification', type: 'number'},
      {name: 'units', type: 'select', collection: ['multiplier', 'percentage'], labelTransform: 'titleize'}
    ];
  }
}
