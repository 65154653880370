import React, {Component} from 'react';
import ActivePatients from './patients_widget/active_patients';
import AllPatients from './patients_widget/all_patients';
import PatientCollection from './patients_widget/patient_collection';
import TrialData from './patients_widget/trial_data';
import request from '../request';
import Loading from './loading';

export default class PatientsWidget extends Component {
  constructor(props) {
    super(props);
    this.state = loadState();

    this.setBodyWidth = this.setBodyWidth.bind(this);
    this.onPatientSelected = this.onPatientSelected.bind(this);
    this.showPatientTrialData = this.showPatientTrialData.bind(this);
    this.showCollection = this.showCollection.bind(this);
    this.closeTrialData = this.closeTrialData.bind(this);

    this.body_width = document.body.offsetWidth;
  }

  setPersistentState(changes, callback) {
    this.setState(changes, () => {
      saveState(this.state);
      if (callback) callback();
    });
  }

  componentDidMount() {
    this.setBodyWidth();
  }

  onPatientSelected(patient_id) {
    this.setPersistentState({selected_patient_id: patient_id});
  }

  showPatientTrialData() {
    this.setPersistentState({show_trial_data: true});
  }

  showCollection(collection_id) {
    this.setPersistentState({selected_collection_id: collection_id});
  }


  closeTrialData() {
    this.setPersistentState({show_trial_data: false});
    this.setState({patient_trial_data: undefined});
  }

  setBodyWidth() {
    let {open, sidebar} = this.state;
    let width;
    if (open && sidebar) {
      width = 80;
    } else {
      width = 100;
    }

    document.body.style.width = `${width}vw`;
    document.body.style.overflowX = 'hidden';

  }

  toggleSidebar() {
    let {sidebar} = this.state;
    sidebar = !sidebar;
    this.setPersistentState({sidebar}, this.setBodyWidth);
  }

  toggleWidget() {
    let {open, tab} = this.state;
    open = !open;
    this.setPersistentState({open, active_patient: undefined}, this.setBodyWidth);
  }


  renderWidgetContent() {
    let {show_trial_data, show_all_patients, selected_patient_id, selected_collection_id} = this.state;
    let {trial_id} = this.props;

    if (show_trial_data) {
      return <TrialData trial_id={trial_id} patient_id={selected_patient_id} onClose={this.closeTrialData} />
    }

    if (selected_collection_id) {
      return <PatientCollection patient_id={selected_patient_id} collection_id={selected_collection_id} trial_id={trial_id}
                                onClose={e => this.setPersistentState({selected_collection_id: undefined})} onShowPatientTrialData={this.showPatientTrialData} />
    }

    if (show_all_patients) {
      return <AllPatients close={e => this.setState({show_all_patients: false})} />
    }
    return <ActivePatients trial_id={trial_id} selected_patient_id={selected_patient_id} onPatientSelected={this.onPatientSelected}
                            onShowPatientTrialData={this.showPatientTrialData} onShowCollection={this.showCollection}
                            onShowAllPatients={e => this.setState({show_all_patients: true})}/>

  }


  renderWidget() {
    let {open, sidebar} = this.state;
    if (!open) return null;
    let className = sidebar ? 'sidebar' : undefined;
    let sidebar_icon = sidebar ? 'fa-chevron-down' : 'fa-window-maximize fa-rotate-90'
    return (
      <div id="patients_widget_container" className={className}>
        <div id="patients_widget_content" className={className}>
          <div className="btn-group patient_widget_header_btns">
            <button className="btn btn-sm btn-default" onClick={e => this.toggleSidebar()}><i className={"fa " + sidebar_icon} /></button>
            <button className="btn  btn-sm btn-default" onClick={e => this.toggleWidget()}><i className="fa fa-times red"  /></button>
          </div>
          {this.renderWidgetContent()}
        </div>
      </div>
    );
  }

  renderIcon() {
    return (
      <button id="open_widget" onClick={e => this.toggleWidget()}><i className="fa fa-user" /></button>
    )
  }

  render() {
    return (
      <div id="patients_widget">
        {this.renderWidget()}
        {this.renderIcon()}
      </div>
    );
  }
}

const LOCAL_STORAGE_KEY = 'serialized_state';

function loadState() {
  let serialized_state = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!serialized_state) {
      return {active_patients: [], open: false}
  }

  let state = JSON.parse(serialized_state);
  // Delete caches
  delete(state.patient_trial_data);
  return state;
}


function saveState(state) {
  let serialized_state = JSON.stringify(state);
  localStorage.setItem(LOCAL_STORAGE_KEY, serialized_state);
}

PatientsWidget.setPatient = function(patient_id) {
  let state = loadState();
  state.selected_patient_id = patient_id;
  state.show_trial_data = true;
  saveState(state);
}
