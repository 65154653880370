import React, {Component} from 'react';
import Form from './form';
import InlineEditableList from './inline_editable_list';


export class TaskActions extends InlineEditableList {

  moveActionUp(current_action) {
    this.moveAction(current_action, -1);
  }

  moveActionDown(current_action) {
    this.moveAction(current_action, 1);
  }

  moveAction(current_action, movement) {
    let {onChange, list} = this.props;
    let index = list.findIndex(a => a.id === current_action.id);

    if ((index === 0 && movement === -1)|| (index === list.length - 1 && movement === 1)) return;

    list.splice(index, 1);
    list.splice(index + movement, 0, current_action);
    onChange(list);
  }


  formFields() {
    return [{name: 'name', required: true}];
  }

  renderItem(action) {
    return (

      <div key={action.id} className="task_action_item">
        <span className="task_action_text">{action.name}</span>
        <div className="btn-group">
          <button onClick={e => this.edit(action)} className="btn btn-default btn-sm"><i className="fa fa-edit"></i></button>
          <button onClick={e => this.delete(action)} className="btn btn-default btn-sm"><i className="fa fa-trash"></i></button>
          <button onClick={e => this.moveActionUp(action)} className="btn btn-default btn-sm"><i className="fa fa-arrow-up"></i></button>
          <button onClick={e => this.moveActionDown(action)} className="btn btn-default btn-sm"><i className="fa fa-arrow-down"></i></button>
        </div>
      </div>
    );
  }
}




export class TaskActionsFormField extends Form.FormField {
  onChange(value) {
    let {field, onChange} = this.props;
    this.props.onChange({target: {name: field.name, value}}, {value_label: ''});
  }

  renderInput() {
    let {value} = this.props;
    return <TaskActions list={value} name="Actions" formContainer="div" onChange={v => this.onChange(v)} />
  }
}
