import React, {Component} from 'react';
import Form from '../form';
import request from '../../request';
import Loading from '../loading';
import {getLocationLabel} from '../locations';
import joinReact from '../join_react';

export default class AdditionalTrials extends Component {
  constructor(props) {
    super(props);
    this.state = {trial_details: []};
  }

  componentDidMount() {
    let {customizations} = this.props;
    if (customizations.additional_trials && customizations.additional_trials.length > 0) {
      let ids = customizations.additional_trials.map(({trial_id}) => trial_id);
      this.fetchPeople(ids).then(trial_details => this.setState({trial_details}));
    }
  }

  addTrial(trial_id) {
    let {customizations, onChange} = this.props;

    if (!customizations.additional_trials) customizations.additional_trials = [];
    customizations.additional_trials.push({trial_id: trial_id, person_ids: []});
    onChange(customizations);

    this.fetchPeople([trial_id]).then(details => {
      let {trial_details} = this.state;
      trial_details = trial_details.concat(details);
      this.setState({trial_details})
    });
  }

  deleteTrial(trial_id) {
    if (!confirm('Are you sure?')) return;
    let {customizations, onChange} = this.props;
    let index = customizations.additional_trials.findIndex(ad => ad.trial_id === trial_id);
    customizations.additional_trials.splice(index, 1);
    onChange(customizations);
  }


  toggleContact(trial_id, person_id) {
    let {customizations, onChange} = this.props;
    let trial_index = customizations.additional_trials.findIndex(ad => ad.trial_id === trial_id);
    let person_index = customizations.additional_trials[trial_index].person_ids.findIndex(id => id === person_id);
    if (person_index === -1) {
      customizations.additional_trials[trial_index].person_ids.push(person_id);
    } else {
      customizations.additional_trials[trial_index].person_ids.splice(person_index, 1);
    }
    onChange(customizations);
  }

  fetchPeople(ids) {
    let url = '/admin/trials/all_related_people?ids=' + ids;
    return request('GET', url);
  }


  renderTrialPeople(people, trial_id, selected_person_ids) {
    if (people.length === 0) return <p>No people associated with this trial.</p>
    let {customizations} = this.props;

    return people.map(person => {
      let location_information;
      if (person.source === 'location') {
        location_information = <span className="subtle">From location {getLocationLabel(person.trial_location)}</span>
      }

      let selected = selected_person_ids.findIndex(id => id === person.id) !== -1;
      let person_url = '/admin/persons/' + person.id
      return (
        <div key={person.id} className="additional_trial_person">
          <input type="checkbox" checked={selected} value={selected} onChange={e => this.toggleContact(trial_id, person.id)} />
            <a href={person_url}>{person.full_name}</a> {location_information}
        </div>
      );
    });
  }

  renderTrials() {
    let {customizations} = this.props;
    if (!customizations.additional_trials || customizations.additional_trials.length === 0) {
      return <p className="subtle">Trials added here will be shown in report as also available at the referring location.</p>
    }

    let {trial_details} = this.state;
    if (!trial_details) return <Loading />;

    return customizations.additional_trials.map(additional_trial => {
      let {trial_id, person_ids} = additional_trial;
      let details = trial_details.find(d => +d.trial_id === trial_id);
      if (!details) return null;

      let {name, people} = details;
      let link_to_trial = `/admin/trials/${trial_id}`
      return (
        <div key={trial_id} className="added_trial">
          <div className="added_trial_header">
            <a href={link_to_trial} target="_blank">{name}</a><br/>
            <button className='btn btn-sm btn-danger' onClick={e => this.deleteTrial(trial_id)}><i className="fa fa-times"></i></button>
          </div>
          <h4>People</h4>
          {this.renderTrialPeople(people, trial_id, person_ids)}
        </div>
      );
    });
  }

  renderTrialsPreview() {
    let {customizations} = this.props;
    if (!customizations.additional_trials || customizations.additional_trials.length === 0) {
      return null
    }
    let {trial_details} = this.state;
    if (!trial_details) return <Loading />

    return (
      <div className="content_preview_box">
        {customizations.additional_trials.map(({trial_id, person_ids}) => {
          let details = trial_details.find(d => +d.trial_id === trial_id);
          if (!details) return null;
          let {name, people} = details;

          return (
            <div key={trial_id} className="additional_trial_preview">
              <p>{name}</p>
              {person_ids.map(person_id => {
                let person = people.find(p => p.id === person_id);
                if (!person) return null;
                let person_details = [person.full_name, person.phone, person.email].filter(i => i && i.length > 0)
                return (
                  <p key={person.id}>
                    {joinReact(person_details, <br/>)}
                  </p>
                );
              })}
            </div>
          );
        })}
      </div>
    )
  }

  render() {
    let {customizations, onChange} = this.props;
    return (
      <div className="row report_config_row">
      <div className="col-md-6">
        <h4 className="report_config_section_header">ADDITIONAL TRIALS AT REFERRING LOCATION</h4>
        {this.renderTrials()}
        <SearchAdditionalTrials addTrial={t => this.addTrial(t)}  />
      </div>
      <div className="col-md-5 col-md-offset-1">
        {this.renderTrialsPreview()}
      </div>
    </div>
    )
  }
}


class SearchAdditionalTrials extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.modalEl = $(this.refs.modal);
    this.modalEl.modal({show: false});
    this.modalEl.on('hide.bs.modal', () => {
      this.setState({show_modal: false});
    });
  }

  search() {
    this.setState({searching: true});
    let {query} = this.state;
    let url = '/admin/trials/search?query=' + query;
    request('GET', url).then(results => {
      this.setState({results, searching: false});
    });
  }

  select(trial) {
    this.props.addTrial(trial.id);
    this.closeModal();
  }

  showModal() {
    this.setState({show_modal: true, query: '', results: null}, () => this.modalEl.modal('show'));
  }

  closeModal() {
    this.setState({show_modal: false, query: ''}, () => this.modalEl.modal('hide'));
  }

  renderSearchResults() {
    let {searching, results} = this.state;
    if (searching) return <Loading />
    if (!results) return null;
    if (results.length === 0) return <p>No results found</p>

    return (
      <div>
        <h3>Search Results</h3>
        {results.map(trial => {
          return (
            <div key={trial.id} className="additional_trial_search_result">
              <span><b>{trial.identifier}:</b> {trial.name}</span>
              <button className="btn btn-sm btn-success" onClick={e => this.select(trial)}>Add Trial</button>
            </div>
          )
        })}
      </div>
    )
  }
  renderSearchForm() {
    let {show_modal, query} = this.state;
    if (!show_modal) return null;

    return (
      <div>
        <Form model={{query}} fields={[{name: 'query', label: 'Search Trial', focus: true}]} onChange={c => this.setState(c)} onSubmit={e => this.search()} submitLabel="Search"/>
        {this.renderSearchResults()}
      </div>
    )

  }

  renderModal() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              {this.renderSearchForm()}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <button className="btn btn-success btm-sm" onClick={e => this.showModal()}>Add Trial</button>
        {this.renderModal()}
      </div>
    );
  }

}
