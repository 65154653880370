import React, {Component} from 'react';
import {PatientSearchKey} from '../constants.js.erb';
import request from '../request';
import Loading from './loading';


export default class PatientPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {query: ''}
  }

  change(e) {
    let {value} = e.target;
    this.setState({query: value});
  }

  search(e) {
    this.setState({loading: true});
    e.preventDefault();
    let {query} = this.state;
    let url = "/admin/patients?q[" + PatientSearchKey + "]=" + query;
    request('GET', encodeURI(url)).then(patients => {
      this.setState({patients, loading: false});
    });
  }

  renderPatients() {
    let {loading, patients} = this.state;
    let {actionLabel, onClick} = this.props;
    if (loading) return <Loading text="Loading Patients" />
    if (!patients) return null;
    return (
      <div>
        {patients.map(patient => {
          return (
            <div key={patient.id}>
              {patient.name}
              <button className="btn btn-sm btn-default" onClick={e => onClick(patient)}>{actionLabel}</button>
            </div>
          );
        })}
      </div>
    );
  }
  render() {
    let {query} = this.state;
    return (
      <div>
        <form onSubmit={e => this.search(e)}>
          <div className="form-group">
            <input type="text" className="form-control" placeholder="Select patient" value={query} onChange={e=> this.change(e)} />
            <button type="submit" className="btn btn-default">Search</button>
          </div>
        </form>

        {this.renderPatients()}
      </div>
    );
  }
}
