import React, {Component} from 'react';
import Form from '../../form';
import request from '../../../request';
import PatientFilePicker from '../../patient_file_picker';

export class BaseEventEditor extends Component {
  onChangeData(data) {
    let {event, onChange} = this.props;
    event.data = data;
    onChange(event);
  }

  onChangeFiles(files) {
    let {event, onChange} = this.props;
    event.files = files;
    onChange(event);
  }

  render() {
    let {patient, event, name, onSubmit, onCancel} = this.props;
    let fields = this.formFields();

    let files_props = {patient: patient, label: 'Files', multiple: true, value: event.files};
    files_props.onChange = f => this.onChangeFiles(f);
    fields.push({name: 'files', type: 'component', component: PatientFilePicker, props: files_props});

    return <Form model_name={name} submitLabel="Save Event" fields={fields} model={event.data} onChange={d => this.onChangeData(d)} onSubmit={onSubmit} onCancel={onCancel} />
  }
}

export class BaseEventViewer extends Component {
  renderForm() {
    let {event, summary, patient} = this.props;
    let {data} = event;

    let fields = this.formFields();
    fields.push({name: 'files', type: 'component', component: FilesViewer, props: {patient, value: event.files}});

    return <BaseEventForm readOnly={true} summary={summary} fields={fields} model={data} />
  }


  render() {
    let {event} = this.props;
    let {data} = event;
    let info;
    if (event.created_at) {
      let label = "Saved by: " + event.admin_name + " on " + moment(event.created_at).format('LLL')
      info = <p className="jt-admin-detail">{label}</p>
    }
    return (
      <div>
        {this.renderForm()}
        {info}
      </div>
    )

  }
}




class BaseEventForm extends Form {
  renderField(field, prefix, model, errors={}) {
    let {summary} = this.props;
    if (summary && !field.inSummary) return null;
    return super.renderField(field, prefix, model, errors);
  }
}


class FilesViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {files: []};
  }
  componentDidMount() {
    this.fetchFiles();
  }

  fetchFiles() {
    this.setState({loading: true});
    let {patient, value, multiple} = this.props;
    let url = '/admin/patients/' + patient.id + '/files/as_options?ids=' + value.join(',');
    request('GET', url).then(({results}) => {
      this.setState({files: results, loading: false});
    });
  }

  render() {
    let {files} = this.state;
    return (
      <div>
      {files.map(file => {
        let icon;
        if (file.preview_url) {
          icon = <img src={file.preview_url} />
        }

          return (
            <div key={file.id} className="event-file-listing">
              {icon}
              <a href={file.url}>{file.filename}</a>

            </div>
          );
        })}
      </div>
    );
  }
}
