import React, {Component} from 'react';
import Loading from '../loading';
import request from '../../request';

export default class ShareSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    this.el = $(this.refs.modal_container);
    this.el.modal({show: true});
    this.el.on('hidden.bs.modal', e => {
      this.close();
    });

    this.fetchLink();
  }


  fetchLink() {
    let {saved_search} = this.props;
    let url = `/admin/search/saved_searches/${saved_search.id}/share_link`;
    request('GET', url).then(({link}) => {
      console.log("link", link);
      this.setState({link});
    });
  }

  copyLink(link) {
    navigator.clipboard.writeText(link);
  }

  close() {
    this.el.modal('hide');
    let {onClose} = this.props;
    if (onClose) onClose();
  }


  renderContent() {
    let {loading, link} = this.state;
    if (loading) return <Loading />

    return (
      <div>
        <strong>{link}</strong>
        <button className="btn btn-default" onClick={() => this.copyLink(link)}>Copy to Clipboard</button>
      </div>
    )
  }

  render() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div id="saved_searches_modal" className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={this.close} aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">Share Saved Search</h4>
            </div>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}
