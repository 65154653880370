import React, {Component} from 'react';
import Form from './journey_tracker_form';
import JourneyRecord from './journey_record';

export default class BrainAndSpine extends JourneyRecord {
  constructor(props) {
    super(props);
    this.state = {};
    this.onChange = this.onChange.bind(this);
  }

  getTitle() { return 'Brain and Spine'; }
  getBasePath() { return 'brain_and_spine'; }

  formFields() {
    return [
      {name: 'brain_metastasis', label: 'Brain Metastasis?', type: 'select', collection: ['Yes', 'No', 'Unknown']},
      {name: 'brain_metastasis_status', type: 'select', collection: ['Present - Active', 'Present - Inactive', 'No Brain Metastasis', 'Equivocal', 'Unknown']},
      {name: 'brain_metastasis_largest_size', type: 'select', collection: ['<1.0 cm', ' 1.1 - 2.0 cm', '2.1-5.0cm', '5.1 cm - 10.0 cm', '> 10.0 cm']},
      {name: 'brain_metastasis_surgery', type: 'select', collection: ['Complete', 'Partial', 'None', 'Other']},
      {name: 'brain_metastasis_radiation', type: 'select', collection: ['Lesional', 'Whole Brain', 'None', 'Other']},
      {name: 'brain_metastasis_symptoms', type: 'select', collection: ['Asymptomatic', 'Symptomatic - Seizures', 'Symptomatic - Motor Deficit', 'Symptomatic - Sensory Deficit', 'Symptomatic - Headache', 'Symptomatic - Other']},
      {name: 'brain_metastasis_symptoms_treatment', type: 'select', collection: ['Steroids', 'Anticonvulsants', 'Steroids and Anticonvulsants', 'Other', 'None']},
      {name: 'brain_metastasis_description', type: 'text'},

      {name: 'spinal_cord_metastasis', label: 'Spinal Cord (Leptomeningeal) Metastasis?', type: 'select', collection: ['Yes', 'No', 'Unknown']},
      {name: 'spinal_cord_status', type: 'select', collection: ['Positive - nodular', 'Positive - carcinomatosis', 'Negative']},
      {name: 'spinal_cord_chemotherapy', type: 'select', collection: ['Current', 'Prior', 'None', 'Other']},
      {name: 'spinal_cord_radiation', type: 'select', collection: ['Lesional', 'Whole Spine', 'Other', 'None']},
      {name: 'spinal_cord_symptoms', type: 'select', collection: ['Asymptomatic (none)', 'Pain', 'Meningitis', 'Other']},
      {name: 'spinal_cord_description', type: 'text'}
    ];
  }

  renderForm() {
    let {brain_and_spine, readOnly} = this.props;
    return <Form fields={this.formFields()} model={brain_and_spine} onChange={this.onChange} readOnly={readOnly} />;
  }
}
