import React, {Component} from 'react';

import Form from './form';
import Loading from './loading';
import request from '../request';
import {getLocationLabel} from './locations';
import Person from './person';
import CollectionTrialTab from './reports/collection_trial_tab';
import TabQuestions from './reports/tab_questions';
import TabNextSteps from './reports/tab_next_steps';

export default class PatientReportConfig extends Component {
  constructor(props) {
    super(props);
    let {report} = props;
    this.state = {report, current_tab: 'general'};
    this.initializeStateFromUrl(this.state);
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  initializeStateFromUrl(state) {
    let url = new URL(window.location.href);
    if (!this.verifyFirstTabValid(true) || !url.hash || url.hash.length === 0) return;

    let slices = url.hash.replace('#', '').split(':')
    state.current_tab = slices[0];
    state.collection_trial_id = +slices[1];
  }

  submit() {
    this.setState({submitting: true});
    let {patient} = this.props;
    let {report, current_tab} = this.state;
    let url = '/admin/patients/' + patient.id + '/collections/' + report.collection_id + '/report_config'
    request('PUT', url, {patient_report: report}).then(({success, errors, report}) => {
      if (success) {
        this.setState({report, submitting: false, errors: undefined});
      } else {
        this.setState({errors, submitting: false});

        // If there are errors, it usually comes from the main tab
        if (current_tab != 'general') {
          window.alert("Cant' save the changes");
        }

      }
    });
  }

  onChange(report, silent=false) {
    this.setState({report}, () => {
      if (!silent) this.submit();
    });
  }

  verifyFirstTabValid(silent=false) {
    let {report} = this.state;
    if (report.report_type && report.report_type.length > 0) {
      return true;
    }
    if (!silent) {
      window.alert("Set the type of report to continue.")
    }
    return false;
  }

  selectTab(current_tab) {
    if (!this.verifyFirstTabValid()) return;
    this.setState({current_tab});
  }

  selectCollectionTrial(collection_trial_id) {
    if (!this.verifyFirstTabValid()) return;
    this.setState({current_tab: 'collection_trial', collection_trial_id});
  }

  renderResultsPicker() {
    let {current_tab, collection_trial_id} = this.state;
    let {collection_trials} = this.props;
    return (
      <ul className="nav nav-pills nav-stacked light-nav">
        {collection_trials.map(collection_trial => {
          let label = collection_trial.trial_identifier;
          let className =  (current_tab === 'collection_trial' && collection_trial_id === collection_trial.id) ? 'active' : null;
          let anchor = '#' + current_tab + ':' + collection_trial.id;
          return <li key={collection_trial.id} role="presentation" className={className}><a href={anchor} onClick={e => this.selectCollectionTrial(collection_trial.id)}>{label}</a></li>
        })}
      </ul>
    );

  }

  renderHeader() {
    let {patient} = this.props;
    let {report} = this.state;
    let patient_url = '/admin/patients/' + patient.id;
    let collection_url = patient_url + '/collections/' + report.collection_id;
    let preview_url = collection_url + '/report_preview'

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="report_config_header">
            <div>
              <h1>{patient.name}</h1>
              <p><a href={patient_url}>View Patient</a> | <a href={collection_url}>View this Collection</a></p>
            </div>
            <a className="btn btn-success btn-lg" href={preview_url}>See Preview and Print</a>
          </div>
        </div>
      </div>
    )
  }

  renderNav() {
    let {current_tab} = this.state;
    return (
      <ul className="nav nav-pills nav-stacked light-nav">
        {Object.keys(TABS).map(tab_id => {
          let {label} = TABS[tab_id];
          let className =  (current_tab === tab_id) ? 'active' : null;
          let anchor = '#' + current_tab
          return <li key={tab_id} role="presentation" className={className}><a href={anchor} onClick={e => this.selectTab(tab_id)}>{label}</a></li>
        })}
      </ul>
    )
  }

  renderContent() {
    let {current_tab, report, collection_trial_id, submitting, errors} = this.state;
    let props = {report, submitting, errors, onChange: this.onChange, submit: this.submit}
    if (current_tab === 'collection_trial') {
      let {collection_trials, patient} = this.props;
      let collection_trial = collection_trials.find(c => c.id === collection_trial_id);
      return <CollectionTrialTab collection_trial={collection_trial} patient={patient} {...props} />
    }

    let {component} = TABS[current_tab];
    return React.createElement(component, props);
  }


  renderMarkdownGuide() {
    let {show_markdown_guide} = this.state;

    let content;
    if (show_markdown_guide) {
      content = (
        <div id="markdown-guide-holder">
          <div className="markdown-example">
            <h4>If you enter:</h4>
            <p>**bold text** between 2 asterisks.</p>
            <p>*italic text* between 1 asterisk.</p>
            <p>***bold and italic text*** between 3 asterisks</p>
            <hr/>
            <p>[A link](https://www.google.com/) will create a link.</p>
            <hr/>
            <p>You can create a list with hyphens:</p>
            <p>- Item Number One</p>
            <p>- Item Number Two</p>
            <p>- Item Number Three</p>
          </div>
          <div className="markdown-example">
            <h4>You will get:</h4>
            <p><b>bold text</b> between 2 asterisks.</p>
            <p><i>italic text</i> between 1 asterisk.</p>
            <p><b><i>bold and italic text</i></b> between 3 asterisks</p>
            <hr/>
            <p><a href="https://www.google.com/">A link</a> will create a link.</p>
            <hr/>
            <p>You can create a list with hyphens:</p>
            <ul>
              <li>Item Number One</li>
              <li>Item Number Two</li>
              <li>Item Number Three</li>
            </ul>
          </div>
        </div>
      );
    }

    return (
      <div>
        <h5 className="markdown-guide-header"><button className="btn btn-link" onClick={e => this.setState({show_markdown_guide: !show_markdown_guide})}><b>See Markdown Guide</b></button></h5>
        {content}
      </div>
    );
  }

  render() {
    return (
      <div id="patient_report_config">
        {this.renderHeader()}
        <div className="row">
          <div className="col-md-3">
            <div className="report_config_sidebar">
              {this.renderNav()}
              <h4 className="siderbar_nav_header">Patient Options</h4>
              {this.renderResultsPicker()}
              <p className="sidebar_note">To adjust options and the order in which they appear, please update the collection.</p>

              {this.renderMarkdownGuide()}
            </div>
          </div>
          <div className="col-md-9">
            <div className="report_config_content_box">
              {this.renderContent()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}


class ContentComponent extends Form.FormField {
  constructor(props) {
    super(props);
    this.changeData = this.changeData.bind(this);
  }

  changeData(data) {
    let {onChange} = this.props;
    let meta = {value_label: ''};
    onChange({target: {name: 'data', value: data}}, meta);
  }

  renderInput() {
    let {field, model, embedded} = this.props;
    let fields = [{name: 'content', type: 'checkboxes', collection: REPORT_CONTENT}];
    return <Form containerElement="div" fields={fields} model={model.data} onChange={this.changeData} />
  }
}

class TabGeneral extends Component {
  constructor(props) {
    super(props);
    this.onChangeField = this.onChangeField.bind(this);
  }

  onChangeField(field, value) {
    let {report, onChange} = this.props;
    report[field] = value;

    if (field === 'partner_id' && value === '1') {
      if (!report.partner_first_contact || report.partner_first_contact.length === 0) {
        report.partner_first_contact = DEFAULT_FIRST_CONTACT;
      }

      if (!report.partner_second_contact || report.partner_second_contact.length === 0) {
        report.partner_second_contact = DEFAULT_SECOND_CONTACT;
      }
    }
    onChange(report, true);
  }

  fields() {
    return [
      {name: 'report_type', label: 'Type of Report', required: true, type: 'select', collection: gon.reportTypes},
      {name: 'partner_id', type: 'select', collection: PARTNERS, blankLabel: 'Sagely Health', visible: c => c.report_type === 'pathfinder'},
      {name: 'partner_first_contact', type: 'text', label: 'First Contact (Partner)', visible: c => c.report_type === 'pathfinder' && c.partner_id === '1'},
      {name: 'partner_second_contact', type: 'text', label: 'Second Contact (Sagely Health)', visible: c => c.report_type === 'pathfinder' && c.partner_id === '1'},
      {name: 'title', label: 'Custom Title'},
      {name: 'wamberg_contact', type: 'select', collection: WAMBERG_CONTACTS, visible: c => c.report_type === 'wamberg'},
      {name: 'date', type: 'date', hint: 'Leave this blank to use date PDF is exported.'},
      {name: 'summary', label: 'Patient Cancer Type One Line Summary'},
      {name: 'willingness_to_travel', type: 'text', hint: 'Optional. Will appear below patient summary if provided.'},
      {name: 'insurance_information', type: 'text', hint: 'Optional. Will appear below patient summary if provided.'},
      {name: 'investigative_results', label: 'Cancer Expert Introduction to Investigative Results', type: 'markdown', hint: 'This text will appear above the listing of options included in the report. USE PREVIEW TO CHECK MARKDOWN'},
      {name: 'content', type: ContentComponent},
    ];
  }

  render() {
    let {report, submit, submitting, errors} = this.props;

    return (
      <div id={report.id}>
        <h2 className="config_section_header">General</h2>
        <div className="config_general_form_holder">
          <Form model_name="Report" model={report} fields={this.fields()} onChangeField={this.onChangeField} onSubmit={submit} submitting={submitting} errors={errors} />
        </div>
      </div>
    )
  }
}



class TabGlossary extends Component {
  render() {
    return (
      <div>
        <h2 className="config_section_header">Glossary</h2>
        <p>The glossary is currently hardcoded, but may be customizable (or automatted) in the future.</p>
        <p>You can decide whether or not to include a glossary for this report.</p>
        <hr/>
        <p>If there is a mistake with the glossary items that are appearing in reports, please let the tech team know.</p>
      </div>
    )
  }
}


const TABS = {
  general: {label: 'General', component: TabGeneral},
  next_steps: {label: 'Next Steps', component: TabNextSteps},
  questions: {label: 'Questions', component: TabQuestions},
  glossary: {label: 'Glossary', component: TabGlossary}
};


const REPORT_CONTENT = {
  our_approach: 'Include "Our Approach" section',
  next_steps: 'Include Next Steps',
  questions: 'Include Questions',
  glossary: 'Include Glossary',
  patient_summary: 'Include Patient Summary'

};

const WAMBERG_CONTACTS = {
  irene_guterman: 'Irene Guterman, MS, RN, CNL',
  jessica_olsen: 'Jessica Olsen, RN, BSN, OCN'
};

const PARTNERS = [
  {id: 1, name: 'Priority Physicians'}
]

const DEFAULT_FIRST_CONTACT = '**Jane Doe**\n555-555-55555\njane.doe@email.com';
const DEFAULT_SECOND_CONTACT = '**Sagely Health**\ninfo@sagelyhealth.com\n1 (844) 724-3591';
