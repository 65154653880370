import React, {Component} from 'react';

import Form from './journey_tracker_form';
import JourneyRecord from './journey_record';
import OptionsCache from './options_cache';

export default class Pathology extends JourneyRecord {
  constructor(props) {
    super(props);
    this.state = {show_changelog: false}
    this.onChange = this.onChange.bind(this);
    this.cancerTypeCache = new OptionsCache({source: 'patient_cancer_types', onChange: e => this.forceUpdate()})
  }

  getTitle() { return 'Pathology'; }
  getBasePath() {
    let {diagnosis} = this.props;
    return 'diagnoses/[id:' + diagnosis.id + ']/pathology';
  }

  getSubtitle() {
    let {diagnosis} = this.props;
    let patient_cancer_type = this.cancerTypeCache.get(diagnosis.patient_cancer_type_id);
    return <small>{patient_cancer_type.text}</small>;
  }

  getSectionLabel() {
    let {diagnosis} = this.props;
    let patient_cancer_type = this.cancerTypeCache.get(diagnosis.patient_cancer_type_id);
    return ['Pathology', patient_cancer_type.text];
  }

  formFields() {
    let {diagnosis} = this.props;
    let patient_cancer_type = this.cancerTypeCache.get(diagnosis.patient_cancer_type_id);
    let {staging_options, tumor_sizes, nodal_spreads, metastases, grades} = patient_cancer_type;

    return [
      {name: 'invasion_of_nearby_structure', type: 'select', collection: ['yes', 'no', 'unknown'], labelTransform: 'titleize'},
      {name: 'margins', type: 'select', collection: ['positive', 'negative'], labelTransform: 'titleize',  blankLabel: 'N/A'},
      {name: 'tumor_details', label: 'Tumor (T) Details', type: 'select', collection: ['Tis (Carcinoma in situ)', 'T1', 'T2', 'T3', 'T4a', 'T4b'], blankLabel: 'Unknown'},
      {name: 'histology_type', type: 'text'},
      {name: 'histology_grade', type: 'text'},
      {name: 'archival_tissue_available', label: 'Archival Tissue Available?', type: 'select', collection: ['yes', 'no', 'unknown'], labelTransform: 'titleize'}
    ];
  }


  renderForm() {
    let {diagnosis, readOnly} = this.props;
    return <Form fields={this.formFields()} model={diagnosis.pathology} onChange={this.onChange} readOnly={readOnly} />
  }

}
