import React, {Component} from 'react';
import TrialShowEmbedded from './trial_show_embedded';

import request from '../request';
import Loading from './loading';


export default class InterventionShow extends Component {
  constructor(props) {
    super(props);
    this.state = {tab: 'trials'}
  }

  selectTab(e, tab) {
    e.preventDefault();
    this.setState({tab});
  }

  renderCompanies() {
    let {intervention} = this.props;
    return (
      <div>
      Companies: {intervention.companies.map(company => {
        let link = `/admin/companies/${company.id}`;
        return (
          <a key={company.id} href={link} target="_blank">{company.name}</a>
        )
      })}
      </div>
    )
  }

  renderContent() {
    let {intervention} = this.props;
    let {tab} = this.state;

    if (tab === 'trials') {
      return <InterventionTrials intervention={intervention} />
    } else {
      return <InterventionReferences intervention={intervention} />
    }
  }

  renderTabs() {
    let {tab} = this.state;
    let tabs = [{id: 'trials', label: 'Trials'}, {id: 'references', label: 'References'}];

    return (
      <ul className="nav nav-tabs">
        {tabs.map(({id, label}) => {
          let className = tab === id ? 'active' : ''
          return <li key={id} role="presentation" className={className}><a href='#'  onClick={e => this.selectTab(e, id)}>{label}</a></li>
        })}
      </ul>
    )
  }

  render() {
    let {intervention} = this.props;

    let populations_link = `/admin/interventions/${intervention.id}/populations`;
    return (
      <div>
        <h1>{intervention.name}</h1>
        <div>
          <span className="badge">{intervention.major_class_name}</span>
          <span>MOA: {intervention.moa_type}</span>
        </div>

        <div>{intervention.description}</div>
        {this.renderCompanies()}
        <div>
         Tagged in <a href={populations_link}>{intervention.populations_count} populations</a>
        </div>
        {this.renderTabs()}
        {this.renderContent()}
      </div>
    );
  }
}


class InterventionTrials extends Component {

  constructor(props) {
    super(props);
    this.state = {page: 1, order: 'desc', hide_without_references: false, hide_standard_therapies: true};
    this.fetchTrials = this.fetchTrials.bind(this);
  }

  componentDidMount() {
    this.fetchTrials();
  }

  fetchTrials() {
    let {intervention} = this.props;
    let {page, order} = this.state;
    let url = `/admin/interventions/${intervention.id}/trials.json?page=${page}&order=${order}`;
    this.setState({loading: true});
    request('GET', url)
      .then(new_trials => {
        let {trials} = this.state;
        if (!trials) trials = [];
        trials = trials.concat(new_trials);
        this.setState({trials, loading: false});
      }).catch(error => {
        this.setState({loading: false});
      });
  }

  loadNext() {
    let page = this.state.page + 1;
    this.setState({page}, this.fetchTrials);
  }

  setOrder(order) {
    if (order === this.state.order) return;
    this.setState({order, page: 1, trials: null}, this.fetchTrials);
  }

  changeCheckbox(e) {
    let {name, checked} = e.target;
    this.setState({[name]: checked});
  }

  renderTrials() {
    let {intervention} = this.props;
    let {trials, hide_without_references, hide_standard_therapies} = this.state;
    if (!trials) return null;

    return (
      <div id="trials_list">
        {trials.map(trial => {
          if (hide_without_references && (!trial.references || trial.references.length === 0)) return null;
          if (hide_standard_therapies && trial.is_standard_therapy) return null;
          return <TrialShowEmbedded key={trial.id} trial={trial} intervention={intervention} />
        })}

        {this.renderLoadMore()}
      </div>
    )
  }

  renderFilters() {
    let {order, hide_without_references, hide_standard_therapies} = this.state;
    let desc_btn_class = order === 'desc' ? 'selected' : ''
    let asc_btn_class = order === 'asc' ? 'selected' : ''

    return (
      <div id="filters">
        <div id="order">
          <span>Sort By:</span>
          <button className={"btn btn-link " + desc_btn_class} onClick={e => this.setOrder('desc')}>Newer Trials</button>
          <button className={"btn btn-link " + asc_btn_class}  onClick={e => this.setOrder('asc')}>Older Trials</button>
        </div>

        <div id="visibility">
          <div className="checkbox">
            <label>
              <input type="checkbox" name="hide_without_references" value={hide_without_references} checked={hide_without_references} onChange={e => this.changeCheckbox(e)}/> Hide Trials without References
            </label>
          </div>

          <div className="checkbox">
            <label>
              <input type="checkbox" name="hide_standard_therapies" value={hide_standard_therapies} checked={hide_standard_therapies} onChange={e => this.changeCheckbox(e)}/> Hide Standard Therapies
            </label>
          </div>
        </div>
      </div>
    )
  }

  renderLoadMore() {
    let {intervention} = this.props;
    let {trials} = this.state;
    if (!trials || intervention.trials_count === trials.length) return null;
    return (
      <div>
        <button className="btn btn-default" onClick={e => this.loadNext()}>Load More</button>
      </div>
    )
  }

  renderLoading() {
    let {loading} = this.state;
    if (!loading) return null;
    return <Loading />
  }

  render() {
    let {intervention} = this.props;
    let link_to_trials = `/admin/interventions/${intervention.id}/trials`;
    return (
      <div id="intervention-trials">
        <div>
          <a href={link_to_trials} className="pull-right">Show all Trials</a>
          <h2>Trials</h2>
        </div>

        {this.renderFilters()}
        {this.renderTrials()}
        {this.renderLoading()}
      </div>
    );
  }
}

class InterventionReferences extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.fetchReferences = this.fetchReferences.bind(this);
  }

  componentDidMount() {
    this.fetchReferences();
  }

  fetchReferences() {
    let {intervention} = this.props;
    let url = `/admin/interventions/${intervention.id}/references.json`;
    this.setState({loading: true});
    request('GET', url)
      .then(references => {
        this.setState({references, loading: false});
      }).catch(error => {
        this.setState({loading: false});
      });
  }



  copyToClipboard() {
    let content = this.renderContent();
    navigator.clipboard.writeText(content);
  }

  renderContent() {
    let {references} = this.state;
    let buffer = "";
    for (let reference of references) {
      if (reference.abstract_text && reference.abstract_text.length > 0) {
        buffer += `${reference.name} ${reference.reference_year} \n------------------------------------------------\n`;
        buffer += `${reference.abstract_text}\n\n`;
      }
    }
    return buffer;

  }

  render() {
    let {loading, references} = this.state;
    if (loading || !references) return <Loading />

    return (
      <div id="intervention-references">
        <h2>References</h2>
        <button onClick={e => this.copyToClipboard()}>Copy all abstracts to clipboard</button>
        <div>
          {references.map(reference => {
            let reference_link = `/admin/references/${reference.id}/edit`;
            let abstract_el = (reference.abstract_text && reference.abstract_text.length > 0) ? <div><label>Abstract</label><pre className="formatted">{reference.abstract_text}</pre></div> : null;
            return (
              <div key={reference.id} className="reference">
                <h4><a href={reference_link}>{reference.name} {reference.reference_year}</a></h4>

                <a href={reference.source_link} target="_blank">{reference.source_link}</a>
                {abstract_el}
                <hr />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
