import React, {Component} from 'react';

import Form from './journey_tracker_form';
import JourneyRecord from './journey_record';
import OptionsCache from './options_cache';

export default class PatientCancerTypeTraits extends JourneyRecord {
  constructor(props) {
    super(props);
    this.state = {show_changelog: false}
    this.onChange = this.onChange.bind(this);
    this.cancerTypeCache = new OptionsCache({source: 'patient_cancer_types', onChange: e => this.forceUpdate()})
  }

  getTitle() { return 'Custom Cancer Details'; }
  getBasePath() {
    let {diagnosis} = this.props;
    return 'diagnoses/[id:' + diagnosis.id + ']/traits';
  }

  getSubtitle() {
    let {diagnosis} = this.props;
    let patient_cancer_type = this.cancerTypeCache.get(diagnosis.patient_cancer_type_id);
    return <small>{patient_cancer_type.text}</small>;
  }

  getSectionLabel() {
    let {diagnosis} = this.props;
    let patient_cancer_type = this.cancerTypeCache.get(diagnosis.patient_cancer_type_id);
    return ['Custom Cancer Details', patient_cancer_type.text];
  }

  formFields() {
    let {diagnosis, readOnly} = this.props;
    let patient_cancer_type = this.cancerTypeCache.get(diagnosis.patient_cancer_type_id);
    let traits = patient_cancer_type.traits || [];

    return traits.map(trait => {
      if (trait.type === 'multiple') {
        return {name: trait.id, label: trait.name, type: 'checkboxes', collection: trait.options, labelField: 'text'}
      } else if (trait.type === 'single') {
        return {name: trait.id, label: trait.name, type: 'radio', collection: trait.options, labelField: 'text'}
      } else {
        return {name: trait.id, label: trait.name, type: 'text'}
      }
    });
  }
  renderForm() {
    let {diagnosis, readOnly} = this.props;
    return <Form fields={this.formFields()} model={diagnosis.traits} onChange={this.onChange} readOnly={readOnly} />
  }
}
