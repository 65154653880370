import React, {Component} from 'react';
import Form from './form';
import request from '../request';
import {Countries, States, PersonRoles, CancerCenterSearchKey} from '../constants.js.erb';

export default class PersonForm extends Component {
  constructor(props) {
    super(props);
    let {person} = props;
    this.state = {person, errors: {}};
  }

  static getDerivedStateFromProps(props, state) {
    state.person = props.person;
    state.errors = {};
    return state;
  }

  submit(e) {
    e.preventDefault();
    this.setState({submitting: true});
    let {person} = this.state;
    let {onSubmit} = this.props;
    let url = "/admin/persons";
    let method;


    if (person.id) {
      url += "/" + person.id;
      method = "PUT"
    } else {
      method = "POST"
    }

    let model = {};
    for (let field of this.formFields()) {
      model[field.name] = person[field.name];
    }

    request(method, url, {person: model}).then((person) => {
      if (onSubmit) {
        onSubmit(person);
      } else {
        window.location = "/admin/persons"
      }
      this.setState({errors: [], submitting: false});
    }).catch(({errors}) => {
      this.setState({errors, submitting: false});
    });
  }


  onChange(person) {
    this.setState({person});
  }

  formFields() {
    return [
      {name: 'name', required: true},
      {name: 'aliases', type: 'text'},
      {name: 'titles'},
      {name: 'phone', label: 'Office Phone'},
      {name: 'cell_phone'},
      {name: 'fax'},
      {name: 'email'},
      {name: 'url'},
      {name: 'address', type: 'text'},
      {name: 'city'},
      {name: 'zip'},
      {name: 'year_of_graduation', label: 'Year of Graduation from Medical School', type: 'number'},
      {name: 'department'},
      {name: 'country_code', label: 'Country', collection: countries},
      {name: 'state', collection: states, visible: (p => p.country_code === 'US')},
      {name: 'notes', type: 'text'},
      {name: 'roles', collection: PersonRoles, type: 'select2-tags', multiple: true},
      {name: 'cancer_center_ids', label: 'Cancer Center', multiple: true, type: 'remote-select2', src: "/admin/cancer_centers/as_options.json", search_key: CancerCenterSearchKey},
      {name: 'tags', type: 'select2-tags', multiple: true}
    ]
  }

  render() {
    let {person, errors, submitting} = this.state;
    let {onCancel} = this.props;
    return <Form model_name="Person" prefix="person" fields={this.formFields()} model={person} errors={errors} submitting={submitting} onChange={r => this.onChange(r)} onSubmit={e => this.submit(e)} onCancel={onCancel} />
  }
}

const countries = buildCollection(Countries);
const states = buildCollection(States);
function buildCollection(list) {
  let all = {};
  for (let l of list) {
    all[l.id] = l.name;
  }
  return all;
}
