import React, {Component} from 'react';
import PatientTrialData from '../patient_trial_data';

import request from '../../request';
import Loading from '../loading';

export default class TrialData extends Component {
  constructor(props) {
    super(props);
    this.state = {loading: true};
  }

  componentDidMount() {
    this.fetchPatientTrialData();
  }

  fetchPatientTrialData() {
    let {patient_id, trial_id} = this.props;

    let url = `/admin/patients/${patient_id}/trials/${trial_id}/patient_widget_data`;
    request('GET', url).then(patient_trial_data => {
      let {patient, trial_data, therapy_type, trial_locations, cancer_centers, trial_groups, people} = patient_trial_data;
      this.setState({patient, trial_data, therapy_type, trial_locations, cancer_centers, trial_groups, people, loading: false});
    }).catch(e => {
      this.props.onClose();
    });
  }


  render() {
    let {patient, trial_data, therapy_type, trial_locations, cancer_centers, trial_groups, people, loading} = this.state;
    if (loading) return <Loading />;
    let {trial_id, current_admin_id, onClose} = this.props;

    let data = {patient, trial_data, therapy_type, trial_locations, cancer_centers, trial_groups, people, trial_id, current_admin_id}
    return(
      <div id="patient_trial_data">
        <div className="widget_collection_details_header">
          <button className="btn btn-sm btn-default"onClick={onClose}><i className="fa fa-chevron-left" /> Go Back</button>
          <h4 className="patient_widget_header_text">{patient.name}</h4>
        </div>
        <PatientTrialData {...data}  />
      </div>

    )

  }
}
