import jquery from 'jquery';
import PatientWidget from './components/patients_widget';

export default {
  setup: function() {
    jquery( document ).ready(function() {
      if (jquery('#patient_widget_select_patient').length > 0) {
        let patient_id = jquery('#patient_widget_select_patient').data('patient');
        PatientWidget.setPatient(patient_id);
      }
    });
  }
}
