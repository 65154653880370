import React, {Component} from 'react';

import JourneyTable from './journey_table';
import NeestedRecord from './neested_record';
import OptionsCache from './options_cache';
import Form from './journey_tracker_form';
import * as DateHelpers from './date_helpers';
import * as Inflector from 'inflected';
import {globalDefaults} from './journey_data';
import {FindingSearchKey, OrganSearchKey} from '../../constants.js.erb';
import {FindingStatusModal, FindingStatus} from './finding_status';

export default class Findings extends JourneyTable {
  constructor(props) {
    super(props);
    this.state = {};
    this.findingCache = new OptionsCache({source: 'findings', onChange: e => this.forceUpdate()});
    this.organCache = new OptionsCache({source: 'organs', onChange: e => this.forceUpdate()});
  }

  addFinding() {
    let {data, settings} = this.props;
    let current = globalDefaults(defaulFinding(), data, settings);
    this.setState({current: current});
  }

  delete(finding) {
    if (!this.validateDeletion('finding_id', finding.id)) return;
    if (!confirm('Are you sure?')) return;

    let {onDelete} = this.props;
    let sectionLabel = buildSectionLabel(finding, this.findingCache);
    onDelete(BASE_PATH, sectionLabel, finding);
  }


  show(current) {
    this.setState({current});
  }

  renderForm() {
    let {patient, data, fields_changelog, onAddBatch, onEditBatch, readOnly} = this.props;
    let {current} = this.state;

    let props = {readOnly, patient, data, fields_changelog, onAddBatch, onEditBatch, record: current};
    props.onClose = e => this.setState({current: null});

    return <Finding {...props} />
  }

  renderList() {
    let {data, readOnly, patient, fields_changelog, onAddBatch, onEditBatch} = this.props;
    let {findings} = data;

    return (
      <div>
        <button className="btn btn-success pull-right" onClick={e => this.addFinding()} disabled={readOnly}>Add Finding</button>
        <div className="clearfix"></div>
        <p><b>Findings</b></p>
        <table className="table table-condensed table-striped jt-log-table" id="findings_list">
          <thead>
            <tr>
              <th>Finding</th>
              <th>Organ</th>
              <th>Date Reported</th>
              <th>Treating?</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {findings.map(finding => {
              let action_label = readOnly ? 'Show' : 'Edit';
              let finding_text = this.findingCache.get(finding.finding_id).text;
              let organ_text = finding.organ_id ? this.organCache.get(finding.organ_id).text : null;

              let treatement = finding.treatement ? Inflector.titleize(finding.treatement) : null;
              let status_count = finding.status.length;

              let delete_el;
              if (!readOnly) {
                let id = "delete_finding-" + finding.id;
                delete_el = <button id={id} className="btn btn-default btn-sm" onClick={e => this.delete(finding)}><i className="fa fa-trash"></i></button>
              }

              return(
                <tr key={finding.id}>
                  <td>{finding_text}</td>
                  <td>{organ_text}</td>
                  <td>{DateHelpers.formatEstimated(finding, {field: 'date_reported'})}</td>
                  <td>{treatement}</td>
                  <td>
                    <FindingStatusModal readOnly={readOnly} model={finding} embedded={true} field={{patient, fields_changelog, callbacks: {onAddBatch, onEditBatch}}}/>
                  </td>
                  <td className="actions">
                    <div className="btn-group">
                      <button className="btn btn-default btn-sm" onClick={e => this.show(finding)}>{action_label}</button>
                      {delete_el}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    );

  }
}

const BASE_PATH = 'findings';
function buildSectionLabel(record, findingCache) {
  let finding = findingCache.get(record.finding_id);
  return ['Finding', finding.text];
}

class Finding extends NeestedRecord {
  constructor(props) {
    super(props);
    this.findingCache = new OptionsCache({source: 'findings', onChange: e => this.forceUpdate()});
    this.organCache = new OptionsCache({source: 'organs', onChange: e => this.forceUpdate()});
  }

  getModelName() {
    return "Finding";
  }

  getBasePath() {
    return BASE_PATH;
  }

  getSectionLabel() {
    let {record} = this.state;
    return buildSectionLabel(record, this.findingCache);
  }

  onChange(field_name, value, meta) {
    super.onChange(field_name, value, meta);
    let {record} = this.state;
    if (record.finding_id) {
      this.findingCache.preload([record.finding_id]);
    }
  }

  formFields() {
    let {data} = this.props;

    let FindingValueLabelCallback = l => {
      let finding = this.findingCache.get(l.finding_id);
      return finding.text;
    };

    let OrganValueLabelCallback = l => {
      let organ = this.organCache.get(l.organ_id);
      return organ.text;
    };

    let interventions = data.interventions.map(i => {
      let intervention = this.interventionCache.get(i.intervention_id);
      return {id: i.id, name: intervention.text}
    });

    return [
      {name: 'date_reported', type: 'date', required: true},
      {name: 'finding_id', required: true, type: 'remote-select2', src: "/admin/findings/as_options.json", search_key: FindingSearchKey, valueLabelCallback: FindingValueLabelCallback},
      {name: 'organ_id', type: 'remote-select2', src: "/admin/organs/as_options.json", search_key: OrganSearchKey, valueLabelCallback: OrganValueLabelCallback},
      {name: 'details', type: 'text'},
      {name: 'treatement', type: 'select', collection: ['yes', 'no', 'unknown'], labelTransform: 'titleize'},
      {name: 'treating_intervention', type: 'select', collection: interventions, idField: 'id', labelField: 'name', hint: 'Interventions added for patient will appear here.'},
      {name: 'assessment', label: 'Assessment/Interpretation', type: 'text'},
      {name: 'is_tumor_log', label: 'Should appear in Tumor Log', type: 'bool'}
    ];
  }
}

function defaulFinding() {
  return {status: []};
}


Findings.Finding = Finding;
