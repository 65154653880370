import React, {Component} from 'react';
import JoinReact from '../join_react';

export default class PopulationSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {active_panels: {}};
  }

  toggleSection(section) {
    let {active_panels} = this.state;
    active_panels[section] = !active_panels[section];
    this.setState({active_panels})
  }

  renderSummaryContent(key, data) {
    let {active_panels} = this.state;
    let keys = Object.keys(data).filter(k => ['type', 'section_title_label', 'section_title_value'].indexOf(k) === -1);

    return keys.map(k => {
      let item = data[k];

      let value, style;
      if (item.style) {
        style = 'population_style_' + item.style;
      }
      if (item.value) {
        value = <td className='population-summary-value' dangerouslySetInnerHTML={{__html: item.value}}></td>
      } else if (active_panels[key]) {
        value = <td className='population-summary-empty-value'>None</td>
      } else {
        return null;
      }


      return (
        <tr key={k} className={style}>
          <td className='population-summary-label'><span dangerouslySetInnerHTML={{__html: item.label}}></span>: </td>
          {value}
        </tr>
      )
    });
  }

  renderSummary(section, section_id) {
    let {population, hide_links, selectTab} = this.props;
    let {data} = section;
    let key = section.key || section_id;
    let id = "summary_for_" + (key);
    let link;
    if (!hide_links) {
      link = <button className="btn btn-btn-secondary btn-sm" onClick={e => selectTab(e, section_id)}><i className="fa fa-pencil-alt"/></button>
    }

    return (
      <tbody key={key} id={id} >
        <tr className='population-summary-header'>
          <th className='population-summary-title clickable' onClick={e => this.toggleSection(key)}>{data.section_title_label}:</th>
          <th className='population-summary-title-value clickable' onClick={e => this.toggleSection(key)}>{data.section_title_value}</th>
          <th className='population-summary-actions'>{link}</th>
        </tr>
        {this.renderSummaryContent(key, data)}
      </tbody>
    );
  }

  renderCancerDetails({data}) {
    let el = [];

    if (Object.getOwnPropertyNames(data.cancer_category).length > 0) {
      el.push(this.renderSummary({data: data.cancer_category, key: "cancer_category"}, 'cancer_types'));
    }

    if (Object.getOwnPropertyNames(data.tumor_type).length > 0) {
      el.push(this.renderSummary({data: data.tumor_type, key: "tumor_type"}, 'cancer_types'));
    }

    if (Object.getOwnPropertyNames(data.cancer_type).length > 0) {
      for (let i=0; i < data.cancer_type.types.length; i++) {
        el.push(this.renderSummary({data: data.cancer_type.types[i], key: "cancer_type_" + i}, 'cancer_types'));
      }

      for (let i=0; i < data.cancer_type.tumor_types.length; i++) {
        el.push(this.renderSummary({data: data.cancer_type.tumor_types[i], key: "cancer_tumor_type_" + i}, 'cancer_types'));
      }
    }


    return el;
  }

  renderTable() {
    let {population} = this.props;
    let summary = population.summary;

    return (
      <table className="table population-summary">
        {this.renderCancerDetails(summary.cancer_types)}
        {this.renderSummary(summary.patient_details, 'patient_details')}
        {this.renderSummary(summary.prior_therapy, 'prior_therapy')}
        {this.renderSummary(summary.recent, 'recent')}
        {this.renderSummary(summary.concurrent, 'concurrent')}
        {this.renderSummary(summary.genes_diagnostics, 'genes_markers')}
        {this.renderSummary(summary.notes, 'notes')}
      </table>
    )
  }


  renderEditors() {
    let {population} = this.props;
    if (!population.editors || population.editors.length === 0) return null;
    let editors = population.editors.map(admin => {
      let link = '/admin/admins/' + admin.id;
      return <a key={admin.id} href={link}>{admin.name}</a>
    });

    return (
      <div>
        <h3>Edited by</h3>
        {JoinReact(editors, <span>, </span>)}
      </div>
    );
  }
  render() {
    return (
      <div>
        {this.renderTable()}
        {this.renderEditors()}
      </div>
    )
  }
}
