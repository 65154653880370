import jquery from 'jquery';
import request from 'request';

export default {
  setup: () => {
    jquery( document ).ready(function() {
      if (jquery('body#references_new_duplicate').length  === 0) return;



      let alert_el = document.getElementById('name_exists');
      let input = document.getElementById('reference_name');
      input.addEventListener('input', e => {
        let {value} = e.target;

        if (debounce_pid) clearTimeout(debounce_pid);

        let callback = () => {
          checkUniqueness(value, ({exists}) => {
            if (exists) {
              alert_el.style.display="block";
            } else {
              alert_el.style.display="none";
            }
          });
          debounce_pid = undefined;
        };

        debounce_pid = setTimeout(callback, DEBOUNCE_TIMEOUT);
      });

    });
  }
}

const DEBOUNCE_TIMEOUT = 300;
const CHECK_DUPLICATE_URL = '/admin/references/name_exists';
var debounce_pid;

function checkUniqueness(name, callback) {
  request('POST', CHECK_DUPLICATE_URL, {value: name}).then(callback);
}
