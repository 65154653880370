import React, {Component} from 'react';
import Form from '../form';
import ItemsCollection from '../items_collection';
import SavePanel from './save_panel';
import NotesWidget from './notes_widget';
import request from '../../request';
import * as Inflector from 'inflected';
import {POPULATION_DATA_GROUPS, POPULATION_INCLUSSION_OPTIONS} from '../../constants.js.erb';

export default class SectionWithItems extends Component {
  constructor(props) {
    super(props);

    let section_data = this.sectionData();
    this.state = {section_data};
    this.submit = this.submit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    window.addEventListener('population-save-request', this.submit, false);
  }

  componentWillUnmount() {
    window.removeEventListener('population-save-event', this.submit, false);
  }

  onChange(section_data) {
    console.log("calling on change");
    this.setState({section_data}, this.submit);
  }


  renderSavePanel() {
    let icon, text, style, action;
    let {submitting, save_success, save_error} = this.state;
    return <SavePanel submitting={submitting} save_success={save_success} save_error={save_error} onClick={e => this.submit()}/>
  }

  onChangeItems(key, inclussion, items) {
    let {section_data} = this.state;
    section_data[key][inclussion] = items
    this.setState({section_data}, this.submit);
  }

  onChangeRadio(key, inclussion, e) {
    let {section_data} = this.state;
    let {value} = e.target;
    let inclussion_key = inclussion + '_validation';
    section_data[key][inclussion_key] = value;
    this.setState({section_data}, this.submit);
  }

  onResetRadio(e, key, inclussion) {
    e.preventDefault();
    let {reseteable} = this.props;
    if (!reseteable) return;

    let {section_data} = this.state;
    let inclussion_key = inclussion + '_validation';
    section_data[key][inclussion_key] = undefined;
    this.setState({section_data}, this.submit);
  }

  renderGroup(key, label, inclussion, sources) {
    let {section_data} = this.state;
    let data = section_data[key][inclussion] || [];

    let inclussion_key = inclussion + '_validation';
    let inclussion_label = Inflector.titleize(inclussion) + ' Validation';
    let inclussion_options = inclussion === 'required' ? REQUIRED_OPTIONS : PROHIBITED_OPTIONS;
    let all_selected = section_data[key][inclussion_key] === 'all';

    return (
      <div key={key + inclussion} className="field-group-holder">
        <ItemsCollection inputLabel={label} items={data} onChange={i => this.onChangeItems(key, inclussion, i)} sources={sources} destination='population' createLinks candidatesDecorator={ItemsCollection.MOAFamilyDecorator} />


        <div className="form-group">
          <label className="control-label">  {inclussion_label}</label>
          {Object.keys(inclussion_options).map(inclussion_option => {
            let checked = section_data[key][inclussion_key] == inclussion_option;
            let label = inclussion_options[inclussion_option];

            return (
              <div className="radio" key={key + inclussion + inclussion_option}>
                <label onDoubleClick={e => this.onResetRadio(e, key, inclussion)}>
                  <input type="radio" value={inclussion_option} checked={checked} onChange={e => this.onChangeRadio(key, inclussion, e)} />{label}
                </label>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  renderGroupedInputs() {
    let items = []
    return (
      <div className="row">
        <div className="col-md-6">
          <h4>Required</h4>
          {POPULATION_DATA_GROUPS.map(({key, label, sources}) => {
            return this.renderGroup(key, label, 'required', sources);
          })}
        </div>

        <div className="col-md-6">
          <h4>Prohibited</h4>
          {POPULATION_DATA_GROUPS.map(({key, label, sources}) => {
            return this.renderGroup(key, label, 'prohibited', sources);
          })}
        </div>

      </div>
    );
  }


  render() {
    let {section_data} = this.state;
    let {title, subtitle} = this.sectionDetails();
    return (
      <div>
        <a name="tab-top-section"></a>
        <div className="well label-spacing select-100">

          <div className="intervention_selection_holder prior_selections">
            <div className="tab-section-holder">
              <div>
                <h3><b>{title}</b></h3>
                <p className="population_subtitle">{subtitle}</p>
              </div>
              <a href="#tab-notes-section" className="btn btn-primary btn-xs">Jump to Notes</a>
            </div>
            {this.renderGroupedInputs()}
            <a name="tab-notes-section"></a>
            <div className="tab-section-holder">
              <NotesWidget data={section_data} onChange={this.onChange} />
              <a href="#tab-top-section" className="btn btn-primary btn-xs">Jump to Top</a>
            </div>
          </div>
        </div>
      </div>
    );

  }

}


const REQUIRED_OPTIONS = {
  all: 'Required to Have All',
  some: 'Required to Have At Least One'
}

const PROHIBITED_OPTIONS = {
  all: 'Prohibited From Having All (Can Have Some)',
  some: 'Prohibited From Having Any'
}
