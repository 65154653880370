import React, {Component} from 'react';
import {BaseEventEditor, BaseEventViewer} from './base_event';
import {RelatedToOptions} from '../../../constants.js.erb';

class MedDoctorNoteEditor extends BaseEventEditor {
  formFields() {
    let {patient} = this.props;
    return formFields(patient);
  }
}


class MedDoctorNoteViewer extends BaseEventViewer {
  formFields() {
    let {patient} = this.props;
    return formFields(patient);
  }
}


export default {
  viewer: MedDoctorNoteViewer,
  editor: MedDoctorNoteEditor
}


function formFields(patient) {
  let contacts = patient.contacts.map(c => {return {id: c.id, text: c.name}});
  let medical_team_members = patient.medical_team_members.map(m => {return {id: m.id, text: m.person.name}});

  return [
    {name: 'date', type: 'date', required: true, inSummary: true, label: 'Date Note was Received by Sagely Health'},
    {name: 'date_estimated', label: 'Date Estimated?', type: 'bool'},
    {name: 'related_to', type: 'select', collection: RelatedToOptions},
    {name: 'doctor_id', type: 'select2', collection: medical_team_members, idField: 'id', labelField: 'text', label: 'To appear here, the MD must be added to the patient.'},
    {name: 'doctor_description', type: 'text', label: 'Further details about MD'},
    {name: 'user', inSummary: true, label: 'To whom or how was this note sent to Sagely Health?'},
    {name: 'peer_type', type: 'select', collection: ['patient', 'contact', 'medical_team', 'other'], labelTransform: 'titleize', label: 'Who provided this note to Sagely Health?'},
    {name: 'contact_id', type: 'select2', collection: contacts, visible: e => e.peer_type === 'contact', idField: 'id', labelField: 'text', hint: 'To appear here, contacts must be added to the patient.'},
    {name: 'medical_team_member_id', type: 'select2', collection: medical_team_members, visible: e => e.peer_type === 'medical_team', idField: 'id', labelField: 'text', hint: 'To appear here, medical team members must be added to the patient.'},
    {name: 'description', type: 'text', inSummary: true}
  ];
}
