import React, {Component} from 'react';
import request from '../request';
import PatientPicker from './patient_picker';
import Loading from './loading';
import Form from './form';

export default class AdminCollectionModal extends Component {

  constructor(props) {
    super(props);
    this.state = {patients: []};
    this.onCopyCollection = this.onCopyCollection.bind(this);
    this.onCopyTrial = this.onCopyTrial.bind(this);
  }

  componentDidMount() {
    $(this.refs.patients_modal_container).modal({show: false});
    $(this.refs.patients_modal_container).on('hide.bs.modal', () => {
      this.setState({show_patients: false, show_collections: false});
    });

    window.addEventListener('copy-collection', this.onCopyCollection);
    window.addEventListener('copy-trial', this.onCopyTrial);
  }
  componentWillUnmount() {
    window.removeEventListener('copy-collection', this.onCopyCollection);
    window.removeEventListener('copy-trial', this.onCopyTrial);
  }

  onCopyCollection(e) {
    this.fetchPatients();
    this.setState({show_patients: true, show_collections: false, success: false});
    $(this.refs.patients_modal_container).modal('show');
  }

  onCopyTrial(e) {
    let collection_trial_id = e.detail.collection_trial_id;
    let trial_id = e.detail.trial_id
    this.setState({collection_trial_id, trial_id, show_collections: true});
    $(this.refs.patients_modal_container).modal('show');
  }

  fetchPatients() {
    this.setState({loading: true});
    let url = "/admin/patients.json";
    request('GET', url).then(patients => {
      // Filter current patient
      patients = patients.filter(p => p.id !== this.props.patient_id);

      this.setState({patients, loading: false});
    });
  }

  copyCollectionToPatient(patient) {
    let {patient_id, collection_id} = this.props;
    let url = "/admin/patients/" + patient_id + "/collections/" + collection_id + "/copy";
    request("PUT", url, {to_patient_id: patient.id}).then(r => {
      this.setState({success: true});
    });
  }


  renderPatientsPicker() {
    let {patients, success} = this.state;

    if (success) return (
      <div>
        <h3>Collection copied succesfuly</h3>
      </div>
    )

    return <PatientPicker actionLabel="Copy" onClick={p => this.copyCollectionToPatient(p)} />
  }

  renderCollectionsPicker() {
    let {collection_id, patient_id} = this.props;
    let {collection_trial_id, trial_id} = this.state;
    return <CollectionPicker patient_id={patient_id} collection_id={collection_id} collection_trial_id={collection_trial_id}  trial_id={trial_id} />
  }


  render() {
    let {show_patients, show_collections} = this.state;
    let content;
    if (show_patients) content = this.renderPatientsPicker();
    if (show_collections) content = this.renderCollectionsPicker();

    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="patients_modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              {content}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    );

  }
}


class CollectionPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {collections: []};
  }

  componentDidMount() {
    this.fetchCollections();
  }

  fetchCollections() {
    let {patient_id, collection_id} = this.props;
    this.setState({loading_collections: true});
    let url = "/admin/patients/" + patient_id + "/collections";
    request('GET', url).then(collections => {
      this.setState({collections, loading_collections: false});
    });
  }

  copyTrialToCollection(collection) {
    let {patient_id, collection_id, collection_trial_id} = this.props;
    let url = "/admin/patients/" + patient_id + "/collections/" + collection_id + "/trials/" + collection_trial_id + "/copy";
    request("PUT", url, {to_collection_id: collection.id}).then(r => {
      this.setState({success: true});
    });
  }

  updateCollection(new_collection) {
    this.setState({new_collection});
  }

  submitCollection() {
    let {new_collection} = this.state;
    let {patient_id} = this.props;
    let url = "/admin/patients/" + patient_id + "/collections";
    request("POST", url, {collection: new_collection}).then(({success, collection}) => {
      let {collections} = this.state;
      if (success) {
        collections.push(collection);
      }
      this.setState({collections, add_collection: false});
    });
  }

  addCollection() {
    this.setState({add_collection: true, new_collection: {}});
  }

  renderSuccess() {
    return (
      <div>
        <h3>Collection Trial copied succesfuly</h3>
      </div>
    )
  }


  renderForm() {
    let {new_collection} = this.state;
    let fields = [{name: 'name', required: true}];
    return <Form modelName="collection" fields={fields} model={new_collection} onChange={c => this.updateCollection(c)} onSubmit={e => this.submitCollection()} />
  }

  render() {
    let {collections, loading_collections, add_collection, success} = this.state;
    if (loading_collections) return <Loading />;
    if (add_collection) return this.renderForm();
    if (success) return this.renderSuccess();


    let {collection_id, trial_id} = this.props;
    let available_collections = collections.filter(c => c.id !== collection_id);

    return (
      <div id="collection-picker">
        <button className="btn btn-default pull-right" onClick={e => this.addCollection()}>Add Collection</button>
        <h2>Select a Collection</h2>
        <hr/>
        {available_collections.map(c => {
          let action;
          if (c.trials.findIndex(t => t.trial_id === trial_id) === -1) {
            action = <button onClick={e => this.copyTrialToCollection(c)} className="btn btn-default btn-sm">Copy</button>
          } else {
            action = <span>Already in this collection</span>
          }

          return (
            <div key={c.id} className="collection_picker_item">
              <div>{c.name}</div>
              <div>{action}</div>
            </div>
          )
        })}
      </div>
    );

  }
}
