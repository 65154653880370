import React, {Component} from 'react';
import joinReact from './join_react';

export default class TrialShowEmbedded extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderTrialTreatmentGroups(trial) {
    if (!trial.treatments_groups || trial.treatments_groups.length === 0) return (
      <div className="detail_col"><p className="subtle"><b>NO TREATMENT GROUPS</b></p></div>
    );
    return (
      <div className="detail_col">
        <p className="subtle"><b>TREATMENT GROUPS</b></p>
        <ol>
          {trial.treatments_groups.map(group => {
            let {intervention} = this.props;
            let items = group.items.map(i => <a key={i.type + "::" + i.id} href={i.link} target="_blank">{intervention && i.name === intervention.name ? <b>{i.name}</b> : i.name}</a>);
            return (
              <li key={group.id}>{joinReact(items, ', ')}</li>
            )
          })}
        </ol>
      </div>
    )
  }

  renderTrialPopulations(trial) {
    if (!trial.populations || trial.populations.length === 0) return (
      <div className="detail_col"><p className="subtle"><b>NO POPULATIONS</b></p></div>
    );
    return (
      <div className="detail_col">
        <p className="subtle"><b>POPULATIONS</b></p>
        <ol>
          {trial.populations.map(population => {
            let link_to_population = `/admin/trials/${trial.id}/populations/${population.id}`;
            return (
              <li key={population.id}>
                <a href={link_to_population} target="_blank">{population.name}</a>
              </li>
            )
          })}
        </ol>
      </div>
    )
  }

  renderTrialReferences(trial) {
    if (!trial.references || trial.references.length === 0) return (
      <div className="detail_col"><p className="subtle"><b>NO REFERENCES</b></p></div>
    );
    return (
      <div className="detail_col">
        <p className="subtle"><b>REFERENCES</b></p>
        <ol>
          {trial.references.map(reference => {
            let link_to_reference = `/admin/references/${reference.id}/edit`;
            return (
              <li key={reference.id}>
                <a href={link_to_reference} target="_blank">{reference.full_name}</a>
              </li>
            )
          })}
        </ol>
      </div>
    )
  }

  render() {
    let {trial} = this.props;

    let link_to_trial = `/admin/trials/${trial.id}`;
    let start_date_class, completion_date_class;
    if (trial.start_date_type) {
      start_date_class = trial.start_date_type.toLowerCase();
    }
    if (trial.primary_completion_date_type) {
      completion_date_class = trial.primary_completion_date_type.toLowerCase();
    }
    let trial_color_class = '';
    if (['TERMINATED', 'WITHDRAWN', 'SUSPENDED', 'UNKNOWN_STATUS'].includes(trial.overall_status)) {
      trial_color_class = 'trial_red_bg'
    } else if (['COMPLETED'].includes(trial.overall_status)) {
      trial_color_class = 'trial_blue_bg'
    } else if (['Recruiting', 'ACTIVE_NOT_RECRUITING'].includes(trial.overall_status)) {
      trial_color_class = 'trial_green_bg'
    } else if (['NOT_YET_RECRUITING'].includes(trial.overall_status)) {
      trial_color_class = 'trial_yellow_bg'
    }

    return (
      <div key={trial.id} className={`trial ${trial_color_class}`}>
        <div className="trial-left">
          <div className="dates">
            <span className={start_date_class}>{trial.start_date}</span> - <span className={completion_date_class}>{trial.primary_completion_date}</span>
          </div>
          <div>{trial.status_humanized} - {trial.overall_status_humanized}</div>
          <div><a href={link_to_trial} target="_blank">{trial.identifier}</a></div>
        </div>

        <div className="trial-right">
          <h4 className="trial_name">{trial.name}</h4>
          <div className="trial_main_details">
            {this.renderTrialPopulations(trial)}
            {this.renderTrialTreatmentGroups(trial)}
            {this.renderTrialReferences(trial)}
          </div>
        </div>
      </div>
    )
  }
}
