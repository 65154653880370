import React, {Component} from 'react';
import Form from './form';
import request from '../request';
import {InnovationTagSearchKey} from '../constants.js.erb';

export default class TrialInnovations extends Component {
  constructor(props) {
    super(props);
    let {innovation_notes, innovation_tag_ids} = props;
    this.state = {innovation_notes, innovation_tag_ids};
    this.onChange = this.onChange.bind(this);
  }

  submit() {
    this.setState({submitting: true});
    let {trial_id} = this.props;
    let {innovation_notes, innovation_tag_ids} = this.state;
    let url = '/admin/trials/' + trial_id;
    let trial = {innovation_notes, innovation_tag_ids};
    request('PUT', url, {trial}).then(trial => {
      this.setState({submitting: false});
    });
  }

  onChange(name, value) {
    this.setState({[name]: value});
  }


  fields() {
    return [
      {name: 'innovation_tag_ids', label: 'Innovation Tags', type: 'remote-select2', multiple: true, src: "/admin/innovation_tags/as_options.json", search_key: InnovationTagSearchKey},
      {name: 'innovation_notes', type: 'text'}
    ]
  }
  render() {
    let {submitting} = this.state;
    return (
      <div className="well innovative_details_form">
        <Form singleton model_name="Innovation Data" model={this.state} fields={this.fields()} onChangeField={this.onChange} onSubmit={e => this.submit()} submitting={submitting} />
      </div>
    );
  }
}
