import React, {Component} from 'react';
import joinReact from './join_react';
import request from '../request';
import Form from './form';
import {DeferReasons} from '../constants.js.erb';

export class TrialBottomNav extends Component {
  duplicate() {
    if (!window.confirm('This will create a new therapy with all the same details, groups, and populations. Are you sure you want to continue?')) return;

    let {trial} = this.props;
    let url = '/admin/trials/' + trial.id + '/duplicate';
    request('PUT', url)
      .then(({url}) => {
        window.location = url;
      })
      .catch(() => {
        window.alert("Error duplicating the trial");
      });
  }

  className(action) {
    if (action === this.props.action) return 'active-trial-nav';
    return null;
  }

  render() {
    let {trial, return_path} = this.props;
    if (!trial.id) return null;

    let link_to_trial = '/admin/trials/' + trial.id;
    let link_to_edit = link_to_trial + '/edit';
    let link_to_locations = link_to_trial + '/locations';
    let link_to_therapy_cancer_centers = link_to_trial + '/therapy_cancer_centers';
    let link_to_references = link_to_trial + '/references';
    let link_to_statuses = link_to_trial + '/statuses';
    let link_to_in_collections = link_to_trial + '/in_collections';
    let back_el, locations_el;

    if (return_path) {
      back_el = <a href={return_path} className="all_link"><i className="fa fa-chevron-left"></i> Back</a>
    } else {
      back_el = <a href="/admin/trials" className="all_link"><i className="fa fa-chevron-left"></i> All Therapies</a>
    }

    if (trial.isTrial) {
      locations_el = <a className={this.className('locations')} href={link_to_locations}>Locations</a>;
    } else {
      locations_el = <a className={this.className('therapy_cancer_centers')} href={link_to_therapy_cancer_centers}>Cancer Centers</a>;
    }

    return (
      <div className="overall-trial-nav">
        {back_el}
        <a className={this.className('edit')} href={link_to_edit}>Edit</a>
        <a className={this.className('show')} href={link_to_trial}>View</a>
        {locations_el}
        <a className={this.className('references')} href={link_to_references}>References</a>
        <a className={this.className('statuses')} href={link_to_statuses}>History</a>
        <a className={this.className('in_collections')} href={link_to_in_collections}>In Collections</a>
        <button onClick={e => this.duplicate()} className="all_link">Duplicate</button>
      </div>
    )
  }
}


export class TrialHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  showDeferModal() {
    this.setState({show_defer_modal: true});
  }

  closeDeferModal() {
    this.setState({show_defer_modal: false});
  }

  defer(defer_data) {
    if (!window.confirm('You are about to delete this trial and add this to the list of deferred trials. Are you sure you want to continue?')) return;
    let {trial} = this.props;
    let url = '/admin/trials/' + trial.id + '/defer';
    request('PUT', url, {defer_data})
      .then(({url}) => {
        window.location = url;
      })
      .catch((e) => {
        let reason =  e.reason || "Error. Unable to defer trial. This is likely due to trial being older and not imported via our available trial functionality. Consider setting trial status to archived instead."
        this.setState({show_defer_modal: false});
        window.alert(reason);
      });
  }

  renderInfo() {
    let {trial} = this.props;
    if (isTrial(trial)) {
      return this.renderTrialInfo(trial);
    } else {
      return this.renderTherapyInfo(trial);
    }
  }

  renderTrialInfo(trial) {
    return (
      <span key="info">
        <i className="fa fa-external-link-alt"></i>
        <b><a href={trial.study_url} target="_blank">{trial.identifier}</a></b>&nbsp;
        <a href={trial.changelog_url} target="_blank" className="changelog_link">Change Log</a>&nbsp;|&nbsp;
        <a href={trial.study_data_json} target="_blank" className="changelog_link">Data</a>
      </span>
    )
  }

  renderTherapyInfo(trial) {
    return (
      <span key="info">
        <b>{trial.identifier}</b>&nbsp;
      </span>
    )
  }

  renderTrialStatus() {
    let {trial} = this.props;
    if (!isTrial(trial)) return null;
    return <span key="trial_status">Trial Status: <b>{trial.overall_status_humanized}</b></span>
  }

  renderUpdates() {
    let {trial} = this.props;
    let link_to_statuses = '/admin/trials/' + trial.id + '/statuses';
    return <a key="updates" href={link_to_statuses}><span className="fa fa-comment-alt" /></a>;
  }


  renderStatus() {
    let {trial} = this.props;
    if (!trial.status) return null;
    let status_label = trial.status_humanized;
    if (trial.status_changed_at) {
      let date = moment(trial.status_changed_at).format('LLL');
      status_label += ' (' + date + ')';
    }

    return <span key="status">Sagely Health Status: <b>{status_label}</b> {this.renderUpdates()}</span>
  }


  renderDeprecatedStatus() {
    let {trial} = this.props;
    if (!trial.deprecated) return null;
    let deprecated_list_url = `/admin/trials?query=${trial.deprecated_by}`;
    return <span className="deprecated_label">DEPRECATED BY <a href={deprecated_list_url} target="_blank">{trial.deprecated_by}</a></span>;

  }
  renderDeferButton() {
    let {trial} = this.props;
    if (!isTrial(trial)) return null;
    return <button key="defer" onClick={e => this.showDeferModal()} className="btn btn-link">Defer Trial</button>
  }

  renderDeferModal() {
    let {show_defer_modal} = this.state;
    if (!show_defer_modal) return null;
    return <DeferModal onSubmit={e => this.defer(e)} onCancel={e => this.closeDeferModal(e)} />
  }

  render() {
    let {trial} = this.props;
    if (!trial.id) return null;

    let content = [
      this.renderInfo(),
      this.renderTrialStatus(),
      this.renderStatus(),
      this.renderDeprecatedStatus(),
      this.renderDeferButton()
    ].filter(c => !!c);

    return (
      <div className="trial_header_holder">
        <div className="trial_header_info">
          {joinReact(content, <span className="header-content-divider">|</span>)}
          {this.renderDeferModal()}
        </div>
        <h3 className="trial_title">{trial.name}</h3>
        <p className="subtle">{trial.patient_friendly_title || trial.brief_title}</p>
        <div className="clearfix"></div>
      </div>
    );
  }
}


function isTrial(trial) {
  return trial.therapy_type === 'trial'
}


class DeferModal extends Component {
  constructor(props) {
    super(props);
    let {show} = props;
    let defer_data = {};
    this.state = { show, defer_data };
  }

  componentDidMount() {
    let {onCancel} = this.props;
    $(this.refs.modal_container).modal({show: true});
  }

  submit() {
    let {onSubmit} = this.props;
    let {defer_data} = this.state;
    $(this.refs.modal_container).modal('hide');
    onSubmit(defer_data);
  }


  cancel() {
    let {onCancel} = this.props;
    $(this.refs.modal_container).modal('hide');
    onCancel();
  }


  onChange(defer_data) {
    this.setState({defer_data});
  }

  formFields() {
    return [
      {name: 'deferred_reason', label: 'Reason', type: 'select', collection: DeferReasons, required: true},
      {name: 'deferred_notes', label: 'Notes', type: 'text'}
    ];
  }


  render() {
    let {defer_data} = this.state;
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">Defer Trial</h4>
            </div>
            <div className="modal-body">
              <Form model={defer_data} fields={this.formFields()} onChange={e => this.onChange(e)} onSubmit={e => this.submit(e) } onCancel={e => this.cancel(e)} submitLabel="Defer Trial"/>
            </div>
          </div>
        </div>
      </div>
    );
  }


}
