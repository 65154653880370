const DICTIONARY = {
  labs: {label: 'Labs'},
  findings: {label: 'Findings'},
  symptoms: {label: 'Symptoms'},
  diagnoses: {label: 'Diagnoses'},
  sequencies: {label: 'Sequencies'},
  interventions: {label: 'Interventions'},
  comorbid_conditions: {label: 'Comorbid Conditions'}
}

export const dataSections = Object.keys(DICTIONARY);

export function findDependents(data, field_name, id) {
  let fields = [field_name];
  if (RELATED_FIELD_NAMES.indexOf(field_name) !== -1) {
    fields.push('related_' + field_name);
  }

  let dependents = {};
  for (let section of dataSections) {
    if (data[section]) {
      for (let record of data[section]) {
        for (let field of fields) {
          if (record[field] === id) {
            if (!dependents[section]) dependents[section] = [];
            dependents[section].push({field_name, id});
          }
        }
      }
    }
  }
  return dependents;
}

export function sectionLabel(section) {
  let data = DICTIONARY[section];
  if (!data) return null;
  return data.label;
}


const RELATED_FIELD_NAMES = ['intervention_id', 'symptom_id', 'comorbid_condition_id', 'finding_id'];
