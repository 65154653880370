import React, {Component} from 'react';
import Form from '../form';
import request from '../../request';

export default class Settings extends Component {
  constructor(props) {
    super(props);
    let {settings} = props;
    this.state = {settings, submitting: false};
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(settings) {
    this.setState({settings});
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({submitting: true});
    let {patient, onChangeSettings} = this.props;
    let {settings} = this.state;

    let url = '/admin/patients/' + patient.id;
    request('PUT', url, {patient: {settings}}).then(r => {
      onChangeSettings(settings);
      this.setState({submitting: false});
    });
  }

  formFields() {
    let {patient} = this.props;
    let contacts = patient.contacts.map(c => {return {id: c.id, text: c.name}});
    let medical_team_members = patient.medical_team_members.map(m => {return {id: m.id, text: m.person.name}});

    return [
      {name: 'peer_type', type: 'select', label: 'Default Sent by', collection: ['patient', 'contact', 'medical_team', 'other'], labelTransform: 'titleize', inSummary: true},
      {name: 'contact_id', type: 'select2', collection: contacts, visible: s => s.peer_type === 'contact', idField: 'id', labelField: 'text', hint: 'To appear here, contacts must be added to the patient.'},
      {name: 'medical_team_member_id', type: 'select2', collection: medical_team_members, visible: s => s.peer_type === 'medical_team', idField: 'id', labelField: 'text', hint: 'To appear here, medical team members must be added to the patient.'},

    ];
  }

  render() {
    let {settings} = this.props;
    let {submitting} = this.state;
    return <Form model={settings} modelName='Settings' singleton fields={this.formFields()} onChange={this.onChange} onSubmit={this.onSubmit} submitting={submitting} />
  }
}
