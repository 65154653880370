import React, {Component} from 'react';

import JourneyTable from './journey_table';
import NeestedRecord from './neested_record';
import OptionsCache from './options_cache';
import * as DateHelpers from './date_helpers';
import * as Inflector from 'inflected';
import {globalDefaults} from './journey_data';
import {InterventionSearchKey} from '../../constants.js.erb';


export default class Interventions extends JourneyTable {
  constructor(props) {
    super(props);
    this.state = {};
    this.interventionCache = new OptionsCache({source: 'interventions', onChange: e => this.forceUpdate()});
    this.cancerTypeCache = new OptionsCache({source: 'patient_cancer_types', onChange: e => this.forceUpdate()});
    this.preloadNested();
  }

  componentDidUpdate() {
    this.preloadNested();
  }

  preloadNested() {
    let {interventions} = this.props.data;
    let intervention_ids = interventions.map(s => s.intervention_id);
    this.interventionCache.preload(intervention_ids);
  }

  addIntervention() {
    let {data, settings} = this.props;
    let current = globalDefaults({}, data, settings);
    this.setState({current});
  }

  delete(intervention) {
    if (!this.validateDeletion('intervention_id', intervention.id)) return;
    if (!confirm('Are you sure?')) return;

    let {onDelete} = this.props;
    let sectionLabel = buildSectionLabel(intervention, this.interventionCache);
    onDelete(BASE_PATH, sectionLabel, intervention);
  }


  show(current) {
    this.setState({current});
  }

  changeUsageFilter(e) {
    let usage_filter = e.target.value;
    this.setState({usage_filter});
  }

  renderForm() {
    let {patient, data, fields_changelog, onAddBatch, onEditBatch, readOnly} = this.props;
    let {current} = this.state;

    let props = {readOnly, patient, data, fields_changelog, onAddBatch, onEditBatch, record: current};
    props.onClose = e => this.setState({current: null});
    props.interventionCache = this.interventionCache;

    return <Intervention {...props}  />
  }

  renderList() {
    let {data, readOnly} = this.props;
    let {usage_filter} = this.state;
    let interventions = data.interventions;

    if (usage_filter) {
      interventions = interventions.filter(i => i.usage === usage_filter);
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">

              <label>Filter by Intervention Category</label>
              <select value={usage_filter} onChange={e => this.changeUsageFilter(e)} className="form-control">
                <option value="">Select Category</option>
                {INTERVENTION_USAGE.map(usage => <option key={usage} value={usage}>{Inflector.titleize(usage)}</option>)}
              </select>

            </div>
            <hr/>
          </div>
          <div className="col-md-6">
            <div className="btn-group pull-right">
              <button className="btn btn-success pull-right" onClick={e => this.addIntervention()} disabled={readOnly}>Add Intervention</button>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>




        <table className="table table-condensed table-striped jt-log-table" id="interventions_list">
          <thead>
            <tr>
              <th>Intervention</th>
              <th>Usage</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Line</th>
              <th>Rationale</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {interventions.map(intervention => {
              let action_label = readOnly ? 'Show' : 'Edit';
              let intervention_cache = this.interventionCache.get(intervention.intervention_id);

              let delete_el;
              if (!readOnly) {
                let id = "delete_intervention-" + intervention.id
                delete_el = <button id={id} className="btn btn-default btn-sm" onClick={e => this.delete(intervention)}><i className="fa fa-trash"></i></button>
              }

              return(
                <tr key={intervention.id}>
                  <td>{intervention_cache.text}</td>
                  <td>{Inflector.titleize(intervention.usage)}</td>
                  <td>{DateHelpers.formatEstimated(intervention, {field: 'start_date', estimated_field: 'start_date_estimated'})}</td>
                  <td>{DateHelpers.formatEstimated(intervention, {field: 'end_date', estimated_field: 'end_date_estimated'})}</td>


                  <td>{intervention.understood_line_of_therapy}</td>
                  <td>{intervention.rationale}</td>
                  <td className="actions">
                    <div className="btn-group">
                      <button className="btn btn-default btn-sm" onClick={e => this.show(intervention)}>{action_label}</button>
                      {delete_el}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    );

  }
}

const BASE_PATH = 'interventions';

function buildSectionLabel(record, interventionCache) {
  let intervention = interventionCache.get(record.intervention_id);
  return ['Intervention', intervention.text];
}

class Intervention extends NeestedRecord {
  getModelName() {
    return "Intervention";
  }

  getBasePath() {
    return BASE_PATH;
  }

  getSectionLabel() {
    let {record} = this.state;
    return buildSectionLabel(record, this.props.interventionCache);
  }

  onChange(field_name, value, meta) {
    super.onChange(field_name, value, meta);
    let {record} = this.state;
    if (record.intervention_id) {
      this.props.interventionCache.preload([record.intervention_id]);
    }
  }

  formFields() {
    let interventionValueLabelCallback = i => {
      let {interventionCache} = this.props;
      let intervention = interventionCache.get(i.intervention_id);
      return intervention.text;
    };

    return [
      {name: 'intervention_id', label: 'Intervention', required: true, type: 'remote-select2', src: "/admin/interventions/as_options.json", search_key: InterventionSearchKey, valueLabelCallback: interventionValueLabelCallback},
      {name: 'description', type: 'text'},
      {name: 'usage', label: 'Intervention Usage', required: true, type: 'select', collection: INTERVENTION_USAGE, labelTransform: 'titleize'},
      {name: 'surgeon', label: 'Surgeon, if applicable'},
      {name: 'rationale', type: 'select', collection: ['New Line of Therapy', 'Dose Modification', 'Surgery', 'Clinical Trial Enrollment', 'Other']},
      {name: 'rationale_details', type: 'text'},
      {name: 'trial_nct', label: 'Trial NCT (if applicable)'},
      {name: 'understood_line_of_therapy', type: 'select', collection: ['Adjuvant', 'Neoadjuvant', 'First Line', 'Second Line', 'Third Line', 'Fourth Line', 'Fifth Line', 'Sixth Line', 'Seventh Line', 'Eighth Line', 'Ninth Line', 'Tenth Line']},
      {name: 'planned_cycle', label: 'Planned Cycle/Fraction Number', type: 'number'},
      {name: 'current_cycle', label: 'Current Cycle/Fraction Number', type: 'number'},
      {name: 'start_date', type: 'date'},
      {name: 'start_date_estimated', label: 'Date Estimated?', type: 'bool'},
      {name: 'end_date', type: 'date'},
      {name: 'end_date_estimated', label: 'Date Estimated?', type: 'bool'}
    ];
  }
}


const INTERVENTION_USAGE = ['anti_cancer', 'other'];
