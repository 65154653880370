import React, {Component} from 'react';

export default class ChangelogViewer extends Component {
  render() {
    let {fields_changelog} = this.props;
    let changes = Object.keys(fields_changelog).map(key => {
      let {original, value, field_name, meta} = fields_changelog[key];

      let label = meta.section_label.join(' | ');
      let value_label = meta.value_label || value;

      let el;
      if (field_name === 'id') {
        el = <span><b>{label}:</b><br/><span className="subtle">New record added</span></span>
      } else if (field_name === '!id') {
        el = <span><b>{label}:</b><br/><span className="subtle">Record deleted</span></span>
      } else if (original === undefined || original === '') {
        el = <span><b>{label}:</b> <span className="change-field-label">{meta.field_label}</span><br/> <span className="subtle">Set to:</span> {value_label}</span>
      } else {
        el = <span><b>{label}:</b> <span className="change-field-label">{meta.field_label}</span><br/> <span className="subtle">Changed to:</span> {value_label}</span>
      }

      return <li key={key}>{el}</li>
    });

    return (<ul className="event-changes_list">{changes}</ul>);
  }
}
