import React, {Component} from 'react';
import Form from './form';
import request from '../request';
import Loading from './loading';
import TrialReview from './trial_review';
import TrialGroupsContainer from './trial_groups';
import TrialPopulationsIndex from './trial_populations';
import PeopleWidget from './people_widget';
import {TrialHeader, TrialBottomNav} from './trial_partials';
import {CancerCenterSearchKey, RecruitmentStatus, Phases, THERAPY_TYPE_TRIAL, THERAPY_TYPE_THERAPY} from '../constants.js.erb';
import * as Inflector from 'inflected';
import NeestedRecords from './neested_records';

const RESET_SUBMIT_STATUS_TIMEOUT = 3000;

export default class TrialForm extends Component {
  constructor(props) {
    super(props);
    let {trial} = props;
    this.state = {trial};

    this.changeUpdate = this.changeUpdate.bind(this);
    this.changeTrial = this.changeTrial.bind(this);
    this.changeProxy = this.changeProxy.bind(this);
  }

  importTrial() {
    this.submit(true);
  }

  submit(import_data=false) {
    let {accepted_fields, return_path} = this.props;
    let {trial, submitting} = this.state;
    if (submitting) return;

    let method;
    let url = '/admin/trials';

    if (trial.id) {
      url += "/" + trial.id;
      method = 'PUT';
    } else {
      method = 'POST'
    }

    let trial_data = {}
    for (let field of accepted_fields) {
      trial_data[field] = trial[field];
    }

    this.setState({submitting: true, importing: import_data});
    request(method, url, {trial: trial_data, import: import_data})
      .then(({trial}) => {
        this.setState({trial, importing: false, submitting: false, unsaved_changes: false, imported: import_data, submit_status: 'success'});
        this.scheduleResetSubmitStatus();
        if (method === 'POST') {
          window.history.pushState("", "", `/admin/trials/${trial.id}/edit`);
        }

        // if (return_path) {
        //   window.location = return_path;
        // } else if (method === 'POST') {
        //   window.history.pushState("", "", `/admin/trials/${trial.id}/edit`);
        // }

      })
      .catch(e => {
        window.alert(e.errors)
        this.setState({importing: false, submitting: false, submit_status: 'error'});
        this.scheduleResetSubmitStatus();
      });
  }

  scheduleResetSubmitStatus() {
    setTimeout(() => this.setState({submit_status: undefined, imported: undefined}), RESET_SUBMIT_STATUS_TIMEOUT);
  }


  changeProxy(name, value, trigger_changes=true) {
    let {trial} = this.state;
    trial[name] = value;
    this.setState({trial, unsaved_changes: trigger_changes});
  }

  changeTrial(trial) {
    this.setState({trial, unsaved_changes: true});
  }

  changeUpdate(update) {
    let {trial} = this.state;
    trial.update_data = update;
    this.setState({trial, unsaved_changes: true});
  }


  renderHeaderNew() {
    let {trial} = this.state;
    if (trial.id) return null;
    return (
      <div>
        <a href="/admin/trials" className="btn btn-danger pull-right">Cancel and go to all therapies</a>
        <h1>New Therapy</h1>
        <hr/>
      </div>
    );
  }

  renderEditSubnav() {
    let {trial} = this.state;
    if (!trial.id) return null;

    return (
      <div className="btn-group trial_sub_nav_group">
        <a href="#details" className="btn btn-sm btn-primary">Details</a>
        <a href="#groups" className="btn btn-sm btn-primary">Groups</a>
        <a href="#populations" className="btn btn-sm btn-primary">Populations</a>
      </div>
    )
  }

  renderSubmitStatus() {
    let {trial, unsaved_changes} = this.state;
    let position_class;
    if (!trial.id) {
      position_class = "therapy_save_box position_bottom"
    } else {
      position_class = "therapy_save_box"
    };
    let {submitting, submit_status, imported} = this.state;
    let status_el, button_el;
    if (submit_status === 'success') {
      if (imported) status_el = <p className="success"><span className="glyphicon glyphicon-ok"></span> Imported successfully.</p>
      else status_el = <p className="success"><span className="glyphicon glyphicon-ok"></span> Updated successfully.</p>
    } else if (submit_status === 'error') {
      status_el = <p className="error"><span className="glyphicon glyphicon-warning-sign"></span> Oops, something went wrong.</p>
    } else if(unsaved_changes) {
      status_el = <p className="error">You have unsaved changes.</p>
    } else {
      status_el = <p>Make sure you save your changes.</p>
    }

    if (submitting) {
      button_el = <button className="btn btn-success btn-sm" disabledstyle={{margin_right: 30}}>Saving Form</button>
    } else {
      button_el = <button className="btn btn-success btn-sm" onClick={e => this.submit()}  style={{margin_right: 30}}>Save Form</button>
    }

    return (
      <div className={position_class}>
        {this.renderEditSubnav()}
        {button_el}
        <div className="save_status_box">
          {status_el}
        </div>
      </div>
    );
  }



  renderLastStatusUpdate() {
    let {trial} = this.state;
    let lastStatusUpdate = trial.updates.find(u => u.type === 'trial' && !!u.status)
    if (!lastStatusUpdate) return null;

    let notes_el, admin_el;
    if (lastStatusUpdate.description) {
      notes_el = <span><b>NOTES:</b> {lastStatusUpdate.description}</span>
    } else {
      notes_el = <span>with no notes provided</span>
    }

    if (lastStatusUpdate.admin) {
      admin_el = <span>by <b>{lastStatusUpdate.admin.name}</b></span>;
    }

    return (
      <div className="last-status-update">
        Last saved as <b>{lastStatusUpdate.status_humanized}</b> on <b>{lastStatusUpdate.date_str}</b> {admin_el} {notes_el}
      </div>
    );
  }

  renderReviewStatus() {
    let {trial} = this.state;
    if (!trial.upd_needs_review) return null;

    let {trial_reviews, current_admin} = this.props;
    return (
      <TrialReview trial={trial} trial_reviews={trial_reviews} current_admin={current_admin} />
    )
  }


  renderReviewPanel() {
    let {trial} = this.state;
    let {trials_with_same_identifier_present, current_admin} = this.props;
    if (!trial.upd_needs_review) return null;
    let badges = trial.upd_changelog.map(change => <span key={change} className="badge" >{change}</span> )

    let fields = [
      {name: 'update_reviewed', label: 'Update Reviewed', type: 'bool'},
      {name: 'update_reviewed_all', label: 'Mark all trials with this same NCT as reviewed.', type: 'bool', visible: () => trials_with_same_identifier_present }
    ];


    let last_review_el;
    if (trial.last_version_reviewed) {
      let date = moment(trial.last_version_reviewed).format('LL');
      last_review_el = <p>Last reviewed on {date}</p>
    }
    return (
      <div>
        {last_review_el}
        <p>{badges}</p>
        <div className="trial-form-fix">
          <Form model={trial} fields={fields} onChange={this.changeTrial} />
        </div>
      </div>
    );

  }


  renderUpdateForm() {
    let {trial_status} = this.props;
    let {trial} = this.state;
    if (!trial.update_data) trial.update_data = {};

    let fields = [
      {name: 'status', type: 'select', collection: trial_status, idField: 'key', labelField: 'label'},
      {name: 'description', type: 'text'}
    ];
    return (
      <div id="new_update" className="trial-form-fix trial-form-hide-btns">
        <Form model={trial.update_data} fields={fields} onChange={this.changeUpdate} />
      </div>
    )
  }


  renderStatus() {
    let {trial} = this.state;
    if (!trial.id) return null;

    return (
      <div className="trial-section-box">
        <h4>STATUS</h4>
        {this.renderLastStatusUpdate()}
        {this.renderUpdateForm()}
        {this.renderReviewPanel()}
      </div>
    );
  }

  renderDetails() {
    let {therapy_types, trial_types} = this.props;
    let {trial, importing} = this.state;
    let import_btn_label = importing ? 'Importing' : 'Is ClinicalTrials.gov (NCT), import or update data';
    let F = fieldBuilder(trial);
    let identifier_hint;

    if (trial.therapy_type === 'trial') {
      identifier_hint = 'Enter NCT and import'
    } else if (trial.therapy_type === 'therapy' && (!trial.identifier || trial.identifier.length === 0)) {
      identifier_hint = 'Leave it blank and the app will generate the identifier'
    }

    let fields = [
      {name: 'therapy_type', type: 'select', collection: therapy_types, required: true},
      {name: 'trial_type', type: 'select', collection: trial_types, allowBlank: true, visible: t => t.therapy_type === THERAPY_TYPE_TRIAL, required: true},
      F({name: 'name', required: true, visible: t => t.therapy_type === THERAPY_TYPE_THERAPY}),
      {name: 'patient_friendly_title', type: 'text'},
      {name: 'identifier', required: t => t.therapy_type === 'trial', hint: identifier_hint},
      {name: 'import', className: 'btn btn-primary btn-sm', type: 'button', label: import_btn_label, onClick: e => this.importTrial(), visible: t => t.therapy_type === THERAPY_TYPE_TRIAL},
      {name: 'set_as_standard_treatment', label: 'This trial set a standard treatment', type: 'bool'},
      {name: 'child_trial_ids', type: NeestedTrials, visible: t => t.therapy_type === THERAPY_TYPE_THERAPY}
    ]

    return (
      <div className="trial-form-fix trial-form-hide-btns">
        <Form prefix="trial" model={trial} fields={fields} onChange={this.changeTrial} />
      </div>
    )
  }

  renderTrialFields() {
    let {trial} = this.state;
    if (!isTrial(trial)) return null;

    let overall_status_hint = 'Only select status here if clinicaltrials.gov status is wrong. If you select a status here, this trial status will no longer be updated automatically.';
    let overall_status_blank = '-- ' + trial.imported_data.overall_status + ' (from clinicaltrials.org) --';

    let phase_hint = 'Only select phase here if clinicaltrials.gov phase is wrong. If you select a phase here, this trial phase will no longer be updated automatically.';
    let phase_blank = '-- ' + trial.imported_data.phase + ' (from clinicaltrials.org) --';

    let F = fieldBuilder(trial);

    let fields_block1 = [
      {name: 'c_overall_status', label: 'Recruitment Status', type: 'select', hint: overall_status_hint, collection: RecruitmentStatus, blankLabel: overall_status_blank},
      F({name: 'title', type: 'text'}),
      F({name: 'brief_summary', type: 'text'}),
      F({name: 'condition'}),
      F({name: 'start_date'}),
      {name: 'start_date_type', disabled: true},
      F({name: 'primary_completion_date'}),
      {name: 'primary_completion_date_type', disabled: true}
    ];

    let fields_block2 = [
      {name: 'c_phase', label: 'Phase', type: 'select', collection: Phases, hint: phase_hint, blankLabel: phase_blank},
      F({name: 'enrollment'}),
      F({name: 'enrollment_type'}),
      F({name: 'eligibility_gender', label: 'Gender', type: 'select', collection: GENDERS}),
      F({name: 'eligibility_min_age', label: 'Minimum Age'}),
      F({name: 'eligibility_max_age', label: 'Maximum Age'}),

    ];

    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <div className="trial-form-fix">
              <Form model={trial} fields={fields_block1} onChange={this.changeTrial} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="trial-form-fix">
              <Form model={trial} fields={fields_block2} onChange={this.changeTrial} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderGroups() {
    let {current_admin} = this.props;
    let {trial} = this.state;
    if (!trial.id) return (
      <p>Save this therapy to be able to record groups.</p>
    );

    return (
      <div>
        <TrialGroupsContainer trial={trial} onChange={this.changeProxy} currentAdmin={current_admin} />
      </div>
    );
  }

  renderPopulations() {
    let {trial} = this.state;
    if (!trial.id) return (
      <p>Save this therapy to be able to record populations.</p>
    );
    let link_to_all_populations = "/admin/trials/" + trial.id + "/populations"
    let link_to_new_population = link_to_all_populations + "/new";
    let link_to_bulk_editor = `/admin/trials/${trial.id}/populations_bulk_editor`

    return(
      <div>
        <TrialPopulationsIndex trial={trial} onChange={(n, v) => this.changeProxy(n, v, false)} />
        <div className="btn-group">
          <a href={link_to_new_population} className="btn btn-success" target="_self">Add Population</a>
          <a href={link_to_all_populations} className="btn btn-default" target="_self">See All Populations</a>
          <a href={link_to_bulk_editor} className="btn btn-default" target="_self">Bulk Editor</a>
        </div>
      </div>
    );
  }

  render() {
    let {trial} = this.state;
    let {return_path} = this.props;

    return (
      <div>
        <TrialHeader trial={trial} />
        {this.renderHeaderNew()}
        <div id="trials_form" role="form" className="form-horizontal">
          {this.renderSubmitStatus()}
          <TrialBottomNav trial={trial} action="edit" return_path={return_path}/>
          {this.renderReviewStatus()}
          <div className="row">
            <div className="col-md-6">
              {this.renderStatus()}
            </div>
            <div className="col-md-6">
            </div>
          </div>
          <a name="details" className="anchor_offset"></a>
          <div className="trial-section-box">
            <h4>DETAILS</h4>
            {this.renderDetails()}
            {this.renderTrialFields()}
          </div>
          <div>
            <a name="groups" className="anchor_offset"></a>
          </div>
          <div className="trial-section-box">
            <h4>GROUPS (INTERVENTIONS BY ARMS)</h4>
            {this.renderGroups()}
          </div>
          <a name="populations" className="anchor_offset"></a>
          <div className="trial-section-box">
            <h4>POPULATIONS</h4>
            {this.renderPopulations()}
          </div>
        </div>
      </div>
    );
  }
}

class NeestedTrials extends Component {
  constructor(props) {
    super(props);
    this.state = {cached_trials: []}
    this.openModal = this.openModal.bind(this);
    this.onTrialSelected = this.onTrialSelected.bind(this);
  }

  componentDidMount() {
    this.fetchTrialNames();

    $(this.refs.modal_container).modal({show: false});
    $(this.refs.modal_container).on('hide.bs.modal', () => {
      this.setState({show_modal: false});
    });
  }

  openModal() {
    this.setState({show_modal: true});
    $(this.refs.modal_container).modal('show');
  }

  fetchTrialNames() {
    let {model} = this.props;
    if (!model.child_trial_ids || model.child_trial_ids.length === 0) return;

    let url = '/admin/trials/as_options?ids=' + model.child_trial_ids.join(',');
    request('GET', url).then(({results}) => {
      this.setState({cached_trials: results});
    });
  }

  onTrialSelected(record) {
    let {model, field, onChange} = this.props;
    let {child_trial_ids} = model;
    let {cached_trials} = this.state;

    child_trial_ids.push(record.id);
    onChange({target: {name: 'child_trial_ids', value: child_trial_ids}});

    cached_trials.push(record);
    this.setState({cached_trials})

    $(this.refs.modal_container).modal('hide');
  }

  deleteTrial(trial_id) {
    if (!confirm('Are you sure?')) return;

    let {model, field, onChange} = this.props;
    let {child_trial_ids} = model;
    let index = child_trial_ids.findIndex(t => t === trial_id);
    child_trial_ids.splice(index, 1);
    onChange({target: {name: 'child_trial_ids', value: child_trial_ids}});
  }

  renderTrial(trial_id) {
    let {cached_trials} = this.state;
    if (!cached_trials || cached_trials.length === 0) return null;

    let trial = cached_trials.find(t => t.id === trial_id);
    let url = `/admin/trials/${trial_id}/edit`;
    return(
      <div key={trial_id} className="listed_trial">
        <a href={url} target="_blank"><strong>{trial.identifier}</strong>: {trial.name}</a>
        <button className="btn btn-default btn-sm" onClick={e => this.deleteTrial(trial_id)}><i className="fa fa-times" /></button>
      </div>
    );
  }

  renderModal() {
    let {show_modal} = this.state;
    let content;
    if (show_modal) {
      let {model} = this.props;
      content = <TrialsPicker id="neested_trials_picker" onSelect={this.onTrialSelected} items={model.child_trial_ids} />
    }

    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">Select a Trial</h4>
            </div>
            <div className="modal-body">
              {content}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    );

  }

  render() {
    let {model} = this.props;
    return (
      <div className="associated_ncts_holder">
        <div className="add_action">
          <h4>Associated Trials</h4>
          <button className="btn btn-primary" onClick={this.openModal}>Add Trial</button>
        </div>
        <div className="associated_list">
          {model.child_trial_ids.map(trial => this.renderTrial(trial))}
        </div>


        {this.renderModal()}

      </div>
    );
  }
}

class TrialsPicker extends NeestedRecords {
  searchRequest(text) {
    let url = "/admin/trials/search?query=" + text;
    return request("GET", url);
  }

  renderResult(trial) {
    let {items} = this.props;
    let  onClick;
    let className="item-result";

    if (items.find(r => r === trial.id)) {
      className += " item-added";
    } else {
      className += " clickable";
      onClick = e => this.onSelect(trial);
    }

    return (
      <div key={trial.id} onClick={onClick} className={className}>
        <strong>{trial.identifier}</strong>: {trial.name}
      </div>
    )
  }

}



function isTrial(trial) {
  return trial.therapy_type === 'trial'
}

function isCancerCenter(trial) {
  return trial.therapy_type === 'therapy'
}

function fieldBuilder(trial) {
  return function(field) {
    let imported_value = trial.imported_data[field.name];
    if (!field.label) field.label = Inflector.titleize(field.name)

    field.original_name = field.name;
    field.name = 'c_' + field.original_name;
    if (imported_value && imported_value.length > 0) {
      field.hint = 'Imported: ' + imported_value;
    }
    return field;
  }
}

function legacyReferenceHint(trial, field) {
  if (!trial) return null;

  let data = trial.quick_references_changelog[field];
  if (!data) return null;

  let date = moment(data.changed_at).format('LL');

  return 'Changed by ' + data.user_name + ' at ' + date;
}


const GENDERS = {
  male: 'Male',
  female: 'Female',
  all: 'All'
}
