import React, {Component} from 'react';
import Loading from '../loading';
import request from '../../request';
import * as Inflector from 'inflected';
import {TrialSearchKey} from '../../constants.js.erb';

export default class GlobalMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.resetAction = this.resetAction.bind(this);
  }

  componentDidMount() {
    $(this.refs.modal_container).modal({show: false});
    $(this.refs.modal_container).on('hide.bs.modal', () => {
      this.setState({action: null});
    });
  }

  showModal(selected_unknown_item) {
    $(this.refs.modal_container).modal('show');
    this.setState({show_modal: true, selected_unknown_item});
  }

  closeModal() {
    $(this.refs.modal_container).modal('hide');
  }

  resetAction() {
    this.setState({action: null}, this.closeModal);
  }

  copyToTrial(trial) {
    let {trial_id, population_id} = this.props;
    let url = `/admin/trials/${trial_id}/populations/${population_id}/copy_to_trial`;
    return request('POST', url, {target_trial_id: trial.id});
  }

  selectAction(e, action) {
    e.preventDefault();
    this.setState({action}, this.showModal);
  }

  renderContent() {
    let {action} = this.state;
    if (action === 'copy_to_trial') {
      return <SelectTrial onSelect={t => this.copyToTrial(t)} onClose={this.resetAction} />
    }
    return null;
  }

  renderModal() {
    let {action} = this.state;
    let title = action ? Inflector.titleize(action) : null;
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">{title}</h4>
            </div>
            <div className="modal-body">
              {this.renderContent()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let {trial_id, population_id} = this.props;
    let compare_link = `/admin/trials/${trial_id}/populations/compare`;
    let see_all_link = `/admin/trials/${trial_id}/populations`
    let bulk_editor_link = `/admin/trials/${trial_id}/populations_bulk_editor`

    return (
      <div className="dropdown">
        <button className="btn btn-primary btn-sm dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          Tools&nbsp;
          <span className="caret"></span>
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
          <li><a href={see_all_link}>See All Populations</a></li>
          <li><a href={compare_link}>Compare Populations</a></li>
          <li><a href={bulk_editor_link}>Bulk Editor</a></li>
          <li role="separator" className="divider"></li>
          <li><a href="#" onClick={e => this.selectAction(e, 'copy_to_trial')}>Copy to Trial/Therapy</a></li>
        </ul>
        {this.renderModal()}
      </div>
    );
  }

}


class SelectTrial extends Component {
  constructor(props) {
    super(props);
    this.state = {query: ''};
    this.onChange = this.onChange.bind(this);
    this.search = this.search.bind(this);
  }

  select(trial) {
    let {onSelect} = this.props;
    onSelect(trial).then(population => {
      this.setState({show_confirmation: true, last_population_created: population});
    }).catch(({errors}) => {
      this.setState({show_errors: true, errors});
    });
  }

  search(e) {
    e.preventDefault();
    this.setState({loading: true});
    let {query} = this.state;
    let url = `/admin/trials/as_options?q[${TrialSearchKey}]=${query}`;
    request('GET', url).then(({results}) => {
      console.log(results);
      this.setState({results, loading: false});
    });

  }

  onChange(e) {
    let value = e.target.value;
    this.setState({query: value});
  }


  resetForm() {
    this.setState({query: '', show_errors: false, show_confirmation: false, errors: null});
  }

  renderResults() {
    let {results, loading} = this.state;
    if (loading) return <Loading />
    if (!results || results.length === 0) return null;

    return (
      <div id="results">
        {results.map(trial => {
          return (
            <div key={trial.id} >
              <button onClick={e => this.select(trial)} className="btn btn-link">{trial.identifier} - {trial.name}</button>
            </div>
          );
        })}
      </div>
    );
  }

  renderForm() {
    let {query} = this.state;
    return (
      <form onSubmit={this.search}>
        <div className="form-group">
          <label>Search for Trial or Therapy</label>
          <input type="text" className="form-control" value={query} onChange={this.onChange} />
        </div>
        <input type="submit" value="Search" className="btn btn-default"/>
      </form>
    );
  }

  renderErrors() {
    let {errors} = this.state;
    let {onClose} = this.props;
    return (
      <div className="copy-modal-message">
        <h3>Errors</h3>
        <p>{errors}</p>
        <button className="btn btn-success" onClick={e => this.resetForm()}>Try Again</button>
        <button className="btn btn-default" onClick={onClose}>Close</button>
      </div>
    )
  }


  renderConfirmation() {
    let population = this.state.last_population_created;
    let {onClose} = this.props;
    let link = `/admin/trials/${population.trial_id}/populations/${population.id}`;
    return (
      <div className="copy-modal-message">
        <h3>Population Created!</h3>
        <a href={link} className="btn btn-success">Go to the Population</a>
        <button className="btn btn-default" onClick={onClose}>Close</button>
      </div>
    )
  }

  render() {
    let {show_confirmation, show_errors} = this.state;
    if (show_confirmation) return this.renderConfirmation();
    if (show_errors) return this.renderErrors();

    return (
      <div id="select_trial">
        {this.renderForm()}
        {this.renderResults()}
      </div>
    )
  }
}
