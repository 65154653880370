import SectionWithItems from './section_with_items';

export default class Concurrent extends SectionWithItems {

  sectionData() {
    let {population} = this.props;
    return population.data.concurrent;
  }

  submit() {
    let {population, onChange} = this.props;
    let {section_data} = this.state;
    population.data.concurrent = section_data;
    onChange(population.data);
  }

  sectionDetails() {
    return {
      title: 'Concurrent Therapy',
      subtitle: 'Therapy received within last month and/or on study. Chemo does not need to be captured as concurrent (requirement for 99% of trials).'
    }
  }
}
