import React, {Component} from 'react';
import PopulationSummary from './summary';
import Reference from './reference';
import CancerTypes from './cancer_types';
import PatientDetails from './patient_details';
import PriorTherapy from './prior_therapy';
import Recent from './recent';
import Concurrent from './concurrent';
import GenesMarkers from './genes_markers';
import Notes from './notes';

export default class Population extends Component {
  constructor(props) {
    super(props);
    let tabs  = props.tabs || Object.keys(TABS);
    let current_tab = currentTabFromUrl() || tabs[0];
    this.state = {current_tab, tabs};

    this.selectTab = this.selectTab.bind(this);
  }


  selectTab(e, tab_id) {
    e.preventDefault();
    this.setState({current_tab: tab_id});

    // Change the slug
    let url = window.location.pathname + '#' + tab_id;
    window.history.pushState({}, null, url);
  }


  renderContent() {
    let {population, onChange, extraProps} = this.props;
    let {current_tab} = this.state;
    let {component, propsFactory} = TABS[current_tab];
    let props = propsFactory ? propsFactory(population) : {population};
    if (extraProps && extraProps[current_tab]) {
      props = {...props, ...extraProps[current_tab]};
    }
    props.onChange = onChange;
    props.selectTab = this.selectTab;

    return React.createElement(component, props);
  }

  renderBottonNav() {
    let {current_tab, tabs} = this.state;
    let {population} = this.props;
    return (
      <div className="fixed-bottom-tabs">
        <ul className="nav nav-pills">
          {tabs.map(tab_id => {
            let {label, visible} = TABS[tab_id];
            if (visible && !visible(population)) return null;
            let className = current_tab === tab_id ? 'active' : ''
            return (
              <li key={tab_id} className={className} role="presentation"><a href="#" onClick={e => this.selectTab(e, tab_id)}>{label}</a></li>
            )
          })}
        </ul>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderContent()}
        {this.renderBottonNav()}
      </div>
    );
  }
}


function currentTabFromUrl() {
  let hash = window.location.hash;
  if (!hash || hash.length === 0) return null;
  return hash.replace('#', '');
}

const TABS = {
  summary: {label: "Summary", component: PopulationSummary},
  reference: {label: "Reference", component: Reference, visible: (p) => !!p.reference_id},
  cancer_types: {label:"Cancer Types", component: CancerTypes},
  patient_details: {label: "Patient Details", component: PatientDetails},
  prior_therapy: {label: "Prior", component: PriorTherapy},
  recent: {label: "Recent", component: Recent},
  concurrent: {label: "Concurrent", component: Concurrent},
  genes_markers: {label: "Genes / Diagnostics", component: GenesMarkers},
  notes: {label: "Notes", component: Notes}
}
