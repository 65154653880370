import React, {Component} from 'react';
import JourneyTable from './journey_table';
import NeestedRecord from './neested_record';
import OptionsCache from './options_cache';
import {PatientCancerTypeSearchKey} from '../../constants.js.erb';
import * as DateHelpers from './date_helpers';

export default class DiagnosesList extends JourneyTable {
  constructor(props) {
    super(props);
    this.state = {};
    this.cancerTypeCache = new OptionsCache({source: 'patient_cancer_types', onChange: e => this.forceUpdate()})
    this.preloadNested();
  }

  preloadNested() {
    let {diagnoses} = this.props.data;
    let patient_cancer_type_ids = diagnoses.map(d => d.patient_cancer_type_id);
    this.cancerTypeCache.preload(patient_cancer_type_ids);
  }

  componentDidUpdate() {
    let {diagnoses} = this.props.data;
    let patient_cancer_type_ids = diagnoses.map(d => d.patient_cancer_type_id);
    this.cancerTypeCache.preload(patient_cancer_type_ids);
  }


  addDiagnosis() {
    this.setState({current: newDiagnosis()});
  }

  delete(diagnosis) {
    if (!this.validateDeletion('diagnosis_id', diagnosis.id)) return;
    if (!confirm('Are you sure?')) return;

    let {onDelete} = this.props;
    let sectionLabel = buildSectionLabel(diagnosis, this.cancerTypeCache);
    onDelete(BASE_PATH, sectionLabel, diagnosis);
  }

  show(current) {
    this.setState({current});
  }


  renderForm() {
    let {readOnly, patient, fields_changelog, onAddBatch, onEditBatch} = this.props;
    let {current} = this.state;
    let props = {readOnly, patient, fields_changelog, onAddBatch, onEditBatch, record: current};
    props.onClose = e => this.setState({current: null});
    props.cancerTypeCache = this.cancerTypeCache;
    return <Diagnosis  {...props} />
  }

  renderList() {
    let {data, readOnly} = this.props;
    let {diagnoses} = data;

    return (
      <div>
        <button className="btn btn-success pull-right" onClick={e => this.addDiagnosis()} disabled={readOnly}>Add Diagnosis</button>
        <div className="clearfix"></div>
        <p><b>Diagnosis</b></p>
        <table className="table table-condensed table-striped jt-log-table" id="diagnoses_list">
          <thead>
            <tr>
              <th>Date of Diagnosis</th>
              <th>Type</th>
              <th>Primary Tumor Status</th>
              <th>Active?</th>
              <th>Stage at Diagnosis</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {diagnoses.map(diagnosis => {
              let action_label = readOnly ? 'Show' : 'Edit';
              let patient_cancer_type = this.cancerTypeCache.get(diagnosis.patient_cancer_type_id);
              let primary_tumor_status = PRIMARY_TUMOR_STATUSES[diagnosis.primary_tumor_status];
              let delete_el;
              if (!readOnly) {
                let id = "delete_diagnosis-" + diagnosis.id
                delete_el = <button id={id} className="btn btn-default btn-sm" onClick={e => this.delete(diagnosis)}><i className="fa fa-trash"></i></button>
              }

              return(
                <tr key={diagnosis.id}>
                  <td>{DateHelpers.formatEstimated(diagnosis, {field: 'date', estimated_field: 'is_date_estimated'})}</td>
                  <td>{patient_cancer_type.text}</td>
                  <td>{primary_tumor_status}</td>
                  <td>{diagnosis.active ? 'Active' : 'Not Active'}</td>
                  <td>{diagnosis.initial_stage_at_diagnosis}</td>
                  <td className="actions">
                    <div className="btn-group">
                      <button className="btn btn-default btn-sm" onClick={e => this.show(diagnosis)}>{action_label}</button>
                      {delete_el}
                    </div>
                  </td>
                </tr>

              )
            })}
          </tbody>
        </table>
      </div>
    );

  }
}



function newDiagnosis() {
  return {stage: {}, pathology: {}, traits: {}};
}

const BASE_PATH = 'diagnoses';

function buildSectionLabel(record, cancerTypeCache) {
  let cancer_type = cancerTypeCache.get(record.patient_cancer_type_id);
  return ['Diagnosis', cancer_type.text];
}

class Diagnosis extends NeestedRecord {
  getModelName() {
    return "Diagnosis";
  }

  getBasePath() {
    return BASE_PATH;
  }

  getSectionLabel() {
    let {record} = this.state;
    return buildSectionLabel(record, this.props.cancerTypeCache);
  }


  /*
   * Override some methods because diagnosis obviously does not have
   * diagnisis_id
   * -------------------------------------------------------------------*/

  allFormFields() {
    return this.formFields();
  }

  sharedFields() {
    return null;
  }

  onChange(field_name, value, meta) {
    super.onChange(field_name, value, meta);
    let {record} = this.state;
    if (record.patient_cancer_type_id) {
      this.props.cancerTypeCache.preload([record.patient_cancer_type_id]);
    }
  }

  formFields() {
    let cancerTypeValueLabelCallback = d => {
      let {cancerTypeCache} = this.props;
      let cancer_type = cancerTypeCache.get(d.patient_cancer_type_id);
      return cancer_type.text;
    };

    let {record} = this.state;
    let {cancerTypeCache} = this.props;
    let staging_options = [];

    if (record.patient_cancer_type_id) {
      let cancer_type = cancerTypeCache.get(record.patient_cancer_type_id);
      if (cancer_type && cancer_type.staging_options) staging_options = cancer_type.staging_options;
    }

    return [
      {name: 'patient_cancer_type_id', label: 'Cancer Type', required: true, type: 'remote-select2', src: "/admin/patient_cancer_types/as_options.json", search_key: PatientCancerTypeSearchKey, valueLabelCallback: cancerTypeValueLabelCallback},
      {name: 'date', type: 'date', label: 'Date of diagnosis'},
      {name: 'is_date_estimated', type: 'bool', label: 'Is this date estimated?'},
      {name: 'primary_tumor_status', type: 'select', collection: PRIMARY_TUMOR_STATUSES},
      {name: 'active', type: 'bool'},
      {name: 'requires_systemic_treatment', type: 'bool'},
      {name: 'initial_stage_at_diagnosis', label: 'Initial Stage at Diagnosis', type: 'select', collection: staging_options}
    ];
  }
}

const PRIMARY_TUMOR_STATUSES = {
  active: 'Active/Present',
  inactive: 'Inactive (e.g. raditated)',
  resected_completely: 'Resected Completely',
  resected_partially: 'Resected Partially',
  recurrent_progressed: 'Recurrent/Progressed',
  unknown: 'Unknown'
}
