import React, {Component} from 'react';
import {BaseEventEditor, BaseEventViewer} from './base_event';

class CustomEventForm extends BaseEventEditor {
  formFields() {
    return fields();
  }

}

class CustomEventPreview extends BaseEventViewer {
  formFields() {
    return fields();
  }
}

export default {
  viewer: CustomEventPreview,
  editor: CustomEventForm
}


function fields() {
  return [
    {name: 'description', type: 'text', required: true, inSummary: true, label: 'Please explain why you are making these changes outside a normal event' },
  ];

}
