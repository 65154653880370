import React, {Component} from 'react';
import Form from '../form';
import ItemsCollection from '../items_collection';
import Select2 from '../select2';
import {TrialSearchKey, GeneSearchKey, Phases} from '../../constants.js.erb';

const MOA_SOURCES = ['moa_types', 'interventions', 'moa_families', 'intervention_families'];
const MOA_SELECTED_SOURCES = ['moa_families', 'intervention_families'];

const THERAPY_TYPES = {
  therapeutic_trial: 'Therapeutic Trial',
  diagnostic_trial: 'Diagnostic Trial',
  standard_therapy: 'Standard Therapy',
  accesible_therapy: 'Accessible Therapy'
}


export default class SpecificTrialsDetails extends Component {
  changeFilter(section, e, isCheckbox) {
    let {name, value, checked} = e.target;
    let {onChange, filters} = this.props
    if (!filters[section]) filters[section] = {};
    let field_value = isCheckbox ? checked : value;
    filters[section][name] = field_value;
    onChange && onChange({filters});
  }

  changeQueryCheckbox(section, e) {
    let {name, checked} = e.target;
    let {onChange, query} = this.props
    if (!query[section]) query[section] = {};
    query[section][name] = checked;
    onChange && onChange({query});
  }

  renderCompanyDetails() {
    let {query, onChange} = this.props;
    let company = query.company || {};
    let fields = [
      {name: 'trial_ids', label: 'Specific Trials', type: 'remote-select2', multiple: true, src: "/admin/trials/as_options.json", search_key: TrialSearchKey}
    ];
    return <Form fields={fields} onChange={c => onChange('company', c)} model={company}/>
  }


  renderGenes() {
    let {filters} = this.props;
    let patient_details = filters.patient_details || {}


    let diagnostics_and_gene_ids = patient_details.diagnostics_and_gene_ids || [];
    let condition = patient_details.diagnostics_and_gene_condition || 'any';

    return (
      <div className="filter_list_holder side-panel-filter">
        <div className="filter_list-flex-header">
          <p className="filter_list_header">Targeted Genes / Diagnostics</p>
          <div className="filter_list_options_holder">
            <label className="radio-inline">
              <input type="radio" name="diagnostics_and_gene_condition" value="any" checked={condition === 'any'} onChange={e => this.changeFilter('patient_details', e)}/> Any
            </label>
            <label className="radio-inline">
              <input type="radio" name="diagnostics_and_gene_condition" value="all" checked={condition === 'all'} onChange={e => this.changeFilter('patient_details', e)}/> All
            </label>
          </div>
        </div>
        <Select2 name="diagnostics_and_gene_ids" value={diagnostics_and_gene_ids} multiple={true}  remote={true} src="/api/genes" searchField={GeneSearchKey} onChange={e => this.changeFilter('patient_details', e)} />
      </div>
    );
  }

  renderMoaItems() {
    let {filters} = this.props;
    let patient_details = filters.patient_details || {}

    let moa = patient_details.moa || [];
    let moa_condition = patient_details.moa_condition || 'any';

    let moa_prohibited = patient_details.moa_prohibited || [];
    let moa_probited_condition = patient_details.moa_prohibited_condition || 'any';
    let moa_exact_group_match = patient_details.moa_exact_group_match || false;

    return (
      <div>
        <div className="filter_list_holder side-panel-filter">
          <div className="filter_list-flex-header">
            <p className="filter_list_header">
              Required Interventions, MOAs, and Families
            </p>
            <div className="filter_list_options_holder">
              <label className="radio-inline">
                <input type="radio" name="moa_condition" value="any" checked={moa_condition === 'any'} onChange={e => this.changeFilter('patient_details', e)}/> Any
              </label>
              <label className="radio-inline">
                <input type="radio" name="moa_condition" value="all" checked={moa_condition === 'all'} onChange={e => this.changeFilter('patient_details', e)}/> All
              </label>

              <label className="radio-inline">
                <input type="radio" name="moa_condition" value="exact_match" checked={moa_condition === 'exact_match'} onChange={e => this.changeFilter('patient_details', e)}/> Exact
              </label>
            </div>
          </div>
          <ItemsCollection items={moa} onChange={i => this.changeFilter('patient_details', {target: {name: 'moa', value: i}})} sources={MOA_SOURCES} selected_sources={MOA_SELECTED_SOURCES} destination='population' candidatesDecorator={ItemsCollection.MOAFamilyDecorator} />
        </div>

        <div className="filter_list_holder side-panel-filter">
          <div className="filter_list-flex-header">
            <p className="filter_list_header">Prohibited Interventions, MOAs, and Families</p>
            <div className="filter_list_options_holder">
              <label className="radio-inline">
                <input type="radio" name="moa_prohibited_condition" value="any" checked={moa_probited_condition === 'any'} onChange={e => this.changeFilter('patient_details', e)}/> Any
              </label>
              <label className="radio-inline">
                <input type="radio" name="moa_prohibited_condition" value="all" checked={moa_probited_condition === 'all'} onChange={e => this.changeFilter('patient_details', e)}/> All
              </label>
            </div>
          </div>
          <ItemsCollection items={moa_prohibited} onChange={i => this.changeFilter('patient_details', {target: {name: 'moa_prohibited', value: i}})} sources={MOA_SOURCES} selected_sources={MOA_SELECTED_SOURCES} destination='population' candidatesDecorator={ItemsCollection.MOAFamilyDecorator} />
        </div>

      </div>
    );
  }

  renderTherapyType() {
    let {query} = this.props;
    let therapy_type = query.therapy_type || {};

    let content = Object.keys(THERAPY_TYPES).map(key => {
      let label = THERAPY_TYPES[key];
      return (
        <div key={"type_" + key}>
          <input type="checkbox" name={key} checked={therapy_type[key] || ""} value={therapy_type[key] || ""} onChange={e => this.changeQueryCheckbox("therapy_type", e)}/> <label>{label}</label>
        </div>
      );
    })

    return (
      <div className="filter_list_holder side-panel-filter">
        <p className="filter_list_header">Therapy Type</p>
        {content}
      </div>
    );
  }


  renderMatchType() {
    let {query} = this.props;
    let match_type = query.match_type || {};

    return (
      <div className="filter_list_holder side-panel-filter">
        <p className="filter_list_header">Match Type</p>
        <div>
          <input type="checkbox" name="tumor_type" value={match_type.tumor_type || ""} checked={match_type.tumor_type || ""} onChange={e => this.changeQueryCheckbox("match_type", e)} />
            <label>Solid / Liquid</label>
        </div>
        <div>
          <input type="checkbox" name="cancer_category" value={match_type.cancer_category || ""} checked={match_type.cancer_category || ""} onChange={e => this.changeQueryCheckbox("match_type", e)}/>
            <label>Cancer Category</label>
        </div>
        <div>
          <input type="checkbox" name="cancer_type" value={match_type.cancer_type || ""} checked={match_type.cancer_type || ""} onChange={e => this.changeQueryCheckbox("match_type", e)}/>
            <label>Cancer Type</label>
        </div>

      </div>
    );
  }


  render() {

    return (
      <div id="specific_trial_details" className="search_panel">
        {this.renderTherapyType()}
        {this.renderMatchType()}
        {this.renderGenes()}
        {this.renderMoaItems()}
        {this.renderCompanyDetails()}
      </div>
    )
  }
}
