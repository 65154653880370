import React, {Component} from 'react';
import request from '../../request';
import Loading from '../loading';
import PatientPicker from '../patient_picker';

export default class CopySavedSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {loading: true}
  }

  close() {
    this.modal.modal('hide');
    let {onClose} = this.props;
    onClose();
  }

  componentDidMount() {
    this.modal = $(this.refs.modal_container);
    this.modal.modal({show: true});
    this.modal.on('hidden.bs.modal', e => {
      let {onClose} = this.props;
      if (onClose) onClose();
    });
  }


  copy(patient) {
    let {saved_search} = this.props;
    let url = "/admin/search/saved_searches/" + saved_search.id + "/copy_to_patient";
    request('PUT', url, {patient_id: patient.id}).then((success) => {
      if (success) {
        this.close();
      }
    });
  }


  render () {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container" id="copy_to_patient_modal">
        <div id="saved_searches_modal" className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={e => this.close()} aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">Copy Saved Search</h4>
            </div>
            <div className="modal-body">
              <PatientPicker actionLabel="Copy" onClick={p => this.copy(p)}/>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" onClick={e => this.close()}>Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
