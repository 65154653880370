import React, {Component} from 'react';
import Form from '../form';

export default class NotesWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {note: {text: ''}};
    this.change = this.change.bind(this);
  }

  componentDidMount() {
    this.modalEl = $(this.refs.modal_container);
    this.modalEl.modal({show: false});
  }

  submit(e) {
    e.preventDefault();
    let {note, index} = this.state;
    let {data, onChange} = this.props;
    if (!data.notes) data.notes = [];
    if (index !== undefined) {
      data.notes[index] = note;
    } else {
      data.notes.push(note);
    }
    onChange(data);
    this.modalEl.modal('hide');
  }

  deleteNote(index) {
    if (!confirm('Are you sure?')) return;
    let {data, onChange} = this.props;
    data.notes.splice(index, 1);
    onChange(data);
  }

  change(note) {
    this.setState({note});
  }

  newNote() {
    let note = {text: ''}
    this.setState({note, index: undefined});
    this.modalEl.modal('show');
  }

  editNote(index) {
    let {data} = this.props;
    let note = data.notes[index];
    this.setState({note, index});
    this.modalEl.modal('show');
  }



  renderNotes() {
    let {data} = this.props;
    if (!data.notes || data.notes.length === 0) return null;

    return (
      <div>
        {data.notes.map((note, index) => {
          return (
            <div className="panel panel-default" key={index}>
              <div className="panel-body">
                {this.renderNote(note)}
                <p>
                  <button onClick={e => this.editNote(index)} className="btn btn-xs btn-default"><span className="fa fa-pencil-alt"></span></button>
                  <button onClick={e => this.deleteNote(index)} className="btn btn-xs btn-danger"><span className="fa fa-trash"></span></button>
                </p>
              </div>
            </div>

          )})
        }
      </div>
    );
  }

  renderNote(note) {
    let type;
    if (note.type && note.type.length > 0) {
      type = <span>{CRITERIA_TYPES[note.type]}: </span>
    }

    return <p>{type}{note.text}</p>;
  }

  renderForm() {
    let {note} = this.state;
    let fields = [
      {name: 'type', label: 'Criteria', type: 'select', collection: CRITERIA_TYPES},
      {name: 'text', label: 'Admin Note', type: 'text', required: true}
    ];
    return <Form model={note} model_name="Note" fields={fields} onChange={this.change} />
  }

  render() {
    let {data} = this.props;
    return (
      <div>
        <h3>Notes</h3>
        <hr/>
        {this.renderNotes()}
        <button className="btn btn-default" onClick={e => this.newNote()}>Add Note</button>


        <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title">Notes</h4>
              </div>
              <div className="modal-body">
                {this.renderForm()}
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                <button className="btn btn-success" onClick={e => this.submit(e)}>Submit</button>
              </div>
            </div>
          </div>
        </div>


      </div>
    );

  }
}

const CRITERIA_TYPES = {
  inclusion: 'INCLUSION',
  exclusion: 'EXCLUSION'
}
