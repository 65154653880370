import request from '../request';
var cached_admins = false;
var loading_admins = false;

function preloadAdmins() {
  return new Promise(async (success, error) => {
    while(loading_admins) {
      await sleep(1000);
    }

    if (cached_admins) {
      success(cached_admins)
    } else {
      loading_admins = true;
      let url = '/admin/admins';
      request('GET', url)
        .then(admins => {
          cached_admins = admins;
          loading_admins = false;
          success(admins)

        })
        .catch(e => {
          loading_admins = false;
          error(e);
        });
    }
  });
}


function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  get: preloadAdmins
}
