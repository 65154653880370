import React, {Component} from 'react';
import SearchResult from './search/results';
import {SearchFilters, QueryFilters} from './search/filters';
import SearchCancerType from './search/cancer_type';
import SavedSearches from './search/saved_searches';
import CopySavedSearch from './search/copy_saved_search';
import PatientDetails from './search/patient_details';
import SpecificTrialsDetails from './search/specific_trial_details';
import LocationsPanel from './search/locations_panel';
import {calculateDistance} from './geolocation';
import {getLocationLabel} from './locations';
import ResultsMap from './search/results_map';
import CollectionsModal from './search/collections_modal';
import CancerCentersList from './search/cancer_centers_list';
import ShareSearch from './search/share_search';
import Loading from './loading';
import Notifications from './notifications';
import {WebsocketURL} from '../constants.js.erb';
import request from '../request';
import Stadox from '../stadox';

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState(props.patient);

    this.initializeStateFromUrl(this.state);

    if (props.patient) {
      if (props.admin_mode) {
        this.state.collections = props.patient.collections;
      }
    }

    Stadox.init();
    this.ctx = Stadox.subscribe(this);

    this.change = this.change.bind(this);
    this.onChangeCancerType = this.onChangeCancerType.bind(this);
    this.search = this.search.bind(this);
    this.loadSavedSearch = this.loadSavedSearch.bind(this);
    this.saveSearch = this.saveSearch.bind(this);
    this.changeFilters = this.changeFilters.bind(this);
    this.changeAllFilters = this.changeAllFilters.bind(this);
    this.changeQueryFilters = this.changeQueryFilters.bind(this);
    this.onAddFilter = this.onAddFilter.bind(this);
    this.toggleSelection = this.toggleSelection.bind(this);
    this.receiveResultsCompleted = this.receiveResultsCompleted.bind(this);
    this.addTrialToCollection = this.addTrialToCollection.bind(this);
    this.chunkAvailable = this.chunkAvailable.bind(this);
    this.websocketPing = this.websocketPing.bind(this);
  }



  componentDidMount() {
    if (gon.remoteSearches) {
      this.connectWebsockets();
    }

  }

  componentWillUnmount() {
    if (gon.remoteSearches) {
      this.socket.close();
      clearInterval(this.websocket_ping);
    }
  }

  /*
   * Websockets
   * -------------------------------------------------------------------*/


  connectWebsockets() {
    let socket = new WebSocket(WebsocketURL);
    socket.onclose = this.onWebsocketClose.bind(this);
    socket.onopen = this.onWebsocketOpen.bind(this);

    socket.onmessage = e => {
      if (e.data === 'pong') return;
      this.processWebsocketMessage(JSON.parse(e.data));
    }

    socket.onerror = e => {
      console.log("Connection Error");
    }

    this.socket = socket;
  }

  onWebsocketClose(e) {
    this.socket = null;
    this.connectWebsockets();
  }

  onWebsocketOpen(e) {
    if (this.ws && this.ws.job_id) {
      this.sendWebsocket({action: 'subscribe', job_id: this.ws.job_id});
    }
    if (!this.websocket_ping) {
      this.websocket_ping = setInterval(this.websocketPing, 30 * 1000);
    }
  }

  sendWebsocket(data) {
    if (!this.socket) this.connectWebsockets();
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(data));
    }
  }

  processWebsocketMessage(message) {
    if (message.action === 'header_available') {
      this.downloadHeader();
    }
    else if (message.action === 'chunk_available') {
      this.ws.last_chunk_available = message.index;
      this.chunkAvailable();
    }

    else if (message.action === 'error') {
      this.setState({loading: false});
      window.alert("Error searching. Please, try again.");
    }
  }


  downloadHeader() {
    // Get the header
    let url = "/search_results_header?job_id=" + this.ws.job_id;
    request('GET', url).then(header => {
      this.setHeader(header)
    })
  }


  chunkAvailable() {
    if (!this.state.counts) {
      setTimeout(this.chunkAvailable, 500);
      return ;
    }

    if (!this.ws.downloading) {
      let index;
      if (this.ws.last_chunk_downloaded === undefined) {
        index = 0;
      } else {
        index = Math.min(this.ws.last_chunk_available, this.ws.last_chunk_downloaded + 1);
      }
      this.downloadChunk(index);
    }
  }

  downloadChunk(index, retry) {
    let {order_by} = this.state;
    this.ws.downloading = true;
    if (retry === undefined) retry = 0;

    let url = "/search_results_chunk?job_id=" + this.ws.job_id + "&index=" + index;
    request('GET', url).then(chunk => {
      let {results, counts} = this.state;
      if (index === 0) results = [];

      if (chunk === false) {
        console.log("Error downloading chunk: ", index, ". Downloaded ", results.length, " of ",  counts.trials)
        if (retry < 10) {
          setTimeout(() => this.downloadChunk(index, retry + 1), 2500)
        } else {
          window.alert("Error downloading content");
        }
        return;
      }

      // Calculate distances
      this.calculateDistances(chunk);

      results = results.concat(chunk);

      // Reorder if necessary
      if (order_by && order_by != DEFAULT_ORDER) {
        results = this.orderResults(order_by, results);
      }

      this.ws.last_chunk_downloaded = index;

      this.setState({results}, () => {
        // If this was the last chunk
        if (counts && counts.trials === results.length) {
          this.cleanupRemoteSearch();
          this.ws = false;

          let {selection, filters} = this.state;
          selection = selection.filter(trial_id => results.find(r => r.trial.id === trial_id));

          let changes = {selection, downloading_chunks: false};

          // If nothing selected, disable the filter
          if (selection.length === 0) changes.only_selected = false;

          this.setState(changes, this.receiveResultsCompleted);

        }

        else if (this.ws.downloading && this.ws.last_chunk_available > index) {
          this.downloadChunk(index + 1);
        } else {
          this.ws.downloading = false;
        }
      });
    })
  }

  cleanupRemoteSearch() {
    this.ws.downloading = false;
    let url = "/search_results_completed?job_id=" + this.ws.job_id;
    request('GET', url);
  }

  setHeader(header) {
    let {filters, counts} = header;
    let changes = {filters, counts, loading: false, downloading_chunks: true};
    let callback = undefined;

    if (counts.trials === 0) {
      changes.downloading_chunks = false;
      changes.results = [];
      callback = this.receiveResultsCompleted
    }

    this.setState(changes, callback);
  }

  websocketPing() {
    if (!this.socket) this.connectWebsockets();
    this.socket.send("ping");
  }


  initializeStateFromUrl(state) {
    let url = new URL(window.location.href);
    let saved_search_id = url.searchParams.get("saved_search_id");
    let shared_search_token = url.searchParams.get("shared_search");

    if (saved_search_id) {
      this.fetchSavedSearch(saved_search_id);
    } else if (shared_search_token) {
      this.fetchSharedSearch(shared_search_token);
    }
  }

  fetchSavedSearch(saved_search_id) {
    let url = "/search/saved_searches/" + saved_search_id;
    if (this.props.admin_mode) {
      url = "/admin" + url;
    }
    request('GET', url).then(saved_search => {
      this.loadSavedSearch(saved_search);
    });
  }

  fetchSharedSearch(shared_search_token) {
    let url = `/search/saved_searches/${shared_search_token}/shared`
    request('GET', url).then(saved_search => {
      this.loadSavedSearch(saved_search, {shared: true});
    });
  }

  duplicateSavedSearch() {
    if (!confirm('Are you sure?')) return;
    let {saved_search} = this.state;
    let url = `/search/saved_searches/${saved_search.id}/duplicate`;
    if (this.props.admin_mode) {
      url = "/admin" + url;
    }
    request('PUT', url).then(saved_search => {
      this.loadSavedSearch(saved_search);
    });
  }


  unsetPatient() {
    if (!confirm('Are you sure?')) return;
    let {saved_search} = this.state;
    let url = `/admin/search/saved_searches/${saved_search.id}/unset_patient`;
    request('PUT', url).then(({link}) => {
      window.location = link;
    });
  }

  saveSearch(name) {
    let {admin_mode, patient} = this.props;
    let {query, filters, selection, result_ids, saved_search, show_update_search, order_by} = this.state;

    let method, params, url;
    let options = {order_by};

    if (show_update_search) {
      method = "PUT";
      url = "/search/saved_searches/" + saved_search.id;
    } else {
      url = "/search/saved_searches";
      method = "POST";
    }

    if (patient) url = "/patients/" + patient.id + url;
    if (admin_mode) url = "/admin" + url;

    return request(method, url, {saved_search: {name, result_ids, data: {query, filters, selection, options}}})
      .then((saved_search) => {
        saved_search.result_ids = this.state.result_ids;
        this.setState({saved_search, results_diff: null});
        this.updateUrl(saved_search);
      });
  }

  resetSearch() {
    let state = defaultState();
    this.setState({...state});
    this.resetUrl();
  }

  loadSavedSearch(saved_search, opt={}) {
    let {query, filters, selection, options} = saved_search.data;

    // Initialize some values
    if (!query.therapy_status) query.therapy_status = defaultTrialStatus();
    if (!query.therapy_phases) query.therapy_phases = {};

    // Force loading=true to prevent rendering invalid filters
    let state = Object.assign({}, defaultState(), {query, filters, selection, loading: true});
    if (!opt.shared) {
      state.saved_search = saved_search;
    }

    if (options && options.order_by) {
      state.order_by = options.order_by;
    }

    if (!state.selection) state.selection = [];

    this.setState(state, () => this.search({from_saved_search: true}));

    // Update the url if the saved search is not
    if (!opt.shared) {
      this.updateUrl(saved_search);
    }
  }

  update_saved_results() {
    let {saved_search, result_ids} = this.state;
    let url = "/search/saved_searches/" + saved_search.id;
    if (this.props.admin_mode) {
      url = "/admin" + url;
    }
    request('PUT', url, {saved_search:{result_ids}}).then((s) => {
      saved_search.result_ids = result_ids;
      this.setState({saved_search, results_diff: null});
    })
  }

  addTrialToCollection(collection_trial) {
    let {collections} = this.state;
    let index = collections.findIndex(c => c.id === collection_trial.collection_id);
    collections[index].trials.push(collection_trial);
    this.setState({collections});
  }

  updateUrl(saved_search) {
    let url = new URL(window.location.href);
    url.searchParams.delete("shared_search");
    url.searchParams.set("saved_search_id", saved_search.id);
    window.history.pushState({}, null, url.toString());
  }

  resetUrl() {
    let url = new URL(window.location.href);
    url.search = '';
    window.history.pushState({}, null, url.toString());
  }

  applySavedFilters(filters, saved_filters) {
    for (let section_name of Object.keys(filters)) {
      let section = filters[section_name];
      for (let filter of section) {
        let saved_item = saved_filters[section_name] && saved_filters[section_name].find(i => i.item.id === filter.item.id);
        if (saved_item) {
          filter.selected = saved_item.selected;
        }
      }
    }
    return filters;
  }

  change(section, value, callbackKey) {
    let query = this.state.query;
    query[section] = value;
    let callback;
    if (callbackKey === 'cancerTypeChangedCallback') {
      callback = this.onChangeCancerType;
    }
    this.setState({query}, callback)
  }

  onChangeCancerType() {
    let {filters} = this.state;
    // Reset cancer type diagnostics and genes
    filters.cancer_type_diagnostics_and_genes = [];
    this.setState({filters});
  }

  changeFilters(section_name, index, value) {
    let filters = this.state.filters;
    let section = filters[section_name];
    section[index].selected = value;
    this.setState({filters});
  }

  changeAllFilters(section_name, value) {
    let filters = this.state.filters;
    let section = filters[section_name];
    for (let i=0; i < section.length; i++) {
      section[i].selected = value;
    }
    this.setState({filters});
  }

  changeQueryFilters(change) {
    this.setState(change);
  }

  onAddFilter(section, filter) {
    let {filters} = this.state;
    filters[section].push(filter);
    this.setState({filters});
  }

  changeCollections(collections) {
    this.setState({collections});
  }

  showLoadSearch() {
    this.setState({show_load_search: true});
  }

  closeLoadSearch() {
    this.setState({show_load_search: false});
  }


  search(opt={}) {
    let {query, filters, order_by} = this.state;

    if (gon.remoteSearches && this.ws && this.ws.job_id) {
      this.cleanupRemoteSearch();
    }

    if (!query.cancer_type) {
      window.alert("Select a cancer type");
      return;
    }

    if (query.locations.country_code === 'US' && Object.keys(query.locations.states).length == 0) {
      window.alert("Select at least one state from the list");
      return;
    }

    let filtered_filters = cleanupFilters(filters);
    let params = {query, filters: filtered_filters};

    if (opt.from_saved_search) params.from_saved_search = true;

    // Set order_by to distance if nothing was set and any location avaliable
    if (!order_by && this.anyLocationAvailable()) order_by = 'distance';

    this.setState({results: false, loading: true, downloading_chunks: false, error: false, order_by, loading_starts_at: new Date()})

    let url = this.props.admin_mode ? "/admin/search" : "/search"

    request("PUT", url, params)
      .then(data => {

        let search_results_changed = !opt.from_saved_search;
        this.setState({search_results_changed});

        if (gon.remoteSearches) {
          if (!data) {
            window.alert("Error sending data to the searches server");
            return;
          }
          this.sendWebsocket({action: 'subscribe', job_id: data.job_id});
          this.ws = {job_id: data.job_id, last_chunk_downloaded: undefined, last_chunk_available: 0};
        } else {
          this.setResults(data);
        }

      })
      .catch(e => {
        console.log("ERROR", e);
        this.setState({results: [], filters: [], loading: false, error: true});
      });
  }


  anyLocationAvailable() {
    let {patient} = this.props;
    let {query} = this.state;
    let {extra_locations} = query.locations;
    let locations_found = (patient && patient.locations && patient.locations.length > 0) || (extra_locations && extra_locations.length > 0);
    return locations_found;
  }

  calculateDistances(results) {
    let {query} = this.state;
    let {patient} = this.props;
    let {extra_locations} = query.locations;

    for (let result of results) {
      let {trial_locations} = result.trial;
      let locations = [];

      if (patient && patient.locations.length > 0) {
        locations = locations.concat(patient.locations);
      }

      if (extra_locations && extra_locations.length > 0) {
        locations = locations.concat(extra_locations);
      }

      let unmapped_found = false;
      let cache = [];

      for (let location of locations) {
        if (location.lon && location.lat) {
          for (let trial_location of trial_locations) {
            let found = false;
            for (let cancer_center of trial_location.cancer_centers) {
              if (cancer_center.lon && cancer_center.lat) {
                found = true;
                let distance = Math.round(calculateDistance(location, cancer_center));
                cache.push({type: 'cancer_center', location, trial_location, cancer_center, distance});
              }
            }

            if (!found) {
              cache.push({type: 'trial_location', trial_location});
              unmapped_found = true;
            }
          }
        }
      }

      cache = cache.sort(compareGeolocationWithStatus);
      result.trial._geolocation_cache = {cache, unmapped_found};
    }
  }


  setResults(data) {
    let {selection, order_by} = this.state;
    let {results, filters, counts} = data;

    // Filter selected records that are no longed included in the results
    selection = selection.filter(trial_id => results.find(r => r.trial.id === trial_id));


    let changes = {results, filters, counts, selection, loading: false};

    // Calculate distances
    this.calculateDistances(results);

    if (order_by && order_by != DEFAULT_ORDER) {
        results = this.orderResults(order_by, results);
    }

    // If nothing selected, disable the filter
    if (selection.length === 0) changes.only_selected = false;
    this.setState(changes, this.receiveResultsCompleted);
  }


  receiveResultsCompleted() {
    let {results, saved_search} = this.state;
    let result_ids = [];

    for (let {trial} of results) {
      result_ids.push(+trial.id);
    }

    let results_diff;
    if (saved_search) {
      results_diff = this.saveResultsDiff(result_ids, saved_search.result_ids);
    }
    this.setState({result_ids, results_diff});
  }

  changeOrder(order_by) {
    let {results} = this.state;
    results = this.orderResults(order_by, results);
    this.setState({results, order_by});
  }

  orderResults(order_by, results) {
    let compare_fn;
    if (order_by === 'score') {
      compare_fn = orderByScore;
    } else if (order_by === 'rating') {
      compare_fn = orderByRating;
    } else {
      compare_fn = orderByDistance;
    }

    results = results.sort(compare_fn);
    return results;
  }

  saveResultsDiff(result_ids, saved_result_ids) {
    let deleted = []
    for (let id of saved_result_ids) {
      if (result_ids.indexOf(id) === -1) {
        deleted.push(id);
      }
    }
    let deleted_count = deleted.length;

    if (deleted_count > 0) {
      let url = '/admin/trials/as_options?ids=' + deleted.join(',');
      request('GET', url).then(({results}) => {
        this.setState({cached_trials_deleted: results})
      });
    }

    let added = [];
    for (let id of result_ids) {
      if (saved_result_ids.indexOf(id) === -1) {
        added.push(id);
      }
    }

    let added_count = added.length;



    return {added, added_count, deleted, deleted_count};
  }

  exportCsvAll() {
    let {results} = this.state;
    if (!results || results.length === 0) { // only the header
      window.alert("Nothing to export");
      return;
    };

    let content = [['NCT', 'Name', 'Therapy Name', 'Quick References', 'Match Type', 'Population', 'Populations Count']];

    for (let result of results) {
      let row = buildCSVRow(result);
      content.push(row)
    }

    downloadBlob(content.join('\n'), 'trials.csv', 'text/csv;encoding:utf-8');
  }

  exportCsvSelected() {
    let content = [['NCT', 'Name', 'Therapy Name', 'Quick References', 'Match Type', 'Population', 'Populations Count']];

    let {results, selection} = this.state;
    if (!results || results.length === 0) { // only the header
      window.alert("Nothing to export");
      return;
    };

    for (let trial_id of selection) {
      let result = results.find(r => r.trial.id === trial_id)
      if (result) {
        let row = buildCSVRow(result);
        content.push(row)
      }
    }

    if (content.length === 1) { // only the header
      window.alert("Nothing to export. Select results to export");
      return;
    };
    downloadBlob(content.join('\n'), 'trials.csv', 'text/csv;encoding:utf-8');
  }

  downloadPdf(all) {
    let {query, filters, selection} = this.state;
    let {extra_locations} = query.locations;
    let {admin_mode, patient} = this.props;
    if (!query.cancer_type) return ;
    let filtered_filters = cleanupFilters(filters);
    let params = {query, filters: filtered_filters};

    if (admin_mode && patient) {
      params.patient_id = patient.id;
    }

    if (extra_locations && extra_locations.length > 0) {
      params.extra_locations = extra_locations;
    }

    if (!all) params.selection = selection;

    let body = JSON.stringify(params);

    let token = document.querySelector('meta[name="csrf-token"]').content;
    let headers = {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': token
    }

    let url = "/search/export.pdf"
    if (admin_mode) url = "/admin" + url;

    fetch(url, {method: "POST", headers, body: body, credentials: 'include'})
      .then(response => {
        response.blob().then(data => downloadBlob(data, 'report.pdf', 'application/pdf'))

      })
      .catch(e => console.error("ERROR", e));
  }

  openReport(opt={}) {
    let {results, selection} = this.state;

    let popup_props = {};

    if (opt.only_selected) {
      popup_props.results = results.filter(result => selection.indexOf(result.trial.id) !== -1);
    } else {
      popup_props.results = results;
    }

    let popup = window.open('/admin/search/report');
    popup.props = popup_props;;
  }

  changeMapSelection(map_selection) {
    this.setState({map_selection});
  }

  // Mark as selected the markers selected on the map
  selectMapSelection() {
    let {selection, map_selection} = this.state;
    for (let item of map_selection) {
      if (item.results) {
        for (let result of item.results) {
          selection.push(result.trial.id);
        }
      }
    }
    selection = selection.unique();
    this.setState({selection});
    this.afterSelection(selection);
  }

  toggleSelection(trial) {
    let {selection} = this.state;
    let index = selection.indexOf(trial.id);
    if (index != -1) {
      selection.splice(index, 1);
    } else {
      selection.push(trial.id);
    }

    this.setState({selection});
    this.afterSelection(selection);
  }


  afterSelection(selection) {
    // Disable the filter if nothing selected
    if (selection.length === 0) {
      this.setState({only_selected: false});
    }

    // Create event
    let event = new Event('result_stared');
    window.dispatchEvent(event);
  }

  toggleOnlySelectedFilter() {
    let only_selected = !this.state.only_selected;
    this.setState({only_selected});
  }

  toggleDistantLocationsVisibility() {
    let hide_distant_locations = !this.state.hide_distant_locations;
    this.setState({hide_distant_locations});
  }

  toggleNonUSLocationsVisibility() {
    let hide_non_us = !this.state.hide_non_us;
    this.setState({hide_non_us});
  }

  toggleTrialUniquenessFilter() {
    let hide_duplicated_trials = !this.state.hide_duplicated_trials;
    this.setState({hide_duplicated_trials});
  }

  toggleMonitoring() {
    let {saved_search} = this.state;

    let url = '/admin/search/saved_searches/' + saved_search.id;
    if (saved_search.monitorized) {
      url += '/unmonitorize';
    } else {
      url += '/monitorize';
    }

    request('PUT', url).then(({success, new_state}) => {
      saved_search.monitorized = new_state;
      this.setState({saved_search});
    });
  }

  toggleDiffPanel() {
    let show_results_diff_panel = !this.state.show_results_diff_panel;
    this.setState({show_results_diff_panel});
  }

  clearSelection() {
    if (window.confirm('Are you sure you want to unselect all?')) {
      this.setState({only_selected: false, selection: []});
    }
  }
  renderError() {
    return (
      <div>
        <h3 style={{color: "red"}}>Error getting data from the server</h3>
      </div>
    )
  }

  renderLoading() {
    let {loading_starts_at} = this.state;

    return (
      <div className="search_loading">
        <div className="search_loader"></div>
        <p>Finding Matches...</p>
        <TimeCounter start={loading_starts_at} />
      </div>
    )
  }


  renderMapSelection() {
    let {show_map, map_selection} = this.state;
    if (!show_map) return null;
    let disabled = !map_selection || map_selection.length === 0;

    return <button className="btn btn-default" disabled={disabled} onClick={e => this.selectMapSelection()}>Select All</button>
  }



  renderMapFilters() {
    let {show_map, hide_distant_locations, hide_duplicated_trials, hide_non_us} = this.state;
    if (!show_map) return null;
    let locations_found = this.anyLocationAvailable();


    return (
      <div className="map-hide-prefs">
        <p className="pref-header">HIDE <span>(hover to see details):</span></p>
        <div className="checkbox">
          <label  title="Hides all trial locations > 100 miles from the patient locations.">
            <input type="checkbox" checked={locations_found && hide_distant_locations} value={locations_found && hide_distant_locations} onChange={e => this.toggleDistantLocationsVisibility(e)} disabled={!locations_found} />
            Distant Locations
          </label>
        </div>

        <div className="checkbox">
          <label title="Hide duplicated trials.">
            <input type="checkbox" checked={locations_found && hide_duplicated_trials} value={locations_found && hide_duplicated_trials} onChange={e => this.toggleTrialUniquenessFilter(e)} disabled={!locations_found}/>
            Duplicated Trials
          </label>
        </div>


        <div className="checkbox">
          <label  title="Hides all trials locations that are not in the US">
            <input type="checkbox" checked={hide_non_us} value={hide_non_us} onChange={e => this.toggleNonUSLocationsVisibility(e)} />
            Non US Locations
          </label>
        </div>

      </div>
    );
  }

  renderMapToggle() {
    let {show_map, results, show_cancer_centers} = this.state;
    if (!results || results.length === 0) return null;

    let mapStyle = show_map ? "btn btn-success" : "btn btn-default"
    let listStyle = show_map ? "btn btn-default" : "btn btn-success"

    return (
      <div className="btn-group" role="group" id="map-toggle-buttons">
        <button className={listStyle} title="Show Results" disabled={!show_map} onClick={e => this.setState({show_map: false})}><i className="fa fa-list" /></button>
        <button id="show_map" title="Show Map" className={mapStyle} disabled={show_map} onClick={e => this.setState({show_map: true})}><i className="fa fa-map-marked" /></button>
        <button id="show_cancer_centers_list" title="Show Cancer Centes List" className="btn btn-default" onClick={e => this.setState({show_cancer_centers: true})}><i className="fa fa-house-medical"/></button>
      </div>
    );
  }


  renderContent() {
    let {show_map} = this.state;
    if (show_map) {
      return this.renderMap();
    } else {
      return this.renderSearch();
    }
  }


  renderMap() {
    let {patient, admin_mode} = this.props;
    let {results, selection, collections, only_selected, query, hide_distant_locations, hide_duplicated_trials, hide_non_us} = this.state;
    let params = {patient, results, admin_mode, only_selected, selection, collections, query, hide_distant_locations, hide_duplicated_trials, hide_non_us};
    return <ResultsMap {...params} addTrialToCollection={this.addTrialToCollection} toggleSelection={this.toggleSelection} onMapSelectionChange={s => this.changeMapSelection(s)} />
  }


  renderSearch() {
    let {admin_mode, patient} = this.props;
    let {query, filters, counts} = this.state;
    return (
      <div className="row">
        <div className="col-md-5">
          <PopulationFilters onChange={this.change} onChangeFilters={this.changeQueryFilters} query={query} filters={filters} counts={counts} admin_mode={admin_mode} patient={patient} />
        </div>

        <div className="col-md-7 search_panel">
          {this.renderResults()}
        </div>
      </div>

    )
  }

  renderResultsDiff() {
    let {show_results_diff_panel, results_diff, search_results_changed} = this.state;
    if (!results_diff || (results_diff.added_count === 0 && results_diff.deleted_count === 0)) {
      return null;
    }
    let chunks = [];
    if (results_diff.added_count > 0) {
      chunks.push(results_diff.added_count + " new ");
    }
    if (results_diff.deleted_count > 0) {
      chunks.push(results_diff.deleted_count + " removed");
    }
    let message = chunks.join(' and ');
    let diff_panel_action = show_results_diff_panel ? 'Close' : 'Open';
    let set_as_reviewed_title;
    if (search_results_changed) {
      set_as_reviewed_title = "The current search has changed";
    }
    return (
      <div id="results_diff">
        <div className="monitoring_results">
          <p>{message} <button className="btn btn-link btn-sm" onClick={e => this.toggleDiffPanel()}>{diff_panel_action} Details</button></p>
          <button className="btn btn-success btn-sm" disabled={search_results_changed} title={set_as_reviewed_title} onClick={e => this.update_saved_results()}>Set as reviewed</button>
        </div>
        {this.renderResultsDiffPanel()}
      </div>
    )
  }

  renderResultsDiffPanel() {
    let {show_results_diff_panel, results, saved_search, results_diff, cached_trials_deleted} = this.state;
    if (!show_results_diff_panel) return null;

    let added_el, deleted_el;
    if (results_diff.added_count > 0) {
      let added = results_diff.added.map(id => {
        let index = results.findIndex(r => r.trial.id === id);
        let name = `${results[index].trial.name} [${results[index].trial.identifier}]`;
        let display_index = results[index].index;
        return {id, name, index: display_index}
      });

      added_el = (
        <div>
          <h5><b>New Matches Since Last Review</b> (find and review in results below)</h5>
          <hr/>
          <ul className="added_matches_list">
            {added.map(({id, name, index}) => {
              let link = `/admin/trials/${id}`;
              return (
                <li key={id}>
                  <span className="added_index_number">#{index}</span>
                  <a href={link} target="_blank">{name}</a>
                </li>
              )
            })}
          </ul>
        </div>
      )

    }

    if (results_diff.deleted_count > 0 && cached_trials_deleted) {
      let deleted = results_diff.deleted.map(id => {
        let index = saved_search.result_ids.findIndex(saved_id => saved_id === id);
        let result_index = cached_trials_deleted.findIndex(r => r.id === id);
        let name = cached_trials_deleted[result_index] ? cached_trials_deleted[result_index].name : false;
        return {id, name, index}
      });

      deleted_el = (
        <div>
          <h5><b>Therapies that no longer match</b> (click to open in a new tab)</h5>
          <hr/>
          <ul>
            {deleted.map(({id, name, index}) => {
              let trial_el;
              if (name) {
                let link = '/admin/trials/' + id;
                trial_el = <a href={link} target="_blank">{name}</a>
              } else {
                trial_el = '* Trial Deleted with id: ' + id + ' *';
              }
              return <li key={id}>{trial_el} <span className="subtle_text">#{index + 1}</span></li>
            })}
          </ul>
        </div>
      )
    }
    return (
      <div id="results_diff_panel">
        <button className="btn btn-link pull-right" onClick={e => this.toggleDiffPanel()}><i className="fa fa-close"></i></button>
        <div id="results_diff_panel_container">
          {added_el}
          {deleted_el}
        </div>
      </div>

    )
  }

  renderResults() {
    let {results, query, filters, selection, counts, only_selected, collections, loading, downloading_chunks, error, saved_search} = this.state;
    let {admin_mode, patient} = this.props;
    if (loading) return this.renderLoading();
    if (error) return this.renderError();
    if (results === undefined) return null;

    let result_els, resultsCountEl, loadingMoreEl;
    if (results) {
      result_els = results.map(r => {
        let selected = selection.indexOf(r.trial.id) !== -1;
        let new_result = saved_search && saved_search.result_ids && saved_search.result_ids.indexOf(r.trial.id) === -1;

        if (only_selected && !selected) return null;
        return <SearchResult {...r} key={r.trial.id} admin_mode={admin_mode} patient={patient} collections={collections} addTrialToCollection={this.addTrialToCollection} onCollectionsChange={c => this.changeCollections(c)} selected={selected} new_result={new_result} selectCallback={this.toggleSelection} extra_locations={query.locations.extra_locations} />
      });

      if (results.length == 0) {
        result_els = <h4 className="no_results_text">No results found</h4>;
      }
    }

    if (counts && counts.populations > 0) {
      resultsCountEl = (
        <div>
          <h2 className="search_header_counts">
            {counts.trials} THERAPY MATCHES <span className="populations_count">{counts.populations} POPULATIONS</span></h2>
        </div>
      )
    }

    if (downloading_chunks) {
      loadingMoreEl = <Loading />
    }

    return (
      <div id="results">
        {this.renderResultsDiff()}
        {resultsCountEl}
        <QueryFilters query={query} filters={filters} onChange={this.changeQueryFilters} onSubmit={this.search}/>
        <SearchFilters query={query} filters={filters}  onChange={this.changeFilters} onChangeAll={this.changeAllFilters} onAddFilter={this.onAddFilter} onSubmit={this.search} results={this.state.results} admin_mode={admin_mode} />
        {result_els}
        {loadingMoreEl}
      </div>
    )

  }

  renderTabsContent() {
    return Object.keys(this.tabs).map(id => {
      let show = id === this.state.tab
      let tab = this.tabs[id]
      let query = this.state.query[id] || {};
      return React.createElement(tab.comp, {show, query, key: "tab_" + id, });
    });
  }

  renderSaveButtons() {
    let btns = [];

    btns.push(<li key="reset_search_btn"><button onClick={e => this.resetSearch()} className="btn btn-link">New Search</button></li>);
    btns.push(<li key="load_search_btn"><button onClick={e => this.showLoadSearch()} className="btn btn-link">Load Search</button></li>);
    btns.push(<li key="save_btn"><button onClick={e => this.setState({show_save_search: true})} className="btn btn-link">Save New Search</button></li>);

    if (this.state.saved_search) {
      btns.push(<li key="update_btn"><button onClick={e => this.setState({show_update_search: true})} className="btn btn-link">Update Saved Search</button></li>);
      if (this.props.admin_mode) {
        btns.push(<li key="duplicate_btn"><button onClick={e => this.duplicateSavedSearch()} className="btn btn-link">Duplicate Search</button></li>);
        btns.push(<li key="copy_btn"><button onClick={e => this.setState({show_copy: true})} className="btn btn-link">Copy to Patient</button></li>);
        btns.push(<li key="unset_patient_btn"><button onClick={e => this.unsetPatient()} className="btn btn-link">Unset Patient</button></li>);
        btns.push(<li key="share_btn"><button onClick={e => this.setState({show_share_modal: true})} className="btn btn-link">Share Search</button></li>);
      }
    }

    return btns;
  }


  renderCollectionButtons() {
    let {patient} = this.props;
    if (!patient) return null;

    return (
      <div>
        <li role="separator" className="divider"></li>
        <li><button onClick={e => this.setState({show_collections_modal: true})} className="btn btn-link">Add Results to Collection</button></li>
      </div>
    );
  }

  renderMonitorizeButtons() {
    let {admin_mode} = this.props;
    let {saved_search} = this.state;
    if (!admin_mode || !saved_search) return null;

    let action = saved_search.monitorized ? 'Disable' : 'Enable';

    return (
      <div>
        <li role="separator" className="divider"></li>
        <li><button onClick={e => this.toggleMonitoring()} className="btn btn-link">{action} Monitoring</button></li>
      </div>
    );
  }

  renderModals() {
    let {admin_mode, patient} = this.props;
    let {query, filters, selection, result_ids} = this.state;

    if (this.state.show_save_search) {
      return <SavedSearches admin_mode={admin_mode} patient={patient} mode="save" query={query} filters={filters} selection={selection} result_ids={result_ids} onClose={e => this.setState({show_save_search: false})} onSave={this.saveSearch} />
    }
    if (this.state.show_load_search) {
      return <SavedSearches admin_mode={admin_mode} patient={patient} mode="load" onClose={e => this.closeLoadSearch()} onLoad={this.loadSavedSearch} onDelete={e=> this.setState({saved_search: null})}/>
    }

    if (this.state.show_update_search) {
      return <SavedSearches admin_mode={admin_mode} patient={patient} mode="update" query={query} filters={filters} selection={selection} result_ids={result_ids} onClose={e => this.setState({show_update_search: false})} saved_search={this.state.saved_search} onSave={this.saveSearch} />
    }

    if (this.state.show_copy) {
      return <CopySavedSearch onClose={e => this.setState({show_copy: false})} patient={patient} saved_search={this.state.saved_search} />
    }


    if (this.state.show_collections_modal) {
      let {results, collections} = this.state;
      return <CollectionsModal collections={collections} patient={patient} results={results} selection={selection} onClose={e => this.setState({show_collections_modal: false})} onCollectionsChange={c => this.changeCollections(c)} />
    }

    if (this.state.show_cancer_centers) {
      let {results, collections} = this.state;
      let props = {admin_mode, patient, query, selection, collections, addTrialToCollection: this.addTrialToCollection, toggleSelection: this.toggleSelection, onCollectionsChange: c => this.changeCollections(c)};
      return <CancerCentersList results={results} onClose={e => this.setState({show_cancer_centers: false})} {...props} />
    }

    if (this.state.show_share_modal) {
      return <ShareSearch onClose={e => this.setState({show_share_modal: false})} saved_search={this.state.saved_search} />
    }

    return null;
  }

  renderOrderSwitch() {
    let {show_map, order_by, results} = this.state;
    if (show_map) return null;
    if (!results || results.length === 0) return null;


    return (
      <div className="selection_filter_box">

        <div className="radio">
          <label>
            <input type="radio" name="sort_order" value="score" checked={order_by == 'score'} onChange={e => this.changeOrder('score')} />
              Most relevant
          </label>
        </div>

        <div className="radio">
          <label>
            <input type="radio" name="sort_order" value="rating" checked={order_by == 'rating'} onChange={e => this.changeOrder('rating')} />
            Reference Rating
          </label>
        </div>

        {this.renderDistanceOrderSwitch()}
      </div>
    );
  }

  renderDistanceOrderSwitch() {
    let {order_by, results} = this.state;
    if (!this.anyLocationAvailable()) return null;
    return (
      <div className="radio">
        <label>
          <input type="radio" name="sort_order" value="distance" checked={order_by == 'distance'} onChange={e => this.changeOrder('distance')} />
          Distance
        </label>
      </div>
    )
  }

  renderSelectionCount() {
    let {results, only_selected, selection, downloading_chunks, counts} = this.state;
    if (downloading_chunks) {
      let text;
      if (counts) {
        let current = results.length || 0
        text = "Loading results " + current + "/" + counts.trials;
      } else {
        text = "Loading results";
      }
      return <Loading size={18} text={text} containerStyle="loading_selection_filters"/>
    }

    if (!results || results.length === 0) return null;

    if (selection.length === 0) {
      return <div className="selection_filter_box" id="selection_empty">
              None Selected
            </div>
    }

    return (
      <div id="selection_count" className="selection_filter_box">
        <span className="selection_count_text">
          <span className="green"><b>{selection.length}</b> </span>
          Selected
        </span>
        <button className="btn btn-default btn-sm" title="Filter Selected" onClick={e => this.toggleOnlySelectedFilter()}>
          <i className="fa fa-filter" data-enabled={only_selected}/>
        </button>
        <button className="btn btn-link btn-sm"onClick={e => this.clearSelection()}>Clear</button>
      </div>
    );
  }

  renderSavedSearchInformation() {
    let {saved_search} = this.state;
    if (!saved_search) return null;
    return (
      <div>
        <strong>Saved Search:</strong> {saved_search.name}
      </div>
    )
  }
  render() {
    let {loading} = this.state;
    let Userholder = this.props.admin_mode ? "admin_search_holder" : "user_search_holder"

    return(
      <div id="searches" className={Userholder} >
        {this.renderSavedSearchInformation()}
        <div id="buttons">
          <button onClick={this.search} className="btn btn-success" disabled={loading}>Run Search</button>
          {this.renderMapToggle()}
          {this.renderMapSelection()}
          {this.renderMapFilters()}

          <div className="pull-right">
            {this.renderOrderSwitch()}
            {this.renderSelectionCount()}
            <div id="search_menu_dropdown" className="dropdown" >
              <button id="dLabel" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="btn btn-primary">
                Search Options <span className="caret"></span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="dLabel">
                {this.renderSaveButtons()}
                {this.renderCollectionButtons()}
                {this.renderMonitorizeButtons()}
                <li role="separator" className="divider"></li>
                <li><button onClick={e => this.exportCsvAll()} className="btn btn-link">Export CSV (All)</button></li>
                <li><button onClick={e => this.exportCsvSelected()} className="btn btn-link">Export CSV (Selected)</button></li>
                <li><button onClick={e => this.downloadPdf(true)} className="btn btn-link">Export PDF (All)</button></li>
                <li><button onClick={e => this.downloadPdf(false)} className="btn btn-link">Export PDF (Selected)</button></li>
                <li role="separator" className="divider"></li>
                <li><button onClick={e => this.openReport({only_selected: false})} className="btn btn-link">Quick Match Report (All)</button></li>
                <li><button onClick={e => this.openReport({only_selected: true})} className="btn btn-link">Quick Match Report (Selected)</button></li>
              </ul>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>



        {this.renderContent()}
        {this.renderModals()}
      <Notifications />
      </div>
    )
  }
}


class PopulationFilters extends Component {
  constructor(props) {
    super(props)
    this.state = {selected: "cancer_type"}
  }


  togglePanel(selected) {
    if (selected === this.state.selected) {
      this.setState({selected: null})
    } else {
      this.setState({selected})
    }
  }


  renderPanelContent(panel_name, component, opts) {
    if (panel_name !== this.state.selected) {
      return null;
    }

    let {onChange, onChangeFilters, query, filters, counts, admin_mode, patient} = this.props;
    let panel_query = opts.raw_query ? query : query[panel_name] || defaultConfig(panel_name);
    let content = React.createElement(component, {counts, query: panel_query, onChange, filters, onChangeFilters, admin_mode, patient});
    return (
      <div className="panel-body">
        {content}
      </div>
    )
  }

  renderPanel(panel_name, title, component, opts={}) {
    return (
      <div className="panel panel-default panel-toggle">
        <div className="panel-heading" onClick={e => this.togglePanel(panel_name)}>
          <h3 className="panel-title">{title} <span className="fa fa-caret-down pull-right"></span></h3>
        </div>
        {this.renderPanelContent(panel_name, component, opts)}
      </div>
    )
  }

  render() {
    return (
      <div id="pop_filters">
        {this.renderPanel("trial_details", "Trial Details", SpecificTrialsDetails, {raw_query: true})}
        {this.renderPanel("patient_details", "Patient Details", PatientDetails)}
        {this.renderPanel("locations", "Locations", LocationsPanel)}
        {this.renderPanel("cancer_type", "Cancer Type", SearchCancerType)}
      </div>
    )
  }
}

const cleanupFilters = filters => {
  let out = {};
  for (let key of Object.keys(filters)) {
    if (Array.isArray(filters[key])) {
      out[key] = filters[key].filter(f => f.selected !== 0);
    } else {
      out[key] = filters[key];
    }
  }
  return out;
}


const defaultConfig = (section) => {
  if (section === "locations") return {only_recruiting: false, states: {}, included_countries: [], excluded_countries: [], overall_status: {RECRUITING: true, NOT_YET_RECRUITING: true, AVAILABLE: true}, extra_locations: []}
  if (section === 'company') return {trial_ids: []};
  return {};
}
const DEFAULT_ORDER = 'score';

const defaultState = (patient) => {
  let locations = defaultConfig("locations")
  let therapy_status = defaultTrialStatus();
  let match_type = {cancer_type: true, cancer_category: true, tumor_type: false};
  let therapy_type =  {therapeutic_trial: true, diagnostic_trial: true};
  let filters = {patient_details: {moa:  [], moa_condition: 'any'}, cancer_type_diagnostics_and_genes: []};
  let query = {locations, therapy_status, match_type, therapy_type, patient_details: {}};
  let state = {query: query, filters: filters, selection: [], tab: 'cancer_type', hide_distant_locations: false, hide_duplicated_trials: false, hide_non_us: true};
  state.saved_search = undefined;
  state.results = undefined;;
  state.counts = undefined;


  if (patient) {

    if (patient.gender) {
      if (patient.gender === 'man') {
        state.query.patient_details.gender = 'male';
      } else if (patient.gender === 'woman') {
        state.query.patient_details.gender = 'female';
      }
    }

    if (patient.date_of_birth) {
      state.query.patient_details.age = moment().diff(patient.date_of_birth, 'years');
    }

    if (patient.locations && patient.locations.length > 0) {
      let geolocated_locations = patient.locations.filter(l => l.lat && l.lat.length > 0 && l.lon && l.lon.length > 0);
      if (geolocated_locations.length > 0) {
        let patient_location = geolocated_locations.find(l => l.primary) || geolocated_locations[0];
        let location = {...patient_location, place_id: `custom_location-${patient_location.id}`, display_name: getLocationLabel(patient_location)};
        state.query.locations.extra_locations.push(location);
      }
    }
    if (patient.cancer_type_details && patient.cancer_type_details.length === 1) {
      state.query.cancer_type = patient.cancer_type_details[0];
      state.query.cancer_type.options = {};
      state.query.cancer_type.tumor_type = {};
    }
  }
  return state;
}

function defaultTrialStatus() {
  let status = {'_empty': true}

  gon.therapyStatus.forEach(({key, label}) => {
    status[key] = (key !== 'archived' && key !== 'on_hold');
  })
  return status;
}


const downloadBlob = (content, fileName, mimeType) => {
   var a = document.createElement('a');
  //mimeType = mimeType || 'application/octet-stream';

  if (navigator.msSaveBlob) { // IE10
    navigator.msSaveBlob(new Blob([content], {type: mimeType}), fileName);

  } else if (URL && 'download' in a) { //html5 A[download]
    a.href = URL.createObjectURL(new Blob([content], {type: mimeType}));
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
  }
}


const SAFE_KEYS = ['nct', 'name', 'therapy_name', 'match_type', 'population_name']
const buildCSVRow = (result) => {

  let {trial, match_label, population_data} = result
  let population_name = population_data[0] && population_data[0].name;

  let data = {
    nct: trial.identifier,
    name: trial.name,
    therapy_type: trial.therapy_type_name,
    match_type: match_label,
    population_name: population_name,
    populations_count: population_data.length
  };


  for (let key of SAFE_KEYS) {
    if (data[key]) data[key] ='"' + data[key] + '"';
  }

  return Object.values(data);
}

class TimeCounter extends Component {
  constructor(props) {
    super(props);
    this.state = {minutes: 0, seconds: 0};
    this.updateTime = this.updateTime.bind(this);
  }
  componentDidMount() {
    this.interval_id = setInterval(this.updateTime, 500);
  }

  componentWillUnmount() {
    clearInterval(this.interval_id);
  }

  updateTime() {
    let now = new Date();
    let elapsed = now - this.props.start;
    elapsed = new Date(elapsed);
    let minutes = elapsed.getMinutes();
    let seconds = elapsed.getSeconds();
    this.setState({minutes, seconds});
  }


  render() {
    let {minutes, seconds} = this.state;

    return (
      <div className="time_counter">
        {minutes}:{seconds}
      </div>
    )
  }

}

function orderByScore(a, b)  {
  return a.index - b.index;
}

function orderByDistance(a, b) {
  let distance_a, distance_b;

  if (a.trial._geolocation_cache.cache && a.trial._geolocation_cache.cache.length > 0) {
    distance_a = a.trial._geolocation_cache.cache[0].distance;
  }

  if (b.trial._geolocation_cache.cache && b.trial._geolocation_cache.cache.length > 0) {
    distance_b = b.trial._geolocation_cache.cache[0].distance;
  }

  if (distance_a === distance_b ){
    return 0;
  }

  if ( distance_b === undefined || distance_a < distance_b ){
    return -1;
  }
  if ( distance_a === undefined || distance_b < distance_a ){
    return 1;
  }

  return 0;
}

function orderByRating(a, b) {
  let rating_a = highEfficacyRatingScore(a);
  let rating_b = highEfficacyRatingScore(b);
  // descending order, high score first
  return rating_b - rating_a;
}

function highEfficacyRatingScore(result) {
  let reference_populations = result.trial.reference_populations_data;
  if (!reference_populations || reference_populations.length === 0) return -99;
  let matched_populations = [];
  let unmatched_populations = [];
  for (let population of reference_populations) {
    if (population.data && population.data.ratings && population.data.ratings.length > 0) {
      if (result.reference_population_ids.indexOf(population.id) === -1) {
        unmatched_populations.push(population);
      } else {
        matched_populations.push(population);
      }
    }
  }

  let matched_efficacy_rating = matched_populations.map(p => Math.max.apply(Math, p.data.ratings.map(r => r.efficacy_rating || 0)));
  let unmatched_efficacy_rating = unmatched_populations.map(p => Math.max.apply(Math, p.data.ratings.map(r => r.efficacy_rating || 0)));

  let max_matched_efficacy_rating = Math.max.apply(Math, matched_efficacy_rating) || -99;
  let max_unmatched_efficacy_rating = Math.max.apply(Math, unmatched_efficacy_rating) || -99;

  let matched_score = max_matched_efficacy_rating * 10;
  let unmatched_score = max_unmatched_efficacy_rating * 9;
  return Math.max(matched_score, unmatched_score);
}


function compareGeolocationWithStatus(a, b) {
  if (a.trial_location && a.trial_location.status === 'RECRUITING' && (!b.trial_location || b.trial_location.status !== 'RECRUITING')) return -1;
  if (b.trial_location && b.trial_location.status === 'RECRUITING' && (!a.trial_location || a.trial_location.status !== 'RECRUITING')) return 1;
  if (a.distance === undefined && b.distance !== undefined) return 1;
  if (a.distance !== undefined && b.distance === undefined) return -1;
  if (a.distance > b.distance) return 1;
  if (a.distance < b.distance) return -1;
  return 0;
}
