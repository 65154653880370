import React, {Component} from 'react';

import Custom from './event_types/custom';
import ComEmail from './event_types/com_email';
import ComPhone from './event_types/com_phone';
import ComVoiceMail from './event_types/com_voice_mail';
import ComFax from './event_types/com_fax';
import ComTextMessage from './event_types/com_text_message';
import ComLetter from './event_types/com_letter';
import ComSage from './event_types/com_sage';
import ComOther from './event_types/com_other';
import ComDecision from './event_types/com_decision';

import MedPathologyReport from './event_types/med_pathology_report';
import MedTumorImaging from './event_types/med_tumor_imaging';
import MedGeneralImaging from './event_types/med_general_imaging';
import MedDoctorNote from './event_types/med_doctor_note';
import MedSequencingReport from './event_types/med_sequencing_report';
import MedLabDiagnostic from './event_types/med_lab_diagnostic';
import MedSurgicalReport from './event_types/med_surgical_report';
import MedOtherMedicalReport from './event_types/med_other_medical_report';

export class EventModal extends Component {
  constructor(props) {
    super(props);
    let event = props.event || buildNewEvent();
    this.state = {event};
  }

  cancel(e) {
    e.preventDefault();
    this.props.onCancel();
  }

  eventTypeChanged(event_type_id) {
    let {settings} = this.props;
    let {event} = this.state;
    event.type = event_type_id;
    event.data = buildEventData({}, event_type_id, settings)
    this.setState({event});
  }

  changeEvent(event) {
    this.setState({event});
  }

  submit(e) {
    e.preventDefault();
    let {event} = this.state;
    let {onChange} = this.props;
    onChange(event);

    let event_data = EVENT_TYPES[event.type];
    if (event_data.default_tab) {
      let custom_event = new CustomEvent('change_tab', {detail: {tab: event_data.default_tab}});
      window.dispatchEvent(custom_event);
    }
  }

  renderEventTypeSelector() {
    let com_opt = COM_EVENTS.map(event_type_id => {
      let event = EVENT_TYPES[event_type_id];
      return <li key={event_type_id}><button className="btn btn-block btn-default" onClick={e => this.eventTypeChanged(event_type_id)}>{event.label}</button></li>
    });
    let med_opt = MED_EVENTS.map(event_type_id => {
      let event = EVENT_TYPES[event_type_id];
      return <li key={event_type_id}><button className="btn btn-block btn-default" onClick={e => this.eventTypeChanged(event_type_id)}>{event.label}</button></li>
    });

    return (
      <div className="jt-event-outer-holder">
        <button className="btn btn-default" onClick={e => this.cancel(e)}>&lt; Back to the Patient</button>
        <div className="jt-event-inner-holder">
          <div className="row">
            <div className="col-md-6">
              <h4 className="jt-add-event-header">Communications</h4>
              <ul className="list-unstyled jt-add-event-list">
                {com_opt}
              </ul>
            </div>
            <div className="col-md-6">
              <h4 className="jt-add-event-header">Medical Record</h4>
              <ul className="list-unstyled jt-add-event-list">
                {med_opt}
              </ul>
            </div>
          </div>
          <button className="btn btn-block btn-default" onClick={e => this.eventTypeChanged('custom')}>Custom Event</button>
        </div>
      </div>
    );
  }

  render() {
    let {event} = this.state;
    if (!event.type) {
      return this.renderEventTypeSelector();
    }

    let {patient} = this.props;
    let {label, components} = EVENT_TYPES[event.type];
    let component = components.editor;
    let name = label + ' Event';
    let props = {patient, name, event};
    props.onChange = e => this.changeEvent(e);
    props.onSubmit = e => this.submit(e);
    props.onCancel = e => this.cancel(e);

    let el = React.createElement(component, props);

    return (
      <div className="jt-event-outer-holder">
        <button className="btn btn-default" onClick={e => this.cancel(e)}>&lt; Back to the Patient</button>
        <div className="jt-event-inner-holder">
          <p><b>{name}</b></p>
          <hr/>
          {el}
        </div>
      </div>
    )
  }
}

export class EventPreview extends Component {
  render() {
    let {patient, event, summary} = this.props;

    let {label, components} = EVENT_TYPES[event.type];
    let component = components.viewer;
    let name = label + ' Event';
    let props = {patient, name, event, summary};

    let el = React.createElement(component, props);
    return (
      <div className="event-preview-content">
        <p><b>{name}</b></p>
        <hr/>
        <div className="event-preview-info">
          {el}
        </div>
      </div>
    )
  }
}

function buildNewEvent() {
  return {files: [], data: {}}
}


function buildEventData(data, type, settings) {
  if (settings.peer_type) {
    let {peer_type, contact_id, medical_team_member_id} = settings;
    data = {peer_type, contact_id, medical_team_member_id};
  }

  if (type !== 'custom') {
    data.date = moment().format('YYYY-M-D');
  }

  return data;
}

const EVENT_TYPES = {
  custom: {label: 'Custom', components: Custom},
  com_email: {label: 'Email', components: ComEmail},
  com_phone: {label: 'Phone', components: ComPhone},
  com_voice_mail: {label: 'Voicemail', components: ComVoiceMail},
  com_fax: {label: 'Fax', components: ComFax},
  com_text_message: {label: 'Text Message', components: ComTextMessage},
  com_letter: {label: 'Letter', components: ComLetter},
  com_sage: {label: 'Sagely Health', components: ComSage},
  com_decision: {label: 'Decision', components: ComDecision},
  com_other: {label: 'Other', components: ComOther},


  med_pathology_report: {label: 'Pathology Report', components: MedPathologyReport, default_tab: 'details'},
  med_tumor_imaging: {label: 'Tumor Imaging', components: MedTumorImaging, default_tab: 'tumors'},
  med_general_imaging: {label: 'General Imaging', components: MedGeneralImaging, default_tab: 'comorbid'},
  med_doctor_note: {label: 'Doctor\'s Note', components: MedDoctorNote, default_tab: 'symptoms'},
  med_sequencing_report: {label: 'Sequencing Report', components: MedSequencingReport, default_tab: 'sequencing'},
  med_lab_diagnostic: {label: 'Lab/Diagnostic', components: MedLabDiagnostic, default_tab: 'labs'},
  med_surgical_report: {label: 'Surgical Report', components: MedSurgicalReport, default_tab: 'interventions'},
  med_other_medical_report: {label: 'Other General Medical Report', components: MedOtherMedicalReport}
}

const COM_EVENTS = Object.keys(EVENT_TYPES).filter(e => e.startsWith('com_'));
const MED_EVENTS = Object.keys(EVENT_TYPES).filter(e => e.startsWith('med_'));


function buildLabels() {
  let labels = {};
  for (let key of Object.keys(EVENT_TYPES)) {
    labels[key] = EVENT_TYPES[key].label;
  }
  return labels;
}

export const EventLabels = buildLabels();
