import React, {Component} from 'react';
import {BaseEventEditor, BaseEventViewer} from './base_event';
import request from '../../../request';
import {RelatedToOptions} from '../../../constants.js.erb';

class MedGeneralImagingEditor extends BaseEventEditor {
  constructor(props) {
    super(props);
    this.state = {other_scan_events: []};
  }
  componentDidMount() {
    this.fetchOtherScanEvents();
  }

  fetchOtherScanEvents() {
    let url = '/admin/patients/' + gon.patientId + '/journey_tracker_events/search?q[event_type_cont]=med_general_imaging';
    request('GET', url).then(events => {
      let other_scan_events = events.map(event => {
        let {scan_type, body_location_scanned} = event.data;
        let date = moment(event.created_at).format('L');
        let text = date + ' - ' + scan_type + ' - ' + body_location_scanned;
        return {id: event.id, text};
      });
      this.setState({other_scan_events});
    });
  }

  formFields() {
    let {patient} = this.props;
    let {other_scan_events} = this.state;
    return formFields(patient, other_scan_events);
  }
}


class MedGeneralImagingViewer extends BaseEventViewer {
  constructor(props) {
    super(props);
    this.state = {other_scan_events: []};
  }
  componentDidMount() {
    this.fetchOtherScanEvents();
  }

  fetchOtherScanEvents() {
    let url = '/admin/patients/' + gon.patientId + '/journey_tracker_events/search?q[event_type_cont]=med_general_imaging';
    request('GET', url).then(events => {
      let other_scan_events = events.map(event => {
        let {scan_type, body_location_scanned} = event.data;
        let date = moment(event.created_at).format('L');
        let text = date + ' - ' + scan_type + ' - ' + body_location_scanned;
        return {id: event.id, text};
      });
      this.setState({other_scan_events});
    });
  }

  formFields() {
    let {patient} = this.props;
    let {other_scan_events} = this.state;
    return formFields(patient, other_scan_events);
  }
}


export default {
  viewer: MedGeneralImagingViewer,
  editor: MedGeneralImagingEditor
}


function formFields(patient, other_scan_events) {
  let contacts = patient.contacts.map(c => {return {id: c.id, text: c.name}});
  let medical_team_members = patient.medical_team_members.map(m => {return {id: m.id, text: m.person.name}});
  let medical_centers = patient.medical_centers.map(mc => { return {id: mc.id, text: mc.cancer_center.name}});
  let select_scan_events = [{id: 'self', text: 'This tumor Imaging'}].concat(other_scan_events);

  return [
    {name: 'date', type: 'date', required: true, label: 'Date Report/Scan was Received by Sagely Health', inSummary: true},
    {name: 'date_estimated', label: 'Date Estimated?', type: 'bool'},
    {name: 'related_to', type: 'select', collection: RelatedToOptions},
    {name: 'medical_record_date', type: 'date', required: true, inSummary: true},
    {name: 'medical_center_id', label: 'Cancer Center', type: 'select2', collection: medical_centers, idField: 'id', labelField: 'text', hint: 'To appear here, the medical center must be added to the patient.'},
    {name: 'scan_type', label: 'Radiology Imaging/Scan Type:', type: 'select', collection: SCAN_TYPES, required: true, inSummary: true},
    {name: 'scan_type_description', type: 'text'},
    {name: 'comparison_scan', type: 'select', collection: other_scan_events, idField: 'id', labelField: 'text', hint: 'If previously added, other general imaging events will be listed here.'},
    {name: 'body_location_scanned', label: 'Major Body Location Scanned', type: 'select', collection: BODY_LOCATION_SCANNED, required: true, inSummary: true},
    {name: 'location_detail', type: 'text'},
    {name: 'overall_result', type: 'select', collection: ['WIthin normal limits', 'Abnormal', 'Unknown', 'No applicable']},
    {name: 'severity', type: 'select', collection: ['Minimal', 'Significant', 'Major/Severe', 'Life-Threatening', 'Unknown', 'Not Applicable']},
    {name: 'impression_findings', label: 'Impression/Findings', type: 'text'},
    {name: 'user', label: 'To whom or how was this imaging report sent to Sagely Health?'},
    {name: 'peer_type', type: 'select', collection: ['patient', 'contact', 'medical_team', 'other'], labelTransform: 'titleize', label: 'Who provided this imaging report to Sagely Health?'},
    {name: 'contact_id', type: 'select2', collection: contacts, visible: e => e.peer_type === 'contact', idField: 'id', labelField: 'text', hint: 'To appear here, contacts must be added to the patient.'},
    {name: 'medical_team_member_id', type: 'select2', collection: medical_team_members, visible: e => e.peer_type === 'medical_team', idField: 'id', labelField: 'text', hint: 'To appear here, medical team members must be added to the patient.'},
    {name: 'description', type: 'text', inSummary: true}
  ];
}



const SCAN_TYPES = ['Echocardiogram (Echo)', 'Computed tomography scan (CT)', 'Computed tomography scan non-contrast (nc-CT)', 'Computed tomography scan with Contrast']
const BODY_LOCATION_SCANNED = ['Heart', 'Chest/Abdomen/Pelvis', 'Abdomen/Pelvis', 'Abdomen', 'Pelvis', 'Chest', 'Brain', 'Spine', 'Bones', 'Full body', 'Extremeties', 'Other'];
