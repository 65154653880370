import SectionWithItems from './section_with_items';

export default class Recent extends SectionWithItems {

  sectionData() {
    let {population} = this.props;
    return population.data.recent;
  }

  submit() {
    let {population, onChange} = this.props;
    let {section_data} = this.state;
    population.data.recent = section_data;
    onChange(population.data);
  }

  sectionDetails() {
    return {title: 'Recent Therapy', subtitle: 'Therapy received within the last 1-6 months.'};
  }
}
