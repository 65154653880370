import React, {Component} from 'react';
import * as Inflector from 'inflected';
import joinReact from './join_react';

export default class GroupsInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  renderItems() {
    let {data, admin_mode} = this.props;

    return data.map(item => {
      let label;
      if (item.type === 'interventions') {
        let name = Inflector.titleize(item.name);
        if (this.props.admin_mode) {
          let link = "/admin/interventions/" + item.id + "/edit";
          label = <a href={link} target="_blank">{name}</a>
        } else {
          label = <span>{name}</span>
        }

        if (!item.moa_type || item.moa_type.length === 0) {
          return <span key={item.key}>{label}</span>;
        }

        return <span key={item.key}>{label}: {item.moa_type}</span>
      }
      else {
        if (this.props.admin_mode) {
          label = <a href={item.link} target="_blank">{item.name}</a>
        } else {
          label = <span>{item.name}</span>
        }

        return <span key={item.key}>{item.type_humanized}: {label}</span>
      }
    });
  }

  render() {
   let {data} = this.props;
    if (!data || data.length === 0) return null;

    return (
      <div>
        {joinReact(this.renderItems(), <span>&nbsp;|&nbsp;</span>)}
      </div>
    );
  }
}
