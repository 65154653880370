import React, {Component} from 'react';
import request from '../request';
import Person from './person';
import PersonForm from './person_form';
import Loading from './loading';

export default class PeopleWidget extends Component {
  constructor(props) {
    super(props);
    let people = props.people.sort(sortByScore);

    this.state={people, search: '', search_results: false}
    this.quickAddPersonCallback = this.quickAddPersonCallback.bind(this);
  }

  componentDidMount() {
    this.refs.container.addEventListener('quick-add-person', this.quickAddPersonCallback, false);
    window.addEventListener('quick-add-person', this.quickAddPersonCallback, false);
  }

  componentWillUnmount() {
    this.refs.container.removeEventListener('quick-add-person', this.quickAddPersonCallback, false);
    window.removeEventListener('quick-add-person', this.quickAddPersonCallback, false);
  }

  quickAddPersonCallback(e) {
    let {data} = e.detail;
    let {context} = this.props;
    let defaults = context && context.defaults ? context.defaults : {}

    let person = Object.assign({country_code: 'US', roles: []}, defaults, data);
    if (person.last_name) {
      person.name = person.last_name;
      delete person.last_name;
    }

    this.setState({person});
    this.fetchSuggestions(person);
  }

  showPersonForm() {
    let {context} = this.props;
    let defaults = context && context.defaults ? context.defaults : {}
    let person = Object.assign({country_code: 'US', roles: []}, defaults);
    this.setState({person})
  }

  fetchSuggestions(person) {
    let url = '/admin/persons/suggestions'
    this.setState({loading_suggestions: true});
    request('PUT', url, {person}).then(suggestions => {
      this.setState({suggestions, loading_suggestions: false});
    });
  }

  changeSearch(e) {
    let {value} = e.target;
    this.setState({search: value});
  }

  search(e) {
    e.preventDefault();
    let {search} = this.state;
    if (!search || strip(search).length < 2) {
      this.setState({search_results: false});
      return;
    };

    let url = '/admin/persons/search?text=' + search;
    request("GET", url).then(results => {
      let {people} = this.state;
      results = results.filter(r => people.findIndex(p => p.id === r.id) === -1)
      this.setState({search_results: results});
    });
  }

  tagNewPerson(person) {
    this.setState({person: undefined});
    this.tagPerson(person);
  }

  tagPerson(person) {
    let {class_name, record_id} = this.props;
    let url = '/admin/persons/' + person.id + '/add'
    request("PUT", url, {class_name, record_id}).then(person => {
      let {people, search_results} = this.state;
      people.push(person);
      people = people.sort(sortByScore);

      // Remove the record from the search results
      if (search_results) {
        let index = search_results.findIndex(p => p.id === person.id);
        search_results.splice(index, 1);
        if (search_results.length === 0) search_results = false;
      }

      this.setState({people, search_results});
    });
  }

  untagPerson(person) {
    let {class_name, record_id} = this.props;
    let url = '/admin/persons/' + person.id + '/remove'
    request("PUT", url, {class_name, record_id}).then(person => {
      let {people} = this.state;
      let index = people.findIndex(p => p.id === person.id);
      people.splice(index, 1);
      this.setState({people});
    });

  }


  tagSuggestedPerson(person) {
    this.tagPerson(person);
    this.setState({suggestions: undefined, person: undefined});
  }

  renderPeople() {
    let {people} = this.state;
    if (people.length === 0) return this.renderNoPeopleTagged();
    return (
      <div className="people-list">
      {people.map(p => {
        return (
          <div key={p.id} className="people-list-person">
            <button className="btn btn-default pull-right" onClick={e => this.untagPerson(p)}>Untag</button>
            <Person person={p} />
            <div className="clearfix"></div>
          </div>
        )
      })}
      </div>
    );
  }

  renderNoPeopleTagged() {
    return (
      <div className="not-found-msg">
        <p>No people added yet.</p>
      </div>
    );
  }


  renderSearch() {
    let {search} = this.state;
    return (
      <div>
        <form onSubmit={e => this.search(e)}>
          <div className="form-group">
            <label className="sr-only">Add Person</label>
            <div className="input-group">
              <input type="text" className="form-control" placeholder="Search People" value={search} onChange={e => this.changeSearch(e)} />
              <div className="input-group-btn">
                <button className="btn btn-default" type="submit"><span className="fa fa-search"/></button>
              </div>
            </div>
          </div>
        </form>

        {this.renderSearchResults()}

      </div>
    );
  }

  renderSearchResults() {
    let {search_results} = this.state;
    if (!search_results) return null;
    if (search_results.length === 0) return this.renderEmptyResults();

    return (
      <div className="people-search-results">
      {search_results.map(p => {
        return (
          <div key={p.id} className="people-list-person search-result">
            <button className="btn btn-success pull-right" onClick={e => this.tagPerson(p)}>Tag</button>
            <Person person={p} />
            <div className="clearfix"></div>
          </div>
        );
      } )}
      </div>
    )
  }

  renderNoSuggestions() {
    return(
      <div>
        <h4><b>Suggestions</b></h4>
        <p><i>No suggestions found</i></p>
      </div>
    );
  }

  renderSuggestions() {
    let {suggestions, loading_suggestions, people} = this.state;
    if (loading_suggestions) return <Loading text="Loading Suggestions" size={21}/>
    if (!suggestions) return null;
    if (suggestions.length === 0) return this.renderNoSuggestions();

    return (
      <div>
        <h4><b>Suggestions</b></h4>
        <div className="suggestion_content">
          {suggestions.map(person => {
            let url = '/admin/persons/' + person.id;
            let action;
            if (people.findIndex(p => p.id === person.id) === -1) {
              action =    <button className="btn btn-success pull-right" onClick={e => this.tagSuggestedPerson(person)}>Tag</button>
            } else {
              action = <span className="badge pull-right">Already Tagged</span>
            }

            return (
              <div key={person.id} className="people-list-person">
                <a href={url} target="_blank">{person.full_name}</a> ({MATCH_TYPE[person.suggestion_score]} Match)
                {action}
                <div className="clearfix"></div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  renderEmptyResults() {
    return (
      <div className="not-found-msg">
        <p className="text-center">No results found</p>
      </div>
    );
  }

  onCancelForm(e) {
    e.preventDefault();
    this.setState({person: undefined})
  }

  renderPersonForm() {
    let {person} = this.state;
    if (!person) {
      return (
        <button className="btn btn-success" onClick={e => this.showPersonForm()}>Add New Person</button>
      );
    }
    return(
      <div className="well">
        <h2>Add New Person</h2>
        <hr/>
        <PersonForm person={person} onSubmit={p => this.tagNewPerson(p)} onCancel={e => this.onCancelForm(e)} />
      </div>
    )
  }

  renderSubheader() {
    let {subheader} = this.props;
    if (!subheader || subheader.length === 0) {
      subheader = 'Tagged Sagely Health Contacts'
    }
    return <h3>{subheader}</h3>
  }

  render() {
    let {container_id, context} = this.props;

    return (
      <div className="people-component" id={container_id} ref="container">
        <h1>People</h1>
        {this.renderSubheader()}
        {this.renderPeople()}
        {this.renderSearch()}
        {this.renderSuggestions()}
        {this.renderPersonForm()}
      </div>
    );
  }
}

function strip(str) {
    return str.replace(/^\s+|\s+$/g, '');
}

function sortByScore(a, b) {
  return b.score - a.score;
}

const MATCH_TYPE = ['Exact Name', 'Name', 'Email', 'Phone']
