import React, {Component} from 'react';
import PatientFileForm from './patient_file_form';
import Loading from './loading';
import request from '../request';

export default class PatientFilePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {cache: {}}
    this.openModal = this.openModal.bind(this);
    this.onFileSelected = this.onFileSelected.bind(this);
  }

  componentDidMount() {
    this.fetchValueFiles();
  }

  fetchValueFiles() {
    let {value, multiple} = this.props;
    if (!value || (multiple && value.length === 0)) return;

    this.setState({loading: true});
    let {patient} = this.props;
    let ids = multiple ? value.join(',') : value;
    let url = '/admin/patients/' + patient.id + '/files/as_options?ids=' + ids;

    request('GET', url).then(({results}) => {
      let cache = {};
      for (let f of results) { cache[f.id] = f; }
      this.setState({cache, loading: false});
    });

  }


  onFileSelected(file) {
    let {cache} = this.state;
    cache[file.id] = file;
    this.setState({cache});

    let {value, multiple, onChange} = this.props;
    if (multiple) {
      value.push(file.id);
    } else {
      value = file.id;
    }

    onChange(value);
  }


  onFileDeleted(file) {
    let {value, multiple, onChange} = this.props;
    if (multiple) {
      let index = value.findIndex(id => file.id === id);
      value.splice(index, 1);
    } else {
      value = null;
    }

    onChange(value);
  }

  openModal() {
    this.setState({show_modal: true});
  }

  renderModal() {
    let {show_modal} = this.state;
    if (!show_modal) return null;

    let {patient, value, multiple} = this.props;
    return <Modal patient={patient} value={value} multiple={multiple} onClose={e => this.setState({show_modal: false})} onSelect={this.onFileSelected} />
  }

  render() {
    let {patient, label, value, multiple} = this.props;
    let {cache} = this.state;
    return (
      <div>
        <Input label={label} patient={patient} multiple={multiple} value={value} cache={cache} onClick={this.openModal} onDelete={ f => this.onFileDeleted(f)} />
        {this.renderModal()}
      </div>
    );
  }
}


class Input extends Component {
  deleteFile(e, file) {
    e.preventDefault();
    e.stopPropagation();
    let {onDelete} = this.props;
    onDelete(file);
  }

  renderFile(file_id) {
    let {cache, onDelete} = this.props;
    let file = cache[file_id];
    if (!file) return null;

    return (
      <div className="file" key={file.id}>
        <span>{file.filename}</span>
        <button onClick={e => this.deleteFile(e, file)} className="btn btn-sm btn-link"><i className="glyphicon glyphicon-remove" /></button>
      </div>
    );
  }


  renderValue() {
    let {value, multiple} = this.props;

    if (multiple) {
      return value.map(file_id => this.renderFile(file_id))
    } else {
      return this.renderFile(value)
    }
  }

  render () {
    let {label, files, onClick} = this.props;
     return (
      <div className='form-group'>
        <label>{label}</label>
        <div className="form-control patient-file-picker" onClick={onClick}>
          {this.renderValue()}
        </div>
      </div>
    )

  }
}


class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {order: 'date', query: ''}
  }

  componentDidMount() {
    this.fetchFiles();
    let el = $(this.refs.modal_container);
    el.modal({show: true});
    el.on('hide.bs.modal', () => {
      this.props.onClose();
    });
  }

  cancel() {
    $(this.refs.modal_container).modal('hide');
    //this.props.onClose();
  }


  fetchFiles() {
    this.setState({loading: true});
    let {patient, value, multiple} = this.props;
    let url = '/admin/patients/' + patient.id + '/files';
    request('GET', url).then(f => {
      let all_files;
      if (multiple) {
        all_files = f.filter(f => value.findIndex(v => v === f.id) === -1)
      } else {
        all_files = f.filter(f => f.id !== value)
      }

      let files = all_files.sort(SORT_FUNCTIONS[this.state.order]);
      this.setState({all_files, files, loading: false});
    });
  }

  changeQuery(e) {
    let query = e.target.value;
    let files;
    if (query && query.length > 0) {
      var re = new RegExp(query, "gi");
      files = this.state.all_files.filter(file => file.filename.match(re) !== null || (file.description && file.description.match(re) !== null));
    } else {
      files = this.state.all_files;
    }
    this.setState({files, query});
  }

  sort(order) {
    let files = this.state.all_files.sort(SORT_FUNCTIONS[order]);
    this.setState({order, files});
  }

  select(file) {
    let {onSelect} = this.props;
    onSelect(file);
    this.cancel();
  }


  addFile() {
    this.setState({show_form: true})
  }

  onFileAded(file) {
    let {all_files, order} = this.state;
    all_files.push(file);
    let files =  all_files.sort(SORT_FUNCTIONS[order]);
    this.setState({all_files, files, show_form: false, query: ''});
  }

  renderPicker() {
    let {loading, files, query} = this.state;
    if (loading) return <Loading />
    if (!files) return null;

    return (
      <div>
        <div className="row">
          <div className="col-xs-9">
          <div className="form-group">
            <input type="text" value={query} onChange={e => this.changeQuery(e)} className="form-control" placeholder="Search Files" />
          </div>

          </div>
          <div className="col-xs-3">
            <button className="btn btn-success pull-right" onClick={e => this.addFile()}>Add New File</button>
          </div>
        </div>

        <table className="table table-condensed table-striped jt-log-table">
          <thead>
            <tr>
              <th></th>
              <th><button onClick={e => this.sort('name')} className="btn btn-link">File</button></th>
              <th>Description</th>
              <th><button onClick={e => this.sort('date')} className="btn btn-link">Created at</button></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {files.map(file => {

              let icon;
              if (file.preview_url) {
                icon = <img src={file.preview_url} />
              }
              let date = moment(file.created_at).format('LL');
              return(
                <tr key={file.id}>
                  <td>{icon}</td>
                  <td><a href={file.url}>{file.filename}</a></td>
                  <td>{file.description}</td>
                  <td>{date}</td>
                  <td>
                    <button className="btn btn-default btn-sm" onClick={e => this.select(file)}>Select</button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    );
  }


  renderForm() {
    let {patient} = this.props;
    return <PatientFileForm patient={patient} record={{}} onCancel={e => this.setState({show_form: false})} onSubmit={f => this.onFileAded(f)} containerElement='div' />
  }

  renderContent() {
    let {show_form} = this.state;
    if (show_form) {
      return this.renderForm();
    } else {
      return this.renderPicker();
    }
  }

  render() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="pull-left"><b>Files</b></h3>
              <button type="button" className="close" aria-label="Close" onClick={e => this.cancel()}><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              {this.renderContent()}
            </div>

            <div className="modal-footer">
              <div className="btn-group pull-right">
                <button type="button" className="btn btn-default" onClick={e => this.cancel()}>Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}


function sortByDate(a, b) {
  return new Date(b.created_at) - new Date(a.created_at);
}

function sortByFilename(a, b) {
  if (a.filename > b.filename) return 1;
  if (a.filename < b.filename) return -1;
  return 0;
}


const SORT_FUNCTIONS = {
  date: sortByDate,
  name: sortByFilename
}
