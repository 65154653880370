import React, {Component} from 'react';
import request from '../request';
import GroupsContainer from './groups_container';

export default class TrialGroupsInference extends Component{
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {} = this.state;
    let {groups, current_admin} = this.props;
    return (
      <div>
        <h1>Groups</h1>
        <GroupsContainer groups={groups} onChange={g => this.onChangeGroups(g)} closeable={true} currentAdmin={current_admin} />
      </div>
    )
  }
}
