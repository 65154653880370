import React, {Component} from 'react';
import Population from './populations/population';
import Summary from './populations/summary';
import Loading from './loading';
import request from '../request';

export default class PopulationsBulkEditor extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();
    this.changePopulation = this.changePopulation.bind(this);
    this.changeSelectedPopulations = this.changeSelectedPopulations.bind(this);
  }

  defaultState() {
    return {step: 0, selected_population_ids: [], population: populationDefaults(), reverse_migration: false};
  }

  submit() {
    this.setState({submitting: true, step: 99});
    let {trial_id} = this.props;
    let {population, selected_population_ids, reverse_migration} = this.state;
    let url = `/admin/trials/${trial_id}/populations_bulk_editor`;
    let bulk_edit_params = {changes: population.data, selected_population_ids, reverse_migration};
    request('POST', url, {bulk_edit_params}).then(summary_blocks => {
      this.setState({submitting: false});
    });
  }

  reset() {
    let state = this.defaultState();
    this.setState(state);
  }

  cancel() {
    if (!confirm('Are you sure? If you cancel, all work will be lost.')) return;
    let {trial_id} = this.props;
    let url = `/admin/trials/${trial_id}/populations`;
    window.location = url;
  }

  nextStep() {
    let step = this.state.step + 1;
    this.setState({step});
  }

  prevStep() {
    let step = this.state.step - 1;
    this.setState({step});
  }

  changeSelectedPopulations(selected_population_ids) {
    this.setState({selected_population_ids});
  }

  changePopulation(data) {
    let {population} = this.state;
    population.data = data;
    this.setState({population});
  }

  toggleReverseMigration() {
    let reverse_migration = !this.state.reverse_migration;
    this.setState({reverse_migration});
  }

  renderPopulationEditor() {
    let {population} = this.state;
    return (
      <div>
        <div className="bulk_editor_header">
          <button onClick={e => this.prevStep()} className="btn btn-success">Previous</button>
          <h3>Set the Population Changes <button className="btn btn-link" onClick={e => this.cancel()}>cancel</button></h3>
          <button onClick={e => this.nextStep()} className="btn btn-success pull-right">Next</button>
        </div>
        <Population population={population} onChange={this.changePopulation} tabs={POPULATION_TABS} extraProps={EXTRA_PROPS}/>
      </div>
    );
  }

  renderSummary() {
    let {trial_id, populations} = this.props;
    let {population, selected_population_ids} = this.state;
    window.population = population;
    return (
      <div>
        <div className="bulk_editor_header">
          <button onClick={e => this.prevStep()} className="btn btn-success">Previous</button>
          <h3>Summary (Please Confirm) <button className="btn btn-link" onClick={e => this.cancel()}>cancel</button></h3>
          <div>&nbsp;</div>
        </div>
        <p><b>The Following Populations Will Be Updated:</b></p>
        <ul>
        {selected_population_ids.map(population_id => {
          let selected_population = populations.find(p => p.id === population_id);
          return (
            <li key={population_id}>{selected_population.name}</li>
          )
        })}
        </ul>
        <hr/>
        <p><b>These Changes Will Be Made (Open Panels to Review):</b></p>
        <ChangesSummary trial_id={trial_id} population={population} />
        <div className="bulk_editor_submit_section">
          <button className="btn btn-success btn-lg" onClick={e => this.submit()}>Submit</button>
          <p><i>If everything looks good, you may submit. This cannot be undone.</i></p>
        </div>
      </div>
    )
  }

  renderSubmitting() {
    let {submitting} = this.state;
    let {trial_id} = this.props;
    if (submitting) return <Loading />
    let link_to_trial = `/admin/trials/${trial_id}`;
    let link_to_populations = `/admin/trials/${trial_id}/populations`;
    return (
      <div className="bulk_editor_completed_holder">
        <div className="bulk_editor_completed_box">
          <div className="bulk_editor_confirm_icon">
            <i className="fa fa-check"></i>
          </div>
          <h3><b>Changes Completed</b></h3>
          <h4 className="bulk_editor_next_header">WHAT WOULD YOU LIKE TO DO NEXT?</h4>
          <div>
            <button className="btn btn-link" onClick={e => this.reset()}>Make Another Bulk Edit</button>
          </div>
          <div>
            <a href={link_to_trial}>View Trial</a>
            &nbsp;&nbsp;&bull;&nbsp;&nbsp;
            <a href={link_to_populations}>View All Populations</a>
          </div>
        </div>
      </div>
    )
  }


  renderPopulationsPicker() {
    let {populations} = this.props;
    let {selected_population_ids, reverse_migration} = this.state;
    let next_button_disabled = selected_population_ids.length === 0;
    return (
      <div>
        <div className="bulk_editor_header">
          <div>&nbsp;</div>
          <h3>Select the Populations <button className="btn btn-link" onClick={e => this.cancel()}>cancel</button></h3>
          <button onClick={e => this.nextStep()} className="btn btn-success" disabled={next_button_disabled} >Next</button>
        </div>

        <PopulationsPicker populations={populations} selected_population_ids={selected_population_ids} onChange={this.changeSelectedPopulations} />

        <h4><b>Need to Remove Data?</b></h4>
        <div className="bulk_editor_population_options">
          <label>
            <input type="checkbox" value={reverse_migration} onChange={e => this.toggleReverseMigration()} />
            Yes, I want to make changes to remove instead of add
          </label>
        </div>

      </div>
    )
  }

  renderContent() {
    let {step} = this.state;
    if (step === 0) {
      return this.renderPopulationsPicker();
    } else if (step === 1) {
      return this.renderPopulationEditor();
    } else if (step === 2) {
      return this.renderSummary();
    } else if (step === 99) {
      return this.renderSubmitting();
    }
    return null;
  }

  render() {
    return (
      <div className="bulk_editor_overall_holder">
        {this.renderContent()}
      </div>
    );
  }
}

class PopulationsPicker extends Component {

  selectAll() {
    let {populations, onChange} = this.props;
    let selected_population_ids = populations.map(p => p.id);
    onChange(selected_population_ids);
  }

  selectNone() {
    let {onChange} = this.props;
    onChange([]);
  }

  togglePopulation(population_id) {
    let {selected_population_ids, onChange} = this.props;
    let index = selected_population_ids.indexOf(population_id);
    if (index === -1) {
      selected_population_ids.push(population_id);
    } else {
      selected_population_ids.splice(index, 1);
    }
    onChange(selected_population_ids);
  }

  render() {
    let {populations, selected_population_ids} = this.props;
    return (
      <div>
        <div className="btn-group">
          <button className="btn btn-default btn-sm" onClick={e => this.selectAll()}>Select All</button>
          <button className="btn btn-default btn-sm" onClick={e => this.selectNone()}>Select None</button>
        </div>
        <div className="bulk_editor_options_holder">
          {populations.map(population => {
            let selected = selected_population_ids.indexOf(population.id) !== -1;
            let reference_badge;
            if (population.reference_id) {
              reference_badge = <span className="badge">Reference</span>
            }
            return (
              <div key={population.id} className="bulk_editor_population_options">
                <label>
                  <input type="checkbox"  selected={selected} checked={selected} onChange={e => this.togglePopulation(population.id)} />
                  {population.name} {reference_badge}
                </label>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

}

class ChangesSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {loading: true};
  }

  componentDidMount() {
    this.fetchSummary();
  }

  fetchSummary() {
    this.setState({loading: true});
    let {population, trial_id} = this.props;
    let url = `/admin/trials/${trial_id}/populations_bulk_editor/summary`;
    request('POST', url, {population}).then(summary_blocks => {
      this.setState({summary_blocks, loading: false});
    });
  }

  render() {
    let {loading, summary_blocks} = this.state;
    if (loading) return <Loading />
    return <Summary population={{summary: summary_blocks}} hide_links={true} />
  }
}

const EXTRA_PROPS = {
  prior_therapy: {reseteable: true},
  recent: {reseteable: true},
  concurrent: {reseteable: true},
  genes_markers: {reseteable: true}
}

const POPULATION_TABS = ['patient_details', 'prior_therapy', 'recent', 'concurrent', 'genes_markers'];

function populationDefaults() {
  let data =  {
    prior_therapy:{
      intervention_combinations: {required:[],prohibited:[]},
      moa_and_families:{ required:[], prohibited:[]},
      major_classes:{ required:[], prohibited:[]}
    },
    concurrent:{
      intervention_combinations:{ required:[], prohibited:[]},
      moa_and_families:{ required:[], prohibited:[]},
      major_classes:{ required:[], prohibited:[]}
    },
    recent:{
      intervention_combinations:{ required:[], prohibited:[]},
      moa_and_families:{ required:[], prohibited:[]},
      major_classes:{ required:[], prohibited:[]}
    }
  }

  return {data};

}
