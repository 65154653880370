import React, {Component} from 'react';
import Form from './form';
import request from '../request';
import {Countries} from '../constants.js.erb';

export class List extends Component {
  constructor(props) {
    super(props);
    let {trials_available} = props;
    this.state = {trials_available};
    this.trialDeferredCallback = this.trialDeferredCallback.bind(this);
  }

  componentDidMount() {
    window.addEventListener('trial_deferred', this.trialDeferredCallback, false);
  }

  componentWillUnmount() {
    window.removeEventListener('trial_deferred', this.trialDeferredCallback);
  }

  trialDeferredCallback(e) {
    let {trial_available} = e.detail;
    let {trials_available} = this.state;
    trials_available = trials_available.filter(t => t.id !== trial_available.id);
    this.setState({trials_available});
  }


  deferTrial(trial_available) {
    let event = new CustomEvent('defer_requested', {detail: {trial_available}});
    window.dispatchEvent(event);
  }

  undeferTrial(trial_available) {
    if (!confirm('Are you sure you want to undefer this therapy?')) return;
    let url = '/admin/trials_availables/' + trial_available.id + '/undefer';
    request('POST', url).then(({ok, link}) => {
      if (ok) {
        let trials_available = this.state.trials_available.filter(t => t.id !== trial_available.id);
        this.setState({trials_available});
      }
    });
  }


  importTrial(trial_available) {
    if (!confirm('Are you sure you want to import this therapy?')) return;
    let url = '/admin/trials_availables/' + trial_available.id + '/import';
    request('POST', url).then(({ok, link}) => {
      if (ok) {
        let trials_available = this.state.trials_available.map(t => {
          if (t.id === trial_available.id) {
            t._link = link;
          }
          return t;
        });
        this.setState({trials_available});
      }
    });
  }

  renderCancerTypes(trial_available) {
    let cancer_types = trial_available.cancer_types
    if (!cancer_types || cancer_types.length === 0) return null;
    return (
      <ul>
        {cancer_types.map(a =>
          <li key={a.id}><a href={`/admin/cancer_types/${a.id}`} target="_blank">{a.name}</a></li>
        )}
      </ul>
    )
  }

  renderCancerCategories(trial_available) {
    let cancer_categories = trial_available.cancer_categories
    if (!cancer_categories || cancer_categories.length === 0) return null;
    let links = cancer_categories.map(a => <a href={`/admin/cancer_categories/${a.id}`} target="_blank">{a.name}</a>)
    return (
      <ul>
        {cancer_categories.map(a =>
          <li key={a.id}><a href={`/admin/cancer_categories/${a.id}`} target="_blank">{a.name}</a></li>
        )}
      </ul>
    )
  }


  renderTumorTypes(trial_available) {
    let tumor_types = trial_available.tumor_types
    if (!tumor_types || tumor_types.length === 0) return null;
    return (
      <ul>
        {tumor_types.map(a =><li key={a.id}>{a.name}</li>)}
      </ul>
    )
  }

  renderCountries(trial_available) {
    if (!trial_available.countries || trial_available.countries.length === 0) return null;

    let countries = trial_available.countries.map(country_code => {
      let country = Countries.find(c => c.id === country_code);
      return country.name;
    });
    return countries.join(', ');
  }

  renderDeferred(trial_available) {
    let deferred_at = moment(trial_available.deferred_at).format('LLL');

    let study_first_posted, has_us_locations;
    if (trial_available.study_first_posted) {
      study_first_posted = moment(trial_available.study_first_posted).format('LLL');
    }

    if (trial_available.has_us_locations === true) {
      has_us_locations = 'Yes';
    } else if (trial_available.has_us_locations === false) {
      has_us_locations = 'No';
    } else {
      has_us_locations = 'Unknown';
    }

    let defer_reason;
    if (trial_available.deferred_reason) {
      defer_reason = DEFER_REASONS[trial_available.deferred_reason]
    }

    let score = this.props.super_admin ? <td>{trial_available.score}</td> : null;

    return (
      <tr key={trial_available.id} id={"trial_available-" + trial_available.id}>
        {score}
        <td>{deferred_at}</td>
        <td>{trial_available.deferred_by_name}</td>
        <td>{study_first_posted}</td>
        <td><a href={trial_available.study_url} target="_blank">{trial_available.nct_id}</a></td>
        <td>{ trial_available.title }</td>
        <td>{ trial_available.condition_summary }</td>
        <td>{ trial_available.intervention_summary }</td>
        <td>{ trial_available.status_humanized }</td>
        <td>{has_us_locations}</td>
        <td>{ trial_available.study_type }</td>
        <td>{defer_reason}</td>
        <td>{trial_available.deferred_notes}</td>
        <td className="actions text-center">
          <button className="btn btn-sm btn-default" onClick={e => this.undeferTrial(trial_available)}>Undefer</button>
        </td>
      </tr>
    );
  }

  renderImported(trial_available) {
    let downloaded_at = moment(trial_available.downloaded_at).format('LLL');

    let study_first_posted, has_us_locations;
    if (trial_available.study_first_posted) {
      study_first_posted = moment(trial_available.study_first_posted).format('LLL');
    }

    if (trial_available.has_us_locations === true) {
      has_us_locations = 'Yes';
    } else if (trial_available.has_us_locations === false) {
      has_us_locations = 'No';
    } else {
      has_us_locations = 'Unknown';
    }

    let score = this.props.super_admin ? <td>{trial_available.score}</td> : null;
    let link_to_trials_index = '/admin/trials?query=' + trial_available.nct_id;

    return (
      <tr key={trial_available.id} id={"trial_available-" + trial_available.id}>
        {score}
        <td>{downloaded_at}</td>
        <td>{study_first_posted}</td>
        <td><a href={trial_available.study_url} target="_blank">{trial_available.nct_id}</a></td>
        <td>{ trial_available.title }</td>
        <td>{ trial_available.condition_summary }</td>
        <td>{ trial_available.intervention_summary }</td>
        <td>{ trial_available.status_humanized }</td>
        <td>{has_us_locations}</td>
        <td>{ trial_available.study_type }</td>
        <td>{this.renderTumorTypes(trial_available)}</td>
        <td>{this.renderCancerCategories(trial_available)}</td>
        <td>{this.renderCancerTypes(trial_available)}</td>
        <td>{this.renderCountries(trial_available)}</td>
        <td className="actions text-center">
          <a href={link_to_trials_index} target="_blank">Trials</a>
        </td>
      </tr>
    );
  }


  renderAvailableButtons(trial_available) {
    if (trial_available._link) {
      return (
        <td className="actions text-center">
          <a href={trial_available._link} target="_blank" className="btn btn-sm btn-default">Open Trial</a>
        </td>
      )
    } else {
      return (
        <td className="actions text-center">
          <button className="btn btn-sm btn-default" onClick={e => this.deferTrial(trial_available)}>Defer</button>
          <button className="btn btn-sm btn-default" onClick={e => this.importTrial(trial_available)}>Import</button>
        </td>
      )
    }

  }

  renderAvailable(trial_available) {
    let downloaded_at = moment(trial_available.downloaded_at).format('LLL');

    let study_first_posted, has_us_locations;
    if (trial_available.study_first_posted) {
      study_first_posted = moment(trial_available.study_first_posted).format('LLL');
    }

    if (trial_available.has_us_locations === true) {
      has_us_locations = 'Yes';
    } else if (trial_available.has_us_locations === false) {
      has_us_locations = 'No';
    } else {
      has_us_locations = 'Unknown';
    }

    let score = this.props.super_admin ? <td>{trial_available.score}</td> : null;

    return (
      <tr key={trial_available.id} id={"trial_available-" + trial_available.id}>
        {score}
        <td>{downloaded_at}</td>
        <td>{study_first_posted}</td>
        <td><a href={trial_available.study_url} target="_blank">{trial_available.nct_id}</a></td>
        <td>{ trial_available.title }</td>
        <td>{ trial_available.condition_summary }</td>
        <td>{ trial_available.intervention_summary }</td>
        <td>{ trial_available.status_humanized }</td>
        <td>{has_us_locations}</td>
        <td>{ trial_available.study_type }</td>
        <td>{this.renderTumorTypes(trial_available)}</td>
        <td>{this.renderCancerCategories(trial_available)}</td>
        <td>{this.renderCancerTypes(trial_available)}</td>
        <td>{this.renderCountries(trial_available)}</td>

        {this.renderAvailableButtons(trial_available)}
      </tr>
    );
  }

  render() {
    let {trials_available} = this.state;
    let {action} = this.props;

    return trials_available.map(trial_available => {
      if (action === 'deferred') {
        return this.renderDeferred(trial_available);
      } else if (action === 'imported') {
        return this.renderImported(trial_available);
      } else {
        return this.renderAvailable(trial_available);
      }
    });


  }
}

export class DeferModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    $(this.refs.modal_container).modal({show: false});
    // $(this.refs.modal_container).on('hide.bs.modal', () => {

    // });
    window.addEventListener('defer_requested', this.showModal, false);
  }

  componentWillUnmount() {
    window.removeEventListener('defer_requested', this.showModal);
  }

  submit() {
    let {trial_available, defer_data} = this.state;
    let url = '/admin/trials_availables/' + trial_available.id + '/defer';
    request('POST', url, {defer_data}).then(({ok}) => {
      if (ok) {
        let event = new CustomEvent('trial_deferred', {detail: {trial_available}});
        window.dispatchEvent(event);
        this.closeModal();
      }
    });
  }

  onChange(defer_data) {
    this.setState({defer_data});
  }

  showModal(e) {
    let {trial_available} = e.detail;
    let defer_data = {}
    this.setState({trial_available, defer_data}, () => {
      $(this.refs.modal_container).modal('show');
    });
  }

  closeModal() {
    $(this.refs.modal_container).modal('hide');
  }

  formFields() {
    return [
      {name: 'deferred_reason', label: 'Reason', type: 'select', collection: DEFER_REASONS, required: true},
      {name: 'deferred_notes', label: 'Notes', type: 'text'}
    ];
  }

  renderForm() {
    let {trial_available, defer_data} = this.state;
    if (!trial_available) return null;
    return <Form model={defer_data} fields={this.formFields()} onChange={this.onChange} onSubmit={this.submit} onCancel={this.closeModal} submitLabel="Defer Trial"/>
  }

  render() {
    let {trial_available} = this.state;
    let title = "Defer Trial";
    if (trial_available) {
      title += ' ' + trial_available.nct_id;
    }
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">{title}</h4>
            </div>
            <div className="modal-body">
              {this.renderForm()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const DEFER_REASONS = gon.deferReasons;
