import React, {Component} from 'react';
import MapViewer from './map_viewer';
import Form from './form';
import Loading from './loading';

import request from '../request';
import {calculateDistance} from './geolocation';
import {States, CancerCenterTagSearchKey} from '../constants.js.erb'

const GREEN = '#55ba47';
const BLUE = '#008bff';
const GREY = '#737373';
const LIGHT_GRAY = '#989898';
const MARGIN_BOTTOM = 120;

const DEFAULT_RADIUS = '100';

export default class PatientCancerCenter extends Component {
  constructor(props) {
    super(props);
    this.patient_location_options = buildPatientLocationOptions(props.patient);
    let patient_location_ids = this.patient_location_options.map(l => l.id);
    let filters = {patient_location_ids, radius: DEFAULT_RADIUS};
    let markers = this.buildMarkers({filters});

    this.state = {markers, filters, show_map: true};

    this.resizeWindow = this.resizeWindow.bind(this);
    this.changeFilters = this.changeFilters.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeWindow, false);
    this.resizeWindow();
  }

  resizeWindow() {
    let {container} = this.refs;
    if (!container) return;

    let window_height = window.innerHeight;
    let height = window_height - container.offsetTop - MARGIN_BOTTOM;
    this.setState({height});
  }

  changeFilters(filters) {
    this.setState({filters});
  }

  search() {
    this.setState({searching: true, selected: undefined});
    let {patient} = this.props;
    let {filters} = this.state;
    let url = `/admin/patients/${patient.id}/cancer_centers`;

    request('PUT', url, {filters}).then(cancer_centers => {
      cancer_centers = calculateAllDistances(patient, cancer_centers, filters.patient_location_ids);

      let {selected} = this.state;
      let markers = this.buildMarkers({cancer_centers, filters});
      this.setState({markers, cancer_centers, searching: false}, this.resizeWindow);
    }).catch(e => {
      this.setState({searching: false});
    });

  }

  buildMarkers({cancer_centers, filters, selected}) {
    let {patient} = this.props;

    let markers = []

    // Create the markers for patient locations
    let {patient_location_ids} = filters;
    let style = {color: BLUE, icon: 'home'}
    for (let location of patient.locations) {
      let {id, lat, lon, primary} = location;
      if (lat && lon && patient_location_ids.findIndex(enabled_location_id => id === enabled_location_id) !== -1) {
        markers.push({lat, lon, style, zIndexOffset: 999})
      }
    }

    // Create markers for cancer centers
    if (cancer_centers) {
      for (let center of cancer_centers) {
        let {id, lat, lon} = center;
        let onClick = e => {
          window.selected = e.target;
          this.selectCancerCenter(center);
        }

        let color = (selected && (center.id === selected.id)) ? GREEN : GREY
        let style = {color}
        let title = center.name;
        markers.push({cancer_center_id: id, lat, lon, title, onClick, style, zIndexOffset: 1})
      }
    }

    return markers;

  }

  selectCancerCenter(center) {
    let {patient} = this.props;
    let {cancer_centers, filters} = this.state;
    this.setState({selected: center}, () => {

      let markers = this.buildMarkers({cancer_centers, filters, selected: center});
      this.setState({markers});
    });
  }

  renderFilters() {
    let {filters, searching} = this.state;

    let fields = [
      {name: 'patient_location_ids', label: 'Patient Location(s)', type: 'checkboxes', collection: this.patient_location_options},
      {name: 'radius', type: 'select', collection: RADIUS_OPTIONS_FOR_SELECT, allowBlank: false},
      {name: 'tag_ids', label: 'Tags', type: 'remote-select2', multiple: true, src: "/admin/cancer_center_tags/as_options.json", search_key: CancerCenterTagSearchKey},
    ];

    return (
      <div className="well">
        <Form model={filters} fields={fields} onSubmit={this.search} onChange={this.changeFilters} submitLabel="Search" />
      </div>
    )
  }


  renderList() {
    let {patient} = this.props;
    let {cancer_centers} = this.state;
    return (
      <table className="table table-condensed table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>City</th>
            <th>State</th>
            <th>Tags</th>
            <th>Distances</th>
          </tr>
        </thead>
        <tbody>
          {cancer_centers.map(center => {
            let link = `/admin/cancer_centers/${center.id}`;
            let state = States.find(s => s.id === center.state);
            let state_name = state ? state.name : center.state;
            return (
              <tr key={center.id}>
                <td><a href={link} target="_blank">{center.name}</a></td>
                <td>{center.city}</td>
                <td>{state_name}</td>
                <td>{center.tags.map(t => <span key={t.id} className="badge">{t.name}</span>)}</td>
              <td>
                <ul>
                  {center._distances.map(d => {
                    let location = patient.locations.find(l => l.id === d.id);
                    let distance = d.distance.toLocaleString('en-US')
                    return <li key={d.id}>{distance} miles from {location.city}</li>
                  })}
                </ul>
              </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderMap() {
    let {markers, searching, height} = this.state;
    if (searching || !markers) return <Loading />
    return (
      <div id="cancer_centers_map" ref="container" style={{height}}>
        <MapViewer height={height} markers={markers} onClickOnMap={e => this.setState({selected: null})} />
      </div>
    );
  }

  renderNoResults() {
    return (
      <p>No results found</p>
    );
  }

  renderResult() {
    let {selected, show_map} = this.state;
    if (!selected) return null;
    let {patient} = this.props;
    let center_url = `/admin/cancer_centers/${selected.id}`;
    let edit_url = `/admin/cancer_centers/${selected.id}/edit`;

    return (
      <div id="cancer_centers-box" className="map_box_details">
        <p><a href={center_url} target="_blank">{selected.name}</a></p>
        <p className="center_edit_link">
          <i className="fa fa-edit"></i>
          <a href={edit_url} target="_blank"> Edit Cancer Center</a>
        </p>
        <div>
          {selected.tags.map(tag => {
            return (
              <div key={tag.id}><span className="badge" >{tag.name}</span></div>
            )
          })}
        </div>

        <p><b>Distances</b></p>
        <ul>
          {selected._distances.map(d => {
            let location = patient.locations.find(l => l.id === d.id);
            let distance = d.distance.toLocaleString('en-US')
            return <li key={d.id}>{distance} miles from {location.city}</li>
          })}
      </ul>
      </div>
    );
  }

  renderPatientLink() {
    let {patient} = this.props;
    let patient_url = `/admin/patients/${patient.id}/edit`;

    return (
      <p>
        <a href={patient_url}><i className="fa fa-arrow-left"></i> Edit Patient Details</a>
      </p>
    )
  }

  renderMapToggle() {
    let {show_map, cancer_centers} = this.state;
    if (!cancer_centers) return null;

    let mapStyle = show_map ? "btn btn-success" : "btn btn-default"
    let listStyle = show_map ? "btn btn-default" : "btn btn-success"

    return (
      <div className="btn-group" role="group" id="map-toggle-buttons">
        <button id="show_map" className={mapStyle} disabled={show_map} onClick={e => this.setState({show_map: true})}><i className="fa fa-map-marked" /></button>
        <button className={listStyle} disabled={!show_map} onClick={e => this.setState({show_map: false})}><i className="fa fa-list" /></button>
      </div>
    );
  }

  renderCounters() {
    let {cancer_centers, searching} = this.state;
    if (!cancer_centers || searching) return null;
    return <h4><b>{cancer_centers.length}</b> Cancer Centers</h4>
  }

  renderMain() {
    let {show_map, cancer_centers, searching} = this.state;
    if (searching) return <Loading />;
    if (!cancer_centers || cancer_centers.length === 0) return this.renderNoResults();

    if (show_map) {
      return this.renderMap();
    } else {
      return this.renderList();
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-3">
          {this.renderPatientLink()}
          {this.renderFilters()}
          {this.renderResult()}
        </div>
        <div className="col-md-9">
          <div className="cancer_centers_results_header">
            {this.renderCounters()}
            {this.renderMapToggle()}
          </div>
          {this.renderMain()}
        </div>
      </div>
    );
  }
}


const RADIUS_OPTIONS = [10, 20, 50, 100, 200, 500, 1000, 10000]
const RADIUS_OPTIONS_FOR_SELECT = RADIUS_OPTIONS.map(r => ({id: r, name: (r + ' Miles')}));

function buildPatientLocationOptions(patient) {
  let locations = patient.locations.filter(l => l.lat && l.lon);
  return locations.map(l => ({id: l.id, name: l.city}));
}


function calculateDistances(patient, center, patient_location_ids) {
  let distances =  patient_location_ids.map(location_id => {
    let location = patient.locations.find(l => l.id === location_id);
    let distance = Math.round(calculateDistance(location, center));
    return {id: location.id, distance};
  });

  return distances.sort(function(a, b){return a.distance - b.distance});
}


function calculateAllDistances(patient, cancer_centers, patient_location_ids) {
  for (let center of cancer_centers) {
    center._distances = calculateDistances(patient, center, patient_location_ids);
  }

  return cancer_centers.sort(function(a, b){return a._distances[0].distance - b._distances[0].distance});
}
