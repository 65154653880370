import React, {Component} from 'react';
import request from '../request';

export default class NeestedRecords extends Component {
  constructor(props) {
    super(props);
    this.state = {text:""}
    this.onSelect = this.onSelect.bind(this);
    this.search = this.search.bind(this);
    this.debounce_timeout = 1000;
  }

  onInputChange(e) {
    e.preventDefault();
    let text = e.target.value;
    this.setState({text});

    if (this.debouce_timeout_id) clearTimeout(this.debouce_timeout_id);
    if (text.length < 2) return;
    this.debouce_timeout_id = setTimeout(this.search, this.debounce_timeout);
  }

  closeSearch() {
    this.setState({results: null, searching: false});
  }

  search() {
    let {text} = this.state;
    this.setState({searching: true});
    this.searchRequest(text)
      .then(results => {
        this.setState({results, searching: false})
      });
  }

  onSelect(record) {
    this.setState({results: undefined, text: ""})
    this.props.onSelect(record);
  }



  renderResults() {
    let {results} = this.state;
    if (!results) return null;

    if (results.length == 0) {
      return (
        <div>
          <div className="item-results">
            <div className="no-results" className="text-center">
              <h4>No results</h4>
            </div>
          </div>
          <div className="item-results-background" onClick={e => this.closeSearch()}></div>
        </div>
      )
    }


    return (
      <div>
        <div className="item-results">
          {results.map(r => this.renderResult(r))}
        </div>
        <div className="item-results-background" onClick={e => this.closeSearch()}></div>
      </div>
    )
  }


  renderIndicator() {
    let {results, searching } = this.state;

    if (searching) {
      return <i className="glyphicon glyphicon-refresh glyphicon-spin form-control-feedback" aria-hidden="true" />;
    }
    return null;
  }

  render() {
    let {inputLabel, id} = this.props;
    let {text} = this.state;

    return (
      <div id={id} className="neested-records-picker">
        <div className="form-group has-feedback neested-records-picker-input">
          <label className="control-label">{inputLabel}</label>
          <input type="text" className="item-picker-input form-control" placeholder="Start typing to search" value={text} onChange={e => this.onInputChange(e)} ref="input" />
          {this.renderIndicator()}
        </div>
        {this.renderResults()}
      </div>
    )
  }
}
