import React, {Component} from 'react';
import Form from './form';
import Loading from './loading';
import request from '../request';

export default class CancerTypeForm extends Component {
  constructor(props) {
    super(props);
    let {cancer_type} = props;
    this.state = {cancer_type};
    this.onChangeField = this.onChangeField.bind(this);
  }

  componentDidMount() {
    let {cancer_type} = this.state;
    if (cancer_type.cancer_category_id) {
      this.fetchCancerCategory(cancer_type.cancer_category_id);
    }
  }

  fetchCancerCategory(cancer_category_id) {
    let url = `/admin/cancer_categories/${cancer_category_id}`;
    request('GET', url).then(cancer_category => this.setState({cancer_category}));
  }

  submit(e) {
    e.preventDefault();
    this.setState({submitting: true});
    let {cancer_type} = this.state;

    let url = "/admin/cancer_types";
    let method;


    if (cancer_type.id) {
      url += "/" + cancer_type.id;
      method = "PUT"
    } else {
      method = "POST"
    }

    request(method, url, {cancer_type}).then(cancer_type => {
      this.setState({cancer_type, errors: [], submitting: false});
      window.location = "/admin/cancer_types/";

    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors, submitting: false});
    });
  }

  onChangeField(name, value) {
    let {cancer_type} = this.state;
    cancer_type[name] = value;
    if (name === 'cancer_category_id') {
      this.fetchCancerCategory(value);
      cancer_type.traits = {};
      cancer_type.tags = {};
    }

    this.setState({cancer_type});

  }

  fields() {
    let {cancer_category} = this.state;
    return [
      {name: 'name', required: true},
      {name: 'aliases'},
      {name: 'description', type: 'text'},
      {name: 'cancer_category_id', type: 'select', collection: gon.cancerCategories},
      {name: 'tags', type: CancerTypeTags, cancer_category, visible: t => t.cancer_category_id },
      {name: 'traits', type: CancerTypeTraits, cancer_category, visible: t => t.cancer_category_id }
    ];
  }

  render() {
    let {cancer_type, submitting, errors} = this.state;

    return (
      <div>
        <Form model={cancer_type} model_name="Cancer Type" fields={this.fields()} errors={errors} onChangeField={this.onChangeField} onSubmit={e => this.submit(e)} submitting={submitting} />
      </div>
    );
  }
}


class CancerTypeTags extends Component {
  toggleTag(tag) {
    let {field, onChange} = this.props;
    let {value} = this.props;
    value[tag.id] = !value[tag.id];
    onChange({target: {name: field.name, value}});
  }

  renderTag(tag) {
    let {value} = this.props;

    let className = "btn btn-default"
    if (value[tag.id]) {
      className += ' active';
    }
    return (
      <div key={tag.id} className="cancer_type_tag">
        <button className={className} onClick={e => this.toggleTag(tag)}>{tag.text}</button>
      </div>
    );
  }

  render() {
    let {cancer_category} = this.props.field;
    if (!cancer_category) return <Loading />;
    if (cancer_category.tags.length === 0) return null;
    return (
      <div>
        <h4><b>Tags</b></h4>
        <p>Select all that apply this type:</p>
        {cancer_category.tags.map(tag => this.renderTag(tag))}
      </div>
    );
  }
}


class CancerTypeTraits extends Component {
  toggleLocalTrait(trait) {
    let {field, onChange} = this.props;
    let {value} = this.props;
    value[trait.id] = !value[trait.id];
    onChange({target: {name: field.name, value}});
  }

  renderGlobalTraits() {
    let {cancer_category} = this.props.field;
    let global_traits = cancer_category.traits.filter(t => t.applies_to_all);
    if (global_traits.length === 0) return null;

    return (
      <div>
        <h4><b>Global Traits</b></h4>
        <p>These traits apply to all cancer types in this category.</p>
        {global_traits.map(trait => <div key={trait.id}><span className="fa fa-check"></span> {trait.name}</div>)}
      </div>
    );
  }

  renderLocalTraits() {
    let {value} = this.props;
    let {cancer_category} = this.props.field;
    let local_traits = cancer_category.traits.filter(t => !t.applies_to_all);
    if (local_traits.length === 0) return null;

    return (
      <div>
        <h4><b>Traits</b></h4>
        <p>Choose the traits that are appropriate for this cancer type:</p>
        {local_traits.map(trait => {
          let checked = !!value[trait.id];
          return (
            <div key={trait.id}>
              <input type="checkbox" value={checked} checked={checked} onChange={e => this.toggleLocalTrait(trait)}/> <label>{trait.name}</label>
            </div>
          )
        })}
      </div>
    );
  }

  render() {
    let {cancer_category} = this.props.field;
    if (!cancer_category) return null;

    return (
      <div>
        <hr/>
        {this.renderGlobalTraits()}
        <hr/>
        {this.renderLocalTraits()}
        <hr/>
      </div>
    );
  }
}
