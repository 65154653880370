import React, {Component} from 'react';
import Form from './form';
import request from '../request';
import {TaskActionsFormField} from './task_actions';
import {PatientSearchKey, WorkCategorySearchKey, AdminSearchKey} from '../constants.js.erb';

export default class TaskForm extends Component {
  constructor(props) {
    super(props);
    let {task} = props;
    this.state = {task};
    this.onChange = this.onChange.bind(this);
  }

  submit(e) {
    e.preventDefault();
    this.setState({submitting: true});
    let {task} = this.state;
    let {onSubmit, return_path} = this.props;

    let url = "/admin/tasks";
    let method;

    if (task.id) {
      url += "/" + task.id;
      method = "PUT"
    } else {
      method = "POST"
    }

    request(method, url, {task}).then(task => {
      if (onSubmit) {
        onSubmit(task);
      } else if (return_path) {
        window.location = return_path;
      }
    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors, submitting: false});
    });

  }

  onChange(task) {
    this.setState({task});
  }

  fields() {
    return [
      {name: 'name', required: true},
      {name: 'description', type: 'text'},
      {name: 'due_date', type: 'date', required: true},
      {name: 'admin_id', type: 'remote-select2', src: "/admin/admins/as_options.json", search_key: AdminSearchKey},
      {name: 'patient_id', type: 'remote-select2', src: "/admin/patients/as_options.json", search_key: PatientSearchKey},
      {name: 'work_category_id', type: 'remote-select2', src: "/admin/work_categories/as_options.json", search_key: WorkCategorySearchKey},
      {name: 'actions', type: TaskActionsFormField, visible: t => !t.id}
    ];
  }

  render() {
    let {task, errors, submitting} = this.state;
    let {onCancel} = this.props;
    let form_action = task.id ? 'Edit' : 'New';
    return (
      <div className="task_form_holder">
        <h4>{form_action} Task</h4>
        <hr/>
        <Form model_name="Task" model={task} fields={this.fields()} onChange={this.onChange} onSubmit={e => this.submit(e)} errors={errors} submitting={submitting} onCancel={onCancel} />
      </div>
    );
  }
}
