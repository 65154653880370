export function format(record, opt={}) {
  let field = opt.field || 'date';
  let value = record[field];

  if (!value) return null;
  if (typeof(value) === 'string') value = new Date(value);

  let buffer = moment(value).format('LL');
  return buffer;
}

export function formatEstimated(record, opt={}) {
  let field = opt.estimated_field || 'is_date_estimated';
  let buffer = format(record, opt);
  if (record[field]) {
    buffer += ' (estimated)';
  }
  return buffer;
}
