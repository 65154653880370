import React from 'react';
export default function (childrens, separator) {
  if (!childrens || childrens.length === 0) return null;

  return childrens.map((node, index) => {
    let key = node.key || index;
    if (index < childrens.length -1) {
      return <span key={key}>{node} {separator}</span>
    } else {
      return <span key={key}>{node}</span>
    }
  });
}
