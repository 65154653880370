import jquery from 'jquery';

export default {
  setup: function() {
    jquery( document ).ready(function() {
      if (isProtectedPage()) {
        installTimeout();
        // Reset the timeout after xhr requests
        window.addEventListener('signout_reminder_reset', installTimeout);
      }
    });
  }
}

const TIMEOUT = 90 * 60 * 1000; // 90 minutes in milliseconds
const META_NAME = 'protected-page';
var timeout_id;

function isProtectedPage() {
  let metas = document.getElementsByTagName('meta');
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === META_NAME) {
      return true;
    }
  }
  return false;
}

function installTimeout() {
  if (timeout_id) {
    clearTimeout(timeout_id);
  }
  timeout_id = setTimeout(showAlert, TIMEOUT);
}

function showAlert() {
  window.alert("You have been signed out.");
}
