import React, {Component} from 'react';

export default class StarsRatingField extends Component {
  setValue(value) {
    let {name, onChange} = this.props;
    onChange && onChange({target: {name, value}});
  }

  onClick(e, value) {
    // If double click reset the value
    if (e.detail > 1) {
      value = 0;
    }
    this.setValue(value);
  }


  buildStars() {
    let {value, starsCount, onChange} = this.props;
    let stars = [];
    let count = starsCount || 5;
    for (let i=0; i < count; i++) {
      let star_value = i + 1;
      let statusClassName = (value === undefined || value === null || value < star_value) ? 'far' : 'fas';
      let clickableClassName = onChange ? 'clickable' : '';
      let className = ['fa-star', statusClassName, clickableClassName].join(' ');

      let star = (<span key={star_value} className={className} onClick={e => this.onClick(e, star_value)}></span>)
      stars.push({component: star, value: star_value});
    }
    return stars;
  }


  buildExtraOptions() {
    let {value, extraOptions, onChange} = this.props;
    if (!extraOptions || extraOptions.length === 0) return false;

    return extraOptions.map(option => {
      let order = option.hasOwnProperty('order') ? option.order : option.value;
      let baseClassName = value == option.value ? option.selectedClassName : option.className;
      let clickableClassName = onChange ? 'clickable' : '';
      let className = [baseClassName, clickableClassName].join(' ');
      let component = (<span key={option.value} className={className} onClick={e => this.onClick(e, option.value)}>{option.content}</span>)
      return {component, order, extra: true, value: option.value};
    });

  }

  renderComponents() {
    let {value, valueComponentOnly, separateExtraOptions} = this.props;
    let items = this.buildStars();

    let extraOptions = this.buildExtraOptions();
    if (extraOptions) {
      if (separateExtraOptions) {
        let extra_item = extraOptions.find(i => i.extra && i.value === value);
        if (extra_item) return extra_item.component;
      } else {
        items = items.concat(extraOptions);
        items = items.sort((a, b) => a.order - b.order);
      }
    }

    if (valueComponentOnly) {
      if (value === null) return "--";
      let item = items.find(i => i.extra && i.value === value);
      if (item) return item.component;

      // otherwise, filter out extra items
      items = items.filter(i => !i.extra);
    }
    return items.map(i => i.component)
  }

  render() {
    let {title} = this.props;

    return (
      <div className="stars-rating-field" title={title} >
        {this.renderComponents()}
       </div>
     );

  }
}
