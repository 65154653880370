import React, {Component} from 'react';

import Form from './journey_tracker_form';
import JourneyRecord from './journey_record';
import OptionsCache from './options_cache';

export default class Stage extends JourneyRecord {
  constructor(props) {
    super(props);
    this.state = {show_changelog: false}
    this.onChange = this.onChange.bind(this);
    this.cancerTypeCache = new OptionsCache({source: 'patient_cancer_types', onChange: e => this.forceUpdate()})
  }

  getTitle() { return 'Stage'; }
  getBasePath() {
    let {diagnosis} = this.props;
    return 'diagnoses/[id:' + diagnosis.id + ']/stage';
  }

  getSubtitle() {
    let {diagnosis} = this.props;
    let patient_cancer_type = this.cancerTypeCache.get(diagnosis.patient_cancer_type_id);
    return <small>{patient_cancer_type.text}</small>;
  }

  getSectionLabel() {
    let {diagnosis} = this.props;
    let patient_cancer_type = this.cancerTypeCache.get(diagnosis.patient_cancer_type_id);
    return ['Stage', patient_cancer_type.text];
  }

  formFields() {
    let {diagnosis} = this.props;
    let patient_cancer_type = this.cancerTypeCache.get(diagnosis.patient_cancer_type_id);
    let {staging_options, tumor_sizes, nodal_spreads, metastases, grades} = patient_cancer_type;

    return [
      {name: 'stage', label: 'Solid Tumor Stage', type: 'select', collection: staging_options || []},
      {name: 'tumor_size', label: 'Tumor (T) Size and extent', type: 'select', collection: tumor_sizes || []},
      {name: 'nodal_spread', label: 'Nodal (N) Spread', type: 'select', collection: nodal_spreads || []},
      {name: 'metastases', label: 'Metastases (M)', type: 'select', collection: metastases || []},
      {name: 'grade', type: 'select', collection: grades || []},
    ];
  }


  renderForm() {
    let {diagnosis, readOnly} = this.props;
    return <Form fields={this.formFields()} model={diagnosis.stage} onChange={this.onChange} readOnly={readOnly} />
  }
}
