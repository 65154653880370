import Viewer from 'viewerjs';
import "viewerjs/dist/viewer.css";

export default {
  setup: () => {
    window.addEventListener('load', function () {
      var elements = document.getElementsByClassName('viewerjs');
      for (let el of elements) {
        setupViewer(el);
      }
    });
  }
}

const toolbar = {
  zoomIn: true,
  zoomOut: true,
  oneToOne: true,
  reset: false,
  prev: false,
  play: false,
  next: false,
  rotateLeft: true,
  rotateRight: true
}
const options = {toolbar, url: image =>  image.dataset.fullsize };


function setupViewer(el) {
  var viewer = new Viewer(el, options);
}
