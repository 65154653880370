import jquery from 'jquery';
import request from './request';

const WATCHED_ICON = 'glyphicon glyphicon-eye-open';
const NOT_WATCHED_ICON = 'glyphicon glyphicon-eye-close';

export default {
  setup: () => {
    jquery( document ).ready(e =>  {
      let buttons = jquery('button.toggle_patient_watch_btn');
      for (let button of buttons) {
        let {watched, patient} = jquery(button).data();
        setContent(button, watched);

        jquery(button).on('click', e => {
          if (watched) {
            deleteActivePatient(patient).then(e => {
              watched = false;
              setContent(button, watched);
            });


          } else {
            createActivePatient(patient).then(e => {
              watched = true;
              setContent(button, watched);
            });
          }


        });
      }
    });
  }
}


function setContent(button, isWatched) {
  let content_icon, style;
  if (isWatched) {
    content_icon = WATCHED_ICON;
    style = 'toggle_patient_watch_btn btn btn-sm btn-success'
  } else {
    content_icon = NOT_WATCHED_ICON;
    style = 'toggle_patient_watch_btn btn btn-sm btn-default'
  }
  jquery(button).attr('class', style);

  let content = `<span class="${content_icon}" aria-hidden="true"></span>`
  jquery(button).html(content);
}


function createActivePatient(patient_id) {
  let url = "/admin/active_patients";
  return request("POST", url, {patient_id: patient_id})
}

function deleteActivePatient(patient_id) {
  let url = "/admin/active_patients/" + patient_id;
  return request("DELETE", url);
}
