import React, {Component} from 'react';
import request from '../request';
import Loading from './loading';

export class Nlp extends Component {
  constructor(props) {
    super(props);
    this.state = {text: ''};
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);

  }

  submit(e) {
    e.preventDefault();
    this.setState({loading: true});
    let {text} = this.state;
    let {access_token} = this.props;

    let url = '/admin/google_api';

    request('PUT', url, {nlp: {text, access_token}})
      .then(data => {

        let entities = JSON.stringify(data.entities, null, 4);
        let mentions = JSON.stringify(data.entityMentions, null, 4);
        this.setState({entities, mentions, loading: false});
      })
      .catch(error => {
        console.log("ERROR", error);
        this.setState({loading: false});
      });
  }

  onChange(e) {
    let text = e.target.value;
    this.setState({text});
  }

  renderResponse() {
    let {loading, entities, mentions} = this.state;
    if (loading) return <Loading />
    if (!entities || !mentions) return null;

    return (
      <div>
        <h3>Entities</h3>
        <pre>{entities}</pre>
        <h3>Entity Mentions</h3>
        <pre>{mentions}</pre>
      </div>
    );
  }

  renderForm() {
    let {text} = this.state;
    return (
      <form onSubmit={this.submit}>
        <div className="form-group">
          <textarea className="form-control" rows="10" value={text} onChange={this.onChange}></textarea>
        </div>
        <input type="submit" className="btn btn-primary"/>
      </form>
    )
  }

  render() {

    return (
      <div>
        <h1>Google Healhcare API</h1>
        {this.renderForm()}
        {this.renderResponse()}
      </div>
    );
  }
}



function highlightJson(json) {
    if (typeof json != 'string') {
         json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        var cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
    });
}
