import React, {Component} from 'react';

import Form from './journey_tracker_form';
import JourneyRecord from './journey_record';
import {ECOG} from '../../constants.js.erb';

export default class PatientDetails extends JourneyRecord {
  constructor(props) {
    super(props);
    this.state = {show_changelog: false}
    this.onChange = this.onChange.bind(this);
  }

  getTitle() { return 'Patient Details'; }
  getBasePath() { return 'patient_details'; }

  formFields() {
    return [
      {name: 'height', hint: 'Text entry. Please use format foot and inch format: 5\'7\'\'.'},
      {name: 'weight', type: 'number', hint: 'Enter number in lbs.'},
      {name: 'ecog', type: 'select', collection: ECOG},
      {name: 'deceased', label: 'Patient Deceased?', type: 'bool'},
      {name: 'date_of_death', type: 'date'},
      {name: 'date_of_death_estimated', label: 'Date Estimated?', type: 'bool'}
    ];
  }


  renderForm() {
    let {patient_details, readOnly} = this.props;
    return <Form fields={this.formFields()} model={patient_details} onChange={this.onChange} readOnly={readOnly} />
  }

}
