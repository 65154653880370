export function globalDefaults(record, data, settings={}) {
  if (!data) return record;

  let active_diagnosis = data.diagnoses.filter(d => d.active);
  if (active_diagnosis.length === 1) {
    record.diagnosis_id = active_diagnosis[0].id;
  }

  return record;
}
