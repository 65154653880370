import React, {Component} from 'react';
import CollectionTrial from './collection_trial';
import AdminCollectionModals from './admin_collection_modals';
import CollectionMap from './collection_map';
import Form from './form';
import Loading from './loading';
import request from '../request';

export default class Collection extends Component {
  constructor(props) {
    super(props);
    let {collection_trials} = props;

    this.state = {collection_trials}
    this.onDelete = this.onDelete.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onTrialAdded = this.onTrialAdded.bind(this);
  }

  componentDidMount() {
    $(this.refs.modal_container).modal({show: false});
    $(this.refs.modal_container).on('hide.bs.modal', () => {
      this.setState({show_form: false});
    });
  }


  onDelete(collection_trial) {
    let {collection_trials} = this.state;
    let index = collection_trials.findIndex(t => t.id === collection_trial.id)
    collection_trials.splice(index, 1);
    this.setState({collection_trials});
  }

  onMove({id, position}) {
    let {collection_trials} = this.state;
    let index = collection_trials.findIndex(t => t.id === id)
    let collection_trial = collection_trials.splice(index, 1)[0];
    collection_trials.splice(position - 1 , 0, collection_trial);
    this.setState({collection_trials});
  }

  onTrialAdded(collection_trial) {
    let {collection_trials} = this.state;
    collection_trials.push(collection_trial);
    this.setState({collection_trials});
    this.closeForm();
  }

  showForm() {
    this.setState({show_form: true}, () => $(this.refs.modal_container).modal('show'));
  }

  closeForm() {
    this.setState({show_form: false});
    $(this.refs.modal_container).modal('hide')
  }

  renderModal() {
    let {show_form} = this.state;
    let content;
    if (show_form) {
      let {patient, collection} = this.props;
      let {collection_trials} = this.state;

      content = <AddTrialForm patient_id={patient.id} collection_id={collection.id} onTrialAdded={this.onTrialAdded} current_collection_trials={collection_trials} />
    }

    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              {content}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCollectionTrial(collection_trial) {
    let {patient} = this.props;
    return <CollectionTrial key={collection_trial.id} collection_trial={collection_trial} patient={patient} onDelete={this.onDelete} onMove={this.onMove} />
  }

  renderMapToggle() {
    let {show_map, results} = this.state;

    let mapStyle = show_map ? "btn btn-primary" : "btn btn-default"
    let listStyle = show_map ? "btn btn-default" : "btn btn-primary"
    return (
      <div className="btn-group" role="group" id="map-toggle-buttons">
        <button className={listStyle} disabled={!show_map} onClick={e => this.setState({show_map: false})}><i className="fa fa-list" /></button>
        <button id="show_map" className={mapStyle} disabled={show_map} onClick={e => this.setState({show_map: true})}><i className="fa fa-map-marked" /></button>
      </div>
    );
  }

  renderContent() {
    let {show_map} = this.state;
    if (show_map) {
      return this.renderMap();
    } else {
      return this.renderCollections();
    }
  }

  renderMap() {
    let {collection_trials} = this.state;
    let {patient} = this.props;
    return <CollectionMap collection_trials={collection_trials} patient={patient} onDelete={this.onDelete} onMove={this.onMove} />
  }

  renderCollections() {
    let {patient, collection} = this.props;
    let {collection_trials} = this.state;
    return (
      <div>
        {collection_trials.map(t => this.renderCollectionTrial(t))}
        <AdminCollectionModals patient_id={patient.id} collection_id={collection.id}/>
      </div>
    )
  }

  render() {
    return (
      <div id="admin_collections_trials">
        <div className="pull-right collection-view-toggle">
          <button className="btn btn-success" onClick={e => this.showForm()}>Add Trial</button>
          {this.renderMapToggle()}
          {this.renderModal()}
        </div>
        <div className="clearfix"></div>
        {this.renderContent()}
      </div>
    )
  }

}


class AddTrialForm extends Component {
  constructor(props) {
    super(props);
    this.state = {query: ''}
    this.search = this.search.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(name, value) {
    this.setState({query: value});
  }

  addTrial(trial) {
    let {patient_id, collection_id, onTrialAdded} = this.props;
    let url = "/admin/patients/" + patient_id + "/collections/" + collection_id + "/trials"
    request('POST', url, {trial_id: trial.id}).then(({success, collection_trial}) => {
      if (success) {
        onTrialAdded(collection_trial);
      }
    });

  }

  search(e) {
    e.preventDefault();
    let {query} = this.state;
    this.setState({searching: true});
    let url = '/admin/trials/search?query=' + query;
    request('GET', url).then(results => {
      let {current_collection_trials} = this.props;
      results = results.filter(r => current_collection_trials.findIndex(c => c.trial_id === r.id) === -1)
      this.setState({results, searching: false});
    });
  }

  renderForm() {
    let {query} = this.state;
    let fields= [
      {name: 'query', label: 'Trial', focus: true}
    ];

    return <Form model={{query}} fields={fields} onSubmit={this.search} onChangeField={this.onChange} submitLabel='Search'/>
  }

  renderSearchResults() {
    let {onAddTrial} = this.props;
    let {searching, results} = this.state;
    if (searching) return <Loading />
    if (!results) return null;
    if (results.length === 0) return <p>No results found</p>

    return (
      <div>
        <h3>Search Results</h3>
        {results.map(trial => {
          return (
            <div key={trial.id} className="additional_trial_search_result">
              <span><b>{trial.identifier}:</b> {trial.name}</span>
              <button className="btn btn-sm btn-success" onClick={e => this.addTrial(trial)}>Add Trial</button>
            </div>
          )
        })}
      </div>
    )
  }

  render() {

    return (
      <div>
        {this.renderForm()}
        {this.renderSearchResults()}
      </div>
    );
  }

}
