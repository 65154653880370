import React, {Component} from 'react';
import request from '../../request';

export default class SavedSearches extends Component {
  constructor(props) {
    super(props);
    this.state = {saved_searches: [], visible_saved_searches: [], search_string: "", name: "", mode: null}
    if (props.mode == "update") {
      this.state.name = props.saved_search.name
    }

    this.initEl = this.initEl.bind(this);
    this.close = this.close.bind(this);
    this.saveSearch = this.saveSearch.bind(this);
    this.deleteSearch = this.deleteSearch.bind(this);
  }

  componentDidMount() {
    let {admin_mode, patient} = this.props;
    let url = "/search/saved_searches"
    if (patient) url = "/patients/" + patient.id + url;
    if (admin_mode) url = "/admin" + url;
    request("GET", url)
      .then(data => {
        this.setState({saved_searches: data, visible_saved_searches: data});
      });
  }

  deleteSearch() {
    let {search} = this.state;
    let url = "/search/saved_searches/" + search.id;
    if (this.props.admin_mode) url = "/admin" + url;
    request("DELETE", url)
      .then((reply) => {
        this.close();
        this.props.onDelete();
      })
  }

  saveSearch() {
    let {name} = this.state;
    let {onSave} = this.props;
    onSave(name).then(() => this.close());
  }

  close() {
    this.el.modal('hide');
  }

  initEl(el) {
    this.el = $(el);
    this.el.modal({show: true});
    this.el.on('hidden.bs.modal', e => {
      let {onClose} = this.props;
      if (onClose) onClose();
    });
  }


  loadSaved(saved_search) {
    let {admin_mode, patient} = this.props;

    if (admin_mode && (saved_search.patient_id && !patient)) {
      let url = "/admin/patients/" + saved_search.patient_id + "/search?saved_search_id=" + saved_search.id;
      window.location =  url;
      return;
    }

    let url = "/search/saved_searches/" + saved_search.id;
    if (this.props.admin_mode) {
      url = "/admin" + url;
    }

    request('GET', url).then(saved_search => {
    this.props.onLoad(saved_search);
    this.close();
    });
  }


  setSearchString(search_string) {
    let {sort_field, sort_direction, saved_searches} = this.state;
    let re = new RegExp(search_string, "i");
    let visible_saved_searches = saved_searches.filter(s => re.test(s.name) || re.test(s.cancer_type_name))
    if (sort_field && sort_direction) {
      visible_saved_searches = this.sort(sort_field, sort_direction, visible_saved_searches);
    }
    this.setState({search_string, visible_saved_searches})
  }

  sortBy(field) {
    let {sort_field, sort_direction, visible_saved_searches} = this.state;

    if (sort_field === field) {
      sort_direction = sort_direction === "asc" ? "desc" : "asc";
    } else {
      sort_direction = "asc";
    }

    visible_saved_searches = this.sort(field, sort_direction, visible_saved_searches);
    this.setState({sort_field: field, sort_direction, visible_saved_searches});
  }

  sort(field, direction, visible_saved_searches) {
    return visible_saved_searches.sort((a, b) => {
      if (direction === "asc") {
        if (a[field] > b[field]) return 1;
        if (a[field] < b[field]) return -1;
      } else {
        if (a[field] < b[field]) return 1;
        if (a[field] > b[field]) return -1;
      }
      return 0;
    });
  }

  renderHeader(field, label) {
    let caret;
    let {sort_field, sort_direction} = this.state;
    if (sort_field === field) {
      let caret_icon = sort_direction === "asc" ? "fa-caret-up" : "fa-caret-down"
      caret = <i className={"fa " + caret_icon} />
    }


    return (
      <button className="btn btn-link" onClick={e => this.sortBy(field)}>
        {label} {caret}
      </button>
    )
  }

  renderLoad() {
    let body = this.state.visible_saved_searches.map(s => {
      let owner = s.owner_name;
      if (this.props.admin_mode && s.owner_type === 'user') {
        owner += " (user)";
      }
      return (
        <tr key={s.id}>
          <td>{s.name}</td>
          <td>{s.patient_name}</td>
          <td>{s.cancer_type_name}</td>
          <td>{s.created_at}</td>
          <td>{owner}</td>
          <td>{s.results_count}</td>
          <td>
            <button onClick={e => this.setState({mode: "destroy", search: s})} className="btn btn-link">Delete</button>
            <button onClick={e => this.loadSaved(s)} className="btn btn-default">Load Search</button>
          </td>
        </tr>
      )
    });
    return (
      <div id="load">
        <h3>Saved Searches</h3>

        <div className="form-group">
          <label>Search by Name</label>
          <input type="text" className="form-control" value={this.state.search_string} onChange={e => this.setSearchString(e.target.value)} />
        </div>


        <table className="table table-condensed">
          <thead>
            <tr>
              <th>{this.renderHeader("name", "Search Name")}</th>
              <th>{this.renderHeader("patient_name", "Patient")}</th>
              <th>{this.renderHeader("cancer_type_name", "Cancer Type")}</th>
              <th>{this.renderHeader("timestamp", "Date")}</th>
              <th>{this.renderHeader("owner_name", "Saved by")}</th>
              <th>{this.renderHeader("results_count", "Results")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="saved_search_tbody">
            {body}
          </tbody>
        </table>
      </div>
    )
  }


  renderSave() {
    return (
      <form id="save" onSubmit={this.saveSearch}>
        <div className="form-group">
          <label>Name</label>
          <input type="text" className="form-control" value={this.state.name} onChange={e => this.setState({name: e.target.value})} />
        </div>
      </form>
    )
  }

  renderDestroy() {
    let {search} = this.state;

    return(
      <div>
        <h4>You are about to delete the saved search '{search.name}'</h4>
        <p>Are you sure?</p>
      </div>
    )
  }

  render() {
    let title, content, action;
    let {mode} = this.props;
    if (this.state.mode === "destroy") {
      title = "Delete Search";
      content = this.renderDestroy();
      action =  <button type="button" className="btn btn-primary" onClick={this.deleteSearch}>Delete</button>
    } else if (mode === "save") {
      content = this.renderSave();
      title = "Save Search";
      action =  <button type="button" className="btn btn-primary" onClick={this.saveSearch}>Save</button>
    } else if (mode == "load") {
      content = this.renderLoad();
      title = "Load Search";
    } else  if (mode === "update") {
      content = this.renderSave();
      title = "Update Search";
      action =  <button type="button" className="btn btn-primary" onClick={this.saveSearch}>Save</button>
    }
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref={this.initEl}>
        <div id="saved_searches_modal" className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={this.close} aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">{title}</h4>
            </div>
            <div className="modal-body">
              {content}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" onClick={this.close}>Close</button>
              {action}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
