import React, {Component} from 'react';

/*
 * call this.initializeScrollWatcher(); when the component is loaded
 * to initialize the scroll
 * -------------------------------------------------------------------*/

export default class FixedScroll extends Component {
  constructor(props) {
    super(props);
    this.onScroll = this.onScroll.bind(this);
  }

  initializeScrollWatcher() {
    let {watchScroll} = this.props;

    if (watchScroll) {
      this.scroll_el = document.getElementById(watchScroll);
      window.scroll_el =  this.scroll_el;

      if (!this.resetScroll()) {
        let current_scroll = sessionStorage.getItem("current_scroll");
        setTimeout(() => {this.scroll_el.scrollTop = +current_scroll}, 1);
      }

      // Watch scroll
      this.scroll_el.addEventListener("scroll", this.onScroll);
    }
  }

  onScroll(e) {
    let {population_id} = this.props;
    let scroll_position = this.scroll_el.scrollTop;
    sessionStorage.setItem("current_scroll", scroll_position);
  }

  /*
   * Return true if we should reset the scroll position
   * -------------------------------------------------------------------*/

  resetScroll() {
    return false;
  }
}
