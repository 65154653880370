import React, {Component} from 'react';
import joinReact from './join_react';
import Form from './form';
import {TrialHeader, TrialBottomNav} from './trial_partials';
import ReferencesForm from './references_form';

import Loading from './loading';
import request from '../request';
import {ReferenceSearchFieldKey, PersonSearchKey} from '../constants.js.erb';
const RESET_SUBMIT_STATUS_TIMEOUT = 3000;

export default class TrialReferences extends Component {
  constructor(props) {
    super(props);
    let {trial_references, trial_references_changelog} = props;

    this.state = {trial_references, trial_references_changelog, references: []}
    this.onAddReference = this.onAddReference.bind(this);
    this.onDeleteReference = this.onDeleteReference.bind(this);
    this.onMoveTrialReferenceUp = this.onMoveTrialReferenceUp.bind(this);
    this.onMoveTrialReferenceDown = this.onMoveTrialReferenceDown.bind(this);
  }

  componentDidMount() {
    let {trial_references} = this.state;
    $(this.refs.modal_container).modal({show: false});
    $(this.refs.modal_container).on('hide.bs.modal', () => {
      this.setState({show_modal: false});
    });
  }

  showModal(modal_content) {
    this.setState({show_modal: true, modal_content});
    $(this.refs.modal_container).modal('show');
  }

  closeModal() {
    this.setState({show_modal: false, modal_content: null},);
    $(this.refs.modal_container).modal('hide');
  }


  scheduleResetSubmitStatus() {
    setTimeout(() => this.setState({submit_status: undefined}), RESET_SUBMIT_STATUS_TIMEOUT);
  }

  onAddChangelogUpdate(update) {
    let {trial} = this.props;
    this.setState({submitting_changelog: true});
    let url = `/admin/trials/${trial.id}/references/add_changelog_update`;
    request('PUT', url).then(trial_references_changelog => {
      this.setState({trial_references_changelog, submitting_changelog: false});
    });
  }

  undoChangelogUpdate() {
    let {trial} = this.props;
    this.setState({submitting_changelog: true});
    let url = `/admin/trials/${trial.id}/references/undo_changelog_update`;
    request('PUT', url).then(trial_references_changelog => {
      this.setState({trial_references_changelog, submitting_changelog: false});
    });
  }

  onAddReference(reference) {
    let {trial} = this.props;
    let {trial_references} = this.state;

    let trial_reference = {trial_id: trial.id, reference_id: reference.id, position: trial_references.length}
    let url = `/admin/trials/${trial.id}/references`;
    request('POST', url, {trial_reference}).then(trial_references => {
      this.setState({trial_references});
    });
  }

  onDeleteReference(trial_reference) {
    let {trial} = this.props;
    let {trial_references} = this.state;

    let url = `/admin/trials/${trial.id}/references/${trial_reference.id}`;
    request('DELETE', url).then(trial_references => {
      this.setState({trial_references});
    });
  }

  // Trial references use a reverse order
  onMoveTrialReferenceUp(trial_reference) {
    let {trial} = this.props;
    let url = `/admin/trials/${trial.id}/references/${trial_reference.id}/move_down`;
    request('PUT', url).then(trial_references => {
      this.setState({trial_references});
    });
  }

  // Trial references use a reverse order
  onMoveTrialReferenceDown(trial_reference) {
    let {trial} = this.props;
    let url = `/admin/trials/${trial.id}/references/${trial_reference.id}/move_up`;
    request('PUT', url).then(trial_references => {
      this.setState({trial_references});
    });
  }

  reorder_references(trial_references) {
    return trial_references.map((trial_reference, index) => {
      trial_reference.position = index;
      return trial_reference;
    })

  }


  renderModalContent() {
    let {show_modal, modal_content} = this.state;
    if (!show_modal) return null;

    if (modal_content === 'add_reference') {
      let {trial_references} = this.state;
      return <TrialReferencePicker onAdd={this.onAddReference} trial_references={trial_references} close={e => this.closeModal()} />
    } else if (modal_content === 'copy_data') {
      let {trial} = this.props;
      return <CopyReferencesForm trial={trial} close={e => this.closeModal()} onChange={trial_references => this.setState({trial_references})} />
    }

    throw('Invalid modal content');
  }

  renderModal() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container" data-focus="false">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">Add References</h4>
            </div>
            <div className="modal-body">
              {this.renderModalContent()}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderList() {
    let {trial} = this.props;
    let {trial_references} = this.state;
    if (!trial_references || trial_references.length === 0) return null;
    let options = {
      onDelete: this.onDeleteReference,
      onMoveUp: this.onMoveTrialReferenceUp,
      onMoveDown: this.onMoveTrialReferenceDown
    }

    return (
      <div id="references_list" className="reference_list_holder">
        {trial_references.map(trial_reference => {
          let populations = trial.populations.filter(p => p.reference_id === trial_reference.reference_id);
          return <TrialReference key={trial_reference.reference_id} trial={trial} trial_reference={trial_reference} populations={populations} {...options} />
        })}
      </div>
    )
  }

  render() {
    let {trial_references, trial_references_changelog, submitting_changelog} = this.state;
    let {trial, admin_id, trial_notes, return_path} = this.props;
    let copy_data_btn;
    if (trial.isTherapy) {
      copy_data_btn = <button className="btn btn-default" onClick={e => this.showModal('copy_data')}>Copy References</button>
    }

    return (
      <div>
        <TrialHeader trial={trial} />
        <div id="references">
          <div className="row">
            <div className="col-md-8">
              <div className="trial-section-box">
                <div className="reference_header_area">
                  <h3>References</h3>
                  <div className="btn-group">
                    <button className="btn btn-primary" onClick={e => this.showModal('add_reference')}>Add References</button>
                    {copy_data_btn}
                  </div>
                </div>

                {this.renderList()}
              </div>
            </div>
            <div className="col-md-4">
              <TrialReferencesChangelog admin_id={admin_id} trial_references_changelog={trial_references_changelog}  onAddUpdate={() => this.onAddChangelogUpdate()} undoChangelog={() => this.undoChangelogUpdate()} submitting_changelog={submitting_changelog} />
              <TrialNotes admin_id={admin_id} trial_id={trial.id} trial_notes={trial_notes}/>
            </div>
          </div>
          {this.renderModal()}
          <TrialBottomNav trial={trial} action="references" return_path={return_path}/>
        </div>
      </div>
    )
  }
}


class TrialReference extends Component {
  delete() {
    let {trial_reference, onDelete} = this.props;
    trial_reference._destroy = true;
    onDelete(trial_reference);
  }

  creationInfo() {
    let {trial_reference} = this.props;
    let date = moment(trial_reference.created_at).format('LL')

    if (trial_reference.admin_name) {
      return "Added to therapy by " + trial_reference.admin_name + " on " + date
    } else {
      return "Added to therapy on " + date;

    }
  }


  renderPopulations(reference) {
    if (reference.populations.length === 0) return null;
    let {trial} = this.props;
    return (
      <>
        <p><b>POPULATIONS:</b></p>
        <ul className="added_reference_population_list">
          {reference.populations.map(population => {
            let url = "/admin/trials/" + population.trial_id +"/populations/" + population.id;
            let belongsToTrialLabel;
            if (trial.id === population.trial_id) {
              belongsToTrialLabel = <span className="belongsToTrial">Belongs to this {trial.isTherapy ? 'therapy' : 'trial'}</span>;
            }
            return (
              <li key={population.id}>
                <a href={url}>{population.name}</a> {belongsToTrialLabel}
              </li>
            )})
          }
        </ul>
      </>
    )
  }

  render() {
    let {trial_reference, onMoveUp, onMoveDown, populations} = this.props;
    let {reference} = trial_reference;

    let reference_url = "/admin/references/" + reference.id;
    let remove_btn;

    if (populations.length === 0) {
      remove_btn = <button onClick={e => this.delete()} className="btn btn-sm btn-default">Remove</button>
    }

    return (
      <div className="single-reference">
        <div className="single-reference-header">
          <div>
            <h4><b><a href={reference_url} target="_blank">{reference.full_name}</a></b></h4>
            <small><a href={reference.source_link} target="_blank">{reference.source_link}</a></small>
          </div>
          <div className="btn-group">
            {remove_btn}

            <button onClick={e => onMoveUp(trial_reference)} title="Move Up" className="btn btn-sm btn-default"><span className="glyphicon glyphicon-arrow-up"></span></button>
            <button onClick={e => onMoveDown(trial_reference)} title="Move Down" className="btn btn-sm btn-default"><span className="glyphicon glyphicon-arrow-down"></span></button>
          </div>
        </div>
        <div className="single-reference-info">
          {this.renderPopulations(reference)}
          <p>{this.creationInfo()}</p>
        </div>
      </div>

    )
  }
}

class TrialReferencePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {filter: ''}
  }
  static getDerivedStateFromProps(props, state) {
    if (state.references) {
      state.references = filterReferences(state.references, props.trial_references);
    }

    if (state.latests) {
      state.latests = filterReferences(state.latests, props.trial_references);
    }

    return state;
  }

  componentDidMount() {
    this.fetchLatestsReferences();
  }

  fetchLatestsReferences() {
    let {trial_references} = this.props;
    let exclude_ids = trial_references.map(({reference_id}) => reference_id);
    let url = '/admin/references/latests?exclude_ids=' + exclude_ids.join(',');

    request('GET', url).then(latests => {
      latests = filterReferences(latests, trial_references);
      this.setState({latests});
    });
  }

  addReference(reference, closeModal) {
    let {onAdd, close} = this.props;
    onAdd(reference)
    if (closeModal && close) close();
  }

  search(e) {
    e.preventDefault();
    this.setState({loading: true});
    let {filter} = this.state;
    let url = "/admin/references/search?query=" + filter;
    request('GET', url).then(references => {
      references = filterReferences(references, this.props.trial_references);
      this.setState({references, page: 0, loading: false});
    });
  }

  change(e) {
    let {value} = e.target;
    this.setState({filter: value});
  }

  renderPopulations(reference) {
    if (reference.populations.length === 0) return null;
    return (
      <ul className="added_reference_population_list">
        {reference.populations.map(population => {
          let url = "/admin/trials/" + population.trial_id +"/populations/" + population.id
          return (
            <li key={population.id}>
              <a href={url} target="_blank">{population.name}</a>
            </li>
          )})
        }
      </ul>
    )
  }

  renderResult(reference) {
    return (
      <div className="reference_holder" key={reference.id}>
        <button className="btn btn-success btn-sm" onClick={e => this.addReference(reference)}>Add Reference</button>
        <div>
          <p><a href={reference.url} target="_blank">{reference.full_name}</a></p>
          <p><i className="fa fa-external-link-alt" /> <a href={reference.source_link} target="_blank" className="subtle">{reference.source_link}</a></p>
          {this.renderPopulations(reference)}
        </div>
      </div>

    )
  }

  renderResults() {
    let {references, loading} = this.state;
    if (!references) return null;

    let loading_el;
    if (loading) {
      loading_el = <Loading />
    }

    return (
      <div id="references_search_results" className="ref_search_results">
        {references.map(r => this.renderResult(r))}
        {loading_el}
      </div>
    )
  }

  renderLatests() {
    let {latests} = this.state;
    if (!latests) return null;
    return (
      <div id="latests_references_search_results">
        <h3 className="reference_add_header">Recently Added References</h3>
        {latests.map(r => this.renderResult(r))}
      </div>
    )
  }

  renderSearch() {
    let {filter, loading} = this.state;
    let btn_label = loading ? 'Searching' : 'Search';
    return (
      <div id="new_reference">
        <form onSubmit={e => this.search(e)}>
          <div className="form-group">
            <label className="sr-only">Search by Cancer Type</label>
            <input type="text" className="form-control" placeholder="Search by name, cancer type or intervention name" id="reference_search_term" value={filter} onChange={e => this.change(e)} />
          </div>
          <button className="btn btn-default" disabled={loading}>{btn_label}</button>
          <button className="btn btn-success pull-right" onClick={e => this.setState({show_new: true})}>Create a New Reference</button>
          <div className="clearfix"></div>
        </form>

        {this.renderResults()}
        <hr />
        {this.renderLatests()}
      </div>
    )
  }

  renderCreate() {
    let reference = {groups: []};
    return <ReferencesForm reference={reference} onCancel={e => this.setState({show_new: false})} onCreate={r => this.addReference(r, true)}  />
  }

  render() {
    let {show_new} = this.state;
    if (show_new) {
      return this.renderCreate();
    } else {
      return this.renderSearch();
    }
  }
}

class TrialReferencesChangelog extends Component {

  addUpdate() {
    let {onAddUpdate} = this.props;
    onAddUpdate()
  }

  undoLastCheck() {
    if (!window.confirm('Are you sure?')) return;
    this.props.undoChangelog()
  }

  renderLastUpdate() {
    let {trial_references_changelog} = this.props;
    if (!trial_references_changelog || trial_references_changelog.length === 0) return null;
    let last_update = trial_references_changelog[trial_references_changelog.length -1];
    let date = moment(last_update.date).format('LL')
    let admin_name = last_update.admin_name || '';
    return (
      <div className="last-reference-check-update">
        <p>On {date}, {admin_name} checked for references and found no updates.</p>
        <button className="btn btn-sm btn-link" onClick={e => this.undoLastCheck()}>Undo</button>
      </div>
    )
  }

  render() {
    let {submitting_changelog} = this.props;
    let btn_label = submitting_changelog ? 'Creating log entry' : 'Checked for references and nothing found';
    return (
      <div className="reference-log-holder">
        <h4>References Log</h4>
        {this.renderLastUpdate()}
        <button className="btn btn-primary btn-sm" disabled={submitting_changelog} onClick={e => this.addUpdate()}>{btn_label}</button>
      </div>
    )
  }
}


function filterReferences(references, trial_references) {
    return references.filter(reference => {
      return !trial_references.some(trial_reference => trial_reference.reference_id === reference.id);
    });
  }

class TrialNotes extends Component {
  constructor(props) {
    super(props);
    let {trial_notes} = props;
    this.state = {trial_notes};

    this.addNote = this.addNote.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  cancel() {
    this.setState({current_note: undefined});
  }

  submit() {
    this.setState({submitting: true});
    let {current_note} = this.state;
    let {trial_id} = this.props;

    let url = `/admin/trials/${trial_id}/trial_notes`;

    let method;
    if (current_note.id) {
      url += "/" + current_note.id;
      method = "PUT";
    } else {
      method = "POST";
    }
    request(method, url, {trial_note: current_note}).then(trial_note => {
      let {trial_notes} = this.state;
      if (method === 'POST') {
        trial_notes.splice(0, 0, trial_note);
      } else {
        let index = trial_notes.findIndex(n => n.id === trial_note.id);
        trial_notes[index] = trial_note;
      }
      this.setState({trial_notes, current_note: undefined, submitting: false});
    }).catch(({errors}) => {
      this.setState({errors, submitting: false});
    });
  }

  deleteNote(trial_note) {
    if (!window.confirm('Are you sure?')) return;
    let {trial_id} = this.props;

    let url = `/admin/trials/${trial_id}/trial_notes/${trial_note.id}`;
    request('DELETE', url).then( e => {
      let {trial_notes} = this.state;
      let index = trial_notes.findIndex(n => n.id === trial_note.id);
      trial_notes.splice(index, 1);
      this.setState({trial_notes});
    });
  }


  onChange(current_note) {
    this.setState({current_note});
  }

  addNote() {
    this.setState({current_note: {}});
  }

  editNote(current_note) {
    this.setState({current_note});
  }


  renderNewNote() {
    let {current_note} = this.state;
    if (!current_note || current_note.id) return <button onClick={this.addNote} className="btn btn-sm btn-primary">Add Note</button>;
    return this.renderTrialNoteForm(current_note);
  }

  renderTrialNoteForm(trial_note) {
    let {submitting} = this.state;
    let fields = [
      {name: 'person_id', type: 'slektr', label: 'Who is the source?', hint: 'Select from People added to our system', type: 'slektr', src: "/admin/persons/as_options.json", search_key: PersonSearchKey},
      {name: 'text', type: 'text', required: true , label: 'Note:', rows: 3, autosize: true}

    ]
    return (
      <div key={trial_note.id} className="trial-note-form-holder">
        <Form model={trial_note} model_name="Note" fields={fields} onSubmit={this.submit} onCancel={this.cancel} onChange={this.onChange} submitting={submitting}/>
        <p>You can <a href="/admin/persons/new" target="_blank">add a new person</a> (opens in a new tab)</p>
      </div>
    )
  }

  renderTrialNote(trial_note) {
    let {current_note} = this.state;
    if (current_note && current_note.id === trial_note.id) {
      return this.renderTrialNoteForm(trial_note);
    }

    let date = moment(trial_note.created_at).format('LL');
    let person_el;
    if (trial_note.person_name) {
      let person_link = '/admin/persons/' + trial_note.person_id;
      person_el = <p>SOURCE: <a href={person_link} target="_blank">{trial_note.person_name}</a></p>
    }
    return (
      <div key={trial_note.id} className="single-trial-note-holder">
        {person_el}
        <p>{trial_note.text}</p>

        <div className="note-actions">
          <p className="added-by-text">ADDED BY: {trial_note.admin_name} on {date}</p>
          <div className="btn-group">
            <button onClick={e => this.editNote(trial_note)} className="btn btn-xs btn-default">Edit</button>
            <button onClick={e => this.deleteNote(trial_note)} className="btn btn-xs btn-default">Delete</button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let {trial_notes} = this.state;
    return (
      <div className="trial-note-holder">
        <h4>Trial Notes</h4>
        <p>Record non-public information we learn about this trial.</p>
        {this.renderNewNote()}
        {trial_notes.map(trial_note => this.renderTrialNote(trial_note))}
      </div>
    );
  }
}

class CopyReferencesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {query: ''}
  }

  submit(from_trial_id) {
    let {trial, onChange, close} = this.props;
    let url = `/admin/trials/${trial.id}/references/import_references`;
    request('PUT', url, {from_trial_id}).then(trial_references => {
      onChange(trial_references);
      close();
    });
  }

  search(e) {
    e.preventDefault();
    this.setState({searching: true})
    let {query} = this.state;
    let url = '/admin/trials/search?only_standard_treatment=true&query=' + query;
    request('GET', url).then(results => {
      this.setState({results, searching: false})
    });
  }

  changeQuery(e) {
    let query = e.target.value;
    this.setState({query});
  }

  renderForm() {
    let {query} = this.state;
    return (
      <div>
        <form onSubmit={e => this.search(e)}>
          <div className="form-group">
            <label>Trial</label>
            <input type="text" className="form-control" value={query} onChange={e => this.changeQuery(e)} />
          </div>
          <input type="submit" className="btn btn-success" />
        </form>
        {this.renderResults()}
      </div>
    )
  }

  renderResults() {
    let {results, searching} = this.state;
    if (!results) return null;
    if (searching) return <Loading />

    return (
      <div>
        <h3>Results</h3>
        {results.map(trial => {
          return (
            <div key={trial.id}>
              {trial.name}
              <button className="btn btn-success btn-sm" onClick={e => this.submit(trial.id)}>Copy</button>
            </div>
          )
        })}
      </div>
    )
  }
  render() {
    return this.renderForm();
  }
}
