import React, {Component} from 'react';

import JourneyTable from './journey_table';
import NeestedRecord from './neested_record';
import OptionsCache from './options_cache';
import * as DateHelpers from './date_helpers';
import {globalDefaults} from './journey_data';
import {SideEffectSearchKey} from '../../constants.js.erb';


export default class Symptoms extends JourneyTable {
  constructor(props) {
    super(props);
    this.state = {};
    this.symptomCache = new OptionsCache({source: 'symptoms', onChange: e => this.forceUpdate()})
    this.cancerTypeCache = new OptionsCache({source: 'patient_cancer_types', onChange: e => this.forceUpdate()});
    this.preloadNested();
  }

  componentDidUpdate() {
    this.preloadNested();
  }

  preloadNested() {
    let {symptoms} = this.props.data;
    let side_effect_ids = symptoms.map(s => s.side_effect_id);
    this.symptomCache.preload(side_effect_ids);
  }

  addSymptom() {
    let {data, settings} = this.props;
    let current = globalDefaults({}, data, settings);
    this.setState({current: current});
  }

  delete(symptom) {
    if (!this.validateDeletion('symptom_id', symptom.id)) return;
    if (!confirm('Are you sure?')) return;

    let {onDelete} = this.props;
    let sectionLabel = buildSectionLabel(symptom, this.symptomCache);
    onDelete(BASE_PATH, sectionLabel, symptom);
  }

  show(current) {
    this.setState({current});
  }

  renderForm() {
    let {patient, data, fields_changelog, onAddBatch, onEditBatch, readOnly} = this.props;
    let {current} = this.state;

    let props = {readOnly, patient, data, fields_changelog, onAddBatch, onEditBatch, record: current};
    props.onClose = e => this.setState({current: null});
    props.symptomCache = this.symptomCache;

    return <Symptom {...props} />
  }

  renderList() {
    let {data, readOnly} = this.props;
    let {symptoms} = data;

    return (
      <div>
        <button className="btn btn-success pull-right" onClick={e => this.addSymptom()} disabled={readOnly}>Add Symptom</button>
        <div className="clearfix"></div>
        <p><b>Symptoms</b></p>
        <table className="table table-condensed table-striped jt-log-table" id="symptoms_list">
          <thead>
            <tr>
              <th>Symptom</th>
              <th>Date Reported</th>
              <th>Grade</th>
              <th>Treated?</th>
              <th>Resolved?</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {symptoms.map(symptom => {
              let action_label = readOnly ? 'Show' : 'Edit';
              let side_effect = this.symptomCache.get(symptom.side_effect_id);

              let delete_el;
              if (!readOnly) {
                let id = "delete_symptom-" + symptom.id
                delete_el = <button id={id} className="btn btn-default btn-sm" onClick={e => this.delete(symptom)}><i className="fa fa-trash"></i></button>
              }

              return(
                <tr key={symptom.id}>
                  <td>{side_effect.text}</td>
                  <td>{DateHelpers.formatEstimated(symptom, {field: 'date_reported', estimated_field: 'date_reported_estimated'})}</td>
                  <td>{symptom.grade}</td>
                  <td>{symptom.treated}</td>
                  <td>{symptom.resolved}</td>
                  <td className="actions">
                    <div className="btn-group">
                      <button className="btn btn-default btn-sm" onClick={e => this.show(symptom)}>{action_label}</button>
                      {delete_el}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    );

  }
}

const BASE_PATH = 'symptoms';
function buildSectionLabel(record, symptomCache) {
  let side_effect = symptomCache.get(record.side_effect_id);
  return ['Symptom', side_effect.text];
}

class Symptom extends NeestedRecord {
  constructor(props) {
    super(props);
  }

  getModelName() {
    return "Symptom";
  }

  getBasePath() {
    return BASE_PATH;
  }

  getSectionLabel() {
    let {record} = this.state;
    return buildSectionLabel(record, this.props.symptomCache);
  }

  onChange(field_name, value, meta) {
    super.onChange(field_name, value, meta);
    let {record} = this.state;
    if (record.side_effect_id) {
      this.props.symptomCache.preload([record.side_effect_id]);
    }
  }

  formFields() {
    let {data, symptomCache} = this.props;
    let sideEffectValueLabelCallback = s => {
      let side_effect = symptomCache.get(s.side_effect_id);
      return side_effect.text;
    };

    return [
      {name: 'side_effect_id', label: 'Symptom', required: true, type: 'remote-select2', src: "/admin/side_effects/as_options.json", search_key: SideEffectSearchKey, valueLabelCallback: sideEffectValueLabelCallback},
      {name: 'date_reported', type: 'date'},
      {name: 'date_reported_estimated', label: 'Date Estimated?', type: 'bool'},
      {name: 'grade', type: 'select', collection: ['1', '2', '3', '4', '5']} ,
      {name: 'treated', type: 'select', collection: ['Yes', 'No', 'Unknown']},
      {name: 'resolved', type: 'select', collection: ['Yes', 'No', 'Unknown']},
      {name: 'date_resolved', type: 'date'},
      {name: 'date_resolved_estimated', label: 'Date Estimated?', type: 'bool'},
      {name: 'notes', type: 'text'}
    ];
  }
}
