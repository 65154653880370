import React, {Component} from 'react';
import Form from './form';
import request from '../request';

export default class DiagnosticAndGeneOptions extends Component {
  constructor(props) {
    super(props);
    let current_value = {name: ''};
    this.state = {current_value}
    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }


  componentDidMount() {
    this.modalEl = $(this.refs.modal_container);
    this.modalEl.modal({show: false});
  }

  onSubmit(e) {
    let {current_value} = this.state;
    let {field, value, onChange} = this.props;

    if (current_value.id) {
      let index = value.findIndex(r => r.id === current_value.id);
      value[index] = current_value;
    } else {
      current_value.id = generateUUID();
      value.push(current_value);
    }



    let meta = {value_label: ''};
    onChange({target: {name: field.name, value}}, meta);

    current_value = {name: ''}
    this.setState({current_value});
  }

  delete(result) {
    if (!confirm('Are you sure?')) return;

    let {field, value, onChange} = this.props;

    let index = value.findIndex(r => r.id === result.id);
    value.splice(index, 1);

    let meta = {value_label: ''};
    onChange({target: {name: field.name, value}}, meta);
  }

  onCancel(e) {
    let current_value = {name: ''};
    this.setState({current_value});
  }

  onChange(current_value) {
    this.setState({current_value});
  }

  edit(result) {
    let current_value = Object.assign({}, result);
    this.setState({current_value});
  }

  showModal(result, populations) {
    let modal_data = {result, populations};
    this.setState({modal_data}, () => this.modalEl.modal('show'));
  }

  renderPopulations() {
    let {model} = this.props;
    let {modal_data} = this.state;
    if (!modal_data) return null;
    let {result, populations} = modal_data;
    let trials = groupByTrial(populations);

    return (
      <div>
        <h4>{model.name} - {result.name}</h4>
        <table className="table table-condensed table-striped">
          <thead>
            <tr>
              <th>Status in app</th>
              <th>Name</th>
              <th>Trial Status</th>
              <th>Populations</th>
            </tr>
          </thead>
          <tbody>
            {trials.map(trial => {
              let link_to_trial = "/admin/trials/" + trial.id + "/edit";
              return (
                <tr key={trial.id}>
                <td>{trial.status_humanized}</td>
                <td><a href={link_to_trial} target="_blank">{trial.name}</a></td>
                <td>{trial.overall_status}</td>
                <td>
                  <ul>
                    {trial.populations.map(population => {
                      let link_to_population = "/admin/trials/" + trial.id + "/populations/" + population.id;
                      return (
                        <li key={population.id}><a href={link_to_population} target="_blank">{population.name}</a></li>
                      );
                    })}
                  </ul>
                </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  renderModal() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">This record was used in the following populations</h4>
            </div>

            <div className="modal-body">
              {this.renderPopulations()}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

    );
  }

  renderForm() {
    let {current_value} = this.state;
    let fields = [{name: 'name', required: true}];
    return (
      <div className="manage-result-holder">
        {this.renderFormHeader()}
        <Form containerElement='div' model={current_value} fields={fields} model_name="Result" onChange={this.onChange} onSubmit={this.onSubmit} onCancel={this.onCancel} />
      </div>
    )
  }


  renderAny() {
    return null;
  }

  renderTable() {
    let {value} = this.props;
    return (
      <table className="table table-condensed table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Populations</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.renderAny()}

          {value.sort(compareNames).map(result => {
            let populations = this.findConnectedPopulations(result);
            let populations_btn;
            if (populations.length > 0) {
              populations_btn = <button className="btn btn-link" onClick={e => this.showModal(result, populations)}>Tagged in {populations.length} populations</button>
            }

            return (
              <tr key={result.id}>
                <td>{result.name}</td>
                <td>{populations_btn}</td>
                <td>
                  <div className="btn-group pull-right">
                    <button className="btn btn-sm btn-default" onClick={e => this.edit(result)}>Edit</button>
                    <button className="btn btn-sm btn-danger" onClick={e => this.delete(result)} disabled={populations.length > 0} >Delete</button>
                  </div>
                  <div className="clearfix"></div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  render() {
    return (
      <div id="diagnostic_value_container">
        {this.renderHeader()}
        {this.renderForm()}
        {this.renderTable()}
        {this.renderModal()}
      </div>
    );
  }
}


function groupByTrial(populations) {
  let trials = [];
  for (let population of populations) {
    let trial = trials.find(t => t.id === population.trial_id);
    if (!trial) {
      trial = Object.assign({}, population.trial, {populations: []});
      trials.push(trial);
    }
    trial.populations.push(population);
  }

  return trials;
}


function compareNames(a, b ) {
  let name_a = a.name.toLowerCase();
  let name_b = b.name.toLowerCase();

  if ( name_a < name_b ){
    return -1;
  }
  if ( name_a > name_b ){
    return 1;
  }
  return 0;
}
