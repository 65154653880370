import React, {Component} from 'react';

export default class MapViewer extends Component {
  constructor(props) {
    super(props);
    this.selectionLayers = [];
  }


  componentDidMount() {
    this.setupMap();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.height !== this.props.height) {
      this.onResize();
    }

    if (this.markersLayer && prevProps.markers !== this.props.markers) {
      this.resetMakers();
    }
  }

  setupMap() {
    let el = this.refs.map;
    if (!el) return;

    let {lat, lon, onClickOnMap, selectable} = this.props;
    // Create the map
    this.map = L.map(el, {trackResize: true, zoomControl: false});

    //add zoom control with your options
    L.control.zoom({position:'bottomright'}).addTo(this.map);

    if (onClickOnMap) {
      this.map.on('click', onClickOnMap);
    }

    // Add the tiles
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://cloudmade.com">CloudMade</a>',
      maxZoom: 18
    }).addTo(this.map);

    this.createMarkers();
    this.centerMap();

    if (selectable) {
      this.createEditableLayer();
    }
  }

  createMarkers() {
    this.groupedMarkersLayer = L.markerClusterGroup(markerClusterOptions()).addTo(this.map);
    this.markersLayer = L.layerGroup().addTo(this.map);

    let {markers} = this.props;
    if (!markers || markers.length === 0) return;

    for (let marker of markers) {
      let options = {title: marker.title, zIndexOffset: marker.zIndexOffset};
      if (marker.style) {
        options.icon = createMarkerIcon(marker.style);
      }

      let m = L.marker([marker.lat, marker.lon], options);

      if (marker.onClick) {
        m.on('click', marker.onClick);
      }

      if (marker.grouped) {
        m.addTo(this.groupedMarkersLayer);
      } else {
        m.addTo(this.markersLayer);
      }
    }
  }

  createEditableLayer() {
    var editableLayers = new L.FeatureGroup();

    var drawPluginOptions = {
      position: 'topright',
      draw: {
        polygon: {
          allowIntersection: false, // Restricts shapes to simple polygons
          drawError: {
            color: '#e1e100', // Color the shape will turn when intersects
            message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
          },
          shapeOptions: {
            color: '#97009c'
          }
        },
        // disable toolbar item by setting it to false
        polyline: false,
        circle: false, // Turns off this drawing tool
        rectangle: false,
        marker: false,
        circlemarker: false,
        polygon: true
      },
      edit: {
        featureGroup: editableLayers, //REQUIRED!!
        remove: true
      }
    };

    // Initialise the draw control and pass it the FeatureGroup of editable layers
    var drawControl = new L.Control.Draw(drawPluginOptions);
    this.map.addControl(drawControl);


    this.map.addLayer(editableLayers);

    this.map.on('draw:created',e => {
      var type = e.layerType;
      let layer = e.layer;
      editableLayers.addLayer(layer);
      this.selectionLayers.push(layer);
      this.selectMakers();
      // let polygon = layer.getLatLngs();
      // console.log(layer);
    });

    this.map.on('draw:deleted',e => {
      let layers = e.layers.getLayers();
      for (let layer of layers) {
        let index = this.selectionLayers.findIndex(l => l._leaflet_id === layer._leaflet_id);
        this.selectionLayers.splice(index, 1);
      }
      this.selectMakers();
    });
  }

  selectMakers() {
    let {markers, onSelectionChange} = this.props;
    if (!onSelectionChange) return;

    if (this.selectionLayers.length === 0) {
      onSelectionChange([]);
      return;
    }

    let selected = [];
    for (let marker of markers) {
      if (!marker.excludeSelection) {
        for (let layer of this.selectionLayers) {
          if (layer.getBounds().contains(marker)) {
            selected.push(marker.data);
          }
        }
      }
    }

    onSelectionChange(selected);
  }


  deleteMakers() {
    this.map.removeLayer(this.groupedMarkersLayer)
    this.map.removeLayer(this.markersLayer)
  }

  resetMakers() {
    this.deleteMakers();
    this.createMarkers();
  }

  centerMap() {
    let {lat, lon, zoom, markers} = this.props;

    if (lat && lon) {
      if (!zoom) zoom = DEFAULT_ZOOM;
      this.map.setView([lat, lon], zoom);
      let marker = L.marker([lat, lon]).addTo(this.map);
    } else if (markers && markers.length > 0) {
      let points = markers.map(m => [m.lat, m.lon]);
      this.map.fitBounds(points);
    } else {
      this.map.fitBounds([[0.0, 0.0]]);
    }
  }

  onResize() {
    if (!this.map) return;
    this.map.invalidateSize();
    this.centerMap();
  }

  renderNoData() {
    return (
      <div>
        <h1>No data to render the map</h1>
      </div>
    );
  }

  render() {
    let {width, height, lat, lon, markers} = this.props;
    // if (!(lat || lon) && (!markers || markers.length === 0)) return this.renderNoData();
    let style = {width, height};
    return  <div ref="map" style={style}></div>
  }
}

const DEFAULT_ZOOM = 14;

function createMarkerIcon(style) {
	let myCustomColour = style.color || '#0000ff';

	let size = 10,    // size of the marker
		border = 2;   // border thickness

	let markerHtmlStyles = `
		background-color: ${myCustomColour};
		width: ${size * 3}px;
		height: ${size * 3}px;
		display: block;
		left: ${size * -1.5}px;
		top: ${size * -1.5}px;
		position: relative;
		border-radius: ${size * 3}px ${size * 3}px 0;
		transform: rotate(45deg);
		border: ${border}px solid #FFFFFF;
		`;

	let captionStyles = `
		transform: rotate(-45deg);
		display:block;
		width: ${size * 3}px;
		text-align: center;
		line-height: ${size * 3}px;
		`;

  let caption = '';
  if (style.icon) {
    caption = '<i class="fa fa-' + style.icon + '" />'
  } else if (style.caption) {
    caption = style.caption;
  }

	let icon = L.divIcon({
		className: 'color-pin-' + myCustomColour.replace('#', ''),
		iconAnchor: [border, size*2 + border*2],
		labelAnchor: [-(size/2), 0],
		popupAnchor: [0, -(size*3 + border)],

		html: `<span style="${markerHtmlStyles}"><span class="marker-caption" style="${captionStyles}">${caption}</span></span>`
	});

	return icon;
}


function markerClusterOptions() {
  return {
    disableClusteringAtZoom: 7


    // iconCreateFunction: function(cluster) {
    //   console.log(cluster.getAllChildMarkers());

    //   let childCount = 69;

    //   let  c = ' marker-cluster-';
		//   if (childCount < 10) {
		// 	  c += 'small';
		//   } else if (childCount < 100) {
		// 	  c += 'medium';
		//   } else {
		// 	  c += 'large';
		//   }
    //   return new L.DivIcon({ html: '<div><span>' + childCount + '</span></div>', className: 'marker-cluster' + c, iconSize: new L.Point(40, 40) });
	  // }
  }
}
