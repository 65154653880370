
import React, {Component} from 'react';
import PopulationSummary from './populations/summary';

const COLUMN_WIDTH = 600;

export default class PopulationsComparator extends Component {
  constructor(props) {
    super(props);
    let columns = this.initColumns(props);
    this.state = {columns};
  }

  initColumns(props) {
    let columns = [{}, {}];

    if (props.left_population_id) {
      columns[0].population = props.populations.find(p => p.id == props.left_population_id);
    }

    return columns;
  }

  addColumn() {
    let columns = this.state.columns;
    columns.push({});
    this.setState({columns});
  }

  deleteColumn(index) {
    let columns = this.state.columns;
    columns.splice(index, 1);
    this.setState({columns});
  }

  select(index, e) {
    let population_id = +e.target.value;
    if (!population_id) return;

    let {populations} = this.props;
    let population = populations.find(p => p.id == population_id);

    let columns = this.state.columns;
    columns[index].population = population;
    this.setState({columns});
  }

  renderColumn(index) {
    let data = this.state.columns[index];
    if (!data.population) return null;
    return(
      <div>
        <h3>{data.population.name}</h3>
        <PopulationSummary population={data.population} />
      </div>
    )
  }

  renderSelector(index) {
    let data = this.state.columns[index];
    let value = data && data.population && data.population.id;

    let {populations} = this.props;
    let options = populations.map(p => (
      <option key={p.id} value={p.id}>{p.name}</option>
    ));

    return(
      <div>
        <select value={value} onChange={e => this.select(index, e)}>
          <option >Select a Population</option>
          {options}
        </select>
        <button onClick={e => this.deleteColumn(index)} className="btn btn-default">Close</button>
      </div>
    )
  }

  renderContent(index) {
    return (
      <div className="summary" style={{width: COLUMN_WIDTH}} key={index}>
        {this.renderSelector(index)}
        {this.renderColumn(index)}
      </div>
    )
  }

  render() {
    let content = [];
    let columns_count = this.state.columns.length;
    for (let i=0; i < columns_count; i++) {
      content.push(this.renderContent(i));
    }
    let width = COLUMN_WIDTH * (columns_count + 1);

    return (
      <div id="population_summaries" style={{width}}>
        {content}
        <div className="add_column">
          <button onClick={e => this.addColumn()} className="btn btn-default">Add column</button>
        </div>
      </div>
    )
  }
}
