import React, {Component} from 'react';
import marked from 'marked'

export default  class Makdown extends Component {
  constructor(props) {
    super(props);
    this.renderer = new marked.Renderer();
    this.renderer.link = function( href, title, text ) {
      if (!title) title = '';
      return '<a target="_blank" href="'+ href +'" title="' + title + '">' + text + '</a>';
    }
  }
  render() {
    let {text, className, id} = this.props;
    if (!text || text.length === 0) return null;
    let html = marked(text, {renderer: this.renderer, gfm: true, breaks: true});
    return <div id={id} className={className} dangerouslySetInnerHTML={{__html: html}} />
  }
}
