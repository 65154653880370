import React, {Component} from 'react';
import request from '../request';

export default class Select2 extends Component {
  constructor(props) {
    super(props);
    let defaultValue = props.multiple ? [] : ""
    this.state = {value: props.value || defaultValue};

    this.initEl = this.initEl.bind(this);
    this.queryBuilder = this.queryBuilder.bind(this);
    this.changeCallback = this.changeCallback.bind(this);
  }

  componentDidMount() {
    this.initEl(this.refs.select2);
    let {value, options, tags} = this.props;
    if (!options && !tags) {
      this.fetchOptions(value);
      return;
    }
  }

  componentDidUpdate(prevProps) {
    let {value, options, tags} = this.props;
    if (!options && !tags && value !== prevProps.value) {
      this.fetchOptions(value);
    }
  }

  fetchOptions(value) {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      this.el.val(null).trigger('change');
      return;
    }

    value = Array.isArray(value) ? value.join(",") : value
    if (!value || value.length === 0) return;

    let {src} = this.props;
    src += "?ids=" + value
    request("GET", src).then(data => {
      this.el.val(null)//.trigger('change');
      for (let opt of data.results) {
        var newOption = new Option(opt.text, opt.id, false, true);
        this.el.append(newOption);
      }
    });
  }

  initEl(el) {
    let {editable, remote, multiple, src, value, tags, allowBlank, placeholder} = this.props;
    let params = {multiple, tags};

    if (tags) params.dropdownCssClass = 'select2-hidden';

    if (!editable && !tags) {
      params.createTag = (params) => {
        return undefined;
      }
    }

    if (remote) {
      params.minimumInputLength = 2;
      params.delay = 250;
      params.ajax = {url: src, data: this.queryBuilder, dataType: "json"}
    }

    params.allowClear = allowBlank
    params.placeholder = placeholder || {};

    this.el = $(el);
    this.el.select2(params)
    this.el.on("select2:select", this.changeCallback);
    this.el.on("select2:unselect", this.changeCallback);
    window.select2 = this.el;
  }


  queryBuilder(input) {
    let {searchField, filters} = this.props;
    let q = {}
    q[searchField] = input.term;
    let params = {q, page: input.page}
    if (filters) {
      params.extra_filters = filters;
    }
    return params;
  }

  changeCallback(data) {
    let {onChange, name, multiple} = this.props;
    if (!onChange) return;

    let value = this.el.val() || (multiple ? [] : null);

    // Avoid duplicates
    if (multiple) value = value.unique();

    let meta = {value_label: data.params.data.text};
    onChange({target: {name, value, el: this.el}}, meta);
  }

  render() {
    let {id, multiple, value, allowBlank, blankLabel, options} = this.props;

    let blank, option_tags;
    if (allowBlank || value === '') {
      let label = blankLabel || 'Select option';
      blank = <option value=''>{label}</option>
    }

    if (options) {
      option_tags = options.map(({id, label}) => <option key={id} value={id}>{label}</option>);
    }

    return(
      <select id={id} ref="select2" style={{width: "100%"}} multiple={multiple} value={value} onChange={e => true }>
        {blank}
        {option_tags}
      </select>
    )
  }
}
