import React, {Component} from 'react';

export default class Loading extends Component {
  renderText() {
    let {text, inline} = this.props;
    if (!text) return null;
    return <h2>{text}</h2>
  }

  render() {
    let size = this.props.size || 80;
    let {inline, containerStyle} = this.props;
    return(
      <div className={containerStyle}>
        {this.renderText()}
        <i id="patient-widget-loading" className="glyphicon glyphicon-refresh glyphicon-spin" style={{fontSize: size}}/>
      </div>
    );
  }
}
