import React, {Component} from 'react';

import {findDependents, sectionLabel} from './data_repo';

export default class JourneyTable extends Component {
  validateDeletion(field_name, id) {
    let {data} = this.props;
    let dependents = findDependents(data, field_name, id);
    if (Object.keys(dependents).length === 0) return true;

    this.setState({dependents_records: dependents});
    return false;
  }


  renderDeleteAlert() {
    let {dependents_records} = this.state;
    if (!dependents_records) return null;
    return <DependentAlert records={dependents_records} onClose={e => this.setState({dependents_records: null})}/>
  }


  renderListLayout() {
    return (
      <div>
        {this.renderDeleteAlert()}
        {this.renderList()}
      </div>
    )
  }

  render() {
    let {current} = this.state;
    if (current) {
      return this.renderForm();
    } else {
      return this.renderListLayout();
    }
  }

}


class DependentAlert extends Component {
  componentDidMount() {
    $(this.refs.modal_container).modal({show: true});
    $(this.refs.modal_container).on('hide.bs.modal', () => {
      let {onClose} = this.props;
      onClose();
    });
  }

  renderRecords() {
    let {records} = this.props;
    return (
      <ul>
        {Object.keys(records).map(table => {
          return <li key={table}>{sectionLabel(table)}</li>
        })}
      </ul>
    )
  }

  render() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">Can't delete the record</h4>
            </div>
            <div className="modal-body">
              <p>We have found that the record you are deleting is used by:</p>
              {this.renderRecords()}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
