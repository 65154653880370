import React, {Component} from 'react';
import Loading from '../loading';
import SearchResult from './results';

export default class CancerCentersList extends Component {
  constructor(props) {
    super(props);
    let {results} = props;
    let cancer_centers = this.buildCancerCentersList(results);

    this.state = {cancer_centers};
    if (this.anyStateSelected()) {
      this.state.any_state_selected = true;
      this.state.show_filtered = true;
      this.state.visible_cancer_centers = this.filterVisibleCancerCenters(cancer_centers);
    }
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    this.el = $(this.refs.modal_container);
    this.el.modal({show: true});
    this.el.on('hidden.bs.modal', e => {
      this.close();
    });
  }


  close() {
    this.el.modal('hide');
    let {onClose} = this.props;
    if (onClose) onClose();
  }


  buildCancerCentersList(results) {
    let centers = [];
    for (let result of results) {
      for (let location of result.trial.trial_locations) {
        for (let cancer_center of location.cancer_centers) {
          let center;
          let index = centers.findIndex(c => c.data.id === cancer_center.id);

          if (index === -1) {
            center = {data: cancer_center, results: []};
            centers.push(center);
          } else {
            center = centers[index];
          }

          // Prevent duplicated results if many trial locations are
          // linked to the same cancer center
          if (center.results.findIndex(r => r.trial.id === result.trial.id) === -1) {
            center.results.push(result);
          }
        }
      }
    }

    return centers.sort(( a, b ) => {
      if ( a.results.length < b.results.length ){
        return 1;
      }
      if ( a.results.length > b.results.length ){
        return -1;
      }
      return 0;
    })
  }

  filterVisibleCancerCenters(cancer_centers) {
    let {query} = this.props;
    let states = query.locations.states;
    return cancer_centers.filter(cancer_center => {
      return states[cancer_center.data.state];
    });
  }

  toggleFilteredButton() {
    let show_filtered = !this.state.show_filtered;
    this.setState({show_filtered});
  }

  anyStateSelected() {
    let {query} = this.props;
    if (!query.locations || !query.locations.states) {
      return false;
    }
    let states = Object.keys(query.locations.states).filter(country => query.locations.states[country])

    return states.length > 0;

  }

  visibleCancerCenters() {
    if (this.state.show_filtered) {
      return this.state.visible_cancer_centers;
    } else {
      return this.state.cancer_centers;
    }
  }

  renderContent() {
    let {admin_mode, patient, selection, collections, addTrialToCollection, toggleSelection, onCollectionsChange} = this.props;
    let cancer_centers = this.visibleCancerCenters();

    return (
      <div id="cancer_centers_list">
        {cancer_centers.map(center => {
          return <CancerCenterResult key={center.data.id} center={center}  admin_mode={admin_mode} patient={patient} collections={collections} addTrialToCollection={addTrialToCollection} toggleSelection={toggleSelection} selection={selection} onCollectionsChange={onCollectionsChange} />
        })}
      </div>
    );
  }

  renderToggleFilteredButton() {
    let {any_state_selected, visible_cancer_centers} = this.state;
    if (!any_state_selected || visible_cancer_centers.length === 0) return null;
    let label, btnLabel;
    if (this.state.show_filtered) {
      label = 'Cancer Centers filtered by states selected';
      btnLabel =  'Show all Cancer Centers';
    } else {
      btnLabel = 'Hide Cancer Centers from unselected states';
    }
    return (
      <div>
        {label}<br/>
        <button className="btn btn-sm btn-default" onClick={e => this.toggleFilteredButton()}>{btnLabel}</button>
      </div>
    );
  }

  render() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div id="saved_searches_modal" className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={this.close} aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title"><b>Trials by Cancer Center <span className="small">Click to view trials</span></b></h4>
              {this.renderToggleFilteredButton()}
            </div>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

class CancerCenterResult extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.toggleVisiblity = this.toggleVisiblity.bind(this);
  }

  toggleVisiblity() {
    let show = !this.state.show;
    this.setState({show});
  }

  renderResults() {
    let {center, admin_mode, patient, selection, collections, addTrialToCollection, toggleSelection, onCollectionsChange} = this.props;
    let {show} = this.state;
    if (!show) return null;

    return (
      <div className="cancer_center_trials_list">
        {center.results.map(result => {
          let selected = selection.indexOf(result.trial.id) !== -1;
          let key = `${center.data.id}::${result.trial.id}`;
          return <SearchResult {...result} key={key} admin_mode={admin_mode} patient={patient} collections={collections} addTrialToCollection={addTrialToCollection} selected={selected} selectCallback={toggleSelection} onCollectionsChange={onCollectionsChange} />
        })}
      </div>
    )
  }

  render() {
    let {center} = this.props;


    return (
      <div className="cancer_center_result">
        <p onClick={this.toggleVisiblity} className="clickable">
          <b>{center.results.length}</b>&nbsp;-&nbsp;{center.data.name}
        </p>

        {this.renderResults()}
      </div>
    )
  }
}
