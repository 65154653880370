import React, {Component} from 'react';
import Form from './form';
import request from '../request';

export default class CancerCategoryForm extends Component {
  constructor(props) {
    super(props);
    let {cancer_category} = props;
    this.state = {cancer_category};
    this.onChange = this.onChange.bind(this);
  }

  submit(e) {
    e.preventDefault();
    this.setState({submitting: true});
    let {cancer_category} = this.state;

    let url = "/admin/cancer_categories";
    let method, was_new;


    if (cancer_category.id) {
      url += "/" + cancer_category.id;
      method = "PUT"
    } else {
      was_new = true;
      method = "POST"
    }

    request(method, url, {cancer_category}).then(cancer_category => {
      this.setState({cancer_category, errors: [], submitting: false});
      if (!was_new) window.location = "/admin/cancer_categories/";

    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors, submitting: false});
    });
  }

  onChange(field, value) {
    let {cancer_category} = this.state;
    cancer_category[field] = value;
    this.setState({cancer_category});
  }


  renderTitle() {
    let {cancer_category} = this.state;

    if (cancer_category.id) return <h1>Edit Cancer Category</h1>
    else return <h1>New Cancer Category</h1>
  }

  renderForm() {
    let {cancer_category, errors} = this.state;

    let fields = [
      {name: 'name', required: true},
      {name: 'tumor_type', type: 'radio', collection: TUMOR_TYPE_OPTIONS, required: true}
    ];

    return (
      <div id="cancer_category_form">
        <Form model_name="Cancer Category" model={cancer_category} fields={fields} errors={errors} onChangeField={this.onChange} />
      </div>
    );
  }

  renderSubmitButton() {
    let {cancer_category, submitting} = this.state;
    return <Form model_name="Cancer Category" model={cancer_category} fields={[]} onSubmit={e => this.submit(e)} submitting={submitting} />
  }


  renderTraits() {
    let {traits} = this.props;
    let {cancer_category} = this.state;
    if (!cancer_category.id) return null;

    return <Traits cancer_category={cancer_category} traits={traits} />;
  }

  render() {
    let {cancer_category} = this.state;

    return (
      <div>
        {this.renderTitle()}
        <hr/>
        <div className="well">
          {this.renderForm()}
          <Tags tags={cancer_category.tags} onChange={this.onChange} />
          {this.renderSubmitButton()}
        </div>
        {this.renderTraits()}
      </div>
    );
  }
}


class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {selected: {}};
    this.onChange = this.onChange.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    let {selected} = this.state;
    let {tags, onChange} = this.props;

    if (selected.id) {
      let index = tags.findIndex(t => t.id === selected.id);
      tags[index] = selected;
    } else {
      selected.id = generateUUID();
      tags.push(selected);
    }

    onChange('tags', tags);
    this.setState({selected: {}})
  }

  delete(tag) {
    if (!confirm('Are you sure?')) return;
    let {tags, onChange} = this.props;
    let index = tags.findIndex(t => t.id === tag.id);
    tags.splice(index, 1);
    onChange('tags', tags);
  }

  moveTagUp(tag) {
    let {tags, onChange} = this.props;
    let index = tags.findIndex(t => t.id === tag.id);
    tags.splice(index, 1);
    tags.splice(index - 1, 0, tag);
    onChange('tags', tags);
  }

  moveTagDown(tag) {
    let {tags, onChange} = this.props;
    let index = tags.findIndex(t => t.id === tag.id);
    tags.splice(index, 1);
    tags.splice(index + 1, 0, tag);
    onChange('tags', tags);
  }


  onCancel() {
    this.setState({selected: {}})
  }

  onChange(selected) {
    this.setState({selected})
  }

  select(tag) {
    let selected = Object.assign({}, tag);
    this.setState({selected})
  }

  renderForm() {
    let {selected} = this.state;
    let fields = [{name: 'text', required: true, label: "Add Tag"}];

    let cancelCallback;
    if (selected.id) {
      cancelCallback = e => this.onCancel(e);
    }

    return <Form key="form" model_name="Tag" model={selected} fields={fields} onChange={this.onChange} onSubmit={e =>this.onSubmit(e)} onCancel={cancelCallback} />
  }


  render() {
    let {tags} = this.props;
    let {selected} = this.state;
    return (
      <div className="option-list">
        <h4><b>Tags</b></h4>
        <hr/>
        {tags.map(tag => {
          return (
            <div key={tag.id} className="single-option">
              <div className="btn-group">
                <button className="btn btn-sm btn-default" onClick={e => this.moveTagUp(tag)}><span className="fa fa-arrow-up"/></button>
                <button className="btn btn-sm btn-default" onClick={e => this.moveTagDown(tag)}><span className="fa fa-arrow-down"/></button>
                <button className="btn btn-sm btn-primary" onClick={e => this.select(tag)}><span className="fa fa-pencil-alt" /></button>
                <button className="btn btn-sm btn-danger" onClick={e => this.delete(tag)}><span className="fa fa-trash"/></button>
              </div>
              <span className="single-option-text">{tag.text}</span>
            </div>
          );
        })}
        <div className="well">
          {this.renderForm()}
        </div>
      </div>
    );

  }
}


class Traits extends Component {
  constructor(props) {
    super(props);
    let traits = props.traits || [];
    this.state = {traits, current: defaultTrait(), current_option: {}};
  }

  onSubmit() {
    let {current} = this.state;
    this.submitTrait(current).then(() => {
      this.setState({current: defaultTrait(), current_option: {}});
    });
  }


  submitTrait(trait) {
    this.setState({submitting: true});
    let {cancer_category} = this.props;
    let {traits} = this.state;

    let method, was_new;
    let url = `/admin/cancer_categories/${cancer_category.id}/traits`;
    if (trait.id) {
      url += "/" + trait.id;
      method = "PUT"
    } else {
      was_new = true;
      method = "POST"
    }

    return request(method, url, {trait: trait}).then(trait => {
      if (was_new) {
        traits.push(trait);
      } else {
        let index = traits.findIndex(t => t.id === trait.id);
        traits[index] = trait;
      }

      this.setState({traits, submitting: false});
    });
  }

  moveTraitUp(trait) {
    let {cancer_category} = this.props;
    let url = `/admin/cancer_categories/${cancer_category.id}/traits/${trait.id}/move_up`;
    request('PUT', url).then(traits => {
      this.setState({traits});
    });
  }

  moveTraitDown(trait) {
    let {cancer_category} = this.props;
    let url = `/admin/cancer_categories/${cancer_category.id}/traits/${trait.id}/move_down`;
    request('PUT', url).then(traits => {
      this.setState({traits});
    });
  }


  moveOptionUp(option) {
    let {cancer_category} = this.props;
    let {current} = this.state;
    let index = current.options.findIndex(o => o.id === option.id);
    if (index === 0) return;
    current.options.splice(index, 1);
    current.options.splice(index - 1, 0, option);
    this.setState({current});
  }

  moveOptionDown(option) {
    let {cancer_category} = this.props;
    let {current} = this.state;
    let index = current.options.findIndex(o => o.id === option.id);
    if (index === current.options.length -1) return;
    current.options.splice(index, 1);
    current.options.splice(index + 1, 0, option);
    this.setState({current});
  }

  // delete(trait) {
  //   if (!confirm('Are you sure?')) return;
  //   let {cancer_category} = this.props;
  //   let {current, traits} = this.state;

  //   let url = `/admin/cancer_categories/${cancer_category.id}/traits/${trait.id}`;
  //   request('DELETE', url).then(e => {
  //     let index = traits.findIndex(t => t.id === trait.id);
  //     traits.splice(index, 1);
  //     this.setState({traits});
  //   });
  // }

  // deleteOption(option) {
  //   if (!confirm('Are you sure?')) return;
  //   let {current, traits} = this.state;
  //   let option_index = current.options.findIndex(o => o.id === option.id);
  //   current.options.splice(option_index, 1);

  //   let trait_index = traits.findIndex(t => t.id === current.id);
  //   traits[trait_index] = current;
  //   this.setState({traits, current});
  // }

  select(trait) {
    let current = Object.assign({}, trait);
    this.setState({current});
  }

  selectOption(option) {
    let current_option = Object.assign({}, option);
    this.setState({current_option});
  }


  onSubmitOption() {
    let {current, current_option} = this.state;
    if (current_option.id) {
      let index = current.options.findIndex(o => o.id === current_option.id);
      current.options[index] = current_option;
    } else {
      current_option.id = generateUUID();
      current.options.push(current_option);
    }

    this.setState({current, current_option: {}});
  }

  onChangeCurrent(current) {
    this.setState({current});
  }

  onChangeCurrentOption(current_option) {
    this.setState({current_option});
  }


  renderList(){
    let {traits} = this.state;
    return traits.map(trait => {
      let search_side_el, applies_to_all_el, essential_el;
      let id = "trait_" + trait.id;
      if (trait.search_side_label && trait.search_side_label.length > 0) {
        search_side_el = <p>{trait.search_side_label}</p>
      }
      if (trait.applies_to_all) {
        applies_to_all_el = <strong>Applies to all</strong>;
      }
      if (trait.essential) {
        essential_el = <strong>Essential</strong>;
      }

      return (
        <div id={id} key={trait.id} className="panel panel-default">
          <div className="panel-body">
            <h4><button className="btn btn-link" onClick={e => this.select(trait)} title={"Trait ID: " + trait.id}>{trait.name}</button></h4>
            {search_side_el}
            {applies_to_all_el} {essential_el}
            <p><small>{trait.option_type_humanized}</small></p>

            {trait.options.map(option => {
              return <span key={option.id} className="badge">{option.text}</span>
            })}

            <hr />
            <div className="trait-footer">
              <div className="btn-group">
                <button className="btn btn-sm btn-default" onClick={e => this.moveTraitUp(trait)}><span className="fa fa-arrow-up"/></button>
                <button className="btn btn-sm btn-default" onClick={e => this.moveTraitDown(trait)}><span className="fa fa-arrow-down"/></button>
                <button className="btn btn-primary btn-sm" onClick={e => this.select(trait)}><span className="fa fa-pencil-alt"></span></button>
              </div>
              <p className="small-text">Click edit and then make edits in the form above.</p>
            </div>
          </div>
        </div>
      );
    });
  }

  renderOptionsList() {
    let {current} = this.state;
    return (
      <div className="option-list">
        <h4><b>Options</b></h4>
        <hr/>
        {current.options.map(option => {
          let search_side_el;
          if (option.search_side_label && option.search_side_label.length > 0) {
            search_side_el = <span>[{option.search_side_label}]</span>
          }
          return (
            <div key={option.id} className="single-option">
              <div className="btn-group">
                <button className="btn btn-sm btn-default" onClick={e => this.moveOptionUp(option)}><span className="fa fa-arrow-up"/></button>
                <button className="btn btn-sm btn-default" onClick={e => this.moveOptionDown(option)}><span className="fa fa-arrow-down"/></button>

                <button className="btn btn-primary btn-sm" onClick={e => this.selectOption(option)}><span className="fa fa-pencil-alt"></span></button>
              </div>
              <span className="single-option-text">{option.text} {search_side_el}</span>
            </div>
          );
        })}
      </div>
    );
  }
  renderOptionForm() {
    let {current_option, current} = this.state;
    let fields = [
      {name: 'text', required: true},
      {name: 'search_side_label'},
      {name: 'summary_text', type: 'text'}
    ];

    let onCancelCallback;
    if (current_option.id) onCancelCallback = e => this.setState({current_option: {}});

    return (
      <div className="well">
        <h4>Add Option</h4>
        <Form model_name="Option" model={current_option} fields={fields} onChange={o => this.onChangeCurrentOption(o)} onSubmit={e => this.onSubmitOption(e)} onCancel={onCancelCallback} />
      </div>
    );
  }

  renderForm() {
    let {current, submitting} = this.state;
    let fields = [

      {name: 'name', required: true},
      {name: 'search_side_label'},
      {name: 'essential', type: 'bool'},
      {name: 'applies_to_all', label: "Applies to all types in category?", type: 'bool'},
      {name: 'option_type', type: 'radio', collection: TRAIT_OPTION_TYPE},
      {name: 'summary_text', type: 'text', label: 'Summary Sentence Text'}
    ];

    let onCancelCallback;
    if (current.id) onCancelCallback = e => this.setState({current: defaultTrait()});

    return (
      <div className="well">
        <Form model_name="Trait" model={current} fields={fields} onChange={c => this.onChangeCurrent(c)} onSubmit={e => this.onSubmit(e)} onCancel={onCancelCallback} submitting={submitting} />
        {this.renderOptionsList()}
        {this.renderOptionForm()}
      </div>
    );
  }

  render() {
    return (
      <div id="traits">
        <h2>Traits</h2>
        <hr/>

        {this.renderForm()}
        {this.renderList()}
      </div>
    )
  }
}


function defaultTrait() {
  return {option_type: 'M', options: []}
}

const TUMOR_TYPE_OPTIONS = {
  S: 'Solid',
  L: 'Liquid'
}

const TRAIT_OPTION_TYPE =  {
  S: 'Radio (can only choose one)',
  M: 'Checkbox (can choose multiple)'
}
