import React, {Component} from 'react';
import {getLocationLabel} from './locations';
import {calculateDistance, compareGeolocation} from './geolocation';

export default class GeolocationInformation extends Component {
  constructor(props) {
    super(props);
    if (props.cache) {
      let {cache, unmapped_found} = props.cache;
      this.state = {cache, unmapped_found};
    } else {
      let {cache, unmapped_found} = this.calculateDistances();
      this.state = {cache, unmapped_found};
    }
  }

  calculateDistances() {
    let {patient, trial_locations, extra_locations} = this.props;
    let locations = [];

    if (patient && patient.locations.length > 0) {
      locations = locations.concat(patient.locations);
    }

    if (extra_locations && extra_locations.length > 0) {
      locations = locations.concat(extra_locations);
    }

    if (locations.length === 0) return [];

    let unmapped_found = false;
    let cache = [];

    for (let location of locations) {
      if (location.lon && location.lat) {
        for (let trial_location of trial_locations) {
          let found = false;
          for (let cancer_center of trial_location.cancer_centers) {
            if (cancer_center.lon && cancer_center.lat) {
              found = true;
              let distance = Math.round(calculateDistance(location, cancer_center));
              cache.push({type: 'cancer_center', location, trial_location, cancer_center, distance});
            }
          }

          if (!found) {
            cache.push({type: 'trial_location', trial_location});
            unmapped_found = true;
          }
        }
      }
    }

    cache = cache.sort(compareGeolocation);
    return {cache, unmapped_found};
  }


  renderLocation({trial_location}, index) {
    let label = locationLabel(trial_location, {show_zip: false, show_status: false});
    let link ="/admin/trials/" + trial_location.trial_id + "/locations/" + trial_location.id + "/edit";
    if (!trial_location.cancer_centers || trial_location.cancer_centers.length === 0) {
      return <td colSpan={4}><a href={link}>{label}</a>  has no cancer centers</td>
    }
    return <td colSpan={4}><a href={link}>{label}</a>  has no geolocated cancer centers</td>
  }


  renderCancerCenter({location, trial_location, cancer_center, distance}, index) {
    let label = locationLabel(location, {show_zip: false, show_status: false});
    let location_el;
    if (location.primary) {
      location_el = <span className="main-location" title="Main Location">{label}</span>
    } else if (location.place_id) {
      location_el = <span className="custom-location" title="Custom Location">{label}</span>
    } else {
      location_el = <span className="other-location">{label}</span>
    }

    return (
      <>
        <td>{cancer_center.name}</td>
        <td>{location_el}</td>
        <td>{distance} miles</td>
        <td>{trial_location.status_humanized}</td>
      </>
    );
  }

  renderFirstCancerCenterText({location, trial_location, cancer_center, distance}) {
    let label = locationLabel(location, {show_zip: false, show_status: false});
    let location_el;
    if (location.primary) {
      location_el = <span className="main-location">{label}</span>
    } else if (location.place_id) {
      location_el = <span className="custom-location">{label}</span>
    } else {
      location_el = <span className="other-location">{label}</span>
    }
    return (
      <span><b>Closest Location:</b> {cancer_center.name} <b>({trial_location.status_humanized})</b> is <b>{distance} miles</b> from <b>{location_el}</b></span>
    );
  }

  renderAll() {
    let {cache} = this.state;
    return (
      <div className="result-geo-info">
        <button className="btn btn-default btn-sm" onClick={e => this.setState({show_all: false})}>Close</button>
        <table className="table table-striped table-condensed trial-location-table">
          <thead>
            <tr>
              <th>Trial Location</th>
              <th>Patient Location</th>
              <th>Distance</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {cache.map((cache, index) => {
              let content = cache.type === 'cancer_center' ? this.renderCancerCenter(cache, index) : this.renderLocation(cache, index);
              return <tr key={index}>{content}</tr>
            })}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    let {hide_show_all_btn} = this.props;
    let {cache, show_all, unmapped_found} = this.state;
    if (!cache) return null;
    if (show_all) return this.renderAll();
    let first_cancer_center = cache.find(c => c.type === 'cancer_center');

    let unmapped_el, content_el, action_el;
    if (unmapped_found) {
      unmapped_el = <span className="subtle unmapped_note">Unmapped and/or international locations found.</span>;
    }

    if (first_cancer_center) {
      content_el = <p>{this.renderFirstCancerCenterText(first_cancer_center)}</p>
      if (!hide_show_all_btn) {
        action_el = <button className="btn btn-default btn-sm" onClick={e => this.setState({show_all: true})}>Show all</button>
      }
    } else {
      content_el = <span>No geolocated cancer centers found</span>
    }

    return (
      <div className="result-geo-info">

        {content_el}
        {action_el}
        {unmapped_el}

      </div>
    );
  }
}





function locationLabel(location) {
  return location.display_name || getLocationLabel(location, {show_zip: false, show_status: false});
}
