import React, {Component} from 'react';
import Form from './form';

export default class InlineEditableList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  newItem() {
    return {};
  }

  formFields() {
    return [];
  }


  renderItem(item) {
    return (
      <div key={item.id}>
        <span>{item.name}</span>
        <div className="btn-group">
          <button onClick={e => this.edit(item)} className="btn btn-default btn-sm"><i className="fa fa-edit"></i></button>
          <button onClick={e => this.delete(item)} className="btn btn-default btn-sm"><i className="fa fa-trash"></i></button>
        </div>
      </div>
    );
  }


  submit(e) {
    e.preventDefault();
    let {onChange, list} = this.props;
    let {current_item} = this.state;
    if (current_item.id) {
      let index = list.findIndex(a => a.id === current_item.id);
      list[index] = current_item;
    } else {
      current_item.id = generateUUID();
      list.push(current_item);
    }
    onChange(list);
    this.close();
  }

  change(current_item) {
    this.setState(current_item);
  }

  delete(current_item) {
    if (!confirm('Are you sure?')) return;

    let {onChange, list} = this.props;
    let index = list.findIndex(a => a.id === current_item.id);
    list.splice(index, 1);
    onChange(list);
  }

  renderForm(item) {
    let {name, formContainer} = this.props;
    let fields = this.formFields();
    return (
      <div key={item.id} className="inline_form_form">
        <Form model_name={name} model={item} fields={fields} onChange={i => this.change(i)} onSubmit={e => this.submit(e)} onCancel={e => this.close()} containerElement={formContainer} />
      </div>
    )
  }

  renderTitle() {
    let {name} = this.props;
    if (!name || name.length === 0) return null;
    return <h3 className="inline_form_header">{name}</h3>;
  }


  edit(current_item) {
    this.setState({current_item, is_new: false});
  }

  new() {
    let current_item = this.newItem();
    this.setState({current_item, is_new: true});
  }

  close() {
    this.setState({current_item: undefined, is_new: undefined});
  }

  renderNewButton() {
    let {current_item, is_new} = this.state;
    if (current_item) return null;

    let {name} = this.props;
    return <button className="btn btn-success btn-sm" onClick={e => this.new()}>Add {name}</button>
  }

  renderNewForm() {
    let {current_item, is_new} = this.state;
    if (!is_new) return null;
    return this.renderForm(current_item);
  }

  renderList() {
    let {list} = this.props;
    let {current_item} = this.state;

    return (
      <div>
        {list.map(item => {
          if (current_item && current_item.id === item.id) {
            return this.renderForm(item);
          } else {
            return this.renderItem(item);
          }
        })}
      </div>
    );
  }


  render() {
    return (
      <div className="inline_form_holder">
        {this.renderTitle()}
        {this.renderList()}
        {this.renderNewButton()}
        {this.renderNewForm()}
      </div>
    );
  }
}
