import React, {Component} from 'react';
import Form from './form';
import request from '../request';
import {PatientSearchKey, WorkCategorySearchKey, AdminSearchKey} from '../constants.js.erb';

export default class TaskFactory extends Component {
  constructor(props) {
    super(props);
    let {task_data} = props;
    this.state = {task_data};
    this.onChange = this.onChange.bind(this);
  }

  submit(e) {
    e.preventDefault();
    this.setState({submitting: true});
    let {task_data} = this.state;
    let {template} = this.props;
    let url = `/admin/task_templates/${template.id}/create_task`;
    request('POST', url, {task_data})

      .then(({success}) => {
        this.setState({submitting: false});
        if (success) {
          window.location = '/admin/task_templates';
        }
      })
      .catch(error => {
        this.setState({submitting: false});
      });
  }


  onChange(task_data) {
    this.setState({task_data});
  }

  formFields() {
    return [
      {name: 'name', required: true },
      {name: 'description', type: 'text'},
      {name: 'due_date', type: 'date'},
      {name: 'admin_ids', label: 'Admins', type: 'remote-select2', multiple: true, src: "/admin/admins/as_options.json", search_key: AdminSearchKey},
      {name: 'patient_ids', label: 'Patients', type: 'remote-select2', multiple: true, src: "/admin/patients/as_options.json", search_key: PatientSearchKey, hint: 'Select as many admins or patients as needed, but each admin/patient combination will result in a separate instance of this task. You likely want to assign multiple admins OR multiple patients, but not both as the same time, except in rare circumstances.'},
      {name: 'work_category_id', type: 'remote-select2', src: "/admin/work_categories/as_options.json", search_key: WorkCategorySearchKey}

    ];
  }
  render() {
    let {task_data, submitting} = this.state;
    
    
    
    return (
    
      <div className="task_form_holder">
        <h4>Create Task(s) From this Template</h4>
        <hr/>
        <Form singeton model_name="Task" model={task_data} fields={this.formFields()} onChange={this.onChange} onSubmit={e => this.submit(e)} submitting={submitting} />
      </div>
    
    );
  }
}
