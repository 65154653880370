import React, {Component} from 'react';
import CollectionTable from './collection_table';
import {SearchCancerCenter} from './link_cancer_centers_and_locations';
import request from '../request';


export class LocationCancerCenters extends Component {
  constructor(props) {
    super(props);
    this.state = {linked: []};
    this.linkCancerCenter = this.linkCancerCenter.bind(this);
    this.unlinkCancerCenter = this.unlinkCancerCenter.bind(this);
  }

  componentDidMount() {
    let {trial_id, trial_location_id} = this.props;
    this.fetchCancerCenters(trial_id, trial_location_id);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.trial_location_id !== this.props.trial_location_id) {
      let {trial_id, trial_location_id} = newProps;
      this.fetchCancerCenters(trial_id, trial_location_id);
    }
  }

  updateLinked(linked) {
    let {onCancerCentersChange} = this.props;
    this.setState({linked});
    onCancerCentersChange && onCancerCentersChange(linked);
  }

  fetchCancerCenters(trial_id, trial_location_id) {
    this.setState({loading: true});
    let url = "/admin/trials/" + trial_id + "/locations/" + trial_location_id + "/cancer_centers";
    request("GET", url).then((linked) => {
      this.updateLinked(linked);
      this.setState({loading: false});
    });
  }


  linkCancerCenter(cancer_center) {
    let {trial_id, trial_location_id, onChange} = this.props;
    let url = linkCancerCenterURL(trial_id, trial_location_id, cancer_center.id);
    request('PUT', url).then(({success, cancer_center, trial_location}) => {
      if (success) {
        let {linked} = this.state;
        linked.push(cancer_center);
        this.updateLinked(linked);
        onChange && onChange(trial_location);
      }
    });
  }

  unlinkCancerCenter(cancer_center) {
    let {trial_id, trial_location_id, onChange} = this.props;
    let url = unlinkCancerCenterURL(trial_id, trial_location_id, cancer_center.id);
    request('PUT', url).then(({success, cancer_center, trial_location}) => {
      if (success) {
        let {linked} = this.state;
        linked = linked.filter(tl => tl.id !== cancer_center.id);
        this.updateLinked(linked);
        onChange && onChange(trial_location);
      }
    });
  }

  addLinked(cancer_center) {
    let {linked} = this.state;
    if (linked.find(l => l.id === cancer_center.id)) return;

    linked.push(cancer_center);
    this.updateLinked(linked);
  }

  renderLoading() {
    return <i className="glyphicon glyphicon-refresh glyphicon-spin" style={{fontSize: 80}}/>
  }

  render() {
    let {trial_id, trial_location_id} = this.props;
    let {loading, linked, unconfirmed_suggested} = this.state;

    if (loading) return this.renderLoading();
    return(
      <div id="cancer_center_locations">
        <div className="pull-right inline-content">
          <SuggestedCancerCenters trial_id={trial_id} trial_location_id={trial_location_id} linked={linked} onLink={this.linkCancerCenter}/>
          <SearchCancerCenter trial_id={trial_id} location_id={trial_location_id} onLink={tl => this.addLinked(tl)} linked={linked} />
        </div>
        <LinkedCancerCenterCollection collection={linked} title="Linked Cancer Centers" unlink={this.unlinkCancerCenter}/>
      </div>
    );
  }
}



class CancerCenterCollection extends CollectionTable {
  renderActionHeader() {
    return null;
  }

  renderAction() {
    return null;
  }


  renderTableHeader() {
    return (
      <thead>
        <tr>
          <th>Name</th>
          <th>State</th>
          <th>City</th>
          <th>ZIP Code</th>
          {this.renderActionHeader()}
        </tr>
      </thead>

    );
  }

  renderRow(record) {
    let url = cancerCenterURL(record.id)
    return (
      <tr key={record.id}>
        <td><a href={url} target="_blank">{record.name}</a></td>
        <td>{record.state_name}</td>
        <td>{record.city}</td>
        <td>{record.zip}</td>
        {this.renderAction(record)}
      </tr>
    );
  }

}

class LinkedCancerCenterCollection extends CancerCenterCollection {
  renderActionHeader() {
    return <th></th>;
  }

  renderAction(record) {
    let {unlink} = this.props;
    return (
      <td>
        <button className="btn btn-sm btn-warning" onClick={e => unlink(record)}>Unlink</button>
      </td>
    );
  }

}

class SuggestedCancerCenterCollection extends CancerCenterCollection {
  renderActionHeader() {
    return <th></th>;
  }

  renderAction(record) {
    let {link} = this.props;
    return (
      <td>
     <button className="btn btn-sm btn-success" onClick={e => link(record)}>Link</button>
      </td>
    );
  }
}


class SuggestedCancerCenters extends Component {
  constructor(props) {
    super(props);
    this.state = {suggested: [], loading: false, show_modal: false};
  }

  componentDidMount() {
    this.modalEl = $(this.refs.modal_container);
    this.modalEl.modal({show: false});
    this.modalEl.on('hide.bs.modal', () => {
      this.setState({show_modal: false})
    });
  }

  onLink(trial_location) {
    let {onLink} = this.props;
    let {suggested} = this.state;
    suggested = suggested.filter(l => l.id !== trial_location.id);
    this.setState({suggested});
    onLink(trial_location);
  }

  fetchSuggestions() {
    let {trial_id, trial_location_id, linked} = this.props;
    this.setState({loading: true});
    let url = "/admin/trials/" + trial_id + "/locations/" + trial_location_id + "/suggested_cancer_centers";
    request("GET", url).then((suggested) => {
      suggested = suggested.filter(s => !linked.some(l =>  l.id === s.id));
      this.setState({suggested, loading: false});
    });
  }

  showModal() {
    this.modalEl.modal('show');
    this.fetchSuggestions();
  }


  renderContent() {
    let {isUmbrella} = this.props;
    let {suggested, loading} = this.state;

    if (loading) return this.renderLoading();
    return (
      <div id="suggested_cancer_Centers">
        <SuggestedCancerCenterCollection collection={suggested} link={tl => this.onLink(tl)} title="Suggested Cancer Centers"  />
      </div>
    );
  }

  renderLoading() {
    return <i className="glyphicon glyphicon-refresh glyphicon-spin" style={{fontSize: 80}}/>
  }


  render() {
    return (
      <div>
        <button className="btn btn-default" onClick={e => this.showModal()}>Show Suggestions</button>

        <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
          <div className="modal-dialog" role="document" style={{width: '90%'}}>
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title">Suggestions</h4>
              </div>
              <div className="modal-body">
                {this.renderContent()}
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function cancerCenterURL(cancer_center_id) {
  return gon.links.cancer_center.replace('CANCER_CENTER_ID', cancer_center_id);
}

function linkCancerCenterURL(trial_id, trial_location_id, cancer_center_id) {
  return gon.links.link_cancer_center.replace('TRIAL_ID', trial_id).replace('TRIAL_LOCATION_ID', trial_location_id).replace('CANCER_CENTER_ID', cancer_center_id)
}

function unlinkCancerCenterURL(trial_id, trial_location_id, cancer_center_id) {
  return gon.links.unlink_cancer_center.replace('TRIAL_ID', trial_id).replace('TRIAL_LOCATION_ID', trial_location_id).replace('CANCER_CENTER_ID', cancer_center_id)
}
