import React, {Component} from 'react';
import {PopulationTypes} from '../constants.js.erb';

import request from '../request';

export default class TrialPopulationsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    $(this.refs.modal).modal({show: false});
    $(this.refs.modal).on('hidden.bs.modal', () => {
      this.setState({selected: null});
    });
  }


  archivePopulation() {
    let {trial, onChange} = this.props;
    let {reason, population_id} = this.state.selected;

    let url = "/admin/trials/" + trial.id + "/populations/" + population_id + "/archive";
    let archive = {reason: reason}
    request("PUT", url, {archive}).then(population => {
      let index = trial.populations.findIndex(p => p.id === population_id)
      trial.populations[index] = population;
      onChange(trial);
      $(this.refs.modal).modal('hide');
    });
  }

  unarchivePopulation(population) {
    if (!confirm("Are you sure?")) return;

    let {trial, onChange} = this.props;

    let url = "/admin/trials/" + trial.id + "/populations/" + population.id + "/unarchive";
    request("PUT", url).then(population => {
      let index = trial.populations.findIndex(p => p.id === population.id)
      trial.populations[index] = population;
      onChange(trial);
      $(this.refs.modal).modal('hide');
    });
  }

  deletePopulation(population) {
    if (!confirm("Are you sure?")) return;
    let {trial, onChange} = this.props;
    let url = "/admin/trials/" + trial.id + "/populations/" + population.id;
    request("DELETE", url).then(() => {
      let index = trial.populations.findIndex(p => p.id === population.id)
      trial.populations.splice(index, 1);
      onChange(trial);
    });
  }

  duplicatePopulation(population) {
    if (!confirm("Are you sure?")) return;
    let {trial} = this.props;
    let url = "/admin/trials/" + trial.id + "/populations/" + population.id + '/duplicate';
    request("POST", url).then(({redirect_to}) => {
      window.location = redirect_to;
    });
  }



  showArchiveModal(population) {
    this.setState({selected: {population_id: population.id, reason: ""}})
    $(this.refs.modal).modal('show');
  }


  changeForm(e) {
    let {name, value} = e.target;
    let {selected} = this.state;
    selected[name] = value;
    this.setState({selected});
  }

  renderPopulation(population) {
    let {trial} = this.props;
    let link = "/admin/trials/" + trial.id + "/populations/" + population.id;
    let compare_link = "/admin/trials/" + this.props.trial.id + "/populations/compare?left_population_id=" + population.id;

    let archive_action, archive_details, reference_el, population_type_el;
    if (population.archived) {
      archive_action = <button onClick={e => this.unarchivePopulation(population)} className="archive-population btn btn-default">Unarchive</button>
      archive_details = <p className="archive-details"><b>{population.archived_details} for the following reason:</b><br/> <i>{population.archived_reason}</i></p>
    } else {
      archive_action = <button onClick={e => this.showArchiveModal(population)} className="archive-population btn btn-default">Archive</button>
    }

    if (population.reference_id) {
      let reference_url = `/admin/references/${population.reference_id}`;
      reference_el = <a href={reference_url} target="_blank" className="btn btn-success btn-sm">Reference</a>
    }

    if (trial.isTherapy && population.population_type) {
      let label = PopulationTypes[population.population_type];
      population_type_el = <button className="btn btn-success btn-sm">{label}</button>;
    }


    return (
      <div key={population.id} className="populations-list-item" id={"population_" + population.id}>
        <div className="btn-group pull-right">
          <a href={link} target="_self" className="btn btn-default">Edit</a>
          <a href={compare_link} target="_self" className="btn btn-default">Compare</a>
          <button onClick={e => this.deletePopulation(population)} className="btn btn-default delete-population">Delete</button>
          <button onClick={e => this.duplicatePopulation(population)} className="btn btn-default duplicate-population">Duplicate</button>
          {archive_action}
        </div>
        <div className="population_listing">
          {population_type_el} {reference_el}
          <a href={link} target="_self">{population.name}</a>
        </div>
        <div className="clearfix"></div>
        {archive_details}
      </div>
    )
  }

  renderModal() {
    let {selected} = this.state;
    if (!selected) return null;

    return (
      <div className="modal-dialog text_entry_modal" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 className="modal-title">Archive Population</h4>
          </div>
          <div className="modal-body">
            <form onSubmit={e => this.archivePopulation()}>

                <label>Reason for Archiving:</label>
                <textarea className="form-control" name="reason" value={selected.reason} onChange={e => this.changeForm(e)}></textarea>

            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary" onClick={e => this.archivePopulation()}>Save changes</button>
          </div>
        </div>
      </div>
    );
  }

  renderActivePopulations(populations) {
    return (
      <div id="active-populations" className="populations-list">
        {populations.map(p => this.renderPopulation(p))}
      </div>
    )
  }


  renderArchivedPopulations(populations) {
    if (populations.length === 0) return null;
    let {show_archived_populations} = this.state;
    let collapse_btn_label = show_archived_populations ? 'Hide Archived Populations' : 'See Archived Populations';
    let content;

    if (show_archived_populations) {
      content = <div className="populations-list">{populations.map(p => this.renderPopulation(p))}</div>
    }

    return (
      <div id="archived-populations">
          <button onClick={e => this.setState({show_archived_populations: !show_archived_populations})} className="btn btn-link margin-bottom">
            {collapse_btn_label}
          </button>
        {content}
      </div>
    )
  }

  render() {
    let {trial} = this.props;
    let active_populations = trial.populations.filter(p => !p.archived);
    active_populations.sort(orderPopulationsReferenceLast);

    let archived_populations = trial.populations.filter(p => p.archived).sort(orderPopulationsReferenceLast);
    archived_populations.sort(orderPopulationsReferenceLast);

    return (
      <div>
        {this.renderActivePopulations(active_populations)}
        {this.renderArchivedPopulations(archived_populations)}

        <div className="modal fade" tabIndex="-1" role="dialog" ref="modal">
          {this.renderModal()}
        </div>
      </div>

    );
  }
}


function orderPopulationsReferenceLast(a, b) {
  if (a.reference_id === b.reference_id) return 0;
  else if (a.reference_id === null) return -1;
  else return 1;
}
