import React, {Component} from 'react';
import Form from './form';
import request from '../request';

export default class EventForm extends Component {
 constructor(props) {
   super(props);
   let {event} = props;
   this.state = {event};
   this.onChange = this.onChange.bind(this);
  }

  submit() {
    this.setState({submitting: true});
    let {patient_id, onSubmit, return_path} = this.props;
    let {event} = this.state;
    let url = `/admin/patients/${patient_id}/events`;
    let method;
    if (event.id) {
      url += '/' + event.id;
      method = 'PUT';
    } else {
      method = 'POST';
    }

    request(method, url, {event})
      .then(event => {
        if (onSubmit) {
          onSubmit(event);
        } else if (return_path) {
          window.location = return_path;
        }
      })
      .catch(errors => {
        this.setState({errors, submitting: false});
      });
  }

  onChange(event) {
    this.setState({event});
  }

  fields() {
    return [
      {name: 'date', type: 'date', required: true},
      {name: 'title', required: true},
      {name: 'description', type: 'text'}
    ];
  }

  render() {
    let {event, submitting, errors} = this.state;
    let {onCancel} = this.props;
    return <Form model_name="Event" fields={this.fields()} model={event} errors={errors} onChange={this.onChange} onSubmit={e => this.submit(e)} onCancel={onCancel} submitting={submitting} />
  }
}
