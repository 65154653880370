import React, {Component} from 'react';

const NEW_NOTIFICATION_EVENT_NAME = 'new-notification';
const NOTIFICATION_TIMEOUT = 4000;

export default class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {notifications: []};
    this._pushNotification = this._pushNotification.bind(this);

  }

  componentDidMount() {
    window.addEventListener(NEW_NOTIFICATION_EVENT_NAME, this._pushNotification);
  }


  componentWillUnmount() {
    window.removeEventListener(NEW_NOTIFICATION_EVENT_NAME, this._pushNotification);
  }

  static push(message) {
    window.dispatchEvent(new CustomEvent(NEW_NOTIFICATION_EVENT_NAME, { detail: {message}}))
  }

  _pushNotification(e) {
    let {message} = e.detail;
    let {notifications} = this.state;
    let uuid = generateUUID();
    notifications.push({uuid, message});
    this.setState({notifications});
    setTimeout(() => this._popNotification(uuid), NOTIFICATION_TIMEOUT);
  }

  _popNotification(uuid) {
    let {notifications} = this.state;
    notifications = notifications.filter(n => n.uuid !== uuid);
    this.setState({notifications});
  }

  render() {
    let {notifications} = this.state;
    return (
      <div id="floating-notifications-container">
      {notifications.map(notification => {
        return (
          <div key={notification.uuid} className="notification">
            <p>{notification.message}</p>
          </div>
        )
      })}
      </div>
    )
  }
}
