import React, {Component} from 'react';
import Form from './form';
import request from '../request';

export default class TraitsEditor extends Component {
  constructor(props) {
    super(props);
    let {traits} = props;
    this.state = {trait: newTrait()};
  }

  editTrait(trait) {
    this.setState({trait});
  }

  deleteTrait(trait) {
    if (!confirm('Are you sure?')) return;
    let {traits, onChange} = this.props;
    let index = traits.findIndex(t => t.id === trait.id);
    traits.splice(index, 1);
    onChange(traits);
  }

  moveTraitUp(trait) {
    let {traits, onChange} = this.props;
    let index = traits.findIndex(t => t.id === trait.id);
    traits.splice(index, 1);
    traits.splice(index - 1, 0, trait);
    onChange(traits);
  }

  moveTraitDown(trait) {
    let {traits, onChange} = this.props;
    let index = traits.findIndex(t => t.id === trait.id);
    traits.splice(index, 1);
    traits.splice(index + 1, 0, trait);
    onChange(traits);
  }

  traitSubmitted(trait) {
    let {traits, onChange} = this.props;
    if (trait.id) {
      let index = traits.findIndex(t => t.id === trait.id);
      traits[index] = trait;
    } else {
      trait.id = generateUUID();
      trait.position = traits.length;
      traits.push(trait);
    }
    onChange(traits);
    this.setState({trait: newTrait()});
  }

  cancelEditTrait() {
    let {traits} = this.props;
    this.setState({trait: newTrait()});
  }

  renderTrait(trait) {
    let applies_to_all;

    let options = trait.options.map(option => <span className="badge badge-mr" key={option.id}>{option.text}</span>);
    return(
      <div className="panel panel-default" key={trait.id}>
        <div className="panel-body">
          <h4>{trait.name}</h4>
          <p>{trait.search_side_label}</p>
          <p><small>{TRAIT_TYPES[trait.type]}</small></p>
          <p><small>{trait.option_type_humanized}</small></p>
          {options}
          <hr/>
          <div className="btn-group">
            <button className="btn btn-primary btn-sm" title="Edit" onClick={e => this.editTrait(trait)}><span className="fa fa-pencil-alt"></span></button>
            <button className="btn btn-danger btn-sm" title="Delete" onClick={e => this.deleteTrait(trait)}><span className="fa fa-trash"></span></button>
            <button className="btn btn-default btn-sm" title="Move Up" onClick={e => this.moveTraitUp(trait)}><span className="fa fa-arrow-up"></span></button>
            <button className="btn btn-default btn-sm" title="Move Down" onClick={e => this.moveTraitDown(trait)}><span className="fa fa-arrow-down"></span></button>
          </div>
        </div>
      </div>

    );
  }
  renderList() {
    let {traits} = this.props;

    return (
      <div>
        {traits.map(trait => this.renderTrait(trait))}
      </div>

    );
  }

  renderForm() {
    let {trait} = this.state;
    return <TraitForm trait={trait} onSubmit={t => this.traitSubmitted(t)} onCancel={e => this.cancelEditTrait(e)} />
  }

  render() {
    return (
      <div>
        {this.renderList()}
        {this.renderForm()}
      </div>
    );
  }
}


class TraitForm extends Component {
  constructor(props) {
    super(props);
    let {trait} = props;
    this.state = {trait, errors: [], option: newOption(trait.options)};
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    state.trait = props.trait;
    return state;
  }

  changeOptions(options) {
    let {trait} = this.state;
    trait.options = options;
    this.setState({trait});
  }

  onChange(trait) {
    this.setState({trait});
  }

  onSubmit(e) {
    e.preventDefault();
    let {trait} = this.state;
    let {onSubmit} = this.props;
    onSubmit(trait);
  }

  formFields() {
    let {trait} = this.state;
    return [
      {name: 'name', required: true},
      {name: 'type', type: 'radio', collection: TRAIT_TYPES, required: true},
      {name: 'description', type: 'text'},
      {name: 'options', type: 'component', component: OptionsEditor, props: {options: trait.options, onChange: o => this.changeOptions(o)}, visible: e => e.type !== 'text'}
    ];
  }

  render() {
    let {trait, errors, submitting} = this.state;
    let action = trait.id ? 'Edit' : 'New';
    let onCancel = trait.id ? this.props.onCancel : false;
    return (
      <div className="well">
        <p><b>{action} Trait</b></p>
        <Form model_name="Trait" fields={this.formFields()} model={trait} submitting={submitting} errors={errors} onChange={this.onChange} onSubmit={this.onSubmit} onCancel={onCancel} />
      </div>
    )
  }
}



class OptionsEditor extends Component {
  constructor(props) {
    super(props);
    let {options} = props;
    this.state = {option: newOption(options)}
  }

  submitOption() {
    let {option} = this.state;
    if (option.text.length === 0) return;

    let {options} = this.props;
    if (option.id) {
      let index = options.findIndex(o => o.id === option.id);
      options[index] = option;
    } else {
      option.id = generateUUID();
      options.push(option);
    }

    this.setState({option: newOption(options)});
    this.props.onChange(options);
  }

  cancelOption() {
    let {options} = this.props;
    this.setState({option: newOption(options)});
  }
  changeOptionName(e) {
    let {option} = this.state;
    option.text = e.target.value;
    this.setState({option});
  }

  editOption(o) {
    let option = Object.assign({}, o);
    this.setState({option});
  }

  deleteOption(option) {
    if (!confirm('Are you sure?')) return;
    let {options} = this.props;
    let index = options.findIndex(t => t.id === option.id);
    options.splice(index, 1);
    this.props.onChange(options);
  }

  moveOptionUp(option) {
    let {options} = this.props;
    let index = options.findIndex(t => t.id === option.id);
    options.splice(index, 1);
    options.splice(index - 1, 0, option);
    this.props.onChange(options);
  }

  moveOptionDown(option) {
    let {options} = this.props;
    let index = options.findIndex(t => t.id === option.id);
    options.splice(index, 1);
    options.splice(index + 1, 0, option);
    this.props.onChange(options);
  }

  renderForm() {
    let {option} = this.state;
    let action = option.id ? 'Edit' : 'New';
    let cancel_el;
    if (option.id) {
      cancel_el = <button className="btn btn-default btn-sm" onClick={e => this.cancelOption()}>Cancel</button>
    }
    return (
      <div>
        <div className="well inner_well">
          <div className="form-group">
            <label>{action} Trait Option</label>
            <input type="text" className="form-control" value={option.text} onChange={e => this.changeOptionName(e)} />
          </div>
          <button className="btn btn-primary btn-sm" onClick={e => this.submitOption()}>Submit Option</button>
          {cancel_el}
        </div>
      </div>
    );
  }

  renderList() {
    let {options} = this.props;
    if (options.length === 0) return null;

    return (
      <div>
        <p><b>Options</b></p>
        {options.map(option => {
          return (
            <div key={option.id} className="trait-option">
              <p className="pull-left">{option.text}</p>

              <div className="pull-right btn-group">
                <button className="btn btn-primary btn-sm" title="Edit" onClick={e => this.editOption(option)}><span className="fa fa-pencil-alt"></span></button>
                <button className="btn btn-danger btn-sm" title="Delete" onClick={e => this.deleteOption(option)}><span className="fa fa-trash"></span></button>
                <button className="btn btn-default btn-sm" title="Move Up" onClick={e => this.moveOptionUp(option)}><span className="fa fa-arrow-up"></span></button>
                <button className="btn btn-default btn-sm" title="Move Down" onClick={e => this.moveOptionDown(option)}><span className="fa fa-arrow-down"></span></button>
              </div>
              <div className="clearfix"></div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderList()}
        {this.renderForm()}
        <hr />
      </div>
    )
  }
}


function newTrait() {
  return {options: []}
}

function newOption(options) {
  let position = options.length;
  return {position, text: ''}
}

const TRAIT_TYPES = {
  text: 'Open Text Area',
  single: 'Radio Button (Select one option)',
  multiple: 'Checkbox (Select all that apply)'
}
