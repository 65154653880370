import React, {Component} from 'react';
import DatePicker from 'react-datepicker';

export default class DatePickerWrapper extends Component {

  onChange(datetime) {
    let value;
    if (datetime) {
      value = moment(datetime).format('YYYY/M/D');
    } else {
      value = null;
    }
    let {name, onChange} = this.props;
    let target = {name, value}
    onChange({target})
  }

  render() {
    let {value, id} = this.props;
    if (typeof(value) === 'string' && value.length > 0) {
      let datetime = value + " 00:00:00";
      // Fix issue with safari
      datetime = datetime.replaceAll('-', '/');
      value = new Date(datetime);
    }

    return <DatePicker id={id} selected={value} className="form-control" onChange={d => this.onChange(d)} autoComplete="off"/>
  }
}
