import React, {Component} from 'react';



export default class SearchesReport extends Component {
  constructor(props) {
    super(props);
    let results = (window.props && window.props.results) ? window.props.results : props.results;
    this.state = {results}
  }

  componentDidMount() {
    window.PagedPolyfill.preview();
  }


  renderClosestLocation(trial) {
    if (trial.isTherapy) {
      return <div><i>This is an approved therapy your oncologist should be able to provide.</i></div>
    }

    if (!trial._geolocation_cache || !trial._geolocation_cache.cache || trial._geolocation_cache.cache.length == 0) {
      return null;
    }
    let cache = trial._geolocation_cache.cache[0];
    let closest_location = {distance: cache. distance, location_name: cache.cancer_center.name};


    return (
      <div className="qk_report_result_detail">
        <strong>Closest Location</strong><br />
        {closest_location.location_name}
      </div>
    )
  }


  renderTrialLink(trial) {
    if (trial.isTherapy) return null;

    return (
      <div className="qk_report_result_detail">
        Trial ID and Link:<br/>
        <a href={trial.study_url}>{trial.identifier}</a>
      </div>
    )
  }

  renderResult(index, result) {
    let {trial} = result;
    let population = result.population_data[0];
    let closest_location;
    return (
      <div key={index} className="qk_report_result_holder">
        <div className="qk_report_index_number">{index + 1}</div>
        <div>
          <h2 className="qk_report_result_name">{trial.name}</h2>
          <p className="qk_report_result_population">{population.name}</p>
          <div className="qk_report_result_details">
            {this.renderTrialLink(trial)}
            {this.renderClosestLocation(trial)}
          </div>
        </div>
      </div>
    )
  }

  renderResults() {
    let {results} = this.state;
    let content = []
    for (let i=0; i < results.length; i++) {
      content.push(this.renderResult(i, results[i]));
    }
    return content;
  }

  render() {
    return (
      <div>
      {this.renderResults()}
      </div>
    );

  }
}
