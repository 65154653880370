import React, {Component} from 'react';

import request from '../../request';
import Loading from '../loading';

export default class PatientCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchCollection();
  }

  fetchCollection() {
    let {patient_id, collection_id} = this.props;
    let url = `/admin/patients/${patient_id}/collections/${collection_id}`;
    request('GET', url).then(collection => {
      this.setState({collection});
    }).catch(e => {
      // Close the view if we can't fetch the collection
      this.props.onClose();
    });
  }


  renderTrials() {
    let {collection} = this.state;
    let {trial_id, onShowPatientTrialData} = this.props;

    return (
      <div>
        {collection.trials.map(trial => {
          let link = `/admin/trials/${trial.id}`
          let current, button;
          if (trial_id === trial.id) {
            button = <button onClick={onShowPatientTrialData} className="btn btn-sm btn-success">Collection Details</button>
            current  =  <span className="badge"><i className="fa fa-chevron-left" /> CURRENT TRIAL</span>
          }

          return (
            <div key={trial.id} className="widget_collection_trial">
              {current} {button}
              <h4><strong><a href={link}>{trial.identifier}</a></strong></h4>
              <p>{trial.name}</p>
            </div>
          )
        })}
      </div>
    );
  }

  render() {
    let {patient_id, patient_name, onClose} = this.props;
    let {collection} = this.state;
    if (!collection) return <Loading />

    let link_to_collection = `/admin/patients/${patient_id}/collections/${collection.id}`;
    return (
      <div className="inner_widget_container">
        <button className="btn btn-sm btn-default" onClick={onClose}><i className="fa fa-chevron-left" /> Back to Patient List</button>
        <div className="widget_collection_header">
          <h4 className="patient_widget_header_text">{collection.name}</h4>
          <h5 className="subtle">{patient_name}</h5>
          <a className="btn btn-default btn-xs" href={link_to_collection}>Go to Collection</a>
        </div>

        {this.renderTrials()}
      </div>
    );
  }
}
