import React, {Component} from 'react';
import request from '../request';
import {GroupItemTypes, GroupItemBaseURL} from '../constants.js.erb';

export default class NeestedRecordPicker extends Component {
  constructor(props) {
    super(props);

    let {sources, selected_sources} = this.props;
    if (!sources || sources.length === 0) {
      sources = Object.keys(GroupItemTypes);
    }

    if (!selected_sources || selected_sources.length === 0) {
      selected_sources = [...sources]; // duplicate the array
    }

    this.state = {sources, selected_sources, text:""}
    this.onSelect = this.onSelect.bind(this);
    this.search = this.search.bind(this);
    this.toggleSource = this.toggleSource.bind(this);
    this.debounce_timeout = 1000;
  }

  onInputChange(e) {
    e.preventDefault();
    let text = e.target.value;
    this.setState({text});

    if (this.debouce_timeout_id) clearTimeout(this.debouce_timeout_id);
    if (text.length < 2) return;
    this.debouce_timeout_id = setTimeout(this.search, this.debounce_timeout);
  }


  toggleSource(e) {
    let {name, checked} = e.target;
    let {selected_sources} = this.state;
    let index = selected_sources.indexOf(name);
    if (index !== -1) {
      selected_sources.splice(index, 1);
    } else {
      selected_sources.push(name);
    }

    this.setState({selected_sources}, this.search);
  }

  closeSearch(reset) {
    let changes = {results: null, searching: false};

    if (reset) {
      changes.create_filter_record_mode = false;
      changes.text = '';
    }

    this.setState(changes);
  }

  toggleCreateFilterMode() {
    let create_filter_record_mode = !this.state.create_filter_record_mode;
    this.setState({create_filter_record_mode});
  }

  search() {
    let {destination} = this.props;
    let {selected_sources, text} = this.state;

    this.setState({searching: true});
    request("POST", "/api/admin/trials/search_neested", {text, destination, sources: selected_sources})
      .then(results => {
        this.setState({results, searching: false})
      });
  }

  onSelect(record) {
    let {callback} = this.props;
    this.setState({results: undefined, text: ""})

    if (record.type !== 'interventions') {
      callback && callback(record);
      return;
    }

    request("POST", "/api/admin/trials/item_details", record)
      .then(item => {
        callback && callback(item);
      });

  }


  renderNoResults(style) {
    return (
      <div className="no-results-holder">
        <div id="item-results" style={style}>
          {this.renderSourcesFilter()}
          <div id="no-results" className="text-center">
            <h4>No results</h4>
          </div>
        </div>
        <div id="item-results-background" onClick={e => this.closeSearch()}></div>
      </div>
    )
  }


  renderNoSources(style) {
    return (
      <div>
        <div id="item-results" style={style}>
          {this.renderSourcesFilter()}
          <div id="no-sources" className="text-center">
            <h4>No sources selected</h4>
          </div>
        </div>
        <div id="item-results-background" onClick={e => this.closeSearch()}></div>
      </div>
    )
  }


  renderSourcesFilterCreate() {
    let {sources, selected_sources} = this.state;
    return (
      <div id="item-results-filter-box">
        <div id="item-results-filter-box-header">
          <strong>Sources</strong>
          <button className="btn btn-sm btn-default" onClick={e => this.toggleCreateFilterMode()}>Cancel</button>
        </div>

        {sources.map(source => {
          let checked = selected_sources.indexOf(source) !== -1;
          let label = GroupItemTypes[source];
          let add_link = GroupItemBaseURL[source] + '/new';
          return(
            <label key={source}>
              <a href={add_link} target="_blank" className="btn btn-sm btn-default" onClick={e => this.closeSearch(true)}>{label}</a>
            </label>
          )
        })}
      </div>
    );
  }



  renderSourcesFilterSearch() {
    let {sources, selected_sources} = this.state;
    let {createLinks} = this.props;

    let add_btn;
    if (createLinks) {
      add_btn = <button className="btn btn-sm btn-link" onClick={e => this.toggleCreateFilterMode()}><span className="fa fa-plus"></span> Create Record</button>;
    }

    return (
      <div id="item-results-filter-box">
        <div id="item-results-filter-box-header">
          <strong>Sources</strong>
          {add_btn}
        </div>

        {sources.map(source => {
          let checked = selected_sources.indexOf(source) !== -1;
          let label = GroupItemTypes[source];
          return(
            <label key={source}>
              <input type="checkbox" name={source} checked={checked} value={checked} onChange={this.toggleSource}/> {label}
            </label>
          )
        })}

      </div>
    );
  }



  renderSourcesFilter() {
    let {create_filter_record_mode} = this.state;
    if (create_filter_record_mode) {
      return this.renderSourcesFilterCreate();
    } else {
      return this.renderSourcesFilterSearch()
    }
  }


  renderResult(record) {
    let {items, candidatesDecorator} = this.props;
    let {id, name, key} = record;
    let type = GroupItemTypes[record.type];
    let className = "item-result"
    let onClick;
    if (items.find(r => r.id === record.id && r.type === record.type)) {
      className += " item-added";
    } else {
      className += " clickable";
      onClick = e => this.onSelect(record);
    }

    if (candidatesDecorator) {
      let styles = candidatesDecorator(record, items);
      if (styles) {
        if (styles.class) className += " " + styles.class;
      }
    }

    return (
      <div key={key} className={className} onClick={onClick}>
        <strong>{type}</strong>
        <p>{name}</p>
      </div>
    )
  }

  renderResults() {
    let {results, selected_sources} = this.state;
    if (!results) return null;
    let style;

    if (this.refs.input) {
      let width = this.refs.input.offsetWidth;
      style = {width};
    }

    if (selected_sources.length === 0) {
      return this.renderNoSources(style);
    }

    if (results.length == 0) {
      return this.renderNoResults(style);
    }

    return (
      <div className="neested-records-results">
        {this.renderSourcesFilter()}
        <div id="item-results" style={style}>
          {results.map(r => this.renderResult(r))}
        </div>
        <div id="item-results-background" onClick={e => this.closeSearch(e)}></div>
      </div>
    )
  }


  renderIndicator() {
    let {results, searching } = this.state;

    if (searching) {
      return <i className="glyphicon glyphicon-refresh glyphicon-spin form-control-feedback" aria-hidden="true" />;
    }
    return null;
  }


  renderLabel() {
    let {inputLabel} = this.props;
    if (!inputLabel || inputLabel.length === 0) return null;
    return <label className="control-label">{inputLabel}</label>
  }

  render() {
    let {text} = this.state;

    return (
      <div className="item-picker">
        <div className="form-group has-feedback">
          {this.renderLabel()}
          <input type="text" className="item-picker-input form-control" placeholder="Start typing to search" value={text} onChange={e => this.onInputChange(e)} ref="input" />
          {this.renderIndicator()}
        </div>
        {this.renderResults()}
      </div>
    )
  }
}
