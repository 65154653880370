import React, {Component} from 'react';
import Form from './form';
import request from '../request';
import {Countries, States, CancerCenterSearchKey, CancerCenterTagSearchKey, CancerCenterRegions} from '../constants.js.erb';

export default class CancerCenterForm extends Component {
  constructor(props) {
    super(props);
    let {cancer_center} = props;
    this.state = {cancer_center, errors: {}};
  }

  submit(e) {
    e.preventDefault();
    this.setState({submitting: true});
    let {cancer_center} = this.state;

    let url = "/admin/cancer_centers";
    let method;


    if (cancer_center.id) {
      url += "/" + cancer_center.id;
      method = "PUT"
    } else {
      method = "POST"
    }

    let model = {lat: cancer_center.lat, lon: cancer_center.lon};
    for (let field of this.formFields()) {
      model[field.name] = cancer_center[field.name];
    }

    request(method, url, {cancer_center: model}).then(cancer_center => {
      window.location = "/admin/cancer_centers/" + cancer_center.id;
      this.setState({errors: [], submitting: false});
    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors, submitting: false});
    });
  }

  formFields() {
    let fields = [
      {name: 'name', required: true},
      {name: 'alternate_name', hint: 'Other names for this cancer center (for display only)'},
      {name: 'aliases', type: 'text', hint: 'One per line. Used for matching sponsors.'},
      {name: 'address'},
      {name: 'city'},
      {name: 'region', type: 'select', collection: CancerCenterRegions},
      {name :'country',type: 'select', collection: Countries},
      {name: 'state',type: 'select', collection: States, visible: c => c.country === 'US'},
      {name: 'zip_codes'},
      {name: 'geolocation', type: 'geolocation', paramsMapping: {zip: 'zip_codes'}, visible: c => !c.is_umbrella},
      {name: 'phone'},
      {name: 'website'},
      {name: 'tag_ids', label: 'Tags', type: 'remote-select2', multiple: true, src: "/admin/cancer_center_tags/as_options.json", search_key: CancerCenterTagSearchKey},
      {name: 'clinical_trial_phone'},
      {name: 'clinical_trial_email'},
      {name: 'clinical_trial_url'},

      {name: 'notes', type: 'text'},
      {name: 'keywords', hint: 'Separate with commas. Used for matching trial locations.'},
      {name: 'is_umbrella', type: 'bool'},

      {name: 'parent_cancer_center_id', type: 'remote-select2', src: "/admin/cancer_centers/as_options.json", search_key: CancerCenterSearchKey, filters: {umbrella_only: true}, visible: c => !c.is_umbrella},

    ]
    if (!this.props.anonymous_mode) {
      fields.splice(0, 1, {name: 'name', required: true})
    }
    return fields;
  }

  onChange(cancer_center) {
    this.setState({cancer_center});
  }

  render() {
    let {cancer_center, errors, submitting} = this.state;
    return <Form model_name="Cancer Center" prefix="cancer_center" fields={this.formFields()} model={cancer_center} errors={errors} submitting={submitting} onChange={r => this.onChange(r)} onSubmit={e => this.submit(e)} />
  }

}
