import React, {Component} from 'react';
import request from '../request';
import Loading from './loading';

const FIND_MATCHES_DELAY = 500;

export default class TextSearchRefactored extends Component {
  constructor(props) {
    super(props)
    let {marks, needs_update} = props;
    this.state = {marks, needs_update, matches_count: 0, search_text: '', hide_non_matches: false, full_word_match: false, hide_filter_non_matches: false, active_filters_list: []}

    this.changeSearchText = this.changeSearchText.bind(this);
    this.changeCheckbox = this.changeCheckbox.bind(this);
    this.findMatches = this.findMatches.bind(this);

    this.onChangeHideNonMatches = this.onChangeHideNonMatches.bind(this);
    this.onToggleActiveFiltersList = this.onToggleActiveFiltersList.bind(this);
  }


  submitChanges(marks) {
    let {trial_id, population_id} = this.props;
    let url = `/admin/trials/${trial_id}/populations/${population_id}`;
    request('PUT', url, {population: {eligibility_criteria_marks: marks.join(',')}});
  }

  resetMarks() {
    let {trial_id, population_id} = this.props;
    let url = `/admin/trials/${trial_id}/populations/${population_id}/reset_eligilibity_criteria_marks`;
    request('PUT', url).then(r => {
      this.setState({marks: [], needs_update: false});
    });
  }

  resetMarksManually() {
    if (!confirm('Are you sure?')) return;
    this.resetMarks();
  }

  toggleTextMark(idx) {
    let {population_id} = this.props;
    if (!population_id) return;

    let {marks} = this.state;
    let index = marks.indexOf(idx);
    if (index !== -1) {
      marks.splice(index, 1);
    } else {
      marks.push(idx);
    }
    this.setState({marks});
    this.submitChanges(marks);
  }

  changeSearchText(e) {
    let {value} = e.target;
    this.setState({search_text: value});
    this.triggerSearchStringChanged();
  }

  changeCheckbox(e) {
    let {name, checked} = e.target;
    let callback;

    // We have to find matches after change full_word_match
    if (name === 'full_word_match') {
      callback = () => this.triggerSearchStringChanged(true);
    }

    this.setState({[name]: checked}, callback);


  }

  triggerSearchStringChanged(force_now=false) {
    if (this.find_matches_timeout) {
      clearTimeout(this.find_matches_timeout);
      this.find_matches_timeout = false;
    }

    if (force_now) {
      this.findMatches();
    } else  {
      this.find_matches_timeout = setTimeout(this.findMatches, FIND_MATCHES_DELAY);
    }
  }

  findMatches() {
    this.setState({searching_matches: true});
    let matches_count = 0;
    let {data} = this.props;
    let {search_text, full_word_match} = this.state;
    let length = search_text.length;
    let re;
    if (search_text && search_text.length > 0) {
      let text = full_word_match ? `\\b${search_text}\\b` : search_text;
      re = new RegExp(text, 'gi');
      for (let chunk of data) {
        chunk.matches = [];

        let match
        while((match = re.exec(chunk.text))) {
          let start = match.index;
          let end = start + length;
          chunk.matches.push([start, end]);
          matches_count++;
        }
      }
    }

    else {
      for (let chunk of data) {
        chunk.matches = false;
      }
    }

    this.setState({data, matches_count, searching_matches: false});
  }

  /* Filters List */

  onChangeHideNonMatches() {
    let hide_filter_non_matches = !this.state.hide_filter_non_matches;
    this.setState({hide_filter_non_matches});
  }

  onToggleActiveFiltersList(filter_id) {
    let {active_filters_list} = this.state;
    let index = active_filters_list.findIndex(id => id === filter_id);
    if (index === -1) {
      active_filters_list.push(filter_id);
    } else {
      active_filters_list.splice(index, 1);
    }
    this.setState({active_filters_list});
    this.findFilterListMatches(active_filters_list);
  }

  findFilterListMatches(active_filters_list) {
    let {filters, data} = this.props;
    for (let chunk of data) {
      chunk.filter_list_matches = [];
      chunk.filter_list_matches_css = [];
      for (let filter_id of active_filters_list) {
        let filter = filters.find(f => f.id === filter_id);
        let match = filter.keywords.some(keyword => {
          let re = new RegExp(keyword, 'i');
          return re.test(chunk.text);
        });

        if (match) {
          chunk.filter_list_matches.push(filter_id);
          chunk.filter_list_matches_css.push(filter.css_class)
        }
      }
    }
  }

  renderTitle(chunk) {
    return <div key={chunk.idx} className="title">{chunk.text}</div>
  }

  renderFilterListIcons(chunk) {
    if (!chunk.filter_list_matches || chunk.filter_list_matches.length === 0) return null;
    let {filters} = this.props;
    return chunk.filter_list_matches.map(filter_id => {
      let filter = filters.find(f => f.id === filter_id);
      return renderFilterListIcon(filter);
    });
  }

  renderText(chunk) {
    let {marks, hide_non_matches, hide_filter_non_matches} = this.state;
    if (hide_non_matches && chunk.matches && chunk.matches.length === 0) return null;
    if (hide_filter_non_matches && (!chunk.filter_list_matches || chunk.filter_list_matches.length === 0)) return null;

    let content =  highlightMatches(chunk);
    let on_click_cb, offset_el;
    if (chunk.markable) {
      on_click_cb = e => this.toggleTextMark(chunk.idx);
    }

    let classes = ['text_chunk']
    if (chunk.style) {
      classes.push(chunk.style);
    }

    if (marks.indexOf(chunk.idx) !== -1) {
      classes.push('marked');
    }

    if (chunk.filter_list_matches_css && chunk.filter_list_matches_css.length > 0) {
      classes = classes.concat(chunk.filter_list_matches_css);
    }

    let style = {}

    if (chunk.offset) {
      style['marginLeft'] = chunk.offset * 4;
    }
    let filter_list_icons = this.renderFilterListIcons(chunk);
    return <div key={chunk.idx} onDoubleClick={on_click_cb} className={classes.join(' ')} style={style}>{content} {filter_list_icons}</div>
  }

  renderContent() {
    let {data} = this.props;
    return data.map(chunk => {
      if (chunk.type === 'title') {
        return this.renderTitle(chunk);
      } else {
        return this.renderText(chunk);
      }
    });
  }

  renderForm() {
    let {search_text, hide_non_matches, full_word_match, matches_count, searching_matches} = this.state;

    let matches_count_el;
    if (searching_matches) {
      matches_count_el = <Loading />
    }else if (matches_count) {
      matches_count_el = <p>{matches_count} matches count</p>
    }

    let disable_hide_non_matches = !search_text || search_text.length === 0
    return (
      <div id="filter_box">
        <div id="filter_form">
          <input type="text" value={search_text} onChange={this.changeSearchText} placeholder="Search text"/>
          {matches_count_el}
        </div>
        <div>
          <input type="checkbox" name="full_word_match" checked={full_word_match} value={full_word_match} onChange={this.changeCheckbox} />
          <label htmlFor="full_word_match">Full word match</label>

          <input type="checkbox" name="hide_non_matches" checked={hide_non_matches} value={hide_non_matches} onChange={this.changeCheckbox} disabled={disable_hide_non_matches} />
          <label htmlFor="hide_non_matches">Hide Non Matches</label>
          <button className="btn btn-default btn-sm" onClick={e => this.resetMarksManually()}>Reset</button>
        </div>
      </div>
    );
  }

  renderFilters() {
    let {filters} = this.props;
    let {hide_filter_non_matches, active_filters_list} = this.state;
    return <FiltersList filters={filters} active_filters={active_filters_list} hideNonMatches={hide_filter_non_matches}
                        onChangeHideNonMatches={this.onChangeHideNonMatches} onToggleActiveFilters={this.onToggleActiveFiltersList} />;
  }


  renderUpdateRequired() {
    return (
      <div>
        <h3>Entry criteria has been updated</h3>
        <button className="btn btn-primary" onClick={e => this.resetMarks()}>Reset Marks</button>
      </div>
    );
  }


  render() {
    let {needs_update} = this.state;
    if (needs_update) return this.renderUpdateRequired();
    return (
      <div id="text_search">
        {this.renderFilters()}
        {this.renderForm()}
        <div id="text_content">
          {this.renderContent()}
        </div>
      </div>
    )
  }
}

class FiltersList extends Component {
  constructor(props) {
    super(props);
    this.state = {open: false}

    this.toggleFilterList = this.toggleFilterList.bind(this);
  }

  toggleFilterList() {
    let open = !this.state.open;
    this.setState({open});
  }

  renderList() {
    let {open} = this.state;
    if (!open) return null;

    let {filters, active_filters, hideNonMatches, onToggleActiveFilters, onChangeHideNonMatches} = this.props;

    return (
      <ul className='filter_list_choice'>

        {filters.map(filter => {
          let checked = active_filters.indexOf(filter.id) !== -1;
          return (
            <li key={filter.id}>
              <input type='checkbox' checked={checked} onChange={e => onToggleActiveFilters(filter.id)} />
              <label>
                {renderFilterListIcon(filter)}
                <span className='filer_list_name'>{filter.name}</span>
              </label>
            </li>
          )
        })}

        <hr/>
        <li className='filter_lists_hide_non_matches'>
          <input id='filter-lists-hide-non-matches' type='checkbox' checked={hideNonMatches} onChange={onChangeHideNonMatches} />
          <label htmlFor='filter-lists-hide-non-matches'>
            <span className='filer_list_hide'>Hide Non Matches</span>
          </label>
        </li>
      </ul>
    )
  }

  renderHeader() {
    let {filters, active_filters} = this.props;

    return (
      <div onClick={this.toggleFilterList}>
        <span className='filter_header_text'>FILTER</span>
        <div className='active_filters_list'>
          {active_filters.map(filter_id => {
            let filter = filters.find(f => f.id === filter_id);
            return renderFilterListIcon(filter);
          })}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className='filter_lists_component'>
        <div className='filter_lists_relative_container'>
          <div className='filter_lists '>
            {this.renderHeader()}
            {this.renderList()}
          </div>
        </div>
      </div>
    )
  }
}


function renderFilterListIcon(filter) {
  let symbol;
  let style = {};
  if (filter.general_requirement) {
    symbol = 'Aa';
  } else {
    style.backgroundColor = filter.color;
  }

  return (
    <span key={filter.id} className='filter_list_icon' style={style} title={filter.name}>
      {symbol}
    </span>
  )
}


function highlightMatches(chunk) {
  if (!chunk.matches || chunk.matches.length === 0) return chunk.text;

  let content = [];
  let start = 0;

  for (let i=0; i < chunk.matches.length; i++) {
    let match = chunk.matches[i];
    let base_key = `${chunk.idx}:${i}:`;

    let end = match[0];
    content.push(<span key={base_key + 'pre'}>{chunk.text.substring(start, end)}</span>)
    start = end;
    end = match[1];
    content.push(<span key={base_key + 'match'} className="match">{chunk.text.substring(start, end)}</span>)
    start = end;
  }

  // Add the last part at the end
  let post_key = `${chunk.idx}:post:`;
  content.push(<span key={post_key}>{chunk.text.substring(start)}</span>)
  return content;
}
