import React, {Component} from 'react';
import Form from '../form';
import {getLocationLabel} from '../locations';
import Markdown from '../markdown';
import Loading from '../loading';
import request from '../../request';
import LocationsConfig from './locations_config';
import CancerCentersConfig from './cancer_centers_config';
import CollectionsReport from '../collections_report';
import TrialCollectionStarsRating from '../trial_collection_stars_rating';
import {REPORT_DEFAULT_HOW_TO_ACCESS_TEXT} from '../../constants.js.erb';

export default class CollectionTrialTab extends Component {
  constructor(props) {
    super(props);
    let {report, collection_trial} = props;
    let customizations = report.data.collection_trial_customizations[collection_trial.id] || defaultCustomizations();

    if (!customizations.references) customizations.references = {};
    if (!customizations.referring_person_references) customizations.referring_person_references = [];
    if (!customizations.included_interventions) customizations.included_interventions = [];
    if (!customizations.layout) customizations.layout = 'therapy';

    this.state = {collection_trial_id: collection_trial.id, customizations};
    this.onChange = this.onChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let {report, collection_trial} = props;
    if (collection_trial.id !== state.collection_trial_id) {
      state.collection_trial_id = collection_trial.id;
      state.customizations = report.data.collection_trial_customizations[collection_trial.id] || defaultCustomizations();
      state.patient_trial_extra = undefined;
    }
    return state;
  }

  componentDidUpdate() {
    if (!this.state.patient_trial_extra) {
      this.fetchPatientTrialData();
    }
  }

  componentDidMount() {
    this.fetchPatientTrialData();
  }

  fetchPatientTrialData() {
    let {collection_trial, patient} = this.props;
    let url = '/admin/patients/' + patient.id + '/trials/' + collection_trial.trial_id;
    request('GET', url).then(patient_trial_extra => {
      this.setState({patient_trial_extra})
    });
  }

  onChange(customizations) {
    this.setState({customizations});
    let {report, collection_trial, onChange} = this.props;
    report.data.collection_trial_customizations[collection_trial.id] = customizations;
    onChange(report, true);
  }

  selectIntervention(item_id, e) {
    let {customizations} = this.state;
    let {checked} = e.target;

    if (checked) {
      customizations.included_interventions.push({intervention_id: item_id, route_of_administration_ids: []});
    } else {
      let index = customizations.included_interventions.findIndex(i => i.intervention_id === item_id)
      customizations.included_interventions.splice(index, 1);
    }
    this.onChange(customizations);
  }


  selectInterventionROA(item_id, roa_id, e) {
    let {customizations} = this.state;
    let {checked} = e.target;

    let index = customizations.included_interventions.findIndex(i => i.intervention_id === item_id)
    if (checked) {
      customizations.included_interventions[index].route_of_administration_ids.push(roa_id);
    } else {
      let roa_index = customizations.included_interventions[index].route_of_administration_ids.indexOf(roa_id);
      customizations.included_interventions[index].route_of_administration_ids.splice(roa_index, 1);
    }
    this.onChange(customizations);
  }

  renderHeader() {
    let {collection_trial} = this.props;
    if (collection_trial.is_trial) {
      return this.renderTrialHeader();
    } else {
      return this.renderTherapyHeader();
    }

  }

  renderTrialHeader() {
    let {collection_trial} = this.props;
    let {trial_identifier, trial_name, trial_description} = collection_trial;
    let position = collection_trial.position

    return (
      <div className="report_option_listing report_option_listing_config">
        <div className="index_and_link">
          <div className="listing_index">{position}</div>
        </div>
        <div className="listing_details">
          <span className="listing_id">{trial_identifier}</span>
          <h5 className="listing_title">{trial_name}</h5>
        </div>
      </div>
    );
  }


  renderTherapyHeader() {
    let {customizations} = this.state;

    let content;
    if (customizations.layout === THERAPY_LAYOUT) {
      content = this.renderTherapyLayoutHeader();
    } else {
      content = this.renderPersonLayoutHeader();
    }

    let fields = [{name: 'layout', type: 'select', collection: LAYOUTS, allowBlank: false}];
    return (
      <div>
        <Form fields={fields} model={customizations} onChange={this.onChange} />
        {content}
      </div>
    );
  }


  renderTherapyLayoutHeader() {
    let {collection_trial} = this.props;

    let {therapy_type_name, patient_friendly_description, name} = collection_trial.trial;
    let position = collection_trial.position

    let title = patient_friendly_description || name;
    return (
      <div className="report_option_listing report_option_listing_config">
        <div className="index_and_link">
          <div className="listing_index">{position}</div>
        </div>
        <div className="listing_details">
          <h5 className="listing_title">{title}</h5>
        </div>
      </div>
    );
  }

  renderPersonLayoutHeader() {
    let {collection_trial} = this.props;

    let {therapy_type_name, patient_friendly_description, name} = collection_trial.trial;
    let position = collection_trial.position

    let title = patient_friendly_description || name;


    let {primary_person_id, primary_person} = collection_trial.patient_trial_data;

    let primary_person_el;

    if (primary_person_id) {
      primary_person_el = primary_person.full_name;
    } else {
      primary_person_el = "Primary Person not assigned"
    }


    return (
      <div className="report_option_listing report_option_listing_config">
        <div className="index_and_link">
          <div className="listing_index">{position}</div>
        </div>
        <div className="listing_details">
          <h5 className="listing_title">{primary_person_el}</h5>
          <span>{title}</span>
        </div>
      </div>
    );
  }


  renderReadOnlyInfo() {
    let {collection_trial} = this.props;
    let {customizations} = this.state;
    if (collection_trial.is_therapy && customizations.layout !== THERAPY_LAYOUT) return null;

    return (
      <div className="row report_config_row" id="how_it_works">
        <div className="col-md-12">
          <h4 className="report_config_section_header">HOW IT WORKS</h4>
          <p className="read_only_intro_text">All Interventions from the therapy&#x27;s groups are listed below and you can choose which ones will appear in the report.</p>

          {this.renderHowItWorks()}
        </div>
      </div>
    )
  }



  renderWhyIsThisOfInterest() {
    let {patient_trial_extra, customizations} = this.state;
    let {collection_trial} = this.props;
    if (collection_trial.is_trial || customizations.layout !== THERAPY_LAYOUT) return null;

    let {efficacy_notes} = collection_trial.patient_trial_data;

    let fields = [
      {name: 'custom_efficacy_notes', type: 'bool', label: ' Use custom efficacy notes'},
      {name: 'efficacy_notes', type: 'text', visible: c => c.custom_efficacy_notes},
    ];

    let preview_content = customizations.custom_efficacy_notes ? customizations.efficacy_notes : efficacy_notes;
    return (
      <div className="row report_config_row">
        <div className="col-md-6">
          <h4 className="report_config_section_header">WHY IS THIS OF INTEREST?</h4>
          <h4 className="generated_content_header">Generated from last efficacy status in collection</h4>
          <p>{efficacy_notes}</p>
          <div className="content_customize_box">
            <Form fields={fields} model={customizations} onChange={this.onChange} />
          </div>
        </div>
        <div className="col-md-5 col-md-offset-1">
          <div className="content_preview_box">
            <h4 className="report_config_preview_header">WHY IS THIS OF INTEREST?</h4>
            <Markdown text={preview_content} />
          </div>
        </div>
      </div>
    )
  }

  renderWhatElseShouldYouKnow() {
    let {patient_trial_extra, customizations} = this.state;
    let {collection_trial} = this.props;
    if (collection_trial.is_trial || customizations.layout !== THERAPY_LAYOUT) return null;

    let {side_effects_notes} = collection_trial.patient_trial_data;

    let fields = [
      {name: 'custom_side_effects_notes', type: 'bool', label: ' Use custom side effects notes'},
      {name: 'side_effects_notes', type: 'text', visible: c => c.custom_side_effects_notes},
    ];

    let preview_content = customizations.custom_side_effects_notes ? customizations.side_effects_notes : side_effects_notes;
    return (
      <div className="row report_config_row">
        <div className="col-md-6">
          <h4 className="report_config_section_header">WHAT ELSE SHOULD YOU KNOW</h4>
          <h4 className="generated_content_header">Generated from last side effects status in collection</h4>
          <p>{side_effects_notes}</p>
          <div className="content_customize_box">
            <Form fields={fields} model={customizations} onChange={this.onChange} />
          </div>
        </div>
        <div className="col-md-5 col-md-offset-1">
          <div className="content_preview_box">
            <h4 className="report_config_preview_header">WHAT ELSE SHOULD YOU KNOW</h4>
            <Markdown text={preview_content} />
          </div>
        </div>
      </div>
    )
  }


  renderHowToAccess() {
    let {customizations} = this.state;
    let {collection_trial} = this.props;

    if (collection_trial.is_trial || customizations.layout !== THERAPY_LAYOUT) return null;

    let fields = [
      {name: 'how_to_access', type: 'text'}
    ];

    let preview_text = customizations.how_to_access || REPORT_DEFAULT_HOW_TO_ACCESS_TEXT;
    return (
      <div className="row report_config_row">
        <div className="col-md-6">
          <h4 className="report_config_section_header">How to Access</h4>

          <div className="content_customize_box">
            <Form fields={fields} model={customizations} onChange={this.onChange} />
          </div>
        </div>
        <div className="col-md-5 col-md-offset-1">
          <div className="content_preview_box">
            <h4 className="report_config_preview_header">How To Access</h4>
            <Markdown text={preview_text} />
          </div>
        </div>
      </div>
    )
  }


  renderROA(item, index, selected) {
    let {customizations} = this.state;
    if (!item.route_of_administrations || item.route_of_administrations.length === 0) return null;

    return (
      <ul className="intervention_roa_options">
        <li>Available ROAs</li>
        {item.route_of_administrations.map(roa => {
          let roa_checked = selected && customizations.included_interventions[index].route_of_administration_ids.indexOf(roa.id) !== -1;
          return (
            <li key={roa.id}>
              <input type="checkbox" disabled={!selected} value={roa_checked} checked={roa_checked} onChange={e => this.selectInterventionROA(item.id, roa.id, e)} />
              <label>{roa.name}</label>
            </li>
          )
        })}
      </ul>
    )
  }

  renderHowItWorks() {
    let {patient_trial_extra, customizations} = this.state;

    let {group_items} = patient_trial_extra;

    if (group_items.length === 0) {
      return <p className="nothing_to_show"><b>NOTE:</b> There are no interventions in the groups for this therapy. Edit the trial to add interventions.</p>
    }

    return (
      <div className="how_it_works_intervention_options">
        {group_items.map(item => {
          let index = customizations.included_interventions.findIndex(i => i.intervention_id === item.id)
          let selected = index !== -1;

          return (
            <div key={item.key} className="intervention_option">
              <div className="invention_option_details">
                <input type="checkbox" value={selected} checked={selected} onChange={e => this.selectIntervention(item.id, e)} />
                <p><b><a href={item.url} target="_blank">{item.name}</a></b> - {item.description}</p>
              </div>

              {this.renderROA(item, index, selected)}
            </div>
          );
        })}
      </div>
    );
  }


  renderPatientTrialData() {
    let {collection_trial, patient} = this.props;
    let {patient_trial_data} = collection_trial;
    let efficacy_notes_el, side_effects_notes_el;

    if (patient_trial_data.efficacy_notes && patient_trial_data.efficacy_notes.length > 0) {
      efficacy_notes_el = (
        <div>
          <Markdown text={patient_trial_data.efficacy_notes} />
        </div>
      );
    }

    if (patient_trial_data.side_effects_notes && patient_trial_data.side_effects_notes.length > 0) {
      side_effects_notes_el = (
        <div>
          <Markdown text={patient_trial_data.side_effects_notes} />
        </div>
      );
    }

    return (
      <div className="row report_config_row" id="patient_trial_data">
        <div className="col-md-12">
          <h4><b>From Collection (Patient Specific)</b></h4>
           <TrialCollectionStarsRating patient_trial_data={patient_trial_data} />
        </div>
      </div>
    )

  }

  renderCollectionsReport() {
    let {collection_trial, patient} = this.props;
    let {patient_trial_extra} = this.state;
    let collections = patient_trial_extra.relevant_collections.filter(r => r.collection.id !== collection_trial.collection_id && r.patient.id !== patient.id);

    if (collections.length === 0) {
      return <p className="nothing_to_show"><b>NOTE:</b> This has not appeared in a collection of any other patients.</p>
    }

    let patients_count = collections.map(c => c.patient.id).unique().length;

    return (
      <div className="related_collections_holder">
        <div className="related_collections_header">
          <p>This therapy has been included in <b>{collections.length}</b> other collections belonging to {patients_count} other patients.</p>
          <button className="btn btn-sm btn-success" type="button" data-toggle="collapse" data-target="#collections_report" aria-expanded="false" aria-controls="collections_report">
            View Details
          </button>
        </div>
        <div className="collapse" id="collections_report">
          <CollectionsReport collections={collections} />
        </div>
      </div>
    )
  }



  renderTherapyType() {
    let {patient_trial_extra, customizations} = this.state;

    let fields = [
      {name: 'custom_therapy_type_label', type: 'bool', label: ' Set custom text for the therapy type'},
      {name: 'therapy_type_label', type: 'text', label: 'Type of Therapy:', visible: c => c.custom_therapy_type_label}
    ];

    let hide_option = [
      {name: 'hide_therapy_type', type: 'bool', label: ' Do not show Type of Therapy' }
    ];


    let trial_id = this.props.collection_trial.trial_id
    let trial_url = '/admin/trials/' + trial_id + '/edit/#groups'

    let generated_content
    let preview_content

    if (patient_trial_extra.therapy_type_label) {
      preview_content = patient_trial_extra.therapy_type_label
      generated_content = (
        <div>
          <h4 className="generated_content_header">Generated from Trial Groups</h4>
          <p>{patient_trial_extra.therapy_type_label}</p>
        </div>
      )
    } else {
      generated_content = (
        <p className="subtle">There is no therapy type set for this trial. Please review trial groups as the interventions added determine the therapy type. <a href={trial_url} target='_blank'>Go to Trial Groups</a></p>
      )
    }

    if (customizations.custom_therapy_type_label) {
      preview_content = customizations.therapy_type_label
    }

    let preview_el;

    if (!customizations.hide_therapy_type) {
      preview_el = <div className="content_preview_box"><p>TYPE OF THERAPY: {preview_content}</p></div>
    }

    return (
      <div className="row report_config_row">
        <div className="col-md-6">
          <h4 className="report_config_section_header">TYPE OF THERAPY</h4>
          {generated_content}
          <div className="content_customize_box">
            <Form fields={fields} model={customizations} onChange={this.onChange} />
          </div>
        </div>
        <div className="col-md-5 col-md-offset-1">
          {preview_el}
          <Form fields={hide_option} model={customizations} onChange={this.onChange} />
        </div>
      </div>
    )
  }


  renderLink() {
    let {patient_trial_extra, customizations} = this.state;
    let {collection_trial} = this.props;
    if (collection_trial.is_therapy) return null;

    let trial_id = collection_trial.trial_identifier;
    let fields = [
      {name: 'custom_link', type: 'bool', label: ' Use custom link instead'},
      {name: 'link_text', visible: c => c.custom_link},
      {name: 'link_url', visible: c => c.custom_link}
    ];
    let generated_url = patient_trial_extra.study_url;
    let label;
    if (customizations.custom_link) {
      label = <a href={customizations.link_url} target="_blank">{customizations.link_text}</a>;
    } else {
      label = <a href={generated_url} target="_blank">{trial_id}</a>;
    }

    return (
      <div className="row report_config_row">
        <div className="col-md-6">
          <h4 className="report_config_section_header">THERAPY DETAIL LINK</h4>
          <p>{generated_url}</p>
          <div className="content_customize_box">
            <Form fields={fields} model={customizations} onChange={this.onChange} />
          </div>
        </div>
        <div className="col-md-5 col-md-offset-1">
          <div className="content_preview_box">
            <p>REFERENCE: {label}</p>
          </div>
        </div>
      </div>
    )
  }


  renderLocations() {
    let {patient_trial_extra, customizations} = this.state;
    let {collection_trial} = this.props;
    if (collection_trial.is_therapy && customizations.layout === THERAPY_LAYOUT) return null;

    if (collection_trial.is_trial) {
      return <LocationsConfig patient_trial_extra={patient_trial_extra} customizations={customizations} onChange={this.onChange} />
    } else {
      return <CancerCentersConfig patient_trial_extra={patient_trial_extra} customizations={customizations} onChange={this.onChange} />
    }
  }


  renderContacts() {
    let {patient_trial_extra, customizations} = this.state;
    let {collection_trial} = this.props;
    if (collection_trial.is_therapy && customizations.layout === THERAPY_LAYOUT) return null;

    let fields = [
      {name: 'custom_contacts', type: 'bool', label: ' Set custom text for contacts'},
      {name: 'contacts', type: 'text', visible: c => c.custom_contacts}
    ];

    let hide_option = [
      {name: 'hide_contacts', type: 'bool', label: ' Do not show contacts' }
    ];

    let generated_people, generated_content, preview_content;

    if (patient_trial_extra.people.length > 0) {
      generated_people = (
        <div>
          {patient_trial_extra.people.map(p => {
            return (
              <div key={p.id} className="gen_person_listing">
                <p><b>{p.full_name}</b></p>
                <p>{p.email}</p>
                <p>{p.phone}</p>
                <p>{p.cell_phone}</p>
              </div>
            );
          })}
        </div>
      )
      generated_content = (
        <div>
          <h4 className="generated_content_header">Relevent People marked in collection</h4>
          {generated_people}
        </div>
      )
      preview_content = generated_people
    } else {
      generated_content = <p className="subtle">There are no relevant people. Please mark in collection.</p>;
    }

    if (customizations.custom_contacts) {
      preview_content = <Markdown text={customizations.contacts} />;
    }

    let preview_box;
    if (!customizations.hide_contacts) {
      preview_box = (
        <div className="content_preview_box report_preview_listing">
          {preview_content}
        </div>
      )
    };

    return (
      <div className="row report_config_row">
        <div className="col-md-6">
          <h4 className="report_config_section_header">CONTACTS</h4>
          {generated_content}
          <div className="content_customize_box">
            <Form fields={fields} model={customizations} onChange={this.onChange} />
          </div>
        </div>
        <div className="col-md-5 col-md-offset-1">
          {preview_box}
          <Form fields={hide_option} model={customizations} onChange={this.onChange} />
        </div>
      </div>
    )
  }


  renderSponsors() {
    let {patient_trial_extra, customizations} = this.state;
    let {collection_trial} = this.props;
    if (collection_trial.is_therapy && customizations.layout === THERAPY_LAYOUT) return null;

    let fields = [
      {name: 'custom_sponsors', type: 'bool', label: ' Set custom text for sponsors'},
      {name: 'sponsors', type: 'text', label: 'Custom text for Sponsors/Companies', visible: c => c.custom_sponsors}
    ];

    let hide_option = [{name: 'hide_sponsors', type: 'bool', label: ' Do not show companies'}]

    let lead_content, collaborators_content;
    let {lead, collaborators} = patient_trial_extra.sponsors;

    let generated_lists, generated_content, preview_content

    if (lead || collaborators.length > 0) {
      if (lead) {
        lead_content = (
          <div className="sponsor_group">
            <strong>Lead Sponsor</strong>
            <div>{lead.name}</div>
          </div>
        );
      }

      if (collaborators.length > 0) {
        collaborators_content = (
          <div className="sponsor_group">
            <strong>Collaborators</strong>
            {collaborators.map(c => {
              return (
                <div key={c.id}>
                  {c.name}
                </div>
              );
            })}
          </div>
        );
      }

      generated_content = (
        <div>
          <h4 className="generated_content_header">Sponsors belonging to the trial</h4>
          {lead_content}
          {collaborators_content}
        </div>
      )

      preview_content = (
        <div>
          {lead_content}
          {collaborators_content}
        </div>
      )

    } else {
      generated_content = <p className="subtle">There are no sponsors listed for this trial.</p>;
    }

    if (customizations.custom_sponsors) {
      preview_content = <Markdown text={customizations.sponsors} />;
    }

    let preview_box;
    if (!customizations.hide_sponsors) {
      preview_box = (
        <div className="content_preview_box report_preview_listing">
          <h4 className="report_config_preview_header">COMPANIES</h4>
          {preview_content}
        </div>
      )
    }


    return (
      <div className="row report_config_row">
        <div className="col-md-6">
          <h4 className="report_config_section_header">SPONSORS</h4>
          {generated_content}
          <div className="content_customize_box">
            <Form fields={fields} model={customizations} onChange={this.onChange} />
          </div>
        </div>
        <div className="col-md-5 col-md-offset-1">
          {preview_box}
          <Form fields={hide_option} model={customizations} onChange={this.onChange} />
        </div>
      </div>
    )
  }


  renderHeaderDetails() {
    let {customizations} = this.state;
    let {collection_trial} = this.props;

    let fields = [
      {name: 'custom_sidebar_section', type: 'bool', label: ' Include custom section after basic details'},
      {name: 'sidebar_section_header', visible: c => c.custom_sidebar_section},
      {name: 'sidebar_section_text', type: 'text', visible: c => c.custom_sidebar_section}
    ];

    let preview;

    if (customizations.custom_sidebar_section) {
      preview = (
        <div>
          <div className="content_preview_box">
            <p><strong>{customizations.sidebar_section_header}</strong></p>
            <Markdown text={customizations.sidebar_section_text} />
          </div>
        </div>
      );
    }


    return (
      <div className="row report_config_row">
        <div className="col-md-6">
          <h4 className="report_config_section_header">CUSTOM CALLOUT</h4>
          <div className="content_customize_box">
            <Form fields={fields} model={customizations} onChange={this.onChange} />
          </div>
        </div>
        <div className="col-md-5 col-md-offset-1">
          {preview}
        </div>
      </div>
    )
  }


  renderReferences() {
    let {patient_trial_extra, customizations} = this.state; ;
    let {collection_trial} = this.props;
    if (collection_trial.is_therapy && customizations.layout === PERSON_LAYOUT) return null;

    return (
      <ReferencesCustomization references={patient_trial_extra.references} customizations={customizations} onChange={this.onChange} />
    )
  }


  renderDetails() {
    let {collection_trial} = this.props;
    if (collection_trial.is_therapy) return null;

    let {patient_trial_extra, customizations} = this.state;

    let hide_option = [{name: 'hide_details', type: 'bool', label: ' Do not show these details'}];
    let {placebo, blinded, randomized, combination} = patient_trial_extra.details;
    let generated_content = (
      <div className="report_details_preview">
        <p>{blinded ? 'Blinded' : 'Not Blinded'}</p>
        <p>{randomized ? 'Randomized' : 'Not Randomized'}</p>
        <p>{placebo ? 'Placebo' : 'No Placebo'}</p>
      </div>
    )

    let preview_box;
    if (!customizations.hide_details) {
      preview_box = (
          <div className="content_preview_box">
            <h4 className="report_config_preview_header">DETAILS</h4>
            {generated_content}
          </div>
      )
    }

    return (
      <div className="row report_config_row">
        <div className="col-md-6">
          <h4 className="report_config_section_header">DETAILS</h4>
          {generated_content}
        </div>
        <div className="col-md-5 col-md-offset-1">
          {preview_box}
          <Form fields={hide_option} model={customizations} onChange={this.onChange} />
        </div>
      </div>
    )
  }


  renderReferringPerson() {
    let {customizations} = this.state;
    let {collection_trial} = this.props;
    if (collection_trial.is_trial || customizations.layout === THERAPY_LAYOUT) return null;
    let {primary_person} = collection_trial.patient_trial_data;

    return <ReferringPersonCustomizations primary_person={primary_person} customizations={customizations} onChange={this.onChange} />
  }

  renderNavbar() {
    let {collection_trial, patient, submit, submitting, errors} = this.props;
    let {patient_trial_extra, customizations} = this.state;

    let {collection_id, trial_identifier, trial_name, trial_id} = collection_trial;
    let collection_trial_link = '/admin/patients/' + patient.id + '/collections/' + collection_id + '/trials/' + collection_trial.id;
    let preview_trial_link = '/admin/patients/' + patient.id + '/collections/' + collection_id + '/trials/' + collection_trial.id + '/report';
    let trial_edit_link = '/admin/trials/' + trial_id + '/edit';
    let ct_gov_link = 'https://clinicaltrials.gov/ct2/show/' + trial_identifier
    let position = collection_trial.position

    return (
      <div className="fixed-bottom-navbar">
        <div className="trial_link_group">
          <a href={collection_trial_link} target="_blank">View in Patient Collection</a>
          <a href={preview_trial_link} target="_blank">Preview Report Page</a>
          <a href={trial_edit_link} target="_blank">Edit Trial</a>
          <a href={ct_gov_link} target="_blank">View on clinicaltrials.gov</a>
        </div>
        <div className="nav-bar-form-holder">
          <span className="remember-text">Remember to save your changes!</span>
          <Form model_name="Customizations" singleton model={customizations} fields={[]} onSubmit={submit} submitting={submitting} errors={errors}/>
        </div>
      </div>
    );
  }


  render() {
    let {patient_trial_extra} = this.state;
    if (!patient_trial_extra) return <Loading />

    return (
      <div>
        {this.renderHeader()}
        {this.renderTherapyType()}
        {this.renderLink()}
        {this.renderReadOnlyInfo()}
        {this.renderWhyIsThisOfInterest()}
        {this.renderWhatElseShouldYouKnow()}
        {this.renderHowToAccess()}
        {this.renderPatientTrialData()}
        {this.renderCollectionsReport()}
        {this.renderDetails()}
        {this.renderLocations()}
        {this.renderContacts()}
        {this.renderReferringPerson()}
        {this.renderSponsors()}
        {this.renderHeaderDetails()}
        {this.renderReferences()}
        {this.renderNavbar()}
      </div>
    )
  }
}


export class ReferencesCustomization extends Component {
  constructor(props) {
    super(props);
  }

  change(reference_id, e) {
    let {customizations, onChange} = this.props;
    let {name, checked} = e.target;
    if (!customizations.references[reference_id]) customizations.references[reference_id] = {attachments: {}};
    customizations.references[reference_id][name] = checked;
    onChange(customizations);
  }

  changeReference(reference_id, reference_customization) {
    let {customizations, onChange} = this.props;
    customizations.references[reference_id] = reference_customization;
    onChange(customizations);
  }

  changeAttachment(reference_id, attachment_id, data) {
    let {customizations, onChange} = this.props;
    let reference_customizations = customizations.references[reference_id];
    if (!reference_customizations.attachments) reference_customizations.attachments = {};
    reference_customizations.attachments[attachment_id] = data;
    customizations.references[reference_id] = reference_customizations;
    onChange(customizations);
  }

  renderAttachments(reference, reference_customizations) {
    if (!reference.attachments || reference.attachments.length === 0) return null;

    return (
      <div>
        {reference.attachments.map(attachment => {
          let attachment_data = reference_customizations.attachments[attachment.id] || {}

          return (
            <div key={attachment.id}>
              <div className="reference-attachment-options">
                <div className="reference-attachment-info">
                  <div>
                    <p><b>{attachment.name}</b></p>
                    <p>{attachment.description}</p>
                  </div>
                  <img src={attachment.thumb} />
                </div>
                <hr/>
                <Form model={attachment_data} fields={REFERENCE_ATTACHMENT_FIELDS} onChange={d => this.changeAttachment(reference.id, attachment.id, d)} />
              </div>
            </div>
          );
        })}
      </div>
    )
  }

  renderOptions(reference) {
    let {references, customizations} = this.props;
    let reference_customizations = customizations.references[reference.id];
    if (!reference_customizations || !reference_customizations.include) return null;

    let current_abstract_text_el;
    if (reference.abstract_text && reference.abstract_text.length > 0) {
      current_abstract_text_el = <Markdown text={reference.abstract_text} />
    } else {
      current_abstract_text_el = <p><i>This reference does not have abstract text provided.</i></p>
    }

    return (
      <div>
        <hr/>
        <h4 className="generated_content_header">Reference information</h4>
        <div className="reference-option-information-holder">
          <p>Source: <a href={reference.source_link} target="_blank">{reference.source_link}</a> ({reference.reference_year})</p>
          {current_abstract_text_el}
        </div>
        <div className="content_customize_box">
          <Form model={reference_customizations} fields={REFERENCE_FIELDS} onChange={d => this.changeReference(reference.id, d)} />
          {this.renderAttachments(reference, reference_customizations)}
        </div>
      </div>
    );
  }

  render() {
    let {references, customizations} = this.props;

    return (

        <div className="row report_config_row">
          <div className="col-md-12">
            <h4 className="report_config_section_header">REFERENCES</h4>
            <h4 className="generated_content_header">Select which references should appear in the report</h4>
            {references.map(reference => {
              let reference_customizations = customizations.references[reference.id] || {};
              let included = reference_customizations.include;
              let reference_url = '/admin/references/' + reference.id + '/edit';
              let selected_text = reference_customizations.custom_abstract_text ? reference_customizations.abstract_text : reference.abstract_text
              return (
                <div className="row single-reference-option-row" key={reference.id}>
                  <div className="col-md-6">
                    <div className="reference-toggle">
                      <input type="checkbox" name="include" value={included} checked={included} onChange={e => this.change(reference.id, e)}/>
                      <a href={reference_url} target="_blank">{reference.full_name}</a>
                    </div>
                    {this.renderOptions(reference)}
                  </div>

                  <div className="col-md-5 col-md-offset-1">
                    {reference_customizations.include ?
                    <div className="content_preview_box">
                      <p><b>{reference.full_name}</b></p>
                      <Markdown text={selected_text} />
                    </div>
                      : ''
                    }
                  </div>
                </div>
              )
            })}
      </div>
    </div>
    );
  }
}


class ReferringPersonCustomizations extends Component {

  renderPreview() {
    let {primary_person} = this.props;
    if (!primary_person) return null;

    let {expertise_information, background_information, specialized_offerings} = primary_person;
    return (
      <div>
        <div className="report_main_section">
          <h3 className="report_content_header">Why consider this Expert</h3>
          <Markdown text={expertise_information} />
        </div>

        <div className="report_main_section">
          <h3 className="report_content_header">Background Information</h3>
          <Markdown text={background_information} />
        </div>

        <div className="report_main_section">
          <h3 className="report_content_header">Specialized Offerings</h3>
          <Markdown text={specialized_offerings} />
        </div>
      </div>
    )
  }

  toggleReference(reference_id) {
    let {customizations, onChange} = this.props;
    let index = customizations.referring_person_references.indexOf(reference_id)
    if (index === -1) {
      customizations.referring_person_references.push(reference_id);
    } else {
      customizations.referring_person_references.splice(index, 1);
    }
    onChange(customizations);
  }

  renderReferences() {
    let {primary_person, customizations} = this.props;
    if (!primary_person) return null;
    let {referring_person_references} = customizations;

    return (
      <div className="referring_person_references">
        <h3>References By This Person</h3>
        <h4 className="generated_content_header">Choose which should appear in report</h4>
        {primary_person.references.map(reference => {
          let checked = referring_person_references.indexOf(reference.id) !== -1;
          return (
            <div key={reference.id} className="reference_option">
              <input type="checkbox" value={checked} checked={checked} onChange={e => this.toggleReference(reference.id)} />
              {reference.full_name}
            </div>
          );
        })}
      </div>

    );
  }

  render() {
    let {customizations, primary_person, onChange} = this.props;

    let fields = [
      {name: 'hide_primary_person_photo', type: 'bool', label: 'Hide Photo of Referring Person'},
      // {name: 'expertise_information', type: 'text', label: 'Why consider this Expert'},
      // {name: 'background_information', type: 'text'},
      // {name: 'specialized_offerings', type: 'text'}
    ];

    let link_to_person, message, url;

    if (primary_person) {
      url = `/admin/persons/${primary_person.id}`;
      link_to_person = <a href={url} target="_blank">{primary_person.full_name}</a>;
      message = <h4 className="generated_content_header">Referring Person set in collection:</h4>;

    } else {
      message = <p className="subtle">There is no Referring Person selected. Please mark in collection.</p>
    }


    return (
      <div className="row report_config_row">
        <div className="col-md-6">
          <h4 className="report_config_section_header">REFERRING PERSON</h4>
          {message}
          {link_to_person}
          <div className="content_customize_box">
            <Form fields={fields} model={customizations} onChange={onChange} />
          </div>
          {this.renderReferences()}
        </div>
        <div className="col-md-5 col-md-offset-1">
          <div className="content_preview_box">
            {this.renderPreview()}
          </div>
          <a href={url} target="_blank">Edit Person Details</a>
        </div>

      </div>
    )

  }
}

const REFERENCE_FIELDS = [
  {name: 'custom_abstract_text', type: 'bool', label: 'Use custom text instead of abstract'},
  {name: 'abstract_text', type: 'text', visible: c => c.custom_abstract_text, label: 'Custom text for this reference' }
]

const REFERENCE_ATTACHMENT_FIELDS = [
  {name: 'included', type: 'bool', label: 'Include this Reference Image'},
  {name: 'caption', type: 'text', label: 'Caption (Optional)'}
]

function defaultCustomizations() {
  return {references:{}, included_interventions: [], additional_trials: [], hide_sponsors: true};
}

const PERSON_LAYOUT = 'person';
const THERAPY_LAYOUT = 'therapy'
const LAYOUTS = {
  therapy: 'Therapy',
  person: 'Person'
}
