import React, {Component} from 'react';
import TaskForm from './task_form';
import TimeCardForm from './time_card_form';
import request from '../request';
import Form from './form';
import Loading from './loading';
import {TaskActions} from './task_actions';

export default class Task extends Component {
  constructor(props) {
    super(props);
    let {task} = props;
    this.state = {task};
  }

  deleteTask() {
    if (!confirm('Are you sure?')) return;

    let {task, return_path} = this.props;
    let url = '/admin/tasks/' + task.id;
    request('DELETE', url).then(task => {
      window.location = return_path;
    });
  }

  submitTask(task) {
    let url = '/admin/tasks/' + task.id;
    return request('PUT', url, {task}).then(task => {
      this.setState({task});
    });
  }

  onChangeActions(actions) {
    let {task} = this.state;
    task.actions = actions;
    this.submitTask(task)
  }

  toggleCompleted() {
    this.setState({submitting_state: true});
    let {task} = this.state;
    let action = task.completed ? 'uncomplete' : 'complete';
    let url = `/admin/tasks/${task.id}/${action}`;
    request('PUT', url)
      .then(task => {
      this.setState({task, submitting_state: false});
      })
      .catch(e => {
        this.setState({submitting_state: false});
      });
  }

  onChangeTask(current) {
    this.setState({task: current, current: undefined});
  }

  onCancelEditTask(e) {
    e.preventDefault();
    this.setState({current: undefined});
  }

  editTask(current) {
    this.setState({current})
  }


  onChangeTimeCard(current) {
    let {task} = this.state;
    let index = task.time_cards.findIndex(t => t.id === current.id);
    if (index !== -1) {
      task.time_cards[index] = current;
    } else {
      task.time_cards.push(current);
    }
    this.setState({task, current_time_card: undefined});
  }

  onCancelEditTimeCard(e) {
    e.preventDefault();
    this.setState({current_time_card: undefined});
  }

  editTimeCard(current_time_card) {
    this.setState({current_time_card})
  }


  addTimeCard() {
    let {task} = this.state;
    let {admin_id} = this.props;
    let time_card = {task_id: task.id, work_category_id: task.work_category_id, patient_id: task.patient_id, admin_id, date: new Date()}
    this.editTimeCard(time_card);
  }

  deleteTimeCard(timecard) {
    if (!confirm('Are you sure?')) return;
    let url = `/admin/time_cards/${timecard.id}`;
    request('DELETE', url).then(() => {
      let {task} = this.state;
      let index = task.time_cards.findIndex(t => t.id === timecard.id);
      task.time_cards.splice(index, 1);
      this.setState({task});
    });
  }

  renderTaskForm(task) {
    return (
      <div className="outer_holder outer_holder_form">
        <div className="inner_holder">
          <TaskForm task={task} onSubmit={t => this.onChangeTask(t)} onCancel={e => this.onCancelEditTask(e)} />
        </div>
      </div>
    )
  }

  renderCreator() {
    let {task} = this.state;
    let date = moment(task.created_at).format('LL');
    return (
      <p className="task_creator_note">Created on {date} by {task.creator_name}</p>
    );
  }

  renderState() {
    let {task, submitting_state} = this.state;
    let label = task.completed ? 'Complete' : 'Click to Complete';
    let loading_el, task_msg;

    if (submitting_state) {
      loading_el =  <Loading size="20" />
    }

    if (task.completed) {
      let completed_at = moment(task.completed_at).format('LLL');
      task_msg = <p><i>Marked complete by {task.completed_by_name} on {completed_at}</i></p>
    } 
    
    return (
      <div className="task_status_box">
        <input type="checkbox" checked={task.completed} value={task.completed} onChange={e => this.toggleCompleted()} disabled={submitting_state}/>
        <span className="task_status_label">{label}</span>
        {task_msg}
        {loading_el}
      </div>
    );
  }

  renderTaskHeader() {
    let {task} = this.state;
    let due_date, patient_link;
    if (task.patient_id) {
      let  patient_url = '/admin/patients/' + task.patient_id
      patient_link = (
        <a href={patient_url}>{task.patient_name}</a>
      )
    }
    if (task.due_date) {
      due_date = (
        <p className="task_due_date">DUE: {moment(task.due_date).format('LL')}</p>
      )
    }

    return (
      <div className="task_header">
        <div className="task_details">
          <p><span className="task_detail_header">ASSIGNED TO</span><br/>{task.admin_name ? task.admin_name : 'Not assigned'}</p>
          <p><span className="task_detail_header">CATEGORY</span><br/>{task.work_category_name ? task.work_category_name : 'Not categorized' }</p>
          <p><span className="task_detail_header">PATIENT</span><br/>{task.patient_name ? patient_link : 'No patient'}</p>
        </div>
        {due_date}
        <h4 className="task_header_title">{task.name}</h4>
        <p className="task_description">{task.description}</p>


        <button onClick={e => this.editTask(task)} className="btn btn-xs btn-default">Edit Details</button>

      </div>
    );
  }

  renderActions() {
    let {task} = this.state;
    let total_actions = task.actions.length;
    let completed_actions = task.actions.filter(a => a.completed).length;
    return (
      <div className="task_section_holder">
        <div className="task_section_header">
          <h3>Actions</h3>
          <p className="task_section_status"><strong>{completed_actions}/{total_actions}</strong> Actions Completed </p>
        </div>
        <CurrentTaskActions name="Action" list={task.actions} onChange={a => this.onChangeActions(a)} />

      </div>
    )
  }



  renderTimecards() {
    let {task} = this.state;
    let total_hours = task.time_cards.reduce(((acc, tc) => tc.hours + acc), 0);
    return (
      <div className="task_section_holder">
        <div className="task_section_header">
          <h3>Time Cards</h3>
          <p className="task_section_status"><strong>{total_hours}</strong> Total Hours</p>
        </div>
        <div className="task_timecard_list">
          {task.time_cards.map(tc => {
            let date = moment(tc.date).format('LL');
            return (
              <div key={tc.id} className="task_timecard_item">
                <span>{date}</span>
                <span>{tc.creator_name}</span>
                <span>{tc.hours} hours</span>
                <div className="btn-group">
                  <button onClick={e => this.editTimeCard(tc)} className="btn btn-default btn-sm"><i className="fa fa-edit"></i></button>
                  <button onClick={e => this.deleteTimeCard(tc)} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button>
                </div>
                <p>{tc.details}</p>
              </div>
            );
          })}
        </div>
        <button onClick={e => this.addTimeCard()} className="btn btn-success btn-sm">Add Time Card</button>

      </div>
    )
  }

  renderTimeCardForm(time_card) {
    return (
      <div className="outer_holder outer_holder_form">
        <div className="inner_holder">
          <TimeCardForm time_card={time_card} onSubmit={t => this.onChangeTimeCard(t)} onCancel={e => this.onCancelEditTimeCard(e)} />
        </div>
      </div>
    )
  }

  renderActionForm(action) {
    return <ActionForm action={action} onSubmit={a => this.onChangeAction(a)} onCancel={e => this.onCancelEditAction(e)} />
  }


  render() {
    let {return_path} = this.props;
    let {current, current_action, current_time_card} = this.state;
    if (current) return this.renderTaskForm(current);
    if (current_action) return this.renderActionForm(current_action);
    if (current_time_card) return this.renderTimeCardForm(current_time_card);
    return (
      <div className="outer_holder">
        <a href={return_path}><i className="fa fa-chevron-left"></i> Return</a>

        <div className="inner_holder task_show_inner_holder">
          {this.renderState()}
          {this.renderCreator()}
          {this.renderTaskHeader()}

          {this.renderActions()}
          {this.renderTimecards()}
        </div>
        <button className="task_delete_link" onClick={e => this.deleteTask()}>Delete Task</button>
      </div>
    );
  }
}



class ActionForm extends Component {
  constructor(props) {
    super(props);
    let {action} = props;
    this.state = {action};
    this.onChange = this.onChange.bind(this);
  }

  submit(e) {
    let {action} = this.state;
    let {onSubmit} = this.props;
    this.setState({submitting: true});
    onSubmit(action);
  }

  onChange(action) {
    this.setState({action});
  }

  render() {
    let {onCancel} = this.props;
    let {action, submitting} = this.state;
    let fields = [{name: 'name', required: true, label: 'Action to Take:'}];

    let form_action = action.id ? 'Edit' : 'New';
    return (
      <div className="outer_holder outer_holder_form">
        <div className="inner_holder">
          <h4>{form_action} Action</h4>
          <hr/>
          <Form model_name="Action" model={action} fields={fields} onChange={this.onChange} onSubmit={e => this.submit(e)} onCancel={onCancel} submitting={submitting} />
        </div>
      </div>
    );
  }
}



class CurrentTaskActions extends TaskActions {
  renderTitle() {
    return null;
  }

  toggleActionState(action) {
    let {list, onChange} = this.props;
    action.completed = !action.completed;
    let index = list.findIndex(a => a.id === action.id);
    list[index] = action;
    onChange(list);
  }


  renderItem(action) {
    let value = !!action.completed;
    let action_text_style = value ?  'task_action_text action_completed' : 'task_action_text'

    return (

      <div key={action.id} className="task_action_item">
        <input type="checkbox" checked={value} value={value} onChange={e => this.toggleActionState(action)} />
        <span className={action_text_style}>{action.name}</span>
        <div className="btn-group">
          <button onClick={e => this.edit(action)} className="btn btn-default btn-sm"><i className="fa fa-edit"></i></button>
          <button onClick={e => this.delete(action)} className="btn btn-default btn-sm"><i className="fa fa-trash"></i></button>
          <button onClick={e => this.moveActionUp(action)} className="btn btn-default btn-sm"><i className="fa fa-arrow-up"></i></button>
          <button onClick={e => this.moveActionDown(action)} className="btn btn-default btn-sm"><i className="fa fa-arrow-down"></i></button>
        </div>
      </div>
    );
  }

}
