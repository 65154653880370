import React, {Component} from 'react';
import Form from './form';
import request from '../request';
import GroupsContainer from './groups_container';
import ReferenceStarsRating from './reference_stars_rating';
import OpenAISummary from './openai_summary';
import Loading from './loading';
import Markdown from './markdown';
import * as Inflector from 'inflected';

import {PopulationSizes, TrialSearchKey, InterventionSearchKey, ReferenceStudyTypes, ReferenceTherapyTypes, ReferenceTypes, ReferenceStatuses, ReferenceResultsData} from '../constants.js.erb';

export default class ReferencesForm extends Component {
  constructor(props) {
    super(props);
    let {reference} = props;
    this.state = {reference, errors: {}};
    this.onChangeField = this.onChangeField.bind(this);
    this.submit = this.submit.bind(this);
    this.onChangeOpenaiSummary = this.onChangeOpenaiSummary.bind(this);
    this.onOpenaiSummaryError = this.onOpenaiSummaryError.bind(this);
    this.onChangeOpenaiLoading = this.onChangeOpenaiLoading.bind(this);
  }

  submit(e) {
    e.preventDefault();
    this.setState({submitting: true});
    let {reference} = this.state;

    let url = "/admin/references";
    let method;


    if (reference.id) {
      url += "/" + reference.id;
      method = "PUT"
    } else {
      method = "POST"
    }

    let model = {};
    let fields = this.fields();
    for (let field of fields) {
      model[field.name] = reference[field.name];
    }

    request(method, url, {reference: model}).then(reference => {
      let {onCreate} = this.props;
      if (onCreate) {
        onCreate(reference);
        return;
      } else if (method === 'POST') {
        window.location = "/admin/references/" + reference.id;
      }
      this.setState({errors: [], submitting: false, abstract_changed: false});
    }).catch(({errors}) => {
      this.setState({errors, submitting: false});
    });
  }

  onChangeField(name, value) {
    let {reference} = this.state;
    reference[name] = value;
    let changes = {reference}

    if (name === 'abstract_text') {
      changes.abstract_changed = true;
    }
    this.setState(changes);
  }

  onChangeOpenaiLoading(loading) {
    let {reference} = this.state;
    reference.data.requesting_summary = loading;
    this.setState({reference});
  }

  onChangeOpenaiSummary(summary) {
    let {reference} = this.state;
    reference.data.openai_summary = summary;
    this.setState({reference});
  }

  onOpenaiSummaryError(message, code) {
    let {reference} = this.state;
    reference.data.openai_summary_error_message = message;
    reference.data.openai_summary_error_code = code;
    this.setState({reference});
  }

  fields() {
    let {abstract_changed} = this.state;
    let extraOptions = ReferenceStarsRating.EXTRA_OPTIONS;

    return [
      {name: 'status', type: 'select', collection: ReferenceStatuses},
      {name: 'results_data', type: 'select', label: 'Scoreable Results Data?', collection: ReferenceResultsData, allowBlank: true, hint: 'Must set to rate or add notes'},
      {name: 'reference_type', type: 'select', collection: ReferenceTypes},
      {name: 'name', label: 'Title', hint: 'Source Name', required: true},
      {name: 'doi', label: 'DOI', type: 'string-with-link', showLink: true, linkTemplate: 'https://doi.org/{id}'},
      {name: 'source_link'},
      {name: 'reference_year'},
      {name: 'study_trial_id', label: 'Study NCT', type: 'remote-select2', src: "/admin/trials/as_options.json",
       search_key: TrialSearchKey, hint: 'This is the NCT on which this reference and its data are based.', showLink: true, linkTemplate: '/admin/trials/{id}'},

      {name: 'study_type', type: 'select', collection: ReferenceStudyTypes},
      {name: 'abstract_text', type: 'text', rows: 6, autosize: true},
      {name: 'auto_import', label: 'Imported automatically', type: ImportedInformation,  disabled: true, visible: r => r.auto_import},
      {name: 'summary', type: SummaryComponent, unsavedChanges: abstract_changed, onChangeOpenaiSummary: this.onChangeOpenaiSummary, onOpenaiSummaryError: this.onOpenaiSummaryError, onChangeOpenaiLoading: this.onChangeOpenaiLoading}

    ];
  }

  render() {
    let {reference, errors, submitting} = this.state;
    let {onCancel} = this.props;
    return (
      <>
        <div className="well">
          <Form id="references_form" model_name="Reference" prefix="reference" fields={this.fields()} model={reference} errors={errors} submitting={submitting} onChangeField={this.onChangeField} onSubmit={this.submit} onCancel={onCancel} />
        </div>
      </>
    )
  }
}

class SummaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {current: 'openai'}
  }

  selectTab(e, tab) {
    e.preventDefault();
    this.setState({current: tab});
  }

  renderTabs() {
    let {current} = this.state;
    let {model} = this.props;

    let scitldr_el;
    if (model.data.scitldr_summary && model.data.scitldr_summary.length > 0) {
      scitldr_el = <li role="presentation" className={current == 'scitldr' ? 'active' : ''}><a href="#"  onClick={e => this.selectTab(e, 'scitldr')} className="btn btn-link">SciTLDR</a></li>
    }
    return (
      <ul className="nav nav-tabs">
        <li role="presentation" className={current == 'openai' ? 'active' : ''}><a href="#"  onClick={e => this.selectTab(e, 'openai')} className="btn btn-link">OpenAI GPT</a></li>
        {scitldr_el}
      </ul>
    )
  }

  renderContent() {
    let {current} = this.state;

    if (current === 'openai') {
      let {model} = this.props;
      let {unsavedChanges, onChangeOpenaiSummary, onOpenaiSummaryError, onChangeOpenaiLoading} = this.props.field;
      if (!model.abstract_text || model.abstract_text.length === 0) return <p>Add Abstract Text to Generate Summary.</p>
      if (unsavedChanges) return <p>The Abstract Text has changed. Please save the changes.</p>
      let url = `/admin/references/${model.id}/openai_summary`
      return <OpenAISummary data={model.data} url={url} onChange={onChangeOpenaiSummary} onError={onOpenaiSummaryError} onChangeLoading={onChangeOpenaiLoading}  />
    } else {
      return <SciTLDRSummary {...this.props} />
    }
  }

  render() {
    let reference = this.props.model;
    if (!reference.id || !reference.data) return null;

    return (
      <div>
        {this.renderTabs()}
        <div className="reference_summary_box">
          {this.renderContent()}
        </div>
      </div>
    )
  }
}

class SciTLDRSummary extends Component {
  render() {
    let {model} = this.props;
    return (
      <div>
        <label>Generated Summary</label>
        <Markdown text={model.data.scitldr_summary} />
      </div>
    )
  }
}

class ImportedInformation extends Component {
  render() {
    let {model} = this.props;
    let label = Inflector.titleize(model.import_source);
    return (
      <p className="help-text"><i>Imported from <b>{label}</b></i></p>
    )
  }
}
