import Slektr from 'slektr';
import 'slektr/dist/slektr.css';


export default {
  setup: function() {
    window.addEventListener('load', function () {
      var elements = document.getElementsByClassName('slektr');
      if (!elements) return;
      for (let el of elements) {
        if (el.tagName.toLowerCase() === 'select' && !el.dataset.slektr) {
          let config = {allowBlank: true};

          if (el.dataset.remote_options_src || el.dataset.remoteOptionsSrc) {
            config.initOptionsCallback = initCallback;
            config.searchOptionsCallback = searchCallback
          }

          if (el.dataset.remote_options_search_src || el.dataset.remoteOptionsSearchSrc) {
            config.remoteOptionsSrc = el.dataset.remote_options_search_src || el.dataset.remoteOptionsSearchSrc;
            config.searchOptionsCallback = searchCallback;
          }


          if (el.dataset.link_template || el.dataset.linkTemplate) {
            config.renderValueCallback = renderLink;
          }

          new Slektr(el, config);
        }
      }
    });
  }
}


function initCallback(slektr) {
  if (slektr.config.remoteOptionsSrc && slektr.config.remoteOptionsSrc.length > 0) {
    return new Promise(function(resolve) {
      var url = slektr.config.remoteOptionsSrc;

      if (slektr.config.multiple && slektr.value.length > 0) {
        url = url + '?ids=' + slektr.value.join(',')
      } else {
        url = url + '?ids=' + slektr.value;
      }

      fetch(url).then(function(response) {
        if(response.ok) {
          response.json().then(({results}) => {
            let data = results.map(r => ({value: r.id, label: r.text}))
            resolve(data)
          });
        }
      });
    });
  }

  // Return a dummy promise otherwise
  return Promise.resolve();
}

function searchCallback(text, slektr) {
  if (slektr.config.remoteOptionsSrc && slektr.config.remoteOptionsSrc.length > 0) {
    return new Promise(function(resolve) {
      let query_param = slektr.config.remoteOptionsSearchName || 'query';
      var url = `${slektr.config.remoteOptionsSrc}?q[${query_param}]=${text}`;
      url = encodeURI(url);

      fetch(url).then(function(response) {
        if(response.ok) {
          response.json().then(({results}) => {
            let data = results.map(r => ({value: r.id, label: r.text}))
            resolve(data)
          });
        }
      });
    });
  }

  // Return a dummy promise otherwise
  return Promise.resolve();
}

function renderLink(option, slektr) {
  let link = slektr.config.linkTemplate.replace('$ID', option.value);
  let target;
  if (slektr.config.linkTarget) {
    target = "target=\"${slektr.config.linkTarget}\"";
  }
  return `<a href="${link}" ${target}>${option.label}</a>`;
}
