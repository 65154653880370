import React, {Component} from 'react';
import SavePanel from './save_panel';
import Form from '../form';
import request from '../../request';
import joinReact from '../join_react';

import * as Inflector from 'inflected';

export default class Notes extends Component {
  constructor(props) {
    super(props);
    let {notes} = props.population.data;
    this.state = {notes};

    this.submit = this.submit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.resetSaveState = this.resetSaveState.bind(this);
  }

  componentDidMount() {
    window.addEventListener('population-save-request', this.submit, false);
  }

  componentWillUnmount() {
    window.removeEventListener('population-save-event', this.submit, false);
  }


  submit() {
    let {population, onChange} = this.props;
    let {notes} = this.state;
    population.data.notes = notes;
    this.setState({submitting: true});
    onChange(population.data).then(e => {
      this.setState({submitting: false});
    });
  }

  onChange(field, value) {
    this.setState({notes: value});
  }

  resetSaveState() {
    this.setState({save_error: false, save_success: false})
  }

  renderForm() {
    let {submitting} = this.state;
    let fields = [{name: 'notes', type: 'text', rows: 9}];
    return <Form model={this.state} fields={fields} onChangeField={this.onChange} onSubmit={this.submit} singleton model_name="Note" submitting={submitting} />
  }

  renderSections() {
    let {population} = this.props;

    return SECTIONS.map(({id, title}) => {

      let section_data = population.data[id];
      let content;
      if (!section_data || !section_data.notes || section_data.notes.length === 0) {
        content = <p className="small-text"><i>No Note Added.</i></p>;
      } else {

        let grouped_notes = Object.groupBy(section_data.notes, note => note.type);
        content = Object.keys(grouped_notes).map(section_id => {
          let notes = grouped_notes[section_id].map(n => n.text);
          let title = section_id !== 'undefined' ? Inflector.titleize(section_id) : '';
          let text = joinReact(notes, <br />);
          return (
            <p key={section_id || 'global'} className="small-text">
              <strong>{title}</strong><br />
              {text}
            </p>
          )
        })
      }

      return (
        <div className="population-note-section" key={id}>
          <h5>{title}</h5>
          {content}
        </div>
      );
    })
  }

  render() {
    return (
      <div>
        <div className="well">
          <div className="row">
            <div className="col-md-6">
              {this.renderForm()}
            </div>
            <div className="col-md-6">
              {this.renderSections()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const SECTIONS = [
  {id: 'cancer_type', title: "Cancer Types"},
  {id: 'patient_details', title: "Patient Details"},
  {id: 'prior_therapy', title: "Prior Therapy"},
  {id: 'recent', title: 'Recent'},
  {id: 'concurrent', title: 'Concurrent'},
  {id: 'genes_markers', title: "Genes and Diagnostics"}
]
