import React, {Component} from 'react';
import TrialShowEmbedded from './trial_show_embedded';

export default class TrialGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {trials} = this.props;
    return (
      <div id="trials_list">
        {trials.map(t => <TrialShowEmbedded key={t.id} trial={t} />)}
      </div>
    );
  }
}
