/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import QuickAddPerson from '../quick_add_person';
import SignoutReminder from '../signout_reminder';
import DatePickerAdapter from '../datepicker_adapter';
import DisableNumberInputScroll from '../disable_scroll_numbers_input';
import ReferenceForm from '../reference_form';
import TaskStatusCheckbox from '../src/task_status_checkbox';
import WatchPatientWidget from '../watch_patient_widget';
import PatientWidgetSelectPatient from '../patient_widget_select_patient';
import SlektrLoader from '../slektr_setup';
import ViewerjsLoader from '../viewerjs_loader'

var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

QuickAddPerson.setup();
SignoutReminder.setup();
DatePickerAdapter.setup();
ReferenceForm.setup();
WatchPatientWidget.setup();
PatientWidgetSelectPatient.setup();
TaskStatusCheckbox.setup();
ViewerjsLoader.setup();

// Disable scroll on inputs with type=number
DisableNumberInputScroll.setup();

SlektrLoader.setup();

function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

window.generateUUID = generateUUID;
