import React, {Component} from 'react';

import request from '../../request';
import Loading from '../loading';

export default class ActivePatients extends Component {
  constructor(props) {
    super(props);
    this.state = {active_patients: []}
  }

  componentDidMount() {
    this.fetchActivePatients();
  }

  fetchActivePatients() {
    this.setState({loading: true});
    let url = "/admin/active_patients"
    request('GET', url).then(active_patients => {
      this.setState({active_patients, loading: false});
    });
  }


  removeActivePatient(patient) {
    let url = "/admin/active_patients/" + patient.id;
    request("DELETE", url).then(r => {
      let {active_patients} = this.state;
      active_patients = active_patients.filter(p => p.id !== patient.id)
      this.setState({active_patients});
    });

  }

  addTrial(patient, collection) {
    this.setState({adding_to_collection: collection.id});
    let {trial_id} = this.props;
    let url = "/admin/patients/" + patient.id + "/collections/" + collection.id + "/trials"
    request('POST', url, {trial_id}).then(({success, collection_trial}) => {

      // Add the trial to the list
      let {active_patients} = this.state;
      active_patients = active_patients.map(p => {
        if (p.id === patient.id) {
          let index = p.collections.findIndex(c => c.id === collection.id)
          p.collections[index].trials.push(collection_trial);
        }

        return p;
      });
      this.setState({active_patients, adding_to_collection: null});
    });
  }

  deleteTrialFromCollection(patient, collection) {
    if (!confirm('Are you sure you want to remove this trial from this collection?')) return;

    let {trial_id} = this.props;
    let collection_trial = collection.trials.find(t => t.trial_id === trial_id)
    console.log("Colection", collection_trial);
    let url = "/admin/patients/" + patient.id + "/collections/" + collection.id + "/trials/" + collection_trial.id
    request('DELETE', url).then(r => {

      // remove the trial to the list
      let {active_patients} = this.state;
      active_patients = active_patients.map(p => {
        if (p.id === patient.id) {
          let collection_index = p.collections.findIndex(c => c.id === collection.id)
          let index = p.collections[collection_index].trials.findIndex(t => t.id === collection_trial.id)
          p.collections[collection_index].trials.splice(index, 1);
        }

        return p;
      });
      this.setState({active_patients, adding_to_collection: null});

    })
  }

  toggleActivePatient(patient) {
    let {onPatientSelected} = this.props;
    if (this.state.active_patient && this.state.active_patient.id === patient.id) {
      this.setState({active_patient: undefined});
      onPatientSelected(undefined);
    } else {
      this.setState({active_patient: patient, adding_to_collection: null});
      onPatientSelected(patient.id);
    }
  }

  renderNoCollections() {
    return (
      <div id="no-collections">
        No collections found
      </div>
    )
  }


  renderCollectionsList(patient) {
    let {adding_to_collection} = this.state;

    if (patient.collections.length === 0) return this.renderNoCollections();
    let {trial_id, onShowCollection} = this.props;



    return patient.collections.map(c => {

      let status_icon, action_button;
      if (adding_to_collection === c.id) status_icon = <i className="glyphicon glyphicon-refresh glyphicon-spin" />
      if (c.trials.findIndex(t => t.trial_id === trial_id) !== -1) {
        action_button = <button className="btn btn-default" onClick={e => this.deleteTrialFromCollection(patient, c)} title="Remove from Collection"><i className="fa fa-check-circle" style={{color: 'green'}}/></button>
      } else {
        action_button = <button className="btn btn-default" onClick={e => this.addTrial(patient, c)} title="Add to Collection"><i className="fa fa-minus-circle" style={{color: 'grey'}}/></button>
      }

      return (
        <div key={c.id} className="listed_collection">
        {action_button} <button className="btn btn-link" onClick={e => onShowCollection(c.id)}> ({c.trials.length}) {c.name}</button>
        </div>
      );
    })


  }

  renderCollections(patient) {
    return (
      <div className="patient-collections">
        {this.renderCollectionsList(patient)}
      </div>
    );

  }

  renderPatientContent(patient) {
    let {selected_patient_id, onShowPatientTrialData} = this.props;
    if (selected_patient_id !== patient.id) return null;

    let link = "/admin/patients/" + patient.id;
    return (
      <div className="patient_details_box">
        <div className="btn-group">
          <a href={link} className="btn btn-xs btn-default" target="_blank"><i className="fa fa-external-link-alt" /> View Patient</a>
          <button className="btn btn-xs btn-default" onClick={onShowPatientTrialData}>Collection Details</button>
        </div>

        <h4 className="patient_widget_header_text">Collections</h4>
        {this.renderCollections(patient)}
        <button className="btn btn-xs btn-default" onClick={e => this.removeActivePatient(patient)}><i className="fa fa-times red" /> Remove From Active Patients</button>
      </div>
    );
  }

  renderNoPatients() {
    return (
      <div id="no-patients">
        No patients found
      </div>
    )
  }


  render() {
    let {onShowAllPatients} = this.props;
    let {active_patients, loading} = this.state;
    if (loading) return <Loading />
    if (active_patients.length === 0) return this.renderNoPatients();

    return (
      <div>
        <div className="patient_list_header">
          <h4 className="patient_widget_header_text initial_header">Your Patients</h4>
          <button className="btn btn-xs btn-default" onClick={onShowAllPatients}><i className="fa fa-user-plus" /> Add a Patient</button>
        </div>
        {active_patients.map(patient => {

        return (
          <div key={patient.id} className="active-patient">
            <a className="active_patient_name" onClick={e => this.toggleActivePatient(patient)}>
              {patient.name}
            </a>
            {this.renderPatientContent(patient)}
          </div>
        );
        })}
      </div>
    )
  }

}
