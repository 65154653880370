import React, {Component} from 'react';
import {LocationCancerCenters} from './location_cancer_centers';
import request from '../request';
import {TrialHeader, TrialBottomNav} from './trial_partials';
import {RecruitmentStatus} from '../constants.js.erb';

export class TrialLocations extends Component {
  constructor(props) {
    super(props);
    let {locations} = props;
    this.state = {locations, selected_location: null}
  }

  locationChanged(location) {
    let {locations} = this.state;
    let index = locations.findIndex(l => l.id === location.id);
    locations[index] = location;
    this.setState({locations});
  }

  releaseLocation(location) {
    let confirmation = "If you release this trial location, you will lose any information you've added, including contact information and linked cancer center. You may be able to re-capture, but it will only contain information from clinical trials site. Are you sure you want to release?"
    if (!confirm(confirmation)) return;

    let url = "/admin/trials/" + this.props.trial.id + "/locations/" + location.id;
    request("DELETE", url).then(r => {
      let locations = this.state.locations;
      let index = locations.findIndex(l => l._hash === location._hash);
      location = Object.assign({}, locations[index]);
      location.id = undefined;
      location.cancer_centers_count = undefined;
      locations[index] = location;
      this.setState({locations});
    });
  }

  deleteLocation(location, index) {
    let confirmation = "If you delete this trial location, you will lose any information you've added, including contact information and linked cancer center. Are you sure you want to delete?"
    if (!confirm(confirmation)) return;

    let url = "/admin/trials/" + this.props.trial.id + "/locations/" + location.id;
    request("DELETE", url).then(r => {
      let locations = this.state.locations;
      locations.splice(index, 1);
      this.setState({locations});
    });
  }

  captureLocation(hash) {
    let url = "/admin/trials/" + this.props.trial.id + "/locations/capture?from_hash=" + hash;
    request("PUT", url).then(location => {
      let locations = this.state.locations;
      let location_index = locations.findIndex(l => l._hash === location._hash);
      locations[location_index] = location;
      this.setState({locations});
    });
  }


  showLocation(selected_location) {
    let scroll_y = window.scrollY;
    this.setState({selected_location, scroll_y});
  }

  closeLocation() {
    let scroll_y = this.state.scroll_y;
    if (scroll_y) {
      window.setTimeout(() => window.scrollTo(0, scroll_y), 5);
    }
    this.setState({selected_location: null, scroll_y: null});
  }

  renderLocation(location) {

    let {trial, cancer_center_search_param_name} = this.props;
    let title = location.name + " - " + location.zip + " (" + location.country_name + ")";

    return (
      <div>
        {this.renderTrialInformation()}
        <div className="well">
          <button className="btn btn-default pull-right" onClick={e => this.closeLocation()}>Back to locations</button>
          <h3><b>{title}</b></h3>
          <div className="clearfix"></div>
          <br/>
          <hr/>
          <br/>
          <LocationCancerCenters trial_id={trial.id} trial_location_id={location.id} search_param_name={cancer_center_search_param_name} onChange={l => this.locationChanged(l)}/>
        </div>
      </div>
    )
  }


  renderRow(location, index) {

    let label, editUrl, persistentEl, showLinksBtn, facilityEl,  status_el, rowClassName;

    if (location.id && location.verified) {
      let date = moment(location.verified_at).format('LL')
      label = <span className="label label-success">VERIFIED {date}</span>
    } else if (location.id && location.custom) {
      label = <span className="label label-info">CUSTOM</span>
    }

    if (location.id) {
      editUrl = "/admin/trials/" + this.props.trial.id + "/locations/" + location.id + "/edit";
      showLinksBtn = <button onClick={e => this.showLocation(location)} className="btn btn-link">{location.cancer_centers_count} linked</button>
      facilityEl = <a href={editUrl}>{location.name}</a>
      if (location.custom) {
        persistentEl = <button onClick={e => this.deleteLocation(location, index)} className="btn btn-link">Delete</button>
      }
    } else {
      facilityEl = location.name
    }

    if (location.country !== 'US') {
      rowClassName='subtle';
    }

    if (location.status) {
      status_el = RecruitmentStatus[location.status];
    } else {
      let {trial} = this.props;
      status_el = <span>{RecruitmentStatus[trial.overall_status]} <i>(from trial)</i></span>
    }

    return (
      <tr key={index} className={rowClassName}>
        <td>{facilityEl}</td>
        <td>{location.country}</td>
        <td>{location.state}</td>
        <td>{location.city}</td>
        <td>{location.zip}</td>
        <td>{status_el}</td>
        <td>{label}<br/>{persistentEl}</td>
        <td>{showLinksBtn}</td>
      </tr>
    )
  }

  renderHeader() {
    return (
      <thead>
        <tr>
          <th>Name</th>
          <th>Country</th>
          <th>State</th>
          <th>City</th>
          <th>Zip</th>
          <th>Recruitment Status</th>
          <th>Verification Status</th>
          <th>Link</th>
        </tr>
      </thead>
    )
  }


  renderTrialInformation() {
    let {trial} = this.props;
    let {locations} = this.state;
    let trial_path = "/admin/trials/" + trial.id + "/edit";
    let new_location_path = "/admin/trials/" + trial.id + "/locations/new";

    return(
      <div>
        <a href={new_location_path} className="btn btn-success">New Location</a>
        <hr/>
      </div>
    );
  }

  render() {
    let {trial} = this.props;
    let {locations, selected_location} = this.state;
    if (selected_location) return this.renderLocation(selected_location);

    return (
      <div>
        <TrialHeader trial={trial} />
        {this.renderTrialInformation()}

        <table className="table table-striped table-condensed">
          {this.renderHeader()}
          <tbody>
            {locations.map((l, index) => this.renderRow(l, index))}
          </tbody>
        </table>
        <TrialBottomNav trial={trial} action="locations"/>
      </div>
    );
  }
}
