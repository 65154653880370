import React, {Component} from 'react';
import Form from '../form';
import Loading from '../loading';
import {EventPreview, EventLabels} from './event_modal';
import ChangelogViewer from './changelog_viewer';
import request from '../../request';

export default class EventViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {show_modal: false};
  }
  componentDidMount() {
    let el = $(this.refs.modal_container);
    el.modal({show: false});
    el.on('hide.bs.modal', () => {
      this.close();
    });
  }


  closeModal() {
    $(this.refs.modal_container).modal('hide');
  }

  close() {
    this.setState({current_event: null});
  }


  showEvent(journey_tracker_event_id) {
    this.setState({loading: true}, () => $(this.refs.modal_container).modal('show'));

    let url = '/admin/patients/' + gon.patientId + '/journey_tracker_events/' + journey_tracker_event_id;
    request('GET', url).then(current_event => {
      this.setState({current_event, loading: false});
    });

  }

  renderContent() {
    let {loading, current_event} = this.state;
    let {patient} = this.props;
    if (loading) return <Loading />;
    if (!current_event) return null;

    return (
      <div>
        <EventPreview patient={patient} event={current_event} />
        <div className="event-review-changes">
          <p><b>Changes</b></p>
          <ChangelogViewer fields_changelog={current_event.changelog} />
        </div>
      </div>
    );

  }

  renderModal() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={e => this.closeModal()}><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              {this.renderContent()}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" onClick={e => this.closeModal()}>Close</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let {path, fields, changelog} = this.props;
    let rows = [];

    // Check for the field id, which means record creation
    if (changelog[path + ':id']) {
      let {date, event_id, event_type} = changelog[path + ':id'];
      let event_name = EventLabels[event_type];
      let formatted_date = moment(date).format('LLL');
      let action = <button className="btn btn-default btn-xs pull-right" onClick={e => this.showEvent(event_id)}>See Details</button>;
      rows.push(<li key='id'><b>Record created</b> via <b>{event_name}</b> on {formatted_date} {action}<div className="clearfix"></div></li>);
    }

    for (let field of fields) {
      let key = path + ':' + field.name;
      if (changelog[key]) {
        let {date, event_id, event_type} = changelog[key];
        let event_name = EventLabels[event_type];
        let formatted_date = moment(date).format('LLL');
        let field_name = Form.getFieldLabel(field);
        let action = <button className="btn btn-default btn-xs pull-right" onClick={e => this.showEvent(event_id)}>See Details</button>;
        rows.push(<li key={key}><b>{field_name}</b> changed via <b>{event_name}</b> on {formatted_date} {action}<div className="clearfix"></div></li>);
      }
    }

    return (
      <div>
        <p><b>Changelog</b></p>
        <ul className="jt_changelog_ul">{rows}</ul>
        {this.renderModal()}
      </div>
    );
  }
}
