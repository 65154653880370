import React, {Component} from 'react';

import JourneyTable from './journey_table';
import NeestedRecord from './neested_record';
import OptionsCache from './options_cache';
import * as DateHelpers from './date_helpers';
import {globalDefaults} from './journey_data';
import {ComorbidConditionSearchKey} from '../../constants.js.erb';

export default class ComorbidConditionsList extends JourneyTable {
  constructor(props) {
    super(props);
    this.state = {};
    this.cancerTypeCache = new OptionsCache({source: 'patient_cancer_types', onChange: e => this.forceUpdate()});
    this.comorbidCache = new OptionsCache({source: 'comorbid_conditions', onChange: e => this.forceUpdate()})
    this.preloadNested();
  }

  componentDidUpdate() {
    this.preloadNested();
  }

  preloadNested() {
    let {comorbid_conditions} = this.props.data;
    let comorbid_condition_ids = comorbid_conditions.map(l => l.comorbid_condition_id);
    this.comorbidCache.preload(comorbid_condition_ids);
  }

  addComorbidCondition() {
    let {data, settings} = this.props;
    let current = globalDefaults({}, data, settings);
    this.setState({current});
  }

  delete(comorbid_condition) {
    if (!this.validateDeletion('comorbid_condition_id', comorbid_condition.id)) return;
    if (!confirm('Are you sure?')) return;

    let {onDelete} = this.props;
    let sectionLabel = buildSectionLabel(comorbid_condition, this.comorbidCache);
    onDelete(BASE_PATH, sectionLabel, comorbid_condition);
  }


  show(current) {
    this.setState({current});
  }

  renderForm() {
    let {readOnly, patient, data, fields_changelog, onAddBatch, onEditBatch} = this.props;
    let {current} = this.state;
    let props = {readOnly, patient, data, fields_changelog, onAddBatch, onEditBatch, record: current};
    props.onClose = e => this.setState({current: null});
    props.comorbidCache = this.comorbidCache;
    return <ComorbidCondition {...props}  />
  }

  renderList() {
    let {data, readOnly} = this.props;
    let {comorbid_conditions} = data;

    return (
      <div>
        <button className="btn btn-success pull-right" onClick={e => this.addComorbidCondition()} disabled={readOnly}>Add Comorbid Condition</button>
        <div className="clearfix"></div>
        <p><b>Comorbid Conditions</b></p>
        <table className="table table-condensed table-striped jt-log-table" id="comorbid_conditions_list">
          <thead>
            <tr>
              <th>Comorbid Condition</th>
              <th>Date of Diagnosis</th>
              <th>Current State</th>
              <th>End Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {comorbid_conditions.map(comorbid => {
              let action_comorbid_conditionel = readOnly ? 'Show' : 'Edit';
              let comorbid_condition = this.comorbidCache.get(comorbid.comorbid_condition_id);
              let delete_el;
              if (!readOnly) {
                let id = "delete_comorbid_condition-" + comorbid.id;
                delete_el = <button id={id} className="btn btn-default btn-sm" onClick={e => this.delete(comorbid)}><i className="fa fa-trash"></i></button>
              }

              return(
                <tr key={comorbid.id}>
                  <td>{comorbid_condition.text}</td>
                  <td>{DateHelpers.formatEstimated(comorbid)}</td>
                  <td>{STATES[comorbid.current_state]}</td>
                  <td>{DateHelpers.format(comorbid, {field: 'end_date'})}</td>
                  <td className="actions">
                    <div className="btn-group">
                      <button className="btn btn-default btn-sm" onClick={e => this.show(comorbid)}>{action_comorbid_conditionel}</button>
                      {delete_el}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    );

  }
}

const BASE_PATH = 'comorbid_conditions';
function buildSectionLabel(record, comorbidCache) {
  let comorbid_condition = comorbidCache.get(record.comorbid_condition_id);
  return ['Comorbid Condition', comorbid_condition.text];
}

class ComorbidCondition extends NeestedRecord {
  getModelName() {
    return "Comorbid Condition";
  }

  getBasePath() {
    return BASE_PATH;
  }

  getSectionLabel() {
    let {record} = this.state;
    return buildSectionLabel(record, this.props.comorbidCache);
  }

  onChange(field_name, value, meta) {
    super.onChange(field_name, value, meta);
    let {record} = this.state;
    if (record.comorbid_condition_id) {
      this.props.comorbidCache.preload([record.comorbid_condition_id]);
    }
  }


  formFields() {
    let comorbidConditionValueLabelCallback = c => {
      let {comorbidCache} = this.props;
      let comorbid_condition = comorbidCache.get(c.comorbid_condition_id);
      return comorbid_condition.text;
    };


    let comorbid_filters = [
      {name: 'name', required: true},
      {name: 'category', type: 'select', collection: gon.comorbidCategories}
    ];

    return [
      {name: 'comorbid_condition_id', label: 'Comorbid Condition', required: true, type: 'filtered-select', src: "/admin/comorbid_conditions/as_options.json", search_key: ComorbidConditionSearchKey, valueLabelCallback: comorbidConditionValueLabelCallback, filters: comorbid_filters},
      {name: 'date', type: 'date', label: 'Date of diagnosis'},
      {name: 'is_date_estimated', type: 'bool', label: 'Is this date estimated?'},
      {name: 'current_state', label: 'Current state of condition', type: 'select', collection: STATES},
      {name: 'end_date', type: 'date', label: 'End Date, if resolved'},
      {name: 'description', type: 'text'}
    ];
  }

}



const STATES = {
  chronic_uncontrolled: 'Active, Chronic - Uncontrolled',
  chronic_controlled_with_st: 'Active, Chronic - Controlled by Systemic Treatment',
  chronic_controlled_without_st: 'Active, Chronic - Controlled without Systemic Treatment',
  chronic_inactive: 'Inactive, Chronic',
  active_uncontrolled: 'Active, Acute - Uncontrolled',
  active_controlled_with_st: 'Active, Acute - Controlled by Systemic Treatment',
  active_controlled_without_st: 'Active, Acute - Controlled without Systemic Treatment',
  resolved: 'Inactive, Acute/Resolved'
}
