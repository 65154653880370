import React, {Component} from 'react';
import Form from './form';
import request from '../request';

export default class PersonReviewForm extends Component {
  constructor(props) {
    super(props);
    let {review} = props;
    this.state = {review};
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
  }


  submit(e) {
    e.preventDefault();
    let {review} = this.state;
    this.setState({submitting: true});
    let url =  `/admin/persons/${review.person_id}/person_reviews`;
    let method;


    if (review.id) {
      url += "/" + review.id;
      method = "PUT"
    } else {
      method = "POST"
    }

    request(method, url, {person_review: review}).then((review) => {
      window.location = `/admin/persons/${review.person_id}`;
      this.setState({errors: [], submitting: false});
    }).catch(({errors}) => {
      this.setState({errors, submitting: false});
    });
  }

  onChange(review) {
    this.setState({review});
  }


  render() {
    let {review, submitting} = this.state;
    return <Form fields={FIELDS} model={review} onChange={this.onChange} onSubmit={this.submit} submitting={submitting} model_name="Review" />
  }
}

const FIELDS = [
  {name: 'description', label: 'Review', type: 'text', hint: 'Please provide a review and include the source of the review.', required: true},
  {name: 'score', type: 'stars-rating'}
]
