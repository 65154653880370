import React, {Component} from 'react';
import StarsRatingCombo from './stars_rating_combo';

export default class ReferenceStarsRating extends Component {
  render() {
    let {reference} = this.props;
    let safetyOptions = {monotherapyTitle: 'Safety Monotherapy Rating', combinationTitle: 'Safety Combination Rating', valueComponentOnly: true};
    let efficacyOptions = {monotherapyTitle: 'Efficaicy Monotherapy Rating', combinationTitle: 'Efficacy Combination Rating', valueComponentOnly: true};
    return (
      <div className="references_stars_rating">
        <div className="references_stars_rating_section">
          Safety: <StarsRatingCombo  data={reference} section="safety" extraOptions={ReferenceStarsRating.EXTRA_OPTIONS} {...safetyOptions} />
        </div>
        <div className="references_stars_rating_section">
          Efficacy: <StarsRatingCombo  data={reference} section="efficacy" extraOptions={ReferenceStarsRating.EXTRA_OPTIONS} {...efficacyOptions} />
        </div>
      </div>
    )
  }
}


ReferenceStarsRating.EXTRA_OPTIONS = [
  {value: -1, order: 98, className: 'non-star-option', selectedClassName: 'non-star-option non-star-option-selected', content: 'N/A' },
  {value: -2, order: 99, className: 'non-star-option', selectedClassName: ' non-star-option non-star-option-selected', content: 'No Stars' }
]
