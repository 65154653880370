import React, {Component} from 'react';
import DiagnosticsAndGenes from './diagnostics_and_genes';
import PopulationData from './population_data.js.erb';
import Select2 from '../select2';
import Loading from '../loading';
import request from '../../request';
import Stadox from '../../stadox';

export default class SearchCancerType extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ctx = Stadox.subscribe(this);
  }

  componentDidMount() {
    let cancer_type_id = this.props.query.cancer_type_id;
    if (cancer_type_id) {
      this.fetchCancerType(cancer_type_id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.query.cancer_type_id || this.props.query.cancer_type_id === prevProps.query.cancer_type_id) return;
    this.fetchCancerType(this.props.query.cancer_type_id);
  }

  cancerTypeChanged(cancer_type_id) {
    if (cancer_type_id === this.props.query.cancer_type_id) return;

    this.fetchCancerType(cancer_type_id, cancer_type => {
      let {query, filters, onChange, onChangeFilters} = this.props;

      query.cancer_type_id = cancer_type_id;
      query.cancer_category_id = cancer_type.cancer_category.id
      query.options = {};

      if (cancer_type.cancer_category.tumor_type !== query.tumor_type_id) {
        query.tumor_type_id = cancer_type.cancer_category.tumor_type;
        query.tumor_type = {};
      }

      onChange("cancer_type", query, 'cancerTypeChangedCallback');
    });

  }

  fetchCancerType(cancer_type_id, callback) {
    let url = "/api/cancer_types/" + cancer_type_id;
    this.setState({loading: true});
    request("GET", url).then(cancer_type => {
      this.setState({cancer_type, loading: false});
      this.ctx.change({cancer_type_id, cancer_type_diagnostics_and_genes: cancer_type.diagnostics_and_genes});
      if (callback) callback(cancer_type);
    });
  }

  // buildDefaultTumorTypeOptions(cancer_type) {
  //   let cancer_category = cancer_type.cancer_category;
  //   let tumor_type = cancer_category.tumor_type;
  //   let tumor_type_key = tumor_type === 'S' ? 'solid' : 'liquid';
  //   let data = PopulationData.cancer_type[tumor_type_key];

  //   let opt = {};
  //   for (let section of Object.keys(data)) {
  //     for (let question of Object.keys(data[section].options)) {
  //       if (data[section].options[question].default) {
  //         if (!opt[section]) opt[section] = {};
  //         opt[section][question] = true;
  //       }
  //     }
  //   }

  //   let tumor_type_options = {};
  //   tumor_type_options[tumor_type_key] = opt;
  //   return tumor_type_options;
  // }

  changeOption(trait, opt, event) {
    let value = event.target.checked;
    let {query, onChange} = this.props;
    let {options} = query;
    if (!options[trait.id]) {
      options[trait.id] = {};
    }

    let opt_id = opt.id || opt;
    options[trait.id][opt_id] = value;
    this.evaluateSmartEmtpyState(options[trait.id], value);

    query.options = options
    onChange("cancer_type", query);
  }

  changeTumorTypeOption(tumor_type_id, key, opt_id, event){
    let value = event.target.checked;
    let {query, onChange} = this.props;
    let {tumor_type} = query;

    if (!tumor_type[tumor_type_id]) {
      tumor_type[tumor_type_id] = {}
    }

    if (!tumor_type[tumor_type_id][key]) {
      tumor_type[tumor_type_id][key] = {};
    }


    tumor_type[tumor_type_id][key][opt_id] = value;
    this.evaluateSmartEmtpyState(tumor_type[tumor_type_id][key], value);

    query.tumor_type = tumor_type;
    onChange("cancer_type", query);
  }

  toggleNonEssentialTraits() {
    let show_non_essentials_traits = !this.state.show_non_essentials_traits;
    this.setState({show_non_essentials_traits});
  }


  // https://trello.com/c/7BTDnUOj/696-search-improvements-handling-include-populations-without-details-for-this-field-in-search
  evaluateSmartEmtpyState(collection, value) {
    let keys = Object.keys(collection)
    let checked_keys= keys.filter(id => id !== '_empty' && collection[id]);

    if (value && checked_keys.length === 1) {
      collection['_empty'] = true;
    } else if (!value && checked_keys.length === 0) {
      collection['_empty'] = false;
    }

  }

  renderTrait(trait) {
    let {options} = this.props.query;
    let option_elements = trait.options.map(opt => {
      let key="trait_" + trait.id + "_opt_" + opt.id;
      let value = (options[trait.id] && options[trait.id][opt.id]) || false
      let name;
      if (opt.search_side_label && opt.search_side_label.length > 0) {
        name = opt.search_side_label;
      } else {
        name = opt.text;
      }
      return (
        <div className="checkbox" key={key}>
          <label>
            <input type="checkbox" checked={value} onChange={e => this.changeOption(trait, opt, e)}/> {name}
          </label>
        </div>
      )
    });

    let no_details_value = (options[trait.id] && options[trait.id]["_empty"]) || false
    let name;
    if (trait.search_side_label && trait.search_side_label.length > 0) {
      name = trait.search_side_label;
    } else {
      name = trait.name;
    }
    return (
      <div className="form-group" key={"trait_" + trait.id}>
        <label className="control-label">{name}</label>

        <div className="checkbox select-empty">
          <label>
            <input type="checkbox" onChange={e => this.changeOption(trait, "_empty", e)} checked={no_details_value} />
              <i>Include results without this specified</i>
          </label>
        </div>

        {option_elements}

      </div>
    );
  }


  renderEssentialTraits(traits) {
    return (
      <div>
        {traits.map(trait => this.renderTrait(trait))}
      </div>
    );
  }

  renderNonEssentialTraits(traits) {
    if (traits.length === 0) return null;

    let {show_non_essentials_traits} = this.state;
    let content, togggle_btn_label;
    if (show_non_essentials_traits ) {
      content = traits.map(trait => this.renderTrait(trait));
      togggle_btn_label = 'Hide';
    } else {
      togggle_btn_label = 'Show';
    }

    return (
      <div>
        <button className="traits_toggle_button" onClick={e => this.toggleNonEssentialTraits()}>
          <b>{togggle_btn_label} Non Essential Traits</b>
        </button>
        {content}
      </div>
    );

  }


  renderTraits() {
    let {cancer_type} = this.state;
    let essential_traits = []
    let non_essential_traits = []
    for (let trait of cancer_type.traits) {
      let target =  trait.essential ? essential_traits : non_essential_traits;
      target.push(trait);
    }

    return (
      <div>
        <h3>Cancer Type Traits</h3>
        {this.renderEssentialTraits(essential_traits)}
        {this.renderNonEssentialTraits(non_essential_traits)}
      </div>
    );
  }


  renderTumorType() {
    let {cancer_type} = this.state;
    let {tumor_type} = this.props.query;

    let cancer_category = cancer_type.cancer_category;
    let tumor_type_key = cancer_category.tumor_type === 'S' ? 'solid' : 'liquid';
    let data = PopulationData.cancer_type[tumor_type_key];

    let sections = Object.keys(data).map(key => {
      let section = data[key];
      let options = Object.keys(section.options).map(opt_id => {
        let opt = section.options[opt_id];
        let label = opt.search_label || opt.label
        let value = (tumor_type[tumor_type_key] && tumor_type[tumor_type_key][key] && tumor_type[tumor_type_key][key][opt_id]) || false

        return (
          <div className="checkbox" key={opt_id}>
            <label>
              <input type="checkbox" checked={value} onChange={e => this.changeTumorTypeOption(tumor_type_key, key, opt_id, e)}/> {label}
            </label>
          </div>
        )
      });

      let no_details_value = (tumor_type[tumor_type_key] && tumor_type[tumor_type_key][key] && tumor_type[tumor_type_key][key]['_empty']) || false
      let title = section.search_title || section.title;

      return (
        <div className="form-group" key={"tumor_type_" + key}>
          <label className="control-label">{title}</label>

          <div className="checkbox select-empty">
            <label>
              <input type="checkbox" onChange={e => this.changeTumorTypeOption(tumor_type_key, key, "_empty", e)} checked={no_details_value} />
              <i>Include results without this specified</i>
            </label>
            {options}
          </div>
        </div>
      );
    })

    return (
      <div>
        <h3>{cancer_category.tumor_type_humanized} Tumor</h3>
        {sections}
      </div>
    )
  }


  renderDiagnosticsAndGenes() {
    let {cancer_type} = this.state;
    let {diagnostics_and_genes} = cancer_type;

    if (!diagnostics_and_genes || diagnostics_and_genes.length === 0) return null
    let {filters, onChangeFilters} = this.props;

    return (
      <div>
        <h3>Diagnostics and Genes</h3>
        <DiagnosticsAndGenes diagnostics_and_genes={diagnostics_and_genes} filters={filters} onChange={onChangeFilters} />
      </div>
    )
  }

  renderContent() {
    if (this.state.loading) return <Loading />
    if (!this.props.query.cancer_type_id || !this.state.cancer_type) return null;

    return (
      <div>
        {this.renderTraits()}
        {this.renderDiagnosticsAndGenes()}
        {this.renderTumorType()}
      </div>
    );
  }

  render() {
    let {cancer_type_id} = this.props.query;
    return(
      <div id="by_cancer_type" className="search_panel">
        <div className="cancer_type_box">

          <Select2 id="select2_cancer_type" value={cancer_type_id} remote={true} src="/api/cancer_types/as_options.json" searchField="name_cont" onChange={e => this.cancerTypeChanged(+e.target.value)} />
        </div>
        {this.renderContent()}
      </div>
    )
  }

}
