import React, {Component} from 'react';
import StarsRatingField from './stars_rating_field';

export default class StarsRatingCombo extends Component {
  render() {
    let {data, section, extraOptions, valueComponentOnly, monotherapyTitle, combinationTitle} = this.props;
    let monotherapy_value = data[section + '_monotherapy_rating'];
    let combination_value = data[section + '_combination_rating'];

    return (
      <div className="stars-rate-combo">
        <StarsRatingField value={monotherapy_value} starsCount={5} title={monotherapyTitle || "Monotherapy Rating"} extraOptions={extraOptions} valueComponentOnly={valueComponentOnly} />
        <span className="stars-rate-sep">/</span>
        <StarsRatingField value={combination_value} starsCount={5} title={combinationTitle || "Combination Rating"} extraOptions={extraOptions} valueComponentOnly={valueComponentOnly} />
      </div>
    )
  }
}
