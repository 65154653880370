import Form from '../form';
import * as Inflector from 'inflected';

export default class JourneyTrackerForm extends Form {
  change(field, e, meta={}) {
    let {onChange} = this.props;
    let {name, value} = e.target;

    // Assign metadata
    meta.field_label = field.label || Inflector.titleize(field.name);

    onChange(name, value, meta);
  }
}
