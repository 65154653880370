import React from 'react';
import ReactDOM from 'react-dom';
import jquery from 'jquery';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default {
  setup: function() {
    jquery( document ).ready(function() {
      let els = $("[data-behaviour~='pickadate']");
      for (let el of els) {
        installPicker(jquery(el));
      }
    });
  }
}

function installPicker(target) {
  target.hide();
  let element_id = target[0].id + '-datepicker';
  let placeholder = jquery('<div style="display: inherit;"></div>');
  placeholder.insertAfter(target);

  let onChange = datetime => {
    let new_value = datetime ? moment(datetime).format('YYYY-M-D') : null;
    target.val(new_value)

    let selected = new_value ? new Date(new_value) : null;
    let react_element = React.createElement(DatePicker, {selected, onChange, className: 'form-control'});
    ReactDOM.render(react_element, placeholder[0]);
  };

  let target_value = target.val();
  let value = target_value ? new Date(target_value) : undefined;
  let react_element = React.createElement(DatePicker, {selected: value, onChange, className: 'form-control', id: element_id});
  ReactDOM.render(react_element, placeholder[0]);
}
