import React, {Component} from 'react';

export default class {
  static renderSafetyRubric() {
    return (
      <div className="instructions_holder">
        <h4><b>Safety / Side Effects</b></h4>
        <p>Only captured for 10 of more patients with safety data at a dose level; no DLT's to be captured.</p>
        <hr/>
        <table className="table table-bordered rubric_table">
          <thead>
            <tr>
              <th className="star-column text-left">Rating</th>
              <th>Tolerability Rating based on clinical impactful TRAEs</th>
              <th>Most Common TRAEs (any/all)</th>
              <th>Most Common TRAEs (lab)</th>
              <th>Most Common TRAEs (non-lab)</th>
              <th>Grade 3/4 TRAEs (any/all)</th>
              <th>Grade 3/4 TRAEs (lab)</th>
              <th>Grade 3/4 TRAEs (non-lab)</th>
              <th>Discontinuation on due to TRAEs</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i></td>
              <td>Poor Tolerability</td>
              <td>NA</td>
              <td>NA</td>
              <td>NA</td>
              <td>&#8805; 71%</td>
              <td>&#8805; 81%</td>
              <td>&#8805; 51%</td>
              <td>&#8805; 30%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Significant Tolerability Issues</td>
              <td>&#8805; 90%</td>
              <td>NA</td>
              <td>71 &mdash; 100%</td>
              <td>51 &mdash; 70%</td>
              <td>61 &mdash; 80%</td>
              <td>31 &mdash; 50%</td>
              <td>20 &mdash; 29%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Moderate Tolerability</td>
              <td>60 &mdash; 90%</td>
              <td>85 &mdash; 100%</td>
              <td>51 &mdash; 70%</td>
              <td>26 &mdash; 50%</td>
              <td>41 &mdash; 60%</td>
              <td>21 &mdash; 30%</td>
              <td>13 &mdash; 20%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Well-Tolerated</td>
              <td>30 &mdash; 60%</td>
              <td>51 &mdash; 85%</td>
              <td>26 &mdash; 50%</td>
              <td>16 &mdash; 25%</td>
              <td>21 &mdash; 40%</td>
              <td>10 &mdash; 20%</td>
              <td>6 &mdash; 12%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Excellent Tolerability</td>
              <td>&#8804; 30%</td>
              <td>&#8804; 50%</td>
              <td>&#8804; 25%</td>
              <td>&#8804; 15%</td>
              <td>&#8804; 20%</td>
              <td>&#8804; 10%</td>
              <td>&#8804; 5%</td>
            </tr>
          </tbody>
        </table>
      </div>

    )
  }

  static renderEfficacyRubric(population) {
    let edit_population_path = `/admin/trials/${population.trial_id}/populations/${population.id}/edit`;

    switch (population.rubric_type) {
    case 'solid_advanced':
      return this.renderSolidAdvanced(edit_population_path);
    case 'solid_early':
      return this.renderSolidEarly(edit_population_path);
    case 'liquid_eligible':
      return this.renderLiquidIntensiveEligible(edit_population_path);
    case 'liquid_ineligible':
      return this.renderLiquidIntensiveIneligible(edit_population_path);
    default:
      return this.renderNoRubricTypeSelected(edit_population_path);
    }
  }


  static renderNoRubricTypeSelected(edit_population_path) {
    return (
      <p>The rubric type for this population is not set. <a href={edit_population_path}>Set it now</a>.</p>
    )
  }

  static renderSolidAdvanced(edit_population_path) {
    return (
      <div className="instructions_holder">
        <h4>
          <b>Efficacy (solid tumors, Stage III/IV -  advanced/metastatic, incurable)</b>
          <small> <a href={edit_population_path}>Change</a></small>
        </h4>
        <hr/>
        <table className="table table-bordered rubric_table">
          <thead>
            <tr>
              <th className="star-column text-left">Rating</th>
              <th>Description</th>
              <th>Case Report</th>
              <th>mDOR (mo)</th>
              <th>ORR (%)</th>
              <th>CBR or DCR (%)</th>
              <th>Harzard Ratio (HR)</th>
              <th>mOS (mo)</th>
              <th>mPFS, mDFS, or mEFS (mo)</th>
            </tr>
            <tr className="detail_header_row">
              <th></th>
              <th>[phase] Efficacy:</th>
              <th>Individual patients were reported with benefits - </th>
              <th>Average duration of cancer control in only responding patients is</th>
              <th>% of patients who have significant tumor shrinkage is</th>
              <th>% of Patients who have durable tumor stability is</th>
              <th>Average survival benefit (lower is better) is</th>
              <th>Average survival duration (in months) is</th>
              <th>Average duration of cancer control  (in months) is</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-left">No Stars</td>
              <td>Negligible</td>
              <td>None</td>
              <td>&lt; 1</td>
              <td>&lt; 5</td>
              <td>&lt; 5</td>
              <td>&gt; 0.9</td>
              <td>&lt; 2</td>
              <td>&lt; 1</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i></td>
              <td>Minimal</td>
              <td>Single PR</td>
              <td>&lt; 6</td>
              <td>6 - 14</td>
              <td>&lt; 20</td>
              <td>0.8 - 0.89</td>
              <td>2 - 5</td>
              <td>1 - 3</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Mildly Encouraging</td>
              <td>Multiple PR or stability &gt; 6 months</td>
              <td>6 - 10.9</td>
              <td>15 - 24</td>
              <td>20 - 39</td>
              <td>0.75 - 0.79</td>
              <td>5 - 7.9</td>
              <td>3 - 4.4</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Promising</td>
              <td>1+ CRs or stability &gt; 1 year</td>
              <td>11 - 17.9</td>
              <td>25 - 34</td>
              <td>40 - 59</td>
              <td>0.65 - 0.74</td>
              <td>8 - 11.9</td>
              <td>4.5 - 7.9</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Exciting</td>
              <td>NA</td>
              <td>18 - 23.9</td>
              <td>35 - 49</td>
              <td>60 - 89</td>
              <td>0.5 - 0.65</td>
              <td>12 - 17.9</td>
              <td>8 - 11.9</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Amazing</td>
              <td>NA</td>
              <td>&#8805; 24</td>
              <td>&#8805; 50</td>
              <td>&#8805; 90</td>
              <td>&lt; 0.5</td>
              <td>&#8805; 18</td>
              <td>&#8805; 12</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  static renderSolidEarly(edit_population_path) {
    return (
      <div className="instructions_holder">
        <h4>
          <b>Efficacy (solid tumors, (neo)adjuvant, early Stage I/II, curable)</b>
          <small> <a href={edit_population_path}>Change</a></small>
        </h4>
        <hr/>
        <table className="table table-bordered rubric_table">
          <thead>
            <tr>
              <th className="star-column text-left">Rating</th>
              <th>Description</th>
              <th>pCR / NED</th>
              <th>HR</th>
              <th colSpan={4}>DFS, EFS, RFS, PFS, or OS.  For Relapse Rate, 100-X and use below</th>
            </tr>
            <tr className="detail_header_row">
              <th></th>
              <th>[phase] Efficacy:</th>
              <th>%</th>
              <th>Average survival benefit (lower is better) is</th>
              <th>median</th>
              <th>12 months</th>
              <th>24 months</th>
              <th>36 months</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-left">No Stars</td>
              <td>Negligible</td>
              <td>0</td>
              <td>&gt; 1</td>
              <td>&lt; 12 months (1 year)</td>
              <td>&lt; 45%</td>
              <td>&lt; 30%</td>
              <td>&lt; 30%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i></td>
              <td>Minimal</td>
              <td>&lt; 5</td>
              <td>&gt; 0.8</td>
              <td>&gt; 12 months (1 year)</td>
              <td>&gt; 45%</td>
              <td>&gt; 30%</td>
              <td>&gt; 30%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Mildly Encouraging</td>
              <td>5 - 19</td>
              <td>&gt; 0.7</td>
              <td>&gt; 24 months (2 years)</td>
              <td>&gt; 60%</td>
              <td>&gt; 45%</td>
              <td>&gt; 45%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Promising</td>
              <td>20 - 49</td>
              <td>&gt; 0.6</td>
              <td>&gt; 36 months (3 years)</td>
              <td>&gt; 75%</td>
              <td>&gt; 60%</td>
              <td>&gt; 60%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Exciting</td>
              <td>50 - 69</td>
              <td>&gt; 0.5</td>
              <td>&gt; 48 months (4 years)</td>
              <td>NA</td>
              <td>&gt; 75%</td>
              <td>&gt; 75%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Amazing</td>
              <td>&#8805;  70</td>
              <td>&lt; 0.5</td>
              <td>&gt; 60 months (5 years)</td>
              <td>NA</td>
              <td>NA</td>
              <td>&gt; 90%</td>
            </tr>
          </tbody>
        </table>
      </div>

    )
  }






  static renderLiquidIntensiveEligible(edit_population_path) {
    return (
      <div className="instructions_holder">
        <h4>
          <b>Efficacy (liquid tumors, all stages, intensive treatment eligible)</b>
          <small> <a href={edit_population_path}>Change</a></small>
        </h4>
        <hr/>
        <table className="table table-bordered rubric_table">
          <thead>
            <tr>
              <th className="star-column text-left">Rating</th>
              <th>Description</th>
              <th>Case Report</th>
              <th>Response (major, minor, spleen, other)</th>
              <th>Complete Response (CR, CRi)</th>
              <th colSpan={2}>PFS, EFS, DFS, TTP, RFS, or OS (OS is least preferred)</th>
            </tr>
            <tr className="detail_header_row">
              <th></th>
              <th>[phase] Efficacy:</th>
              <th>Individual patients were reported with benefits</th>
              <th>% of patients who have significant response is</th>
              <th>% of patients who have no signs of cancer (remission) is</th>
              <th>median average survival duration (in months) is</th>
              <th>at 1yr, 2yr, 3yr % of patients surviving at 1/2/3 years is:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-left">No Stars</td>
              <td>Negligible</td>
              <td>None</td>
              <td>&lt; 10</td>
              <td>0</td>
              <td>&lt; 12 months (1 year)</td>
              <td>&lt; 45%, &lt; 30%, &lt; 30%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i></td>
              <td>Minimal</td>
              <td>Single Response</td>
              <td>10 - 30</td>
              <td>&lt; 5</td>
              <td>&gt; 12 months (1 year)</td>
              <td>&gt; 45%, &gt; 30%, &gt; 30%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Mildly Encouraging</td>
              <td>Multiple Responses or stability &gt; 6 months</td>
              <td>30 - 49</td>
              <td>5 - 19</td>
              <td>&gt; 24 months (2 years)</td>
              <td>&gt; 60%, &gt; 45%, &gt; 45%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Promising</td>
              <td>1+ CRs or stability &gt; 1 year</td>
              <td>50 - 69</td>
              <td>20 - 49</td>
              <td>&gt; 36 months (3 years)</td>
              <td>&gt; 75%, &gt; 60%, &gt; 60%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Exciting</td>
              <td>NA</td>
              <td>70 - 89</td>
              <td>50 - 69</td>
              <td>&gt; 48 months (4 years)</td>
              <td>NA, &gt; 75%, &gt; 75%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Amazing</td>
              <td>NA</td>
              <td>&#8805; 90</td>
              <td>&#8805; 70</td>
              <td>&gt; 60 months (5 years)</td>
              <td>NA, NA, &gt; 90%</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  static renderLiquidIntensiveIneligible(edit_population_path) {
    return (
      <div className="instructions_holder">
        <h4>
          <b>Efficacy (liquid tumors, all stages, intensive treatment ineligible, elderly [70])</b>
          <small> <a href={edit_population_path}>Change</a></small>
        </h4>
        <hr/>
        <table className="table table-bordered rubric_table">
          <thead>
            <tr>
              <th className="star-column text-left">Rating</th>
              <th>Description</th>
              <th>Case Report</th>
              <th>Response (major, minor, spleen, other)</th>
              <th>Complete Response (CR, CRi)</th>
              <th colSpan={2}>PFS, EFS, DFS, TTP, RFS, or OS (OS is least preferred)</th>
            </tr>
            <tr className="detail_header_row">
              <th></th>
              <th>[phase] Efficacy:</th>
              <th>Individual patients were reported with benefits</th>
              <th>% of patients who have significant response is</th>
              <th>% of patients who have no signs of cancer (remission) is</th>
              <th>median average survival duration (in months) is</th>
              <th>at 1yr, 2yr, 3yr % of patients surviving at 1/2/3 years is:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-left">No Stars</td>
              <td>Negligible</td>
              <td>None</td>
              <td>0</td>
              <td>0</td>
              <td>&lt; 6 months</td>
              <td>&lt; 35%, &lt; 20%, &lt; 20%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i></td>
              <td>Minimal</td>
              <td>Single Response</td>
              <td>&lt; 5</td>
              <td>&lt; 5</td>
              <td>&gt; 6 months</td>
              <td>&gt; 35%, &gt; 20%, &gt; 20%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Mildly Encouraging</td>
              <td>Multiple Responses or stability &gt; 6 months</td>
              <td>5 - 19</td>
              <td>6 - 14</td>
              <td>&gt; 12 months (1 year)</td>
              <td>&gt; 50%, &gt; 35%, &gt; 35%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Promising</td>
              <td>1+ CRs or stability &gt; 1 year</td>
              <td>20 - 49</td>
              <td>15 - 30</td>
              <td>&gt; 24 months (2 years)</td>
              <td>&gt; 65%, &gt; 50%, &gt; 50%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Exciting</td>
              <td>NA</td>
              <td>50 - 69</td>
              <td>31 - 49</td>
              <td>&gt; 36 months (3 years)</td>
              <td>&gt; 75%, &gt; 65%, &gt; 65%</td>
            </tr>
            <tr>
              <td className="text-left"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></td>
              <td>Amazing</td>
              <td>NA</td>
              <td>&#8805; 70</td>
              <td>&#8805; 50</td>
              <td>&gt; 48 months (4 years)</td>
              <td>&gt; 90%, &gt; 85%, &gt; 75%</td>
            </tr>
          </tbody>
        </table>
      </div>

    )
  }
}
