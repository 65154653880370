import React, {Component} from 'react';
import {TrialHeader, TrialBottomNav} from './trial_partials';

export default class TrialStatuses extends Component {
  renderStatus() {
    let {trial} = this.props;
    return (
      <div className="trial-section-box">
        <h4>STATUS HISTORY</h4>


          <div id="updates_list">
            {trial.updates.map(update => {
              let status_el, type_el, description_el, admin_name;
              if (update.status) {
                status_el = <p>Marked Status as: <strong>{update.status_humanized}</strong></p>
              }
              if (update.type === 'population') {
                type_el = <p>POPULATION: <a href={update.link_to_population} target="_blank">{update.population_name}</a></p>
              }

              if (update.description) {
                description_el = <p className="trial_history_note">{update.description}</p>
              } else if (!update.description && !update.status && update.type === 'trial') {
                description_el = <p>Saved therapy updates.</p>;
              } else if (update.type === 'population' && !update.description) {
                description_el = <p>Saved population updates.</p>;
              }

              if (update.admin && update.admin.name) {
                admin_name = update.admin.name;
              }
              return (
                <div className="trial_history_item" key={update.id}>
                  <p><strong>{update.date_str}</strong> by <strong>{admin_name}</strong></p>
                  {status_el}
                  {type_el}
                  {description_el}
                </div>
              );
            })}
          </div>

      </div>
    );
  }

  render() {
    let {trial} = this.props;
    return (
      <div>
        <TrialHeader trial={trial} />
        {this.renderStatus()}
        <TrialBottomNav trial={trial} action="statuses"/>
      </div>
    );
  }
}
