import jquery from 'jquery';

// disable mousewheel on a input number field when in focus
// (to prevent Cromium browsers change the value when scrolling)

export default {
  setup: function() {
    jquery( document ).ready(() => disableScroll());
  }
}

function disableScroll() {
  jquery('form').on('focus', 'input[type=number]', function (e) {
    jquery(this).on('wheel.disableScroll', function (e) {
      e.preventDefault()
    })
  })
  jquery('form').on('blur', 'input[type=number]', function (e) {
    jquery(this).off('wheel.disableScroll')
  })
}
