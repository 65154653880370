import React, {Component} from 'react';
import Loading from './loading';
import request from '../request';
import Markdown from './markdown';

const REQUEST_DELAY = 3000;

export default class OpenAISummarize extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.requestSummary = this.requestSummary.bind(this);
  }

  componentDidMount() {
    let {reference} = this.props;
    window.reference = reference;
    if (!reference.data.openai_summary_requested_at) {
      this.requestSummary();
    }
    this.setState({summary: reference.data.openai_summary});
  }

  requestSummary() {
    let {reference} = this.props;
    this.setState({loading: true});
    let url = `/admin/references/${reference.id}/openai_summary`;
    request('POST', url).then(response => {
      if (!response.loading && response.summary) {
        this.setState({summary: response.summary, loading: false});
      } else {
        setTimeout(this.requestSummary, REQUEST_DELAY);
      }
    });
  }

  renderLoading() {
    return (
      <div>
        <h3>Generating the summary</h3>
        <p>This might take a while. Please wait</p>
        <Loading />
      </div>
    );
  }

  render() {
    let {reference} = this.props;
    let {summary, loading} = this.state;
    if (loading) return this.renderLoading();

    return (
      <div>
        <h2>Open AI Summary</h2>
        <h3>Abstract</h3>
        <Markdown text={reference.abstract_text} />
        <hr />
        <h3>Summary</h3>
        <Markdown text={summary} />
      </div>
    );
  }
}
