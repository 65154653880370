import React, {Component} from 'react';
import Form from './form';
import Loading from './loading';
import request from '../request';
import {CancerTypeSearchKey} from '../constants.js.erb';

export default class CancerSituationForm extends Component {
  constructor(props) {
    super(props);
    let {cancer_situation} = props;
    this.state = {cancer_situation};

    this.onChangeField = this.onChangeField.bind(this);
  }

  submit(e) {
    e.preventDefault();
    this.setState({submitting: true});
    let {cancer_situation} = this.state;

    let url = "/admin/cancer_situations";
    let method;

    if (cancer_situation.id) {
      url += "/" + cancer_situation.id;
      method = "PUT"
    } else {
      method = "POST"
    }

    request(method, url, {cancer_situation}).then(cancer_situation => {
      this.setState({cancer_situation, errors: [], submitting: false});
      window.location = "/admin/cancer_situations/";

    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors, submitting: false});
    });
  }

  fetchCancerType(cancer_type_id) {
    let url = '/admin/cancer_types/'+ cancer_type_id;
    request('GET', url).then(cancer_type => {
      let {cancer_situation} = this.state;
      cancer_situation.tumor_type = cancer_type.cancer_category.tumor_type;
      this.setState({cancer_situation});
    });
  }

  onChangeField(name, value) {
    let {cancer_situation} = this.state;
    cancer_situation[name] = value;
    if (name === 'cancer_type_id') {
      this.fetchCancerType(value);
     }

    this.setState({cancer_situation});
  }

  fields() {
    let {cancer_category} = this.state;
    return [
      {name: 'name', required: true},
      {name: 'cancer_type_id', label: 'Cancer Type', type: 'remote-select2', src: "/admin/cancer_types/as_options.json", search_key: CancerTypeSearchKey},
      {name: 'local_non_recurrent', type: 'select', collection: RESULT_OPTIONS, visible: c => c.tumor_type === 'S'},
      {name: 'local_recurrent', type: 'select', collection: RESULT_OPTIONS, visible: c => c.tumor_type === 'S'},
      {name: 'regional_non_recurrent', type: 'select', collection: RESULT_OPTIONS, visible: c => c.tumor_type === 'S'},
      {name: 'regional_recurrent', type: 'select', collection: RESULT_OPTIONS, visible: c => c.tumor_type === 'S'},
      {name: 'metastatic_non_recurrent', type: 'select', collection: RESULT_OPTIONS, visible: c => c.tumor_type === 'S'},
      {name: 'metastatic_recurrent', type: 'select', collection: RESULT_OPTIONS, visible: c => c.tumor_type === 'S'},
      {name: 'liquid_non_recurrent', type: 'select', collection: RESULT_OPTIONS, visible: c => c.tumor_type === 'L'},
      {name: 'liquid_recurrent', type: 'select', collection: RESULT_OPTIONS, visible: c => c.tumor_type === 'L'},
      {name: 'notes', type: 'text'},
      {name: 'sources', type: Sources}
    ];
  }

  render() {
    let {cancer_situation, submitting, errors} = this.state;

    return (
      <div>
        <Form model={cancer_situation} model_name="Cancer Situation" fields={this.fields()} errors={errors} onChangeField={this.onChangeField} onSubmit={e => this.submit(e)} submitting={submitting} />
      </div>
    );
  }
}

const RESULT_OPTIONS = {
  Y: 'Y - Candidate for Personalized Treatments',
  N: 'N - Candidate for Standard Treatments',
  M: 'M - Consider Personalized Treatments'
}

class Sources extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.submit = this.submit.bind(this);
  }

  submit() {
    let {current_source} = this.state;
    let {value, onChange} = this.props;
    if (current_source.id) {
      let index = value.findIndex(i => i.id === current_source.id);
      value[index] = current_source;
    } else {
      current_source.id = generateUUID();
      value.push(current_source);
    }

    onChange({value});
    this.setState({show_form: false});
  }

  onCancel() {
    this.setState({show_form: false});
  }

  onChange(current_source) {
    this.setState({current_source});
  }

  addSource() {
    this.setState({show_form: true, current_source: {}});
  }

  edit(source) {
    let current_source = Object.assign({}, source);
    this.setState({current_source, show_form: true});
  }


  delete(source) {
    if (!confirm('Are you sure?')) return;

    let {value, onChange} = this.props;
    let index = value.findIndex(i => i.id === source.id);
    value.splice(index, 1);
    onChange({value});
  }

  renderSources() {
    let {value} = this.props;

    return value.map(source => {
      return (
        <div key={source.id} className="form_listed_source">
          <div className="form_listed_source_info">
            <p><strong>{source.name}</strong></p>
            <a href={source.url} target="_blank">{source.url}</a>
          </div>
          <div className="btn-group">
            <button className="btn btn-default btn-sm" onClick={e => this.edit(source)}>Edit</button>
            <button className="btn btn-danger btn-sm" onClick={e => this.delete(source)}>Delete</button>
          </div>
        </div>
      )
    });
  }

  renderForm() {
    let {show_form, current_source} = this.state;
    let {value} = this.props;
    if (!show_form) return <button className="btn btn-default btn-sm" onClick={e => this.addSource()}>Add Source</button>

    let fields = [
      {name: 'name', required: true, label: 'Text to Display'},
      {name: 'url', required: true, label: 'Source Link'}
    ];

    return (
      <div className="inner-well">
        <Form containerElement='div' model={current_source} model_name="Source" fields={fields} onChange={this.onChange} onSubmit={this.submit} onCancel={this.onCancel} />
      </div>
    )
  }

  render() {
    return (
      <div>
        <h4 className="sources_header">SOURCES</h4>
        {this.renderForm()}
        {this.renderSources()}
        <hr/>
      </div>
    )
  }
}
