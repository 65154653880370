import React, {Component} from 'react';
import Form from './form';
import request from '../request';
import {TaskActionsFormField} from './task_actions';
import {PatientSearchKey, WorkCategorySearchKey, AdminSearchKey} from '../constants.js.erb';

export default class TaskTemplateForm extends Component {
  constructor(props) {
    super(props);
    let {task_template} = props;
    this.state = {task_template};
    this.onChange = this.onChange.bind(this);
  }

  submit(e) {
    e.preventDefault();
    this.setState({submitting: true});
    let {task_template} = this.state;
    let {onSubmit, return_path} = this.props;

    let url = "/admin/task_templates";
    let method;

    if (task_template.id) {
      url += "/" + task_template.id;
      method = "PUT"
    } else {
      method = "POST"
    }

    request(method, url, {task_template}).then(task_template => {
      window.location = '/admin/task_templates';

    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors, submitting: false});
    });

  }

  onChange(task_template) {
    this.setState({task_template});
  }

  fields() {
    return [
      {name: 'name', required: true},
      {name: 'description', type: 'text'},
      {name: 'work_category_id', type: 'remote-select2', src: "/admin/work_categories/as_options.json", search_key: WorkCategorySearchKey},
      {name: 'actions', type: TaskActionsFormField}
    ];
  }

  render() {
    let {task_template, errors, submitting} = this.state;
    let {onCancel} = this.props;
    let form_action = task_template.id ? 'Edit' : 'New';
    return (
      <div className="task_form_holder">
        <h4>{form_action} Task Template</h4>
        <hr/>
        <Form model_name="Task Template" model={task_template} fields={this.fields()} onChange={this.onChange} onSubmit={e => this.submit(e)} errors={errors} submitting={submitting} />
      </div>
    );
  }
}
