import React, {Component} from 'react';

import NeestedRecord from './neested_record';
import JourneyTable from './journey_table';
import OptionsCache from './options_cache';
import {globalDefaults} from './journey_data';
import {DiagnosticsGroupSearchKey, DiagnosticSearchKey} from '../../constants.js.erb';
import request from '../../request';
import Select2 from '../select2';
import Loading from '../loading';
import * as Inflector from 'inflected';

export default class LabsList extends JourneyTable {
  constructor(props) {
    super(props);
    this.state = {labs: [], diagnostics: [], filter: ''};
    this.cancerTypeCache = new OptionsCache({source: 'patient_cancer_types', onChange: e => this.forceUpdate()});
    this.diagnosticCache = new OptionsCache({source: 'diagnostics', onChange: e => this.forceUpdate()})
    this.preloadNested();
  }

  static getDerivedStateFromProps(props, state) {
    state.diagnostic_ids = props.data.labs.map(lab => ''+lab.diagnostic_id).unique();
    state.labs = filterLabs(props.data.labs, state.filter);
    return state;
  }

  componentDidUpdate() {
    this.preloadNested();
  }

  changeFilter(e) {
    let filter = e.target.value;
    let labs = filterLabs(this.props.data.labs, filter)
    this.setState({labs, filter});
  }

  preloadNested() {
    let {labs} = this.props.data;
    let diagnostic_ids = labs.map(l => l.diagnostic_id);
    this.diagnosticCache.preload(diagnostic_ids);
  }

  addLab() {
    let {data, settings, current_event} = this.props;
    let current = newLabData({}, current_event);
    current = globalDefaults(current, data, settings);

    this.setState({current});
  }

  addLabsGroup() {
    this.setState({current_group: true});
  }

  delete(lab) {
    //if (!this.validateDeletion('lab_id', lab.id)) return;
    if (!confirm('Are you sure?')) return;

    let {onDelete} = this.props;
    let sectionLabel = buildSectionLabel(lab, this.diagnosticCache);
    onDelete(BASE_PATH, sectionLabel, lab);
  }


  show(current) {
    this.setState({current});
  }


  renderForm() {
    let {current} = this.state;
    let {readOnly, patient, data, fields_changelog, onAddBatch, onEditBatch} = this.props;
    let props = {record: current, readOnly, patient, data, fields_changelog, onAddBatch, onEditBatch};
    props.onClose = e => this.setState({current: null});
    props.diagnosticCache = this.diagnosticCache;
    return <Lab {...props}  />
  }

  renderGroup(group) {
    let {readOnly, patient, data, current_event, settings, fields_changelog, onAddBatch, onEditBatch} = this.props;
    let props = {readOnly, patient, data, current_event, settings, fields_changelog, onAddBatch, onEditBatch, group};
    props.onClose = e => this.setState({current_group: null});
    props.diagnosticCache = this.diagnosticCache;
    return <LabsGroup {...props}  />
  }

  renderList() {
    let {data, readOnly} = this.props;
    let {labs, diagnostic_ids} = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Filter by diagnostic</label>
              <select className="form-control" onChange={e => this.changeFilter(e)}>
                <option value={''}>Show all</option>
                {diagnostic_ids.map(diagnostic_id => {
                  let diagnostic = this.diagnosticCache.get(diagnostic_id);
                  return <option key={diagnostic_id} value={diagnostic_id}>{diagnostic.text}</option>;
                })}
              </select>
            </div>
            <hr/>
          </div>
          <div className="col-md-6">
            <div className="btn-group pull-right">
              <button className="btn btn-success" onClick={e => this.addLab()} disabled={readOnly}>Add Lab</button>
              <button className="btn btn-success" onClick={e => this.addLabsGroup()} disabled={readOnly}>Add Lab Group</button>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
        <p><b>Labs</b></p>


        <table className="table table-condensed table-striped jt-log-table" id="labs_list">
          <thead>
            <tr>
              <th>Date</th>
              <th>Diagnostic</th>
              <th>Value</th>
              <th>Unit</th>
              <th>Change</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {labs.map(lab => {
              let date;
              if (typeof(lab.date) === 'string') {
                date = moment(new Date(lab.date)).format('LL');
              } else {
                date = moment(lab.date).format('LL');
              }

              let action_label = readOnly ? 'Show' : 'Edit';
              let diagnostic = this.diagnosticCache.get(lab.diagnostic_id);
              let patient_cancer_type_name;

              if (lab.diagnosis_id) {
                let diagnosis = data.diagnoses.find(d => d.id === lab.diagnosis_id);
                let patient_cancer_type = this.cancerTypeCache.get(diagnosis.patient_cancer_type_id);
                patient_cancer_type_name = patient_cancer_type.text;
              }

              let quantitative_value, quantitative_unit, qualitative_interval_change;
              if (diagnostic.lab_type === LAB_TYPE_QUANTITATIVE) {
                quantitative_value = lab.quantitative_value;
                quantitative_unit = lab.quantitative_unit;
              } else if (lab.qualitative_interval_change) {
                qualitative_interval_change = Inflector.titleize(lab.qualitative_interval_change);
              }

              let delete_el;
              if (!readOnly) {
                let id = "delete_lab-" + lab.id;
                delete_el = <button id={id} className="btn btn-default btn-sm" onClick={e => this.delete(lab)}><i className="fa fa-trash"></i></button>
              }


              return(
                <tr key={lab.id}>
                  <td>{date}</td>
                  <td>{diagnostic.text}</td>
                  <td>{quantitative_value}</td>
                  <td>{quantitative_unit}</td>
                  <td>{qualitative_interval_change}</td>
                  <td className="actions">
                    <div className="btn-group">
                      <button className="btn btn-default btn-sm" onClick={e => this.show(lab)}>{action_label}</button>
                      {delete_el}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    );

  }

  render() {
    let {current, current_group} = this.state;
    if (current_group) {
      return this.renderGroup(current_group);
    }
    return super.render();
  }
}


const BASE_PATH = "labs"

function buildSectionLabel(record, diagnosticCache) {
  let diagnostic = diagnosticCache.get(record.diagnostic_id);
  return ['Lab', diagnostic.text];
}

class Lab extends NeestedRecord {
  getModelName() {
    return "Lab";
  }

  getBasePath() {
    return BASE_PATH;
  }

  getSectionLabel() {
    let {record} = this.state;
    return buildSectionLabel(record, this.props.diagnosticCache);
  }

  onChange(field_name, value, meta) {
    super.onChange(field_name, value, meta);
    let {record} = this.state;
    if (record.diagnostic_id) {
      this.props.diagnosticCache.preload([record.diagnostic_id]);
    }
  }

  isQuantitative(lab) {
    let {diagnosticCache} = this.props;
    let diagnostic = diagnosticCache.get(lab.diagnostic_id);
    return diagnostic.lab_type === LAB_TYPE_QUANTITATIVE;
  }

  isQualitative(lab) {
    let {diagnosticCache} = this.props;
    let diagnostic = diagnosticCache.get(lab.diagnostic_id);
    return diagnostic.lab_type === LAB_TYPE_QUALITATIVE;
  }

  isCompletelyQualitative(lab) {
    let {diagnosticCache} = this.props;
    let diagnostic = diagnosticCache.get(lab.diagnostic_id);
    return diagnostic.completely_qualitative;
  }


  formFields() {
    let diagnosticValueLabelCallback = lab => {
      let {diagnosticCache} = this.props;
      let diagnostic = diagnosticCache.get(lab.diagnostic_id);
      return diagnostic.text;
    };
    let {record} = this.state;
    let {diagnosticCache} = this.props;
    let diagnostic = diagnosticCache.get(record.diagnostic_id);
    let units = diagnostic.units || [];

    return [
      {name: 'diagnostic_id', label: 'Diagnostic', required: true, type: 'remote-select2', src: "/admin/diagnostics/as_options.json", search_key: DiagnosticSearchKey, valueLabelCallback: diagnosticValueLabelCallback},
      {name: 'date', type: 'date', required: true, inSummary: true},
      {name: 'quantitative_value', type: 'number', visible: l => this.isQuantitative(l)},
      {name: 'quantitative_unit', type: 'select', collection: units, visible: l => this.isQuantitative(l)},
      {name: 'quantitative_result', type: 'select', collection: ['negative', 'positive', 'high', 'low'], labelTransform: 'titleize', visible: l => this.isQuantitative(l)},
      {name: 'qualitative_interval_change', type: 'select', collection: ['unknown', 'N/A', 'none', 'increase', 'decrease', 'other'], labelTransform: 'titleize', visible: l => this.isQualitative(l) && !this.isCompletelyQualitative(l)},
      {name: 'other_detail', type: 'text'},
      {name: 'description', type: 'text'}
    ];
  }
}


class BatchLab extends Lab {
  componentDidMount() {
    window.addEventListener('submit_labs', this.onSubmit, false);
  }

  componentWillUnmount() {
    window.removeEventListener('submit_labs', this.onSubmit);
  }

  render() {
    return (
      <div className="jt_form_box">
        {this.renderForm()}
      </div>
    );
  }
}


class LabsGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  submit() {
    let event = new Event('submit_labs')
    window.dispatchEvent(event);
    this.props.onClose();
  }

  close() {
    this.props.onClose();
  }

  fetchDiagnosticsGroup(diagnostics_group_id) {
    let url = '/admin/diagnostics_groups/' + diagnostics_group_id;
    this.setState({loadidng: true});
    request('GET', url).then(diagnostics_group => {
      let labs = diagnostics_group.diagnostics_and_gene_ids.map(diagnostic_id => {
        let {data, settings, current_event} = this.props;
        let current = newLabData({diagnostic_id}, current_event);
        return globalDefaults(current, data, settings)
      })
      this.setState({labs, loading: false});
    });
  }

  changeDiagnosticsGroup(e) {
    let {value} = e.target;
    this.setState({diagnostics_group_id: value, labs: null});
    this.fetchDiagnosticsGroup(value);
  }


  renderForm(record, index) {
    let {readOnly, patient, data, fields_changelog, onAddBatch, onEditBatch, diagnosticCache} = this.props;
    let props = {readOnly, patient, data, fields_changelog, onAddBatch, onEditBatch, record, diagnosticCache};
    return <BatchLab key={index} hideButtons={true} prefix={index} {...props}  />
  }


  renderForms() {
    let {labs, loading} = this.state;
    if (loading) return <Loading />
    if (!labs) return null;
    return (
      <div>
        {labs.map((lab, index) => this.renderForm(lab, index))}
        <div className="btn-group">
          <button className="btn btn-success" onClick={e => this.submit()}>Create Labs</button>
          <button className="btn btn-default" onClick={e => this.close()}>Close</button>
        </div>
      </div>
    )
  }

  renderGroupPicker() {
    let {diagnostics_group_id} = this.state;
    return (
      <div className="form-group">
        <label>Select a diagnostics group</label>
        <Select2 remote={true} value={diagnostics_group_id} src="/admin/diagnostics_groups/as_options.json" searchField={DiagnosticsGroupSearchKey} onChange={e => this.changeDiagnosticsGroup(e)} />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderGroupPicker()}
        {this.renderForms()}
      </div>
    );
  }
}

const LAB_TYPE_QUALITATIVE = 'qualitative';
const LAB_TYPE_QUANTITATIVE = 'quantitative';


function filterLabs(labs, filter) {
  labs = labs.sort((a,b) => {return new Date(b.date).getTime() - new Date(a.date).getTime()});
  if (!filter || filter.length === 0) return labs;
  return labs.filter(lab => '' +lab.diagnostic_id === ''+filter);
}


function newLabData(lab, current_event) {
  if (current_event.type === 'med_lab_diagnostic') {
    lab.date = current_event.data.test_date;
  }

  return lab;
}
