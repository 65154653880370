import React, {Component} from 'react';

export default class SavePanel extends Component {
  static triggerEvent(status) {
    let event = new CustomEvent('population-save-event', {detail: {status}});
    window.dispatchEvent(event);
  }


  componentDidUpdate(prevProps) {
    let {submitting, save_success, save_error} = this.props;

    if (save_success && prevProps.save_success !== save_success) {
      SavePanel.triggerEvent('success');
    } else if (save_error && prevProps.save_error !== save_error) {
      SavePanel.triggerEvent('error');
    } else if (submitting && prevProps.submitting !== submitting) {
      SavePanel.triggerEvent('submitting');
    } else {
      SavePanel.triggerEvent('reset');
    }
  }
  render() {
    let icon, text, style, action, errors_list;
    let {submitting, save_success, save_error, errors, onClick} = this.props;

    if (submitting) {
      action = <button className="btn btn-success pull-right" disabled={true}>Saving Form</button>
    } else {
      action = <button className="btn btn-success pull-right" onClick={onClick}>Save Form</button>
    }

    if (save_success) {
      icon = 'glyphicon-ok'
      text = <span>Updates Saved!</span>
      style = 'alert-success';
    }
    else if (save_error) {
      icon = 'glyphicon-warning-sign'
      text = <span><strong>Oops, something went wrong.</strong> Please, try again.</span>
      style = 'alert-danger';
      if (errors) {
        errors_list = <ul>{errors.map((e, index) => <li key={index}>{e}</li>)}</ul>
      }
    }
    else {
      icon = 'glyphicon-warning-sign'
      text = <span>Changed will be auto-saved in most cases, but remember to <strong>save your work</strong>.</span>
      style = 'alert-info';
    }

    return (
      <div className={'form-flash alert ' + style}>
        <span>
          <span className={'glyphicon ' + icon}></span> {text}
        </span>
        {action}
        {errors_list}
        <div className="clearfix"></div>
      </div>
    );
  }
}
