import React, {Component} from 'react';
import Markdown from './markdown';
export default class VerifyPopulationSummary extends Component {

  constructor(props) {
    super(props);
    this.state = {search_section: 'summary', search_text: ''};
    this.changeFilter = this.changeFilter.bind(this);
    this.search = this.search.bind(this);
  }

  setFile(e) {
    let value = e.target.files[0];
    value.text().then(content => {
      let data = JSON.parse(content);
      let visible_data = data;
      window.data = data;
      this.setState({data, visible_data, index: 0, length: data.length, show_form: false});
    });
  }

  changeFilter(e) {
    let {name, value} = e.target;
    this.setState({[name]: value});
  }

  clearFilter() {
    let {data} = this.state;
    let visible_data = data;
    this.setState({visible_data, index: 0, length: visible_data.length, search_text: ''});
  }

  search(e) {
    e.preventDefault();
    let {search_text, search_section, data} = this.state;
    let regexp = new RegExp(search_text);
    let visible_data = data.filter(item => item[search_section].match(regexp))
    this.setState({visible_data, index: 0, length: visible_data.length})
  }


  prev() {
    let {index} = this.state;
    if (index === 0) return;
    this.setState({index: index -1})
  }

  next() {
    let {index, length} = this.state;
    if (index === length -1) return;
    this.setState({index: index +1})
  }

  renderUploadForm() {
    return (
      <form>
        <input type="file" onChange={e => this.setFile(e)} />
      </form>
    )
  }

  renderData() {
    let {visible_data, index } = this.state;
    let data = visible_data[index]
    if (!data) return <p>No data found</p>;
    let formatted_output = formatOutput(data.output)
    let link = `/admin/trials/${data.trial_id}/populations/${data.population_id}`

    return (
      <div>
        <a href={link} target="_blank" className="btn btn-default">Open Population</a>
        <div className="row">
          <div className="col-md-6">
            <h3>Inferred</h3>
            <Markdown text={formatted_output}/>
          </div>
          <div className="col-md-6">
            <h3>App Summary</h3>
            <Markdown text={data.summary}/>
          </div>
        </div>
        <h3>Trial Data</h3>
        <Markdown text={data.source}/>
      </div>
    )

  }

  renderContent() {
    let {index, length, search_text, search_section} = this.state;
    return (
      <div>
        <div>
          <form onSubmit={this.search}>
            <input type="text" name="search_text" value={search_text} onChange={this.changeFilter} />
            <select name="search_section" value={search_section} onChange={this.changeFilter} >
              <option value="output">Inferred</option>
              <option value="summary">Summary</option>
              <option value="source">Source</option>
            </select>
            <input type="submit" value="Search" className="btn btn-default btn-sm" />
            <button className="btn btn-default" onClick={e => this.clearFilter()}>Clear Search</button>
          </form>
        </div>
        <div className="btn-group pull-right">
          <button className="btn btm-default btn-sm" onClick={e => this.prev()}> PREV </button>
          <button className="btn -btn-default btn.sm" disabled>{index + 1} / {length}</button>
          <button className="btn btm-default btn-sm" onClick={e => this.next()}> NEXT </button>
        </div>
        {this.renderData()}
      </div>

    )
  }

  render() {
    let {data, show_form} = this.state;
    if (!data || show_form) {
      return this.renderUploadForm();
    } else {
      return this.renderContent();
    }
  }
}


function formatOutput(input) {
  return input.replaceAll(". ", ".\n")
}
