import React, {Component} from 'react';
import MapViewer from './map_viewer';
import CollectionTrial from './collection_trial';

const GREEN = '#55ba47';
const BLUE = '#008bff';
const GREY = '#737373';
const LIGHT_GRAY = '#989898';


export default class CollectionMap extends Component {
  constructor(props) {
    super(props);
    let markers = this.buildMarkers(props);
    this.state = {markers};
    this.resizeWindow = this.resizeWindow.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeWindow, false);
    this.resizeWindow();
  }

  resizeWindow() {
    let {container} = this.refs;
    let window_height = window.innerHeight;
    let height = window_height - container.offsetTop - MARGIN_BOTTOM;
    this.setState({height});
  }

  buildMarkers({patient, collection_trials}) {
    // First, group results by cancer center
    let centers = [];
    for (let collection_trial of collection_trials) {
      for (let trial_location of collection_trial.trial_locations) {
        for (let cancer_center of trial_location.cancer_centers) {
          let {id, lat, lon} = cancer_center;
          if (lat && lon) {
            let title = cancer_center.name;
            let index = centers.findIndex(c => c.cancer_center.id === id);
            if (index !== -1 && centers[index].collection_trials.findIndex(r => r.id === collection_trial.id) === -1) {
              centers[index].collection_trials.push(collection_trial);
            } else {
              centers.push({cancer_center, lat, lon, title, collection_trials: [collection_trial]});
            }
          }
        }
      }
    }

    // Create markers for cancer centers
    let markers = []

    for (let center of centers) {
      let {id, lat, lon, title, collection_trials} = center;
      let onClick = () => {
        this.setState({selected: center});
      }

      let color = center.selected ? GREEN : GREY
      if (center.cancer_center.fake) color = LIGHT_GRAY;

      let style = {color, caption: collection_trials.length}
      markers.push({cancer_center_id: id, lat, lon, title, onClick, style, zIndexOffset: 1, grouped: true})
    }

    if (patient) {
      let style = {color: BLUE, icon: 'home'}
      for (let location of patient.locations) {
        let {id, lat, lon, primary} = location;
        if (lat && lon) {
          markers.push({lat, lon, style, zIndexOffset: 999})
        }
      }
    }

    return markers;

  }


  renderResult() {
    let {selected, height} = this.state;
    if (!selected) return null;
    let {patient, onDelete, onMove} = this.props;
    let {cancer_center, collection_trials} = selected;
    return (
      <div id="results-box" style={{maxHeight: height - 14}}>
        <div>
          <h4><b>{cancer_center.name}</b></h4>
        </div>
        {collection_trials.map(collection_trial => {
          return <CollectionTrial key={collection_trial.id} collection_trial={collection_trial} patient={patient} hideActions={true} onDelete={onDelete} onMove={onMove} />
        })}
      </div>
    );
  }


  render() {
    let {markers, height} = this.state;
    return (
      <div id="results_map" ref="container" style={{height}}>
        {this.renderResult()}
        <MapViewer height={height} markers={markers} onClickOnMap={e => this.setState({selected: null})} />
      </div>
    );
  }
}

const MARGIN_BOTTOM = 80;
