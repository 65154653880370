import React, {Component} from 'react';

export default class Condition extends Component {
  render() {
    if (!this.props.when || this.props.unless) return null;

    if (Array.isArray(this.props.children)) {
      return <div>{this.props.children}</div>
    }

    return this.props.children;
  }
}
