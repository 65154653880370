import React, {Component} from 'react';
import Loading from './loading';
import Markdown from './markdown';
import request from '../request';

export default class AnonymizedPatientSummary extends Component {
  constructor(props) {
    super(props);
    let openai_anonymized_summary = props.summary.data.openai_anonymized_summary || {}
    this.state = {openai_anonymized_summary};
    this.fetchSummary = this.fetchSummary.bind(this);
  }

  fetchSummary() {
    let {patient_id, summary} = this.props;
    let url = `/admin/patients/${patient_id}/patient_summaries/${summary.id}/anonymized_summary`;
    request('GET', url).then(openai_anonymized_summary => {
      console.log(openai_anonymized_summary);
      if (openai_anonymized_summary.requested_at) {
        setTimeout(this.fetchSummary, 5000);
      } else {
        this.setState({openai_anonymized_summary});
      }
    });
  }

  requestSummary() {
    let {patient_id, summary} = this.props;
    let url = `/admin/patients/${patient_id}/patient_summaries/${summary.id}/request_anonymized_summary`;
    request('POST', url).then(openai_anonymized_summary => {
      this.setState({openai_anonymized_summary}, () => setTimeout(this.fetchSummary, 5000));
    });
  }

  renderRequestSummary() {
    return (
      <div>
        <p>Anonymized summary not found</p>
        <button className="btn btn-default" onClick={e => this.requestSummary()}>Request Summary</button>
      </div>
    )
  }
  render() {
    let {openai_anonymized_summary} = this.state;
    if (openai_anonymized_summary.requested_at) return <Loading />
    if (!openai_anonymized_summary.text) return this.renderRequestSummary();
    let updated_at = moment(openai_anonymized_summary.updated_at).format('LLL');
    return (
      <div>
        <Markdown text={openai_anonymized_summary.text} />
        <p><i>Summary created on {updated_at}</i></p>
        <button className="btn btn-default" onClick={e => this.requestSummary()}>Update Summary</button>
      </div>
    );
  }
}
