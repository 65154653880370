import React, {Component} from 'react';
import * as Inflector from 'inflected';
import Form from './form';
import PersonForm from './person_form';
import request from '../request';
import {Countries, States, CancerCenterSearchKey, PersonSearchKey, PatientTypeSearchKey, AdminSearchKey, CancerTypeSearchKey} from '../constants.js.erb';

const LINK_TO_DRIVE_FOLDER = 'https://drive.google.com/drive/folders/0BwVNO2hQjk5zS19vbnZFTjV6OFU?resourcekey=0-GRrAfcVGUsb7_fXUOtpRgQ&usp=sharing'

export default class PatientForm extends Component {
  constructor(props) {
    super(props);
    let {patient} = props;

    let url = new URL(window.location.href);
    let current_tab = url.searchParams.get("tab") || 'main'

    this.state = {patient, current_tab};
    this.onChangePatient = this.onChangePatient.bind(this);
  }

  selectTab(current_tab) {
    this.setState({current_tab});
  }

  onChangePatient(patient) {
    this.setState({patient});
  }

  renderTabs() {
    let {current_tab} = this.state;
    return (
      <ul className="nav nav-tabs" role="tablist">
        {this.tabs().map(tab => {
          let className = current_tab === tab.id ? 'active' : null;
        return (
          <li key={tab.id} role="presentation" className={className}>
            <button className="btn btn-link" aria-controls={tab} role="tab" data-toggle="tab" onClick={e => this.selectTab(tab.id)}>{tab.label}</button>
          </li>
        )
      })}
      </ul>
    );
  }

  tabs() {
    let {patient} = this.state;
    if (!patient.id) {
      return [{id: 'main', label: 'Patient', content: Patient}]
    }

    return [
      {id: 'main', label: 'Patient', content: Patient},
      {id: 'locations', label: 'Locations', content: Locations},
      {id: 'medical_team', label: 'Medical Team', content: MedicalTeam},
      {id: 'medical_centers', label: 'Medical Centers', content: MedicalCenters},
      {id: 'insurances', label: 'Insurances', content: Insurances},
      {id: 'contacts', label: 'Contacts', content: Contacts},
      {id: 'billing', label: 'Billing', content: Billing},
      {id: 'notes', label: 'Notes', content: Notes}
    ];
  }

  renderContent() {
    let {current_tab, patient} = this.state;
    let {anonymous_mode} = this.props;

    if (current_tab === 'main') {
      return <Patient patient={patient} anonymous_mode={anonymous_mode} onChange={this.onChangePatient} />
    } else if (current_tab === 'medical_centers') {
      return <MedicalCenters patient={patient} onChange={this.onChangePatient} />
    } else if (current_tab === 'medical_team') {
      return <MedicalTeam patient={patient} onChange={this.onChangePatient} />
    } else if (current_tab === 'insurances') {
      return <Insurances patient={patient} onChange={this.onChangePatient} />
    } else if (current_tab === 'contacts') {
      return <Contacts patient={patient} onChange={this.onChangePatient} />
    } else if (current_tab === 'locations') {
      return <Locations patient={patient} onChange={this.onChangePatient} />
    } else if (current_tab === 'billing') {
      return <Billing patient={patient} onChange={this.onChangePatient} />
    } else if (current_tab === 'notes') {
      return <Notes patient={patient} onChange={this.onChangePatient} />
    }

    return null;
  }

  render() {
    return (
      <div>
        {this.renderTabs()}
        {this.renderContent()}
      </div>
    );
  }
}




class Patient extends Component {
  constructor(props) {
    super(props);
    let {patient} = props;
    this.state = {patient, errors: {}};
    this.onChange = this.onChange.bind(this);
  }

  submit(e) {
    e.preventDefault();
    this.setState({submitting: true});
    let {patient} = this.state;

    let url = "/admin/patients";
    let method;


    if (patient.id) {
      url += "/" + patient.id;
      method = "PUT"
    } else {
      method = "POST"
    }

    let model = {};
    for (let field of this.formFields()) {
      model[field.name] = patient[field.name];
    }

    request(method, url, {patient: model}).then(patient => {
      this.props.onChange(patient);
      if (method === 'POST') {
        window.location = "/admin/patients/" + patient.id + "/edit";
      } else {
        this.setState({submitting: false});
      }
    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors, submitting: false});
    });
  }

  formFields() {
    let {patient} = this.state;
    return [
      {name: 'name', required: true, visible: () => !this.props.anonymous_mode},
      {name: 'short_name', required: true},
      {name: 'sh_id', label: 'SH-ID', hint: 'Leave it blank for default'},
      {name: 'patient_type_id', label: 'Patient Type', type: 'remote-select', src: "/admin/patient_types/as_options.json", search_key: PatientTypeSearchKey},
      {name: 'admin_id', label: 'Patient Lead', type: 'remote-select2', src: "/admin/admins/as_options.json", search_key: AdminSearchKey},
      {name: 'admin_ids', label: 'Assigned Admins', type: 'remote-select2', multiple: true, src: "/admin/admins/as_options.json", search_key: AdminSearchKey},
      {name: 'cancer_type_ids', label: 'Cancer Types', type: 'remote-select2', multiple: true, src: "/admin/cancer_types/as_options.json", search_key: CancerTypeSearchKey},
      {name: 'self_reported_cancer_type'},
      {name: 'gender', type: 'select', collection: {man: 'Male', woman: 'Female'}},
      {name: 'date_of_birth', type: 'date'},
      {name: 'folder_url'},
      {name: 'link_to_drive', type: 'button', label: 'Go to Drive', onClick: e => window.open(LINK_TO_DRIVE_FOLDER, '_blank')},
      {name: 'emails', type: 'component', component: Emails, props: {patient, onChange: this.onChange}},
      {name: 'phones', type: 'component', component: Phones, props: {patient, onChange: this.onChange}},
      {name: 'referal_source'},
      {name: 'first_contact_at', type: 'date'},
      {name: 'intake_call_at', type: 'date'},
      {name: 'contracted_at', label: 'Date Contracted', type: 'date'},
      {name: 'willingness_to_travel'},
      {name: 'reported_stage'},
      {name: 'date_initial_diagnosis', type: 'date'},
      {name: 'original_inquirer'},
      {name: 'deceased', type: 'bool'},
      {name: 'deceased_at', type: 'date', visible: p => p.deceased },
      {name: 'has_benefactor', type: 'bool'},
      {name: 'benefactor_name', visible: p => p.has_benefactor },
      {name: 'benefactor_phone', visible: p => p.has_benefactor },
      {name: 'benefactor_email', visible: p => p.has_benefactor }
    ];
  }

  onChange(patient) {
    this.setState({patient});
  }

  render() {
    let {patient, errors, submitting} = this.state;
    return <Form model_name="Patient" prefix="patient" fields={this.formFields()} model={patient} submitting={submitting} errors={errors} onChange={this.onChange} onSubmit={e => this.submit(e)} />
  }
}

class MedicalCenters extends Component {
  constructor(props) {
    super(props);
    let {medical_center} = props;
    this.state = {errors: {}};
  }

  submit(e) {
    e.preventDefault();
    let {medical_center} = this.state;
    let {patient} = this.props;

    let url = "/admin/patients/" + patient.id + "/medical_centers";
    let method;


    if (medical_center.id) {
      url += "/" + medical_center.id;
      method = "PUT"
    } else {
      method = "POST"
    }

    let model = {};
    for (let field of this.formFields()) {
      model[field.name] = medical_center[field.name];
    }

    request(method, url, {medical_center: model}).then(patient => {
      this.props.onChange(patient);
      this.setState({show_form: false});
    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors});
    });
  }

  onChange(medical_center) {
    this.setState({medical_center});
  }

  cancelForm(e) {
    e.preventDefault();
    this.setState({show_form: false})
  }

  addMedicalCenter() {
    let medical_center = {cancer_center_id: '', current: false, mrn: '', emrn: ''};
    this.setState({medical_center, show_form: true});
  }

  editMedicalCenter(medical_center) {
    this.setState({medical_center, show_form: true});
  }

  deleteMedicalCenter(medical_center) {
    if (!confirm('Are you sure?')) return;
    let {patient} = this.props;

    let url = "/admin/patients/" + patient.id + "/medical_centers/" + medical_center.id;
    request('DELETE', url).then(patient => {
      this.props.onChange(patient);
    });
  }

  formFields() {
    return [
      {name: 'cancer_center_id', label: 'Cancer Center', required: true, type: 'remote-select2', src: "/admin/cancer_centers/as_options.json", search_key: CancerCenterSearchKey},
      {name: 'mrn', label: 'MRN'},
      {name: 'emrn', label: 'eMRN'},
      {name: 'current', type: 'bool'}
    ]
  }

  renderForm() {
    let {medical_center, errors} = this.state;
    return <Form model_name="Medical Center" prefix="medical_center" fields={this.formFields()} model={medical_center} errors={errors} onChange={r => this.onChange(r)} onSubmit={e => this.submit(e)} onCancel={e => this.cancelForm(e)} />
  }


  renderMedicalCenter(medical_center) {
    let {cancer_center} = medical_center;
    let current_el
    if (medical_center.current) {
      current_el = <span className="badge">CURRENT</span>
    }
    return (
      <div key={medical_center.id}>
        <strong>{cancer_center.name}</strong><br />
        MRN: {medical_center.mrn} <br />
        eMRN: {medical_center.emrn} <br />
        {current_el}
        <button className="btn btn-link" onClick={e => this.editMedicalCenter(medical_center)}>Edit</button>
        <button className="btn btn-link" onClick={e => this.deleteMedicalCenter(medical_center)}>Delete</button>
      </div>
    );
  }

  render() {
    let {show_form} = this.state;
    if (show_form) return this.renderForm();
    let {patient} = this.props;

    return (
      <div>
        {patient.medical_centers.map(m => this.renderMedicalCenter(m))}
        <button className="btn btn-default" onClick={e => this.addMedicalCenter()}>Add Medical Center</button>
      </div>
    )
  }
}


class Insurances extends Component {
  constructor(props) {
    super(props);
    let {insurance} = props;
    this.state = {};
  }

  submit(e) {
    e.preventDefault();
    let {insurance} = this.state;
    let {patient} = this.props;

    if (insurance.id) {
      let index = patient.insurances.findIndex(i => i.id === insurance.id);
      patient.insurances[index] = insurance;
    } else {
      insurance.id = generateUUID();
      patient.insurances.push(insurance);
    }
    this.submitPatient(patient);
  }

  submitPatient(patient) {
    let url = "/admin/patients/" + patient.id
    request('PUT', url, {patient}).then(patient => {
      this.props.onChange(patient);
      this.setState({show_form: false});
    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors});
    });
  }

  onChange(insurance) {
    this.setState({insurance});
  }

  cancelForm(e) {
    e.preventDefault();
    this.setState({show_form: false})
  }

  addInsurance() {
    let insurance = {company: '', subscriber: '', plan: '', insurance_id: '', notes: ''};
    this.setState({insurance, show_form: true});
  }

  editInsurance(insurance) {
    this.setState({insurance, show_form: true});
  }

  deleteInsurance(insurance) {
    if (!confirm('Are you sure?')) return;
    let {patient} = this.props;
    let index = patient.insurances.findIndex(i => i.id === insurance.id);
    patient.insurances.splice(index, 1);
    this.submitPatient(patient);
  }

  formFields() {
    return [
      {name: 'company', label: 'Company/Provider', required: true},
      {name: 'member_name'},
      {name: 'plan'},
      {name: 'subscriber'},
      {name: 'subscriber_number'},
      {name: 'insurance_id', label: 'Group Number'},
      {name: 'notes', type: 'text'}
    ]
  }

  renderForm() {
    let {insurance, errors} = this.state;
    return <Form model_name="Insurance" fields={this.formFields()} model={insurance} onChange={r => this.onChange(r)} onSubmit={e => this.submit(e)} onCancel={e => this.cancelForm(e)} />
  }


  renderInsurance(insurance) {
    return (
      <div key={insurance.id}>
        <strong>{insurance.company}</strong><br />
        Member Name: {insurance.member_name} <br />
        Subscriber Name: {insurance.subscriber} <br />
        Subscriber Number: {insurance.subscriber_number} <br />
        Plan: {insurance.plan} <br />
        {insurance.notes}
        <button className="btn btn-link" onClick={e => this.editInsurance(insurance)}>Edit</button>
        <button className="btn btn-link" onClick={e => this.deleteInsurance(insurance)}>Delete</button>
      </div>
    );
  }

  render() {
    let {show_form} = this.state;
    if (show_form) return this.renderForm();
    let {patient} = this.props;

    return (
      <div>
        {patient.insurances.map(i => this.renderInsurance(i))}
        <button className="btn btn-default" onClick={e => this.addInsurance()}>Add Insurance</button>
      </div>
    )
  }

}


class Contacts extends Component {
  constructor(props) {
    super(props);
    let {contact} = props;
    this.state = {};
  }

  submit(e) {
    e.preventDefault();
    let {contact} = this.state;
    let {patient} = this.props;

    if (contact.id) {
      let index = patient.contacts.findIndex(i => i.id === contact.id);
      patient.contacts[index] = contact;
    } else {
      contact.id = generateUUID();
      patient.contacts.push(contact);
    }
    this.submitPatient(patient);
  }

  submitPatient(patient) {
    let url = "/admin/patients/" + patient.id
    request('PUT', url, {patient}).then(patient => {
      this.props.onChange(patient);
      this.setState({show_form: false});
    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors});
    });
  }

  onChange(contact) {
    this.setState({contact});
  }

  cancelForm(e) {
    e.preventDefault();
    this.setState({show_form: false})
  }

  addContact() {
    let contact = {company: '', subscriber: '', plan: '', contact_id: '', notes: ''};
    this.setState({contact, show_form: true});
  }

  editContact(contact) {
    this.setState({contact, show_form: true});
  }

  deleteContact(contact) {
    if (!confirm('Are you sure?')) return;
    let {patient} = this.props;
    let index = patient.contacts.findIndex(i => i.id === contact.id);
    patient.contacts.splice(index, 1);
    this.submitPatient(patient);
  }

  formFields() {
    return [
      {name: 'name',  required: true},
      {name: 'relationship', label: 'Relationship to Patient'},
      {name: 'phone'},
      {name: 'email'},
      {name: 'notes', type: 'text'}
    ]
  }

  renderForm() {
    let {contact, errors} = this.state;
    return <Form model_name="Contact" fields={this.formFields()} model={contact} onChange={c => this.onChange(c)} onSubmit={e => this.submit(e)} onCancel={e => this.cancelForm(e)} />
  }


  renderContact(contact) {
    return (
      <div key={contact.id}>
        <strong>{contact.name}</strong> ({contact.relationship})<br />
        {contact.phone}<br />
        {contact.email}<br />
        {contact.notes}
        <button className="btn btn-link" onClick={e => this.editContact(contact)}>Edit</button>
        <button className="btn btn-link" onClick={e => this.deleteContact(contact)}>Delete</button>
      </div>
    );
  }

  render() {
    let {show_form} = this.state;
    if (show_form) return this.renderForm();
    let {patient} = this.props;

    return (
      <div>
        {patient.contacts.map(i => this.renderContact(i))}
        <button className="btn btn-default" onClick={e => this.addContact()}>Add Contact</button>
      </div>
    )
  }
}





class MedicalTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {errors: {}};
  }

  submit(e) {
    e.preventDefault();
    let {medical_team_member} = this.state;
    let {patient} = this.props;

    let url = "/admin/patients/" + patient.id + "/medical_team_members";
    let method;


    if (medical_team_member.id) {
      url += "/" + medical_team_member.id;
      method = "PUT"
    } else {
      method = "POST"
    }

    let model = {};
    for (let field of this.formFields()) {
      model[field.name] = medical_team_member[field.name];
    }

    request(method, url, {medical_team_member: model}).then(patient => {
      this.props.onChange(patient);
      this.setState({show_form: false});
    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors});
    });
  }

  onChange(medical_team_member) {
    this.setState({medical_team_member});
  }

  cancelForm(e) {
    e.preventDefault();
    this.setState({show_form: false})
  }

  newPerson(e) {
    e.preventDefault();
    this.setState({new_person_form: true});
  }

  cancelNewPerson(e) {
    e.preventDefault();
    this.setState({new_person_form: false});
  }


  addNewPerson(p) {
    let medical_team_member = {person_id: p.id, primary: false};
    this.setState({medical_team_member, show_form: true, new_person_form: false});
  }

  addTeamMember() {
    let medical_team_member = {person_id: '', primary: false};
    this.setState({medical_team_member, show_form: true});
  }

  editTeamMember(medical_team_member) {
    this.setState({medical_team_member, show_form: true});
  }

  deleteTeamMember(medical_team_member) {
    if (!confirm('Are you sure?')) return;
    let {patient} = this.props;

    let url = "/admin/patients/" + patient.id + "/medical_team_members/" + medical_team_member.id;
    request('DELETE', url).then(patient => {
      this.props.onChange(patient);
    });
  }

  formFields() {
    return [
      {name: 'person_id', label: 'Person', required: true, type: 'remote-select2', src: "/admin/persons/as_options.json", search_key: PersonSearchKey},
      {name: 'primary', type: 'bool'},
      {name: 'button', label: 'Add New Person', type: 'button', className: 'btn btn-link', onClick: e => this.newPerson(e)}
    ]
  }

  renderForm() {
    let {medical_team_member, errors} = this.state;
    return <Form model_name="Medical Team" prefix="medical_team_member" fields={this.formFields()} model={medical_team_member} errors={errors} onChange={r => this.onChange(r)} onSubmit={e => this.submit(e)} onCancel={e => this.cancelForm(e)} />
  }

  newPersonForm() {
    let person = {};
    return (
      <div>
        <PersonForm person={person} onSubmit={p => this.addNewPerson(p)} onCancel={e => this.cancelNewPerson(e)} />
      </div>
    )
  }

  renderMember(medical_team_member) {
    let {person} = medical_team_member;
    let current_el
    if (medical_team_member.primary) {
      current_el = <span className="badge">PRIMARY ONCOLOGIST</span>
    }
    return (
      <div key={medical_team_member.id}>
        <strong>{person.name}</strong><br />

        {current_el}
        <button className="btn btn-link" onClick={e => this.editTeamMember(medical_team_member)}>Edit</button>
        <button className="btn btn-link" onClick={e => this.deleteTeamMember(medical_team_member)}>Delete</button>
      </div>
    );
  }

  render() {
    let {show_form, new_person_form} = this.state;
    if (new_person_form) return this.newPersonForm();
    if (show_form) return this.renderForm();
    let {patient} = this.props;

    return (
      <div>
        {patient.medical_team_members.map(m => this.renderMember(m))}
        <button className="btn btn-default" onClick={e => this.addTeamMember()}>Add Medical Team Member</button>
      </div>
    )
  }
}


class Locations extends Component {
  constructor(props) {
    super(props);
    let {location} = props;
    this.state = {};
  }

  submit(e) {
    e.preventDefault();
    let {location} = this.state;
    let {patient} = this.props;

    if (location.id) {
      let index = patient.locations.findIndex(i => i.id === location.id);
      patient.locations[index] = location;
    } else {
      location.id = generateUUID();
      patient.locations.push(location);
    }

    if (location.primary) {
      for (let i=0; i < patient.locations.length; i++) {
        if (location.id !== patient.locations[i].id) {
          patient.locations[i].primary = false;
        }
      }
    }

    this.submitLocation(patient);
  }

  submitLocation(patient) {
    let url = "/admin/patients/" + patient.id
    request('PUT', url, {patient}).then(patient => {
      this.props.onChange(patient);
      this.setState({show_form: false});
    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors});
    });
  }

  onChange(location) {
    this.setState({location});
  }

  cancelForm(e) {
    e.preventDefault();
    this.setState({show_form: false})
  }

  addLocation() {
    let location = {primary: false, address: '', country: 'US', city: '', state: '', zip: ''};
    this.setState({location, show_form: true});
  }

  editLocation(location) {
    this.setState({location, show_form: true});
  }

  deleteLocation(location) {
    if (!confirm('Are you sure?')) return;
    let {patient} = this.props;
    let index = patient.locations.findIndex(i => i.id === location.id);
    patient.locations.splice(index, 1);
    this.submitLocation(patient);
  }

  formFields() {
    return [
      {name: 'address'},
      {name :'country', type: 'select', collection: Countries, required: true},
      {name: 'state', type: 'select', collection: States, visible: c => c.country === 'US', required: true},
      {name: 'city', required: true},
      {name: 'zip', required: true},
      {name: 'primary', type: 'bool'},
      {name: 'geolocation', type: 'geolocation'}
    ]
  }

  renderForm() {
    let {location, errors} = this.state;
    return <Form model_name="Location" fields={this.formFields()} model={location} onChange={c => this.onChange(c)} onSubmit={e => this.submit(e)} onCancel={e => this.cancelForm(e)} />
  }


  renderLocation(location) {

    let primary_el
    let geolocation_status
    if (location.primary) {
      primary_el = <span className="badge">PRIMARY</span>
    }
    if (location.lat) {
      geolocation_status = <span className="label label-success">Geolocated!</span>
    } else {
      geolocation_status = <span className="label label-danger">Not Geolocated</span>
    }

    return (
      <div key={location.id}>
        {primary_el}<br/>
        <strong>{location.address}</strong><br />
        {location.city}<br />
        {location.state}<br />
        {location.country}<br />
        {location.zip}<br />
        {geolocation_status}<br/>

        <button className="btn btn-link" onClick={e => this.editLocation(location)}>Edit</button>
        <button className="btn btn-link" onClick={e => this.deleteLocation(location)}>Delete</button>
        <hr/>
      </div>
    );
  }

  render() {
    let {show_form} = this.state;
    if (show_form) return this.renderForm();
    let {patient} = this.props;

    return (
      <div>
        {patient.locations.map(i => this.renderLocation(i))}
        <button className="btn btn-default" onClick={e => this.addLocation()}>Add Location</button>
      </div>
    )
  }
}


class Notes extends Component {
  constructor(props) {
    super(props);
    let {patient} = props;
    this.state = {patient};
  }

  submit(e) {
    e.preventDefault();
    let {patient} = this.state;

    let url = "/admin/patients/" + patient.id
    request('PUT', url, {patient}).then(patient => {
      this.props.onChange(patient);

    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors});
    });
  }

  onChange(patient) {
    this.setState({patient});
  }

  formFields() {
    return [
      {name: 'notes', type: 'text', rows: 6, required: true}
    ]
  }

  render() {
    let {patient} = this.state;
    return <Form model_name="Notes" fields={this.formFields()} model={patient} onChange={c => this.onChange(c)} onSubmit={e => this.submit(e)} />
  }
}

class Emails extends Component {
  constructor(props) {
    super(props);
  }


  change(e, index) {
    let {value} = e.target;
    let {patient, onChange} = this.props;
    patient.emails[index] = value;
    onChange(patient);
  }

  addRow() {
    let {patient, onChange} = this.props;
    patient.emails.push('');
    onChange(patient);
  }

  deleteRow(index) {
    let {patient, onChange} = this.props;
    patient.emails.splice(index, 1);
    onChange(patient);
  }

  render() {
    let {patient} = this.props;
    return (
      <div className="form-group contact-group">
        <label>Emails</label>
        {patient.emails.map((email, index) => {
          return (
            <div key={index} className="patient-contact-field">
              <input type="text" className="form-control" value={email} onChange={e => this.change(e, index)} />
              <button className="btn btn-default btn-sm" onClick={e => this.deleteRow(index)}><i className="glyphicon glyphicon-trash" /></button>
            </div>
          )
        })}
        <div>
          <button className="btn btn-success btn-sm" onClick={e => this.addRow()}>Add Email</button>
        </div>
      </div>
    );
  }
}



class Phones extends Component {
  constructor(props) {
    super(props);
  }


  change(e, index) {
    let {name, value} = e.target;
    let {patient, onChange} = this.props;
    patient.phones[index][name] = value;
    onChange(patient);
  }

  addRow() {
    let {patient, onChange} = this.props;
    patient.phones.push({type: 'home', number: ''});
    onChange(patient);
  }

  deleteRow(index) {
    let {patient, onChange} = this.props;
    patient.phones.splice(index, 1);
    onChange(patient);
  }

  render() {
    let {patient} = this.props;
    return (
      <div className="form-group contact-group">
        <label>Phones</label>
        {patient.phones.map((phone, index) => {
          return (
            <div key={index} className="patient-contact-field">
              <select name="type" value={phone.type} onChange={e => this.change(e, index)}>
                {PHONE_TYPES.map(t => <option key={t} value={t}>{Inflector.titleize(t)}</option>)}
              </select>
              <input type="text" name="number" className="form-control" value={phone.number} onChange={e => this.change(e, index)} />
              <button className="btn btn-default btn-sm" onClick={e => this.deleteRow(index)}><i className="glyphicon glyphicon-trash" /></button>
            </div>
          )
        })}
        <div>
          <button className="btn btn-success btn-sm" onClick={e => this.addRow()}>Add Phone</button>
        </div>
      </div>
    );
  }
}

const PHONE_TYPES = ['home', 'work', 'cell', 'other'];

class Billing extends Component {
  constructor(props) {
    super(props);
    let {patient} = props;
    this.state = {patient, errors: {}};
    this.onChange = this.onChange.bind(this);
  }

  submit(e) {
    e.preventDefault();
    let {patient} = this.state;
    this.setState({submitting: true});

    let url = "/admin/patients/" + patient.id
    request('PUT', url, {patient}).then(patient => {
      this.props.onChange(patient);
      this.setState({patient, submitting: false});
    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors, submitting: false});
    });
  }

  onChange(patient) {
    this.setState({patient});
  }

  formFields() {
    let {patient} = this.state;
    return [
      {name: 'billing_url', hint: 'Provide patient Stipe Customer URL (like: https://dashboard.stripe.com/customers/cus_123)'},
      {name: 'billing_notes', type: 'text', rows: 6},
      {name: 'info', type: 'component', component: BillingEditorInformation, props: {patient}}
    ]
  }

  render() {
    let {patient, errors, submitting} = this.state;
    return <Form model_name="Billing Information" prefix="patient" fields={this.formFields()} model={patient} submitting={submitting} errors={errors} onChange={this.onChange} onSubmit={e => this.submit(e)} />

  }
}


class BillingEditorInformation extends Component {
  render() {
    let {patient} = this.props;
    if (!patient.billing_edited_at) return null;
    let date = moment(patient.billing_edited_at).format('LLL');

    return (
      <div>
        Last updated on {date} by {patient.billing_edited_by}
      </div>
    );
  }
}
