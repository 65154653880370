import React, {Component} from 'react';
import request from '../request';
import GroupsContainer from './groups_container';
import {TrialHeader, TrialBottomNav} from './trial_partials';

export default class TrialGroupsImporter extends Component{
  constructor(props) {
    super(props);
    let {groups, cycles_length} = props;
    this.state = {groups, cycles_length};
    this.onChangeGroups = this.onChangeGroups.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  submit() {
    let confirmation = 'You will delete any existing groups and replace with these new groups. Are you sure?';
    if (!confirm(confirmation)) return;

    let {trial} = this.props;
    let {groups, cycles_length} = this.state;

    let url = `/admin/trials/${trial.id}/groups`;
    request('PUT', url, {groups_data: {groups, cycles_length}}).then(r => {
      let back_path = `/admin/trials/${trial.id}/edit`
      window.location = back_path;
    });
  }

  onChangeGroups(groups) {
    this.setState({groups});
  }

  onChange(e) {
    let {name, value} = e.target;
    this.setState({[name]: value});
  }

  unknownItemsPresent() {
    let {groups} = this.state;
    for (let group of groups) {
      if (group.unknown_items && group.unknown_items.length > 0) {
        return true;
      }
    }
    return false;
  }


  render() {
    let {groups, cycles_length} = this.state;
    let {trial, trial_data, current_admin} = this.props;
    let submit_disabled = this.unknownItemsPresent();
    let return_path = `/admin/trials/${trial.id}/edit`;
    let ct_link = trial.study_url + '#armgroup';

    return (
      <div>
        <TrialHeader trial={trial} />
        <div className="pull-right">
          <a href={ct_link} target="_blank" className="btn btn-primary">Open on CT.gov</a>
          <a href={return_path} className='btn btn-default'>Return to Trial</a>
        </div>

        <h1>Review Imported Groups</h1>
        <div className="row">
          <div className="col-md-6">
            <GroupsContainer groups={groups} onChange={this.onChangeGroups} closeable={true} currentAdmin={current_admin} createLinks changelog reviewItems={true} />

            <div className="form-group">
              <label>Cycle Length (In Weeks)</label>
              <input type="number" className="form-control" name="cycles_length" value={cycles_length || ''} onChange={this.onChange} />
            </div>

            <button className="btn btn-success" disabled={submit_disabled} onClick={e => this.submit()}>Save Groups</button>
            <a href={return_path} className="btn btn-default">Cancel and abandon changes</a>
          </div>

          <div className="col-md-6">
            <TrialInformation trial_data={trial_data} />
          </div>
        </div>
        <TrialBottomNav trial={trial} action="group_importer" return_path={return_path}/>
      </div>
    )
  }
}

class TrialInformation extends Component {

  renderInterventions() {
    let {trial_data} = this.props;
    if (!trial_data.interventions || trial_data.interventions.length === 0) return null;

    return (
      <div>
        <h3>Interventions</h3>
        {trial_data.interventions.map((intervention, index) => {
          return (
            <div className="intervention group_panel_list" key={index}>
              <label>Type:</label> {intervention.type}<br/>
              <label>Name:</label> {intervention.name}<br/>
              <label>Description:</label> {intervention.description}<br/>
              <label>Label:</label> {intervention.armGroupLabels}<br/>
              <label>Other Name:</label>{intervention.other_name}<br/>
            </div>
          )
        })}
      </div>
    )
  }

  renderArmGroups() {
    let {trial_data} = this.props;
    if (!trial_data.arm_groups || trial_data.arm_groups.length === 0) return null;
    return (
      <div>
        <h3>ARM Groups</h3>
        {trial_data.arm_groups.map((arm_group, index) => {
          return (
            <div className="group_panel_list" key={index}>
              <label>Label:</label> {arm_group.label}<br/>
              <label>Type:</label> {arm_group.type}<br/>
              <label>Description:</label> {arm_group.description}<br/>
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderArmGroups()}
        {this.renderInterventions()}
      </div>
    )
  }
}
