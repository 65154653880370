import React, {Component} from 'react';

import OptionsCache from './options_cache';
import PatientFileForm from '../patient_file_form';
import Loading from '../loading';
import request from '../../request';


export default class Files extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.cancerTypeCache = new OptionsCache({source: 'patient_cancer_types', onChange: e => this.forceUpdate()});
    this.fileCache = new OptionsCache({source: 'files', onChange: e => this.forceUpdate()});
  }

  componentDidMount() {
    this.fetchFiles();
    let el = $(this.refs.modal_container);
    el.modal({show: false});
    el.on('hide.bs.modal', () => {
      this.close();
    });
  }

  fetchFiles() {
    this.setState({loading: true});
    let {patient} = this.props;
    let url = '/admin/patients/' + patient.id + '/files';
    request('GET', url).then(files => {
      this.setState({files, loading: false});
    });
  }

  onSubmitFile(file) {
    let {files} = this.state;
    let index = files.findIndex(f => f.id === file.id);

    if (index === -1) {
      files.push(file);
    } else {
      files[index] = file;
    }

    this.setState({files, current_file: null});
    $(this.refs.modal_container).modal('hide')
  }

  addFile() {
    this.setState({current_file: {}}, () => $(this.refs.modal_container).modal('show'));
  }

  editFile(current_file) {
    this.setState({current_file}, () => $(this.refs.modal_container).modal('show'));
  }

  deleteFile(file) {
    if (!window.confirm('Are you sure?')) return;

    let {patient} = this.props;
    let url = '/admin/patients/' + patient.id + '/files/' + file.id;
    request('DELETE', url).then(files => {
      this.setState({files});
    });
  }

  close() {
    this.setState({current_file: null});
  }

  renderForm() {
    let {patient} = this.props;
    let {current_file} = this.state;
    if (!current_file) return null;

    return <PatientFileForm patient={patient} record={current_file} onClose={e => this.setState({current_file: null})} onSubmit={f => this.onSubmitFile(f)} />
  }

  renderModal() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" aria-label="Close" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              {this.renderForm()}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    )
  }


  renderList() {
    let {loading, files} = this.state;
    if (loading) return <Loading />
    if (!files) return null;

    return (
      <div>
        <button className="btn btn-success pull-right" onClick={e => this.addFile()}>Add File</button>
        <div className="clearfix"></div>
        <p><b>Files</b></p>
        <table className="table table-condensed table-striped jt-log-table">
          <thead>
            <tr>
              <th></th>
              <th>File</th>
              <th>Description</th>
              <th>Created at</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {files.map(file => {

              let icon;
              if (file.preview_url) {
                icon = <img src={file.preview_url} />
              }
              let date = moment(file.created_at).format('LL');
              return(
                <tr key={file.id}>
                  <td>{icon}</td>
                  <td><a href={file.url}>{file.filename}</a></td>
                  <td>{file.description}</td>
                  <td>{date}</td>
                  <td>
                    <div className="btn-group">
                      <button className="btn btn-default btn-sm" onClick={e => this.editFile(file)}>Edit</button>
                      <button className="btn btn-default btn-sm" onClick={e => this.deleteFile(file)}>Delete</button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    );

  }

  render() {
    return (
      <div>
        {this.renderList()}
        {this.renderModal()}
      </div>
    );
  }
}
