import React, {Component} from 'react';
import request from '../../request';

export default class TrialGroupsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
      </div>
    );
  }
}
