import React, {Component} from 'react';
import Form from './form';
import request from '../request';
import CompanyForm from './company_form';
import {CompanySearchKey, CombinationSearchKey, MoaTypeSearchKey, InterventionFamilySearchKey} from '../constants.js.erb';

export default class InterventionForm extends Component {
  constructor(props) {
    super(props);
    let intervention = Object.assign({}, props.intervention);
    this.state = {intervention, errors: {}};
    this.onCompanyCreated = this.onCompanyCreated.bind(this);
  }

  submit(e) {
    e.preventDefault();
    let {intervention} = this.state;

    if ((intervention.fda_approved_any_cancer && !this.props.intervention.fda_approved_any_cancer) && (!intervention.display_names || intervention.display_names.length === 0)) {
      let confrm_message = 'You have marked this intervention as FDA-approved, but no trade names have been provided. Please ensure the approved trade name is entered.'
      if (!confirm(confrm_message)) return;
    }

    this.setState({submitting: true});
    let url = "/admin/interventions";
    let method;

    if (intervention.id) {
      url += "/" + intervention.id;
      method = "PUT"
    } else {
      method = "POST"
    }

    let model = {};
    for (let field of this.formFields()) {
      model[field.name] = intervention[field.name];
    }

    request(method, url, {intervention: model}).then(intervention => {
      let {return_path} = this.props;
      this.setState({errors: [], submitting: false});
      window.location = return_path;
    }).catch(({errors}) => {
      this.setState({errors, submitting: false});
    });
  }

  onChange(intervention) {
    this.setState({intervention});
  }

  onCompanyCreated(company) {
    let {intervention} = this.state;
    let company_ids = [...intervention.company_ids, company.id];
    intervention.company_ids = company_ids;
    this.setState({intervention});
  }

  openCompanyModal(){
    window.dispatchEvent(new Event(EVENT_NAME));
  }

  formFields() {
    let {route_of_administrations, molecular_types} = this.props;
    return [
      {name: 'name', required: true},
      {name: 'aliases', type: 'text', hint: 'Add any aliases (must be unique), separated by commas'},
      {name: 'company_ids', label: 'Companies', type: 'remote-select2', multiple: true, src: "/admin/companies/as_options.json", search_key: CompanySearchKey},
      {name: 'add_company', label: 'Create Company', type: 'button', className: 'btn btn-default btn-sm', onClick: e => this.openCompanyModal()},
      {name: 'fda_approved_any_cancer', label: 'FDA approved in ANY indication', type: 'bool'},
      {name: 'display_names', type: 'text', label: 'Trades Name(s)', hint: 'Add any trade names, separated by commas', visible: i => i.fda_approved_any_cancer },
      {name: 'moa_type_id', label: 'MOA or Diagnostic Type', type: 'remote-select2', showLink: true, linkTemplate: '/admin/moa_types/{id}/edit', src: "/admin/moa_types/as_options.json", search_key: MoaTypeSearchKey, required: true},
      {name: 'combination_ids', type: 'remote-select2', multiple: true, src: "/admin/combinations/as_options.json", search_key: CombinationSearchKey},
      {name: 'description', label: 'Medical Description', type: 'text'},
      {name: 'patient_friendly_description', type: 'text'},
      {name: 'route_of_administration_ids', label: 'Route of Administration', type: 'select2', multiple: true, collection: route_of_administrations},
      {name: 'molecular_type_ids', label: 'Molecular Types', type: 'select2', multiple: true, collection: molecular_types},
      {name: 'intervention_family_ids', label: 'Intervention Families', type: 'remote-select2', multiple: true, src: "/admin/intervention_families/as_options.json", search_key: InterventionFamilySearchKey},
      {name: 'default_label', label: 'Default Details (when adding to groups)', type: 'text'},
      {name: 'includes_placebo', type: 'bool'},
      {name: 'research_notes', label: 'Research Notes (e.g. searched, nothing found; undisclosed target; etc.)', type: 'text'},
      {name: 'drug_info_url'},
      {name: 'drug_info_url_2'},
      {name: 'drug_info_url_3'},
      {name: 'published', type: 'bool'}
    ];
  }

  render() {
    let {intervention, errors, submitting} = this.state;

    return (
      <div className="intervention_form_holder">
        <Form model_name="Intervention" prefix="intervention" fields={this.formFields()} model={intervention} errors={errors} submitting={submitting} onChange={r => this.onChange(r)} onSubmit={e => this.submit(e)} />
        <CompanyModal intervention={intervention} onCreate={this.onCompanyCreated} />
      </div>
    )
  }

}

const EVENT_NAME = 'show-company-modal';

class CompanyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onCompanyCreated = this.onCompanyCreated.bind(this);
  }

  componentDidMount() {
    $(this.refs.modal_container).modal({show: false});
    $(this.refs.modal_container).on('hide.bs.modal', () => {
      this.setState({show_modal: false});
    });

    window.addEventListener(EVENT_NAME, this.showModal);
  }

  componentWillUnmount() {
    window.removeEventListener(EVENT_NAME, this.showModal);
  }

  showModal() {
    $(this.refs.modal_container).modal('show');
    this.setState({show_modal: true});
  }

  closeModal() {
    $(this.refs.modal_container).modal('hide');
  }

  onCompanyCreated(company) {
    let {onCreate} = this.props;
    this.closeModal();
    onCreate(company);
  }

  renderContent() {
    let {intervention} = this.props;
    let {show_modal} = this.state;
    if (!show_modal) return null;

    let company = {};
    if (intervention.id) {
      company.intervention_ids = [intervention.id];
    }

    return <CompanyForm company={company} onCancel={this.closeModal} onCreate={this.onCompanyCreated} />
  }

  renderModal() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              {this.renderContent()}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderModal()}
      </div>
    )
  }
}
