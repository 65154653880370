import React, {Component} from 'react';
import AdminCollectionModals from './admin_collection_modals';


export default class CopyCollectionWidget extends Component {
  copy() {
    let {collection_trial_id, trial_id} = this.props;
    window.dispatchEvent(new CustomEvent('copy-trial', { detail: {collection_trial_id: collection_trial_id, trial_id: trial_id}}))
  }


  render() {
    let {patient_id, collection_id} = this.props;
    return (
      <div>
        <button onClick={e => this.copy()} className="btn btn-xs btn-default">Copy to Another Collection</button>
        <AdminCollectionModals patient_id={patient_id} collection_id={collection_id}/>
      </div>
    );
  }
}
