import React, {Component} from 'react';
import Form from './form';
import request from '../request';
const fields = [
  {name: 'reason', type: 'text'}
];
export default class ArchivePopulationForm extends Component {
  constructor(props) {
    super(props);
    let data = {reason: ''};
    this.state = {data};
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
  }


  submit() {
    let {trial_id, population_id, return_url} = this.props;
    let {data} = this.state;

    let url = "/admin/trials/" + trial_id + "/populations/" + population_id + "/archive";
    let archive = data;
    request("PUT", url, {archive}).then(response => {
      window.location = return_url;
    });
  }

  onChange(data) {
    this.setState({data});
  }

  render() {
    let {data} = this.state;
    return (
      <div>
        <Form model={data} fields={fields} onChange={this.onChange} onSubmit={this.submit} submitLabel="Archive Population"/>
      </div>
    );
  }
}
