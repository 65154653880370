import React, {Component} from 'react';
import TrialCollectionStarsRating from './trial_collection_stars_rating';

export default class CollectionsReport extends Component {

  renderCollection({collections, patient, patient_trial_data}) {
    let {efficacy_notes, side_effects_notes} = patient_trial_data;
    let link_to_patient = '/admin/patients/' + patient.id;

    return (
      <div key={patient.id} className="collections_report_item">
        <h4><a href={link_to_patient} target="_blank">{patient.name}</a></h4>
        <p>{patient.cancer_type}</p>

        <h5><b>Collections</b> <span className="subtle_text">(date created)</span></h5>
        <ul className="in_collection_list">
          {collections.map(collection => {
                let created_at = moment(collection.created_at).format('LL');
                let link_to_collection = link_to_patient + '/collections/' + collection.id;
                return (
                  <li  key={collection.id}><a href={link_to_collection} target="_blank">{collection.name}</a> ({created_at})</li>
                )
              })}
        </ul>

        <div className="collections_report_content_holder">
          <TrialCollectionStarsRating patient_trial_data={patient_trial_data} />
        </div>
      </div>
    );
  }

  render() {
    let collections = groupByPatient(this.props.collections)
    return (
      <div>
        {collections.map(c => this.renderCollection(c))}
      </div>
    );
  }
}


function groupByPatient(collections) {
  let grouped = {};
  for (let {collection, patient, patient_trial_data}  of collections) {
    if (!grouped[patient.id]) grouped[patient.id] = {patient, patient_trial_data, collections: []};
    grouped[patient.id].collections.push(collection)
  }

  let keys = Object.keys(grouped);
  return keys.map(patient_id => { return grouped[patient_id] });
}
