import React, {Component} from 'react';
export default class IndeterminateCheckbox extends Component {
  componentDidMount() {
    let {indeterminate} = this.props;
    if (this.refs.checkbox && indeterminate) {
      this.refs.checkbox.indeterminate = true;
    }
  }

  render() {
    let {className, checked, onChange} = this.props;
    let params = {className, checked, onChange}
    return <input type="checkbox" ref="checkbox" {...params} />
  }
}
