import React, {Component} from 'react';
import MapViewer from './map_viewer';
import Loading from './loading';
import request from '../request';

export default class GeolocationPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  fetchGeolocations() {
    let url = '/search_geolocation';
    let {record, paramsMapping} = this.props;
    let location = {};

    for (let f of FIELDS) {
      if(paramsMapping && paramsMapping[f]) {
        location[f] = record[paramsMapping[f]]
      } else {
        location[f] = record[f]
      }
    }

    request('POST', url, {location}).then(locations => {
      this.setState({locations, loading: false});
    });
  }

  selectLocation(location) {
    let {record, globalOnChange} = this.props;
    record.lat = location.lat;
    record.lon = location.lon;
    globalOnChange(record);
    this.setState({show_form: false});
  }

  deleteGeolocation() {
    if (!confirm('Are you sure?')) return;

    let {record, globalOnChange} = this.props;
    record.lat = null;
    record.lon = null;
    globalOnChange(record);
  }

  openForm() {
    this.setState({show_form: true, loading: true});
    this.fetchGeolocations();
  }

  renderNoGeolocation() {
    return (
      <div>
        <p>No Geolocation</p>
        <button className="btn btn-default" onClick={e => this.openForm()}>Search Geolocation</button>
      </div>
    );
  }


  renderLocation() {
    let {record} = this.props;
    return(
      <div>
        <MapViewer lat={record.lat} lon={record.lon} width={650} height={400} />
        <button className="btn btn-default" onClick={e => this.openForm()}>Edit Geolocation</button>
        <button className="btn btn-danger" onClick={e => this.deleteGeolocation()}>Delete Geolocation</button>
      </div>
    );
  }


  renderPicker() {
    let {locations, loading} = this.state;
    if (loading) return <Loading text="Searching Locations" />
    let content;
    if (locations.length === 0) {
      content = <p>No locations found</p>;
    } else {
      content = locations.map(l => {
        return (
          <div key={l.place_id}>
            <button className="btn btn-link" onClick={e => this.selectLocation(l)}>{l.display_name}</button>
          </div>
        );
      })
    }

    return (
      <div>
        {content}
        <button className="btn btn-default" onClick={e => this.fetchGeolocations()}>Refresh</button>
      </div>
    );
  }

  render() {
    let {record} = this.props;
    let {show_form} = this.state;
    if (show_form) return this.renderPicker();
    if (!record || !record.lat || !record.lon) return this.renderNoGeolocation();
    return this.renderLocation();
  }
}

const FIELDS = ['address', 'city', 'country', 'state', 'zip']
