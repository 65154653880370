import React, {Component} from 'react';
import BaseStep from './base_step';

export  default class TabNextSteps extends BaseStep {
  title() {
    return "Next Steps";
  }

  modelName() {
    return "Step";
  }


  getSection() {
    return 'next_steps';
  }

  defaultItem() {
    return {status: 'not_completed'}
  }


  formFields() {
    return [
      {name: 'title', required: true},
      {name: 'description', type: 'text'},
      {name: 'status', type: 'select', collection: NEXT_STEPS_STATUSES}
    ];
  }


  renderItem(item) {
    let status_el;
    if (item.status === 'completed') {
      status_el = <span className="completed_step"><i className="fas fa-check"></i></span>
    } else {
      status_el = <i className="far fa-square"></i>
    }
    return (
        <div className="step_item">
          <div className="step_status">
            {status_el}
          </div>
          <div className="step_details">
            <h4>{item.title}</h4>
            <p>{item.description}</p>
          </div>
        </div>
    );
  }
}

const NEXT_STEPS_STATUSES = {
  completed: 'Completed',
  not_completed: 'Not Completed'
}
