import React, {Component} from 'react';
import Population from './populations/population';
import request from '../request';
export default class PopulationEditor extends Component {
  constructor(props) {
    super(props);
    let {population} = props;
    this.state = {population};
    this.submit = this.submit.bind(this);
  }


  submit(data) {
    this.setState({submitting: true})
    let {population} = this.state;

    let url = '/admin/trials/' + population.trial_id + '/populations/' + population.id;
    let params = {data}
    return request('PUT', url, {population: params})
      .then(population => {
        this.setState({population, save_success: true, submitting: false})
        // window.setTimeout(this.resetSaveState, 5000);
      })
      .catch(({errors}) => {
        this.setState({save_errors: errors, save_error: true, submitting: false})
        // window.setTimeout(this.resetSaveState, 5000);
      })
  }


  render() {
    let {population} = this.state;
    return <Population population={population} onChange={this.submit} />
  }
}
