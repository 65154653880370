import React, {Component} from 'react';
import JourneyTable from './journey_table';
import NeestedRecord from './neested_record';
import OptionsCache from './options_cache';
import {globalDefaults} from './journey_data';
import Form from './journey_tracker_form';
import * as Inflector from 'inflected';
import * as DateHelpers from './date_helpers';
import {OrganSearchKey} from '../../constants.js.erb';
import Findings from './findings';
import {FindingStatusModal} from './finding_status';

export default class TumorsLog extends JourneyTable {
  constructor(props) {
    super(props);
    let findings = filterTumorLogFindings(props.data.findings);
    this.state = {findings};
    this.findingCache = new OptionsCache({source: 'findings', onChange: e => this.forceUpdate()});
    this.organCache = new OptionsCache({source: 'organs', onChange: e => this.forceUpdate()});
  }

  show(current) {
    this.setState({current});
  }

  renderForm() {
    let {readOnly, patient, data, fields_changelog, onAddBatch, onEditBatch} = this.props;
    let {current} = this.state;
    let props = {readOnly, patient, data, fields_changelog, onAddBatch, onEditBatch, record: current};
    props.onClose = e => this.setState({current: null});
    props.organCache = this.organCache;

    return <Findings.Finding {...props} />
  }

  renderList() {
    let {readOnly, patient, fields_changelog, onAddBatch, onEditBatch} = this.props;
    let {findings} = this.state;

    return (
      <div>
        <p><b>Tumors Log</b></p>
        <table className="table table-condensed table-striped jt-log-table" id="tumor_logs_list">
          <thead>
            <tr>
              <th>Finding</th>
              <th>Organ</th>
              <th>Date Reported</th>
              <th>Treating?</th>
              <th></th>
              <th></th>

            </tr>
          </thead>
          <tbody>
            {findings.map((finding, index) => {
              let action_label = readOnly ? 'Show' : 'Edit';
              let finding_text = this.findingCache.get(finding.finding_id).text;
              let organ_text = finding.organ_id ? this.organCache.get(finding.organ_id).text : null;

              let treatement = finding.treatement ? Inflector.titleize(finding.treatement) : null;
              let status_count = finding.status.length;

              return(
                <tr key={finding.id}>
                  <td>{finding_text}</td>
                  <td>{organ_text}</td>
                  <td>{DateHelpers.formatEstimated(finding, {field: 'date_reported'})}</td>
                  <td>{treatement}</td>
                  <td>
                    <FindingStatusModal readOnly={readOnly} model={finding} embedded={true} field={{patient, fields_changelog, callbacks: {onAddBatch, onEditBatch}}}/>
                  </td>
                  <td className="actions">
                    <div className="btn-group">
                      <button className="btn btn-default btn-sm" onClick={e => this.show(finding)}>{action_label}</button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    );

  }
}

function filterTumorLogFindings(findings) {
  return findings.filter(f => {
    if (f.is_tumor_log) return true;
    return false;
  });
}
