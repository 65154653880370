import React, {Component} from 'react';

import {EventModal, EventPreview} from './journey_tracker/event_modal';
import ReviewChangesModal from './journey_tracker/review_changes_modal';

import OptionsCache from './journey_tracker/options_cache';
import Details from './journey_tracker/details';
import TumorsLog from './journey_tracker/tumors_log';
import Labs from '././journey_tracker/labs';
import ComorbidConditions from '././journey_tracker/comorbid_conditions';
import Sequencings from '././journey_tracker/sequencing';
import Interventions from '././journey_tracker/interventions';
import Symptoms from '././journey_tracker/symptoms';
import Findings from '././journey_tracker/findings';
import EventsList from './journey_tracker/events_list';
import Files from './journey_tracker/files';
import Settings from './journey_tracker/settings';
import Form from './form';
import request from '../request';



export default class JourneyTracker extends Component {
  constructor(props) {
    super(props);
    let data = initializeData(props.journey);

    let current_event = null;
    // current_event = {type: 'custom', data: {description: ''}, files: []};

    let tab = getAnchorFromURL() || 'details';
    let {fields_changelog, settings, cache} = props;

    // Initialize the cache
    OptionsCache.initializeCache(cache);

    this.state = {data, tab, current_event, fields_changelog, settings, log: {}, show_event_modal: false};

    this.change = this.change.bind(this);
    this.addDeleteLog = this.addDeleteLog.bind(this);
    this.addBatch = this.addBatch.bind(this);
    this.editBatch = this.editBatch.bind(this);
    this.changeTabListener = this.changeTabListener.bind(this);
    this.changeSettings = this.changeSettings.bind(this);
  }


  componentDidMount() {
    window.addEventListener('change_tab', this.changeTabListener, false);
  }

  componentWillUnmount() {
    window.removeEventListener('change_tab', this.changeTabListener);
  }

  reload() {
    if (!confirm('If you cancel this event, all details about this event and changes related to this event will be lost. Are you sure you want to cancel this event?')) return;

    let {patient} = this.props;
    let url = '/admin/patients/' + patient.id + '/journey_tracker'
    request('GET', url).then(reply => {
      let {journey, fields_changelog, settings} = reply;
      let data = initializeData(journey);
      this.setState({data, settings, fields_changelog, current_event: null, log: {}});
    });
  }

  submit() {
    this.setState({submitting: true, show_review_changes_modal: false});
    let {patient} = this.props;
    let {data, current_event, log} = this.state;
    let url = '/admin/patients/' + patient.id + '/journey_tracker';
    let params = {journey: data, event: current_event, changelog: log}

    request('PUT', url, {journey_tracker: params}).then(reply => {
      let {journey, fields_changelog} = reply;
      let data = initializeData(journey);
      this.setState({data, fields_changelog, current_event: null, log: {}, submitting: false});
    });
  }

  change(path, field_name, value, meta) {
    let {data, log} = this.state;

    // Make the change
    let section = getSection(data, path);
    let original = section[field_name];
    section[field_name] = value;

    // Add the log entry
    let key = path + ':' + field_name;
    if (log[key] === undefined) {
      log[key] = {path, field_name, original, meta}
    }

    log[key].value = value;
    log[key].meta.value_label = meta.value_label;

    this.setState({data, log});
  }

  addDeleteLog(base_path, section_label, record) {
    let {data, log} = this.state;

    // Make the change
    let section = getSection(data, base_path);
    let index = section.findIndex(i => i.id === record.id);
    section.splice(index, 1);

    // Add the log entry
    let path = base_path + '/[id:' + record.id + ']/!id';
    let meta = {section_label};
    log[path] = {path, field_name: '!id', meta};

    this.setState({data, log});
  }

  addBatch(base_path, record, batch_log) {
    let {data, log} = this.state;

    // Make the change
    let section = getSection(data, base_path);
    section.push(record);

    // Add the log entries
    let path = base_path + '/[id:' + record.id + ']';
    log = this.addBatchLogs(log, path, record, batch_log)

    this.setState({data, log});
  }

  editBatch(base_path, record, batch_log) {
    let {data, log} = this.state;

    // Make the change
    let section = getSection(data, base_path);
    let index = section.findIndex(i => i.id === record.id);
    section[index] = record;

    let path = base_path + '/[id:' + record.id + ']';
    log = this.addBatchLogs(log, path, record, batch_log)

    this.setState({data, log});
  }

  addBatchLogs(log, path, record, batch_log) {
    // Add the log entries
    for (let change of batch_log) {
      let {field_name, original, value, meta} = change;
      let key = path + ':' + field_name;
      if (log[key] === undefined) {
        log[key] = {path, field_name, original, meta}
      }
      log[key].value = value;
    }
    return log;
  }

  changeSettings(settings) {
    this.setState({settings});
  }

  changeTabListener(event) {
    let {tab} = event.detail;
    this.changeTab(tab);
  }

  changeTab(tab) {
    this.setState({tab});
  }

  newEvent() {
    this.setState({show_event_modal: true});
  }

  editEvent() {
    this.setState({show_event_modal: true});
  }

  cancelNewEvent() {
    this.setState({show_event_modal: false});
    this.scrollToTop();
  }


  changeCurrentEvent(current_event) {
    this.setState({current_event, show_event_modal: false})
    this.scrollToTop();
  }

  reviewChanges() {
    this.setState({show_review_changes_modal: true});
  }

  cancelReviewChanges() {
    this.setState({show_review_changes_modal: false});
    this.scrollToTop();
  }

  scrollToTop() {
    $(window).scrollTop(0);
  }

  renderTabsList() {
    let current_tab = this.state.tab;
    let tabs = Object.keys(TABS).map(k => {
      let {label} = TABS[k];
      let className = k === current_tab ? 'active' : null;
      let anchor = '#' + k;
      let id = "tab_" + k;
      return <li key={k} id={id} role="presentation" className={className}><a href={anchor} onClick={e => this.changeTab(k)}>{label}</a></li>
    });

    return (
      <ul className="nav nav-pills nav-stacked light-nav">
        {tabs}
      </ul>
    )
  }

  renderCurrentTab() {
    let {patient} = this.props;
    let {tab, data, current_event, fields_changelog, settings} = this.state;
    let {component} = TABS[tab];
    let readOnly = !current_event;
    let props = {patient, data, current_event, readOnly, fields_changelog, settings, onChange: this.change, onAddBatch: this.addBatch, onEditBatch: this.editBatch, onDelete: this.addDeleteLog, onChangeSettings: this.changeSettings}
    return React.createElement(component, props);
  }



  renderEditButton() {
    return (
      <div>
        <button className="btn btn-warning btn-block btn-lg btn-jt-edit" onClick={e => this.newEvent()}>Edit Patient</button>
        <hr/>
      </div>
    );
  }

  renderEventPreview(event) {
    let {patient} = this.props;
    return (
      <div className="event-in-progress-box">
        <EventPreview patient={patient} event={event} summary={true} />
        <div className="event-in-progress-actions">
          <button className="btn btn-success btn-block" onClick={e => this.reviewChanges()}>Review &amp; Submit</button>
          <div className="event-in-progress-subactions">
            <button className="btn btn-sm pull-right" onClick={e => this.reload()}>Cancel Event</button>
            <button className="btn btn-sm pull-left" onClick={e => this.editEvent()}>Edit Event</button>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    );
  }

  renderHeader() {
    let {current_event} = this.state;
    if (current_event) {
      return this.renderEventPreview(current_event);
    } else {
      return this.renderEditButton();
    }
  }

  renderEvent() {
    let {patient} = this.props;
    let {show_event_modal, show_review_changes_modal, current_event, settings, log} = this.state;


    if (show_event_modal) {
      return <EventModal key="e" event={current_event} patient={patient} settings={settings} onChange={e => this.changeCurrentEvent(e)} onCancel={e => this.cancelNewEvent()} />;
    }

    if (show_review_changes_modal) {
      let props = {log, patient, event: current_event};
      props.onSubmit = e => this.submit();
      props.onCancel = e => this.cancelReviewChanges();
      props.onReload = e => this.reload();
      return <ReviewChangesModal key="s" {...props} />
    }
    return null;
  }

  renderJourneyTracker() {
    return(
      <div id="jt_container">
        <div className="row">
          <div className="col-md-3">
            {this.renderHeader()}
            {this.renderTabsList()}
          </div>

          <div className="col-md-9">

            {this.renderCurrentTab()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    let {show_event_modal, show_review_changes_modal} = this.state;
    if (show_event_modal || show_review_changes_modal) {
      return this.renderEvent();
    }

    return this.renderJourneyTracker();
  }

}


function initializeData(data) {
  if (!data) data = {};
  if (!data.diagnoses) data.diagnoses = [];
  for (let diagnosis of data.diagnoses) {
    if (!diagnosis.stage) diagnosis.stage = {};
    if (!diagnosis.pathology) diagnosis.pathology = {};
    if (!diagnosis.traits) diagnosis.traits = {};
  }
  if (!data.patient_details) data.patient_details = {};
  if (!data.brain_and_spine) data.brain_and_spine = {};
  if (!data.labs) data.labs = [];
  if (!data.comorbid_conditions) data.comorbid_conditions = [];
  if (!data.sequencies) data.sequencies = [];
  if (!data.interventions) data.interventions = [];
  if (!data.symptoms) data.symptoms = [];
  if (!data.findings) data.findings = [];
  return data;
}

function getSection(data, path) {
  let section = data;
  path.split('/').forEach(chunk => {
    if (chunk.length === 0) return;

    let parts = chunk.match(/\[([\w]*):([\w\d]*)\]/);
    if (parts === null) {
      section = section[chunk]
    } else {
      let key = parts[1];
      let value = parts[2];
      section = section.find(s => value === '' + s[key]);
    }
  });

  return section;
}

function getAnchorFromURL() {
  let hash = location.hash;
  if (!hash || hash.length === 0) return undefined;
  return hash.replace('#', '');
}


const TABS = {
  details: {label: 'Details', component: Details},
  findings: {label: 'Findings', component: Findings},
  tumors: {label: 'Tumor Log', component: TumorsLog},
  labs: {label: 'Labs', component: Labs},
  sequencing: {label: 'Sequencing', component: Sequencings},
  interventions: {label: 'Interventions', component: Interventions},
  symptoms: {label: 'Symptoms', component: Symptoms},
  comorbid: {label: 'Comorbid', component: ComorbidConditions},
  files: {label: 'Files', component: Files},
  events: {label: 'Events', component: EventsList},
  settings: {label: 'Settings', component: Settings}
}
