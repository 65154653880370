import React, {Component} from 'react';
import Select2 from '../select2';

import {USARegions, Countries, ContinentsData, RecruitmentStatus} from '../../constants.js.erb';
import IndeterminateCheckbox from '../indeterminate_checkbox';
import MultiGeolocationPicker from '../multi_geolocation_picker';
import MultiCancerCenterPicker  from '../multi_cancer_center_picker';
import Slektr from '../slektr_wrapper';


export default class LocationsPanel extends Component {
  constructor(props) {
    super(props);
    this.usa_states = gon.usaStates;
    this.countries = gon.countries;
    this.state = {};
  }

  setAllStates(checked) {
    let {query, onChange} = this.props;
    for (let state of this.usa_states) {
      query.states[state.id] = checked;
    }
    onChange("locations", query);
  }

  setAllOverallStatus(checked) {
    let {query, onChange} = this.props;
    for (let overall_status_id of Object.keys(RecruitmentStatus)) {
      query.overall_status[overall_status_id] = checked;
    }
    onChange("locations", query);
  }

  change(e) {
    let {name, value} = e.target;
    let {query, onChange} = this.props;
    query[name] = value;
    onChange("locations", query);
  }

  changeFilter(section, e, isCheckbox) {
    let {name, value, checked} = e.target;
    let {onChangeFilters, filters} = this.props
    if (!filters[section]) filters[section] = {};
    let field_value = isCheckbox ? checked : value;
    filters[section][name] = field_value;
    onChangeFilters && onChangeFilters({filters});
  }


  toggleOnlyRecruiting() {
    let {query, onChange} = this.props;
    query.only_recruiting = !query.only_recruiting;
    onChange("locations", query);
  }


  changeState(e, field) {
    let {name, checked} = e.target;
    let {query, onChange} = this.props;
    query[field][name] = checked;
    onChange("locations", query);
  }

  onChangeExtraLocations(extra_locations) {
    let {query, onChange} = this.props;
    query.extra_locations = extra_locations;
    onChange("locations", query);
  }

  onChangeCancerCenters(cancer_centers) {
    let {filters, onChangeFilters} = this.props;
    filters.cancer_centers = cancer_centers;
    onChangeFilters({filters});
  }

  renderSelectButtons(any_selected) {
    let alert;
    if (!any_selected) {
      alert = <span style={{color: "red"}}>Select at least one state</span>
    }
    return (
      <div>
        <div>
          {alert}
          <button onClick={e => this.setAllStates(true)} className="btn btn-link">Select All</button>
          <button onClick={e => this.setAllStates(false)} className="btn btn-link">Unselect All</button>
        </div>
      </div>
    );
  }

  renderStates(query, counts) {
    if (!query.included_countries || query.included_countries.indexOf("US") === -1) return null;
    let {show_regions} = this.state;
    if (show_regions) return this.renderRegions(query);

    let any_selected = false;

    for (let k of Object.keys(query.states)) {
      if (query.states[k]) {
        any_selected = true;
      }
    }

    let locations = this.usa_states.map(s => {
      let value = (query.states && query.states[s.id]) || ""
      let count_el
      if (counts && counts.states) {
        count_el = "[" + (counts.states[s.id] || 0) + "]"
      }

      return (
        <div key={s.id}>
          <input type="checkbox" name={s.id} value={value} checked={value}  onChange={e => this.changeState(e, 'states')}  />
            {s.label} {count_el}
        </div>
      )
    });

    return(
      <div>
        <button className="btn btn-link" onClick={e => this.toggleRegions()}>Show Regions</button>
        {this.renderSelectButtons(any_selected)}
        {locations}
        {this.renderSelectButtons(any_selected)}
      </div>
    )
  }

  renderCancerCenterTags() {
    let {filters} = this.props;

    return (
      <div className="location_search_panel_section">
        <h4>Cancer Center Tags</h4>
        {gon.cancerCenterTags.map(({id, label}) => {
          let checked = (filters.cancer_center_tags && filters.cancer_center_tags[id]) || false;
          return (
            <div key={id} className="tag_options">
              <input type="checkbox" name={id} checked={checked} value={checked} onChange={e => this.changeFilter("cancer_center_tags", e, true)} />
              <label>{label}</label>
            </div>
          );
        })}
      </div>
    );
  }

  toggleRegions() {
    let {show_regions} = this.state;
    show_regions = !show_regions;
    this.setState({show_regions});
  }

  toggleRegion(region, value) {
    let {query, onChange} = this.props;
    for (let state of region.states) {
      query.states[state] = value;
    }
    onChange("locations", query);
  }

  renderRegions(query) {
    return (
      <div>
        <button className="btn btn-link" onClick={e => this.toggleRegions()}>Show States</button>
        {USARegions.map(r => {
          let value = usaRegionSelected(r, query);
          let checked = value || false;
          let checkboxClassname = value === null ? 'checkbox-undefined' : null;
          return (
            <div key={r.id} className="checkbox">
              <label>
                <IndeterminateCheckbox className={checkboxClassname} indeterminate={value === null} checked={checked} onChange={e => this.toggleRegion(r, !checked)} />
                {r.name} <span>({r.states.join(', ')})</span>
              </label>
            </div>
          );
      })}
      </div>
    );
  }

  renderStatusSelect() {
    let {query} = this.props;
    if (query.only_recruiting) return null;
    let any_selected = false;

    let inputs = Object.keys(RecruitmentStatus).map(s => {
      let value = query.overall_status[s] || false;
      if (value) any_selected = true;
      return (
        <div key={s}>
          <input type="checkbox" name={s} checked={value} value={value} onChange={e => this.changeState(e, 'overall_status')}/>
          {RecruitmentStatus[s]}
        </div>
      )
    });

    return (
      <div>
        <label>Overall Status</label> {!any_selected && <span style={{color: "red"}}>Select at least one</span>}
        <button onClick={e => this.setAllOverallStatus(true)} className="btn btn-link">Select All</button>
        <button onClick={e => this.setAllOverallStatus(false)} className="btn btn-link">Unselect All</button>

        {inputs}

        <hr />
      </div>
    )
  }


  renderFilterButtons() {
    let {query} = this.props;
    let btn_a_style, btn_b_style;
    if (query.only_recruiting) {
      btn_a_style = "btn btn-sm btn-primary";
      btn_b_style = "btn btn-sm btn-default";
    } else {
      btn_a_style = "btn btn-sm btn-default";
      btn_b_style = "btn btn-sm btn-primary";
    }
    return (
      <div style={{marginBottom: 14}} className="btn-group">
        <button className={btn_a_style} onClick={e => this.toggleOnlyRecruiting()}>By Recruiting Locations</button>
        <button className={btn_b_style} onClick={e => this.toggleOnlyRecruiting()}>By Status and Location</button>
      </div>
    );
  }

  render() {
    let {query, filters, counts, patient} = this.props;
    let continents = ContinentsData.map(c => ({id: c.id, label: c.name, continent: true}));

    let country_options = [
      {group: true, label: 'Countries', options: this.countries},
      {group: true, label: 'Continents', options: continents}
    ];

    return (
      <div id="locations">
        {this.renderFilterButtons()}
        {this.renderStatusSelect()}
        <div className="location_search_panel_section">
          <h4>Included Countries</h4>
          <Slektr name="included_countries" value={query.included_countries} onChange={e => this.change(e)} options={country_options} multiple={true} className="form-control" renderOptionCallback={renderCountryOption} renderValueCallback={renderCountryValue} />
        </div>

        <div className="location_search_panel_section">
          {this.renderStates(query, counts)}
        </div>

        <div className="location_search_panel_section">
          <h4>Excluded Countries</h4>
          <Slektr name="excluded_countries" value={query.excluded_countries} onChange={e => this.change(e)} options={country_options} multiple={true} className="form-control" renderOptionCallback={renderCountryOption} renderValueCallback={renderCountryValue} />
        </div>

        {this.renderCancerCenterTags()}

        <div id="extra_locations" className="location_filter_section">
          <h4>Specific Locations</h4>
          <p>You may add some geolocated locations like a city to use mapping features.</p>
          <MultiGeolocationPicker locations={query.extra_locations || []} onChange={l => this.onChangeExtraLocations(l)} patient={patient} />
        </div>
        <hr/>
        <div id="cancer_centers" className="location_filter_section">
          <h4>Cancer Centers</h4>
          <MultiCancerCenterPicker  cancer_centers={filters.cancer_centers || []} onChange={c => this.onChangeCancerCenters(c)} />
        </div>
      </div>
    )
  }
}


function usaRegionSelected(region, query) {
  let any = false;
  let all = true;
  for (let state of region.states) {
    if (!!query.states[state]) {
      any = true;
    } else {
      all = false;
    }
  }
  if (!any) return false;
  if (any && all) return true;
  return null;
}

const CONTINENT_IDS = ContinentsData.map(c => c.id);

function renderCountryOption(option, slektr) {
  let selected_continents = slektr.value.filter(id => CONTINENT_IDS.indexOf(id) !== -1);
  if (!selected_continents || selected_continents.length === 0) return option.label;
  let included = selected_continents.some(continent_id => {
    let continent = ContinentsData.find(c => c.id === continent_id);
    return continent.countries.findIndex(c => c.code === option.value) !== -1
  });

  if (!included) return option.label;
  return `<span class="continent-selected"><i class="fa-solid fa-book-atlas"></i> ${option.label}</span>`;
}

function renderCountryValue(option, slektr) {
  if (option.continent) {
    return `<span><i class="fa-solid fa-globe"></i> ${option.label}</span>`;
  } else {
    return option.label;
  }
}
