import React, {Component} from 'react';
import OptionsCache from './options_cache';
import Form from './journey_tracker_form';
import EventViewer from './event_viewer';
import joinReact from '../join_react';
import * as Inflector from 'inflected';

export default class NeestedRecord extends Component {
  constructor(props) {
    super(props);
    let record = Object.assign({}, props.record);
    this.state = {record, errors: {}, changes: {}, show_changelog: false}

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.cancerTypeCache = new OptionsCache({source: 'patient_cancer_types', onChange: e => this.forceUpdate()})
    this.symptomCache = new OptionsCache({source: 'symptoms', onChange: e => this.forceUpdate()})
    this.interventionCache = new OptionsCache({source: 'interventions', onChange: e => this.forceUpdate()});
    this.comorbidCache = new OptionsCache({source: 'comorbid_conditions', onChange: e => this.forceUpdate()})
    this.organCache = new OptionsCache({source: 'organs', onChange: e => this.forceUpdate()});
  }

  formFields() {
    return [];
  }

  getModelName() {
    return "Override Me! getModelName";
  }


  getSectionLabel() {
    return "Override Me! getSectionLabel";
  }

  getBasePath() {
    return "Override Me! getBasePath";
  }


  onChange(field_name, value, meta) {
    let {record, changes} = this.state;
    record[field_name] = value;

    // Add the log entry
    changes[field_name] = {value, meta};
    this.setState({record, changes});
  }

  onSubmit(e) {
    e.preventDefault();
    let {record, changes} = this.state;
    let {onClose, onAddBatch, onEditBatch} = this.props;
    let base_path = this.getBasePath();
    let section_label = this.getSectionLabel();

    let batch_log = Object.keys(changes).map(field_name => {
      let original = this.props.record[field_name];
      let {value, meta} = changes[field_name];
      meta.section_label = section_label;
      return {field_name, original, value, meta};
    });

    if (record.id) {
      onEditBatch(base_path, record, batch_log);
    } else {
      record.id = generateUUID();
      batch_log.unshift({field_name: 'id', value: record.id, meta: {section_label}});
      onAddBatch(base_path, record, batch_log);
    }

    onClose && onClose();
  }

  showChangelog() {
    this.setState({show_changelog: true});
  }

  hideChangelog() {
    this.setState({show_changelog: false});
  }

  sharedFields() {
    let {data} = this.props;
    if (!data) return null;

    let diagnosis = data.diagnoses.map(d => {
      let patient_cancer_type = this.cancerTypeCache.get(d.patient_cancer_type_id);
      return {id: d.id, label: patient_cancer_type.text}
    });

    let valueLabelCallback = record => {
      let selected = diagnosis.find(d => d.id === record.diagnosis_id);
      if (!selected) return null;
      return selected.label;
    };

    let symptoms = data.symptoms.map(s => {
      let side_effect = this.symptomCache.get(s.side_effect_id);
      return {id: s.id, label: side_effect.text}
    });

    let interventions = data.interventions.map(i => {
      let intervention = this.interventionCache.get(i.intervention_id);
      return {id: i.id, label: intervention.text}
    });

    let comorbid_conditions = data.comorbid_conditions.map(c => {
      let condition = this.comorbidCache.get(c.comorbid_condition_id);
      return {id: c.id, label: condition.text}
    });

    let findings = data.findings.map(f => {
      let organ = this.organCache.get(f.organ_id);
      return {id: f.id, label: organ.text}
    });


    let diagnosis_el = {name: 'diagnosis_id', type: CheckboxSelectCombo, collection: diagnosis, valueLabelCallback};
    let symptoms_el = {name: 'related_symptom_id', type: CheckboxSelectCombo, collection: symptoms};
    let interventions_el =  {name: 'related_intervention_id', type: CheckboxSelectCombo, collection: interventions};
    let comorbid_conditions_el = {name: 'related_comorbid_condition_id', type: CheckboxSelectCombo, collection: comorbid_conditions};
    let findings_el = {name: 'related_finding_id', type: CheckboxSelectCombo, collection: findings};

    let base_path = this.getBasePath();
    let local_fields;

    if (base_path === 'tumor_logs' || base_path === 'sequencies') {
      local_fields = [diagnosis_el];
    } else if (base_path === 'comorbid_conditions') {
      local_fields = [comorbid_conditions_el, findings_el]
    } else {
      local_fields = [diagnosis_el, symptoms_el, interventions_el, comorbid_conditions_el, findings_el];
    }

    return local_fields;
  }



  allFormFields() {
    let fields = this.formFields();
    let shared = this.sharedFields();
    if (!shared) return fields;
    return shared.concat(fields);
  }

  renderChangelog() {
    let {patient, fields_changelog, record} = this.props;
    let path = this.getBasePath() + '/[id:' + record.id + ']';
    return <EventViewer patient={patient} fields={this.allFormFields()} path={path} changelog={fields_changelog} />
  }


  renderAssociations() {
    let {record, errors} = this.state;
    let {onClose, readOnly, container_element, prefix} = this.props;
    let fields = this.sharedFields();
    if (!fields) return null;

    return (
      <div className="jt-associations">
        <h4>Relevant Associations</h4>
        <Form readOnly={readOnly} fields={fields} model={record} errors={errors} onChange={this.onChange} container_element={container_element} prefix={prefix} />
      </div>
    );
  }

  renderForm() {
    let {record, errors} = this.state;
    let {onClose, readOnly, container_element, hideButtons, prefix} = this.props;
    let model_name = this.getModelName();

    return (
      <div>
        {this.renderAssociations()}
        <Form readOnly={readOnly} model_name={model_name} fields={this.formFields()} model={record} errors={errors} onChange={this.onChange} onSubmit={this.onSubmit} onCancel={onClose} container_element={container_element} hideButtons={hideButtons} prefix={prefix} />
      </div>
    );
  }


  render() {
    let {record, show_changelog} = this.state;
    let {readOnly, onClose} = this.props;
    let model_name = this.getModelName();

    let title;
    if (record.id) {
      title = readOnly ? model_name : 'Edit ' + model_name;
    } else {
      title = 'New ' + model_name;
    }

    let action_btn, close_btn, content, box_class;
    if (show_changelog) {
      content = this.renderChangelog();
      action_btn = <button key="close_changelog" className="btn btn-sm btn-warning" onClick={e => this.hideChangelog()}>Close Changelog</button>;
      box_class = 'jt_read_only_box';
    } else {
      content = this.renderForm();
      box_class = 'jt_form_box';
      if (readOnly) {
        action_btn = <button key="show_changelog" className="btn btn-sm btn-link" onClick={e => this.showChangelog()}>Show Changelog</button>;
        close_btn = <button key="close" onClick={onClose} className="btn btn-sm btn-link">Close</button>
        box_class = 'jt_read_only_box';
      }
    }

    let action_buttons = joinReact([action_btn, close_btn].filter(i => i), ' | ');

    return (
      <div>
        <p><b>{title}</b></p>
        <div className={box_class}>
          <div className="pull-right">{action_buttons}</div>
          <div className="clearfix"></div>
          {content}
        </div>
      </div>
    );
  }

}


class CheckboxSelectCombo extends Form.FormField {
  constructor(props) {
    super(props);
    this.state = {enabled: !!props.value};
  }

  toggleCheckbox(e) {
    let enabled = !this.state.enabled;
    if (!enabled) {
      let {field, onChange} = this.props;
      onChange({target: {name: field.name, value: null}}, {value_label: undefined});
    }
    this.setState({enabled});
  }

  onChange(e) {
    let {value} = e.target;
    let {field, onChange} = this.props;
    let option = field.collection.find(o => o.id === value);
    let value_label = option ? option.label : undefined;
    let meta = {value_label};
    onChange(e, meta);
  }

  renderEmptyCollection() {
    let {field} = this.props;
    let field_label = field.label || Inflector.titleize(field.name);
    let label = Inflector.pluralize(field_label).toLowerCase();
    let text = "No recorded " + label + " to display"

    return (
      <input type="text" className="form-control" disabled value={text} />
    )
  }

  renderSelect() {
    let {field, value, onChange} = this.props;
    let {required, collection} = field;
    if (collection.length === 0) {
      return this.renderEmptyCollection()
    }

    let className = "form-control";
    if (field.required) className += " required";

    let options = collection.map(({id, label}) => <option key={id} value={id}>{label}</option>);
    let blank_label = value ? "" : "Select an option"
    let blank = <option value="">{blank_label}</option>;
    let enabled = !!value || this.state.enabled;

    return (
      <select id={this.fieldID()} className={className} name={field.name} disabled={!enabled} value={value || ''} onChange={e => this.onChange(e)} >
        {blank}
        {options}
      </select>

    );
  }
  renderInput() {
    let {enabled} = this.state;
    let {field} = this.props;
    let {collection} = field
    let disabled = collection.length === 0;

    return (
      <div className="form-group">
        <label>{this.renderLabel(field)}</label>
        <div className="input-group">
          <span className="input-group-addon">
            <input type="checkbox" disabled={disabled} value={enabled} checked={enabled} onChange={e => this.toggleCheckbox(e)} />
          </span>
          {this.renderSelect()}
        </div>
      </div>
    );
  }

  getValueLabel() {
    let {field, value} = this.props;
    let options = [];
    let option = options.find(o => o.id === value);
    if (!option) return field.blankLabel || null;
    return option.label;
  }

}
