import React, {Component} from 'react';
import request from '../request';
import Form from './form';
import Loading from './loading';

import CollectionTable from './collection_table';
import {CancerCenterSearchKey} from '../constants.js.erb'


export default class TherapyCancerCenters extends Component {
  constructor(props) {
    super(props);
    let {therapy_cancer_centers} = props;
    this.state = {therapy_cancer_centers};

    this.onCancerCenterAdded = this.onCancerCenterAdded.bind(this);
    this.onCancerCenterDeleted = this.onCancerCenterDeleted.bind(this);
  }

  componentDidMount() {
    $(this.refs.modal_container).modal({show: false});
    $(this.refs.modal_container).on('hide.bs.modal', () => {
      this.setState({show_form: false});
    });
  }


  onCancerCenterAdded(therapy_cancer_center) {
    let {therapy_cancer_centers} = this.state;
    therapy_cancer_centers.push(therapy_cancer_center);
    this.setState({therapy_cancer_centers});
    this.closeForm();
  }

  onCancerCenterDeleted(therapy_cancer_center) {
    if (!confirm('Are you sure?')) return;
    let {trial_id} = this.props;
    let url = "/admin/trials/" + trial_id + "/therapy_cancer_centers/" + therapy_cancer_center.id;
    request('DELETE', url).then(success => {
      let {therapy_cancer_centers} = this.state;
      let index = therapy_cancer_centers.findIndex(c => c.id === therapy_cancer_center.id);
      therapy_cancer_centers.splice(index, 1);
      this.setState({therapy_cancer_centers});
    });
  }

  showForm() {
    this.setState({show_form: true}, () => $(this.refs.modal_container).modal('show'));
  }

  closeForm() {
    this.setState({show_form: false});
    $(this.refs.modal_container).modal('hide')
  }

  renderModal() {
    let {show_form} = this.state;
    let content;
    if (show_form) {
      let {trial_id} = this.props;
      let {therapy_cancer_centers} = this.state;

      content = <AddCancerCenterForm trial_id={trial_id} onCancerCenterAdded={this.onCancerCenterAdded} current_therapy_cancer_centers={therapy_cancer_centers} />
    }

    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="pull-left">Search Cancer Centers</h3>
              <button type="button" className="close pull-right" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <div className="clearfix"></div>
            </div>
            <div className="modal-body">
              {content}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }


  renderCancerCenters() {
    let {therapy_cancer_centers} = this.state;
    let {trial_id} = this.props;
    return <CancerCenterCollection collection={therapy_cancer_centers} title="Linked Cancer Centers" trial_id={trial_id} onDelete={this.onCancerCenterDeleted} />
  }

  render() {

    return (
      <div>
        <hr/>
        <p>You may add cancer centers where a patient could recieve this therapy along with any notes related to this cancer center.</p>
        <button className="btn btn-success" onClick={e => this.showForm()}>Add Cancer Center</button>
        <hr/>
        {this.renderCancerCenters()}
        {this.renderModal()}
      </div>
    );
  }
}


class CancerCenterCollection extends CollectionTable {
  renderActionHeader() {
    return <th></th>
  }

  renderAction(record) {
    let {trial_id, onDelete} = this.props;
    let edit_link = `/admin/trials/${trial_id}/therapy_cancer_centers/${record.id}/edit`;
    return (
      <td>
        <a href={edit_link}  className="btn btn-default btn-xs"><span className="fa fa-pencil-alt"></span></a>
        <button className="btn btn-default btn-xs btn-danger" onClick={e => onDelete(record)}><span className="fa fa-trash"></span></button>
      </td>
    );
  }


  renderTableHeader() {
    return (
      <thead>
        <tr>
          <th>Name</th>
          <th>State</th>
          <th>City</th>
          <th>ZIP Code</th>
          <th>Notes</th>
          {this.renderActionHeader()}
        </tr>
      </thead>

    );
  }

  renderRow(record) {
    let {cancer_center} = record;
    let url = '/admin/cancer_centers/' + cancer_center.id;

    return (
      <tr key={record.id}>
        <td><a href={url} target="_blank">{cancer_center.name}</a></td>
        <td>{cancer_center.state_name}</td>
        <td>{cancer_center.city}</td>
        <td>{cancer_center.zip}</td>
        <td>{record.notes}</td>
        {this.renderAction(record)}
      </tr>
    );
  }

}


class AddCancerCenterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {query: ''}
    this.search = this.search.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(name, value) {
    this.setState({query: value});
  }

  addCancerCenter(cancer_center) {
    let {trial_id, onCancerCenterAdded} = this.props;
    let url = "/admin/trials/" + trial_id + "/therapy_cancer_centers/"
    request('POST', url, {cancer_center_id: cancer_center.id}).then(therapy_cancer_center => {
      onCancerCenterAdded(therapy_cancer_center);
    });
  }

  search(e) {
    e.preventDefault();

    let {query} = this.state;
    if (!query || query.length === 0) return;

    this.setState({searching: true});
    let url = '/admin/cancer_centers/search?exclude_umbrellas=true&q[' + CancerCenterSearchKey + ']=' + query;
    request('GET', url).then(results => {
      let {current_therapy_cancer_centers} = this.props;
      results = results.filter(r => current_therapy_cancer_centers.findIndex(c => c.cancer_center_id === r.id) === -1)
      this.setState({results, searching: false});
    });
  }

  renderForm() {
    let {query} = this.state;
    let fields= [
      {name: 'query', label: 'Search by Name, Keywords, Address, City, State, or Zip', focus: true}
    ];

    return <Form model={{query}} fields={fields} onSubmit={this.search} onChangeField={this.onChange} submitLabel='Search'/>
  }

  renderSearchResults() {
    let {onAddCancerCenter} = this.props;
    let {searching, results} = this.state;
    if (searching) return <Loading />
    if (!results) return null;
    if (results.length === 0) return <p>No results found</p>

    return (
      <div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>State</th>
              <th>City</th>
              <th>ZIP Code</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {results.map(cancer_center => {
            let url = '/admin/cancer_centers/' + cancer_center.id;
            return (
              <tr key={cancer_center.id}>
                <td><a href={url} target="_blank">{cancer_center.name}</a></td>
                <td>{cancer_center.state_name}</td>
                <td>{cancer_center.city}</td>
                <td>{cancer_center.zip}</td>
                <td><button className="btn btn-xs btn-success" onClick={e => this.addCancerCenter(cancer_center)}>Add</button></td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    )
  }

  render() {

    return (
      <div>
        {this.renderForm()}
        {this.renderSearchResults()}
      </div>
    );
  }

}
