import jquery from 'jquery';
import request from '../request';

export default {
  setup: function() {
    jquery( document ).ready(() => setupTaskStatusCheckbox());
  }
}


function setupTaskStatusCheckbox() {
  let elements = document.getElementsByTagName('task-status-checkbox');
  for (let el of elements) {
    setupElement(jquery(el));
  }
}


function setupElement(container) {
  let value = jquery(container).data('value');
  let task_id = jquery(container).data('task');
  let show_user = jquery(container).data('show-user');

  // Add the elements
  let els = {}
  els.input_el = jquery('<input class="task_status_input" type="checkbox" />');
  els.label_el = jquery('<span class="task_status_label"></span');
  els.loading_el = jquery('<i class="glyphicon glyphicon-refresh glyphicon-spin" />');
  els.completed_by_el = jquery('<p></p>');

  container.append(els.input_el);
  container.append(els.label_el);
  container.append(els.completed_by_el);
  container.append(els.loading_el);

  // Set the current state
  setState(els, value);
  if (show_user && value) {
    let completed_by = jquery(container).data('completed-by');
    let completed_at = jquery(container).data('completed-at');
    setCompletedBy(els, completed_by, completed_at)
  }

  // Add the change listener
  els.input_el.on('change', e => {
    e.preventDefault();
    setLoading(els);

    let {checked} = e.target;
    let action = checked ? 'complete' : 'uncomplete';
    let url = `/admin/tasks/${task_id}/${action}`

    request('PUT', url).then(t => {
      setState(els, checked);
      if (show_user && t.completed) {
        setCompletedBy(els, t.completed_by_name, t.completed_at);
      }
    });
  });
}

function setState(els, value) {
  let {input_el, label_el, loading_el, completed_by_el} = els;

  let label = value ? 'Complete' : 'Click to Complete';
  label_el.html(label);
  input_el[0].checked = value;

  input_el.show();
  label_el.show();
  completed_by_el.hide();
  loading_el.hide();
}

function setLoading(els) {
  let {input_el, label_el, completed_by_el, loading_el} = els;
  input_el.hide();
  label_el.hide();
  completed_by_el.hide();
  loading_el.show();
}


function setCompletedBy(els, completed_by, completed_at) {
  let date = moment(completed_at).format('LLL');
  let msg = `<i>Marked complete by ${completed_by} on ${date}</i>`;
  els.completed_by_el.html(msg);
  els.completed_by_el.show();
}
