import React, {Component} from 'react';
import Form from './form';
import request from '../request';
import {InterventionSearchKey} from '../constants.js.erb';

export default class CompanyForm extends Component {
  constructor(props) {
    super(props);
    let {company} = props;
    this.state = {company, errors: {}};
  }

  submit(e) {
    e.preventDefault();
    this.setState({submitting: true});
    let {company} = this.state;

    let url = "/admin/companies";
    let method;

    if (company.id) {
      url += "/" + company.id;
      method = "PUT"
    } else {
      method = "POST"
    }

    let model = {};
    for (let field of this.formFields()) {
      model[field.name] = company[field.name];
    }

    request(method, url, {company: model}).then(({company}) => {
      let {return_path, onCreate} = this.props;
      this.setState({errors: [], submitting: false});
      if (return_path) window.location = return_path;
      if (onCreate) onCreate(company);

    }).catch(({errors}) => {
      this.setState({errors, submitting: false});
    });
  }

  onChange(company) {
    this.setState({company});
  }

  formFields() {
    let {route_of_administrations, molecular_types} = this.props;
    return [
      {name: 'name', required: true},
      {name: 'aliases', type: 'text', label: 'Aliases - Used to match to sponsors', hint: 'Use linebreaks to separate different aliases. Be very careful updating.'},
      {name: 'mailing_address'},
      {name: 'shipping_address'},
      {name: 'phone'},
      {name: 'fax'},
      {name: 'url'},
      {name: 'email'},
      {name: 'intervention_ids', label: 'Describing what Intervention(s)', type: 'remote-select2', multiple: true, src: "/admin/interventions/as_options.json", search_key: InterventionSearchKey},
      {name: 'notes', type: 'text'}
    ];
  }

  render() {
    let {onCancel} = this.props;
    let {company, errors, submitting} = this.state;

    return <Form model_name="Company" prefix="company" fields={this.formFields()} model={company} errors={errors} submitting={submitting} onChange={r => this.onChange(r)} onSubmit={e => this.submit(e)} onCancel={onCancel} />
  }
}
