import React, {Component} from 'react';
import StarsRatingCombo from './stars_rating_combo';

export default class TrialCollectionStarsRating extends Component {
  render() {
    let {patient_trial_data} = this.props;
    let {efficacy_notes, side_effects_notes} = patient_trial_data;

    let safetyOptions = {monotherapyTitle: 'Safety Monotherapy Rating', combinationTitle: 'Safety Combination Rating', valueComponentOnly: true};
    let efficacyOptions = {monotherapyTitle: 'Efficaicy Monotherapy Rating', combinationTitle: 'Efficacy Combination Rating', valueComponentOnly: true};
    return (
      <div className="patient_trial_data_stars_rating">
        <div className="patient_trial_datas_stars_rating_section">
          <h3>EFFICACY</h3>
          <StarsRatingCombo data={patient_trial_data} section="efficacy" extraOptions={TrialCollectionStarsRating.EXTRA_OPTIONS} {...efficacyOptions} />
          <p className="rating-notes">{efficacy_notes}</p>
        </div>

        <div className="patient_trial_datas_stars_rating_section">
          <h3>SIDE EFFECTS</h3>
          <StarsRatingCombo  data={patient_trial_data} section="safety" extraOptions={TrialCollectionStarsRating.EXTRA_OPTIONS} {...safetyOptions} />
          <p className="rating-notes">{side_effects_notes}</p>
        </div>
      </div>
    )
  }
}


TrialCollectionStarsRating.EXTRA_OPTIONS = [
  {value: -1, order: 98, className: 'non-star-option', selectedClassName: 'non-star-option non-star-option-selected', content: 'N/A' },
  {value: -2, order: 99, className: 'non-star-option', selectedClassName: ' non-star-option non-star-option-selected', content: 'No Stars' }
]
