import React, {Component} from 'react';

import Diagnosis from './diagnosis';
import PatientDetails from './patient_details';
import BrainAndSpine from './brain_and_spine';
import Stage from './stage';
import Pathology from './pathology';
import PatientCancerTypeTraits from './patient_cancer_type_traits';


export default class Details extends Component {
  render() {
    let {patient, data, fields_changelog, onChange, onAddBatch, onEditBatch, onDelete, readOnly} = this.props;
    let {patient_details, diagnoses, brain_and_spine} = data;
    return (
      <div>
        <Diagnosis patient={patient} data={data} onAddBatch={onAddBatch} onEditBatch={onEditBatch} onDelete={onDelete} readOnly={readOnly} fields_changelog={fields_changelog} />
        {diagnoses.map(diagnosis => {
          let key = 'stage_' + diagnosis.id;
          return <Stage key={key} patient={patient} diagnosis={diagnosis} onChange={onChange} readOnly={readOnly} fields_changelog={fields_changelog} />
        })}

        {diagnoses.map(diagnosis => {
          let key = 'pathology_' + diagnosis.id;
          return <Pathology key={key} patient={patient} diagnosis={diagnosis} onChange={onChange} readOnly={readOnly} fields_changelog={fields_changelog} />
        })}

        {diagnoses.map(diagnosis => {
          let key = 'traits_' + diagnosis.id;
          return <PatientCancerTypeTraits key={key} patient={patient} diagnosis={diagnosis} onChange={onChange} readOnly={readOnly} fields_changelog={fields_changelog} />
        })}

        <PatientDetails patient={patient} patient_details={patient_details} onChange={onChange} readOnly={readOnly} fields_changelog={fields_changelog} />
        <BrainAndSpine patient={patient} brain_and_spine={brain_and_spine} onChange={onChange} readOnly={readOnly} fields_changelog={fields_changelog} />

      </div>
    );
  }
}
