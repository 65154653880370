import React, {Component} from 'react';
import request from '../request';
import GroupsContainer from './groups_container';
import {THERAPY_TYPE_THERAPY} from '../constants.js.erb';
import * as Inflector from 'inflected';

export default class TrialGroupsContainer extends Component {
  constructor(props) {
    super(props);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    let {name, value} = e.target;
    let {onChange} = this.props;
    onChange && onChange(name, value)
  }


  onChangeCheckbox(e) {
    let {name, checked} = e.target;
    let {onChange} = this.props;
    onChange && onChange(name, checked)
  }

  onChangeGroups(groups) {
    let {onChange} = this.props;
    onChange('groups', groups)
  }

  renderIncludesCombination(trial) {
    let calculated = trial.calculated_includes_combination === true ? 'Yes' : 'No';

    return (
      <div>
        <h4>Includes combination?</h4>
        <div className="radio">
          <label>
            <input type="radio" name="overrided_includes_combination" value=" " checked={trial.overrided_includes_combination === ' '} onChange={this.onChange} />
            Use calculated value: <strong>{calculated}</strong>
          </label>
        </div>

        <div className="radio">
          <label>
            <input type="radio" name="overrided_includes_combination" value="Y" checked={trial.overrided_includes_combination === 'Y'} onChange={this.onChange} />
            Includes Combination
          </label>
        </div>

        <div className="radio">
          <label>
            <input type="radio" name="overrided_includes_combination" value="N" checked={trial.overrided_includes_combination === 'N'} onChange={this.onChange} />
            Does not Includes Combination
          </label>
        </div>

      </div>
    )
  }

  renderImportedData(trial) {
    if (trial.therapy_type === THERAPY_TYPE_THERAPY) return null;
    return (
      <div>
        <ImportedARM arm_groups={trial.imported_data.arm_groups} />
        <ImportedInterventions interventions={trial.imported_data.interventions} />
      </div>
    )
  }

  renderStudyDesignInfo(trial) {
    if (trial.therapy_type === THERAPY_TYPE_THERAPY) return null;

    let study = trial.imported_data.study_design_info;
    if (!study) return null;

    let content = Object.keys(study).map(key => {
      let title = key.replace('_', ' ').titleize();
      let text = title + ": " + study[key];
      return <li key={key}>{text}</li>;
    });


    return (
      <div id="study_design_info">
        <h4>Study Design</h4>
        <ul>
          {content}
        </ul>
      </div>
    )
  }


  renderCheckboxes(trial) {
    if (trial.therapy_type === THERAPY_TYPE_THERAPY) return null;

    return (
      <div>
         <div className="checkbox">
            <label className="control-label">
              <input type="checkbox" name="arm_randomized" value={trial.arm_randomized} checked={trial.arm_randomized} disabled/>
              Randomized
            </label>
          </div>

          <div className="checkbox">
            <label className="control-label">
              <input type="checkbox" name="arm_blinded" value={trial.arm_blinded} checked={trial.arm_blinded} disabled/>
              Blinded
            </label>
          </div>
      </div>
    );
  }

  renderLinkToGroupsImporter() {
    let {trial} = this.props;
    if (trial.isTherapy) return null;
    let link_to_infer_groups = `/admin/trials/${trial.id}/groups`;
    return <a href={link_to_infer_groups} className="btn btn-success">Go to Group Importer</a>
  }


  render() {
    let {trial, onChange, currentAdmin} = this.props;

    return (
      <div id="interventions" className="row">
        <div className="col-md-7 group-traits-fields">
          <GroupsContainer trial={trial} groups={trial.groups} onChange={g => this.onChangeGroups(g)} closeable={true} currentAdmin={currentAdmin} createLinks />
          <div className="form-group">
            <label>Cycle Length (In Weeks)</label>
            <input type="number" className="form-control" name="cycles_length" value={trial.cycles_length || ''} onChange={this.onChange} />
          </div>

        </div>


        <div className="col-md-5 groups_sidebar">
          {this.renderLinkToGroupsImporter()}
          {this.renderImportedData(trial)}
          {this.renderStudyDesignInfo(trial)}
          {this.renderCheckboxes(trial)}

          <div className="trial_analysis_holder">
            <h4 className="trial_analysis_header"><b>Analysis</b></h4>
            <p className="trial_analysis_note">Save group updates to generate analysis.</p>
            {this.renderIncludesCombination(trial)}
            <div>
              <h4>Placebo Included?</h4>
              <strong>
                {trial.includes_placebo ? 'YES' : 'NO' }
              </strong>
            </div>
          </div>
        </div>
      </div>
    )
  }
}




class ImportedInterventions extends Component {
  constructor(props) {
    super(props);
    this.state = {show_list: false};
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    let show_list = !this.state.show_list;
    this.setState({show_list})
  }

  renderIntervention(intervention, index) {
    return (
      <div className="intervention group_panel_list" key={index}>
        <label>Type:</label> {Inflector.humanize(intervention.type)}<br/>
        <label>Name:</label> {intervention.name}<br/>
        <label>Description:</label> {intervention.description}<br/>
        <label>Label:</label> {intervention.label}<br/>
        <label>Other Name:</label>{intervention.other_name}<br/>
      </div>
    )
  }

  renderList() {
    if (!this.state.show_list) return null;
    let {interventions} = this.props;
    return(
      <div>
        {interventions.map((i, x) => this.renderIntervention(i, x))}
      </div>
    );
  }

  render() {
    let {interventions} = this.props;
    if (!interventions) return null;

    let action = this.state.show_list ? 'Hide' : 'Show';
    return (
      <div>
        <h4>{interventions.length} Imported Interventions</h4>
        <button className="btn btn-default" onClick={this.toggle}>{action} Interventions</button>
        <br/>
        {this.renderList()}
      </div>
    )
  }
}

class ImportedARM extends Component {
  constructor(props) {
    super(props);
    this.state = {show_list: false};
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    let show_list = !this.state.show_list;
    this.setState({show_list})
  }

  renderArmGroup(arm_group, index) {
    if (!arm_group) return null;
    return (
      <div className="group_panel_list" key={index}>
        <p className="arm_group_index">#{index + 1}</p>
        <label>Label:</label> {arm_group.label}<br/>
        <label>Type:</label> {Inflector.humanize(arm_group.type)}<br/>
        <label>Description:</label> {arm_group.description}<br/>
      </div>
    )
  }

  renderList() {
    if (!this.state.show_list) return null;
    let {arm_groups} = this.props;
    return(
      <div id="arm_groups">
        {arm_groups.map((g, x) => this.renderArmGroup(g, x))}
        <span>Note: Numbers are for display purpose only. These numbers can change with updates from clinicaltrials.gov.</span>
      </div>
    );
  }

  render() {
    let {arm_groups} = this.props;
    if (!arm_groups || arm_groups.length === 0) return null;

    let action = this.state.show_list ? 'Hide' : 'Show';
    return (
      <div>
        <h4>{arm_groups.length} Imported Arm Groups</h4>
        <button className="btn btn-default" onClick={this.toggle}>{action} Arm Groups</button>
        <br/>
        {this.renderList()}
      </div>
    )
  }
}
