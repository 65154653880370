import React, {Component} from 'react';
import * as Inflector from 'inflected';
import Autosize from 'autosize';
import Select2 from './select2';
import DatePicker from './datepicker';
import Markdown from './markdown';
import GeolocationPicker from './geolocation_picker';
import StarsRatingField from './stars_rating_field';
import request from '../request';
import Loading from './loading';
import Slektr from './slektr_wrapper';
import ItemsCollection from './items_collection';

const JUST_SUBMITTED_TIMEOUT = 2500;
const FOCUS_DELAY = 1000;

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {just_submitted: false};
  }

  componentDidUpdate(prevProps) {
    let {submitting, errors} = this.props;

    let errors_found = errors && (Array.isArray(errors) ? errors.length > 0 : Object.keys(errors).length > 0);
    if (errors_found) return;

    if (prevProps.submitting && !submitting) {
      let was_new = !prevProps.model.id;
      this.setState({just_submitted: true, was_new});
      setTimeout(() => this.setState({just_submitted: false, was_new: undefined}), JUST_SUBMITTED_TIMEOUT);
    }
  }

  change(field, e) {
    let {model, onChange, onChangeField} = this.props;
    let name, value;
    if (e.hasOwnProperty('target')) {
      name = e.target.name || field.name;
      value = e.target.value;
    } else {
      value = e;
    }

    if (onChangeField) {
      onChangeField(name, value);
    }
    if (model && onChange) {
      model[name] = value;
      onChange(model);
    }
  }

  renderField(field, prefix, model, errors={}) {
    if (field.visible && !field.visible(model)) return null;
    let {onChange, readOnly} = this.props;
    let props = {key: field.name, prefix, field, model, readOnly}

    props.value = model[field.name];
    props.errors = errors[field.name];
    props.onChange = (e, meta) => this.change(field, e, meta);
    props.globalOnChange = onChange;
    props.ref = 'field_' + field.name;
    props.label = getFieldLabel(field);

    let component = getFieldComponent(field);
    return React.createElement(component, props);
  }

  submitButtonVisible() {
    let { readOnly, onSubmit} = this.props;
    return !readOnly && onSubmit;
  }

  renderSubmit() {
    let { model, fields,  onSubmit, submitting, singleton, submitLabel} = this.props;
    let {just_submitted, was_new} = this.state;
    let modelName = this.props.modelName || this.props.model_name;

    if (!this.submitButtonVisible()) return null;

    let label, icon, disabled;

    if (just_submitted) {
      if (!was_new || singleton) {
        label = modelName + " Updated";
      } else {
        label = modelName + " Created";
      }

      icon = <i className="glyphicon glyphicon-ok" />
    }

    else if (submitting) {
      disabled = true;
      label = "Submitting " + modelName;
      icon = <i className="glyphicon glyphicon-refresh glyphicon-spin" />
    }

    else {
      if (submitLabel) {
        label = submitLabel;
      } else if (model.id || singleton) {
        label = "Update " + modelName;
      } else {
        label = "Create " + modelName;
      }

      disabled = !isFormValid(model, fields);
    }

    return (
      <button disabled={disabled} className='btn btn-success' onClick={onSubmit} >
        {icon} {label}
      </button>
    )
  }

  cancelButtonVisible() {
    let {onCancel} = this.props;
    return !!onCancel;
  }

  cancelButtonDisabled() {
    let {submitting} = this.props;
    if (submitting) return true;
    return false;
  }

  renderCancel() {
    let {onCancel} = this.props;
    if (!this.cancelButtonVisible()) return null;
    let disabled = this.cancelButtonDisabled();
    return (
      <button className="btn btn-default" disabled={disabled} onClick={onCancel}>Cancel</button>
    );
  }


  renderButtons() {
    let {hideButtons} = this.props;
    if (hideButtons) return null;
    if (!this.submitButtonVisible() && !this.cancelButtonVisible()) return null;

    return (
      <div className="btn-group">
        {this.renderSubmit()}
        {this.renderCancel()}
      </div>
    )
  }

  render() {
    let {fields, model, errors, prefix, containerElement, id} = this.props;
    let fields_el = fields.map(f => this.renderField(f, prefix, model, errors));
    let buttons = this.renderButtons();
    let container_props = {};

    if (!containerElement) {
      containerElement = this.props.container_element || 'form';
      container_props.onSubmit = e => e.preventDefault();;
    }

    return React.createElement(containerElement, container_props, fields_el, buttons);
  }
}

class FormField extends Component {
  containerClass(field, errors) {
    let {model} = this.props;
    let className = "form-group " + this.fieldID();
    if (isRequired(field, model)) className += " required";
    if (errors) className += " has-error";
    return className;
  }

  fieldID() {
    let {field, prefix} = this.props;
    return [prefix, field.name].filter(i => !!i).join('_');
  }

  labelClass(field, errors) {
    let {model} = this.props;
    let className="control-label";
    if (isRequired(field, model)) className += " required";
    return className;
  }

  renderHint(field) {
    if (!field.hint) return null;
    return (
      <span className="help-block">{field.hint}</span>
    );
  }

  renderLabel(field) {
    let {model} = this.props;
    if (field.label === false) return null;
    let required = isRequired(field, model);

    let labelRequired = required ? <abbr title="required">*</abbr> : null;
    let className="control-label";
    if (required) className += " required";

    return <label className={className} htmlFor={field.name}> {labelRequired} {getFieldLabel(field)}</label>
  }

  renderErrors(errors) {
    if (!errors) return null;
    let message = errors.join(', ');
    return (
      <span className="help-block has-error">{message}</span>
    );
  }

  renderFormGroup(child) {
    let {field, errors, onChange, model} = this.props;
    let labelRequired = isRequired(field, model) ? <abbr title="required">*</abbr> : null;

    let className = "form-control";
    if (isRequired(field, model)) className += " required";

    return (
      <div className={this.containerClass(field, errors)}>
        {this.renderLabel(field)}
        {child}
        {this.renderHint(field)}
        {this.renderErrors(errors)}
      </div>
    )
  }

  isReadOnly() {
    return this.props.readOnly;
  }

  isDisabled() {
    let {field, model} = this.props;
    if (!field.disabled) return false
    if (typeof field.disabled === 'function') {
      return field.disabled(model);
    }
    return field.disabled;
  }

  getValueLabel() {
    let {field, value} = this.props;
    if (field.valueLabelCallback) {
      return field.valueLabelCallback(this.props.model);
    }
    return value;
  }

  renderValue() {
    let {field} = this.props;
    let value = this.getValueLabel();
    return (
      <dl>
       <dt>{this.renderLabel(field)}</dt>
      <dd>{value}</dd>
      </dl>

    );
  }

  render() {
    if (this.isReadOnly()) {
      return this.renderValue();
    } else {
      return this.renderInput();
    }
  }
}

class StringInput extends FormField {
  componentDidMount() {
    let {field} = this.props;
    if (field.focus) {
      setTimeout(e => this.refs.input.focus(), FOCUS_DELAY);
    }
  }

  renderInput() {
    let {field, value, onChange, model} = this.props;

    let className = "form-control";
    if (isRequired(field, model)) className += " required";

    return this.renderFormGroup(
      <input type="text" ref="input" id={this.fieldID()} className={className}  name={field.name} value={value || ''} disabled={this.isDisabled()} onChange={onChange} placeholder={field.placeholder} />
    )
  }
}


class StringWithLinkInput extends StringInput {
  isLinkEnabled() {
    let {field, value} = this.props;
    if (!field.showLink || (!field.linkTemplate || field.linkTemplate.length === 0)) return false;
    return true;
  }

  renderLink() {
    let {field, value} = this.props;
    if (!this.isLinkEnabled()) return null;
    let disabled = (!value || value.length === 0);
    let {linkTemplate} = field;
    if (!linkTemplate) return null;
    let url = linkTemplate.replace('{id}', value);
    return <a href={url} target="_blank"  disabled={disabled} className="input-group-addon"><span className="fa-solid fa-arrow-up-right-from-square"></span></a>
  }

  renderInput() {
    let {field, value, onChange, model} = this.props;
    let containerClassName = this.isLinkEnabled() ? "input-group" : null;
    let className = "form-control";
    if (isRequired(field, model)) className += " required";

    return this.renderFormGroup(
      <div className={containerClassName}>
        <input type="text" ref="input" id={this.fieldID()} className={className}  name={field.name} value={value || ''} disabled={this.isDisabled()} onChange={onChange} placeholder={field.placeholder} />
        {this.renderLink()}
      </div>
    )
  }
}

class TextInput extends FormField {
  initTextArea(el) {
    let {field} = this.props;
    if (!this.autosize_enabled && el && field.autosize) {
      this.autosize_enabled = true;
      Autosize(el);
    }
  }
  renderInput() {
    let {field, value, onChange, model} = this.props;

    let className = "form-control";
    if (isRequired(field, model)) className += " required";

    return this.renderFormGroup(
      <textarea id={this.fieldID()} ref={el => this.initTextArea(el)} className={className} name={field.name} value={value || ''} disabled={this.isDisabled()} onChange={onChange} rows={field.rows} placeholder={field.placeholder} />
    );
  }
}


class NumberInput extends FormField {
  onChange(e) {
    let {onChange} = this.props;
    let {name, value} = e.target;

    if (isNaN(value)) {
      // value = undefined;
      return;
    }
    value = +value;
    onChange({target: {name, value}});
  }

  renderInput() {
    let {field, value, model, onChange} = this.props;
    let className = "form-control";
    if (isRequired(field, model)) className += " required";
    // We can't use the || operator with 0
    if (value === undefined || value === null) value = '';
    return this.renderFormGroup(
      <input type="text" id={this.fieldID()} className={className}  name={field.name} value={value} disabled={this.isDisabled()} onChange={e => this.onChange(e)} placeholder={field.placeholder} />
    )
  }
}

class FloatInput extends FormField {
  onChange(e) {
    let {onChange} = this.props;
    let {name, value} = e.target;
    value = value.replaceAll(',', '.');

    if (isNaN(value)) {
      // value = undefined;
      return;
    }
    //value = +value;
    onChange({target: {name, value}});
  }

  renderInput() {
    let {field, value, model, onChange} = this.props;
    let className = "form-control";
    if (isRequired(field, model)) className += " required";
    // We can't use the || operator with 0
    if (value === undefined || value === null) value = '';
    return this.renderFormGroup(
      <input type="text" id={this.fieldID()} className={className}  name={field.name} value={value} disabled={this.isDisabled()} onChange={e => this.onChange(e)} placeholder={field.placeholder} />
    )
  }
}

class CheckboxInput extends FormField {
  onChange(e) {
    let {onChange} = this.props;
    let {name, checked} = e.target;
    let value_label = checked ? 'Yes' : 'No';
    onChange({target: {name, value: checked}}, {value_label});
  }

  getValueLabel() {
    let {value} = this.props;
    if (value === undefined || value === null) return null;
    return value ? 'Yes' : 'No';
  }

  renderInput() {
    let {field, value, model, errors, onChange} = this.props;
    let labelRequired = isRequired(field, model) ? <abbr title="required">*</abbr> : null;

    let className = "form-control boolean";
    if (field.required) className += " required";
    return (
      <div className={this.containerClass(field, errors)}>
        <label className={this.labelClass(field)} htmlFor={field.name}> {labelRequired}
          <input type="checkbox" id={this.fieldID()}name={field.name} value={value || false} checked={value || false} disabled={this.isDisabled()} onChange={e => this.onChange(e)} />
          {this.renderLabel(field)}
        </label>
        {this.renderHint(field)}
        {this.renderErrors(errors)}
      </div>
    )
  }
}

class Select2Input extends FormField {
  getValueLabel() {
    let {field, value} = this.props;
    let options = buildOptions(field);
    let option = options.find(o => ''+o.id === ''+value);
    if (!option) return null;
    return option.label;
  }

  renderInput() {
    let {field, value, model, onChange} = this.props;
    let className = "form-control";
    if (isRequired(field, model)) className += " required";

    let options = buildOptions(field);

    if (!value) value = field.multiple ? [] : '';
    return this.renderFormGroup(
      <Select2 id={this.fieldID()} multiple={!!field.multiple} className={className} name={field.name} value={value} disabled={this.isDisabled()} onChange={onChange} options={options} />
    )
  }
}


class Select2TagsInput extends FormField {
  renderInput() {
    let {field, value, model, onChange} = this.props;
    let className = "form-control";
    if (isRequired(field, model)) className += " required";
    let options = buildOptions(field);

    return this.renderFormGroup(
      <Select2 id={this.fieldID()} tags={true} multiple={true} className={className} name={field.name} value={value || []} disabled={this.isDisabled()} onChange={onChange} options={options}/>
    )
  }
}

class RemoteSelect2Input extends FormField {
  isLinkEnabled() {
    let {field, value} = this.props;
    if (field.multiple) return false;
    if (!field.showLink || (!field.linkTemplate || field.linkTemplate.length === 0)) return false;
    return true;
  }

  renderLink() {
    let {field, value} = this.props;
    if (!this.isLinkEnabled()) return null;
    let disabled = (!value || value.length === 0);
    let {linkTemplate} = field;
    if (!linkTemplate) return null;
    let url = linkTemplate.replace('{id}', value);
    return <a href={url} target="_blank"  disabled={disabled} className="input-group-addon"><span className="fa-solid fa-arrow-up-right-from-square"></span></a>
  }

  renderInput() {
    let {field, value, model, onChange} = this.props;
    let className = "form-control";
    let containerClassName = this.isLinkEnabled() ? "input-group" : null;
    let allowBlank = (!field.required && !field.multiple);
    if (isRequired(field, model)) className += " required";
    if (!value) value = (field.multiple || field.tags) ? [] : '';

    return this.renderFormGroup(
      <div className={containerClassName}>
        <Select2 id={this.fieldID()} remote={true} tags={field.tags} multiple={field.multiple || field.tags} searchField={field.search_key} filters={field.filters} src={field.src} className={className} name={field.name} value={value} disabled={this.isDisabled()} allowBlank={allowBlank} onChange={onChange} />
        {this.renderLink()}
      </div>
    )
  }
}


class SelectInput extends FormField {
  getOptions() {
    let {field} = this.props;
    return buildOptions(field);
  }

  getValueLabel() {
    let {field, value} = this.props;
    let options = buildOptions(field);
    let option = options.find(o => o.id === value);
    if (!option) return field.blankLabel || null;
    return option.label;
  }

  onChange(e) {
    let {value} = e.target;
    let {onChange} = this.props;
    let options = this.getOptions();
    let option = options.find(o => o.id === value);
    let value_label = option ? option.label : undefined;
    let meta = {value_label};
    onChange(e, meta);
  }

  renderInput() {
    let {field, value, model, onChange} = this.props;
    let {blankLabel, allowBlank} = field;
    let className = "form-control";
    if (isRequired(field, model)) className += " required";

    let options = this.getOptions().map(({id, label}) => <option key={id} value={id}>{label}</option>);;

    let blank;
    if (allowBlank !== false) {
      blank = <option value="">{blankLabel}</option>;
    }

    return this.renderFormGroup(
      <select id={this.fieldID()} className={className} name={field.name} value={value || ''} disabled={this.isDisabled()} onChange={e => this.onChange(e)} >
        {blank}
        {options}
      </select>
    );
  }
}

class RemoteSelectInput extends SelectInput {
  constructor(props) {
    super(props);
    this.state = {options: []};
  }

  componentDidMount() {
    this.fetchOptions()
  }

  fetchOptions() {
    let {field} = this.props;
    request('GET', field.src).then(({results}) => {
      let options = results.map(r => ({id: r.id, label: r.text}));
      this.setState({options});
    });
  }

  getOptions() {
    return this.state.options;
  }
}


class RadioInput extends FormField {
  getValueLabel() {
    let {field, value} = this.props;
    let options = buildOptions(field);
    let option = options.find(o => o.id === value);
    if (!option) return null;
    return option.label;
  }

  onChange(e) {
    let {value} = e.target;
    let {field, onChange} = this.props;
    let options = buildOptions(field);
    let option = options.find(o => o.id === value);
    let value_label = option ? option.label : undefined;
    let meta = {value_label};
    onChange(e, meta);
  }

  onResetRadio(e) {
    e.preventDefault();
    let {field, onChange} = this.props;
    if (!field.reseteable) return;

    let meta = {value_label: undefined};
    let target = {name: field.name, value: undefined};
    onChange({target}, meta);
  }

  renderInput() {
    let {field, value, model, onChange} = this.props;

    let className = "form-control";
    if (isRequired(field, model)) className += " required";

    let options = buildOptions(field);
    return this.renderFormGroup(options.map(option => {
      let checked = option.id === value;
      return (
        <div className="radio" key={option.id}>
          <label onDoubleClick={e => this.onResetRadio(e)}>
            <input type="radio" name={field.name} value={option.id} checked={checked} disabled={this.isDisabled()} onChange={e => this.onChange(e)} />
            {option.label}
          </label>
        </div>
      );
    }));
  }
}

class MultipleCheckboxesInput extends FormField {
  getValue(field, value) {
    let options = buildOptions(field);
    let selected = [];

    if (Array.isArray(value)) {
      for (let s of value) {
        let option = options.find(o => o.id === s.id);
        if (option) selected.push(option.text);
      }
    } else {
      for (let key of Object.keys(value)) {
        if (value[key]) {
          let option = options.find(o => o.id === key);
          if (option) selected.push(option.text);
        }
      }
    }

    let value_label = selected.join(', ');
    return value_label;
  }

  getValueLabel() {
    let {field, value} = this.props;
    if (!value) return null;
    return this.getValue(field, value);
  }

  onChange(e) {
    let {field, onChange, value} = this.props;
    if (!value) value = [];
    let option_id = e.target.value;

    if (Array.isArray(value)) {
      let index = value.indexOf(option_id)
      if ( index === -1) value.push(option_id);
      else value.splice(index, 1);
    } else {
      value[option_id] = e.target.checked;
    }

    let value_label = this.getValue(field, value);
    let meta = {value_label};
    onChange({target: {name: field.name, value}}, meta);
  }

  renderInput() {
    let {field, value, model, onChange} = this.props;
    if (!value) value = [];

    let className = "form-control";
    if (isRequired(field, model)) className += " required";

    let options = buildOptions(field);
    return this.renderFormGroup(options.map(option => {
      let checked;
      if (Array.isArray(value)) {
        checked = value.findIndex(id => id === option.id) !== -1;
      } else {
        checked = !!value[option.id];
      }

      return (
        <div className="checkbox" key={option.id}>
          <label>
            <input type="checkbox" name={field.name} value={option.id} checked={checked} disabled={this.isDisabled()} onChange={e => this.onChange(e)} />
            {option.label}
          </label>
        </div>
      );
    }));
  }
}

class TextInlineInput extends FormField {
  constructor(props) {
    super(props);
    let value = "" + props.value;
    this.state = {value, edit: false}

    this.toggleEditable = this.toggleEditable.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    let {value} = e.target;
    this.setState({value});
  }

  submit(e) {
    let {field, onChange} = this.props;
    let {value} = this.state;
    let meta = {value_label: value};
    onChange({target: {name: field.name, value}}, meta);
    this.setState({edit: false});
  }

  cancelEdit() {
    let {value} = this.props;
    this.setState({value, edit: false});
  }

  isDisabled() {
    let {edit} = this.state;
    if (!edit) return true;
    return super.isDisabled();
  }

  toggleEditable() {
    let changes = {edit: !this.state.edit};

    // Make sure we always edit the last version of changes
    if (changes.edit) {
      changes.value = this.props.value;
    }
    this.setState(changes);
  }

  initTextArea(el) {
    if (!el) return;
    el.focus();

    let {field} = this.props;
    if (!this.autosize_enabled && field.autosize) {
      this.autosize_enabled = true;
      Autosize(el);
    }
  }


  renderReadOnlyInput(className) {
    let {field, value} = this.props;
    return this.renderFormGroup(
      <div id={this.fieldID()} ref={el => this.initTextArea(el)} onClick={this.toggleEditable} className="disabled-div">
        {value}
      </div>
    )

  }

  renderEditableInput() {
    let {field, model} = this.props;
    let {value} = this.state;

    let className = "form-control";
    if (isRequired(field, model)) className += " required";

    return this.renderFormGroup(
      <div>
        <textarea id={this.fieldID()} ref={el => this.initTextArea(el)} className={className} name={field.name} value={value || ''} disabled={false} onChange={this.onChange} rows={field.rows} placeholder={field.placeholder} />
        <button className="btn btn-primary btn-sm" onClick={e => this.submit()}>Save Changes</button>
        <button className="btn btn-default btn-sm" onClick={e => this.cancelEdit()}>Cancel</button>
      </div>
    )
  }

  renderInput() {
    if (this.isDisabled()) {
      return this.renderReadOnlyInput();
    } else {
      return this.renderEditableInput();
    }
  }
}


class MarkdownInput extends FormField {
  constructor(props) {
    super(props);
    this.state = {edit: true}
    this.showPreview = this.showPreview.bind(this);
    this.showEditor = this.showEditor.bind(this);
  }

  showPreview() {
    this.setState({edit: false});
  }

  showEditor() {
    this.setState({edit: true});
  }

  renderPreview() {
    let {field, model, value} = this.props;

    let className = "form-control preview_content";
    if (isRequired(field, model)) className += " required";

    return <Markdown id={this.fieldID()} className={className} name={field.name} text={value || ''} />
  }

  renderEditor() {
    let {field, value, model, onChange} = this.props;

    let className = "form-control";
    if (isRequired(field, model)) className += " required";

    return <textarea id={this.fieldID()} className={className} name={field.name} value={value || ''} disabled={this.isDisabled()} onChange={onChange} rows={field.rows} />
  }

  renderElement() {
    let {edit} = this.state;
    if (edit) return this.renderEditor();
    else return this.renderPreview();
  }

  renderActionButton() {
    let {edit} = this.state;
    if (edit) {
      return <button className="btn btn-primary btn-sm mb-5" onClick={this.showPreview}>Show Preview</button>;
    } else {
      return <button className="btn btn-primary btn-sm mb-5" onClick={this.showEditor}>Show Editor</button>;
    }
  }

  renderInput() {
    return this.renderFormGroup(
      <div>
        {this.renderActionButton()}
        {this.renderElement()}
      </div>
    );
  }
}



class DatePickerInput extends FormField {
  onChange(e) {
    let {value} = e.target;
    let {onChange} = this.props;

    if (typeof(value) === 'string') value = new Date(value);
    let meta = {value_label: moment(value).format('LL')};

    onChange(e, meta);
  }

  getValueLabel() {
    let {value} = this.props;
    if (value === undefined || value === null) return null;
    if (typeof(value) === 'string') value = new Date(value);
    return moment(value).format('LL');
  }

  renderInput() {
    let {field, value, model, onChange} = this.props;

    let className = "form-control";
    if (isRequired(field, model)) className += " required";

    return this.renderFormGroup(
      <DatePicker id={this.fieldID()} className={className} name={field.name} value={value} disabled={this.isDisabled()} onChange={e => this.onChange(e)} />
    );
  }

}

class GeolocationInput extends FormField {
  renderInput() {
    let {field, model, globalOnChange} = this.props;

    let className = "form-control";
    if (isRequired(field, model)) className += " required";

    return this.renderFormGroup(
      <GeolocationPicker id={this.fieldID()} className={className} name={field.name} record={model} globalOnChange={globalOnChange} paramsMapping={field.paramsMapping} />
    );
  }

}

class ButtonInput extends FormField {
  renderInput() {
    let {field, label} = this.props;
    let className = field.className || 'btn btn-default';
    return (
      <div className="form-group">
        <button id={this.fieldID()} className={className} disabled={this.isDisabled()} onClick={field.onClick}>{field.label}</button>
      </div>
    )
  }
}


class ComponentInput extends FormField {
  getValueLabel() {
    return this.renderInput();
  }
  renderInput() {
    let {field, onChange} = this.props;
    let props = field.props || {};

    if (field.adapter) {
      props = field.adapter(this.props);
    }

    if (!props.onChange) {
      props.onChange = onChange;
    }

    return React.createElement(field.component, props)
  }
}

class HeaderInput extends FormField {
  renderInput() {
    let {field, value, onChange} = this.props;

    let label = getFieldLabel(field);
    return <h3>{label}</h3>;
  }
}


class FileInput extends FormField {
  onChange(e) {
    let {field, onChange} = this.props;
    let value = e.target.files[0];
    onChange({target: {name: field.name, value}});
  }

  renderInput() {
    let {field, value, model, onChange} = this.props;

    let className = "form-control";
    if (isRequired(field, model)) className += " required";

    return this.renderFormGroup(
      <input type="file" id={this.fieldID()} className="form-control-file"  disabled={this.isDisabled()} onChange={e => this.onChange(e)} />
    );
  }
}

class StarsRatingInput extends FormField {
  onChange(e) {
    let {field, onChange} = this.props;
    let value = e.target.value;
    onChange({target: {name: field.name, value}});
  }

   renderInput() {
     let {field, value, model, onChange} = this.props;
     let stars_count = field.maxRating || 5;
     return this.renderFormGroup(
       <StarsRatingField starsCount={stars_count} value={value} onChange={e => this.onChange(e)} extraOptions={field.extraOptions} />
     );
   }
}


class SlektrInput extends FormField {
  renderInput() {
    let {field, value, model, onChange} = this.props;
    let className = "form-control";
    let allowBlank = (!field.required && !field.multiple);
    if (isRequired(field, model)) className += " required";
    if (!value) value = (field.multiple || field.tags) ? [] : '';

    let options = buildOptions(field);
    options = options.map(o => ({value: o.id, label: o.label || o.name}));

    return this.renderFormGroup(
      <Slektr id={this.fieldID()} multiple={field.multiple || field.tags} searchKey={field.search_key} filters={field.filters} src={field.src} link={field.link} className={className} name={field.name} value={value} disabled={this.isDisabled()} allowBlank={allowBlank} onChange={onChange} options={options} onChangeCallback={field.onChangeCallback} />
    )
  }
}

class ItemsCollectionInput extends FormField {
  onChangeItems(items) {
    let {field, onChange} = this.props;
    onChange({target: {name: field.name, value: items}});
  }

  renderInput() {
    let {field, value, model, onChange} = this.props;
    if (!value) value = [];
    return <ItemsCollection inputLabel="Add Interventions, Diagnostics, Combination, MOA" items={value} onChange={i => this.onChangeItems(i)} sortableItems {...field.extra}  />
  }
}

function getFieldComponent(field) {
  if (field.type && typeof field.type !== 'string') return field.type;
  if (field.type === 'component') return ComponentInput;
  if (field.type ==='select2') return Select2Input;
  if (field.type ==='select2-tags') return Select2TagsInput;
  if (field.type ==='remote-select2') return RemoteSelect2Input;
  if (field.type ==='select') return SelectInput;
  if (field.type ==='remote-select') return RemoteSelectInput;
  if (field.type ==='filtered-select') return FilteredSelectInput;
  if (field.type === 'bool') return CheckboxInput;
  if (field.type === 'radio') return RadioInput;
  if (field.type === 'checkboxes') return MultipleCheckboxesInput;
  if (field.type === 'text') return TextInput;
  if (field.type === 'text-inline') return TextInlineInput;
  if (field.type === 'markdown') return MarkdownInput;
  if (field.type === 'number') return NumberInput;
  if (field.type === 'float') return FloatInput;
  if (field.type === 'date') return DatePickerInput;
  if (field.type === 'geolocation') return GeolocationInput;
  if (field.type === 'button') return ButtonInput;
  if (field.type === 'file') return FileInput;
  if (field.type === 'header') return HeaderInput;
  if (field.type === 'stars-rating') return StarsRatingInput;
  if (field.type === 'slektr') return SlektrInput;
  if (field.type === 'string-with-link') return StringWithLinkInput;
  if (field.type === 'items-collection') return ItemsCollectionInput;
  return StringInput;
}


function isFormValid(model, fields) {
  for (let field of fields) {
    if (isRequired(field, model) && !isFieldValid(model, field)) {
      return false;
    }

  }
  return true;
}

function isFieldValid(model, field) {
  let value = model[field.name];
  if (field.visible && !field.visible(model)) return true;
  if (value === null || value === undefined) return false;
  if (field.type !== 'bool' &&  value.length === 0) return false;
  return true;
}

function buildOptions(field) {
  if (!field.collection) return [];

  if (Array.isArray(field.collection)) {
    return field.collection.map(record => {
      let id, label;

      if (field.idField) id = record[field.idField];
      else if (record.id !== undefined) id = record.id;
      else id = record;

      if (field.labelField) label = record[field.labelField];
      else if (record.name !== undefined) label = record.name;
      else label = record;

      if (field.labelTransform) label = transformText(label, field.labelTransform);


      return {id, label};
    });
  } else {
    return Object.keys(field.collection).map(id => {
      let label;

      if (field.labelField) label = field.collection[id][field.labelField]
      else if (field.collection[id].name !== undefined) label = field.collection[id].name
      else label = field.collection[id];

      if (field.labelTransform) label = transformText(label, field.labelTransform);
      return {id, label};
    });
  }
}

function getFieldLabel(field) {
  return field.label || Inflector.titleize(field.name);
}


function transformText(string, transform) {
  switch(transform) {
  case 'titleize': return Inflector.titleize(string);
  default: return string;
  }
}


/*
 * Export some utilities
 * -------------------------------------------------------------------*/

Form.FormField = FormField;
Form.getFieldLabel = getFieldLabel;


class EmbedForm extends Form {
    render() {
    let {fields, model, errors, prefix} = this.props;
    return (
      <div>
        {fields.map(f => this.renderField(f, prefix, model, errors))}
        {this.renderSubmit()}
        {this.renderCancel()}
      </div>
    )
  }
}

const DEBOUNCE_TIMEOUT = 1000;
class FilteredSelectInput extends FormField {
  constructor(props) {
    super(props);
    this.state = {show_modal: false, query: '', filter: {}};
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    let el = $(this.refs.modal_container);
    el.modal({show: false});
    el.on('hide.bs.modal', () => {
      this.setState({show_modal: false});
    });

    if (this.props.value) {
      this.fetchValue();
    }
  }

  onChangeFilter(filter) {
    this.setState({filter});
    if (this.debouce_timeout_id) clearTimeout(this.debouce_timeout_id);
    if (!filter.name || filter.name.length < 2) return;
    this.debouce_timeout_id = setTimeout(this.search, DEBOUNCE_TIMEOUT);
  }

  search() {
    this.debouce_timeout_id = null;
    this.setState({searching: true});
    let {field} = this.props;
    let {filter} = this.state;
    let query_string = new URLSearchParams(filter).toString()
    let url = field.src + '?' + query_string;
    request('GET', url).then(({results}) => {
      this.setState({results, searching: false});
    });
  }


  select(record) {
    let value = record.id
    let {field, onChange} = this.props;
    let meta = {value_label: record.text};
    onChange({target: {name: field.name, value}}, meta);

    $(this.refs.modal_container).modal('hide');
    this.setState({value_label: record.text, show_modal: false, query: '', results: undefined});
  }

  fetchValue() {
    let {field, value} = this.props;
    let url = field.src + '?ids=' + value;
    request('GET', url).then(({results}) => {
      let option = results.find(r => r.id === value);
      if (option !== -1) this.setState({value_label: option.text});
    });
  }

  cancel() {
    $(this.refs.modal_container).modal('hide');
    this.setState({show_modal: false});
  }

  showModal(e) {
    this.setState({show_modal: true}, () => $(this.refs.modal_container).modal('show'));
  }


  renderSearching() {
    return <Loading />
  }

  renderNothingFound() {
    return (
      <div className="text-centered">Nothing Found</div>
    );
  }
  renderResults(results) {
    if (this.state.searching) return this.renderSearching();
    if (!results) return null;
    if (results.length === 0) return this.renderNothingFound();

    return (
      <table className="table table-condensed table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {results.map(result => {
            return (
              <tr key={result.id}>
                <td>{result.text}</td>
                <td>
                  <button className="btn btn-default btn-sm pull-right" onClick={e => this.select(result)}>Select</button>
                  <div className="clearfix"></div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderContent() {
    let {field} = this.props;
    let {show_modal, query, filter, results} = this.state;
    if (!show_modal) return null;
    let disabled = !isFormValid(filter, field.filters);

    return (
      <div>
        <EmbedForm fields={field.filters} model={filter} onChange={e => this.onChangeFilter(e)} />
        <hr />
        {this.renderResults(results)}
      </div>
    );
  }

  renderModal() {
    let {field} = this.props;
    let title = "Search " + getFieldLabel(field);

    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" aria-label="Close" onClick={e => this.cancel()}><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">{title}</h4>
            </div>
            <div className="modal-body">
              {this.renderContent()}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" onClick={e => this.cancel()}>Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getValueLabel() {
    let {value_label} = this.state;
    return value_label;
  }


  renderInput() {
    let {field, model, onChange} = this.props;
    let value = this.getValueLabel() || '';
    let className = "form-control";
    if (isRequired(field, model)) className += " required";

    return this.renderFormGroup(
      <div>
        <input type="text" id={this.fieldID()} className={className} value={value} onClick={e => this.showModal(e)} onChange={e => true} />
        {this.renderModal()}
      </div>
    );
  }
}


function isRequired(field, model) {
  if (typeof field.required === 'function') {
    return field.required(model);
  } else {
    return field.required;
  }
}
