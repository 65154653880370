import React, {Component} from 'react';
import EventViewer from './event_viewer';

export default class JourneyRecord extends Component {
  getTitle() {
    return "Override Me! getTitle";
  }

  getSubtitle() {}

  renderForm() {
    return "Override Me! renderForm";
  };

  getBasePath() {
    return "Override Me! getBasePath";
  }

  formFields() {
    return "Override Me! formFields";
  }

  getSectionLabel() {
    return [this.getTitle()]
  }

  showChangelog() {
    this.setState({show_changelog: true});
  }

  hideChangelog() {
    this.setState({show_changelog: false});
  }


  onChange(field_name, value, meta) {
    let {onChange} = this.props;
    meta.section_label = this.getSectionLabel();
    onChange(this.getBasePath(), field_name, value, meta);
  }


  renderChangelog() {
    let {fields_changelog, patient} = this.props;
    let path = this.getBasePath();
    return <EventViewer fields={this.formFields()} patient={patient} path={path} changelog={fields_changelog} />
  }

  render() {
    let {readOnly} = this.props;
    let {show_changelog} = this.state;

    let action_btn, content, box_class;
    if (readOnly) {
      box_class = 'jt_read_only_box'
    } else {
      box_class = 'jt_form_box'
    };
    if (show_changelog) {
      content = this.renderChangelog();
      action_btn = <button className="btn btn-sm btn-warning pull-right" onClick={e => this.hideChangelog()}>Close Changelog</button>
    } else {
      content = this.renderForm();
      if (readOnly) {
        action_btn = <button className="btn btn-sm btn-link pull-right" onClick={e => this.showChangelog()}>Show Changelog</button>
      }
    }

    let title = <b>{this.getTitle()}</b>;
    let subtitle = this.getSubtitle();
    return (
      <div>
        <h4>{title} {subtitle}</h4>
        <div className={box_class}>
          {action_btn}
          <div className="clearfix"></div>
          {content}
        </div>
      </div>
    );
  }
}
