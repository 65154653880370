import React, {Component} from 'react';
import MapViewer from './map_viewer';
import Loading from './loading';
import request from '../request';
import {CancerCenterSearchKey} from '../constants.js.erb';

export default class MultiCancerCenterPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addCancerCenters = this.addCancerCenters.bind(this);
  }

  componentDidMount() {
    this.fetchCancerCenterNames();
  }

  fetchCancerCenterNames() {
    let {cancer_centers} = this.props;
    // We initialize passthough fields as hashes and therefore length === undefined
    if (!cancer_centers || cancer_centers.length === 0 || cancer_centers.length === undefined) return;

    this.setState({loading: true});

    let url = '/admin/cancer_centers/as_options?ids=' + cancer_centers.join(',');
    request('GET', url).then(response => {
      let current_cancer_centers = response.results.map(c => ({id: c.id, name: c.text}));
      this.setState({current_cancer_centers, loading: false});

      // If the number of cancer centers mismatch it means that some has been deleted
      // We update the list of cancer centers to fix the issue
      if (cancer_centers.length !== current_cancer_centers.length) {
        let ids = current_cancer_centers.map(c => c.id);
        this.props.onChange(ids);
      }
    });
  }

  showModal() {
    this.setState({show_modal: true});
  }

  closeModal() {
    this.setState({show_modal: false});
  }

  deleteCancerCenter(cancer_center) {
    if (!confirm('Are you sure?')) return;

    let {cancer_centers, onChange} = this.props;
    let index = cancer_centers.findIndex(cancer_center_id =>  cancer_center_id === cancer_center.id);
    cancer_centers.splice(index, 1);
    onChange(cancer_centers);
  }

  deleteAll() {
    if (!confirm('Are you sure?')) return;
    let {cancer_centers, onChange} = this.props;
    onChange([]);
    this.setState({current_cancer_centers: []});
  }

  addCancerCenters(new_cancer_centers) {
    let {cancer_centers, onChange} = this.props;
    let {current_cancer_centers} = this.state;
    if (!cancer_centers || cancer_centers.length === undefined) cancer_centers = [];
    for (let cancer_center of new_cancer_centers) {
      if (cancer_centers.indexOf(cancer_center.id) === -1) {
        cancer_centers.push(cancer_center.id);
      }
    }

    onChange(cancer_centers);

    if (!current_cancer_centers) current_cancer_centers = [];
    current_cancer_centers = [...current_cancer_centers, ...new_cancer_centers];
    this.setState({current_cancer_centers});
  }

  renderModal() {
    let {cancer_centers} = this.props;
    if (!this.state.show_modal) return null;
    return <CancerCentersModal onClose={this.closeModal} onAddCancerCenters={this.addCancerCenters} current_cancer_centers={cancer_centers} />
  }

  renderDeleteAll() {
    let {cancer_centers} = this.props;
    window.cancer_centers = cancer_centers;
    if (!cancer_centers || cancer_centers.length < 5)  return null;
    return (
      <button className="btn btn-sm" onClick={e => this.deleteAll()}>Remove All</button>
    );
  }

  renderLabels() {
    let {cancer_centers} = this.props;
    let {current_cancer_centers, loading} = this.state;
    if (loading) return <Loading />;
    if (!current_cancer_centers) return null;
    if (!cancer_centers || cancer_centers.length === 0 || cancer_centers.length === undefined) return null;

    return (
      <div className="selected_centers_list_holder">
        <ul className="cancer_center_list">
          {cancer_centers.map(cancer_center_id => {
            let cancer_center = current_cancer_centers.find(c => c.id === cancer_center_id);

            // Deal with deleted cancer centers
            if (!cancer_center) return null;

            return (
              <li key={cancer_center.id}>
                <button className="btn btn-xs btn-default" onClick={e => this.deleteCancerCenter(cancer_center)}><i className="fa fa-times red" /> Remove </button>
                {cancer_center.name} {cancer_center.id}
              </li>
            )
          })}
        </ul>
        {this.renderDeleteAll()}
      </div>
    );
  }

  render() {
    return (
      <div className="multi-cancer_center-picker" >
        {this.renderLabels()}
        <button className="btn btn-default btn-success btn-sm" onClick={this.showModal}>Add Cancer Center</button>
        {this.renderModal()}
      </div>
    )
  }
}



class CancerCentersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {name: ''};
    this.onChange = this.onChange.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    $(this.refs.modal_container).modal({show: true});
    $(this.refs.modal_container).on('hide.bs.modal', () => {
      this.props.onClose();
    });
    setTimeout(e => this.refs.input.focus(), 500);
  }

  selectCancerCenter(cancer_center) {
    let {onAddCancerCenters} = this.props;
    if (cancer_center.is_umbrella) {
      onAddCancerCenters(cancer_center.childs);
    } else {
      onAddCancerCenters([cancer_center]);
    }
    $(this.refs.modal_container).modal('hide');
  }

  onChange(e) {
    let name = e.target.value;
    this.setState({name});
  }

  search(e) {
    let {current_cancer_centers} = this.props;
    this.setState({loading: true});
    e.preventDefault();
    let {name} = this.state;
    let url = `/admin/cancer_centers/search?q[${CancerCenterSearchKey}]=${name}`;

    request('GET', url)
      .then(cancer_centers => {
        if (current_cancer_centers.length > 0) {
          cancer_centers = cancer_centers.filter(c => current_cancer_centers.indexOf(c.id) === -1);
        }
        this.setState({cancer_centers, loading: false});
      })
      .catch(e => {
        this.setState({loading: false});
      });

  }

  renderResults() {
    let {cancer_centers, loading} = this.state;
    if (loading) return <Loading />
    if (!cancer_centers) return null;

    return (
      <div>
        {cancer_centers.map(cancer_center => {
          let counter, icon;
          if (cancer_center.is_umbrella) {
            counter = <span className="childs-count">({cancer_center.childs.length})</span>
            icon = <span className="label label-info">PARENT</span>
          }
          return (
            <div key={cancer_center.id}>
              <button className="btn btn-link" onClick={e => this.selectCancerCenter(cancer_center)}>
                {icon} {cancer_center.name} {counter}
              </button>
            </div>
          );
        })}
      </div>
    );
  }

  renderContent() {
    let {name} = this.state;
    return (
      <div>
        <form onSubmit={this.search}>
          <div className="form-group">
            <input type="text" className="form-control" ref="input" onChange={this.onChange} value={name} />
          </div>
          <button type="submit" className="btn btn-default">Submit</button>
        </form>

        {this.renderResults()}
      </div>
    );
  }

  render() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">Add Cancer Center</h4>
            </div>
            <div className="modal-body">
              {this.renderContent()}
            </div>
            <div className="modal-footer">

            </div>
          </div>
        </div>
      </div>
    )
  }

}
