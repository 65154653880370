import React, {Component} from 'react';
import Loading from './loading';
import Markdown from './markdown';
import request from '../request';

const REQUEST_DELAY = 3000;

export default class OpenAISummaryComponent extends Component {
  constructor(props) {
    super(props);
    this.requestSummary = this.requestSummary.bind(this);

    let {data} = this.props;
    if (data['requesting_summary']) {
      this.requestSummary();
    }
  }

  requestSummary(force=false) {
    let {url, onChange, onError, onChangeLoading} = this.props;
    onChangeLoading(true);
    if (force) url += '?force_update=true';
    onChangeLoading(true);

    request('POST', url).then(response => {
      if (!response.loading && response.summary) {
        // Change the summary on the main object
        onChange(response.summary);
        onChangeLoading(false);
      } else if (!response.loading && response.error) {
        let {error_message, error_code} = response;
        onError(error_message, error_code);
        onChangeLoading(false);
      } else {
        setTimeout(this.requestSummary, REQUEST_DELAY);
      }
    });
  }


  renderNoSummary() {
    return <p>You can generate a summary based off data summary below.</p>;
  }


  renderLoading() {
    return (
      <div>
        <p><b>Generating Summary</b><br/>
        <span className="subtle">This might take a few minutes. Please wait...</span></p>
        <Loading />
      </div>
    );
  }

  renderSummary() {
    let {data} = this.props;

    if (data.openai_summary_error_code) {
      return this.renderError();
    }

    let summary = data.openai_summary;
    if (!summary || summary.length === 0) return this.renderNoSummary();
    let summary_date = moment(data.openai_summary_requested_at).format('LLL');
    return (
      <div>
        <label className="summary_header">Generated Summary</label>
        <Markdown text={summary} />
        <i>Summary generated on {summary_date}</i>
      </div>
    )
  }

  renderError(){
    let {data} = this.props;
    let code = data.openai_summary_error_code
    let message = data.openai_summary_error_message;
    return (
      <div>
        <label><span className="fa-solid fa-triangle-exclamation red"></span> ERROR GENERATING THE SUMMARY [{code}]</label>
        <p><i>{message}</i></p>
      </div>
    )

  }

  render() {
    let {source, data} = this.props;
    if (data['requesting_summary'])  return this.renderLoading();

    return (
      <div>
        {this.renderSummary()}
        <button className="btn btn-default btn-sm" onClick={this.requestSummary}>Generate Summary</button>
      </div>
    )
  }
}
