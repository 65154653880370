import React, {Component} from 'react';
import Form from '../form';
import AdditionalTrials from './additional_trials';
import {getLocationLabel} from '../locations';
import Markdown from '../markdown';
import Loading from '../loading';
import request from '../../request';

export default class CancerCentersConfig extends Component {
  constructor(props) {
    super(props);
    let {patient_trial_extra} = props;
    console.log(patient_trial_extra);
    let non_primary_cancer_centers = patient_trial_extra.cancer_centers.filter(l => !l.primary);
    let primary_cancer_center = patient_trial_extra.cancer_centers.filter(l => l.primary)[0];
    this.state = {primary_cancer_center, non_primary_cancer_centers};
  }

  renderHideLocationsForm() {
    let {customizations, onChange} = this.props;
    let fields = [
      {name: 'hide_locations', type: 'bool', label: ' Do not show locations'}
    ];
    return <Form fields={fields} model={customizations} onChange={onChange} />
  }

  renderCustomizationsForm() {
    let {customizations, onChange} = this.props;
    let visible = c => c.custom_primary_center;

    let fields = [
      {name: 'custom_primary_center', type: 'bool', label: ' Set custom text for referring cancer center'},
      {name: 'primary_center_name', type: 'string', label: 'Referring Location Name', visible: visible},
      {name: 'primary_center_address', type: 'text',label: 'Address (Markdown Available)', visible: visible},
      {name: 'primary_center_notes', type: 'text',label: 'Notes', visible: visible}
    ];

    return (
      <div className="content_customize_box">
        <Form fields={fields} model={customizations} onChange={onChange} />
      </div>

    );
  }


  renderNoPrimaryCancerCenterWarning() {
    return <p className="subtle">There is no Referring Cancer Center selected. Please mark in collection.</p>;
  }

  renderCustomPreview() {
    let {customizations} = this.props;
    return (
      <div className="location_custom_preview">
        <p><b>{customizations.primary_center_name}</b></p>
        <Markdown text={customizations.primary_center_address} />
        <p className="location_custom_preview_notes">{customizations.primary_center_notes}</p>
      </div>
    )
  }

  renderGeneratedPreview() {
    let {primary_cancer_center} = this.state;
    if (!primary_cancer_center) return this.renderNoPrimaryCancerCenterWarning();

    return (
      <div>
        <p><b>{primary_cancer_center.name}</b></p>
        {primary_cancer_center.address}<br/>
      </div>
    )

  }

  renderPreviewBox() {
    let {customizations} = this.props;
    if (customizations.hide_locations) return null;

    let content = customizations.custom_primary_center ? this.renderCustomPreview() : this.renderGeneratedPreview();
    return (
      <div className="content_preview_box">
        {content}
      </div>
    )
  }

  renderPrimaryLocation() {
    let {primary_cancer_center} = this.state;
    if (!primary_cancer_center) return this.renderNoPrimaryCancerCenterWarning();

    let primary_cancer_center_url = '/admin/trials/' + primary_cancer_center.trial_id + '/cancer_centers/' + primary_cancer_center.id + '/edit';

    return (
      <div className="primary_cancer_center_holder">
        <h4>Referring Cancer Center</h4>
        <p>{primary_cancer_center.name}</p>
      </div>
    )
  }

  renderAdditionalTrials() {
    let {customizations, onChange} = this.props;
    return <AdditionalTrials customizations={customizations} onChange={onChange} />;
  }


  render() {
    return (
      <>
        <div className="row report_config_row">
          <div className="col-md-6">
            <h4 className="report_config_section_header">WHERE TO GO</h4>
            {this.renderPrimaryLocation()}
            {this.renderCustomizationsForm()}
          </div>
          <div className="col-md-5 col-md-offset-1">
            {this.renderPreviewBox()}
            {this.renderHideLocationsForm()}
          </div>
        </div>
        {this.renderAdditionalTrials()}
      </>
    )
  }
}
