import React, {Component} from 'react';
import * as Inflector from 'inflected';

export default class AbstractTable extends Component {
  constructor(props) {
    super(props);
    let {data} = props;
    this.state = {data};
  }

  static getDerivedStateFromProps(props, state) {
    let compare_function = factoryCompareFunction(state.sort_field, state.sort_desc);
    state.data = props.data.sort(compare_function);
    return state;
  }

  sort_by(field) {
    let {sort_field, sort_desc, data} = this.state;
    if (sort_field === field.name) {
      sort_desc = !sort_desc;
    } else {
      sort_field = field.name;
      sort_desc = true;
    }

    let compare_function = factoryCompareFunction(field.name, sort_desc);
    data = data.sort(compare_function);
    this.setState({data, sort_field, sort_desc});
  }

  renderHeader() {
    let {fields} = this.props;
    let {sort_field, sort_desc} = this.state;

    return (
      <thead>
        <tr>
          {fields.map(field => {
            let label = field.label || Inflector.titleize(field.name);
            let style, sort_icon;

            if (field.sortable) {
              style = "clickable sort_field";
              if (field.name === sort_field) {
                let sort_icon_type = sort_desc ? 'caret-down' : 'caret-up';
                sort_icon = <span className={"fa fa-" + sort_icon_type}></span>;
              }
            }

            return (
              <th key={field.name} className={style} onClick={e => this.sort_by(field)}>{label} {sort_icon}</th>
            );
          })}
        </tr>
      </thead>
    );
  }


  renderData() {
    let {fields} = this.props;
    let {data} = this.state;

    return (
      <tbody>
        {data.map((row, index) => {
          return (
            <tr key={row.id}>
              {fields.map(field => {
                let key = field.name + row.id;
                let content;
                if (field.callback) {
                  content = field.callback(row)
                } else {
                  content = row[field.name];
                }

                return <td key={key}>{content}</td>
              })}
            </tr>
          )})}
      </tbody>
    );
  }

  render() {
    return (
      <table className="table table-striped abstract-table">
        {this.renderHeader()}
        {this.renderData()}
      </table>
    )
  }

}


function factoryCompareFunction(field, desc) {
  return function(a, b) {
    let a_field = a[field];
    let b_field = b[field];
    if ((a_field === null || a_field === undefined) && (b_field === null || b_field === undefined)) return 0;

    if (desc) {
      if ( (a_field === null || a_field === undefined) || (a_field < b_field) ) return 1;
      if ( (a_field > b_field) || (b_field === null || b_field === undefined) ) return -1;
    } else {
      if ( (a_field === null || a_field === undefined) || (a_field < b_field) ) return -1;
      if ( (a_field > b_field) || (b_field === null || b_field === undefined) ) return 1;
    }
    return 0;
  }
}
