import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import request from '../request';
import jquery from 'jquery';

export default class TextSearch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      eligibility_criteria_needs_update: props.eligibility_criteria_needs_update,
      partialMatches: true,
      hideNonMatches: false,
      filterHideNonMatches: false,
      searchStr: '',
      eligibilityCriteria: this.props.text,
      filterLists: this.props.filterLists
    }

    this.matchesCount = 0

    // load search state from local storage
    if (localStorage.getItem('criteriaSearch')) {
      const storageState = JSON.parse(localStorage.getItem('criteriaSearch'))

      // try to mark filters checked like storage says
      storageState.selectedFiltersIds.forEach((id) => {
        let list = this.state.filterLists.find((fetchedList) => fetchedList.id === id)
        list && (list.checked = true)
      })

      this.state = Object.assign(this.state, storageState)
    }


    this.lookupInElement = this.lookupInElement.bind(this)
    this.findAndSorroundMatchingText = this.findAndSorroundMatchingText.bind(this)
    this.surroundWithSpan = this.surroundWithSpan.bind(this)
    this.searchInputChanged = this.searchInputChanged.bind(this)
    this.partialMatchesChanged = this.partialMatchesChanged.bind(this)
    this.hideNonMatchesChanged = this.hideNonMatchesChanged.bind(this)
    this.highlightMatches = this.highlightMatches.bind(this)
    this.buildSearchableHtml = this.buildSearchableHtml.bind(this)
    this.checkOffText = this.checkOffText.bind(this)
    this.submitEligibilityCriteria = this.submitEligibilityCriteria.bind(this)
    this.onSelectFilter = this.onSelectFilter.bind(this)
    this.annotateFilters = this.annotateFilters.bind(this)
    this.findAndMarkMatchingFilter = this.findAndMarkMatchingFilter.bind(this)
    this.onChangeHideNonMatchesFilter = this.onChangeHideNonMatchesFilter.bind(this)
  }


  componentDidUpdate() {
    // these are not in state as we don't want to trigger any react's lifecycle methods when changing them
    this.matchesCount = 0

    let stateToStore = JSON.parse(JSON.stringify(this.state))
    stateToStore.selectedFiltersIds = stateToStore.filterLists.
      filter((list) => !!list.checked).
      map((list) => list.id)

    // don't keep html, whole filter lists in the storage
    delete stateToStore.eligibilityCriteria
    delete stateToStore.filterLists
    delete stateToStore.eligibility_criteria_needs_update;

    localStorage.setItem('criteriaSearch', JSON.stringify(stateToStore))
  }

  componentDidMount() {
    this.buildSearchableHtml();
  }

  // traverse the html and surround matching texts using surrounderCreateFunc
  lookupInElement(el, regex, onTextFound) {
    if (/^(script|style)$/.test(el.tagName)) {
      return
    }

    let child = el.lastChild
    while (child) {
      if (child.nodeType == Node.ELEMENT_NODE) {
        this.lookupInElement(child, regex, onTextFound)
      } else if (child.nodeType == Node.TEXT_NODE) {
        onTextFound(child, regex)
      }
      child = child.previousSibling
    }
  }

  findAndSorroundMatchingText(textNode, regex) {
    let parent = textNode.parentNode
    let result, surroundingNode, matchedTextNode, matchLength, matchedText
    if (textNode && (result = regex.exec(textNode.data))) {
      this.matchesCount++
      matchedTextNode = textNode.splitText(result.index)
      matchedText = result[0]
      matchLength = matchedText.length

      textNode = matchedTextNode.length > matchLength
        ? matchedTextNode.splitText(matchLength)
        : null

      surroundingNode = this.surroundWithSpan(matchedTextNode.cloneNode(true))
      parent.insertBefore(surroundingNode, matchedTextNode)
      parent.removeChild(matchedTextNode)
      $(parent).removeClass(this.props.hasNoMatchesClass)
    } else if(!($(parent).hasClass(this.props.hasNoMatchesClass))) {
      // add a class that this node contains no matches
      $(parent).addClass(this.props.hasNoMatchesClass)
    }
  }

  findAndMarkMatchingFilter(list, textNode, regex) {
    return (textNode, regex) => {
      let parent = textNode.parentNode
      let result
      if (textNode && (result = regex.exec(textNode.data))) {
        if (list.general_requirement) {
          $(parent).addClass('general_requirement_text')
        } else {
          const filterIcon = `<span class='filter_list_icon' style='background-color: ${list.color}' title='${list.name}'/>`
          $(parent).append(filterIcon)
        }

        $(parent).removeClass(this.props.hasNoFilterMatchesClass)
      } else if(!($(parent).hasClass(this.props.hasNoFilterMatchesClass))) {
        // add a class that this node contains no matches
        $(parent).addClass(this.props.hasNoFilterMatchesClass)
      }
    }
  }

  // This function does the surrounding for every matched piece of text
  surroundWithSpan(matchedTextNode) {
    let el = document.createElement("span")
    el.className = this.props.matchClass
    el.appendChild(matchedTextNode)
    return el
  }

  highlightMatches() {
    let { searchStr, partialMatches } = this.state
    if (searchStr.length == 0) {
      return
    }

    if (!partialMatches) {
      searchStr = `\\b${searchStr}\\b`
    }

    this.lookupInElement(this.dom, new RegExp(searchStr, "gi"), this.findAndSorroundMatchingText)
  }

  annotateFilters() {
    const activeFilters = this.state.filterLists.filter((list) => list.checked)
    activeFilters.forEach((list) => {
      // escape keywords to be used in regexp
      let keywords = list.keywords.map((keyword) => keyword.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'))
      keywords = keywords.join('|')
      this.lookupInElement(this.dom, new RegExp(`\\b${keywords}\\b`, "i"), this.findAndMarkMatchingFilter(list))
    })
  }

  onChangeHideNonMatchesFilter(e) {
    const filterHideNonMatches = e.target.checked
    this.setState({ filterHideNonMatches })
  }

  partialMatchesChanged(event) {
    let partialMatches = event.target.checked
    this.setState({ partialMatches })
  }

  hideNonMatchesChanged(event) {
    let hideNonMatches = event.target.checked
    this.setState({ hideNonMatches })
  }

  searchInputChanged(event) {
    let searchStr = event.target.value
    this.setState({ searchStr })
  }

  buildSearchableHtml() {
    let { title, titleClass, briefTitle, briefTitleClass, summary, summaryClass, criteriaEligibilityTextContainerId } = this.props
    const briefTitleHtml = briefTitle && `<p class='${briefTitleClass}'><b>Brief Title</b><br/>${briefTitle}</p>`
    const titleHtml = title && `<p class='${titleClass}'><b>Official Title</b><br/>${title}</p>`
    const summaryHtml = summary && `<p class='${summaryClass}'><b>Brief Summary</b><br/>${summary}</p>`
    const criteriaEligibility = `<div id='${criteriaEligibilityTextContainerId}'>${this.state.eligibilityCriteria}</div>`

    this.dom = document.createElement('div')
    this.dom.innerHTML = `${briefTitleHtml} ${titleHtml} ${summaryHtml} ${criteriaEligibility}`
  }

  updateEligilibityCriteria() {
    let confirm_text = "Are you sure you want to fetch the latest information? If you do this, you will lose the record of any sentences that you've checked off."
    if (confirm(confirm_text) == false) return;

    request("POST", this.props.reset_eligilibity_criteria_path)
      .then(({eligibility_criteria}) => {
        this.setState({eligibilityCriteria: eligibility_criteria, eligibility_criteria_needs_update: false})
      })
  }

  resetEligibilityCriteria() {
    let confirm_text = "This will clear all the sentences that have been marked as reviewed. Are you sure you want to continue?"
    if (!confirm(confirm_text)) return;

    request("POST", this.props.reset_eligilibity_criteria_path)
      .then(({eligibility_criteria}) => {
        this.setState({eligibilityCriteria: eligibility_criteria, eligibility_criteria_needs_update: false})
      })
  }

  submitEligibilityCriteria(htmlToSend) {
    request("PUT", this.props.trial_update_path, {population: { eligibility_criteria: htmlToSend }})
      .then(({population}) => {
        this.setState({ eligibilityCriteria: population.eligibility_criteria })
      })
      .catch(e => alert('Request failed.'))
  }

  checkOffText(e) {
    e.preventDefault()
    if (this.props.read_only_mode) return;

    let target = jquery(e.target);


    let { criteriaEligibilityTextContainerId, matchClass, hasNoMatchesClass, hasNoFilterMatchesClass } = this.props
    if (target.attr('id') == criteriaEligibilityTextContainerId) {
      // clicked container of whole text
      return false;
    }

    // detect what was clicked
    let isChildOfCriteriaText = target.closest(`#${criteriaEligibilityTextContainerId}`).length
    if (!isChildOfCriteriaText) {
      // return false if title/summary/briefTitle
      return false
    }

    // surround with <del> or remove <del>
    let wrapped = target.closest('del')
    wrapped.length > 0 ? target.unwrap() : target.wrap('<del></del>')

    // send a PUT to update criteria eligibility
    let htmlToSend = target.closest(`#${criteriaEligibilityTextContainerId}`).clone()
    // unwrap matching text from highlighting spans
    htmlToSend.find(`.${matchClass}`).contents().unwrap()
    // remove filter icons
    htmlToSend.find('.filter_list_icon').remove()
    // remove css classes with not-matching keywords and filters
    htmlToSend.find(`.${hasNoMatchesClass}`).removeClass(hasNoMatchesClass)
    htmlToSend.find(`.${hasNoFilterMatchesClass}`).removeClass(hasNoFilterMatchesClass)

    this.submitEligibilityCriteria(htmlToSend.html())
  }

  onSelectFilter(filterLists) {
    this.setState({filterLists})
  }

  renderMatchesCount() {
    let { searchStr } = this.state
    if (!searchStr || searchStr.length == 0) {
      return null
    }

    return <p className="matches_count pull-right">
      {this.matchesCount} matches found
    </p>
  }

  renderSearchModifiers() {
    let { partialMatches, hideNonMatches, searchStr, eligibility_criteria_needs_update } = this.state
    let update_html;

    if (eligibility_criteria_needs_update) {
      update_html = (
        <button className="btn btn-lt btn-default" onClick={e => this.updateEligilibityCriteria()}>Updates Available - Click to Refresh</button>
      )
    }

    return (
      <div className='pull-left'>
        <input
          id="partial-check"
          checked={partialMatches}
          type="checkbox"
          onChange={this.partialMatchesChanged}
        />
        &nbsp;<label htmlFor="partial-check">Partial Matches</label>

        <input
          id="hide-non-matches"
          checked={hideNonMatches}
          type="checkbox"
          onChange={this.hideNonMatchesChanged}
        />
        &nbsp;<label htmlFor="hide-non-matches">Hide Non Matches</label>

        <button className="btn btn-default btn-sm" onClick={e => this.resetEligibilityCriteria()}>Reset</button>
        {update_html}
      </div>
    )
  }

  render() {
    this.buildSearchableHtml()
    this.annotateFilters()
    this.highlightMatches()

    let { hideNonMatches, filterHideNonMatches} = this.state
    let containerCssClass = 'text_search_component'
    hideNonMatches && (containerCssClass += ' hide_non_matches')
    filterHideNonMatches && (containerCssClass += ' hide_non_matches_filter')


    let content = this.dom.innerHTML;
    content = content.replace('Inclusion Criteria', '<strong>INCLUSION CRITERIA</strong>');
    content = content.replace('INCLUSION CRITERIA', '<strong>INCLUSION CRITERIA</strong>');
    content = content.replace('Exclusion Criteria', '<strong>EXCLUSION CRITERIA</strong>');
    content = content.replace('EXCLUSION CRITERIA', '<strong>EXCLUSION CRITERIA</strong>');

    return (
      <div className={containerCssClass}>
        <TextSearchFilterLists
          filterLists={this.state.filterLists}
          guruUrl={this.props.guruUrl}
          onSelectFilter={this.onSelectFilter}
          onChangeHideNonMatches={this.onChangeHideNonMatchesFilter}
          hideNonMatches={filterHideNonMatches}
        />

        <input id="criteria_search" defaultValue={this.state.searchStr} name='criteria_search' type="text" onChange={this.searchInputChanged} placeholder="Search" />
        <div className='clearfix'>
          {this.renderSearchModifiers()}
          {this.renderMatchesCount()}
        </div>

        <div onClick={this.checkOffText} className={this.props.textSearchContentClass} dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    )
  }
}

// TextSearch.propTypes = {
//   matchClass: React.PropTypes.string,
//   text: React.PropTypes.string, // html
//   title: React.PropTypes.string,
//   briefTitle: React.PropTypes.string,
//   summary: React.PropTypes.string,
//   trial_update_path: React.PropTypes.string,
//   filterLists: React.PropTypes.array,
//   guruUrl: React.PropTypes.string,
// }

TextSearch.defaultProps = {
  criteriaEligibilityTextContainerId: 'criteria_eligibility_text',
  matchClass: "text_search_marker",
  briefTitleClass: "text_search_brief_title",
  titleClass: "text_search_title",
  summaryClass: "text_search_summary",
  hasNoMatchesClass: 'contains_no_match',
  hasNoFilterMatchesClass: 'contains_no_filter_match',
  textSearchContentClass: 'text_search_content'
}



class TextSearchFilterLists extends React.Component {
  constructor(props) {
    super(props)
    this.state = { opened: false }

    this.renderFiltersHeader = this.renderFiltersHeader.bind(this)
    this.renderFilterListChoice = this.renderFilterListChoice.bind(this)
    this.toggleFilterList = this.toggleFilterList.bind(this)
    this.onSelectFilter = this.onSelectFilter.bind(this)
    this.renderFilterListIcon = this.renderFilterListIcon.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({ opened: false })
    }
  }

  toggleFilterList() {
    this.setState({ opened: !this.state.opened })
  }

  onSelectFilter(e, clickedListIndex) {
    let value = e.target.value
    let { filterLists } = this.props

    filterLists = filterLists.map((list, index) => {
      if (index == clickedListIndex) {
        list.checked = e.target.checked
      }
      return list
    })

    this.props.onSelectFilter(filterLists)
  }

  renderFiltersHeader() {
    const active_filters = this.props.filterLists.filter((list) => list.checked)

    return (
      <div onClick={this.toggleFilterList}>
        <span className='filter_header_text'>FILTER</span>
        <div className='active_filters_list'>
          {active_filters.map(this.renderFilterListIcon)}
        </div>
      </div>
    )
  }


  renderFilterListIcon(filterList) {
    return (
      <span
        key={filterList.id}
        className='filter_list_icon'
        style={filterList.general_requirement ? null : { backgroundColor: filterList.color }}
        title={filterList.name}
      >
        {filterList.general_requirement && 'Aa'}
      </span>
    )
  }

  renderFilterListChoice() {
    const filters = this.props.filterLists.map((list, index) => {
      return (
        <li key={`filter-${index}`}>
          <input id={`filter-${index}`} type='checkbox' checked={!!list.checked} onChange={(e) => this.onSelectFilter(e, index)} />
          <label htmlFor={`filter-${index}`}>
            {this.renderFilterListIcon(list)}
            <span className='filer_list_name'>{list.name}</span>
          </label>
        </li>
      )
    })

    return (
      <ul className='filter_list_choice'>
        {filters}
        <hr/>
        <li className='filter_lists_hide_non_matches'>
          <input id='filter-lists-hide-non-matches' type='checkbox' checked={!!this.props.hideNonMatches} onChange={this.props.onChangeHideNonMatches} />
          <label htmlFor='filter-lists-hide-non-matches'>
            <span className='filer_list_hide'>Hide Non Matches</span>
          </label>
        </li>
      </ul>
    )
  }

  render() {
    return (
      <div className='filter_lists_component'>
        <div className='filter_lists_relative_container'>
          <div className='filter_lists '>
            {this.renderFiltersHeader()}
            {this.state.opened && this.renderFilterListChoice()}
          </div>
        </div>
      </div>
    )
  }
}
