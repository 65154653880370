import React, {Component} from 'react';
import request from '../request';
import {CancerCenterSearchKey, TrialLocationSearchKey} from '../constants.js.erb';

class BaseSearchModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {text: "", searching: false}
  }

  initModal(el) {
    if (!el) return;
    this.modalEl = $(el);
    this.modalEl.modal({show: false});
  }


  showModal() {
    if (!this.modalEl) return;
    this.modalEl.modal('show');
    this.modalEl.on('hide.bs.modal', () => {
      this.setState({text: "", searching: false, results: false})
    });
  }

  closeModal() {
    if (!this.modalEl) return;
    this.modalEl.modal('hide');
  }

  renderModal() {
    let {text} = this.state;

    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref={e => this.initModal(e)}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">{this.title()}</h4>
            </div>
            <div className="modal-body">
              <form onSubmit={e => this.search(e)}>

                <div className="input-group">
                  <input autoFocus={true} type="text" className="form-control" value={text} onChange={e => this.setState({text: e.target.value})}/>
                  <span className="input-group-btn">
                    <button className="btn btn-default" type="button" onClick={e => this.search(e)}>Search</button>
                  </span>
                </div>

              </form>

              {this.renderResults()}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <button onClick={e => this.showModal()} className="btn btn-success">Search</button>

        {this.renderModal()}
      </div>
    )
  }
}



export class SearchCancerCenter extends BaseSearchModal {
  title() { return "Search Cancer Center" }

  search(e) {
    e.preventDefault();
    let {text} = this.state;
    if (!text) return;
    let url = "/admin/cancer_centers/search?q[" + CancerCenterSearchKey + "]=" + text;
    request("GET", url)
      .then(cancer_centers => this.setState({results: cancer_centers}))
  }

  createLink(center) {
    let {trial_id, location_id, onLink} = this.props;
    let url = "/admin/trials/" + trial_id + "/locations/" + location_id + "/link";
    request('PUT', url, {cancer_center_id: center.id})
      .then(({success, cancer_center}) => {
        if (success) {
          onLink && onLink(cancer_center);
        }
      });
  }



  renderResult(center) {
    let url = "/admin/cancer_centers/" + center.id;
    let actionBtn;
    let index = this.props.linked.findIndex(l => l.id === center.id);

    if (index === -1) {
      actionBtn = <button onClick={e => this.createLink(center)} className='btn btn-default btn-sm'>Link</button>
    } else {
      actionBtn = <span className="badge">LINKED</span>
    }

    return (
      <tr key={center.id}>
        <td><a href={url} target="_blank">{center.name}</a></td>
        <td>{center.state_name}</td>
        <td>{center.city}</td>
        <td>{center.zip}</td>
        <td>{actionBtn}</td>
      </tr>
    )
  }

  renderResults() {
    let {results} = this.state;
    if (!results) return null;

    return(
      <table className="table table-condensed table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>State</th>
            <th>City</th>
            <th>Zip</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {results.map(r => this.renderResult(r))}
        </tbody>
      </table>
    )
  }
}


export class SearchTrialLocation extends BaseSearchModal {
  title() { return "Search Trial Location" }

  search(e) {
    e.preventDefault();
    let {text} = this.state;
    if (!text) return;

    let url = "/admin/trial_locations/search?q[" + TrialLocationSearchKey + "]=" + text;
    request("GET", url)
      .then(locations => this.setState({results: locations}))
  }

  createLink(location) {
    let {cancer_center_id, onLink} = this.props;
    let url = "/admin/cancer_centers/" + cancer_center_id + "/link";
    request('PUT', url, {trial_location_id: location.id})
      .then(({success, trial_location}) => {
        if (success) {
          onLink && onLink(trial_location);
        }
      });
  }

  renderResult(location) {
    let url = "/admin/trials/" + location.trial_id + "/locations/" + location.id + "/edit";
    let actionBtn;
    let index = this.props.linked.findIndex(c => c.id === location.id);

    if (index === -1) {
      actionBtn = <button onClick={e => this.createLink(location)} className='btn btn-default btn-sm'>Link</button>
    } else {
      actionBtn = <span className="badge">LINKED</span>
    }


    return (
      <tr key={location.id}>
        <td><a href={url} target="_blank">{location.name}</a></td>
        <td>{location.state_name}</td>
        <td>{location.city}</td>
        <td>{location.zip}</td>
        <td>{actionBtn}</td>
      </tr>
    )
  }


  renderResults() {
    let {results} = this.state;
    if (!results) return null;

    return(
      <table className="table table-condensed table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>State</th>
            <th>City</th>
            <th>Zip</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {results.map(r => this.renderResult(r))}
        </tbody>
      </table>
    )
  }

}
