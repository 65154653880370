import React, {Component} from 'react';

const PER_PAGE = 10;
export default class CollectionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.customState();
    // let {total, pages} = calculatePages(props.collection);
    // this.state.total = total;
    // this.state.pages =  pages;
    this.state.current_page = 1;
    // this.state.collection = props.collection;
  }

  customState() {
    return {};
  }


  static getDerivedStateFromProps(props, state) {
    let {collection} = props;
    let {total, pages} = calculatePages(collection);
    state.collection = collection
    state.total = total;
    state.pages =  pages;
    return state;
  }


  prevPage() {
    let current_page = this.state.current_page - 1;
    this.setState({current_page});
  }

  nextPage() {
    let current_page = this.state.current_page + 1;
    this.setState({current_page});
  }

  selectPage(e) {
    let current_page = e.target.value;
    this.setState({current_page});
  }


  renderPagination() {
    let {current_page, pages} = this.state;
    if (pages <= 1) return null;

    let prevDisabled = current_page === 1;
    let nextDisabled = current_page === pages;
    let selectOptions = [];
    for (let i=1; i < pages + 1; i++) {
      selectOptions.push(<option key={i} value={i}>{i}</option>);
    }

    return (
      <div className="pagination row">
        <div className="col-xs-2">
          <button onClick={e => this.prevPage()}><i className="glyphicon glyphicon-chevron-left" disabled={prevDisabled}/></button>
        </div>
        <div className="col-xs-8 text-center">
          <select onChange={e => this.selectPage(e)} value={current_page}>
            {selectOptions}
          </select>
          <span>{pages} Pages</span>
        </div>
        <div className="col-xs-2">
          <button onClick={e => this.nextPage()}><i className="glyphicon glyphicon-chevron-right" disabled={nextDisabled}/></button>
        </div>
      </div>
    );
  }

  renderCollection() {
    let {collection, current_page, pages, total} = this.state;
    if (total === 0) return null;

    let start = (current_page - 1) * PER_PAGE;
    let end = Math.min(start + PER_PAGE, total - 1)
    let content = [];

    for (let i=start; i < end + 1; i++) {
      let record = collection[i]
      content.push(this.renderRow(record));
    }

    return <tbody>{content}</tbody>
  }

  renderRow(record) {
    return null;
  }

  renderTableHeader() {
    return null;
  }


  renderHeader() {
    let {title} = this.props;
    let {total} = this.state;
    return (
      <h3><b>{title}</b> ({total})</h3>
    )
  }

  render() {
    return (
      <div>
        {this.renderHeader()}
        <table className="table table-striped">
          {this.renderTableHeader()}
          {this.renderCollection()}
        </table>
        {this.renderPagination()}
      </div>
    );
  }
}


function calculatePages(collection) {
  let total = collection.length;
  if (total === 0) return {total: 0, pages: 0};

  let pages = Math.floor(total / PER_PAGE);
  if (total % PER_PAGE > 0) {
    pages = pages + 1;
  }

  return {total, pages}
}
