import React, {Component} from 'react';


/**
 * Calculate the distance between 2 points each point shound include 2
 * values, lat and lon, in decimal degrees
 * unit: The unit of measurement in which to calculate the results where:
 * 'M' is statute miles (default)
 * 'K' is kilometers
 * 'N' is nautical miles
 */
export function calculateDistance(pointA, pointB, unit='M') {
  var radlat1 = Math.PI * pointA.lat/180
  var radlat2 = Math.PI * pointB.lat/180
  var radlon1 = Math.PI * pointA.lon/180
  var radlon2 = Math.PI * pointB/180
  var theta = pointA.lon - pointB.lon
  var radtheta = Math.PI * theta/180
  var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist)
  dist = dist * 180/Math.PI
  dist = dist * 60 * 1.1515
  if (unit === 'M') return dist;
  if (unit=== 'K') return dist * 1.609344;
  if (unit=== 'N') return dist * 0.8684;
  throw("Invlid Unit: " + unit);
}


export function compareGeolocation(a, b) {
  if (a.distance === undefined && b.distance !== undefined) return 1;
  if (a.distance !== undefined && b.distance === undefined) return -1;
  if (a.distance > b.distance) return 1;
  if (a.distance < b.distance) return -1;
  return 0;
}
