import React, {Component} from 'react';
import Form from '../form';
import {getLocationLabel} from '../locations';
import AdditionalTrials from './additional_trials';
import Markdown from '../markdown';
import Loading from '../loading';
import joinReact from '../join_react';
import request from '../../request';

export default class LocationsConfig extends Component {
  constructor(props) {
    super(props);
    let {patient_trial_extra} = props;
    let non_primary_locations = patient_trial_extra.locations.filter(l => !l.primary);
    let primary_location = patient_trial_extra.locations.filter(l => l.primary)[0];
    this.state = {primary_location, non_primary_locations};
  }

  renderHideLocationsForm() {
    let {customizations, onChange} = this.props;
    let fields = [
      {name: 'hide_locations', type: 'bool', label: ' Do not show locations'}
    ];
    return <Form fields={fields} model={customizations} onChange={onChange} />
  }

  renderCustomizationsForm() {
    let {customizations, onChange} = this.props;
    let visible = c => c.custom_primary_center;

    let fields = [
      {name: 'custom_primary_center', type: 'bool', label: ' Set custom text for referring location'},
      {name: 'primary_center_name', type: 'string', label: 'Referring Location Name', visible: visible},
      {name: 'primary_center_address', type: 'text',label: 'Address (Markdown Available)', visible: visible},
      {name: 'primary_center_notes', type: 'text',label: 'Notes', visible: visible}
    ];

    return (
      <div className="content_customize_box">
        <Form fields={fields} model={customizations} onChange={onChange} />
      </div>

    );
  }


  renderNoPrimaryLocationWarning() {
    return <p className="subtle">There is no Referring Location selected. Please mark in collection.</p>;
  }

  renderNoPrimaryCancerCenterWarning() {
    return <p className="subtle">There is no cancer center assigned to the Referring Location.</p>;
  }

  renderCustomPreview() {
    let {customizations} = this.props;
    return (
      <div className="location_custom_preview">
        <p><b>{customizations.primary_center_name}</b></p>
        <Markdown text={customizations.primary_center_address} />
        <p className="location_custom_preview_notes">{customizations.primary_center_notes}</p>
      </div>
    )
  }

  renderGeneratedPreview() {
    let {primary_location} = this.state;
    if (!primary_location) return this.renderNoPrimaryLocationWarning();
    if (!primary_location.cancer_centers || primary_location.cancer_centers.length === 0) return this.renderNoPrimaryCancerCenterWarning();

    let cancer_center = primary_location.cancer_centers[0];
    let center_details = [cancer_center.clinical_trial_phone, cancer_center.clinical_trial_email, cancer_center.clinical_trial_url].filter(s => s && s.length > 0);

    return (
      <div>
        <p><b>{cancer_center.name}</b></p>
        {cancer_center.address}<br/>
      </div>
    )

  }

  renderPreviewBox() {
    let {customizations} = this.props;
    if (customizations.hide_locations) return null;

    let content = customizations.custom_primary_center ? this.renderCustomPreview() : this.renderGeneratedPreview();
    return (
      <div className="content_preview_box">
        {content}
      </div>
    )
  }

  renderLocations() {
    let {non_primary_locations} = this.state;
    if (non_primary_locations.length < 1) return null;
    return (
      <div className="relevant_locations_holder">
        <h4>Other Relevant Locations</h4>

        <ul>
          {non_primary_locations.map(l => {
            return (
              <li className="gen_location_listing" key={l.id}>{getLocationLabel(l)}</li>
            )
          })}
        </ul>
      </div>
    )
  }

  renderPrimaryLocation() {
    let {primary_location} = this.state;
    if (!primary_location) return this.renderNoPrimaryLocationWarning();
    let verified_content, unlinked_message;
    let primary_location_url = '/admin/trials/' + primary_location.trial_id + '/locations/' + primary_location.id + '/edit';

    if (primary_location.verified && primary_location.verified_notes && primary_location.verified_notes.length > 0 ) {
      verified_content = (
        <>
          <h4><span className="subtle">VERIFICATION STATUS:</span> {primary_location.slot_availability}</h4>
          <p>{primary_location.verified_notes}</p>
        </>
      )
    }

    if (primary_location.cancer_centers.length < 1) {
      unlinked_message = <p>There is no linked cancer center for <a href={primary_location_url} target='_blank'>this trial location</a>.</p>
    }

    return (
      <div className="primary_location_holder">
        <h4>Referring Location</h4>
        <p>{getLocationLabel(primary_location)}</p>
        {verified_content}

        <h4>Cancer Center linked to referring location</h4>
          {unlinked_message}
          {primary_location.cancer_centers.map(cancer_center => {
            let link = `/admin/cancer_centers/${cancer_center.id}`;
            let center_details = [cancer_center.clinical_trial_phone, cancer_center.clinical_trial_email, cancer_center.clinical_trial_url].filter(s => s && s.length > 0);
            return (
              <div key={cancer_center.id}>
               <p><a href={link} target="_blank">{cancer_center.name}</a></p>
               <span className="subtle">{cancer_center.address}<br/>
               {joinReact(center_details, <br/>)}</span>
              </div>
            )
          })}
      </div>
    )
  }

  renderAdditionalTrials() {
    let {customizations, onChange} = this.props;
    return <AdditionalTrials customizations={customizations} onChange={onChange} />;
  }

  render() {
    return (
      <>
        <div className="row report_config_row">
          <div className="col-md-6">
            <h4 className="report_config_section_header">WHERE TO GO</h4>
            {this.renderPrimaryLocation()}
            {this.renderLocations()}
            {this.renderCustomizationsForm()}
          </div>
          <div className="col-md-5 col-md-offset-1">
            {this.renderPreviewBox()}
            {this.renderHideLocationsForm()}
          </div>
        </div>
        {this.renderAdditionalTrials()}
      </>
    )
  }
}
