import React, {Component} from 'react';
import AbstractTable from './abstract_table';

export default class EmbedTrials extends Component {
  constructor(props) {
    super(props)
    let {data} = props;
    this.state = {data, hide_archived: false}
  }


  toggleActive(e) {
    let hide_archived = e.target.checked;

    let {data} = this.props;
    if (hide_archived) {
      data = data.filter(row => row.status !== 'archived')
    }
    this.setState({hide_archived, data});
  }

  render() {
    let {data, hide_archived} = this.state;
    return (
      <div>
        <label>
          Hide Archived <input type="checkbox" checked={hide_archived} value={hide_archived} onChange={e => this.toggleActive(e)} />

        </label>
        <p>{data.length} Trials Found</p>
        <AbstractTable fields={FIELDS} data={data} />
      </div>
    );
  }
}

const FIELDS = [
  {name: 'status', callback: renderStatus},
  {name: 'populations_count', sortable: true},
  {name: 'references_count', sortable: true},
  {name: 'identifier', callback: renderIdentifier, sortable: true},
  {name: 'name', sortable: true},
  {name: 'therapy_type_name', label: 'Therapy Type', sortable: true}
];


function renderIdentifier(row) {
  let link = `/admin/trials/${row.id}/edit`;
  return <a href={link}>{row.identifier}</a>
}

function renderStatus(row) {
  return(
    <span>
      {row.status_humanized}<br />{row.curator_label}
    </span>
  )
}
