import React, {Component} from 'react';
import {PopulationTraits, CancerCategorySearchKey, CancerTypeSearchKey} from '../../constants.js.erb';
import Loading from '../loading';
import Select2 from '../select2';
import request from '../../request';
import NotesWidget from './notes_widget';
import SavePanel from './save_panel';

export default class CancerTypes extends Component {
  constructor(props) {
    super(props);
    let {population} = props;
    let data = this.setupDefaults(population.data);
    this.state = {data};

    this.submit = this.submit.bind(this);
    this.changeData = this.changeData.bind(this);
  }

  setupDefaults(data) {
    let {cancer_type} = data;
    if (!cancer_type.tumor_type) cancer_type.tumor_type = {}
    if (!cancer_type.tumor_type.solid) cancer_type.tumor_type.solid = {}
    if (!cancer_type.tumor_type.liquid) cancer_type.tumor_type.liquid = {}
    if (!cancer_type.cancer_category) cancer_type.cancer_category = {};
    if (!cancer_type.cancer_types) cancer_type.cancer_types = {};
    if (!cancer_type.notes) cancer_type.notes = [];

    return data;
  }

  componentDidMount() {
    window.addEventListener('population-save-request', this.submit, false);
  }

  componentWillUnmount() {
    window.removeEventListener('population-save-event', this.submit, false);
  }

  submit() {
    let {onChange} = this.props;
    let {data} = this.state;
    onChange(data);
  }

  changeData(content, skip_submit=false) {
    let {data} = this.state;
    data.cancer_type = content;
    let callback = skip_submit ? undefined : this.submit;
    this.setState({data}, callback);
  }

  changeType(e) {
    let {data} = this.state;
    let {value} = e.target;
    data.cancer_type.type = value;
    data.cancer_type.tumor_type_id = undefined;
    data.cancer_type.cancer_types = {};
    this.setState({data});
  }


  renderSavePanel() {
    let icon, text, style, action;
    let {submitting, save_success, save_error, save_errors} = this.state;
    return <SavePanel submitting={submitting} save_success={save_success} save_error={save_error} onClick={e => this.submit()} errors={save_errors} />
  }

  renderDetails() {
    let {cancer_type} = this.state.data;
    if (cancer_type.type === 'tumor_type') return <TumorTypeEditor data={cancer_type} onChange={this.changeData} />
    if (cancer_type.type === 'cancer_category') return <CancerCategoryEditor data={cancer_type} onChange={this.changeData} />
    if (cancer_type.type === 'cancer_type') return <CancerTypeEditor data={cancer_type} onChange={this.changeData} />
    return null;
  }


  renderCancerTypeOptions() {
    let {cancer_type} = this.state.data;
    if (cancer_type.type !== 'cancer_type') return null;
    return <CancerTypeOptions data={cancer_type} onChange={this.changeData} />
  }


  render() {
    let {population} = this.props;
    let {cancer_type} = this.state.data;

    return (
      <div>
        <Suggestions population={population} data={cancer_type} onChange={this.changeData} />
        <div className="well label-spacing select-100">
          <div className="row">
            <div className="col-md-6">
              <h3 className="main-area-header"><b>Tumor Type</b></h3>
                <div className="search-group-holder">
                  <bs-field label="Tumor Type">
                    <div className="radio">
                      <label>
                        <input type="radio" value="tumor_type" checked={cancer_type.type === 'tumor_type'} onChange={e => this.changeType(e)} />
                        ADD ALL SOLID / LIQUID TUMORS CATEGORY
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input type="radio" value="cancer_category" checked={cancer_type.type === 'cancer_category'} onChange={e => this.changeType(e)} />
                        ADD MAJOR CANCER CATEGORY
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input type="radio" value="cancer_type" checked={cancer_type.type === 'cancer_type'} onChange={e => this.changeType(e)} />
                        ADD INDIVIDUAL CANCER TYPES
                      </label>
                    </div>
                  </bs-field>
                </div>

              {this.renderCancerTypeOptions()}
            </div>



            <div className="col-md-6">
              {this.renderDetails()}
            </div>
          </div>
          <div className="row">
              <div className="col-md-8">
                <NotesWidget data={cancer_type} onChange={d => this.changeData(d)} />
              </div>
          </div>

        </div>
      </div>
    );
  }
}

const SUGGESTION_LABELS = {
  tumor_type: 'Tumor Types',
  cancer_category: 'Cancer Categories',
  cancer_type: 'Cancer Types'
};

class Suggestions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(props, state) {
    let {suggestions} = state;
    if (!suggestions) return state;;

    let {data} = props;
    state.suggestions_data = Suggestions.extractSuggestions(suggestions, data);
    return state;
  }

  componentDidMount() {
    this.fetchSuggestions().then(({suggestions}) => {
      let {data} = this.props;
      let suggestions_data = Suggestions.extractSuggestions(suggestions, data)
      this.setState({suggestions, suggestions_data});
    });
  }

  fetchSuggestions() {
    let {population} = this.props;
    let url = `/admin/trials/${population.trial_id}/populations/${population.id}/cancer_types`;
    return request('GET', url);
  }

  static extractSuggestions(suggestions, data) {
    if (!suggestions) return false;

    let buffer = []

    if (!data.type || data.type === 'tumor_type') {
      let items = Suggestions.extractTumorTypesSuggestions(suggestions, data);
      if (items && items.length > 0) buffer.push({items, type: 'tumor_type'})
    }

    if (!data.type || data.type === 'cancer_category') {
      let items = Suggestions.extractCancerCategoriesSuggestions(suggestions, data);
      if (items && items.length > 0) buffer.push({items, type: 'cancer_category'})
    }

    if (!data.type || data.type === 'cancer_type') {
      let items = Suggestions.extractCancerTypesSuggestions(suggestions, data);
      if (items && items.length > 0) buffer.push({items, type: 'cancer_type'})
    }

    return buffer;
  }

  static extractCancerCategoriesSuggestions(suggestions, data) {
    if (!suggestions.cancer_categories || suggestions.cancer_categories.length === 0) return null;

    let cancer_categories = suggestions.cancer_categories.filter(s => s.id !== +data.cancer_category_id);
    return cancer_categories;
  }

  static extractCancerTypesSuggestions(suggestions, data) {
    if (!suggestions.cancer_types || suggestions.cancer_types.length === 0) return null;
    let cancer_types = suggestions.cancer_types.filter(s => data.cancer_types[s.id] === undefined );
    return cancer_types;
  }


  static extractTumorTypesSuggestions(suggestions, data) {
    if (!suggestions.tumor_types || suggestions.tumor_types.length === 0) return null;
    let tumor_types = suggestions.tumor_types.filter(s => s.id !== data.tumor_type_id)
    return tumor_types;
  }

  showSuggestions() {
    let {data, onChange} = this.props;
    data.hide_suggestions = false;
    onChange(data, true);
  }

  hideSuggestions() {
    let {data, onChange} = this.props;
    data.hide_suggestions = true;
    onChange(data, true);
  }

  selectSuggestion(type, suggestion) {
    let {data, onChange} = this.props;

    if (!data.type) data.type = type;

    if (type === 'tumor_type') {
      data.tumor_type_id = suggestion.id;
    }

    else if (type === 'cancer_category') {
      data.cancer_category_id = suggestion.id;
      data.cancer_category = {options: {}};
      data.tumor_type_id = suggestion.tumor_type_id;
    }

    else if (type === 'cancer_type') {
      data.cancer_types[suggestion.id] = {options: {}, cancer_type_id: suggestion.id}
      data.tumor_type_id = suggestion.tumor_type_id;

      // Send the event to preload details
      let event = new CustomEvent('cancer_type_added', {detail: {ids: [suggestion.id]}});
      window.dispatchEvent(event);
    }

    onChange(data);
  }


  renderOpenSuggestionsLink() {
    return (
      <div>
        <button className="btn btn-link" onClick={e => this.showSuggestions()}>Show Suggestions</button>
      </div>
    );
  }

  renderSuggestions(suggestions_data) {
    return (
      <div className="suggestions_holder">
        {suggestions_data.map(suggestion => {
          let {items, type} = suggestion;
          let label = SUGGESTION_LABELS[type];

          return (
            <div className="suggestion_type_holder" key={type}>
              <h5>{label}</h5>
              <ul>
                {items.map( i => {
                  let added_to;
                  if (i.populations && i.populations.length > 0) {
                    let population_names = i.populations.map(p => p.name).join(', ');
                    added_to = <p className="help"><span>In population(s):</span> {population_names}</p>;
                  }
                  return (
                    <li key={i.id}>
                      <button className="btn btn-default btn-sm" onClick={e => this.selectSuggestion(type, i)}>{i.name}</button>
                      {added_to}
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </div>
    );
  }

  render() {
    let {data} = this.props;
    let {suggestions_data} = this.state;
    if (!suggestions_data || suggestions_data.length === 0) return null;
    if (data.hide_suggestions) return this.renderOpenSuggestionsLink();

    return (
      <div id="suggested_cancer_options">
        <button className="btn btn-sm pull-right" onClick={e => this.hideSuggestions()}><span className="fa fa-xmark"></span></button>
        <h3>Suggestions <span>Click any to add, will adjust when items are added</span></h3>
        {this.renderSuggestions(suggestions_data)}
      </div>
    );
  }
}



class TumorTypeOptions extends Component {

  changeOptionCheckbox(type, section, key, value) {
    let {data, onChange} = this.props;
    if (!data.tumor_type[type][section]) data.tumor_type[type][section] = {};
    data.tumor_type[type][section][key] = value;
    onChange(data);
  }

  changeOptionRadio(type, section, key) {
    let {data, onChange} = this.props;
    if (!data.tumor_type[type][section]) data.tumor_type[type][section] = {};
    data.tumor_type[type][section] = key;
    onChange(data);
  }

  resetRadio(e, type, section) {
    e.preventDefault();
    let {data, onChange} = this.props;
    if (!data.tumor_type[type][section]) data.tumor_type[type][section] = {};
    delete(data.tumor_type[type][section])
    onChange(data);
  }

  renderOptionsRadio(type, section, key, label) {
    let {data} = this.props;
    let value = data.tumor_type[type][section] === key
    return (
      <div className="radio">
        <label>
          <input type="radio" value={value} checked={value} onChange={e => this.changeOptionRadio(type, section, key)} />
          {label}
        </label>
      </div>
    );
  }


  renderOptionsCheckbox(type, section, key, label) {
    let {data} = this.props;
    let value = data.tumor_type[type][section] && data.tumor_type[type][section][key] || false;
    return (
      <div className="checkbox">
        <label>
          <input type="checkbox" value={value} checked={value} onChange={e => this.changeOptionCheckbox(type, section, key, !value)} />
          {label}
        </label>
      </div>
    );
  }

  renderOptions(type, section, field) {
    return Object.keys(field.options).map(key => {
      let id = section + "_" + key
      let label = field.options[key].label || field.options[key];
      let content;
      if (field.multiple) {
        content = this.renderOptionsCheckbox(type, section, key, label)
      } else {
        content = this.renderOptionsRadio(type, section, key, label)
      }
      return <div key={id}>{content}</div>
    });
  }

  render() {
    let {data} = this.props;
    let type, fields, header;
    if (!data.tumor_type_id) return null;

    if (data.tumor_type_id === 'S') {
      type = 'solid';
      header = 'Solid Tumors'
      fields = PopulationTraits.cancer_type.solid;
    } else {
      type = 'liquid';
      header = 'Liquid Tumors'
      fields = PopulationTraits.cancer_type.liquid;
    }

    return (
      <div className="search-group-holder">
        <h3>{header}</h3>
        {Object.keys(fields).map(key => {
          let field = fields[key];
          let reset;
          if (!field.multiple) {
            reset = <button className="btn btn-link" onClick={e => this.resetRadio(e, type, key)}>Reset</button>
          }
          return (
            <div key={key} className="trait-group">
              <label className="control-label">{field.title}</label>
              <div className="trait-notes">{field.notes}</div>
              {this.renderOptions(type, key, field)}
              {reset}
            </div>
          )
        })}
      </div>
    );
  }

}

class TumorTypeEditor extends Component {
  change(e) {
    let {name, value} = e.target;
    let {data, onChange} = this.props;
    data[name] = value;
    onChange(data);
  }

  changeTumorType(tumor_type_id) {
    let {data, onChange} = this.props;
    data.tumor_type_id = tumor_type_id;
    onChange(data);
  }

  renderButtons() {
    let {data} = this.props;
    let solidClassName, liquidClassName;

    if (data.tumor_type_id === 'S') {
      solidClassName = 'btn btn-primary';
      liquidClassName = 'btn btn-default';
    } else if (data.tumor_type_id === 'L'){
      solidClassName = 'btn btn-default';
      liquidClassName = 'btn btn-primary';
    } else {
      solidClassName = 'btn btn-default';
      liquidClassName = 'btn btn-default';
    }

    return (
      <div>
        <div><label>Tumor Type</label></div>
        <div className="btn-group">
          <button className={solidClassName} onClick={e => this.changeTumorType('S')}>Solid Tumors</button>
          <button className={liquidClassName} onClick={e => this.changeTumorType('L')}>Liquid Tumors</button>
        </div>
      </div>
    );
  }


  renderExclussions() {
    let {data} = this.props;
    if (!data.tumor_type_id) return null;


    return (
      <div id="exclusions" className="search-group-holder">
        <h3>Exclusions</h3>

        <div className="form-group">
          <label className="control-label">Entire Cancer Categories</label>
          <Select2 name="excluded_cancer_categories" multiple={true} remote={true} value={data.excluded_cancer_categories} src="/admin/cancer_categories/as_options.json" searchField={CancerCategorySearchKey} filters={{tumor_type: data.tumor_type_id}} onChange={e => this.change(e)} />
        </div>


        <div className="form-group">
          <label className="control-label">Cancer Types</label>
          <Select2 name="excluded_cancer_types" multiple={true} remote={true} value={data.excluded_cancer_types} src="/admin/cancer_types/as_options.json" searchField={CancerTypeSearchKey} filters={{tumor_type: data.tumor_type_id}} onChange={e => this.change(e)} />
        </div>
      </div>

    );
  }

  render() {
    let {data, onChange} = this.props;
    return (
      <div>

        {this.renderButtons()}

        <form role="form">
          <TumorTypeOptions data={data} onChange={onChange} />

          {this.renderExclussions()}
        </form>

      </div>
    );
  }
}


class Traits extends Component {
 changeOptionCheckbox(trait, option, value) {
    let {options, onChange} = this.props;
    if (!options) options = {}
    if (!options[trait.id]) options[trait.id] = {}
    if (value) {
      options[trait.id][option.id] = value;
    } else {
      delete(options[trait.id][option.id]);
    }
    onChange(options);
  }

  changeOptionRadio(trait, option) {
    let {options, onChange} = this.props;
    if (!options) options = {}
    options[trait.id] = {[option.id]: true};
    onChange(options);
  }

  resetRadio(e, trait) {
    let {options, onChange} = this.props;
    if (!options) options = {}
    options[trait.id] = {};
    onChange(options);
  }

  renderTraitRadio(trait) {
    let {options} = this.props;

    return (
      <div key={trait.id} className="trait-group">
        <label className="control-label">{trait.name}</label>
        {trait.options.map(option => {
          let value = options && options[trait.id] && options[trait.id][option.id] || false;
          return (
            <div className="radio" key={option.id}>
              <label>
                <input type="radio" value={value} checked={value} onChange={e => this.changeOptionRadio(trait, option, !value)} />
                {option.text}
              </label>
            </div>
          );
        })}
        <button className="btn btn-link" onClick={e => this.resetRadio(e, trait)}>Reset</button>
      </div>
    );

  }

  renderTraitCheckbox(trait) {
    let {options} = this.props;

    return (
      <div key={trait.id} className="trait-group">
        <label className="control-label">{trait.name}</label>
        {trait.options.map(option => {
          let value = options && options[trait.id] && options[trait.id][option.id] || false;
          return (
            <div className="checkbox" key={option.id}>
              <label>
                <input type="checkbox" value={value} checked={value} onChange={e => this.changeOptionCheckbox(trait, option, !value)} />
                {option.text}
              </label>
            </div>
          );
        })}
      </div>
    );

  }

  render() {
    let {traits} = this.props;
    return (
      <div>
        {traits.map(trait => {
          if (trait.multiple) {
            return this.renderTraitCheckbox(trait);
          } else {
            return this.renderTraitRadio(trait);
          }
        })}
      </div>
    );
  }
}

class CancerCategoryEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let {data} = this.props;
    if (data.cancer_category_id) {
      this.fetchDetails(data.cancer_category_id);
    }
  }

  change(e) {
    let {name, value} = e.target;
    let {data, onChange} = this.props;
    data[name] = value;
    onChange(data);
  }

  changeCategory(e) {
    let cancer_category_id = e.target.value;
    this.fetchDetails(cancer_category_id);
  }

  changeTraitsOptions(options) {
    let {data, onChange} = this.props;
    data.cancer_category.options = options;
    onChange(data);
  }

  fetchDetails(cancer_category_id) {
    this.setState({loading: true});
    let url = '/admin/cancer_categories/' + cancer_category_id;
    request('GET', url).then(cancer_category => {
      if (cancer_category.cancer_types_count === 1) {
        window.alert("This cancer category only has one cancer type. Please, use this cancer type instead");
        this.setState({loading: false});
        return;
      }

      this.setState({cancer_category, loading: false});
      let {data, onChange} = this.props;
      data.cancer_category_id = cancer_category_id;
      if (data.tumor_type_id !== cancer_category.tumor_type) {
        data.tumor_type_id = cancer_category.tumor_type;
      }
      onChange(data);
    });
  }

  renderTraits() {
    let {cancer_category, loading} = this.state;
    if (loading) return <Loading />
    if (!cancer_category) return null;
    let {data} = this.props;

    return (
      <div className="search-group-holder">
        <h3>{cancer_category.name} Details</h3>
        <Traits traits={cancer_category.traits} options={data.cancer_category.options} onChange={o => this.changeTraitsOptions(o)} />
      </div>
    );
  }

  render() {
    let {data, onChange} = this.props;

    return (
      <div>
        <div className="form-group">
          <label className="control-label">Cancer Category</label>
          <Select2 remote={true} value={data.cancer_category_id} src="/admin/cancer_categories/as_options.json" searchField={CancerCategorySearchKey} onChange={e => this.changeCategory(e)} />
        </div>

        {this.renderTraits()}
        <TumorTypeOptions data={data} onChange={onChange} />

        <div id="exclusions" className="search-group-holder">
          <h3>Exclusions</h3>

          <div className="form-group">
            <label className="control-label">Cancer Types</label>
            <Select2 name="excluded_cancer_types" multiple={true} remote={true} value={data.excluded_cancer_types} src="/admin/cancer_types/as_options.json" searchField={CancerTypeSearchKey} onChange={e => this.change(e)} filters={{cancer_category_id: data.cancer_category_id}}/>
          </div>
        </div>

      </div>
    );
  }
}




class CancerTypeOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {source: 'by_name', name: '', tags: []};
  }

  changeSource(source) {
    this.setState({source, name: '', cancer_category_id: '', cancer_category: undefined, tags: []});
  }

  changeName(e) {
    let {value} = e.target;
    this.setState({name: value});
  }

  changeTumorType(tumor_type_id) {
    let {data, onChange} = this.props;
    data.tumor_type_id = tumor_type_id;
    this.setState({cancer_category_id: '', cancer_category: undefined, tags: []});
    onChange(data, true);
  }

  changeCancerCategory(e) {
    let cancer_category_id = e.target.value;
    this.setState({cancer_category_id, cancer_category: undefined, tags: []});
    this.fetchCancerCategoryDetails(cancer_category_id);
  }

  changeTag(tag_id, value) {
    let {tags} = this.state;
    if (value) {
      tags.push(tag_id);
    } else {
      let index = tags.indexOf(tag_id);
      tags.splice(index, 1);
    }
    this.setState({tags});
  }

  fetchCancerCategoryDetails(cancer_category_id) {
    if (this.state.cancer_category && this.state.cancer_category.id === cancer_category_id) return;
    this.setState({loading_cancer_category: true, tags: []});

    let url = '/admin/cancer_categories/' + cancer_category_id;
    request('GET', url).then(cancer_category => {
      this.setState({cancer_category, loading_cancer_category: false});
    });
  }

  fetchByName() {
    this.setState({loading: true});
    let {name} = this.state;
    let {data} = this.props;
    let url = '/admin/cancer_types/as_options?extra_filters[name]=' + name + '&extra_filters[tumor_type]=' + data.tumor_type_id
    request('GET', url).then(({results}) => {
      this.setState({cancer_types: results, loading: false});
    });
  }

  fetchByCategory() {
    this.setState({loading: true});
    let {cancer_category_id, tags} = this.state;
    let url = '/admin/cancer_types/as_options?extra_filters[cancer_category_id]=' + cancer_category_id;

    if (tags && tags.length > 0) {
      url += '&extra_filters[tags]=' + tags.join(',');
    }

    request('GET', url).then(({results}) => {
      this.setState({cancer_types: results, loading: false});
    });
  }

  changeSelection(index, value) {
    let {cancer_types} = this.state;
    cancer_types[index]._selected = value;
    this.setState({cancer_types});
  }

  selectAll() {
    let {data, onChange} = this.props;
    let {cancer_types} = this.state;
    for (let cancer_type of cancer_types) {
      cancer_type._selected = true;
    }
    this.setState({cancer_types});
  }

  unselectAll() {
    let {cancer_types} = this.state;
    for (let cancer_type of cancer_types) {
      cancer_type._selected = false;
    }
    this.setState({cancer_types});
  }

  addSelected() {
    let {data, onChange} = this.props;
    let {cancer_types} = this.state;
    let ids = [];
    for (let cancer_type of cancer_types) {
      if (cancer_type._selected && !data.cancer_types[cancer_type.id]) {
        data.cancer_types[cancer_type.id] = {cancer_type_id: cancer_type, options: {}};
        ids.push(cancer_type.id);
      }
    }

    onChange(data);

    let event = new CustomEvent('cancer_type_added', {detail: {ids}});
    window.dispatchEvent(event);
  }

  addCancerType(cancer_type) {
    let {data, onChange} = this.props;
    if (data.cancer_types[cancer_type.id]) return;
    data.cancer_types[cancer_type.id] = {cancer_type_id: cancer_type, options: {}};
    onChange(data);

    let event = new CustomEvent('cancer_type_added', {detail: {ids: [cancer_type.id]}});
    window.dispatchEvent(event);
  }

  renderSourceSelector() {
    let {source} = this.state;
    let {data} = this.props;
    if (!data.tumor_type_id) return <p>Select tumor type</p>

    return (
      <div>
        <div className="radio">
          <label>
            <input type="radio" value={source === 'by_name'} checked={source === 'by_name'} onChange={e => this.changeSource('by_name')} />
            Search by Name
          </label>
        </div>
        <div className="radio">
            <label>
              <input type="radio" value={source === 'by_category'} checked={source === 'by_category'} onChange={e => this.changeSource('by_category')} />
              Search by Category
            </label>
        </div>
      </div>
    );
  }

  renderButtons() {
    let {data} = this.props;
    let disabled = !data.cancer_types || Object.keys(data.cancer_types).length > 0;
    let solidClassName, liquidClassName;

    if (data.tumor_type_id === 'S') {
      solidClassName = 'btn btn-primary';
      liquidClassName = 'btn btn-default';
    } else if (data.tumor_type_id === 'L'){
      solidClassName = 'btn btn-default';
      liquidClassName = 'btn btn-primary';
    } else {
      solidClassName = 'btn btn-default';
      liquidClassName = 'btn btn-default';
    }

    return (
      <div>
        <div><label>Tumor Type</label></div>
        <div className="btn-group">
          <button disabled={disabled} className={solidClassName} onClick={e => this.changeTumorType('S')}>Solid Tumors</button>
          <button disabled={disabled} className={liquidClassName} onClick={e => this.changeTumorType('L')}>Liquid Tumors</button>
        </div>
      </div>
    );
  }


  renderCategorySelector() {
    let {data} = this.props;
    let {cancer_category_id} = this.state;

    return (
      <div>
        <label className="control-label">Cancer Category</label>
        <Select2 remote={true} value={cancer_category_id} src="/admin/cancer_categories/as_options.json" searchField={CancerCategorySearchKey} filters={{tumor_type: data.tumor_type_id}} onChange={e => this.changeCancerCategory(e)} />

      </div>
    )
  }


  renderCategoryFilter() {
    let {cancer_category, loading_category, tags} = this.state;
    if (loading_category) return <Loading />
    if (!cancer_category) return null;

    return (
      <div>
        <label className="control-label">Filter by tags</label>

        {cancer_category.tags.map(tag => {
          let value = tags.indexOf(tag.id) !== -1;
          return (
            <div className="checkbox" key={tag.id}>
              <label>
                <input type="checkbox" value={value} checked={value} onChange={e => this.changeTag(tag.id, !value)}/>
                {tag.text}
              </label>
            </div>
          );
        })}
        <hr/>
      </div>
    );
  }

  renderResults() {
    let {cancer_types, loading} = this.state;
    if (loading) return <Loading />
    if (!cancer_types) return null;
    let {data} = this.props;

    return (
      <div id="results">
        <h3 className="main-area-header"><b>Results</b></h3>
        <div>
          <div className="btn-group">
            <button className="btn btn-default btn-sm" onClick={e => this.selectAll()}>Select All</button>
            <button className="btn btn-default btn-sm" onClick={e => this.unselectAll()}>Unselect All</button>
          </div>
          <button className="btn btn-success btn-sm pull-right" onClick={e => this.addSelected()}>Add Selected</button>
        </div>
        <table className="table results-table">
          <tbody>
            {cancer_types.map((cancer_type, index) => {
              let action, directAction, aliases;
              let value = cancer_type._selected || false;
              if (data.cancer_types[cancer_type.id]) {
                directAction = <span className="pull-right"><b>Added</b></span>
              } else {
                action = <input type="checkbox" value={value} checked={value} onChange={e => this.changeSelection(index, !value)} />
                directAction = <button className="btn btn-success btn-sm pull-right" onClick={e => this.addCancerType(cancer_type)}>Add</button>
              }

              if (cancer_type.aliases) {
                aliases = <span className="type_aliases">Aliases: <i>{cancer_type.aliases}</i></span>
              }
              return (
                <tr key={cancer_type.id}>
                <td>{action}</td>
                  <td>
                    <p className="type_result_text"><strong>{cancer_type.name}</strong><br/>
                    {aliases}<br/>
                    <span className="subtle">{cancer_type.description}</span></p>
                  </td>
                  <td>
                    {directAction}
                  </td>
                </tr>
              );
            })}

          </tbody>
        </table>
      </div>
    );
  }

  renderSearchByName() {
    let {data} = this.props;
    if (!data.tumor_type_id) return null;

    let {source, name} = this.state;
    if (source !== 'by_name') return null;
    return (
      <div>
        <div className="form-group">
          <label>Name</label>
          <input type="text" className="form-control" value={name} onChange={e => this.changeName(e)} />
        </div>

        <button className="btn btn-success" onClick={e => this.fetchByName()}>Search</button>

      </div>
    );
  }

  renderSearchByCategory() {
    let {data} = this.props;
    if (!data.tumor_type_id) return null;

    let {source} = this.state;
    if (source !== 'by_category') return null;


    return (
      <div>
        <div className="form-group">
          {this.renderCategorySelector()}
        </div>
        {this.renderCategoryFilter()}

        <button className="btn btn-success" onClick={e => this.fetchByCategory()}>Search</button>
      </div>
    );
  }

  render() {
    let result = {};

    return (
      <div>
        <div className="search-group-holder">
          {this.renderButtons()}
          <hr/>
          {this.renderSourceSelector()}
          <hr/>
          {this.renderSearchByName()}
          {this.renderSearchByCategory()}

        </div>

        {this.renderResults()}

      </div>
    );
  }
}



class CancerTypeEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    let ids = Object.keys(props.data.cancer_types);
    if (ids.length > 0) {
      this.preloadCancerTypeData(ids);
    }

    this.cancerTypeAddedCallback = this.cancerTypeAddedCallback.bind(this);
  }

  componentDidMount() {
    window.addEventListener('cancer_type_added', this.cancerTypeAddedCallback, false);
  }

  componentWillUnmount() {
    window.removeEventListener('cancer_type_added', this.cancerTypeAddedCallback);
  }

  collapseAll() {
    window.dispatchEvent(new Event('collapse_all_cancer_types'));
  }

  expandAll() {
    window.dispatchEvent(new Event('expand_all_cancer_types'));
  }

  cancerTypeAddedCallback(e) {
    let {ids} = e.detail;
    if (ids.length > 0) {
      this.preloadCancerTypeData(ids);
    }
  }

  preloadCancerTypeData(ids) {
    let url = '/admin/cancer_types/all?ids=' + ids.join(',');
    request('GET', url).then(cancer_types => {
      for (let cancer_type of cancer_types) {
        let key = 'cache_' + cancer_type.id;
        this.setState({[key]: cancer_type});
      }
    });
  }

  deleteCancerType(cancer_type_id) {
    let {data, onChange} = this.props;
    delete(data.cancer_types[cancer_type_id]);
    onChange(data);
  }

  changeTraitsOptions(cancer_type_id, options) {
    let {data, onChange} = this.props;
    data.cancer_types[cancer_type_id].options = options;
    onChange(data);
  }

  renderCancerTypes() {
    let {data} = this.props;
    return Object.keys(data.cancer_types).map(cancer_type_id => {
      let key = 'cache_' + cancer_type_id;
      let cancer_type = this.state[key];
      if (!cancer_type) return null;

      let options = data.cancer_types[cancer_type_id].options;

      return <SingleCancerType options={options} cancer_type={cancer_type} key={cancer_type_id} onDelete={e => this.deleteCancerType(cancer_type_id)} onChange={o => this.changeTraitsOptions(cancer_type_id, o)}/>
    })
  }


  renderTumorTypeOptions() {
    let {data, onChange} = this.props;
    if (!data.cancer_types || Object.keys(data.cancer_types).length === 0) return null;

    return <TumorTypeOptions data={data} onChange={onChange} skip_submit={true} />
  }

  render() {
    let {data, onChange} = this.props;
    return (
      <div>
        <CancerTypeGlobalDetailsEditor data={data} onChange={onChange} cache={this.state} />
        <div className="btn-group pull-right">
          <button onClick={e => this.collapseAll()} className="btn btn-sm btn-default">Collapse All</button>
          <button onClick={e => this.expandAll()} className="btn btn-sm btn-default">Expand All</button>
        </div>
        <div className="clearfix"></div>

        {this.renderCancerTypes()}
        {this.renderTumorTypeOptions()}
      </div>
    );
  }
}


class SingleCancerType extends Component {
  constructor(props) {
    super(props);
    this.state = {collapsed: false};
    this.collapse = this.collapse.bind(this);
    this.expand = this.expand.bind(this);
  }

  componentDidMount() {
    window.addEventListener('collapse_all_cancer_types', this.collapse, false);
    window.addEventListener('expand_all_cancer_types', this.expand, false);
  }

  componentWillUnmount() {
    window.removeEventListener('collapse_all_cancer_types', this.collapse, false);
    window.removeEventListener('expand_all_cancer_types', this.expand, false);
  }


  collapse() {
    this.setState({collapsed: true});
  }

  expand() {
    this.setState({collapsed: false});
  }
  renderTraits() {
    if (this.state.collapsed) return null;
    let {cancer_type, options, onChange} = this.props;
    return <Traits traits={cancer_type.traits} options={options} onChange={onChange} />
  }

  renderCollapseButton() {
    let {collapsed} = this.state;
    if (collapsed) {
      return <button onClick={this.expand} className="btn btn-default btn-sm"><i className="fa fa-chevron-down" /></button>
    } else {
      return <button onClick={this.collapse} className="btn btn-default btn-sm"><i className="fa fa-chevron-up" /></button>
    }

  }
  render() {
    let {cancer_type, onDelete} = this.props;

    return (
      <div className="search-group-holder">
        <div>
          <div className="btn-group pull-right">
            {this.renderCollapseButton()}
            <button onClick={onDelete} title="Delete" className="btn btn-danger btn-sm"><i className="fa fa-trash" /></button>
          </div>
          <h3>
            {cancer_type.name}<br/> <small>{cancer_type.cancer_category.tumor_type_humanized} tumor</small>
          </h3>
          <div className="clearfix"></div>

        </div>
        {this.renderTraits()}
      </div>
    );
  }
}






class CancerTypeGlobalDetailsEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {options: {}};
  }

  static getDerivedStateFromProps(props) {
    return buildGlobalDetails(props);
  }

  changeTraitsOptions(option) {
    let {data, onChange} = this.props;
    let {options} = this.state;

    for (let trait_id of Object.keys(option)) {
      for (let option_id of Object.keys(option[trait_id])) {
        if (!options[trait_id]) options[trait_id] = {}
        options[trait_id][option_id] = option[trait_id][option_id];
      }
    }
    this.setState({options});
  }


  applyChanges() {
    let {data, onChange} = this.props;
    let {options} = this.state;
    for (let cancer_type_id of Object.keys(data.cancer_types)) {
      for (let trait_id of Object.keys(options)) {
        for (let option_id of Object.keys(options[trait_id])) {
          if (!data.cancer_types[cancer_type_id].options) data.cancer_types[cancer_type_id].options = {};
          if (!data.cancer_types[cancer_type_id].options[trait_id]) data.cancer_types[cancer_type_id].options[trait_id] = {};
          data.cancer_types[cancer_type_id].options[trait_id][option_id] = options[trait_id][option_id];
        }
      }
    }

    onChange(data);
    this.setState({options: {}, open: false});
  }

  renderDetails() {
    let {open, traits, options} = this.state;
    if (!open) return null;

    return (
      <>
        <div className="clearfix"></div>
        <div className="search-group-holder">
          <h3>Global Details</h3>
          <Traits traits={traits} options={options} onChange={o => this.changeTraitsOptions(o)} />
          <hr/>
          <button className="btn btn-success" onClick={e => this.applyChanges()}>Apply Changes</button>
        </div>
      </>

    );
  }

  render() {
    let {traits, open} = this.state;
    if (!traits || traits.length === 0) return null;
    let element;
    if (open) {
      element = <button className="btn btn-default btn-sm pull-left" onClick={e => this.setState({open: false})}>Done making global changes?</button>
    } else {
      element = <button className="btn btn-default btn-sm pull-left" onClick={e => this.setState({open: true})}>Need to make global changes?</button>
    }

    return (
      <div>
        {element}


        {this.renderDetails()}
      </div>
    );
  }
}


function buildGlobalDetails(props) {
  let {data, cache} = props;

  // First group up cancer types
  let cancer_types = [];
  for (let cancer_type_id of Object.keys(data.cancer_types)) {
    let key = 'cache_' + cancer_type_id;
    let cancer_type = cache[key];
    if (!cancer_type) return {};
    cancer_types.push(cancer_type);
  }

  if (cancer_types.length < 2) return {};

  let traits = [];

  // We only need to check the first cancer type
  for (let trait of cancer_types[0].traits) {
    let exists = false;
    for (let i = 1; i < cancer_types.length; i++) {
      for (let x = 0; x < cancer_types[i].traits.length ; x++) {
        if (cancer_types[i].traits[x].id === trait.id) {
          exists = true;
        }
      }
    }
    if (exists) traits.push(trait);

  }

  return {traits};
}
