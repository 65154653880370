import React, {Component} from 'react';
import NeestedRecordPicker from './neested_record_picker';
import request from '../request';
import {GroupItemTypes, GroupItemBaseURL} from '../constants.js.erb';

export default class ItemsCollection extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.moveUp = this.moveUp.bind(this);
    this.moveDown = this.moveDown.bind(this);
  }

  addItem(item) {
    let {items, onChange, onAddItem} = this.props;

    if (items.find(i => i.id === item.id && i.type === item.type)) {
      return;
    }

    item.description = '';
    items.push(item)
    onChange && onChange(items, {action: 'add', item})
    onAddItem && onAddItem(item);
  }

  onDelete(item) {
    if (!confirm('Are you sure?')) return;

    let {items, onChange, onDeleteItem} = this.props;
    let index = items.findIndex(i => i.type === item.type && i.id === item.id);
    items.splice(index, 1);
    onChange && onChange(items, {action: 'delete', item})
    onDeleteItem && onDeleteItem(item);
  }

  moveUp(item) {
    let {items, onChange} = this.props;
    let index = items.findIndex(i => i.type === item.type && i.id === item.id);
    if (index === 0) return;

    items.splice(index, 1);
    items.splice(index - 1, 0, item);
    onChange && onChange(items)
  }

  moveDown(item) {
    let {items, onChange} = this.props;
    let index = items.findIndex(i => i.type === item.type && i.id === item.id);
    if (index === items.length - 1) return;

    items.splice(index, 1);
    items.splice(index + 1, 0, item);
    onChange && onChange(items)
  }

  onChange(item) {
    let {items, onChange} = this.props;
    let index = items.findIndex(i => i.type === item.type && i.id === item.id);
    items[index] = item;
    onChange && onChange(items);
  }

  editItem(item) {
    let {id, type} = item;
    this.setState({id, type});
  }

  renderItem(item) {
    let {itemLabels, sortableItems, reviewItems} = this.props;
    let {onDelete, onChange, moveUp, moveDown} = this;
    let item_props = {item, onDelete, onChange, moveUp, moveDown}

    let {id, type} = item;
    let key = type + "::" + id;
    return (
      <GroupItem key={key} itemLabels={itemLabels} sortableItems={sortableItems} reviewItems={reviewItems} {...item_props} />
    )
  }

  render() {
    let {items, sources, selected_sources, destination, inputLabel, createLinks, candidatesDecorator} = this.props;
    return (
      <div className="items-collection">
        <NeestedRecordPicker callback={r => this.addItem(r)} items={items} sources={sources} selected_sources={selected_sources} destination={destination} inputLabel={inputLabel} createLinks={createLinks} candidatesDecorator={candidatesDecorator}/>

        <div className="group-items">
          {items.map(i => this.renderItem(i))}
        </div>
      </div>
    )
  }
}

class GroupItem extends Component {
  constructor(props) {
    super(props);
    this.state = {selected: false};
  }

  setDefaultLabel(e) {
    e.preventDefault();
    let {item} = this.state;
    let {type, id} = item;
    request("POST", "/api/admin/trials/default_label", {type, id})
      .then(reply => {
        if (!reply.label) {
          window.alert("Default label not set.");
          return;
        }

        if (window.confirm("Do you want to set as label: \n" + reply.label)) {
          item.label = reply.label;
          this.setState({item});
        }
      })
      .catch(error => {
        window.alert("Error getting the default label");
      });
  }

  edit() {
    let item = Object.assign({}, this.props.item);
    this.setState({item, selected: true});
  }


  toggleReview() {
    let reviewed = !this.state.reviewed;
    this.setState({reviewed});
  }

  onCancelForm(e) {
    e.preventDefault();
    this.setState({item: null, selected: false});
  }

  onSubmitForm(e) {
    e.preventDefault();
    let {onChange} = this.props;
    let {item} = this.state;
    this.setState({item: null, selected: false});
    onChange && onChange(item);
  }

  onInputChange(e) {
    let {item} = this.state;
    let {value} = e.target;
    item.label = value;
    this.setState({item});
  }

  renderForm() {
    let {item} = this.state;
    let label;
    if (item.suggested_details && item.suggested_details.length > 0) {
      label = item.suggested_details.join(". ")
    } else {
      let type = GroupItemTypes[item.type];
      label = "Add details for this " + type;
    }

    return(
      <form onSubmit={e => this.onSubmitForm(e)} >
        <div className="form-group">
          <label className="control-label">{label}</label>
          <textarea className="form-control group-item-label-textarea" value={item.label} onChange={e => this.onInputChange(e)} />
        </div>

        <div className="btn-group">
          <input type="submit" className="btn btn-success" />
          <button onClick={e => this.onCancelForm(e)} className="btn btn-default">Cancel</button>
          <button onClick={e => this.setDefaultLabel(e)} className="btn btn-link">Use Default</button>
        </div>
      </form>
    )
  }


  renderDetails() {
    let {label} = this.props.item;

    return (
      <div>
       {label}
      </div>
    )
  }
  renderMoveButtons() {
    let {sortableItems} = this.props;
    if (!sortableItems) return null;

    let {item, moveUp, moveDown} = this.props;
    return (
      <>
        <button title="Move Up" onClick={e => moveUp(item)}><i className="fa fa-arrow-up"></i></button>
        <button title="Move Down" onClick={e => moveDown(item)}><i className="fa fa-arrow-down"></i></button>
      </>

    )
  }

  renderEditButton() {
    let {itemLabels} = this.props;
    if (!itemLabels) return null;
    return <button title="Edit" onClick={e => this.edit()}><i className="fa fa-pencil-alt"></i></button>
  }

  renderReviewedButton() {
    let {reviewItems} = this.props;
    if (!reviewItems) return null;

    let {reviewed} = this.state;
    let icon = reviewed ? 'check-circle' : 'check';
    let className = 'fa fa-' + icon;
    return <button title="Toggle Reviewed" onClick={e => this.toggleReview()} className="review_button"><i className={className}></i></button>
  }

  render() {
    let {item, onDelete} = this.props;
    let {selected, reviewed} = this.state;
    let type = GroupItemTypes[item.type];
    let link_src = GroupItemBaseURL[item.type] + `/${item.id}/edit`;
    let className='group-item';

    if (reviewed) {
      className += ' reviewed';
    }

    return (
      <div className={className}>
        <div className="btn-group pull-right group-action-buttons">
          <button title="Delete" onClick={e => onDelete(item)}><i className="fa fa-times"></i></button>
          {this.renderMoveButtons()}
          {this.renderEditButton()}
          {this.renderReviewedButton()}
        </div>
        <p className="group-item-type">{type}</p>

        <p>
          <strong>{item.name}</strong>
          <a href={link_src} target="_blank" className="group-item-link"><span className="glyphicon glyphicon-edit" /></a>
        </p>


        {selected ? this.renderForm() : this.renderDetails()}
      </div>
    )
  }
}



ItemsCollection.MOAFamilyDecorator = function(item, items) {
  if (!item.moa_family_ids || item.moa_family_ids.length === 0) return false;
  let selected_moa_families = items.filter(i => i.type === 'moa_families').map(i => i.id);
  let is_included =  item.moa_family_ids.some(id=> selected_moa_families.indexOf(id) !== -1);
  if (!is_included) return false;

  return {class: 'moa_family_included'};
}
