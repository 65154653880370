import React, {Component} from 'react';
export class Dismissible extends Component {
  constructor(props) {
    super(props);
    this.state = {visible: true}
  }

  close() {
    this.setState({visible: false});
  }

  getStyle() {
    let type = this.props.type || 'info'
    return 'alert alert-dismissible alert-' + type
  }

  render() {
    if (!this.state.visible) return null;

    let {message} = this.props;
    return(
      <div className={this.getStyle()} role="alert">
        <button type="button" className="close" onClick={e => this.close()}><span aria-hidden="true">&times;</span></button>
        <p className="text-center">{message}</p>
      </div>
    )
  }
}
