import React, {Component} from 'react';
import {BaseEventEditor, BaseEventViewer} from './base_event';
import request from '../../../request';
import {RelatedToOptions} from '../../../constants.js.erb';

class MedTumorImagingEditor extends BaseEventEditor {
  constructor(props) {
    super(props);
    this.state = {other_scan_events: []};
  }
  componentDidMount() {
    this.fetchOtherScanEvents();
  }

  fetchOtherScanEvents() {
    let url = '/admin/patients/' + gon.patientId + '/journey_tracker_events/search?q[event_type_cont]=med_tumor_imaging';
    request('GET', url).then(events => {
      let other_scan_events = events.map(event => {
        let {scan_type, body_location_scanned} = event.data;
        let date = moment(event.created_at).format('L');
        let text = date + ' - ' + scan_type + ' - ' + body_location_scanned;
        return {id: event.id, text};
      });
      this.setState({other_scan_events});
    });
  }

  formFields() {
    let {patient} = this.props;
    let {other_scan_events} = this.state;
    return formFields(patient, other_scan_events);
  }
}


class MedTumorImagingViewer extends BaseEventViewer {
  constructor(props) {
    super(props);
    this.state = {other_scan_events: []};
  }
  componentDidMount() {
    this.fetchOtherScanEvents();
  }

  fetchOtherScanEvents() {
    let url = '/admin/patients/' + gon.patientId + '/journey_tracker_events/search?q[event_type_cont]=med_tumor_imaging';
    request('GET', url).then(events => {
      let other_scan_events = events.map(event => {
        let {scan_type, body_location_scanned} = event.data;
        let date = moment(event.created_at).format('L');
        let text = date + ' - ' + scan_type + ' - ' + body_location_scanned;
        return {id: event.id, text};
      });
      this.setState({other_scan_events});
    });
  }

  formFields() {
    let {patient} = this.props;
    let {other_scan_events} = this.state;
    return formFields(patient, other_scan_events);
  }
}


export default {
  viewer: MedTumorImagingViewer,
  editor: MedTumorImagingEditor
}


function formFields(patient, other_scan_events) {
  let contacts = patient.contacts.map(c => {return {id: c.id, text: c.name}});
  let medical_team_members = patient.medical_team_members.map(m => {return {id: m.id, text: m.person.name}});
  let medical_centers = patient.medical_centers.map(mc => { return {id: mc.id, text: mc.cancer_center.name}});
  let select_scan_events = [{id: 'self', text: 'This tumor Imaging'}].concat(other_scan_events);

  return [
    {name: 'date', type: 'date', required: true, label: 'Date Imaging Report was Received by Sagely Health', inSummary: true},
    {name: 'date_estimated', label: 'Date Estimated?', type: 'bool'},
    {name: 'related_to', type: 'select', collection: RelatedToOptions},
    {name: 'medical_record_date', type: 'date', required: true, inSummary: true},
    {name: 'medical_center_id', label: 'Cancer Center', type: 'select2', collection: medical_centers, idField: 'id', labelField: 'text', hint: 'To appear here, the medical center must be added to the patient.'},
    {name: 'scan_type', type: 'select', collection: SCAN_TYPES, required: true, inSummary: true},
    {name: 'scan_type_description', type: 'text'},
    {name: 'comparison_scan', type: 'select', collection: other_scan_events, idField: 'id', labelField: 'text', hint: 'If previously added, other tumor imaging events will be listed here.'},
    {name: 'body_location_scanned', type: 'select', collection: BODY_LOCATION_SCANNED, required: true, inSummary: true},
    {name: 'location_detail', type: 'text'},
    {name: 'interval_change_outcome', label: 'Interval Change / Outcome', type: 'select', collection: INTERVAL_CHANGE_OUTCOME, required: true},
    {name: 'interval_change_detail', type: 'text'},
    {name: 'biopsy_accessible_diseases', label: 'Biopsy Accessible Diseases?', type: 'select', collection: ['unknown', 'yes', 'no'], labelTransform: 'titleize', required: true},
    {name: 'lymph_node_details', label: 'Lymph Node Details (if any)', type: 'select', collection: ['N0', 'N1', 'N2', 'N3']},
    {name: 'impression_findings', label: 'Impression/Findings', type: 'text'},
    {name: 'assessment_type', type: 'select', collection: ASSESMENT_TYPES},
    {name: 'select_scan', label: 'Select Scan (end comparison)', type: 'select', collection: select_scan_events, idField: 'id', labelField: 'text', hint: 'If previously added, other tumor imaging events will be listed here.'},
    {name: 'select_baseline_scan', label: 'Select Baseline Scan (comparison)', type: 'select', collection: other_scan_events, idField: 'id', labelField: 'text', hint: 'If previously added, other tumor imaging events will be listed here.'},
    {name: 'recist_response_status', label: 'Recist 1.1 Response/Status', type: 'select', collection: RECIST_RESPONSE_STATUS, visible: r => r.assessment_type === 'recist_11'},
    {name: 'recist_description', label: 'Recist 1.1 Description (Nadir, etc.)', type: 'text', visible: r => r.assessment_type === 'recist_11'},
    {name: 'irecist_response_status', label: 'iRecist Response/Status', type: 'select', collection: IRECIST_RESPONSE_STATUS, visible: r => r.assessment_type === 'irecist'},
    {name: 'irecist_description', label: 'iRecist Description (Nadir, etc.)', type: 'text', visible: r => r.assessment_type === 'irecist'},
    {name: 'cheson_response_status', label: 'Cheson Criteria Response/Status', type: 'select', collection: CHESON_RESPONSE_STATUS, visible: r => r.assessment_type === 'cheson'},
    {name: 'cheson_description', label: 'Cheson Criteria Description (Nadir, etc.)', type: 'text', visible: r => r.assessment_type === 'cheson'},

    {name: 'user', label: 'To whom or how was this tumor imaging report sent to Sagely Health?'},
    {name: 'peer_type', type: 'select', collection: ['patient', 'contact', 'medical_team', 'other'], labelTransform: 'titleize', label: 'Who provided this tumor imaging report to Sagely Health?'},
    {name: 'contact_id', type: 'select2', collection: contacts, visible: e => e.peer_type === 'contact', idField: 'id', labelField: 'text', hint: 'To appear here, contacts must be added to the patient.'},
    {name: 'medical_team_member_id', type: 'select2', collection: medical_team_members, visible: e => e.peer_type === 'medical_team', idField: 'id', labelField: 'text', hint: 'To appear here, medical team members must be added to the patient.'},
    {name: 'description', type: 'text', inSummary: true}
  ];
}



const SCAN_TYPES = ['Computed tomography scan (CT)', 'Computed tomography scan non-contrast (nc-CT)', 'Computed tomography scan with Contrast (c-CT)', 'Magnetic resonance imaging scan (MRI)', 'Positron emission tomography scan (PET)', 'Integrated PET-CT (PET-CT)', 'Ultrasound (UTZ)', 'Bone scan (BS)', 'Contrast-Enhanced UltraSound US (C-UTZ)', 'Other']
const BODY_LOCATION_SCANNED = ['Chest/Abdomen/Pelvis', 'Abdomen/Pelvis', 'Abdomen', 'Pelvis', 'Chest', 'Brain', 'Spine', 'Bones', 'Full body', 'Extremeties', 'Other'];

const INTERVAL_CHANGE_OUTCOME = ['Stable Disease (unchanged)', 'Increasing', 'Decreasing', 'Mixed Response', 'Other'];


const ASSESMENT_TYPES = {
  recist_11: 'RECIST 1.1',
  irecist: 'iRECIST',
  cheson: 'Cheson Criteria (lymphoma, 2007)'
}


const RECIST_RESPONSE_STATUS = ['Unconfirmed Partial Response (-30%) (uPR)', 'Unconfirmed Complete Response (uCR)', 'Confirmed Partial Response (-30%) (PR)', 'Confirmed Complete Response (CR)', 'Stable Disease (SD)', 'Progressive Disease (+20%) (PD)', 'Not Evaluable (NE)'];

const IRECIST_RESPONSE_STATUS = ['Immune Unconfirmed Partial Response (-30%) (iuPR)', 'Immune Unconfirmed Complete Response (iuCR)', 'Immune Confirmed Partial Response (-30%) (iPR)', 'Immune Confirmed Complete Response (iCR)', 'Immune Stable Disease (iSD)', 'Immune Unconfirmed Progressive Disease (+20%) (iuPD)', 'Immune Confirmed Progressive Disease (+20%)  (icPD)', 'Not Evaluable'];

const CHESON_RESPONSE_STATUS = ['Partial Response (-50%) (PR)', 'Complete Response (CR)', 'Stable Disease (SD)', 'Progressive Disease (+20%) (PD)', 'Not Evaluable (NE)'];
