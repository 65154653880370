import React, {Component} from 'react';
import OptionsCache from './options_cache';
import Form from './journey_tracker_form';
import * as Inflector from 'inflected';
import * as DateHelpers from './date_helpers';
import NeestedRecord from './neested_record';


export class FindingStatusModal extends Form.FormField {
  constructor(props) {
    super(props);

    let status = props.model.status.sort(sortableByDate);
    this.state = {status, filter: 'all'};
    this.findingCache = new OptionsCache({source: 'findings', onChange: e => this.forceUpdate()});
  }

  static getDerivedStateFromProps(props, state) {
    state.status = applyFilters(state.filter, props.model.status);
    return state;
  }

  componentDidMount() {
    let el = $(this.refs.modal_container);
    el.modal({show: false});
  }

  newRecord() {
    let {model} = this.props;
    let current = {}
    if (model.status.length > 0 ) {
      current = initializeStatusFromPrevious(model.status);
    }

    this.setState({current});
  }

  edit(current) {
    this.setState({current});
  }

  cancelNewRecord() {
    this.setState({current: undefined});
  }

  showModal(e) {
    this.setState({show_modal: true}, () => $(this.refs.modal_container).modal('show'));
  }

  closeModal() {
    $(this.refs.modal_container).modal('hide');
    this.setState({show_modal: false});
  }

  changeFilter(e) {
    let {model} = this.props;
    let filter = e.target.value;
    let status = applyFilters(filter, model.status);
    this.setState({filter, status});
  }

  renderTitle() {
    let {model} = this.props;
    let finding = this.findingCache.get(model.finding_id);
    return (
      <span>{finding.text} Status</span>
    )
  }


  renderEmptyList() {
    let {readOnly} = this.props;
    let action;
    if (readOnly) {
      action = <p>Add a new event to be able to record an updated status.</p>
    } else {
      action = <button className="btn btn-success" onClick={e => this.newRecord()}>Add Status</button>
    }

    return (
      <div>
        <p><i>No status records found.</i></p>
        {action}
      </div>
    );
  }

  renderList() {
    let {model, readOnly} = this.props;
    if (!model.status || model.status.length === 0) return this.renderEmptyList();
    let {status, filter} = this.state;

    let global_action;
    if (!readOnly) {
      global_action = <button className="btn btn-success" onClick={e => this.newRecord()}>Add Status</button>
    }

    return (
      <div>
        <div className="pull-right">
          {global_action}
        </div>
        <div className="form-inline pull-left">
          <div className="form-group">
            <label>Filter by Status Type &nbsp;&nbsp;</label>
            <select className="form-control" value={filter} onChange={e => this.changeFilter(e)}>
              <option value="all">Show All</option>
              <option value="quantitative">Quantitative</option>
              <option value="qualitative">Qualitative</option>
            </select>
          </div>
        </div>
        <div className="clearfix"></div>
        <hr/>

        <table className="table table-condensed table-striped">
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {status.map(status => {
              let date = DateHelpers.formatEstimated(status);
              let action = readOnly ? 'Show' : 'Edit';
              let text = status.type == 'qualitative' ? this.qualitativeStatus(status) : this.quantitativeStatus(status);

              return (
                <tr key={status.id}>
                  <td>{date}</td>
                  <td>{Inflector.titleize(status.type)}</td>
                  <td>{text}</td>
                  <td><button className="btn btn-default btn-sm" onClick={e => this.edit(status)}>{action}</button></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }


  qualitativeStatus(status) {
    return <span><b>Interval Change:</b> {Inflector.titleize(status.interval_change)}</span>;
  }

  quantitativeStatus(status) {
    return <span><b>Depth:</b> {status.depth / 10}  cm <b>Width:</b> {status.width / 10} cm <b>Length:</b> {status.length} cm</span>;
  }

  renderForm(current) {
    let {model, field, readOnly, organCache} = this.props;
    let {patient, fields_changelog} = field;
    let {onAddBatch, onEditBatch} = field.callbacks;
    return <FindingStatus readOnly={readOnly} record={current} parent={model} onClose={e => this.cancelNewRecord()} container_element='div' onAddBatch={onAddBatch} onEditBatch={onEditBatch} patient={patient} fields_changelog={fields_changelog} />
  }

  renderContent() {
    let {show_modal, current} = this.state;
    if (!show_modal) return null;
    if (current) {
      return this.renderForm(current);
    } else {
      return this.renderList();
    }
  }


  renderModal() {
    let {field} = this.props;
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" aria-label="Close" onClick={e => this.closeModal()}><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">{this.renderTitle()}</h4>
            </div>
            <div className="modal-body">
              {this.renderContent()}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" onClick={e => this.closeModal()}>Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderInput() {
    let {field, model, embedded} = this.props;
    if (embedded) return this.getValueLabel();
    let className = field.className || 'btn btn-default';
    let status_count = model.status.length;

    return (
      <div className="form-group">
        <button className={className} onClick={e => this.showModal(e)}>Show Status ({status_count})</button>
        {this.renderModal()}
      </div>
    )
  }

  getValueLabel() {
    let {model} = this.props;
    let status_count = model.status.length;
    let label = status_count + ' status updates';

    return (
      <div>
        <button className='btn btn-link' onClick={e => this.showModal(e)}>{label}</button>
        {this.renderModal()}
      </div>
    )
  }

  renderValue() {
    let {embedded} = this.props;
    if (embedded) return this.getValueLabel();
    return super.renderValue();
  }
}



export class FindingStatus extends NeestedRecord {
  constructor(props) {
    super(props);
    this.findingCache = new OptionsCache({source: 'findings', onChange: e => this.forceUpdate()});
  }

  getModelName() {
    return "Status";
  }

  getBasePath() {
    let {parent} = this.props;
    return 'findings/[id:' + parent.id + ']/status';
  }

  getSectionLabel() {
    let {record} = this.state;
    let {parent} = this.props;
    let finding = this.findingCache.get(parent.finding_id);
    let date = DateHelpers.format(record);
    return ['Finding', finding.text, 'Status Update', date];
  }

  formFields() {
    let {record} = this.state;
    let shared = this.sharedFormFields();
    if (record.type === 'qualitative') {
      return shared.concat(this.qualitativeFormFields());
    } else {
      return shared.concat(this.quantitativeFormFields());
    }
  }

  sharedFormFields() {
    return [
      {name: 'date', type: 'date', required: true},
      {name: 'is_date_estimated', type: 'bool', label: 'Is this date estimated?'},
      {name: 'type', type: 'select', required: true, collection: ['qualitative', 'quantitative'], labelTransform: 'titleize'},
      {name: 'status', type: 'select', collection: ['active', 'inactive', 'resolved'], labelTransform: 'titleize'},
      {name: 'measurability', type: 'select', collection: ['measurable', 'non_measurable'], labelTransform: 'titleize'},
      {name: 'severity', type: 'select', collection: [1, 2, 3, 4, 5]},
      {name: 'new', type: 'bool'},
      {name: 'target_lesion', label: 'Target Lesion?', type: 'bool'},

    ];
  }
  qualitativeFormFields() {
    return [
      {name: 'interval_change', type: 'select', collection: INTERVALS, labelTransform: 'titleize'},
      {name: 'status_detail', label: 'Qualitative Status Detail', type: 'text'},
      {name: 'detail_on_source', type: 'text'}
    ];
  }

  quantitativeFormFields() {
    return [
      {name: 'length', type: MeasurementInput},
      {name: 'width', type: MeasurementInput},
      {name: 'depth', type: MeasurementInput},
      {name: 'maximum_suv', type: 'number', label: 'Maximum SUV'},
      {name: 'status_detail', label: 'Quantitative Status Detail', type: 'text'},
      {name: 'detail_on_source', type: 'text'}
    ];
  }
}

const INTERVALS = ['initial', 'stable', 'minimal increase', 'minimal decrease', 'increased', 'decreased', 'mixed response']

class MeasurementInput extends Form.FormField {
  constructor(props) {
    super(props);

    let value = +props.value;
    let unit = props.unit || 'cm';
    if (unit === 'cm') value = value / 10;

    this.state = {unit, value}
  }


  changeUnit(e, unit) {
    e.preventDefault();
    if (this.state.unit === unit) return;
    let {value} = this.state;
    if (unit === 'mm') {
      value = this.props.value;
    } else {
      value = value / 10;
    }
    this.setState({value, unit});
  }

  onChange(e) {
    let {onChange} = this.props;
    let {name, value} = e.target;
    value = +value;

    this.setState({value});

    if (this.state.unit === 'cm') value = value * 10;
    onChange({target: {name, value}});
  }


  renderUnits() {
    return (
      <ul className="dropdown-menu dropdown-menu-right">
        {Object.keys(UNITS).map(unit => {
          let symbol;
          if (unit === this.state.unit) {
            symbol =  <span className="glyphicon glyphicon-ok"></span>
          }
          return <li key={unit}><a href="#" onClick={e => this.changeUnit(e, unit)}>{symbol} {UNITS[unit]}</a></li>
        })}
      </ul>
    );
  }

  renderInput() {
    let {field, onChange} = this.props;
    let {value, unit} = this.state;
    let className = "form-control";
    if (field.required) className += " required";

    return this.renderFormGroup(
      <div className="input-group">
        <input type="number" id={this.fieldID()} className={className}  name={field.name} value={value || ''} onChange={e => this.onChange(e)} />

        <div className="input-group-btn">
          <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{UNITS[unit]} <span className="caret"></span></button>
          {this.renderUnits()}
        </div>
      </div>
    );
  }

  getValueLabel() {
    let {value} = this.props;
    return '' + value / 10 + ' cm';
  }

}

function initializeStatusFromPrevious(all_status) {
  let prev_status = all_status.sort(sortableByDate)[0];
  let status = Object.assign({}, prev_status, {id: undefined, date: undefined, is_date_estimated: undefined});
  return status;
}

function sortableByDate(a, b) {
  let a_date = new Date(a.date);
  let b_date = new Date(b.date);

  if (a_date > b_date) return -1;
  if (a_date < b_date) return 1;
  return 0;
}


function applyFilters(filter, all_status) {
  if (filter !== 'all') {
    all_status = all_status.filter(s => s.type === filter)
  }

  all_status = all_status.sort(sortableByDate);
  return all_status;
}

const UNITS = {
  mm: 'Milimeters',
  cm: 'Centimeters'
}
