import React, {Component} from 'react';
import request from '../request';

export default class PatientsActiveThisWeek extends Component {
  constructor(props) {
    super(props);
    let {patients} = props;
    this.state = {patients};
    this.toggleActiveThisWeek = this.toggleActiveThisWeek.bind(this);
  }

  enable(patient) {
    let url = `/admin/patients/${patient.id}/active_this_week`;
    request('PUT', url).then(patient => {
      let {patients} = this.state;
      let index = patients.findIndex(p => p.id === patient.id);
      patients[index] = patient;
      this.setState({patients});
    })
  }

  disable(patient) {
    let url = `/admin/patients/${patient.id}/inactive_this_week`;
    request('PUT', url).then(patient => {
      let {patients} = this.state;
      let index = patients.findIndex(p => p.id === patient.id);
      patients[index] = patient;
      this.setState({patients});
    })
  }

  toggleActiveThisWeek(){
    let show_not_active = !this.state.show_not_active;
    this.setState({show_not_active});
  }

  renderActive() {
    let {patients} = this.state;
    let active = patients.filter(p => p.active_this_week);

    if (!active || active.length === 0) return null;

    // Sort patients by name
    active = active.sort(compareByName);

    return (
      <table className="table table-condensed table-striped">
        <tbody>
          {active.map(patient => {
            let link = '/admin/patients/' + patient.id;
            return (
              <tr key={patient.id}>
                <td><a href={link}>{patient.name}</a> <span className="subtle">{patient.cancer_type}</span></td>
                <td className="btn-cell">
                  <button className="btn btn-xs btn-danger btn-subtle btn-subtle-remove" onClick={e => this.disable(patient)}>
                    <span className="fa fa-minus-circle"></span>
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  renderInactive() {
    let {show_not_active} = this.state;
    let btnLabel = show_not_active ? 'Hide' : 'Show';

    return (
      <div className="inactive-holder">
        <button className="btn btn-default btn-sm" onClick={this.toggleActiveThisWeek}>{btnLabel} Not Active This Week</button>
        {this.renderInactiveList()}
      </div>
    );
  }

  renderInactiveList() {
    let {patients, show_not_active} = this.state;
    if (!show_not_active) return null;
    let inactive = patients.filter(p => !p.active_this_week);
    if (!inactive || inactive.length === 0) return null;
    return (
      <table className="table table-condensed table-striped table-inactive-patients">
        <tbody>
          {inactive.map(patient => {
            let link = '/admin/patients/' + patient.id;
            return (
              <tr key={patient.id}>
                <td><a href={link}>{patient.name}</a>  <span className="subtle">{patient.cancer_type}</span></td>
                <td className="btn-cell">
                  <button className="btn btn-xs btn-success btn-subtle btn-subtle-add" onClick={e => this.enable(patient)}>
                    <span className="fa fa-plus-circle"></span>
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  render() {
    return (
      <div>
        <h2 className="admin_section_header">Active This Week</h2>
        {this.renderActive()}
        {this.renderInactive()}
      </div>
    );
  }
}


function compareByName( a, b ) {
  if ( a.name < b.name ){
    return -1;
  }
  if ( a.name > b.name ){
    return 1;
  }
  return 0;
}
