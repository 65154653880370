import React, {Component} from 'react';
import SavePanel from './save_panel';

export default class EmbeddedSaveButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.saveEventCallback = this.saveEventCallback.bind(this);
  }

  componentDidMount() {
    window.addEventListener('population-save-event', this.saveEventCallback, false);
  }

  componentWillUnmount() {
    window.removeEventListener('population-save-event', this.saveEventCallback, false);
  }

  saveEventCallback(e) {
    let {status} = e.detail;
    this.setState({status});
  }

  triggerSaveEvent() {
    let event = new Event('population-save-request');
    window.dispatchEvent(event);
  }

  render() {
    let icon, text, style, action, errors_list;
    let {status} = this.state;

    if (status === 'submitting') {
      action = <button className="btn btn-success" disabled={true}>Saving Form</button>
    } else {
      action = <button className="btn btn-success" onClick={e => this.triggerSaveEvent()}>Save Form</button>
    }

    if (status === 'success') {
      icon = 'glyphicon-ok'
      text = <span>Success! Updated.</span>
      style = 'alert-success';
    }
    else if (status === 'error') {
      icon = 'glyphicon-warning-sign'
      text = <span><strong>Oops, something went wrong.</strong> Please try again.</span>
      style = 'alert-danger';
    }
    else {
      icon = 'glyphicon-warning-sign'
      text = <span>Remember to <strong>save the form.</strong></span>
      style = 'alert-info';
    }

    return (
      <div className={'form-flash alert ' + style}>
        <span>
          <span className={'glyphicon ' + icon}></span> {text}
        </span>
        {action}
      </div>
    );
  }


}
