import React, {Component} from 'react';
import StarsRatingField from './stars_rating_field';
import joinReact from './join_react'
export default class Person extends Component {
  render() {
    let {person} = this.props;
    let url = "/admin/persons/" + person.id;
    let organization_links = buildOrganizationLinks(person);
    return (
      <div>
        <p className="person-name"><a href={url}>{person.full_name}</a></p>
        <p className="person-org">{joinReact(organization_links, ', ')}</p>
        <StarsRatingField value={person.score} starsCount={5} />
      </div>
    );
  }
}

function buildOrganizationLinks(person) {
  let all = [];
  for (let company of person.companies) {
    let url = "/admin/companies/" + company.id;
    all.push(<a href={url}>{company.name}</a>);
  }

  for (let cancer_center of person.cancer_centers) {
    let url = "/admin/cancer_centers/" + cancer_center.id;
    all.push(<a href={url}>{cancer_center.name}</a>);
  }

  return all;
}
