import React, {Component} from 'react';
import MapViewer from './map_viewer';
import Loading from './loading';
import request from '../request';
import {getLocationLabel} from './locations';

export default class MultiGeolocationPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addLocation = this.addLocation.bind(this);
  }

  showModal() {
    this.setState({show_modal: true});
  }

  closeModal() {
    this.setState({show_modal: false});
  }
  deleteLocation(location) {
    let {locations, onChange} = this.props;
    let index = locations.findIndex(l => l.place_id === location.place_id);
    locations.splice(index, 1);
    onChange(locations);
  }

  addLocation(location) {
    let {locations, onChange} = this.props;
    if (!locations) locations = [];
    if (!location.id) {
      location.id = 'custom_location-' + location.place_id;
    }
    locations.push(location);
    onChange(locations);
  }

  renderModal() {
    if (!this.state.show_modal) return null;
    let {patient, locations} = this.props;
    return <LocationsModal onClose={this.closeModal} onAddLocation={this.addLocation} patient={patient} currentLocations={locations} />
  }

  renderLabels() {
    let {locations} = this.props;
    return (
      <div>
        <ul className="extra_location_list">
        {locations.map(location => {
          return (
            <li key={location.place_id}>
              <button className="btn btn-xs btn-default" onClick={e => this.deleteLocation(location)}><i className="fa fa-times red" /> Remove </button>
              {location.display_name}

            </li>
          )
        })}
        </ul>
        <button className="btn btn-default btn-success btn-sm" onClick={this.showModal}>Add Location</button>
      </div>
    );
  }

  render() {
    return (
      <div className="multi-geolocation-picker" >
        {this.renderLabels()}
        {this.renderModal()}
      </div>
    )
  }
}



class LocationsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {address: ''};
    this.onChange = this.onChange.bind(this);
    this.search = this.search.bind(this);
    this.selectLocation = this.selectLocation.bind(this);
  }

  componentDidMount() {
    $(this.refs.modal_container).modal({show: true});
    $(this.refs.modal_container).on('hide.bs.modal', () => {
      this.props.onClose();
    });
    setTimeout(e => this.refs.input.focus(), 500);
  }

  closeModal() {
    $(this.refs.modal_container).modal('hide');
  }

  selectLocation(location) {
    let {onAddLocation} = this.props;
    onAddLocation(location);
    this.closeModal();
  }

  onChange(e) {
    let address = e.target.value;
    this.setState({address});
  }

  search(e) {
    this.setState({loading: true});
    e.preventDefault();
    let {address} = this.state;
    let location = {address};
    let url = '/search_geolocation';

    request('POST', url, {location})
      .then(locations => {
        this.setState({locations, loading: false});
      })
      .catch(e => {
        this.setState({loading: false});
      });

  }

  renderResults() {3
    let {locations, loading} = this.state;
    if (loading) return <Loading />
    if (!locations) return null;

    return (
      <div>
        {locations.map(location => {
          return (
            <div key={location.place_id}>
              <button className="btn btn-link" onClick={e => this.selectLocation(location)}>
                {location.display_name}
              </button>
            </div>
          );
        })}
      </div>
    );
  }

  renderPatientLocations() {
    let {patient, currentLocations} = this.props;
    if (!patient) return null;
    return <PatientLocations patient_id={patient.id} selectLocation={this.selectLocation} currentLocations={currentLocations} closeModal={e => this.closeModal()} />
  }

  renderContent() {
    let {address} = this.state;

    return (
      <div>
        {this.renderPatientLocations()}
        <form onSubmit={this.search}>
          <h4>Search Locations</h4>
          <div className="form-group">
            <input type="text" className="form-control" ref="input" onChange={this.onChange} value={address} />
          </div>
          <button type="submit" className="btn btn-default">Submit</button>
        </form>
        {this.renderResults()}
      </div>
    );
  }

  render() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">Add Location</h4>
            </div>
            <div className="modal-body">
              {this.renderContent()}
            </div>
            <div className="modal-footer">

            </div>
          </div>
        </div>
      </div>
    )
  }

}


export class PatientLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {loading: true};
  }

  componentDidMount() {
    this.fetchPatientDetails();
  }

  fetchPatientDetails() {
    this.setState({loading: true});
    let {patient_id, currentLocations} = this.props;
    let url = `/admin/patients/${patient_id}`;
    request('GET', url).then(patient => {
      let locations = false;

      if (patient.locations && patient.locations.length > 0) {
        locations = patient.locations.filter(l => currentLocations.findIndex(cl => cl.id === l.id) === -1)
      }
      this.setState({patient, locations, loading: false});
    });
  }

  addLocation(location) {
    let {selectLocation} = this.props;
    location.place_id = `custom_location-${location.id}`;
    location.display_name = getLocationLabel(location);
    selectLocation(location);
  }

  renderNoLocationsMessage() {
    let {patient_id} = this.props;
    let add_locations_link = `/admin/patients/${patient_id}/edit?tab=locations`;
    return (
      <div className="alert alert-warning">
        No locations found for this patient. <a href={add_locations_link} target="_blank">Add Locations</a> or
      <button onClick={e => this.fetchPatientDetails()} className="btn-link" style={{padding: 0}}>Reload</button>
      </div>
    )
  }

  renderPatientLocations() {
    let {locations} = this.state;

    return (
      <div className="alert alert-info">
        <h3>Patient locations</h3>
        <ul>
          {locations.map(location => {
            let label = getLocationLabel(location);
            let primary_label;
            if (location.primary) {
              primary_label = <span className="badge">Primary</span>;
            }
            return (
              <li key={location.id}>{primary_label} <button className="btn btn-link" onClick={e => this.addLocation(location)}>{label}</button></li>
            )
          })}
        </ul>
      </div>
    )
  }

  render() {
    let {patient, locations, loading} = this.state;
    if (loading) return <Loading />
    if (!patient.locations || patient.locations.length === 0 ) return this.renderNoLocationsMessage();
    if (!locations || locations.length === 0) return null;
    return this.renderPatientLocations();
  }
}
