import React, {Component} from 'react';
import request from '../request';

export default class TrialReview extends Component {
  constructor(props) {
    super(props);
    this.state = buildState(props.trial_reviews, props);
  }

  startReview() {
    let {trial} = this.props;
    let url = "/admin/trial_review/start/";
    request("PUT", url, {trial_id: trial.id}).then(trialReviews => {
      let newState = buildState(trialReviews, this.props);
      this.setState(newState);
    });
  }

  cancelReview() {
    if (!confirm('Are you sure?')) return;
    let {trial} = this.props;
    let url = "/admin/trial_review";
    request("DELETE", url).then(trialReviews => {
      let newState = buildState(trialReviews, this.props);
      this.setState(newState);
    });
  }


  renderStartReview() {
     return (
      <div className="review_status_box">
         <span>This trial has updates to review</span>
        <button className="btn btn-primary btn-sm" onClick={e => this.startReview()}>Start the review</button>
      </div>
    );
  }

  renderReviewInProgress() {
     return (
      <div className="review_status_box">
        <span>You are reviewing this trial.</span>
        <button className="btn btn-warning btn-sm" onClick={e => this.cancelReview()}>Cancel the review</button>
      </div>
    );
  }

  renderOtherReviewInProgress() {
    let {other_trial_id} = this.state;
    let url = "/admin/trials/" + other_trial_id + "/edit"
     return (
      <div className="review_status_box">
       <b>You are reviewing <a href={url}>a different trial.</a></b>
      </div>
    );
  }

  renderOtherReviewer() {
     return (
      <p className="other_reviewing_alert">
        Warning: Another user is currently reviewing this trial.
      </p>
    );
  }



  render() {
    let {no_reviews, user_is_reviewing, user_reviewing_other, reviewing_by_other} = this.state;
    if (no_reviews) return this.renderStartReview();
    if (user_is_reviewing) return this.renderReviewInProgress();
    if (user_reviewing_other) return this.renderOtherReviewInProgress();
    if (reviewing_by_other) return this.renderOtherReviewer();
    return null;
  }
}

function buildState(trialReviews, props) {
  let state = {no_reviews: false, user_is_reviewing: false, user_reviewing_other: false, reviewing_by_other: false}

  if (trialReviews.length === 0) {
    state.no_reviews = true;
    return state;
  }

  for (let review of trialReviews) {
    if ((review.admin_id === props.current_admin.id) && (review.trial_id === +props.trial.id)) {
      state.user_is_reviewing = true;
      return state;
    }

    if (review.admin_id === props.current_admin.id) {
      state.user_reviewing_other = true;
      state.other_trial_id = review.trial_id;
    }

    if (review.trial_id === props.trial.id) {
      state.reviewing_by_other = true;
    }
  }

  return state;
}
