import React, {Component} from 'react';

import {EventPreview, EventLabels} from './event_modal';
import ChangelogViewer from './changelog_viewer';
import Loading from '../loading';
import {RelatedToOptions} from '../../constants.js.erb';
import request from '../../request';

export default class EventsList extends Component {
  constructor(props) {
    super(props);
    this.state = {events: [], loading: false};
  }

  componentDidMount() {
    this.fetchEvents();
    let el = $(this.refs.modal_container);
    el.modal({show: false});
    el.on('hide.bs.modal', () => {
      this.close();
    });
  }

  close() {
    this.setState({current_event: null});
  }

  fetchEvents() {
    this.setState({loading: true});
    let url = '/admin/patients/' + gon.patientId + '/journey_tracker_events';
    request('GET', url).then(events => {
      this.setState({events, loading: false});
    });
  }

  showEvent(journey_tracker_event_id) {
    this.setState({loading_event: true}, () => $(this.refs.modal_container).modal('show'));

    let url = '/admin/patients/' + gon.patientId + '/journey_tracker_events/' + journey_tracker_event_id;
    request('GET', url).then(current_event => {
      this.setState({current_event, loading_event: false});
    });

  }

  renderEvent() {
    let {loading_event, current_event} = this.state;
    if (loading_event) return <Loading />;
    if (!current_event) return null;
    let {patient} = this.props;

    return (
      <div>
        <EventPreview patient={patient} event={current_event} />
        <div className="event-review-changes">
          <p><b>Changes</b></p>
          <ChangelogViewer fields_changelog={current_event.changelog} />
        </div>
      </div>
    );

  }

  renderModal() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" aria-label="Close" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              {this.renderEvent()}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    )
  }


  renderContent() {
    let {loading, events} = this.state;
    if (loading) return <Loading />
    console.log(events);
    return (
      <table className="table table-condensed table-striped jt-log-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Admin</th>
            <th>Event Type</th>
            <th>Related To</th>
            <th>Attachments?</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {events.map(event => {
        let date = moment(event.created_at).format('LLL');
        let related_to = RelatedToOptions[event.related_to];
        let event_name = EventLabels[event.event_type];
        let attachments = event.has_files_attached ? 'Yes' : 'No';
        return (
          <tr key={event.id}>
            <td>{date}</td>
            <td>{event.admin_name}</td>
            <td>{event_name}</td>
            <td>{related_to}</td>
            <td>{attachments}</td>
            <td>
              <button className="btn btn-sm btn-default" onClick={e => this.showEvent(event.id)}>Show</button>
            </td>
          </tr>
        );
      })}
        </tbody>
      </table>
    );
  }
  render() {
    return (
      <div>
        <p><b>Events</b></p>
        {this.renderContent()}
        {this.renderModal()}
      </div>
    );
  }
}
