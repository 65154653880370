import React, {Component} from 'react';
import ReferenceStarsRating from '../reference_stars_rating';
import Form from '../form';
import request from '../../request';
import RenderRubrics from './reference_rubrics';
import Loading from '../loading';
import {PopulationRubricTypes, ReferencePopulationRatingTypes} from '../../constants.js.erb';

const DEFAULT_RATING_TYPE = 'monotherapy';

export default class Reference extends Component {
  constructor(props) {
    super(props);
    let reference_data = props.population.data.reference || {ratings: [defaultRating()]};
    this.state = {reference_data};

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.fetchReference();
  }

  fetchReference() {
    let {population} = this.props;
    let url = '/admin/references/' + population.reference_id;
    request('GET', url).then(reference => {
      this.setState({reference});
    });
  }

  submit() {
    this.setState({submitting: true})
    let {population, onChange} = this.props;
    let {reference_data} = this.state;
    population.data.reference = reference_data;
    onChange(population.data);
  }


  onChange(reference_data) {
    this.setState({reference_data})
  }

  onChangeRating(rating, index) {
    let {reference_data} = this.state;
    reference_data.ratings[index] = rating;
    this.setState({reference_data}, this.submit)
  }

  addRating() {
    let {reference_data} = this.state;
    reference_data.ratings.push(defaultRating());
    this.setState({reference_data})
  }

  deleteRating(index) {
    if (!confirm('Are you sure?')) return;

    let {reference_data} = this.state;
    reference_data.ratings.splice(index, 1);
    this.setState({reference_data}, this.submit);
  }

  scoreableFormFields() {
    let extraOptions = ReferenceStarsRating.EXTRA_OPTIONS;

    return [
      {name: 'rating_type', label: 'Type', type: 'select', collection: ReferencePopulationRatingTypes},
      {name: 'items', label: 'Interventions', type: 'items-collection'},
      {name: 'efficacy_rating', label: 'Efficacy', type: 'stars-rating', extraOptions},
      {name: 'efficacy_rating_justification', type: 'text-inline'},
      {name: 'efficacy_description', type: 'text-inline', rows: 6, autosize: true},
      {name: 'safety_rating', label: 'Safety', type: 'stars-rating', extraOptions},
      {name: 'side_effect_rating_justification', type: 'text-inline'},
      {name: 'safety_description', type: 'text-inline', rows: 6, autosize: true},
    ]
  }

  notableFormFields() {
    return [
      {name: 'notable_information', type: 'text', rows: 6, autosize: true},
    ];
  }


  renderRatings() {
    let {reference_data} = this.state;

    return reference_data.ratings.map((rating, index) => {
      return (
        <div key={index} className="reference_rating_holder">
          <div className="rating_header">
            <h4><b>Rating #{index + 1}</b></h4>
            <button onClick={e => this.deleteRating(index)} className="btn btn-default btn-sm">Delete</button>
          </div>
          <Form model={reference_data.ratings[index]} fields={this.scoreableFormFields()} onChange={r => this.onChangeRating(index, r)} />
        </div>
      )
    });
  }

  renderRubricType() {
    let {population} = this.props;
    if (!population.rubric_type) return null;
    let rubric_type = PopulationRubricTypes[population.rubric_type];

    return (
      <div className="rubric-type">
        Rubric Type: <strong>{rubric_type}</strong>
      </div>
    )
  }


  renderScoreable() {
    return (
      <div>
        {this.renderRubricType()}
        <button onClick={e => this.addRating()} className="btn btn-success">Add Rating</button>
        <div className="outer_ratings_holder">
          {this.renderRatings()}
        </div>

        {this.renderSafetyRubric()}
        {this.renderEfficacyRubric()}
      </div>
    );
  }

  renderNotable() {
    let {reference_data} = this.state;
    return (
      <div>
        {this.renderRubricType()}
        <Form model={reference_data} fields={this.notableFormFields()} onChange={r => this.onChange(r)} onSubmit={this.submit} submitLabel="Submit" />
      </div>
    )
  }

  renderSafetyRubric() {
    let {show_safety_rubric} = this.state;

    let btn_label, content;
    if (show_safety_rubric) {
      btn_label = 'Hide Safety Rubric';
      content = RenderRubrics.renderSafetyRubric();
    } else {
      btn_label = 'Show Safety Rubric';
    }

    return (
      <div className="rubric-holder">
        <button className="btn btn-sm btn-primary" onClick={e => this.setState({show_safety_rubric: !show_safety_rubric})}>{btn_label} </button>
        {content}
      </div>
    )
  }


  renderEfficacyRubric() {
    let {population} = this.props;
    let {show_efficacy_rubric} = this.state;

    let btn_label, content;
    if (show_efficacy_rubric) {
      btn_label = 'Hide Efficacy Rubric';
      content = RenderRubrics.renderEfficacyRubric(population);
    } else {
      btn_label = 'Show Efficacy Rubric';
    }

    return (
      <div className="rubric-holder">
        <button className="btn btn-sm btn-primary" onClick={e => this.setState({show_efficacy_rubric: !show_efficacy_rubric})}>{btn_label} </button>
        {content}
      </div>
    )
  }


  renderNoInfo() {
    let {reference} = this.state;
    let link = `/admin/references/${reference.id}/edit`;
    return (
      <div className="well">
        This reference is marked as not having scoreable or notable information, <a href={link}>edit the reference to add a note or score</a>.
      </div>
    )
  }

  render() {
    let {reference} = this.state;
    if (!reference) return <Loading />
    if (reference.results_data === 'y') return this.renderScoreable();
    if (reference.results_data === 'n') return this.renderNotable();
    return this.renderNoInfo();
  }
}

function defaultRating() {
  return {rating_type: DEFAULT_RATING_TYPE};
}
