import React, {Component} from 'react';
import BaseStep from './base_step';
import Form from '../form';
import Loading from '../loading';
import request from '../../request';
import Markdown from '../markdown';
import {ReportQuestionSearchKey, ReportQuestionTagSearchKey} from '../../constants.js.erb';

export default class TabQuestions extends BaseStep {
  constructor(props) {
    super(props);
    let {report} = props;

    // Extract embed data
    let data = {};
    for (let {name} of this.embedFormFields()) {
      data[name] = report.data[name]
    }
    this.state.embed_data = data;

    this.onChangeEmbedForm = this.onChangeEmbedForm.bind(this);
    this.onSubmitEmbedForm = this.onSubmitEmbedForm.bind(this);
    this.importQuestion = this.importQuestion.bind(this);
    this.createGlobalQuestion = this.createGlobalQuestion.bind(this);
  }

  title() {
    return "Questions";
  }

  modelName() {
    return "Question";
  }


  getSection() {
    return 'questions';
  }

  defaultItem() {
    return {}
  }


  extraItemOptions(item) {
    if (item.report_question_id) return [];

    return [{key: 'create_global', label: 'Create Global Question', callback: this.createGlobalQuestion}];
  }


  createGlobalQuestion(item) {
    if (!confirm('Are you sure?')) return;

    let url = '/admin/report_questions';
    let report_question = {text: item.text};
    request('POST', url, {report_question}).then(report_question => {
      item.report_question_id = report_question.id;
      this.submitItem(item);
    });
  }

  importQuestion(new_question) {
    let question = Object.assign({}, new_question);
    question.report_question_id = question.id;
    delete(question.id);
    delete(question.tags);
    this.submitItem(question);
  }

  changeCurrentitem(current_item) {
    if (current_item.report_question_id) {
      delete(current_item.report_question_id);
    }
    this.setState({current_item});
  }

  onSubmitEmbedForm(e) {
    let {report, onChange} = this.props;
    let {embed_data} = this.state;

    for (let {name} of this.embedFormFields()) {
      report.data[name] = embed_data[name]
    }
    onChange(report);
  }

  onChangeEmbedForm(embed_data) {
    this.setState({embed_data});
  }

  renderItem(item) {
    let imported_badge;
    if (item.report_question_id){
      imported_badge = <span className="badge">IMPORTED</span>

    }
    return (
      <div>
        <div>
          <p><Markdown text={item.text} /></p>
          {imported_badge}
        </div>
      </div>
    );
  }

  renderForm(current_item) {
    let model_name = this.modelName();
    let action, import_el;
    if (current_item.id) {
      action = 'Update';
    } else {
      action = 'Create';
    }

    let fields = [
      {name: 'text', label: 'Question', type: 'text', required: true}
    ];


    return (
      <div className="basestep_form_holder">
        <h4><b>{action} {model_name}</b></h4>
        <Form modelName={model_name} model={current_item} fields={fields} onChange={this.changeCurrentitem} onSubmit={this.submitCurrentItem} onCancel={this.cancelCurrentItem} />
      </div>
    )
  }

  embedFormFields() {
    return [
      {name: 'questions_header', label: 'Header to appear at top of questions page'},
      {name: 'questions_description', type: 'text', label: 'Text to appear underneath header'},
      {name: 'space_for_own_questions', type: 'bool', label: ' Include Space to Add Own Questions'}
    ];
  }

  renderListHeader() {
    let {report} = this.props;
    return (
      <div className="list_button_group">
        <div className="btn-group">
          {this.renderAddItemButton()}
          <SearchReportQuestions importQuestion={this.importQuestion} questions={report.data.questions} />
        </div>
        <p className="btn_side_text">Imported questions will remain linked to original question unless edited.</p>
      </div>
    )
  }

  renderListFooter() {
  }

  renderEmbedForm() {
    let {submitting, errors} = this.props;
    let {embed_data} = this.state;
    return (
      <div className="basestep_form_holder">
        <Form model={embed_data} model_name="Config" singleton submitting={submitting} fields={this.embedFormFields()} onChange={this.onChangeEmbedForm} onSubmit={this.onSubmitEmbedForm} errors={errors}/>
      </div>
    );
  }
}


class SearchReportQuestions extends Component {
  constructor(props) {
    super(props);

    this.state = {query: {}};
    this.onChange = this.onChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    state.visible_results = filterReportQuestions(props.questions, state.results);
    return state;
  }

  componentDidMount() {
    this.modalEl = $(this.refs.modal);
    this.modalEl.modal({show: false});
    this.modalEl.on('hide.bs.modal', () => {
      this.setState({show_modal: false});
    });
  }

  onChange(query) {
    this.setState({query});
  }

  search() {
    let {query} = this.state;
    if ((!query.text || query.text.length === 0) && (!query.tag_ids || query.tag_ids.length === 0)) return;

    this.setState({searching: true});
    let url = '/admin/report_questions/search';
    request('POST', url, {report_question: query}).then(results => {
      let {questions} = this.props;
      let visible_results = filterReportQuestions(questions, results);
      this.setState({results, visible_results, searching: false});
    });
  }

  select(question) {
    let {importQuestion} = this.props;
    importQuestion(question);
  }

  showModal() {
    this.setState({show_modal: true, query: {}, results: null}, () => this.modalEl.modal('show'));
  }

  closeModal() {
    this.setState({show_modal: false, query: {}}, () => this.modalEl.modal('hide'));
  }

  renderSearchResults() {
    let {searching, visible_results} = this.state;
    if (searching) return <Loading />
    if (!visible_results) return null;
    if (visible_results.length === 0) return <p>No results found</p>

    return (
      <div>
        <h4><b>Search Results</b></h4>
        {visible_results.map(question => {
          return (
            <div key={question.id} className="question_search_result">
              <div className="question_search_result_details">
                <h5>{question.text}</h5>
                <p>TAGS:
                {question.tags.map(tag => <span key={tag.id} className="question_tag">{tag.name}</span>)}
                </p>
              </div>
              <button className="btn btn-sm btn-success" onClick={e => this.select(question)}>Add Question</button>
            </div>
          )
        })}
      </div>
    )
  }

  renderSearchForm() {
    let {show_modal, query} = this.state;
    if (!show_modal) return null;

    let fields = [
      {name: 'text', focus: true, label: 'Search by Text'},
      {name: 'tag_ids', type: 'remote-select2', label: 'Search by Tags', multiple: true, src: "/admin/report_question_tags/as_options.json", search_key: ReportQuestionTagSearchKey}
    ];
    return (
      <div>
        <Form model={query} fields={fields} onChange={this.onChange} onSubmit={e => this.search()} submitLabel="Search"/>
        {this.renderSearchResults()}
      </div>
    )
  }

  renderModal() {
    let report_questions_url = '/admin/report_questions'
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="pull-left"><b>Search Questions</b> <small><a href={report_questions_url} target="_blank">Manage Global Questions</a></small></h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <div className="clearfix"></div>
            </div>
            <div className="modal-body">
              {this.renderSearchForm()}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <button className="btn btn-default" onClick={e => this.showModal()}>Import Question</button>
        {this.renderModal()}
      </>
    );
  }

}


function extractReportQuestionIds(current_questions) {
  let report_question_ids = [];
  for (let question of current_questions) {
    if (question.report_question_id) {
      report_question_ids.push(question.report_question_id);
    }
  }
  return report_question_ids;
}


function filterReportQuestions(current_questions, results) {
  if (!results) return null;
  let report_question_ids = extractReportQuestionIds(current_questions);
  return results.filter(result => report_question_ids.findIndex(id => id === result.id) === -1);
}
