import React, {Component} from 'react';
import Form from '../form';

export default class BaseStep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.changeCurrentitem = this.changeCurrentitem.bind(this);
    this.submitCurrentItem = this.submitCurrentItem.bind(this);
    this.cancelCurrentItem = this.cancelCurrentItem.bind(this);
  }

  cancelCurrentItem() {
    this.setState({current_item: undefined});
  }

  changeCurrentitem(current_item) {
    this.setState({current_item});
  }

  submitCurrentItem(e) {
    e && e.preventDefault();
    let {current_item} = this.state;
    this.submitItem(current_item);
  }


  submitItem(current_item) {
    let {report, onChange} = this.props;
    let section = this.getSection();

    if (current_item.id) {
      let index = report.data[section].findIndex(i => i.id === current_item.id);
      report.data[section][index] = current_item;
    } else {
      current_item.id = generateUUID();
      report.data[section].push(current_item);
    }

    onChange(report);
    this.setState({current_item: undefined});

  }

  moveItemUp(item) {
    let {report, onChange} = this.props;
    let section = this.getSection();
    let index = report.data[section].findIndex(i => i.id === item.id);
    if (index === 0) return;

    report.data[section].splice(index, 1);
    report.data[section].splice(index - 1, 0, item);
    onChange(report);
  }

  moveItemDown(item) {
    let {report, onChange} = this.props;
    let section = this.getSection();
    let index = report.data[section].findIndex(i => i.id === item.id);
    if (index === report.data[section].length) return;

    report.data[section].splice(index, 1);
    report.data[section].splice(index + 1, 0, item);
    onChange(report);
  }

  editItem(item) {
    let current_item = Object.assign({}, item);
    this.setState({current_item});
  }


  deleteItem(item) {
    if (!confirm('Are you sure?')) return;
    let {report, onChange} = this.props;
    let section = this.getSection();
    let index = report.data[section].findIndex(i => i.id === item.id);
    report.data[section].splice(index, 1);
    onChange(report);
  }

  addItem() {
    let current_item = this.defaultItem();
    this.setState({current_item});
  }

  renderAddItemButton() {
    let model_name = this.modelName();
    return <button className="btn btn-success add_step_btn" onClick={e => this.addItem()}>Add {model_name}</button>
  }

  renderEmbedForm() {
  }

  renderListHeader() {
    return this.renderAddItemButton();
  }

  renderListFooter() {
  }

  extraItemOptions(item) {
    return [];
  }

  renderList() {
    let {report} = this.props;
    let section = this.getSection();
    let list = report.data[section];
    return (
      <div>
        {this.renderListHeader()}
        {list.map(item => {
          let extra_item_options = this.extraItemOptions(item) || [];
          return (
            <div key={item.id} className="report_options_item">
              <div className="dropdown pull-right">
            <button className="btn btn-default btn-sm dropdown-toggle" type="button" id={item.id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">Options&nbsp;<span className="caret"></span></button>
                <ul className="dropdown-menu" aria-labelledby={item.id}>
                  <li><button className="btn btn-link" onClick={e => this.moveItemUp(item)}>Move Up</button></li>
                  <li><button className="btn btn-link" onClick={e => this.moveItemDown(item)}>Move Down</button></li>
                  <li><button className="btn btn-link" onClick={e => this.editItem(item)}>Edit</button></li>
                  <li><button className="btn btn-link" onClick={e => this.deleteItem(item)}>Delete</button></li>
                  {extra_item_options.map(({key, callback, label}) => {
                    return (
                      <li key={key}><button className="btn btn-link" onClick={e => callback(item)}>{label}</button></li>
                    )
                  })}
                </ul>
              </div>

              {this.renderItem(item)}
            </div>
          )
        })}
        {this.renderListFooter()}
      </div>
    )
  }

  renderForm(current_item) {
    let model_name = this.modelName();
    let action = current_item.id ? 'Update' : 'Create';
    return (
      <div className="basestep_form_holder">
        <h4><b>{action} {model_name}</b></h4>
        <Form modelName={model_name} model={current_item} fields={this.formFields()} onChange={this.changeCurrentitem} onSubmit={this.submitCurrentItem} onCancel={this.cancelCurrentItem} />
      </div>
    )
  }

  render() {
    let {current_item} = this.state;

    return (
      <div>
        <h2 className="config_section_header">{this.title()}</h2>
        {this.renderEmbedForm()}
        {current_item ? this.renderForm(current_item) : this.renderList()}
      </div>
    )


  }
}
