import React, {Component} from 'react';

import request from '../../request';
import Loading from '../loading';

const DEBOUNCE_TIMEOUT = 1000;

export default class AllPatients extends Component {
  constructor(props) {
    super(props);
    this.state = {patients: undefined, filter: ''}
    this.triggerChange = this.triggerChange.bind(this);
    this.fetchPatients = this.fetchPatients.bind(this);
  }

  changeFilter(e) {
    let filter = e.target.value;
    this.setState({filter}, this.triggerChange);
  }

  triggerChange() {
    this.setState({loading: true});

    if (this.pid) {
      clearTimeout(this.pid);
    }
    this.pid = setTimeout(this.fetchPatients, DEBOUNCE_TIMEOUT);
  }

  filterPatients(patients, filter) {
    let re = new RegExp(filter, "i");
    return patients.filter(patient => re.test(patient.name));
  }

  fetchPatients() {
    this.pid = undefined;

    let {filter} = this.state;
    if (!filter || filter.length < 2) return;

    this.setState({loading: true});
    let url = "/admin/active_patients/candidates?filter=" + filter;
    request('GET', url).then(patients => {
      this.setState({patients, loading: false});
    });
  }


  addActivePatient(patient) {
    let url = "/admin/active_patients";
    request("POST", url, {patient_id: patient.id}).then(r => {
      this.props.close();
    });
  }

  renderNoPatients() {
    return (
      <div id="no-patients">
        No patients found
      </div>
    )
  }

  renderPatients() {
    let {patients, loading} = this.state;

    if (loading) return <Loading />
    if (patients === undefined) return null;
    if (patients.length === 0) return this.renderNoPatients();

    return patients.map(patient => {
      return (
        <div key={patient.id} className="available-patient">
          {patient.name}
          <button className="btn" onClick={e => this.addActivePatient(patient)}><i className="fa fa-plus-circle" /></button>
        </div>
      );
    })
  }


  render() {
    let {filter} = this.state;
    let {close} = this.props;

    return (
      <div className="inner_widget_container">
        <button className="btn btn-sm btn-default" onClick={close}><i className="fa fa-chevron-left" /> Go Back</button>
        <h4 className="patient_widget_header_text patient_widget_search_header">Search for Patient</h4>
        <div className="form-group">
          <input type="text" className="form-control" placeholder="Enter the patient name" value={filter} onChange={e => this.changeFilter(e)}/>
        </div>
        {this.renderPatients()}
      </div>
    )
  }
}
