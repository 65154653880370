import request from '../../request';

export default class OptionsCache {
  constructor(props) {
    let {source, onChange} = props;
    this.source = source;
    this.onChange = onChange;
    this.cache = GLOBAL_CACHE[source]
  }

  get(record_id) {
    if (!this.exists(record_id)) {
      this.preload([record_id]);
      return {};
    }
    return this.cache.records[record_id];
  }


  preload(record_ids) {
    record_ids = record_ids.filter(id => !this.exists(id));
    if (record_ids.length === 0) return;

    this.cache.queue.push({record_ids, listener: this.onChange});
    if (!this.cache.loading && !this.cache.pid) {
      this.cache.pid = setTimeout(() => fetchRecords(this.source), TIMEOUT);
    }
  }

  exists(record_id) {
    return (this.cache.records[''+record_id] !== undefined);
  }
}

OptionsCache.get = function(source, id) {
  let cache = GLOBAL_CACHE[source];
  return cache.records[''+id] || {_empty: true};
}

OptionsCache.initializeCache = function(cache) {
  for (let src of Object.keys(cache)) {
    for (let record of cache[src]) {
      if (!GLOBAL_CACHE[src].records[record.id]) {
        GLOBAL_CACHE[src].records[record.id] = record;
      }
    }
  }
}


const TIMEOUT = 250;
const GLOBAL_CACHE = {};
const SOURCES = {
  symptoms: '/admin/side_effects/as_options.json',
  interventions: '/admin/interventions/as_options.json',
  genes: '/admin/genes/as_options.json',
  findings: '/admin/findings/as_options.json',
  organs: '/admin/organs/as_options.json',
  patient_cancer_types: '/admin/patient_cancer_types/as_options.json',
  diagnostics: '/admin/diagnostics/as_options.json',
  comorbid_conditions: '/admin/comorbid_conditions/as_options.json'
};

for (let source of Object.keys(SOURCES)) {
  GLOBAL_CACHE[source] = { records: {}, loading: false, queue: []};
}

function fetchRecords(source) {
  let cache = GLOBAL_CACHE[source];
  cache.pid = undefined;

  if (cache.queue.length === 0) return;

  let ids = [];
  let listeners = [];
  for (let {record_ids, listener} of cache.queue) {
    ids = ids.concat(record_ids);
    listeners.push(listener)
  }
  cache.queue = [];
  cache.loading = true;

  let url = SOURCES[source] + '?ids=' + ids.unique().join(',');
  request('GET', url).then(resp => {
    // Some times we ask for records that was already deleted from the server
    // to prevent infinite request, we'll first set all the records to null
    for (let id of ids) {
      cache.records[id] = {_empty: true};
    }

    // Now we'll replace records returned by the server
    for (let result of resp.results) {
      cache.records[result.id] = result;
    }
    for (let listener of listeners) {
      listener();
    }

    cache.loading = false;
  });
}
