import React, {Component} from 'react';
import Form from './form';
import Loading from './loading';
import request from '../request';

export default class DiagnosticTestLinkForm extends Component {
  constructor(props) {
    super(props);
    let {diagnostic_test} = props;
    this.state = {diagnostic_test, current: {}};
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  submit(diagnostic_test) {
    this.setState({submitting: true});
    let url = '/admin/diagnostic_tests/' + diagnostic_test.id;
    request('PUT', url, {diagnostic_test}).then(diagnostic_test => {
      this.setState({diagnostic_test, submitting: false});
    });

  }

  onSubmit(e) {
    e.preventDefault();
    let {current, diagnostic_test} = this.state;
    if (current.id) {
      let index = diagnostic_test.links.findIndex(l => l.id === current.id);
      diagnostic_test.links[index] = current;
    } else {
      current.id = generateUUID();
      diagnostic_test.links.push(current);
    }

    this.setState({current:{}});
    this.submit(diagnostic_test);
  }

  deleteLink(link) {
    if (!confirm('Are you sure?')) return;

    let {diagnostic_test} = this.state;
    let index = diagnostic_test.links.findIndex(l => l.id === link.id);
    diagnostic_test.links.splice(index, 1);
    this.submit(diagnostic_test);
  }


  onChange(current) {
    this.setState({current});
  }

  editLink(current) {
    this.setState({current});
  }

  cancel() {
    this.setState({current: {}});
  }


  renderForm() {
    let {current, submitting} = this.state;

    let fields = [
      {name: 'name', required: true},
      {name: 'url', required: true}
    ];

    let cancelCallback;
    if (current.id) {
      cancelCallback = e => this.cancel();
    }

    return <Form model={current} model_name="Link" fields={fields} onChange={this.onChange} onSubmit={this.onSubmit} submitting={submitting} onCancel={cancelCallback} />
  }

  renderLink(link) {
    return (
      <li className="list-group-item" key={link.id}>
        <p><strong>{link.name}</strong></p>
        <p><a href={link.url} target="_blank">{link.url}</a></p>

        <button onClick={e => this.editLink(link)} className="btn btn-sm btn-default">Edit</button>
        <button onClick={e => this.deleteLink(link)} className="btn btn-sm btn-danger">Delete</button>

      </li>
    );
  }

  renderList() {
    let {diagnostic_test} = this.state;
    return (
      <div id="links_list">
        <ul className="list-group">
          {diagnostic_test.links.map(link => this.renderLink(link))}
        </ul>
      </div>
    );
  }

  render() {
    return (
      <div className="well">
        <div id="links">
          <h3>Links</h3>
          <hr/>
          {this.renderForm()}
          {this.renderList()}
        </div>
      </div>
    );
  }
}
