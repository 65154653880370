import React, {Component} from 'react';
import {BaseEventEditor, BaseEventViewer} from './base_event';
import {RelatedToOptions} from '../../../constants.js.erb';

class MedSequencingReportEditor extends BaseEventEditor {
  formFields() {
    let {patient} = this.props;
    return formFields(patient);
  }
}


class MedSequencingReportViewer extends BaseEventViewer {
  formFields() {
    let {patient} = this.props;
    return formFields(patient);
  }
}


export default {
  viewer: MedSequencingReportViewer,
  editor: MedSequencingReportEditor
}


function formFields(patient) {
  let contacts = patient.contacts.map(c => {return {id: c.id, text: c.name}});
  let medical_team_members = patient.medical_team_members.map(m => {return {id: m.id, text: m.person.name}});

  return [
    {name: 'date', type: 'date', required: true, inSummary: true, label: 'Date Report was Received by Sagely Health'},
    {name: 'date_estimated', label: 'Date Estimated?', type: 'bool'},
    {name: 'related_to', type: 'select', collection: RelatedToOptions},
    {name: 'test_name', inSummary: true, label: 'Name of Test Completed'},
    {name: 'test_type', type: 'select', collection: TEST_TYPES},
    {name: 'specimen_type', type: 'select', collection: ['Slide', 'Block', 'Liquid', 'Blood', 'FFPE']},
    {name: 'report_diagnosis_or_tumor_type', label: 'Report Diagnosis / Tumor Type'},
    {name: 'specimen_collection_date', label: 'Date of Specimen Collection', type: 'date'},
    {name: 'sample_quality_notes', hint: 'Tumor content percentage i.e insufficient quantities', type: 'text'},
    {name: 'specimen_received_date', label: 'Date Specimen was Received', type: 'date'},


    {name: 'user', inSummary: true, label: 'To whom or how was this report sent to Sagely Health?'},
    {name: 'peer_type', type: 'select', collection: ['patient', 'contact', 'medical_team', 'other'], labelTransform: 'titleize', label: 'Who provided this report to Sagely Health?'},
    {name: 'contact_id', type: 'select2', collection: contacts, visible: e => e.peer_type === 'contact', idField: 'id', labelField: 'text', hint: 'To appear here, contacts must be added to the patient.'},
    {name: 'medical_team_member_id', type: 'select2', collection: medical_team_members, visible: e => e.peer_type === 'medical_team', idField: 'id', labelField: 'text', hint: 'To appear here, medical team members must be added to the patient.'},
    {name: 'description', type: 'text', inSummary: true}
  ];
}

const TEST_TYPES = ['Next-Gen Sequencing (NGS)', 'Whole Exome Sequencing (WES)', 'Whole Genome Sequencing (WGS)', 'RNAseq (RNA analysis) (RNA)', 'Germline Analysis (GL)', 'Liquid Tumor Biopsy (LTB)', 'Circulating Tumor Cell Analysis (CTCA)'];
