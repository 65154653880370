import React, {Component} from 'react';
import Form from '../form';
import SavePanel from './save_panel';
import NotesWidget from './notes_widget';
import {PatientCharacteristicTagSearchKey, ECOG} from '../../constants.js.erb';
import request from '../../request';

export default class PatientDetails extends Component {
  constructor(props) {
    super(props);
    let {population} = props;
    let patient_details = setupDefaults(population.data);
    this.state = {patient_details};
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    window.addEventListener('population-save-request', this.submit, false);
  }

  componentWillUnmount() {
    window.removeEventListener('population-save-event', this.submit, false);
  }

  submit() {
    this.setState({submitting: true})
    let {population, onChange} = this.props;
    let {patient_details} = this.state;
    population.data.patient_details = patient_details;
    onChange(population.data);
  }

  onChange(patient_details) {
    this.setState({patient_details}, this.submit);
  }

  onChangeSection(id, data) {
    let {patient_details} = this.state;
    patient_details[id] = data;
    this.setState({patient_details}, this.submit);
  }

  onChangeGeneralRequirementExclusions(exclusions) {
    let {patient_details} = this.state;
    patient_details.general_requirements.unselected = exclusions;
    this.setState({patient_details}, this.submit);
  }

  renderSavePanel() {
    let icon, text, style, action;
    let {submitting, save_success, save_error} = this.state;
    return <SavePanel submitting={submitting} save_success={save_success} save_error={save_error} onClick={e => this.submit()}/>
  }

  renderGeneral() {
    let {patient_details} = this.state;
    let fields = [
      {name: 'life_expentacy', label: 'Life Expectancy (in months)', type: 'number'},
      {name: 'health_status', label: 'Performance Status (ECOG or Zubrod / Karnofsky / Lansky)', type: 'checkboxes', collection: ECOG},
    ]

    return <Form model={patient_details} fields={fields} onChange={this.onChange} />
  }


  renderAditionalDetails() {
    let {patient_details} = this.state;
    let fields = [
      {name: 'required', label: 'Required (Must have)', type: 'slektr', multiple: true, src: "/admin/patient_characteristics/as_options.json", search_key: PatientCharacteristicTagSearchKey},
      {name: 'prohibited', label: "Exclusion (Can't have)", type: 'slektr', multiple: true, src: "/admin/patient_characteristics/as_options.json", search_key: PatientCharacteristicTagSearchKey},
    ];

    return (
      <div>
        <h3><b>Additional Requirements</b></h3>
        <hr/>
        <Form model={patient_details.aditional_requirements} fields={fields} onChange={s => this.onChangeSection('aditional_requirements', s)} />
      </div>
    );
  }


  renderGeneralRequirements() {
    let {patient_details} = this.state;
    return (
      <div>
        <h3><b>General Requirements</b></h3>
        <p>Check off any that do not apply.</p>
        <hr/>
        <GeneralRequirementsEditor exclusions={patient_details.general_requirements.unselected} onChange={e => this.onChangeGeneralRequirementExclusions(e)} />
      </div>
    );

  }

  render() {
    let {patient_details} = this.state;
    return (
      <div>
        <div className="well label-spacing select-100">
          <div className="row">
            <div className="col-md-6">
              {this.renderGeneral()}
              {this.renderAditionalDetails()}
              {this.renderGeneralRequirements()}
            </div>
            <div className="col-md-6">
              <NotesWidget data={patient_details} onChange={this.onChange} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function setupDefaults(data) {
  if (!data.patient_details) data.patient_details = {};

  if (!data.patient_details.notes) data.patient_details.notes = [];
  if (!data.patient_details.health_status) data.patient_details.health_status = {};
  if (!data.patient_details.aditional_requirements) data.patient_details.aditional_requirements = {required: [], exclusion: []};
  if (!data.patient_details.general_requirements) data.patient_details.general_requirements = {required: [], exclusion: [], unselected: []};
  if (!data.patient_details.general_requirements.unselected) data.patient_details.general_requirements.unselected = [];

  return data.patient_details;
}


class GeneralRequirementsEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchGeneralRequirements();
  }

  toggleItem(item) {
    let {exclusions, onChange} = this.props;
    let index = exclusions.findIndex(id => id === item.id);
    if (index === -1) {
      exclusions.push(item.id);
    } else {
      exclusions.splice(index, 1);
    }

    onChange(exclusions);
  }

  fetchGeneralRequirements() {
    request('GET', '/admin/general_requirements/as_options').then(({results}) => {
      let required = results.filter(r => r.type == "required");
      let not_required = results.filter(r => r.type == "not_required");

      this.setState({required, not_required, initialized: true});
    });
  }

  renderItems(items) {
    let {exclusions} = this.props;
    return (
      <ul className="general_requirements_list">
        {items.map(item => {
          let className = 'btn btn-link';
          let excluded = exclusions.findIndex(id => id === item.id) !== -1;
          if (excluded) {
            className += ' general_requirement_excluded';
          }

          return (
            <li key={item.id}>
              <button className={className} onClick={e => this.toggleItem(item)}>{item.text}</button>
              <p className="general_requirements_desc">{item.title}</p>
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    let {initialized, required, not_required} = this.state;
    if (!initialized) return null;

    return (
      <div>
        <h5><b>Required (Must have)</b></h5>
        {this.renderItems(required)}
        <h5><b>Exclusion (Can not have)</b></h5>
        {this.renderItems(not_required)}
      </div>
    );
  }
}
