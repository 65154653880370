import jquery from 'jquery';
export default {
  setup: () => {
    jquery( document ).ready(function() {
      let buttons = jquery('button.quick-add-person');
      for (let button of buttons) {
        button = jquery(button);
        button.on('click', e => {
          let data = button.data('person');
          let target = button.data('target');
          let event = new CustomEvent('quick-add-person', {detail: {data}});
          if (target) {
            let target_el = jquery('#' + target);
            target_el[0] && target_el[0].dispatchEvent(event);
          } else {
            window.dispatchEvent(event);
          }
        });
      }
    });
  }
}
