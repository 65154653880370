import React, {Component} from 'react';
import {LocationForm} from './locations';
import {LocationCancerCenters} from './location_cancer_centers';

export default class EditLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onCancerCentersChange = this.onCancerCentersChange.bind(this);
  }

  onCancerCentersChange(cancer_centers) {
    let people_widget_ctx;
    if (cancer_centers.length === 1) {
      people_widget_ctx = {defaults: {cancer_center_ids: [cancer_centers[0].id]}};
    }
    this.setState({cancer_centers, people_widget_ctx});
  }

  render() {
    let {trial_id, location, people} = this.props;
    let {people_widget_ctx} = this.state;

    return (
      <div>
        <LocationForm location={location} trial_id={trial_id} people={people} people_widget_ctx={people_widget_ctx} />
        <LocationCancerCenters trial_id={trial_id} trial_location_id={location.id} onCancerCentersChange={this.onCancerCentersChange} />
      </div>
    );
  }
}
