import React, {Component} from 'react';
import request from '../../request';

export default class CollectionsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {only_selected: true, collection_id: ''};
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    this.el = $(this.refs.modal_container);
    this.el.modal({show: true});
    this.el.on('hidden.bs.modal', e => {
      this.close();
    });
  }

  submit() {
    let {patient, selection, results} = this.props;
    let {collection_id, only_selected} = this.state;
    if (!collection_id) return;
    let trial_ids;
    if (only_selected) {
      trial_ids = selection;
    } else {
      trial_ids = results.map(r => r.trial.id);
    }

    let url = "/admin/patients/" + patient.id + "/collections/" + collection_id + "/trials/add_all";
    request('PUT', url, {trial_ids}).then(({success, collection}) => {
      if (success) {
        let {collections, onCollectionsChange} = this.props;
        let index = collections.findIndex(c => c.id === collection.id);
        collections[index] = collection;
        onCollectionsChange(collections);
        this.close();
      }
    });
  }


  submitNewCollectionForm(e) {
    e.preventDefault();
    let {collection_name} = this.state;
    if (collection_name.length === 0) return;
    let {patient, collections, onCollectionsChange} = this.props;
    let url = "/admin/patients/" + patient.id + "/collections"
    request('POST', url, {collection: {name: collection_name}}).then(({success, collection}) => {
      if (success) {
        collections.push(collection);
        onCollectionsChange(collections);
        this.setState({show_form: false, collection_id: collection.id});
      }
    });
  }

  close() {
    this.el.modal('hide');
    let {onClose} = this.props;
    if (onClose) onClose();
  }

  createCollection() {
    let {patient} = this.props;
    this.setState({show_form: true, collection_name: ''});


  }

  change(e) {
    let {name, value} = e.target;
    let change = {[name]: value}
    this.setState(change);
  }

  changeCheckbox(e) {
    let {name, checked} = e.target;
    let change = {[name]: checked}
    this.setState(change);
  }

  closeForm(e) {
    e.preventDefault();
    this.setState({show_form: false});
  }

  renderForm() {
    let {collection_name} = this.state;
    return (
      <div>
        <div className="modal-body">
          <form onSubmit={e => this.submitNewCollectionForm(e)}>
            <div>
              <label>Collection Name</label>
              <input type="text" className="form-control" name="collection_name" value={collection_name} onChange={e => this.change(e)} />
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button className="btn btn-default" onClick={e => this.closeForm(e)}>Cancel</button>
          <button className="btn btn-primary" onClick={e => this.submitNewCollectionForm(e)}>Create Collection</button>
        </div>
      </div>
    )
  }

  renderSelect() {
    let {collections} = this.props;
    let {collection_id, only_selected} = this.state;
    return (
      <div>
        <div className="modal-body">
          <div className="well results_to_collection">
            <label>
              <input type="checkbox" name="only_selected" value={only_selected} checked={only_selected} onChange={e => this.changeCheckbox(e)} />
              <span className="checkbox_text">Only Selected Results</span>
            </label>
          </div>
          <div>
            <label>Select a collection</label>
            <select className="form-control" name="collection_id" value={collection_id} onChange={e => this.change(e)}>
              <option value="" disabled="disabled">Select a collection</option>
              {collections.map(collection => {
                return (
                  <option key={collection.id} value={collection.id}>{collection.name}</option>
                );
              })}
            </select>
            <hr/>
            <button className="btn btn-default" onClick={e => this.createCollection()}>Create New Collection</button>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-default" onClick={this.close}>Close</button>
          <button type="submit" className="btn btn-primary" onClick={e => this.submit()}>Submit</button>
        </div>
      </div>
    )
  }

  render() {
    let {show_form} = this.state;
    let content = show_form ? this.renderForm() : this.renderSelect();

    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div id="saved_searches_modal" className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={this.close} aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title"><b>Add results to collection</b></h4>
            </div>
            {content}
          </div>
        </div>
      </div>
    );
  }
}
