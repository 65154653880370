import React, {Component} from 'react';
import {EventPreview} from './event_modal';
import ChangelogViewer from './changelog_viewer';
import Form from '../form';

export default class ReviewChangesModal extends Component {
  constructor(props) {
    super(props);
    let event = props.event || {};
    this.state = {event};
  }

  submit() {
    this.props.onSubmit();
  }

  reload() {
    this.props.onReload();
  }

  cancel() {
    this.props.onCancel();
  }


  render() {
    let {patient, event, log} = this.props;
    return (
      <div className="jt-event-outer-holder">
        <button type="button" className="btn btn-default" onClick={e => this.cancel()}>&lt; Close, and continue editing event</button>
        <div className="jt-event-inner-holder">
          <div>
            <EventPreview patient={patient} event={event} />
          </div>
          <div className="event-review-changes">
            <p><b>Changes</b></p>
            <ChangelogViewer fields_changelog={log} />
            <hr/>
            <div className="btn-group pull-right">
              <button type="button" className="btn btn-default" onClick={e => this.cancel()}>&lt; Continue Editing</button>
              <button type="button" className="btn btn-success" onClick={e => this.submit()}>Submit Changes</button>
            </div>
            <button type="button" className="btn btn-danger pull-left" onClick={e => this.reload()}>Delete Event</button>
          <div className="clearfix"></div>
          </div>
        </div>
      </div>
    );
  }
}
