import React, {Component} from 'react';
import ItemsCollection from './items_collection';
import NeestedRecordPicker from './neested_record_picker';
import {GroupTypes, THERAPY_TYPE_THERAPY} from '../constants.js.erb';

export default class GroupsContainer extends Component {
  constructor(props) {
    super(props);
    this.onGroupChange = this.onGroupChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.moveUp = this.moveUp.bind(this);
    this.moveDown = this.moveDown.bind(this);
    this.duplicateGroup = this.duplicateGroup.bind(this);
  }

  addGroup() {
    let {groups, onChange} = this.props;
    let group = defaultGroup();
    groups.push(group);
    onChange && onChange(groups)
  }

  duplicateGroup(original) {
    let {groups, onChange} = this.props;

    let index = groups.findIndex(g => g.id === original.id);
    // TODO: find a better way
    let group = JSON.parse(JSON.stringify(original));
    group.id = generateUUID();
    groups.splice(index + 1, 0, group);
    onChange && onChange(groups)
  }

  onGroupChange(group) {
    let {groups, onChange} = this.props;
    let index = groups.findIndex(g => g.id === group.id);
    groups[index] = group;
    onChange && onChange(groups)
  }

  onDelete(group) {
    if (!confirm('Are you sure you want to delete this group?')) return;
    let {groups, onChange} = this.props;
    let index = groups.findIndex(g => g.id === group.id);
    groups.splice(index, 1);
    onChange && onChange(groups)
  }

  moveUp(group) {
    let {groups, onChange} = this.props;
    let index = groups.findIndex(g => g.id === group.id);
    if (index === 0) return;

    groups.splice(index, 1);
    groups.splice(index - 1, 0, group);
    onChange && onChange(groups)
  }

  moveDown(group) {
    let {groups, onChange} = this.props;
    let index = groups.findIndex(g => g.id === group.id);
    if (index === groups.length -1) return;

    groups.splice(index, 1);
    groups.splice(index + 1, 0, group);
    onChange && onChange(groups)
  }

  collapseAll() {
    window.dispatchEvent(new Event('collapse_all_groups'));
  }

  expandAll() {
    window.dispatchEvent(new Event('expand_all_groups'));
  }

  renderGroup(group, index) {
    let {currentAdmin, closeable, trial, createLinks, changelog, reviewItems} = this.props;
    let {onGroupChange, onDelete, moveUp, moveDown, duplicateGroup} = this;
    let props = {index, group, trial, onDelete, moveUp, moveDown, currentAdmin, closeable, createLinks, changelog, reviewItems};
    props.onChange = onGroupChange;
    props.onDuplicate = duplicateGroup;
    return (
      <Group key={group.id} {...props} />
    )
  }


  render() {
    let {groups} = this.props;
    return (
      <div id="groups">
        <div className="btn-group">
          <button className="btn btn-default btn-sm" onClick={e => this.collapseAll()}>Collapse All</button>
          <button className="btn btn-default btn-sm" onClick={e => this.expandAll()}>Expand All</button>
        </div>

        {groups.map((g, i) => this.renderGroup(g, i))}
        <div className="form-group">
          <button className="btn btn-primary" onClick={e => this.addGroup()}>Add Group</button>
        </div>
      </div>
    )
  }
}


class Group extends Component {
  constructor(props) {
    super(props);
    let group = Object.assign({}, props.group)
    this.state = {group, collapsed: false}
    this.onChange = this.onChange.bind(this);
    this.onChangeItems = this.onChangeItems.bind(this);
    this.onChangeClosed = this.onChangeClosed.bind(this);
    this.replaceUnknownItem = this.replaceUnknownItem.bind(this);
    this.expand = this.expand.bind(this);
    this.collapse = this.collapse.bind(this);
  }

  componentDidMount() {
    window.addEventListener('collapse_all_groups', this.collapse, false);
    window.addEventListener('expand_all_groups', this.expand, false);
  }

  componentWillUnmount() {
    window.removeEventListener('collapse_all_groups', this.collapse, false);
    window.removeEventListener('expand_all_groups', this.expand, false);
  }

  expand() {
    this.setState({collapsed: false});
  }

  collapse() {
    this.setState({collapsed: true});
  }

  toggleCollapsed() {
    let {collapsed} = this.state;
    this.setState({collapsed: !collapsed});
  }

  onChange(e) {
    let {name, value} = e.target;
    let {onChange} = this.props;
    let {group} = this.state;
    group[name] = value;
    onChange && onChange(group);
  }

  onChangeItems(items, action=false) {
    let {group} = this.state;
    let {onChange} = this.props;
    group.items = items
    if (action) {
      this.addChangelog(group, action);
    }
    onChange && onChange(group);
  }

  addChangelog(group, action) {
    let {changelog, currentAdmin} = this.props;
    if (!changelog) return group;

    action.date =  new Date();
    action.admin_id = currentAdmin.id;

    if (!group.changelog) group.changelog = [];
    group.changelog.push(action);

    return group;
  }

  replaceUnknownItem(unknown_item, new_item=false) {
    let {group} = this.state;
    let {onChange, changelog, currentAdmin} = this.props;

    // Delete the unknown item
    let index = group.unknown_items.findIndex(i => i.id === unknown_item.id);
    group.unknown_items.splice(index, 1);


    // Add the new item if present
    if (new_item) {
      group.items.push(new_item);
    }

    // Add the changelog
    if (changelog) {
      let changelog_action = {item: unknown_item, action: 'delete'};
      if (new_item) {
        changelog_action.action = 'replace';
        changelog_action.new_item = new_item;
      }
      group = this.addChangelog(group, changelog_action);
    }

    onChange && onChange(group);
  }

  onChangeClosed(e) {
    let {checked} = e.target;
    let {onChange, currentAdmin} = this.props;
    let {group} = this.state;
    if (checked) {
      group.closed = true;
      group.closed_at = new Date();
      group.closed_by = currentAdmin.id;
      group.closed_by_name = currentAdmin.name;
    } else {
      group.closed = false;
      delete(group.closed_at);
      delete(group.closed_by);
      delete(group.closed_by_name);
    }
    onChange && onChange(group);
  }

  onChangeFollowOn(follow_on) {
    let {onChange} = this.props;
    let {group} = this.state;
    let index = group.follow_on.findIndex(f => f.id === follow_on.id)
    group.follow_on[index] = follow_on;
    onChange && onChange(group);
  }

  addFollowOn() {
    let {onChange} = this.props;
    let {group} = this.state;
    let follow_on = defaultFollowOn();
    group.follow_on.push(follow_on);
    onChange && onChange(group);
  }

  deleteFollowOn(follow_on) {
    if (!confirm('Are you sure you want to delete this follow on group?')) return;
    let {onChange} = this.props;
    let {group} = this.state;
    let index = group.follow_on.findIndex(f => f.id === follow_on.id)
    group.follow_on.splice(index, 1);
    onChange && onChange(group);
  }

  renderGroupClosed(group) {
    if (!this.props.closeable) return null;
    return (
      <div className="checkbox">
        <label className="control-label">
          <input type="checkbox" name="closed" value={group.closed} checked={group.closed} onChange={this.onChangeClosed} />
          Group Closed
        </label>
        {this.renderGroupClosedInfo(group)}
      </div>
    );
  }

  renderGroupClosedInfo(group) {
    if (!group.closed) return null;
    let date = moment(group.closed_at).format('LL')
    return (
      <p className='help-block'>Marked closed by {group.closed_by_name} as {date}</p>
    )
  }


  renderGroupTypeSelect(group) {
    let {trial} = this.props;
    if (trial && trial.therapy_type === THERAPY_TYPE_THERAPY) return null;

    return (
      <div className="form-group">
        <select className="form-control" name="type" value={group.type} onChange={this.onChange}>
          {Object.keys(GroupTypes).map(t => <option key={t} value={t}>{GroupTypes[t]}</option>)}
        </select>
      </div>

    );
  }

  renderCollapsedContent(group) {
    return (
      <div className="collapsed-content">
        {group.description}
      </div>
    )
  }

  renderGroup(group) {
    let {reviewItems, createLinks} = this.props;

    return (
      <div>
        {this.renderGroupTypeSelect(group)}
        <br/>

        <ItemsCollection inputLabel="Add Interventions, Diagnostics, Combination, MOA" items={group.items} onChange={this.onChangeItems} itemLabels sortableItems createLinks={createLinks} reviewItems={reviewItems}  destination='group' candidatesDecorator={ItemsCollection.MOAFamilyDecorator} />
        <UnknownItemsCollection unknown_items={group.unknown_items} items={group.items} onReplace={this.replaceUnknownItem} />

        {group.follow_on.map(f  => <FollowOn key={f.id} data={f} onChange={f => this.onChangeFollowOn(f)} onDelete={e => this.deleteFollowOn(f)} createLinks={createLinks} reviewItems={reviewItems} />)}
        <button className="btn btn-success btn-sm follow-on-btn" onClick={e => this.addFollowOn()}>Add Follow on</button>
        <br/>
        <br/>
        <div className="form-group">
          <label className="control-label">Group Description / Modifier</label>
          <textarea className="form-control resizable" name="description" value={group.description} onChange={this.onChange} />
        </div>
        {this.renderGroupClosed(group)}

      </div>
    );
  }

  render() {
    let {index, onDelete, onDuplicate, moveUp, moveDown} = this.props;
    let {group, collapsed} = this.state;

    let content, collapse_icon, collapse_title;
    if (collapsed) {
      content = this.renderCollapsedContent(group)
      collapse_icon = "fa fa-square-plus";
      collapse_title="Expand";
    } else {
      content = this.renderGroup(group);
      collapse_icon = "fa fa-square-minus";
      collapse_title="Collapse";
    }

    return(
      <div className="group">
        <div className="btn-group pull-right group-action-buttons">
          <button title="Delete" onClick={e => onDelete(group)}><i className="fa fa-times"></i></button>
          <button title="Move Up" onClick={e => moveUp(group)}><i className="fa fa-arrow-up"></i></button>
          <button title="Move Down" onClick={e => moveDown(group)}><i className="fa fa-arrow-down"></i></button>
          <button title="Duplicate"onClick={e => onDuplicate(group)}><i className="fa fa-copy"></i></button>
        </div>
        <h4 title={collapse_title} className="group-header clickable" onClick={e => this.toggleCollapsed()}>
        <span className={collapse_icon}></span> Group {index + 1}
        </h4>

        {content}

      </div>
    )
  }
}


class FollowOn extends Component {
  constructor(props) {
    super(props)
    this.onChangeItems = this.onChangeItems.bind(this);
  }

  onChangeItems(items, action=false) {
    let {data, onChange} = this.props;
    data.items = items
    onChange && onChange(data, action);
  }

  delete() {
    let {onDelete} = this.props;
    onDelete && onDelete();
  }

  render() {
    let {data, createLinks, reviewItems} = this.props;
    return (
      <div className="follow-on">
        <button onClick={e => this.delete()} className="btn btn-link pull-right"><i className="fa fa-trash" /></button>
        <h4 className="follow-on-header">Followed by:</h4>
        <ItemsCollection inputLabel="Add Interventions, Diagnostics, Combination, MOA" items={data.items} onChange={this.onChangeItems} itemLabels sortableItems createLinks={createLinks} reviewItems={reviewItems}  destination='group' candidatesDecorator={ItemsCollection.MOAFamilyDecorator} />
      </div>
    )
  }
}

class UnknownItemsCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  delete(item) {
    if (!confirm('Are you sure?')) return;
    let {onReplace} = this.props;
    onReplace(item);
  }

  replace(new_item) {
    let {onReplace} = this.props;
    let {selected_unknown_item} = this.state;
    onReplace(selected_unknown_item, new_item);
    this.closeModal();
  }

  componentDidMount() {
    $(this.refs.modal_container).modal({show: false});
    $(this.refs.modal_container).on('hide.bs.modal', () => {
      this.setState({show_modal: false, selected_unknown_item: undefined});
    });
  }

  showModal(selected_unknown_item) {
    $(this.refs.modal_container).modal('show');
    this.setState({show_modal: true, selected_unknown_item});
  }

  closeModal() {
    $(this.refs.modal_container).modal('hide');
  }

  renderContent() {
    let {items} = this.props;
    let {show_modal, selected_unknown_item} = this.state;
    if (!show_modal) return null;

    return (
      <div>
        <h3>{selected_unknown_item.type}: {selected_unknown_item.name}</h3>
        <NeestedRecordPicker callback={i => this.replace(i)} items={items} destination='group' inputLabel="Add Interventions, Diagnostics, Combination, MOA" createLinks/>
      </div>
    );

  }

  renderModal() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">Replace Unknown Item</h4>
            </div>
            <div className="modal-body">
              {this.renderContent()}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderItem(item) {
    return (
      <div className="group-item group-item-unknown" key={item.id}>
        <div className="btn-group pull-right group-action-buttons">
          <button title="Search" onClick={e => this.showModal(item)}><i className="fa fa-search"></i></button>
          <button title="Delete" onClick={e => this.delete(item)}><i className="fa fa-trash"></i></button>
        </div>
        <p className="group-item-type">*{item.type}</p>

        <p><strong>{item.name}</strong></p>
        <div>{item.label}</div>
      </div>
    );
  }

  render() {
    let {unknown_items} = this.props;
    if (!unknown_items) return null;

    return (
      <div className="group-items">
        {unknown_items.map(i => this.renderItem(i))}
        {this.renderModal()}
      </div>
    );
  }
}


const defaultGroup = () => {
  let id = generateUUID();
  let type = 'investigational_or_expansion';
  return {id, type, description: "", closed: false, items: [], follow_on: []}
}

const defaultFollowOn = () => {
  let id = generateUUID();
  return {id, items: []}
};
