import React, {Component} from 'react';
import Form from '../form';
import SavePanel from './save_panel';
import NotesWidget from './notes_widget';
import request from '../../request';
import {GeneSearchKey} from '../../constants.js.erb';
import Select2 from '../select2';

export default class GenesMarkers extends Component {
  constructor(props) {
    super(props);
    let {population} = props;
    let genes_markers = setupDefaults(population.data);
    let fake_genes_markers = buildFakeData(genes_markers);
    this.state = {fake_genes_markers};

    this.submit = this.submit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.resetSaveState = this.resetSaveState.bind(this);
  }

  componentDidMount() {
    window.addEventListener('population-save-request', this.submit, false);
  }

  componentWillUnmount() {
    window.removeEventListener('population-save-event', this.submit, false);
  }


  submit() {
    this.setState({submitting: true})
    let {population, onChange} = this.props;
    let {fake_genes_markers} = this.state;
    let genes_markers = buildFromFake(fake_genes_markers);
    // let params = {genes_markers, updateable_data: {description: '', data: {section: 'genes_markers'}}}
    population.data.genes_markers = genes_markers;
    onChange(population.data);
  }

  resetSaveState() {
    this.setState({save_error: false, save_success: false})
  }


  onChange(fake_genes_markers) {
    this.setState({fake_genes_markers}, this.submit);
  }


  renderSavePanel() {
    let icon, text, style, action;
    let {submitting, save_success, save_error} = this.state;
    return <SavePanel submitting={submitting} save_success={save_success} save_error={save_error} onClick={e => this.submit()}/>
  }

  renderForm() {
    let {reseteable} = this.props;
    let {fake_genes_markers} = this.state;
    let select2_opt = {type: 'slektr', multiple: true, src: "/admin/diagnostics_and_genes/as_extended_options.json", search_key: GeneSearchKey};

    let fields = [
      {name: 'required', label: 'Genes and Diagnostics Required', ...select2_opt},
      {name: 'required_validation', type: 'radio', reseteable, collection: REQUIRED_OPTIONS},
      {name: 'prohibited', label: 'Genes and Diagnostics Prohibited', ...select2_opt},
      {name: 'prohibited_validation', type: 'radio', reseteable, collection: PROHIBITED_OPTIONS}
    ];

    return (
      <div>
        <Form prefix="req_int" model={fake_genes_markers} fields={fields} onChange={this.onChange} />
      </div>
    );
  }

  render() {
    let {fake_genes_markers} = this.state;
    return (
      <div>
        <div className="well label-spacing select-100">
          <div className="row">
            <div className="col-md-6">
              {this.renderForm()}
            </div>
            <div className="col-md-6">
              <NotesWidget data={fake_genes_markers} onChange={this.onChange} />
            </div>
          </div>
        </div>
      </div>
    );
  }

}

function setupDefaults(data) {
  let P = data.genes_markers || {};
  if (!P.notes) P.notes = [];
  return P;
}

const FAKE_FIELDS = ["required", "prohibited"];

function buildFakeData(genes_markers) {
  let fake = {}
  for (let key of Object.keys(genes_markers)) {
    if (FAKE_FIELDS.indexOf(key) === -1) {
      fake[key] = genes_markers[key];
    } else {
      fake[key] = buildFakeItems(genes_markers[key]);

    }
  }
  return fake;
}

function buildFakeItems(data) {
  let fake = [];
  for (let item of data) {
    if (item.any) {
      fake.push("" + item.diagnostics_and_gene_id)
    }

    if(item.gene_options && item.gene_options.length > 0) {
      for (let opt of item.gene_options) {
        fake.push(`${item.diagnostics_and_gene_id}-${opt}`)
      }
    }

    if(item.results) {
      for (let result of item.results) {
        fake.push(`${item.diagnostics_and_gene_id}-R:${result}`);
      }
    }

  }
  return fake;
}

const GENE_REGEXP = /(\d+)-([\d\w_-]+)/
const DIAGNOSTIC_REGEXP = /(\d+)-R\:([\d\w_-]+)/


function buildFromFake(fake_genes_markers) {
  let data = {}
  for (let key of Object.keys(fake_genes_markers)) {
    if (FAKE_FIELDS.indexOf(key) === -1) {
      data[key] = fake_genes_markers[key];
    } else {
      data[key] = buildFromFakeItems(fake_genes_markers[key]);

    }
  }
  return data;
}

function buildFromFakeItems(fake) {
  let data = [];
  for (let fake_item of fake) {
    let match, id, result, field;

    if (DIAGNOSTIC_REGEXP.test(fake_item)) {
      match = DIAGNOSTIC_REGEXP.exec(fake_item);
      id = +match[1];
      field = undefined;
      result = match[2];
    }
    else if (GENE_REGEXP.test(fake_item)) {
      match = GENE_REGEXP.exec(fake_item);
      id = +match[1];
      field = match[2];
      result = undefined;
    }
    else {
      id = +fake_item;
      field = undefined;
      result = undefined;
    }


    let item = data.find(d => d.diagnostics_and_gene_id === id);
    if (!item) {
      item = {diagnostics_and_gene_id: id}
      data.push(item);
    }

    if (result) {
      if (!item.results) item.results = [];
      item.results.push(result);
    } else if (field) {
      if (!item.gene_options) item.gene_options = []
      item.gene_options.push(field);
    } else {
      item.any = true;
    }
  }

  return data;
}

const REQUIRED_OPTIONS = {
  all: 'Required to Have All',
  some: 'Required to Have At Least One'
}

const PROHIBITED_OPTIONS = {
  all: 'Prohibited From Having All (Can Have Some)',
  some: 'Prohibited From Having Any'
}
