import React, {Component} from 'react';
import Form from './form';
import request from '../request';
import {CancerSituationSearchKey, CancerSituationResultOptions} from '../constants.js.erb';


export default class CancerExplorer extends Component {
  constructor(props) {
    super(props);
    this.state = {session: {}};
    this.onChangeField = this.onChangeField.bind(this);
    this.onChangeCancerSituation = this.onChangeCancerSituation.bind(this);
    this.submit = this.submit.bind(this);
  }

  submit() {
    let {session} = this.state;
    let url = '/admin/cancer_explorer';
    request('PUT', url, session).then(result => {
      this.setState({result});
    });
  }

  reset() {
    this.setState({result: null});
  }

  onChangeField(name, value) {
    let {session} = this.state;
    if (name === 'cancer_situation_id') {
      session = {cancer_situation_id: value};
    } else {
      session[name] = value;
    }

    this.setState({session});
  }

  onChangeCancerSituation(e) {
    let {session} = this.state;
    let {option} = e;
    session.tumor_type = option.tumor_type;
    session.cancer_situation_name = option.text;
    this.setState({session});
  }

  fields() {
    return [
      {name: 'cancer_situation_id', type: 'slektr', src: "/admin/cancer_explorer/as_options.json", search_key: CancerSituationSearchKey, onChangeCallback: this.onChangeCancerSituation},
      {name: 'option', label: 'Stage and Recurrence', type: 'radio', collection: SOLID_OPTIONS, required: true, visible: s => s.tumor_type === 'S'},
      {name: 'option', label: 'Recurrence', type: 'radio', collection: LIQUID_OPTIONS, required: true, visible: s => s.tumor_type === 'L'}
    ];
  }

  renderResult(result) {
    let {session} = this.state;
    let option_label = session.tumor_type === 'S' ? SOLID_OPTIONS[session.option] : LIQUID_OPTIONS[session.option];
    let result_label = CancerSituationResultOptions[result.option];
    let result_explanation = RESULT_EXPLANATION[result.option];
    let notes, sources;
    if (result.notes && result.notes.length > 0) {
      notes = <p className="explorer_result_notes"><i className="fa fa-info-circle"></i> <b>{result.notes}</b></p>
    }

    if (result.sources && result.sources.length > 0) {
      sources = (
        <div className="explorer_result_sources">
          <h5>Sources</h5>
          <ul>
          {result.sources.map(source => {
            return (
              <li key={source.id}>
                <a href={source.url} target="_blank">{source.name}</a>
              </li>
            )
          })}
        </ul>
        </div>
      )
    }
    return (
      <div id="result" className="explorer_results">
        <button className="btn btn-default btn-sm" onClick={e => this.reset()}><i className="fa fa-refresh"></i> Start Again</button>
        <p className="explorer_result_mapped"><b>Maps to:</b> {result.cancer_type_name}</p>
        <h2 className="explorer_result_situation">{session.cancer_situation_name}</h2>
        <h3 className="explorer_result_option">{option_label}</h3>
        <p className="explorer_result_result">{result_label}</p>
        <p className="explorer_result_explaination">{result_explanation}</p>
        {notes}
        {sources}
      </div>
    )
  }

  renderForm() {
    let {session} = this.state;
    let submit;
    if (session.option) {
      submit = this.submit;
    }
    return <Form model={session} fields={this.fields()} onChangeField={this.onChangeField} onSubmit={submit} submitLabel="Submit"/>
  }


  renderContent() {
    let {result} = this.state;
    if (result) {
      return this.renderResult(result);
    } else {
      return this.renderForm();
    }
  }

  render() {
    return (
      <div id="cancer_explorer">
        {this.renderContent()}
      </div>
    )
  }
}

const LIQUID_OPTIONS = {
  liquid_non_recurrent: 'Non-recurrent',
  liquid_recurrent: 'Recurrent'
}

const SOLID_OPTIONS = {
  local_non_recurrent: 'Local (Stage I/II) Non-recurrent',
  local_recurrent: 'Local (Stage I/II) Recurrent',
  regional_non_recurrent: 'Regional/ Advanced (Stage III) Non-recurrent',
  regional_recurrent: 'Regional/ Advanced (Stage III) Recurrent',
  metastatic_non_recurrent: 'Metastatic (Stage IV) Non-recurrent',
  metastatic_recurrent: 'Metastatic (Stage IV) Recurrent'
}


const RESULT_EXPLANATION = {
    Y: 'Every single type of cancer has success stories and patients that lead meaningful lives after diagnosis. For this cancer type and situation, too few patients are benefiting from standard therapies alone, so we recommend being as proactive as possible.',
  N: 'Treatment will be needed, but surgery and treatments currently approved for this type of cancer and situation are bringing meaningful and long-lasting cures for a high number of patients.',
  M: 'It is possible that standard treatments for this type of cancer and situation will provide a long-lasting cure, but there are also reasons to be pro-active and do all you can to improve your chances.'
}
