import React, {Component} from 'react';
import Form from './form';
import request from '../request';

export default class PatientFileForm extends Component {
  constructor(props) {
    super(props);
    let record = props.record;
    this.state = {record};
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSubmit() {
    let {patient, onSubmit} = this.props;
    let {record} = this.state;
    this.setState({submitting: true});

    let method, url;
    if (record.id) {
      url = '/admin/patients/' + patient.id + '/files/' + record.id;
      method = 'PUT'
    } else {
      url = '/admin/patients/' + patient.id + '/files';
      method = 'POST'
    }

    request(method, url, record, {multipart: true, root: 'patient_file'}).then(file => {
      onSubmit(file);
    });
  }

  onChange(record) {
    this.setState({record});
  }


  formFields() {
    return [
      {name: 'upload', type: 'file', label: 'File', required: true, visible: f =>  !f.id},
      {name: 'description', type: 'text'}
    ];
  }

  render() {
    let {containerElement, onCancel} = this.props;
    let {record, submitting} = this.state;
    return <Form model={record} modelName='File' fields={this.formFields()} onCancel={onCancel} onChange={this.onChange} onSubmit={this.onSubmit} submitting={submitting} containerElement={containerElement} />
  }
}
