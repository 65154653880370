import React, {Component} from 'react';
import {BaseEventEditor, BaseEventViewer} from './base_event';
import {RelatedToOptions} from '../../../constants.js.erb';

class ComDecisionEditor extends BaseEventEditor {
  formFields() {
    return formFields();
  }

}


class ComDecisionViewer extends BaseEventViewer {
  formFields() {
    return formFields();
  }
}


export default {
  viewer: ComDecisionViewer,
  editor: ComDecisionEditor
}


function formFields() {
  return [
    {name: 'date', type: 'date', label: 'Date of Decision', required: true},
    {name: 'date_estimated', label: 'Date Estimated?', type: 'bool'},
    {name: 'related_to', type: 'select', collection: RelatedToOptions},
    {name: 'user', label: 'Decision Made By'},
    {name: 'description', type: 'text', label: 'Decision Detail/Rationale'}
  ];
}
