import React, {Component} from 'react';
import Form from './form';
import request from '../request';
import {PatientSearchKey, WorkCategorySearchKey, AdminSearchKey, TaskSearchKey} from '../constants.js.erb';

export default class TimeCardForm extends Component {
  constructor(props) {
    super(props);
    let {time_card} = props;
    this.state = {time_card};
    this.onChange = this.onChange.bind(this);
  }

  submit(e) {
    e.preventDefault();
    this.setState({submitting: true});
    let {time_card} = this.state;

    let url = "/admin/time_cards";
    let method;

    if (time_card.id) {
      url += "/" + time_card.id;
      method = "PUT"
    } else {
      method = "POST"
    }

    request(method, url, {time_card}).then(time_card => {
      let {onSubmit, return_path} = this.props;
      if (onSubmit) {
        onSubmit(time_card);
      } else if (return_path) {
        window.location = return_path;
      } else {
        window.location = "/admin/time_cards/";
      };
    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors, submitting: false});
    });

  }

  onChange(time_card) {
    this.setState({time_card});
  }

  fields() {
    return [
      {name: 'date', type: 'date', required: true},
      {name: 'hours', type: 'float', required: true},
      {name: 'description', type: 'text'},
      {name: 'admin_id', type: 'remote-select2', src: "/admin/admins/as_options.json", search_key: AdminSearchKey},
      {name: 'patient_id', type: 'remote-select2', src: "/admin/patients/as_options.json", search_key: PatientSearchKey},
      {name: 'work_category_id', type: 'remote-select', src: "/admin/work_categories/as_options.json", search_key: WorkCategorySearchKey},
    ];
  }
  renderTitle() {
    let {time_card} = this.state;
    let title = time_card.id ? 'Edit Time Card' : 'New Time Card';
    return <h4>{title}</h4>;
  }

  render() {
    let {time_card, errors, submitting} = this.state;
    let {onCancel} = this.props;

    return (
      <div>
        {this.renderTitle()}
        <hr/>
        <Form model_name="Time Card" model={time_card} fields={this.fields()} onChange={this.onChange} onSubmit={e => this.submit(e)} errors={errors} submitting={submitting} onCancel={onCancel} />
      </div>
    );
  }
}
