import React, {Component} from 'react';
import PeopleWidget from './people_widget';
import request from '../request';
import {TrialLocationAvailabilityOptions, TrialLocationSourceOfVerificationOptions, GroupTypes, GroupItemBaseURL} from '../constants.js.erb';
import {getLocationLabel, locationsComparator} from './locations';
import GeolocationInformation from './geolocation_information';

export class Groups extends Component {
  render() {
    let {trial_id, groups, show_innovative, innovative_group_items} = this.props;

    return (
      <div>
        {groups.map((g, index) => {
          let innovative_items = innovative_group_items.filter(i => i.group_id === g.id)
          return <Group key={g.id} trial_id={trial_id} group={g} index={index} show_innovative={show_innovative} innovative_items={innovative_items} />
        })}
      </div>
    )
  }
}


class Group extends Component {
  renderFollowOn(follow_on) {
    let {trial_id, group, show_innovative, innovative_items} = this.props;
    return (
      <li key={follow_on.id}>
        <ul className="follow_on_group">
          {follow_on.items.map(item => {
            let innovative_item = innovative_items.find(i => item.id === i.id && item.type === i.type);
            return <GroupItem item={item} key={item.key || item.id} trial_id={trial_id} group={group} show_innovative={show_innovative} innovative_item={innovative_item} />
          })}
        </ul>
      </li>
    )
  }

  renderDescription() {
    let {group} = this.props;
    if (!group.description || group.description.length === 0) return null
    return (
      <p className="group_description">{group.description}</p>
    );
  }

  renderClosedInformation() {
    let {group} = this.props;
    if (!group.closed) return null;
    let closed_at = moment(group.closed_at).format('LL');
    return (
      <p className="closed_text">
        This group is closed<span className="closed_by_text"> - marked closed by {group.closed_by_name} on {closed_at}</span>
      </p>
    )
  }

  render() {
    let {trial_id, group, index, show_innovative, innovative_items} = this.props;
    let group_type = GroupTypes[group.type];

    let className = "trial_group_holder"
    if (group.closed) className += " group_closed";

    return (
      <div className={className}>
        <h5 className="group_name">GROUP {index + 1} - <span className="group_type">{group_type}</span></h5>

        <ul className="group_item_list">
          {group.items.map(item => {
            let innovative_item = innovative_items.find(i => item.id === i.item_id && item.type === i.item_type);
            return <GroupItem trial_id={trial_id} group={group} item={item} key={item.key || item.id} show_innovative={show_innovative} innovative_item={innovative_item} />
          })}
          {group.follow_on.map(f => this.renderFollowOn(f))}
        </ul>

        {this.renderDescription()}
        {this.renderClosedInformation()}
      </div>
    )
  }
}


class GroupItem extends Component {
  constructor(props) {
    super(props);
    let {innovative_item} = props;
    this.state = {innovative_item, expanded: false, intervention: undefined}
  }

  toggleInnovativeItem() {
    let {innovative_item} = this.state;
    let {trial_id, group, item} = this.props;
    let url = `/admin/trials/${trial_id}/innovative_group_items`;

    if (innovative_item) {
      url = url += '/' + innovative_item.id;
      request('DELETE', url).then(r => {
        this.setState({innovative_item: undefined});
      });
    } else {
      let item_data = {group_id: group.id, item_type: item.type, item_id: item.id};
      request('POST', url, {innovative_item: item_data}).then(innovative_item => {
        this.setState({innovative_item});
      });
    }
  }

  toggleExpanded() {
    let {expanded, intervention} = this.state;
    expanded = !expanded;
    this.setState({expanded});

    if (expanded && !intervention)  {
      this.fetchInterventionDetails();
    }

  }

  fetchInterventionDetails() {
    let {item} = this.props;
    this.setState({loading: true});
    let url = "/admin/interventions/" + item.id;
    request("GET", url).then(intervention => {
      this.setState({intervention, loading: false});
    });
  }


  renderInterventionDetails() {
    let {expanded, intervention} = this.state;
    if (!expanded || !intervention) return null;
    let intervention_url = "/admin/interventions/" + intervention.id + "/edit";

    return (
      <div className="intervention_details">
        <table className="table intervention_details_table">
          <tbody>

            {INTERVENTION_FIELDS.map(({label, field, type}, index) => {
              let value = intervention[field];

              if (type === 'boolean') {
                value = value ? 'YES' : 'NO';
              }

              if (type === 'records_list') {
                value = value.map(r => r.name).join(', ');
              }

              if (!value || value.length === 0) return null;

              return (
                <tr key={index}>
                  <td>{label}:</td>
                  <td className="wrap-it">{value}</td>
                </tr>
              )
            })}

            {intervention.drug_info_urls.map((url, index) => {
              if (!url) return null;
              url = formatURL(url);
              return (
                <tr key={index}>
                  <td>Drug Info URL {index + 1}</td>
                  <td><a href={url} target="_blank">{url}</a></td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <p className="text-right"><a href={intervention_url}>Edit this Intervention</a></p>
      </div>
    );
  }

  renderMoaDetails() {
    let {type, moa_type} = this.props.item;
    if (type !== 'interventions') return null;

    return (
        <div>
          <span>MOA: <span className="subtle">{moa_type}</span></span>
        </div>
    );
  }

  renderType() {
    let {item} = this.props;
    let label;
    if (item.type === 'interventions') label = 'INTERVENTION';
    else if (item.type === 'moa_types') label = 'MOA';
    else if (item.type === 'diagnostics') label = 'DIAGNOSTIC';
    else if (item.type === 'combinations') label = 'COMBINATION';

    return (
      <span className="item_type_label">{label}</span>
    )
  }

  renderInnovativeFlag() {
    let {innovative_item} = this.state;
    let {group, item, show_innovative} = this.props;
    if (!show_innovative) return null;

    let checked = !!innovative_item;
    let id = `innovative_flag#${group.id}:${item.type}:${item.id}`;
    return (
      <span>Innovative <input id={id} type="checkbox" checked={checked} value={checked} onChange={e => this.toggleInnovativeItem()} /></span>
    );
  }

  render() {
    let {item} = this.props;
    let callback, button;

    if (item.type === 'interventions') {
      callback = e => this.toggleExpanded();
      button = (
       <button className="btn btn-xs btn-default open_text" onClick={callback}>Details <i className="fa fa-chevron-down"></i></button>
      )
    }

    let link_src = GroupItemBaseURL[item.type] + `/${item.id}/edit`;

    return (
      <li className="group_item_wrapper">
        <div className="group_item_header">
          <div>
            <a href={link_src}><b>{item.name}</b></a>
            <span className="item_details">{item.label}</span>
            {this.renderMoaDetails()}
            {button}
          </div>
          <div>
            {this.renderType()}
            <br/>
            {this.renderInnovativeFlag()}
          </div>
        </div>
        {this.renderInterventionDetails()}
      </li>

    );
  }

}


function formatURL(url) {
  if (!/^https?:\/\//i.test(url)) {
    url = 'http://' + url;
  }
  return url;
}


export class Locations extends Component {
  constructor(props) {
    super(props);

    let locations = props.locations.map(l => {
      l._label = getLocationLabel(l)
      return l;
    })

    locations = locations.sort(locationsComparator);
    let verified_locations = props.locations.filter(l => l.verified);

    let selected = 0;
    if (props.trial_location) {
      selected = locations.findIndex(l => l.id === props.trial_location.id)
    }

    this.state = {locations, verified_locations, selected}
  }

  componentDidMount() {
    let {selected} = this.state;
    this.fetchSelectedLocation(selected);
  }

  quickImportContact(data) {
    let event = new CustomEvent('quick-add-person', {detail: {data}});
    let target = document.getElementById('trial_location_people');
    target.dispatchEvent(event);
  }

  fetchSelectedLocation(index) {
    let {trial_id} = this.props;
    let {locations} = this.state;
    let location = locations[index];
    if (!location) return;

    this.setState({loading: true});

    let url = "/admin/trials/" + trial_id + "/locations/" + location.id + ".json"
    request('GET', url).then(location => {
      this.setState({loading: false, location});
    });
  }

  selectLocation(e) {
    let index = e.target.value;
    this.setState({selected: index})
    this.fetchSelectedLocation(index);
  }

  renderLoading() {
    return <i className="glyphicon glyphicon-refresh glyphicon-spin" style={{fontSize: 80}}/>
  }


  renderParentCancerCenter(cancer_center) {
    if (!cancer_center.parent_cancer_center) return null;

    let parent = cancer_center.parent_cancer_center;
    let url = "/admin/cancer_centers/" + parent.id;
    let link_to_trial_url;

    if (parent.clinical_trial_url && parent.clinical_trial_url.length > 0) {
      link_to_trial_url = <a href={parent.clinical_trial_url} target="_blank" className="btn btn-xs btn-success">Verify on Trials Website</a>
    }

    return (
      <ul className="list-unstyled">
        <li>
          <b>PARENT:</b> <a href={url}>{parent.name}</a> - {parent.people_count} people<br/>
          {link_to_trial_url}
        </li>
      </ul>
    )
  }

  renderCancerCenters() {
    let {location} = this.state;
    let {cancer_centers} = location;
    if (cancer_centers.length === 0) return null;

    return (
      <div>
        <p><i className="fa fa-hospital-alt"></i> <b>LINKED:</b></p>
        <ul className="list-unstyled">
          {cancer_centers.map(c => {

            let url = "/admin/cancer_centers/" + c.id;
            let link_to_trial_url;
            if (c.clinical_trial_url && c.clinical_trial_url.length > 0) {
              link_to_trial_url = <a href={c.clinical_trial_url} target="_blank" className="btn btn-xs btn-success">Verify on Trials Website</a>
            }
            return (
              <li key={c.id}>
                <a href={url}>{c.name}</a> - {c.people.length} people <br/>
                {link_to_trial_url}
                {this.renderParentCancerCenter(c)}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }


  renderContacts() {
    let {location} = this.state;
    let {contacts} = location;
    if (!contacts) return null;
    return contacts.map(( contact, index) => {
      return (
        <div key={index} className="imported_contact_listing">
          <div>
            <b>Contact</b><br/>
            {contact.name}<br/>
            <span className="email-break">{contact.email}</span><br/>
            {contact.phone}
          </div>
          <button className="btn btn-default btn-sm quick-add-person" onClick={e => this.quickImportContact(contact)}>Quick Add</button>
        </div>
      )
    })
  }

  renderGeolocationData(location) {
    let {trial_id, people_subheader, patient} = this.props;
    if (!patient || patient.locations.length === 0) return null;

    return (
      <div className="geoinfo-trial-view">
        <GeolocationInformation patient={patient} trial_locations={[location]}/>
      </div>
    )
  }

  renderStatus() {
    let {location} = this.state;
    return (
      <div>
        Status: <b>{location.status_humanized}</b> (Not verified)
      </div>
    );
  }

  renderVerification() {
    let {location} = this.state;

    if (!location.verified) return this.renderStatus();
    let date = moment(location.verified_at).format('LL')
    let notes, availability, source_of_verification;
    if (location.verified_notes) {
      notes = <span>and said: <br /><br /> {location.verified_notes}</span>;
    }

    if (location.slot_availability) {
      let option = TrialLocationAvailabilityOptions.find(o => o.id === location.slot_availability);
      availability = <span className="available_slot_info">Available Slots: {option.name}</span>
    }

    if (location.source_of_verification) {
      let option = TrialLocationSourceOfVerificationOptions.find(o => o.id === location.source_of_verification);
      source_of_verification = <span>Source of verification: {option.name}</span>
    }

    return (
      <div className="verification_info_holder">
        <b>{location.verified_by_name}</b> verified as <b>{location.status_humanized}</b>  on {date} {notes} <hr/>
        {availability} <br/>
        {source_of_verification} <br/>
      </div>
    )
  }

  renderGlobalGeolocationData() {
    let {trial_id, people_subheader, patient, locations} = this.props;
    if (!patient || patient.locations.length === 0) return null;

    return (
      <div className="global-geoinfo-trial-view">
        <GeolocationInformation patient={patient} trial_locations={locations} hide_show_all_btn={true} />
      </div>
    )
  }

  renderPeopleWidget() {
    let {people_subheader} = this.props;
    let {location} = this.state;
    let people_widget_ctx;

    if (location.cancer_centers.length === 1) {
      people_widget_ctx = {defaults: {cancer_center_ids: [location.cancer_centers[0].id]}};
    }

    return <PeopleWidget class_name="TrialLocation" record_id={location.id} people={location.people} context={people_widget_ctx} subheader={people_subheader} container_id='trial_location_people' />
  }

  renderLocation() {
    let {locations, location, loading} = this.state;
    if (loading) return this.renderLoading();
    if (!location) return null;

    return (
      <div className="row">
        <div className="col-md-6">
          <h4><b>LOCATION INFO</b></h4>
          <br/>
          <div>
            {location.name}<br/>
            {location.country_name}<br/>
            {location.city}<br/>
            {location.state_name}<br/>
            {location.zip}<br />
          </div>
          {this.renderVerification()}
          <hr/>
          {this.renderCancerCenters()}
        </div>
        <div className="col-md-6">

          <div className="imported_contacts_box">
            <h4 className="imported_contacts_box-label"><b>IMPORTED CONTACTS</b></h4>
            <div className="imported_conacts_box-inner">
              <p className="imported_contacts_box-desc"><span>Location Specific Contacts</span> imported from clinicaltrials.gov.</p>
              {this.renderContacts()}
              </div>
              <p className="imported_contacts_box-footer">Please add and tag people below.</p>
          </div>

          {this.renderPeopleWidget()}
        </div>
        <div className="row">
          <div className="col-md-12">
            {this.renderGeolocationData(location)}
          </div>

        </div>
      </div>
    )
  }

  renderSelectOption(location, index) {
    return (
      <option value={index} key={location.id}>{location._label}</option>
    )
  }

  render() {
    let {trial_id} = this.props;
    let {locations, verified_locations, selected} = this.state;
    let location = locations[selected];

    let locations_count = locations.length;
    let verified_locations_count = verified_locations.length;
    let locations_url = "/admin/trials/" + trial_id + "/locations";
    let edit_location_url;

    if (location) edit_location_url = locations_url + "/" + location.id + "/edit"

    return (
      <div className="col-md-7">
        {this.renderGlobalGeolocationData()}

        <h4><b>TRIAL LOCATIONS</b></h4>
        <br/>
        <p><a href={locations_url}>{locations_count} Locations</a> - <b> {verified_locations_count} Verified</b></p>
        <select value={selected} onChange={e => this.selectLocation(e)} id="trial-location-select">
          {locations.map((l, index) => this.renderSelectOption(l, index))}
        </select>
        <hr/>

        <a href={edit_location_url} className="pull-right"> Edit this Location</a>
        <div className="clearfix"></div>
        <div className="well">
          {this.renderLocation()}
        </div>
      </div>
    )
  }
}

const INTERVENTION_FIELDS = [
  {label: 'Name', field: 'name'},
  {label: 'Alias(es)', field: 'aliases'},
  {label: 'MOA (or Diagnostic Type)', field: 'moa_types'},
  {label: 'Medical Description', field: 'description'},
  {label: 'Patient Friendly Description', field: 'patient_friendly_description'},
  {label: 'Route of Administration', field: 'routes_of_administration'},
  {label: 'Companies', field: 'companies', type: 'records_list'},
  {label: 'Molecular Type(s)', field: 'molecular_types'},
  {label: 'Placebo?', field: 'includes_placebo', type: 'boolean'},

]
