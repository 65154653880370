import React, {Component} from 'react';
import request from '../request';
import {CancerCenterSearchKey} from '../constants.js.erb';

export default class SearchCancerCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {text: "", searching: false}
  }

  initModal(el) {
    if (!el) return;
    this.modalEl = $(el);
    this.modalEl.modal({show: false});
  }


  showModal() {
    if (!this.modalEl) return;
    this.modalEl.modal('show');
    this.modalEl.on('hide.bs.modal', () => {
      this.setState({text: "", searching: false, results: false})
    });
  }

  closeModal() {
    if (!this.modalEl) return;
    this.modalEl.modal('hide');
  }


  selectCancerCenter(center) {
    let {onSelect} = this.props;
    onSelect(center);
    this.closeModal();
  }

  search(e) {
    e.preventDefault();
    let {excludeUmbrella} = this.props;
    let {text} = this.state;
    if (!text) return;
    let url = "/admin/cancer_centers/search?q[" + CancerCenterSearchKey + "]=" + text;
    if (excludeUmbrella) {
      url += '&exclude_umbrellas=true';
    }

    request("GET", url)
      .then(cancer_centers => this.setState({results: cancer_centers}))
  }


  renderResult(center) {
    let url = "/admin/cancer_centers/" + center.id;
    let actionBtn;

    return (
      <tr key={center.id}>
        <td><a href={url} target="_blank">{center.name}</a></td>
        <td>{center.state_name}</td>
        <td>{center.city}</td>
        <td>{center.zip}</td>
        <td><button className="btn btn-sm btm-default" onClick={e => this.selectCancerCenter(center)}>Select</button></td>
      </tr>
    )
  }

  renderResults() {
    let {results} = this.state;
    if (!results) return null;

    return(
      <table className="table table-condensed table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>State</th>
            <th>City</th>
            <th>Zip</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {results.map(r => this.renderResult(r))}
        </tbody>
      </table>
    )
  }

  renderModal() {
    let {text} = this.state;

    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref={e => this.initModal(e)}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">Search Cancer Center</h4>
            </div>
            <div className="modal-body">
              <form onSubmit={e => this.search(e)}>

                <div className="input-group">
                  <input autoFocus={true} type="text" className="form-control" value={text} onChange={e => this.setState({text: e.target.value})}/>
                  <span className="input-group-btn">
                    <button className="btn btn-default" type="button" onClick={e => this.search(e)}>Search</button>
                  </span>
                </div>

              </form>

              {this.renderResults()}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let buttonLabel = this.props.buttonLabel || "Search"
    return (
      <div>
        <button onClick={e => this.showModal()} className="btn btn-success">{buttonLabel}</button>

        {this.renderModal()}
      </div>
    )
  }
}
